import { RaisedButton } from "material-ui";
import { amber900 as biebColor } from "material-ui/styles/colors";
import CompareArrows from "material-ui/svg-icons/action/compare-arrows";
import NewReleases from "material-ui/svg-icons/av/new-releases";
import moment from "moment";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getPageAction } from "../../actions/data";
import { Relation, StoreRoot } from "../../business/models";

export interface UpdateItemButtonProps {
  onClick: (
    biebItemId: number,
    localItemId: number,
    hasUpdate: boolean
  ) => void;
  biebRelation: Relation;
}

const UpdateItemButton = ({ onClick, biebRelation }: UpdateItemButtonProps) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getPageAction(biebRelation.left.itemId));
  }, [biebRelation.left.itemId, dispatch]);

  const lastPublished = useSelector(({ data: { pages } }: StoreRoot) => {
    const dateStr = pages[biebRelation.left.itemId]?.lastPublished;
    return dateStr ? moment(dateStr) : undefined;
  });
  const hasUpdate = lastPublished?.isSameOrAfter(
    moment(biebRelation.modificationDateTime)
  );
  return (
    <RaisedButton
      label="Vergelijken met Bieb"
      labelPosition="before"
      labelColor={hasUpdate ? "white" : undefined}
      backgroundColor={hasUpdate ? biebColor : undefined}
      onClick={() =>
        onClick(
          biebRelation.left.itemId,
          biebRelation.right.itemId,
          Boolean(hasUpdate)
        )
      }
      icon={hasUpdate ? <NewReleases /> : <CompareArrows />}
    />
  );
};

export default UpdateItemButton;
