import classNames from "classnames";
import { compact, entries, flatten, flow, map, orderBy } from "lodash/fp";
import { Checkbox, List, ListItem } from "material-ui";
import { lightGreen500 as primaryColor } from "material-ui/styles/colors";
import IndeterminateCheckBox from "material-ui/svg-icons/toggle/indeterminate-check-box";
import React, { useCallback, useMemo } from "react";

import {
  IproxContent,
  KoppelingClusterMapping,
  NonEmptyFieldData,
  Relation,
  RelationType,
  SiteLink,
  emptyArray,
} from "../../business/models";
import { reverseSide } from "../../business/relations";
import { flown } from "../../lodash";
import Field from "../content/field";
import ClusterRelationView from "./ClusterRelationView";
import {
  BiebUseSelection,
  ClusterContent,
  RepTypContent,
  RepetitiveClusterTreeProps,
} from "./models";
import { getClusterName, isNonEmpty } from "./utils";

const collect = (
  content: IproxContent,
  pagetype: string,
  relations: Relation[],
  insideRepCls: boolean = false
): RepTypContent | undefined => {
  switch (content.iproxType) {
    case "clusters": {
      const clusterContent = flow(
        map((cluster) =>
          collect(
            { ...(cluster as {}), iproxType: "cluster" } as IproxContent,
            pagetype,
            relations,
            true
          )
        ),
        flatten,
        compact
      )(content.clusters);
      if (!clusterContent || clusterContent.length === 0) {
        return undefined;
      }

      return {
        name: getClusterName(content, pagetype),
        content: clusterContent,
        isContainer: true,
      };
    }
    case "cluster": {
      const clusterContent = flow(
        entries,
        map(([_, cluster]) =>
          collect(cluster, pagetype, relations, insideRepCls)
        ),
        flatten,
        compact
      )(content);
      return insideRepCls
        ? {
            name: getClusterName(content, pagetype),
            clusterId: content.clusterId,
            content: clusterContent,
            relations: relations.filter((r) => {
              const siteLink =
                r.side === 3 ? r.using[0] : r.side === 1 ? r.left : r.right;
              return (
                siteLink.pageClusterId !== undefined &&
                siteLink.pageClusterId === Number(content["clusterId"])
              );
            }),
          }
        : clusterContent;
    }
    case "field": {
      return isNonEmpty(content) && insideRepCls ? [content] : [];
    }
    default: {
      return [];
    }
  }
};

const findLabel = (content: RepTypContent): string | undefined => {
  if (Array.isArray(content) && content.length > 0) {
    if ((content[0] as ClusterContent).content) {
      // Cluster array
      return findLabel(content[0] as ClusterContent);
    } else if ((content[0] as NonEmptyFieldData).id) {
      // Field array
      return (
        ((content as NonEmptyFieldData[]).find(
          (f) => f.definition.dataTypeCode === 2
        )?.value as string) ?? "Geen"
      );
    }
  } else {
    return findLabel((content as ClusterContent).content);
  }
};

const descendantFieldIds = (
  content: RepTypContent | NonEmptyFieldData
): number[] => {
  if (Array.isArray(content) && content.length > 0) {
    return content.flatMap((item) => descendantFieldIds(item));
  } else if ((content as NonEmptyFieldData)?.id) {
    const field = content as NonEmptyFieldData;
    return [field.id];
  } else if (content as ClusterContent) {
    return descendantFieldIds((content as ClusterContent).content);
  } else {
    return [];
  }
};

const render = (
  content: RepTypContent | NonEmptyFieldData,
  onCheck: (isInputChecked: boolean, fieldIds: number[]) => void,
  value: BiebUseSelection,
  types: RelationType[],
  isKoppeling: boolean,
  koppelingWithApplications: SiteLink[],
  koppelingClusterMapping: KoppelingClusterMapping | undefined,
  disabled: boolean,
  close: () => void
): JSX.Element[] => {
  if (Array.isArray(content) && content.length > 0) {
    return content.flatMap((cluster) =>
      render(
        cluster,
        onCheck,
        value,
        types,
        isKoppeling,
        koppelingWithApplications,
        koppelingClusterMapping,
        disabled,
        close
      )
    );
  } else if ((content as NonEmptyFieldData)?.id) {
    // Field
    const field = content as NonEmptyFieldData;
    const fieldChecked = value.fieldIds.includes(field.id);

    return [
      <ListItem
        key={field.id}
        primaryText={
          <Field
            className={classNames({ required: field.definition.required })}
            omitLabel={false}
            fullWidth={false}
            field={field}
          />
        }
        className="bieb-use-wizard-field"
        leftCheckbox={
          <Checkbox
            onCheck={(_, checked) => onCheck(checked, [field.id])}
            checked={fieldChecked}
            style={{ top: "calc(50% - 12px)" }}
            disabled={disabled}
          />
        }
      />,
    ];
  } else if (content as ClusterContent) {
    // Cluster
    const cluster = content as ClusterContent;

    if (cluster.isContainer) {
      const children = (cluster.content as ClusterContent[]).map((c) => {
        const repetitiveClusterName = `${cluster.name}: ${
          c.name ?? findLabel(c.content)
        }`;

        return {
          ...c,
          name: repetitiveClusterName,
        };
      });

      return render(
        children,
        onCheck,
        value,
        types,
        isKoppeling,
        koppelingWithApplications,
        koppelingClusterMapping,
        disabled,
        close
      );
    }

    const fieldIds = descendantFieldIds(content);
    const allSelected = fieldIds.every((id) => value.fieldIds.includes(id));
    const someSelected = fieldIds.some((id) => value.fieldIds.includes(id));

    const isGegevenCluster = cluster.name?.startsWith("Gegeven:");
    const canCheck =
      !isKoppeling ||
      (isGegevenCluster &&
        compact(koppelingClusterMapping?.[cluster.clusterId!] ?? emptyArray())
          .length === 2);

    const relations = flown(
      cluster.relations ?? emptyArray(),
      map((r: Relation) => {
        const others =
          r.side === 3 ? [r.left, r.right] : [r.side === 2 ? r.left : r.right];
        return others.map((other, index) => ({
          other,
          side: r.side === 3 ? (index === 0 ? 0 : 1) : reverseSide(r.side),
          isMissing: !isGegevenCluster
            ? false
            : !canCheck &&
              r.side === 3 &&
              other.pageClusterId &&
              koppelingClusterMapping?.[cluster.clusterId!]?.[index] ===
                undefined,
          relation: r,
        }));
      }),
      flatten,
      orderBy(["side", "other.label"], ["asc", "asc"]),
      map(({ other, side, isMissing, relation }) => (
        <ClusterRelationView
          key={other.itemId}
          item={other}
          side={side}
          relation={relation}
          types={types}
          isMissing={isMissing}
          close={close}
        />
      ))
    );

    const primaryText = !canCheck
      ? `${cluster.name} - Veld niet overgenomen in gekoppelde applicatie`
      : `${cluster.name}`;
    return [
      <ListItem
        key={JSON.stringify(cluster)}
        primaryText={primaryText}
        className="bieb-use-wizard-field clusters"
        nestedItems={[
          ...relations,
          ...render(
            cluster.content,
            onCheck,
            value,
            types,
            isKoppeling,
            koppelingWithApplications,
            koppelingClusterMapping,
            !canCheck,
            close
          ),
        ]}
        leftCheckbox={
          <Checkbox
            onCheck={(_, isInputChecked) => onCheck(isInputChecked, fieldIds)}
            checked={allSelected}
            uncheckedIcon={someSelected ? <IndeterminateCheckBox /> : undefined}
            iconStyle={someSelected ? { fill: primaryColor } : undefined}
            disabled={!canCheck}
            style={{ top: "calc(50% - 12px)" }}
          />
        }
      />,
    ];
  }

  return [];
};

const RepetitiveClusterTree = ({
  page,
  relations,
  types,
  onCheck,
  value,
  allFieldIds,
  koppelingWithApplications,
  koppelingClusterMapping,
  close,
}: RepetitiveClusterTreeProps) => {
  const isKoppeling = useMemo(
    () => page.pagetype === "koppeling",
    [page.pagetype]
  );

  const tree = useMemo<ClusterContent[]>(
    () =>
      flow(
        entries,
        map(([_, content]) =>
          collect(content, page["pagetype"] as unknown as string, relations)
        ),
        flatten,
        compact
      )(page),
    [page, relations]
  );

  const allFields = useMemo(
    () =>
      tree
        .flatMap((content) => descendantFieldIds(content))
        .filter((id) => allFieldIds.includes(id)),
    [allFieldIds, tree]
  );

  const handleAllFieldsClick = useCallback(
    (_: unknown, isInputChecked: boolean) => {
      onCheck(isInputChecked, allFields);
    },
    [allFields, onCheck]
  );

  if (tree.length === 0) {
    return <p>Er zijn geen clusters gevonden om over te nemen.</p>;
  }

  const allSelected =
    allFields.length > 0 && allFields.every((f) => value.fieldIds.includes(f));
  const someSelected = allFields.some((f) => value.fieldIds.includes(f));
  return (
    <List>
      <ListItem
        primaryText="Alles selecteren"
        className="bieb-use-wizard-field select-all"
        leftCheckbox={
          <Checkbox
            checked={allSelected}
            uncheckedIcon={someSelected ? <IndeterminateCheckBox /> : undefined}
            iconStyle={someSelected ? { fill: primaryColor } : undefined}
            onCheck={handleAllFieldsClick}
            style={{ top: "calc(50% - 12px)" }}
          />
        }
      />
      {render(
        tree,
        onCheck,
        value,
        types,
        isKoppeling,
        koppelingWithApplications,
        koppelingClusterMapping,
        false,
        close
      )}
    </List>
  );
};

export default RepetitiveClusterTree;
