import empty from "empty";
import {
  isEmpty as _isEmpty,
  filter,
  find,
  flow,
  includes,
  keys,
  map,
  omit,
  reject,
  some,
} from "lodash/fp";
import FlatButton from "material-ui/FlatButton";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import {
  compose,
  setDisplayName,
  setPropTypes,
  withHandlers,
  withProps,
  withState,
} from "recompose";

import { itemListShape, relationTypeShape } from "../../business/prop-types";
import { Side, emptyType, relationMultiple } from "../../business/relations";
import Dialog from "../../containers/dialog";
import { flown } from "../../lodash";
import { execOnChange } from "../../recompose.contrib";
import { isEmpty } from "../../utils";
import Form from "./form";

const AddRelation = ({
  open,
  valid,
  add,
  addClusterRelations,
  clear,
  handleNextStep,
  handlePreviousStep,
  setStepIndex,
  validStep,
  title,
  metaFieldsValid,
  clusterRequired,
  id,
  clusterId,
  clusters,
  clustersLoading,
  clustersForRelations,
  clustersForRelationsClear,
  initialForm,
  setForm,
  biebStructures,
  structures,
  maySelectLocation,
  ...props
}) => {
  useEffect(() => {
    if (clusterRequired && id && clusterId) {
      clustersForRelations(id, clusterId);
    } else if (!clusterRequired) {
      clustersForRelationsClear();
    }
  }, [
    clusterId,
    clusterRequired,
    clustersForRelations,
    clustersForRelationsClear,
    id,
  ]);

  useEffect(() => {
    if (!initialForm) {
      return;
    }

    // set the value of the initial form
    setForm(omit(["loading", "_expiry"])(initialForm));
    if (
      initialForm.clusterIds &&
      initialForm.clusterIds.length > 0 &&
      initialForm.disableStep1
    ) {
      // when step 1 is disabled => go to step 2
      setStepIndex(1);
    }
  }, [initialForm, setForm, setStepIndex]);

  if (!open) {
    return null;
  }

  return (
    <Dialog
      title={title || "Relatie toevoegen"}
      open
      autoScrollBodyContent
      actions={
        (props.usingRequired && props.stepIndex < 2) ||
        (clusterRequired && props.stepIndex === 0)
          ? [
              <FlatButton
                key="close"
                secondary
                label="Annuleren"
                onClick={clear}
              />,
              <FlatButton
                key="previous"
                label="Vorige"
                disabled={props.stepIndex === 0}
                onClick={handlePreviousStep}
              />,
              <FlatButton
                key="next"
                label="Volgende"
                primary
                disabled={!validStep}
                onClick={handleNextStep}
              />,
            ]
          : [
              <FlatButton
                key="close"
                secondary
                label="Annuleren"
                onClick={clear}
              />,
              (props.usingRequired || clusterRequired) && (
                <FlatButton
                  key="previous"
                  label="Vorige"
                  disabled={props.stepIndex === 0}
                  onClick={handlePreviousStep}
                />
              ),
              <FlatButton
                primary
                key="save"
                disabled={!(valid && metaFieldsValid)}
                label="Opslaan"
                onClick={clusterRequired ? addClusterRelations : add}
              />,
            ]
      }
    >
      <Form
        {...props}
        setForm={setForm}
        handleNextStep={handleNextStep}
        setStepIndex={setStepIndex}
        clusters={clusters}
        clusterRequired={clusterRequired}
        clustersLoading={clustersLoading}
        biebStructures={biebStructures}
        structures={structures}
        maySelectLocation={maySelectLocation}
      />
    </Dialog>
  );
};

AddRelation.propTypes = {
  open: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
  add: PropTypes.func.isRequired,
  clear: PropTypes.func.isRequired,
  handleNextStep: PropTypes.func.isRequired,
  handlePreviousStep: PropTypes.func.isRequired,
  setStepIndex: PropTypes.func.isRequired,
  validStep: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  metaFieldsValid: PropTypes.bool.isRequired,
  usingRequired: PropTypes.bool.isRequired,
  stepIndex: PropTypes.number.isRequired,
};

const isItemDisabled =
  (currentSelection, type, checked, newClusterIds, relationType = emptyType) =>
  (item) => {
    const selected = (id, clusterId) =>
      id === currentSelection &&
      (!clusterId || newClusterIds.includes(clusterId));

    const disableOthers = checked.length > 0 && !relationMultiple(type.rule);
    const disabled =
      item.disabled ||
      (item.itemId === currentSelection &&
        (relationType.hierarchically || relationType.symmetrically)) ||
      (currentSelection &&
        some(
          ({
            left: {
              itemId: leftId,
              pageClusterId: leftClusterId,
            } = empty.object,
            right: {
              itemId: rightId,
              pageClusterId: rightClusterId,
            } = empty.object,
            using = empty.array,
          }) =>
            selected(leftId, leftClusterId) ||
            selected(rightId, rightClusterId) ||
            some(({ itemId: usingId }) => selected(usingId))(using)
        )(item.relations)) ||
      (disableOthers && !includes(item.itemId)(checked));

    return { ...item, disabled };
  };

export default compose(
  setDisplayName("AddRelation"),
  setPropTypes({
    type: relationTypeShape,
    peers: itemListShape.isRequired,
    using: itemListShape.isRequired,
    add: PropTypes.func.isRequired,
    addNew: PropTypes.func.isRequired,
    addUsing: PropTypes.func.isRequired,
    clear: PropTypes.func.isRequired,
  }),
  withState("form", "setForm", empty.object),
  withState("stepIndex", "setStepIndex", 0),
  withProps(
    ({
      type = emptyType,
      scope,
      peers,
      using,
      form,
      setForm,
      stepIndex,
      side,
      id,
      form: { metaData = empty.array } = empty.object,
      metaFieldDefinitions = empty.object,
    }) => {
      const usingRequired =
        !isEmpty(type.using) && isEmpty(type.using.specificItemId);
      const { peers: usingPeers = empty.array } = form.using || empty.object;
      const { peers: peerPeers = empty.array } = form.peer || empty.object;
      const [currentSelection] = usingRequired
        ? (usingPeers.length > 0 ? usingPeers : peerPeers) || empty.array
        : [id];

      const metaFieldsRequired = !_isEmpty(metaFieldDefinitions)
        ? flow(
            keys,
            map((key) => {
              const {
                definition: { name, required = false } = empty.object,
                isEmpty = true,
              } = metaFieldDefinitions[key] || empty.object;
              const { value } = find({ name })(metaData) || empty.object;
              return { required, valid: value ? value !== "" : !isEmpty };
            }),
            filter({ required: true })
          )(metaFieldDefinitions)
        : undefined;

      const peerType = side === Side.left ? type.left : type.right;
      const usingType = form.using || empty.object;
      const clusterRequired = type.needsCluster && !isEmpty(peerType.clusterId);

      return {
        title: `${
          (side === Side.left ? type.left : type.right).label
        } toevoegen`,
        open: !isEmpty(peers.loading),
        clusterRequired,
        itemId: id,
        clusterId: peerType.clusterId,
        usingRequired,
        valid: Boolean(
          !form.sent &&
            type.mayAdd &&
            !isEmpty(form.peer) &&
            (isEmpty(type.using) || !isEmpty(form.using))
        ),
        peer: {
          loading: peers.loading,
          items: peers.items.map(
            isItemDisabled(
              currentSelection,
              peerType,
              peerPeers,
              form.clusterIds ?? empty.array,
              type
            )
          ),
          value: form.peer,
          externalApplication: form.externalApplication,
          setValue: (peer, externalApplication = false) =>
            setForm({ ...form, peer, externalApplication, sent: false }),
          label: peerType.label,
        },
        using: {
          loading: using.loading,
          items: using.items.map(
            isItemDisabled(
              currentSelection,
              usingType,
              usingPeers,
              form.clusterIds ?? empty.array,
              type
            )
          ),
          value: form.using,
          externalApplication: form.externalApplication,
          setValue: (using, externalApplication = false) =>
            setForm({ ...form, using, externalApplication, sent: false }),
          label:
            (form.side === Side.left ? type.right : type.left).labelSpec ||
            usingType.pagetype,
          specificItemId: usingType.specificItemId,
        },
        validStep: Boolean(
          stepIndex === 0 && clusterRequired
            ? !form.sent && form.clusterIds && form.clusterIds.length > 0
            : stepIndex === 0 && usingRequired
            ? !form.sent && type.mayAdd && !isEmpty(form.startFrom)
            : stepIndex === 1 && usingRequired
            ? !form.sent &&
              type.mayAdd &&
              ((form.startFrom === "using" &&
                !isEmpty(form.using) &&
                !isEmpty(form.using.peers)) ||
                (form.startFrom === "peer" &&
                  !isEmpty(form.peer) &&
                  !isEmpty(form.peer.peers)))
            : false
        ),
        metaFieldsValid: !flown(
          metaFieldsRequired,
          map("valid"),
          includes(false)
        ),
        maySelectLocation:
          !Boolean(type.using) &&
          side !== undefined &&
          !(type[Side[Side.rev(side)]].scope > 0),
      };
    }
  ),
  withHandlers({
    refresh:
      ({ type, side, id, refreshRelations }) =>
      (targetSitIdt, ssoItmIdt) =>
        refreshRelations(type, side, id, targetSitIdt, ssoItmIdt),

    add:
      ({
        id,
        type = emptyType,
        form,
        form: { externalApplication },
        setForm,
        types: { peertype, usingtype } = empty.object,
        add,
        addNew,
        addRelations,
        addUsing,
        side,
        isBiebItem,
        siteId,
      }) =>
      () => {
        const structureId = isBiebItem ? siteId : undefined;
        const metaModel = { pagetype: type.pagetype, metaData: form.metaData };
        setForm({ ...form, sent: true });
        if (!type.mayAdd) {
          return;
        }

        if (form.using && form.using.peers) {
          for (const using of form.using.peers) {
            for (const peer of form.peer.peers) {
              if (typeof peer === "number" && typeof using === "number") {
                add(peer, using, metaModel);
              } else if (
                side === Side.using &&
                typeof peer === "string" &&
                typeof using === "number"
              ) {
                const peerSide =
                  type.left.pagetype === peertype ? Side.left : Side.right;
                addNew(
                  {
                    label: peer,
                    side: peerSide,
                    structureId,
                    externalApplication,
                  },
                  using,
                  metaModel
                );
              } else if (
                side === Side.using &&
                typeof peer === "number" &&
                typeof using === "string"
              ) {
                const usingSide =
                  type.left.pagetype === usingtype ? Side.left : Side.right;
                addNew(
                  {
                    label: using,
                    side: usingSide,
                    structureId,
                    externalApplication,
                  },
                  peer,
                  metaModel
                );
              } else if (
                typeof peer === "string" &&
                typeof using === "number"
              ) {
                addNew(
                  { label: peer, structureId, externalApplication },
                  using,
                  metaModel
                );
              } else if (
                typeof peer === "number" &&
                typeof using === "string"
              ) {
                addUsing(using, peer, structureId, externalApplication);
              }
            }
          }

          return;
        }

        const relationModels = [];
        for (const peer of form.peer.peers) {
          if (typeof peer !== "number") {
            addNew(
              { label: peer, structureId, externalApplication },
              form.using,
              metaModel
            );
          } else if (metaModel?.pagetype) {
            add(peer, form.using, metaModel);
          } else {
            relationModels.push(
              side === Side.left
                ? { vanItmIdt: id, narItmIdt: peer }
                : { vanItmIdt: peer, narItmIdt: id }
            );
          }
        }

        if (relationModels.length > 0) {
          addRelations(relationModels);
        }
      },

    addClusterRelations:
      ({
        type = emptyType,
        id,
        form,
        form: { externalApplication },
        setForm,
        addRelations,
        addNew,
        isBiebItem,
        siteId,
      }) =>
      () => {
        const structureId = isBiebItem ? siteId : undefined;
        const metaModel = { pagetype: type.pagetype, metaData: form.metaData };
        setForm({ ...form, sent: true });

        let clusterRelations = [];
        for (const peer of form.peer.peers) {
          if (typeof peer === "number") {
            clusterRelations = [
              ...clusterRelations,
              ...form.clusterIds.map((pagClsIdt) => ({
                vanItmIdt: id,
                vanPagClsIdt: pagClsIdt,
                narItmIdt: peer,
              })),
            ];
          } else {
            addNew(
              {
                label: peer,
                structureId,
                externalApplication,
              },
              form.using,
              metaModel,
              form.clusterIds
            );
          }
        }

        addRelations(clusterRelations);
      },

    clear:
      ({ setForm, setStepIndex, clear }) =>
      () => {
        setForm(empty.object);
        setStepIndex(0);
        clear();
      },

    updateStartFromSide:
      ({ setForm }) =>
      (side = "using") => {
        setForm({ startFrom: side });
      },

    handleNextStep:
      ({ stepIndex, setStepIndex }) =>
      () => {
        const nextStep = stepIndex + 1;
        setStepIndex(nextStep);
      },

    handlePreviousStep:
      ({ stepIndex, setStepIndex }) =>
      () => {
        if (stepIndex > 0) {
          const prevStep = stepIndex - 1;
          setStepIndex(prevStep);
        }
      },

    metaFieldRevert:
      ({ setForm, form: { metaData = empty.array, ...rest } = empty.object }) =>
      ({ name }) => {
        setForm({ ...rest, metaData: reject({ name })(metaData) });
      },

    metaFieldAdd:
      ({ setForm, form: { metaData = empty.array, ...rest } = empty.object }) =>
      ({ name, value, data, values }) => {
        setForm({
          ...rest,
          metaData: [...metaData, { name, value, values, data }],
        });
      },

    updateClusterIds:
      ({ setForm, form: { clusterIds = empty.array, ...rest } }) =>
      (clusterIds) => {
        setForm({ ...rest, clusterIds });
      },
  }),
  execOnChange(
    ({
      form: { sent = false } = empty.object,
      form,
      using: { specificItemId } = empty.object,
      setForm,
      formLoading,
      clear,
    }) => {
      if (formLoading === "success") {
        // als verzonden dan form-state clearen
        clear();
      } else if (!sent && specificItemId && !form.using) {
        // als er een specifieke using is, zet deze dan
        setForm({ ...form, using: specificItemId });
      }
    },
    "form",
    "formLoading",
    "using"
  )
)(AddRelation);
