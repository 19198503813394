import empty from "empty";
import {
  compact,
  endsWith,
  entries,
  filter,
  flatMap,
  flatten,
  flow,
  groupBy,
  has,
  head,
  identity,
  includes,
  map,
  memoize,
  orderBy,
  reject,
  some,
  sortBy,
  values,
} from "lodash/fp";

import { flown, mapWithIndex } from "../lodash";
import {
  arrayFilterMap,
  arrayFlatMap,
  emptyLookup,
  isNonEmptyArray,
  labelize,
} from "../utils";
import { scopeBieb } from "./index";
import { ShowClusters } from "./models";

export const Side = labelize(["unspecified", "left", "right", "using"]);
export const reverseSide = (side) =>
  side === Side.left
    ? Side.right
    : side === Side.right
    ? Side.left
    : side === Side.using
    ? Side.using
    : Side[Side.rev(Side[side])];
Side.rev = reverseSide;

const flipLabels = (obj = empty.object, showRequested) => {
  const show = showRequested ?? obj.show;
  if (showRequested !== Side.right) {
    return { ...obj, show };
  }
  const { label, labelSpec, ...rest } = obj;
  return { ...rest, label: labelSpec, labelSpec: label, show };
};

/** Signature [relationTypeShape] -> relationFilterByAliasShape -> relationSideShape? */
export const relationSideByAlias =
  (types) =>
  ({ alias: typeAlias, side, show, ...other }) => {
    const type = types.alias?.[typeAlias];
    if (!type || (type.symmetrically && side !== Side.left)) {
      return undefined;
    }

    const {
      id,
      alias,
      canExist,
      mayAdd,
      mayAddToBieb,
      mayDelete,
      needsCluster,
      pagetype,
      rightShow,
      ...links
    } = type;
    return {
      ...other,
      id,
      alias,
      side,
      show,
      canExist,
      mayAdd,
      mayAddToBieb,
      mayDelete,
      needsCluster,
      metaPagetype: pagetype,
      rollupPagetype:
        other.showClusters === ShowClusters.rollup
          ? type[Side[Side.rev(side)]].pagetype
          : undefined,
      rightShow,
      ...flipLabels(links[Side[side]], show),
    };
  };

const projectStatus = ({ item: { status: { alias } = empty.object } }) =>
  alias === "lopend" ? 0 : alias === "in voorbereiding" ? 1 : 2;

const defaultSorts = Object.freeze(["position", "item.label"]);
const projectSorts = Object.freeze([projectStatus, ...defaultSorts]);

/** Signature [relationShape] -> relationSideShape? -> [relationShape] */
export const filterRelationsByRelationSide = (relationSide) =>
  flow(
    arrayFilterMap((relation = empty.object) => {
      const {
        id,
        relationTypeId,
        side,
        using,
        meta,
        mutation,
        leftPosition,
        rightPosition,
        usingPosition,
        ...links
      } = relation;
      if (
        !relationSide ||
        relationTypeId !== relationSide.id ||
        side !== relationSide.side
      ) {
        return undefined;
      }

      const link = (toSide) =>
        toSide === Side.using ? using : [links[Side[toSide]]];

      const primarySide =
        side === Side.using
          ? relationSide.show || Side.left
          : relationSide.show || Side.rev(side);
      const primaryLink = link(primarySide)[0];
      if (!primaryLink) {
        return undefined;
      }

      const secondarySide =
        primarySide === Side.using
          ? Side.rev(side)
          : side === Side.using
          ? Side.rev(primarySide)
          : Side.using;
      const secondaryLink = link(secondarySide);

      const position = (toSide) => {
        switch (toSide) {
          case Side.left:
            return leftPosition;
          case Side.right:
            return rightPosition;
          case Side.using:
            return usingPosition;
          default:
            return undefined;
        }
      };

      return {
        id,
        pageClusterId: link(side)[0].pageClusterId,
        item: primaryLink,
        meta,
        using: secondaryLink,
        mutation,
        relation,
        position: position(side) ?? 0,
      };
    }),
    sortBy(relationSide.sorts || defaultSorts)
  );

/** Signature [relationShape] -> string? -> [relationShape] */
export const filterRelationsByPagetype = (pagetype) =>
  flow(
    arrayFlatMap((relation = empty.object) => {
      const { id, meta, mutation } = relation;
      return flown(
        [relation.left, relation.right],
        filter((link) => link.type === pagetype),
        map(({ pageClusterId, pageClusterLabel, ...item }) => ({
          id,
          item,
          meta,
          using: empty.array,
          mutation,
          relation,
        }))
      );
    }),
    groupBy((relation) => relation.item.itemId),
    values,
    map(head),
    sortBy(defaultSorts)
  );

export const relationRequired = (rule) => rule % 2 === 1;
export const relationMultiple = (rule) => rule !== 1 && rule !== 2;

const addMiniSaars =
  (miniSaars = emptyLookup("variantId")) =>
  (relation) => {
    let result = relation;

    if (result.item && result.item.variantId) {
      result = {
        ...result,
        item: {
          ...result.item,
          miniSaar: miniSaars.variantId[result.item.variantId],
        },
      };
    }

    if (has("using[0].variantId")(result)) {
      result = {
        ...result,
        using: [
          {
            ...result.using[0],
            miniSaar: miniSaars.variantId[result.using[0].variantId],
          },
        ],
      };
    }

    return result;
  };

/* Items with a `miniSaar` property and no `type.scope` property need to be removed, while not editing */
const removeOtherMiniSaars = (edit, type) =>
  reject(
    ({ item: { miniSaar } = empty.object }) =>
      !edit &&
      !(type.scope > 0) &&
      typeof miniSaar === "object" &&
      miniSaar.alias !== "iznet"
  );

/** Signature [relationShape] -> relationSideShape? -> relationDisplayShape? */
export const relationDisplayByRelationSide =
  (
    relations,
    edit,
    loading,
    prohibitEdit,
    hideEmpty,
    scope,
    authorizationMask,
    miniSaars = emptyLookup("variantId")
  ) =>
  (relationSide) => {
    if (!relationSide) {
      return undefined;
    }

    const links = flown(
      relations,
      relationSide.showClusters === ShowClusters.rollup
        ? filterRelationsByPagetype(relationSide.rollupPagetype)
        : filterRelationsByRelationSide(relationSide),
      map(addMiniSaars(miniSaars)),
      removeOtherMiniSaars(edit, relationSide)
    );
    const { canExist } = relationSide;
    const mayAdd =
      (authorizationMask?.mayAdd ?? true) &&
      relationSide.showClusters !== ShowClusters.rollup &&
      (scopeBieb(scope) ? relationSide.mayAddToBieb : relationSide.mayAdd);
    const mayEdit =
      (authorizationMask?.mayEdit ?? true) &&
      relationSide.showClusters !== ShowClusters.rollup &&
      (relationSide.mayEdit ?? relationSide.mayDelete);
    const mayDelete =
      (authorizationMask?.mayDelete ?? true) &&
      relationSide.showClusters !== ShowClusters.rollup &&
      relationSide.mayDelete;

    if (
      links.length === 0 &&
      ((relationSide.scope && relationSide.scope !== scope) ||
        !canExist ||
        prohibitEdit ||
        ((hideEmpty || !(edit && mayAdd)) &&
          (loading || !relationRequired(relationSide.rule))))
    ) {
      return undefined;
    }

    return { ...relationSide, mayAdd, mayEdit, mayDelete, links };
  };

export const emptyType = Object.freeze({
  left: empty.object,
  right: empty.object,
  using: empty.object,
});

export const projectType = (relation, maxLength = relation.maxLength) => ({
  sorts: projectSorts,
  maxLength,
  ...relation,
});

const autoApplyProjectTypeFromJson = memoize((json) => {
  const relation = JSON.parse(json);
  const { alias, side } = relation;
  if (alias && alias.indexOf("project_") === 0 && side === Side.right) {
    return projectType(relation, alias.indexOf("persoon") >= 0 ? 4 : undefined);
  }

  if (alias && alias.indexOf("_project") > 0 && side === Side.left) {
    return projectType(relation, alias.indexOf("persoon") >= 0 ? 4 : undefined);
  }

  return relation;
});

const autoApplyProjectType = (relation) =>
  has("sorts")(relation)
    ? relation
    : flown(relation, JSON.stringify, autoApplyProjectTypeFromJson);

const regroupByLabel = flow(
  groupBy(({ id, needsCluster, label, labelSpec, show }) =>
    needsCluster ? id : show === Side.using ? labelSpec : label
  ),
  values,
  map((list) => {
    const first = head(list);
    const label = first.show === Side.using ? first.labelSpec : first.label;
    return { ...first, label, links: flown(list, map("links"), flatten) };
  })
);

const raamwerkGgvTypFilter = (field) =>
  typeof field === "object" &&
  field?.iproxType === "field" &&
  field.isEmpty === false &&
  field.definition.dataTypeCode === 274;

const asRelationType = ([relationTypeName, raamwerkItems], idx) => {
  const linksWithFilteredRaamwerkItems = flown(
    map("link")(raamwerkItems),
    orderBy(["item.label"], ["asc"]),
    map((link) => ({
      ...link,
      meta: {
        ...link.meta,
        page: {
          ...link.meta.page,
          ...flown(
            link.meta?.page,
            entries,
            filter(([_, field]) => raamwerkGgvTypFilter(field)),
            map(([name, field]) => ({
              [name]: {
                ...field,
                value: {
                  items: filter(({ item: { alias } = empty.object }) =>
                    endsWith(relationTypeName)(alias)
                  )(field.value.items),
                },
              },
            })),
            head
          ),
        },
      },
    }))
  );

  return {
    alias: relationTypeName,
    canExist: true,
    id: -1 * idx,
    label: relationTypeName,
    leftShow: 1,
    links: linksWithFilteredRaamwerkItems,
    metaPagetype: "relatie_praktijksituatie",
    pagetype: "praktijksituatie",
    side: Side.left,
  };
};

export const regroupPratijksituaties = (types) => {
  const first = head(types);
  if (!first || first.leftShow !== 1) {
    return types;
  }

  return flown(
    types,
    flatMap("links"),
    map((link) =>
      flown(
        link.meta?.page,
        values,
        filter(raamwerkGgvTypFilter),
        flatMap("value.items"),
        filter(({ item }) => item.alias?.includes(">")),
        map((item) => ({ ...item, link }))
      )
    ),
    flatten,
    groupBy("item.value"),
    entries,
    mapWithIndex(asRelationType),
    orderBy(["label"], ["asc"])
  );
};

export const isLeftShowGrouped = ({ alias = "", side }) =>
  alias.startsWith("praktijksituatie_") && side === Side.left;

export const shownRelations = (
  relations,
  types,
  {
    edit = false,
    loading = false,
    prohibitEdit = false,
    hideEmpty = false,
    scope = 0,
    authorizationMask,
    miniSaars = emptyLookup("variantId"),
  } = empty.object
) => {
  return (typeSelection, tabName) => {
    return flown(
      typeSelection,
      map(
        flow(
          autoApplyProjectType,
          relationSideByAlias(types),
          relationDisplayByRelationSide(
            relations,
            edit,
            loading,
            prohibitEdit,
            hideEmpty,
            scope,
            authorizationMask,
            miniSaars
          )
        )
      ),
      compact,
      edit && tabName
        ? identity
        : arrayFilterMap((type) => {
            const links = type.links.filter((relation) => {
              if (relation.relation.side !== Side.left) {
                return true;
              }

              const aliases = flown(
                relation.meta?.page,
                values,
                filter(
                  (field) =>
                    typeof field === "object" &&
                    field?.iproxType === "field" &&
                    field.isEmpty === false &&
                    field.definition.dataTypeCode === 274
                ),
                flatMap((field) =>
                  field.value.items.map((entry) => entry.item)
                ),
                flatMap((item) => [
                  item.value,
                  item.alias,
                  (item.alias ?? item.value).replace(/>.*/, ""),
                ])
              );
              return (
                !isNonEmptyArray(aliases) ||
                (tabName
                  ? includes(tabName)(aliases)
                  : some((a) => a.includes(">"))(aliases))
              );
            });
            return links.length === 0 && hideEmpty
              ? undefined
              : {
                  ...type,
                  links,
                  leftShow: tabName || !isLeftShowGrouped(type) ? 0 : 1,
                };
          }),
      compact,
      regroupByLabel,
      regroupPratijksituaties
    );
  };
};
