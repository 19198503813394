/* eslint  react/prop-types: off */
import empty from "empty";
import PropTypes from "prop-types";
import React from "react";
import {
  compose,
  defaultProps,
  setDisplayName,
  setPropTypes,
  withHandlers,
} from "recompose";

import { propTypes, withDefaultPageActions } from "./ggvtyp";

/**
 * Gegevenstype 'Tekstvlak' (21)
 */
const Tekstvlak = ({
  value,
  className,
  pageActions: { edit },
  field: { definition: { explanation, valuePlaceholder } = empty.object },
  handleChange,
}) =>
  edit ? (
    [
      explanation && (
        <div className="explanation" key="explanation">
          {explanation}
        </div>
      ),
      <textarea
        key="textarea"
        className={className}
        onChange={handleChange}
        value={value}
        rows={value && value.indexOf("\n") >= 0 ? value.split("\n").length : 2}
        placeholder={valuePlaceholder}
      />,
    ]
  ) : (
    <pre className={className}>{value}</pre>
  );

const enhance = compose(
  setDisplayName("Tekstvlak"),
  setPropTypes({
    ...propTypes,
    field: PropTypes.shape({
      value: PropTypes.string,
    }).isRequired,
  }),
  defaultProps({
    className: "tekstvlak",
  }),
  withDefaultPageActions(),
  withHandlers({
    handleChange:
      ({ setValue }) =>
      ({ target: { value } }) =>
        setValue({ value }),
  })
);

export default enhance(Tekstvlak);
