import empty from "empty";
import {
  entries,
  filter,
  groupBy,
  last,
  map,
  some,
  sortBy,
  values,
} from "lodash/fp";
import IconButton from "material-ui/IconButton";
import { List, ListItem } from "material-ui/List";
import Paper from "material-ui/Paper";
import PropTypes from "prop-types";
import React, { Fragment } from "react";

import {
  itemShape,
  pageActionsShape,
  relationShape,
  structureShape,
} from "../../../business/prop-types";
import { flown } from "../../../lodash";
import Field from "../../content/field";
import { isVisibleField } from "../../content/query";
import ClusterCard from "../../material/clusterCard";
import { Relation } from "../../relation";
import icons from "../icons";

export const KerngegevenList = ({
  list,
  label,
  subLabel,
  ...relationProps
}) => (
  <div className="relations">
    <List>
      {!subLabel && label && (
        <h3 style={{ fontWeight: 500, marginBottom: 0 }}>{label}</h3>
      )}
      {subLabel && label && (
        <h4 style={{ fontWeight: 500, marginBottom: 0 }}>{label}</h4>
      )}
      {flown(
        list,
        sortBy("right.pageClusterLabel"),
        map(({ id, right }) => (
          <Relation
            key={id}
            id={id}
            item={right}
            Wrap={ListItem}
            labelRender={({ label, pageClusterLabel }) =>
              pageClusterLabel || label
            }
            {...relationProps}
          />
        ))
      )}
    </List>
  </div>
);
KerngegevenList.propTypes = {
  label: PropTypes.string,
  subLabel: PropTypes.bool,
  list: PropTypes.arrayOf(relationShape.isRequired).isRequired,
};

const VerantwoordingBlok = ({
  biebStructures,
  pageActions,
  relations,
  removeRelation,
  removeAllRelations,
  verantwoording: item,
  verantwoording: {
    page: verantwoording,
    page: { pagetype } = empty.object,
  } = empty.object,
  gotoVerantwoordingen,
}) => {
  const hasVerantwoording = pagetype === "verantwoording";
  const { id: itemId, title: label = "geen" } = hasVerantwoording
    ? item
    : empty.object;
  return (
    <Paper style={{ marginBottom: 16 }}>
      <ClusterCard
        titlePrefix="Verantwoording: "
        title={label}
        titleSuffix={
          itemId &&
          !pageActions.edit && (
            <div className="icon-button-inline">
              <IconButton
                title={label}
                onClick={(e) => {
                  e.stopPropagation();
                  gotoVerantwoordingen(last(relations[0].using));
                }}
              >
                <icons.verantwoording />
              </IconButton>
            </div>
          )
        }
        expandable
        onDelete={itemId && pageActions.edit ? removeAllRelations : undefined}
        onTitleUpdate={
          itemId && pageActions.edit
            ? (value) => pageActions.titleUpdate({ itemId, value })
            : undefined
        }
      >
        {hasVerantwoording &&
          flown(
            verantwoording,
            entries,
            filter(([_, value]) => isVisibleField(value, pageActions)),
            map(([key, field]) => (
              <Field
                key={key}
                field={field}
                itemId={itemId}
                pageActions={pageActions}
              />
            ))
          )}
        {flown(
          relations,
          groupBy("right.itemId"),
          values,
          sortBy("[0].right.label"),
          map((applicatie) => (
            <Fragment key={applicatie[0].right.itemId}>
              <h3
                style={{ fontWeight: 500, marginBottom: 0 }}
              >{`Gegevens van ${applicatie[0].right.type}: ${applicatie[0].right.label}`}</h3>
              {flown(
                applicatie,
                groupBy("using[0].itemId"),
                values,
                sortBy("[0].using[0].label"),
                map((entiteit) => {
                  const first = entiteit[0].using[0] ?? empty.object;
                  return (
                    <KerngegevenList
                      key={first.itemId ?? 0}
                      subLabel
                      label={
                        first.type === "entiteit"
                          ? `Entiteit: ${first.label}`
                          : undefined
                      }
                      list={entiteit}
                      insideBieb={some({ id: first.structureId ?? 0 })(
                        biebStructures
                      )}
                      removeRelation={(id) =>
                        removeRelation(id, applicatie[0].right.itemId, itemId)
                      }
                      edit={pageActions.edit}
                      clusterFirst
                      showHeader
                    />
                  );
                })
              )}
            </Fragment>
          ))
        )}
      </ClusterCard>
    </Paper>
  );
};

VerantwoordingBlok.propTypes = {
  biebStructures: PropTypes.arrayOf(structureShape.isRequired).isRequired,
  pageActions: pageActionsShape.isRequired,
  relations: PropTypes.arrayOf(relationShape.isRequired).isRequired,
  removeRelation: PropTypes.func.isRequired,
  removeAllRelations: PropTypes.func.isRequired,
  verantwoording: itemShape,
  gotoVerantwoordingen: PropTypes.func.isRequired,
};

export default VerantwoordingBlok;
