import { CircularProgress } from "material-ui";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setPreviewAction } from "../../actions/ui";
import { requireVerantwoordingen } from "../../actions/verantwoordingen";
import { AddVerantwoording } from "../../containers/relations/addKerngegevenRelation";
import { flown } from "../../lodash";
import VerantwoordingStep from "../relations/verantwoordingStep";

interface Props {
  itemId: number | undefined;

  onChange(itemId: number): void;
}

const VerantwoordingPicker = ({ itemId, onChange }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const [adding, setAdd] = useState(false);
  const onAdd = useCallback(() => {
    setAdd(true);
  }, []);
  const handleChange = useCallback(
    (value: string): void => flown(value, Number, onChange),
    [onChange]
  );
  const handleAdd = useCallback(
    (itemId: number | false): void => {
      setAdd(false);
      if (itemId) {
        onChange(itemId);
      }
    },
    [onChange]
  );
  const setPreview = useCallback(
    (itemId: number): void => {
      dispatch(setPreviewAction(itemId));
    },
    [dispatch]
  );
  const allVerantwoordingen: any[] | undefined = useSelector(
    (state: any) => state.data.verantwoordingen.list
  );
  useEffect((): void => {
    dispatch(requireVerantwoordingen());
  }, [dispatch]);
  const verantwoordingen = useMemo((): any[] | undefined => {
    return allVerantwoordingen && allVerantwoordingen.filter((v) => !v.bieb);
  }, [allVerantwoordingen]);

  return verantwoordingen ? (
    <>
      <VerantwoordingStep
        onAdd={onAdd}
        setPreview={setPreview}
        verantwoordingen={verantwoordingen}
        verantwoordingId={itemId}
        onChange={handleChange}
      />
      {adding && <AddVerantwoording setAdd={handleAdd} />}
    </>
  ) : (
    <CircularProgress />
  );
};

export default VerantwoordingPicker;
