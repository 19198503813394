import FlatButton from "material-ui/FlatButton";
import * as React from "react";
import { Action } from "redux";

import Dialog from "../containers/dialog";

interface ConfirmProps {
  title?: string;
  stage: {
    message: string;
    extra: () => JSX.Element;
    action: Action<string>;
  };
  onCancel: () => void;
  onConfirm: (action: Action<string>) => void;
}

const Confirm = ({
  title = "Weet je het zeker?",
  stage: { message, extra, action },
  onCancel,
  onConfirm,
}: ConfirmProps): JSX.Element => {
  const confirmCallback = React.useCallback(
    (): void => onConfirm(action),
    [onConfirm, action]
  );
  return (
    <Dialog
      title={title}
      modal
      open={typeof message === "string"}
      actionsContainerClassName="dialog-buttons"
      className="confirm-dialog"
      actions={[
        <FlatButton
          key="cancel"
          secondary
          label="Annuleren"
          onClick={onCancel}
        />,
        <FlatButton key="ok" label="OK" primary onClick={confirmCallback} />,
      ]}
    >
      <p>{`${message}`}</p>
      {typeof extra === "function" && extra()}
    </Dialog>
  );
};

export default Confirm;
