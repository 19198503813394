import {} from "../../components/relations/RelationClusterList";

import empty from "empty";
import React from "react";
import { compose, pure, setDisplayName } from "recompose";
import { createSelector } from "reselect";

import { init, remove } from "../../actions/relation";
import { ShowClusters, ShowSide } from "../../business/models";
import { Side, shownRelations } from "../../business/relations";
import RelationsComponent from "../../components/relations";
import { legoblokStructuresSelector } from "../../selectors";
import { typesSelector } from "../../selectors/page";
import { dispatchWrap, isNonEmptyArray } from "../../utils";
import connect from "../connect";

const getTypes = createSelector(typesSelector, (types) => ({ types }));

const getActions = (dispatch) => ({
  initAddRelation: dispatchWrap(init, dispatch),
  removeRelation: dispatchWrap(remove, dispatch),
});

/**
 * Compose Higher-order component
 */
const Relations = compose(
  setDisplayName("Relations"),
  connect(
    (state, props) => ({
      ...getTypes(state, props),
      legoblokStructures: legoblokStructuresSelector(state, props),
    }),
    getActions
  ),
  pure
)(RelationsComponent);

export default Relations;

export const getRelationsCreator =
  (itemId, relations, types, miniSaars, pageActions, options = empty.object) =>
  (...filterOrTabName) => {
    const [tabName, filter] =
      typeof filterOrTabName[0] === "string"
        ? [filterOrTabName[0], filterOrTabName.slice(1)]
        : [undefined, filterOrTabName];
    const shown = shownRelations(relations, types, { ...options, miniSaars })(
      filter,
      tabName
    );
    return (
      isNonEmptyArray(shown) && (
        <Relations
          key={`shown_${shown
            .map(
              ({
                id,
                side,
                show = Side.left,
                showSide = ShowSide.default,
                showClusters = ShowClusters.default,
              }) => `${id}_${side}_${show}_${showSide}_${showClusters}`
            )
            .join("-")}`}
          itemId={itemId}
          shown={shown}
          miniSaars={miniSaars}
          pageActions={pageActions}
          {...options}
        />
      )
    );
  };
