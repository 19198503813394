import empty from "empty";
import { compose, setDisplayName, withHandlers, withState } from "recompose";

import { requestPasswordAction } from "../actions/session";
import Forgot from "../components/forgot";
import { requestPasswordSelector } from "../selectors";
import connect from "./connect";

export default compose(
  setDisplayName("Forgot"),
  connect(requestPasswordSelector, (dispatch) => ({
    requestPassword: (emailaddress) =>
      dispatch(requestPasswordAction(emailaddress)),
  })),
  withState("emailaddress", "update", ""),
  withHandlers({
    handleChange:
      ({ update }) =>
      ({ target: { value = "" } = empty.object }) =>
        update(value),
    handleSubmit:
      ({ emailaddress, requestPassword }) =>
      (event) => {
        event.preventDefault();
        requestPassword(emailaddress);
      },
  })
)(Forgot);
