import {
  amber500,
  amber900,
  black,
  grey600,
  lightBlue500,
  lightGreen500,
  purple500,
} from "material-ui/styles/colors";
import ActionHistory from "material-ui/svg-icons/action/history";
import WarningIcon from "material-ui/svg-icons/alert/warning";
import LibraryBooks from "material-ui/svg-icons/av/library-books";
import ArchiveIcon from "material-ui/svg-icons/content/archive";
import React, { CSSProperties, PropsWithChildren } from "react";

import { Validity } from "../business/models";
import { pagetypeViewName } from "../pagetypes";
import icons, { MaterialSvgIcon } from "./pagetypes/icons";

const defaultStyle: CSSProperties = {
  height: "24px",
  width: "24px",
  display: "block",
  position: "absolute",
  top: "0px",
  margin: "12px",
  left: "4px",
};

const getIcon = (type: string): MaterialSvgIcon => {
  switch (true) {
    case type === "bieb":
      return LibraryBooks;
    case type === "version-history":
      return ActionHistory;
    default:
      return (icons[type] ?? icons.standaard) as MaterialSvgIcon;
  }
};

const PagetypeIcon = ({
  containerStyle,
  iconStyle,
  style,
  type,
  colorProperty,
  simpleIcon = false,
  insideBieb = false,
  biebIntern,
  validity,
  ...rest
}: {
  containerStyle?: CSSProperties;
  iconStyle?: CSSProperties;
  style?: CSSProperties;
  type?: string;
  color?: string;
  colorProperty?: string;
  simpleIcon?: boolean;
  insideBieb?: boolean;
  biebIntern?: string;
  validity?: Validity;
}) => {
  const typeName = pagetypeViewName(type);
  const CurrentIcon = getIcon(type ?? "empty");
  const currentStyle = containerStyle || { ...defaultStyle, ...style };

  const colorValue = (() => {
    switch (colorProperty) {
      case "nieuw":
        return insideBieb ? amber900 : purple500;
      case "extern":
        return insideBieb ? amber900 : lightBlue500;
      case "lopend":
        return lightGreen500;
      case "in voorbereiding":
      case "process-self":
        return amber500;
      default:
        return insideBieb || type === "bieb" ? amber900 : grey600;
    }
  })();

  const colorPropertyPostfix =
    colorProperty && colorProperty !== "process-self"
      ? ` - ${colorProperty}`
      : "";

  const validityPostfix =
    validity && validity !== "definitief" ? ` - ${validity}` : "";

  const biebPrefix = insideBieb ? "Saar Bieb - " : "";

  if (simpleIcon) {
    return <CurrentIcon color={colorValue} style={style} />;
  }

  return (
    <div
      title={
        biebIntern
          ? `${biebIntern}: ${typeName}`
          : `${biebPrefix}${typeName}${colorPropertyPostfix}${validityPostfix}`
      }
      style={
        currentStyle.position
          ? currentStyle
          : { ...currentStyle, position: "relative" }
      }
    >
      <CurrentIcon {...rest} color={colorValue} style={iconStyle} />
      {validity && <ValidityIcon validity={validity} />}
      {biebIntern && <ValidityIcon validity="concept" color={amber900} />}
    </div>
  );
};

const ValidityWrapper = ({ children }: PropsWithChildren<{}>) => (
  <div className="validity-wrapper">{children}</div>
);

const ValidityIcon = ({
  validity,
  color,
}: {
  validity: Validity;
  color?: string;
}) => {
  const defaultIconStyle: CSSProperties = {
    width: 16,
    height: 16,
  };

  switch (validity) {
    case "concept":
      return (
        <ValidityWrapper>
          <WarningIcon
            style={{ ...defaultIconStyle, color: color ?? amber500 }}
          />
        </ValidityWrapper>
      );
    case "archief":
      return (
        <ValidityWrapper>
          <ArchiveIcon style={{ ...defaultIconStyle, color: color ?? black }} />
        </ValidityWrapper>
      );
    case "definitief":
    default:
      return null;
  }
};

export default PagetypeIcon;
