import { loadingPresent } from "async-lifecycle";
import empty from "empty";
import { entries, eq, filter, flow, fromPairs, get } from "lodash/fp";
import {
  branch,
  compose,
  flattenProp,
  renderComponent,
  withHandlers,
  withState,
} from "recompose";
import { createSelector } from "reselect";

import { requireEntiteiten } from "../../actions/application";
import {
  addItemSilentAction,
  fieldDefinitionsByPagetypeAction,
} from "../../actions/cms";
import {
  clearVerantwoordingen,
  requireVerantwoordingen,
} from "../../actions/verantwoordingen";
import AddKerngegevenRelationComponent from "../../components/relations/addKerngegevenRelation";
import AddVerantwoordingDialog from "../../components/relations/addVerantwoording";
import { flown } from "../../lodash";
import { execOnChange } from "../../recompose.contrib";
import { dispatchWrap } from "../../utils";
import connect from "../connect";

const verantwoordingSelector = createSelector(
  [
    ({
      data: {
        fieldDefinitionsByPagetype: { verantwoording = empty.object },
      },
    }) => verantwoording,
  ],
  (verantwoording) => ({
    fields: flown(
      verantwoording.page,
      entries,
      filter(flow(get("[1].iproxType"), eq("field"))),
      fromPairs
    ),
    loading: !loadingPresent(verantwoording.loading),
  })
);

export const AddVerantwoording = compose(
  connect(verantwoordingSelector, (dispatch, { setAdd }) => ({
    requireDefinition: () =>
      dispatch(fieldDefinitionsByPagetypeAction("verantwoording")),
    onCancel: () => setAdd(false),
    onSave: ({ title: label, rootFields }) =>
      dispatch(
        addItemSilentAction(
          { label, pagetype: "verantwoording", rootFields },
          {
            callbackSuccess: (payload) => {
              dispatch(clearVerantwoordingen());
              dispatch(requireVerantwoordingen());
              setAdd(payload.itemId);
            },
          }
        )
      ),
  })),
  execOnChange("requireDefinition")
)(AddVerantwoordingDialog);

const AddKerngegevenRelationContainer = compose(
  connect(undefined, (dispatch, { setAdd }) => ({
    requireEntiteiten: dispatchWrap(requireEntiteiten, dispatch),
    requireVerantwoordingen: dispatchWrap(requireVerantwoordingen, dispatch),
    onAdd: () => setAdd(true),
  })),
  execOnChange("requireEntiteiten"),
  execOnChange("requireVerantwoordingen")
)(AddKerngegevenRelationComponent);

const AddKerngegevenRelation = compose(
  withState("state", "setState", { add: false, verantwoordingId: undefined }),
  withHandlers({
    setAdd:
      ({ setState }) =>
      (value) =>
        setState({
          add: typeof value === "boolean" && value,
          verantwoordingId: typeof value === "number" ? value : undefined,
        }),
  }),
  flattenProp("state"),
  branch(get("add"), renderComponent(AddVerantwoording))
)(AddKerngegevenRelationContainer);

export default AddKerngegevenRelation;
