import ArrowLeft from "material-ui/svg-icons/navigation/chevron-left";
import { ReactNode } from "react";
import { useSelector } from "react-redux";

import { SiteLink } from "../../../business/models";
import { selectResultSet } from "../../../business/store/resultset";
import { ItemLink } from "../../relation.itemlink";
import { PathLink } from "../../relation.pathlink";

interface Props {
  itemId: number;
}

const ResultSetNavigation = ({ itemId }: Props) => {
  const resultSet = useSelector(selectResultSet);
  const index = resultSet.items.findIndex((item) => item.itemId === itemId);

  if (index < 0 || resultSet.items.length < 1) {
    return null;
  }

  const prev = resultSet.items[index - 1];
  const next = resultSet.items[index + 1];
  return (
    <span className="resultset-navigation">
      <PathLink path={`page/${resultSet.itemId}/${resultSet.slug}`}>
        <ArrowLeft className="icon" />
        <span>{resultSet.title}</span>
      </PathLink>
      {
        <RelLink item={prev}>
          <span className="black">&lt;</span> Vorige
        </RelLink>
      }
      <span>
        {index + 1}/{resultSet.items.length}
      </span>
      {
        <RelLink item={next}>
          Volgende <span className="black">&gt;</span>
        </RelLink>
      }
    </span>
  );
};

const RelLink = ({
  item,
  children,
}: {
  item: SiteLink | undefined;
  children: ReactNode;
}) =>
  item ? <ItemLink item={item}>{children}</ItemLink> : <span>{children}</span>;

export default ResultSetNavigation;
