import { ListItem } from "material-ui/List";
import React, { ReactNode } from "react";

import PagetypeIcon from "../pagetypeIcon";
import { followPrimary } from "../relation.utils";

export interface RelationClusterListItemProps {
  primary: ReactNode;
  secondary: ReactNode[];
  numberOfSecondaryItems: number;
  onClick?: React.MouseEventHandler<{}>;
  iconType: string;
}

const RelationClusterListItem = ({
  primary,
  secondary,
  numberOfSecondaryItems,
  onClick,
  iconType,
}: RelationClusterListItemProps) => {
  return (
    <ListItem
      innerDivStyle={{ paddingLeft: 56 }}
      leftIcon={
        <PagetypeIcon
          type={iconType}
          containerStyle={undefined}
          iconStyle={undefined}
          style={undefined}
          colorProperty={undefined}
          insideBieb={undefined}
          simpleIcon
        />
      }
      primaryText={primary}
      secondaryText={secondary}
      secondaryTextLines={numberOfSecondaryItems > 1 ? 2 : 1}
      onClick={followPrimary(onClick)}
    />
  );
};

export default RelationClusterListItem;
