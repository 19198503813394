import { composeAction } from "async-lifecycle";
import empty from "empty";
import { filter, flow, map } from "lodash/fp";

import {
  getExpandedLandscape,
  getLandscape,
  getLandscapeBetween,
} from "../api";

export const requireLandscapeAction = (id, relations) =>
  composeAction({
    group: "LANDSCAPE",
    fire: (id, relations) => getLandscape(id, relations),
    args: [id, relations],
    key: "id",
    cachekey: "landscape",
  });

const getRelatedItemIdsOfType = (relations, pagetype) => {
  const leftIds = relations
    .filter(
      ({ right, left }) =>
        left.type === pagetype &&
        (right.type !== pagetype || left.structureId === right.structureId)
    )
    .map(({ left }) => left.itemId);
  const rightIds = relations
    .filter(
      ({ right, left }) =>
        right.type === pagetype &&
        (left.type !== pagetype || left.structureId === right.structureId)
    )
    .map(({ right }) => right.itemId);
  const ids = [...leftIds, ...rightIds];
  // Remove duplicates
  return ids.filter((item, index) => ids.indexOf(item) === index);
};

export const requireLandscapeBetweenAction = (id, relations) =>
  composeAction({
    group: "LANDSCAPE",
    fire: (id, relations) =>
      getLandscapeBetween(id, getRelatedItemIdsOfType(relations, "applicatie")),
    args: [id, relations],
    key: "id",
    cachekey: "landscape",
  });

export const requireEntityLandscapeBetweenAction = (id, relations) =>
  composeAction({
    group: "ENTITYLANDSCAPE",
    fire: (id, relations) =>
      getLandscapeBetween(
        id,
        getRelatedItemIdsOfType(relations, "entiteit"),
        "entiteit_entiteit"
      ),
    args: [id, relations],
    key: "id",
    cachekey: "entitylandscape",
  });
export const requireLandscapeForKoppelingAction = (id, relations) => {
  const filtered = [
    ...flow(
      filter(
        ({ right: { type, pageClusterId } = empty.object }) =>
          !pageClusterId && type === "applicatie"
      ),
      map(({ right }) => right.itemId)
    )(relations),
    ...flow(
      filter(
        ({ left: { type, pageClusterId } = empty.object }) =>
          !pageClusterId && type === "applicatie"
      ),
      map(({ left }) => left.itemId)
    )(relations),
  ];

  return composeAction({
    group: "LANDSCAPE",
    fire: getLandscapeBetween,
    args: [id, filtered],
    key: "id",
    cachekey: "landscape",
  });
};
export const requireExpandedLandscapeAction = (
  id,
  startingPoints,
  relationType
) =>
  composeAction({
    group: "EXPANDED_LANDSCAPE",
    fire: (id, startingPoints) =>
      getExpandedLandscape(id, startingPoints, relationType),
    args: [id, startingPoints],
    key: "id",
    cachekey: "expandedLandscape",
  });
