import empty from "empty";
import { has } from "lodash/fp";
import { combineReducers } from "redux";

import data from "./dataReducers";
import form from "./form";
import session from "./sessionReducers";
import ui from "./uiReducers";

const emptyErrors = Object.freeze({ errors: empty.array });

const error = (state = emptyErrors, { type, payload }) => {
  const { error: { status } = empty.object } = payload || empty.object;
  if (status === 418) {
    // Ignore
    return state;
  }

  switch (type) {
    case "EMPTY_ERROR":
      return { errors: state.errors.slice(1) };
    case "LOGIN_SUCCESS":
      return has("data.user.id")(payload) ||
        has("data.user.changePassword")(payload) ||
        has("data.possession")(payload)
        ? state
        : {
            errors: [
              ...state.errors,
              { status: has("data.possessionInvalid")(payload) ? 499 : 401 },
            ],
          };
    default:
      return payload?.error?.notify
        ? { errors: [...state.errors, { status: 0, ...payload.error }] }
        : state;
  }
};

const emptyStages = Object.freeze({ stages: empty.array });

const stage = (state = emptyStages, { type, payload }) => {
  switch (type) {
    case "STAGE_PUSH":
      return { stages: [...state.stages, payload] };
    case "STAGE_CANCEL":
    case "STAGE_CONFIRM":
      return { stages: state.stages.slice(1) };
    default:
      return state;
  }
};

const addReducers = (reducers) =>
  combineReducers({ ...reducers, data, error, form, session, stage, ui });
export default addReducers;
