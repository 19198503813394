import { filter, map, some } from "lodash/fp";
import Checkbox from "material-ui/Checkbox";
import Subheader from "material-ui/Subheader";
import PropTypes from "prop-types";
import React, { Fragment } from "react";

import { entiteitShape, selectedGegevenShape } from "../../business/prop-types";
import { flown } from "../../lodash";

const SelectKerngegevens = ({
  entiteiten,
  onGegevenToggle,
  currentGegevens,
  selectedGegevens,
}) =>
  flown(
    entiteiten,
    filter(({ gegevens }) => gegevens.length > 0),
    map(({ id: entiteitId, naam, gegevens }) => (
      <Fragment key={entiteitId}>
        <Subheader style={{ paddingLeft: 0 }}>
          Kerngegevens van entiteit: {naam}
        </Subheader>
        {flown(
          gegevens,
          map(({ id: gegevenId, naam }) => (
            <Checkbox
              className="checkbox-level1"
              key={gegevenId}
              value={{ entiteitId, gegevenId }}
              label={naam}
              checked={
                some({ entiteitId, gegevenId })(currentGegevens) ||
                some({ entiteitId, gegevenId })(selectedGegevens)
              }
              disabled={some({ entiteitId, gegevenId })(currentGegevens)}
              onCheck={(_, isInputChecked) =>
                onGegevenToggle({ entiteitId, gegevenId }, isInputChecked)
              }
            />
          ))
        )}
      </Fragment>
    ))
  );
SelectKerngegevens.propTypes = Object.freeze({
  entiteiten: PropTypes.arrayOf(entiteitShape).isRequired,
  onGegevenToggle: PropTypes.func.isRequired,
  currentGegevens: PropTypes.arrayOf(selectedGegevenShape.isRequired)
    .isRequired,
  selectedGegevens: PropTypes.arrayOf(selectedGegevenShape.isRequired)
    .isRequired,
});

export default SelectKerngegevens;
