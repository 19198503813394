import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { createHashHistory } from "history";

import { user } from "../api/auth";

const hashHistory = createHashHistory();

let reactPlugin: ReactPlugin | null = null;
let appInsights: ApplicationInsights | null = null;

export interface TelemetryServiceResult {
  reactPlugin: ReactPlugin | null;
  appInsights: ApplicationInsights | null;
  initialize: (instrumentationKey: string) => void;
}

/**
 * Create the App Insights Telemetry Service
 * @return {{reactPlugin: ReactPlugin, appInsights: Object, initialize: Function}} - Object
 */
const createTelemetryService = (): TelemetryServiceResult => {
  /**
   * Initialize the Application Insights class
   * @param {string} instrumentationKey - Application Insights Instrumentation Key
   * @return {void}
   */
  const initialize = (instrumentationKey: string): void => {
    if (!hashHistory) {
      throw new Error("Could not initialize Telemetry Service");
    }

    if (!instrumentationKey) {
      throw new Error("Instrumentation key not provided");
    }

    reactPlugin = new ReactPlugin();

    appInsights = new ApplicationInsights({
      config: {
        instrumentationKey,
        disableFetchTracking: false,
        isBrowserLinkTrackingEnabled: true,
        isCookieUseDisabled: true,
        extensions: [reactPlugin],
        extensionConfig: {
          [reactPlugin.identifier]: {
            history: hashHistory,
          },
        },
      },
    });

    appInsights.addTelemetryInitializer(({ tags }) => {
      if (tags) {
        tags["ai.user.id"] = user() || "anonymous";
      }
    });
    appInsights.loadAppInsights();
  };

  return { reactPlugin, appInsights, initialize };
};

export const ai = createTelemetryService();

export const getAppInsights = () => appInsights;

export const emptyInstrumentationKey = "00000000-0000-0000-0000-000000000000";
