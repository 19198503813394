import { compose, setDisplayName } from "recompose";

import Picklist from "../../components/ggvtyp/picklist";
import { selectionList } from "../../selectors/definition";
import connect from "../connect";

export default compose(
  setDisplayName("Picklist"),
  connect(selectionList)
)(Picklist);
