import RefreshIndicator from "material-ui/RefreshIndicator";
import { withProps } from "recompose";

const PageRefreshIndicator = withProps({
  size: 38,
  left: 0,
  top: 0,
  status: "loading",
  style: { position: "relative" },
})(RefreshIndicator);

export default PageRefreshIndicator;
