import empty from "empty";
import PropTypes from "prop-types";
import { withHandlers, withProps } from "recompose";

import { PageActions } from "../../actions/page";
import { isEmpty } from "../../utils";

const dummyPageActions = Object.freeze(
  new PageActions(empty.func, 9999999, {
    busy: false,
    dirty: false,
    edit: false,
    edited: false,
  })
);

const defaultPageActions = (props) => ({
  value:
    props.value === null
      ? undefined
      : props.value === undefined && !isEmpty(props.field.value)
      ? props.field.value
      : props.value,
  pageActions:
    props.pageActions instanceof PageActions
      ? props.pageActions
      : { ...dummyPageActions, ...props.pageActions },
});

const defaultPageHandlers = {
  setValue: ({ setValue }) => setValue || empty.func,
};

export const withDefaultPageActions = empty.functionThatReturns(
  withProps(defaultPageActions),
  withHandlers(defaultPageHandlers)
);

export const getValue = (props) => {
  const {
    value,
    field: { value: fieldValue },
  } = props;
  return value === undefined && !isEmpty(fieldValue) ? fieldValue : value;
};

const propTypes = {
  className: PropTypes.string.isRequired,
  field: PropTypes.shape({
    value: PropTypes.any,
  }).isRequired,
};

export { propTypes };
