import { RaisedButton } from "material-ui";
import { amber900 as biebColor } from "material-ui/styles/colors";
import CompareArrows from "material-ui/svg-icons/action/compare-arrows";
import NewReleases from "material-ui/svg-icons/av/new-releases";
import moment, { Moment } from "moment";
import { useCallback } from "react";

import { Relation } from "../../business/models";

export interface UpdateRelatedButtonProps {
  onClick: (
    biebItemId: number,
    localItemId: number,
    hasUpdate: boolean
  ) => void;
  biebRelation: Relation;
  lastPublished: Moment;
}

const UpdateRelatedButton = ({
  onClick,
  biebRelation,
  lastPublished,
}: UpdateRelatedButtonProps) => {
  const hasUpdate = lastPublished.isSameOrAfter(
    moment(biebRelation.modificationDateTime)
  );
  const handleClick = useCallback(() => {
    onClick(biebRelation.left.itemId, biebRelation.right.itemId, hasUpdate);
  }, [biebRelation, onClick, hasUpdate]);

  return (
    biebRelation.left.type !== "kosppeling" && (
      <RaisedButton
        label="Vergelijken"
        labelPosition="before"
        labelColor={hasUpdate ? "white" : undefined}
        backgroundColor={hasUpdate ? biebColor : undefined}
        onClick={handleClick}
        icon={hasUpdate ? <NewReleases /> : <CompareArrows />}
      />
    )
  );
};

export default UpdateRelatedButton;
