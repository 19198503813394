import empty from "empty";
import { filter, flow, last, map } from "lodash/fp";
import PropTypes from "prop-types";
import React from "react";

import { consolidatePlug } from "../../business/contentPlug";
import { contentPlugShape, pageActionsShape } from "../../business/prop-types";
import { arrayFlatMap, expandPath } from "../../utils";
import ClusterCard from "../material/clusterCard";
import Cluster from "./cluster";
import Field from "./field";
import { isField, isVisible } from "./query";

const Content = (
  {
    path,
    showCard,
    omitAddButton,
    omitTitle,
    relations,
    types,
    root: objRoot,
    pagetypes: objPagetypes = empty.array,
    pageActions,
    ...plug
  },
  root = objRoot,
  pagetypes = objPagetypes
) => {
  const [content, cluster] = expandPath(root, path);
  const fullPlug = consolidatePlug(plug);
  const alias = last(path.split("."));
  return isVisible(content, pageActions) && fullPlug.show(content) ? (
    isField(content) ? (
      <ClusterCard title={omitTitle !== true ? content.names[0] : undefined}>
        <Field
          alias={alias}
          field={content}
          name={fullPlug.fieldName(content)}
          pagetypes={pagetypes}
          pageActions={pageActions}
          plug={fullPlug}
          clusterId={cluster ? cluster.clusterId : undefined}
        />
      </ClusterCard>
    ) : (
      <Cluster
        alias={alias}
        key={path}
        cluster={{
          ...content,
          clusters: flow(
            filter(fullPlug.clusterFilter),
            map(fullPlug.clusterAlter)
          )(content.clusters),
        }}
        showCard={showCard}
        omitAddButton={omitAddButton}
        omitTitle={omitTitle}
        pagetypes={pagetypes}
        pageActions={pageActions}
        plug={fullPlug}
        clusterId={cluster ? cluster.clusterId : undefined}
        relations={relations}
        types={types}
      />
    )
  ) : null;
};

Content.propTypes = Object.freeze({
  // eslint-disable-next-line react/forbid-prop-types
  root: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
  omitTitle: PropTypes.bool,
  showCard: PropTypes.bool,
  pagetypes: PropTypes.arrayOf(PropTypes.string.isRequired),
  pageActions: pageActionsShape.isRequired,
  plug: contentPlugShape,
});

export default Content;

export const getContent =
  (root, pagetypes = empty.array, defaults = empty.object) =>
  (...zones) =>
    arrayFlatMap((options) =>
      Content({ ...defaults, showCard: true, ...options }, root, pagetypes)
    )(zones);
