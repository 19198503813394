import empty from "empty";
import { every, filter, find, flow, get, some, values } from "lodash/fp";
import { withRouter } from "react-router";
import { branch, compose, renderNothing, setDisplayName } from "recompose";

import ClusterDialog from "../components/clusterDialog";
import {
  isCluster,
  isField,
  isVisibleField,
} from "../components/content/query";
import { flown } from "../lodash";
import { typesSelector } from "../selectors/page";
import connect from "./connect";

const hasAllRequiredShallow = (template, staged, pageActions) =>
  flow(
    values,
    filter(
      (field) =>
        isVisibleField(field, pageActions) &&
        field.definition.required &&
        field.isEmpty
    ),
    every(({ definition: { name } }) => find({ name })(staged) !== undefined)
  )(template);

const hasAllRequiredDeep = (template, staged, pageActions) => {
  const shallow = hasAllRequiredShallow(template, staged, pageActions);
  const deep = flow(
    values,
    filter(isCluster),
    every((cluster) => hasAllRequiredShallow(cluster, staged, pageActions))
  )(template);
  return shallow && deep;
};

const hasAnyLinkShallow = (template, staged) =>
  flow(
    values,
    filter((field) => isField(field) && field.definition.dataTypeCode === 18),
    some(({ definition: { name } }) => find({ name })(staged) !== undefined)
  )(template);

const hasAnyLinkDeep = (template, staged) =>
  hasAnyLinkShallow(template, staged) ||
  flown(
    template,
    values,
    filter(isCluster),
    some((cluster) => hasAnyLinkShallow(cluster, staged))
  );

export default compose(
  setDisplayName("ClusterDialog"),
  withRouter,
  connect((state, { pageActions: { pageId }, pageActions, location }) => {
    const {
      data: {
        pages: {
          [pageId]: { page: { pagetype } = empty.object },
        },
      },
      form: {
        addCluster: {
          loading,
          templates: [{ name, template } = empty.object] = empty.array,
        } = empty.object,
        page: { [pageId]: { staged = empty.array } = empty.object },
      },
    } = state;
    const itmRelTypIdt = get("alias.kerngegeven_applicatieveld.id")(
      typesSelector(state, location)
    );
    return {
      loading,
      name,
      template,
      pagetype,
      itmRelTypIdt,
      open:
        template !== undefined &&
        some(
          ({ $type: type = "", ready = false }) =>
            /ClusterAddAction/.test(type) && !ready
        )(staged),
      valid:
        staged.length > 1 &&
        (hasAnyLinkDeep(template, staged) ||
          hasAllRequiredDeep(template, staged, pageActions)),
    };
  }),
  branch(({ open }) => !open, renderNothing)
)(ClusterDialog);
