import empty from "empty";
import { keyBy, range, uniq } from "lodash/fp";

if (process.env.NODE_ENV !== "test") {
  // eslint-disable-next-line global-require
  require("../tinymce/mediawidget");
}

const featureConfig = keyBy("id")([
  {
    id: 0,
    inline: true,
    fix_list_elements: true,
    browser_spellcheck: true,
    object_resizing: false,
    menubar: false,
    // language: "nl",
    plugins: ["paste", "searchreplace"],
    formatbar: ["formatselect", "removeformat", "undo redo"],
    funcbar: ["pastetext", "searchreplace"],
    block_formats: [{ title: `Paragraph`, format: "p" }],
    style_formats: "",
    element_format: "xhtml",
    entity_encoding: "numeric",
  },
  ...range(1, 6).map((id) => ({
    id,
    block_formats: [{ title: `Header ${id}`, format: `h${id}` }],
  })),
  {
    id: 7,
    formatbar: ["bold italic"],
  },
  {
    id: 10,
    plugins: ["image", "imagetools"],
    automatic_uploads: true,
    paste_data_images: true,
    file_picker_callback: (callback, value, meta) => {
      if (meta.filetype === "image") {
        const input = document.createElement("input");
        input.setAttribute("type", "file");
        input.setAttribute("accept", "image/*");
        input.onchange = function onchange() {
          const file = this.files[0];
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            const id = `blobid-${new Date().getTime()}`;
            const blobCache =
              global.tinymce.activeEditor.editorUpload.blobCache;
            const base64 = reader.result.split(",")[1];
            const blobInfo = blobCache.create(id, file, base64);
            blobCache.add(blobInfo);
            return callback(blobInfo.blobUri(), {
              alt: file.name,
              title: file.name,
            });
          };
        };

        input.click();
      }
    },
    file_picker_types: "image",
    funcbar: ["image"],
  },
  {
    id: 11,
    plugins: ["autolink", "link"],
    link_assume_external_targets: true,
    target_list: false,
    funcbar: ["link unlink"],
  },
  {
    id: 14,
    plugins: ["lists"],
    funcbar: ["bullist numlist"],
  },
  {
    id: 16,
    plugins: ["table"],
    table_advtab: false,
    table_row_advtab: false,
    table_cell_advtab: false,
    styles: {
      table: "width,height",
      tr: "height,text-align",
      "td,th": "width,height,text-align,vertical-align",
    },
    funcbar: ["table"],
  },
  {
    id: 23,
    plugins: ["mediawidget"],
    funcbar: ["mediawidget"],
  },
]);

const mergeArrays = (left, right) =>
  uniq([...(left || empty.array), ...(right || empty.array)]);

const mergeFeatures = (left, right) => ({
  ...left,
  ...right,
  plugins: mergeArrays(left.plugins, right.plugins),
  block_formats: mergeArrays(left.block_formats, right.block_formats),
  formatbar: mergeArrays(right.formatbar, left.formatbar),
  funcbar: mergeArrays(right.funcbar, left.funcbar),
});

const style2block = (a) =>
  a.map(({ title, format }) => `${title}=${format}`).join(";");

const finalizeConfig = (
  { block_formats, formatbar, funcbar, ...options },
  placeholder,
  images_upload_handler
) => ({
  ...options,
  block_formats: style2block(block_formats),
  toolbar1: formatbar.join(" | "),
  toolbar2: funcbar.join(" | "),
  placeholder,
  images_upload_handler,
});

const defaultFeatures = Object.freeze([0]);

// eslint-disable-next-line import/prefer-default-export
export const getConfig = (
  { features = defaultFeatures, name, images_upload_handler } = empty.object
) =>
  finalizeConfig(
    features
      .map((fea) => featureConfig[fea] || empty.object)
      .reduce(mergeFeatures, empty.object),
    name,
    images_upload_handler
  );
