/* eslint  react/prop-types: off */
import IconButton from "material-ui/IconButton";
import ContentClear from "material-ui/svg-icons/content/clear";
import React from "react";

const ImageLightbox = ({
  imageLightbox,
  toggleImageLightbox,
  documentSize,
}) => (
  <div
    className="image-lightbox"
    style={{ height: documentSize.height }}
    onClick={() => toggleImageLightbox(false)}
  >
    <IconButton
      style={{
        position: "fixed",
        right: 0,
        top: 0,
      }}
      iconStyle={{
        color: "#fff",
      }}
      onClick={() => toggleImageLightbox(false)}
    >
      <ContentClear />
    </IconButton>
    <img
      src={imageLightbox.image}
      alt=""
      style={{
        maxHeight: documentSize.height - 2 * 8,
      }}
    />
  </div>
);

export default ImageLightbox;
