import { FlatButton, IconButton } from "material-ui";
import FullscreenIcon from "material-ui/svg-icons/navigation/fullscreen";
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import Dialog from "../../containers/dialog";

const EnlargeButton = ({ field }: { field: ReactElement }) => {
  const [isOpen, setOpen] = useState(false);
  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);
  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <>
      {isOpen && (
        <Dialog
          open
          autoScrollBodyContent
          actions={[
            <FlatButton
              key="close"
              secondary
              label="Sluiten"
              onClick={handleClose}
            />,
          ]}
        >
          {React.cloneElement(field, { fullWidth: true, omitLabel: true })}
        </Dialog>
      )}
      <IconButton title="Toon volledig veld" onClick={handleOpen}>
        <FullscreenIcon />
      </IconButton>
    </>
  );
};

const WithEnlarge = ({ field }: { field: ReactElement }) => {
  const childRef = useRef<HTMLDivElement>(null);
  const [isTruncated, setTruncated] = useState(false);
  useEffect(() => {
    const htmlDiv = childRef.current?.querySelector(".field.ggvtyp5 .html div");
    if (htmlDiv) {
      setTruncated(htmlDiv.scrollHeight > htmlDiv.clientHeight);
    }
  }, [childRef]);

  return (
    <div className="with-zoom" ref={childRef}>
      {isTruncated && <EnlargeButton field={field} />}
      {field}
    </div>
  );
};

export default WithEnlarge;
