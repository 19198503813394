import IconButton from "material-ui/IconButton";
import ActionHighlightOff from "material-ui/svg-icons/action/highlight-off";
import TextField from "material-ui/TextField";
import React, { memo, useCallback } from "react";

const SearchBox = ({ search, onSearch }) => {
  const handleChange = useCallback(
    (_event, newValue) => {
      onSearch(newValue);
    },
    [onSearch]
  );

  const handleReset = useCallback(() => {
    onSearch("");
  }, [onSearch]);

  return (
    <div style={{ display: "flex", alignItems: "flex-end" }}>
      <TextField
        value={search}
        onChange={handleChange}
        fullWidth
        floatingLabelText="Zoeken"
        floatingLabelFixed
        hintText="Voer een zoekterm in"
      />
      <IconButton
        onClick={handleReset}
        disabled={!search}
        title="Zoekterm wissen"
      >
        <ActionHighlightOff />
      </IconButton>
    </div>
  );
};

export default memo(SearchBox);
