import empty from "empty";

import { fetchVariantJSON, toQueryString } from "./fetch";

const routePrefix = "cms/relations";

export const find = (itmRelTypIdt, dir, itmIdt, targetSitIdt, ssoItmIdt) => {
  const queryString = toQueryString({ target: targetSitIdt, ssoItmIdt });
  return fetchVariantJSON(
    `${routePrefix}/${itmRelTypIdt}/find/${dir}/${itmIdt}?${queryString}`
  );
};

export const filterPersonRole = (source, itmIdt) =>
  fetchVariantJSON(`${routePrefix}/${source}/${itmIdt}`);

export const add = (itmRelTypIdt, vanItmIdt, narItmIdt, metItmIdt, metaData) =>
  fetchVariantJSON(
    `${routePrefix}/${itmRelTypIdt}/add/${vanItmIdt}/${narItmIdt}/${
      metItmIdt || ""
    }`,
    metaData
  );

export const addRelations = (itmRelTypIdt, modelArray) =>
  fetchVariantJSON(`${routePrefix}/${itmRelTypIdt}/add`, modelArray);

export const addNew = (
  itmRelTypIdt,
  oneItmIdt,
  newItem = empty.object,
  twoItmIdt,
  relationMetaData,
  clusterIds
) =>
  fetchVariantJSON(
    `${routePrefix}/${itmRelTypIdt}/addnew/${oneItmIdt}/${twoItmIdt || ""}`,
    { item: { ...newItem, relationMetaData }, clusterIds }
  );

export const remove = (itmRelTypIdt, relIdt) =>
  fetchVariantJSON(
    `${routePrefix}/${itmRelTypIdt}/delete/${relIdt}`,
    empty.object
  );

export const removeAll = (itmRelTypIdt, ids) =>
  fetchVariantJSON(`${routePrefix}/${itmRelTypIdt}/delete`, { ids });

export const chainRelations = (itmIdt, chainModel) =>
  fetchVariantJSON(`relations/${itmIdt}/chain`, chainModel);

const relationModule = {
  find,
  add,
  addRelations,
  addNew,
  remove,
  filterPersonRole,
  chainRelations,
};
export default relationModule;
