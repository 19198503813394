import empty from "empty";
import PropTypes from "prop-types";
import React from "react";

const idNaam = Object.freeze({
  id: PropTypes.number.isRequired,
  naam: PropTypes.string.isRequired,
});

export const kernVeldShape = PropTypes.shape(idNaam);

PropTypes.shape({
  alias: PropTypes.string,
  selectionId: PropTypes.number.isRequired,
  value: PropTypes.string.isRequired,
});

export const kernGegevenShape = PropTypes.shape(
  Object.freeze({
    ...idNaam,
    velden: PropTypes.arrayOf(kernVeldShape.isRequired).isRequired,
  })
);

export const applicatieGegevenShape = kernGegevenShape;

export const siteLinkShape = PropTypes.shape({
  itemId: PropTypes.number.isRequired,
  structureId: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  pageClusterId: PropTypes.number,
});

export const pageShape = PropTypes.shape(empty.object);
export const itemShape = PropTypes.shape({
  page: pageShape,
});

const relationProperties = Object.freeze({
  id: PropTypes.number.isRequired,
  relationTypeId: PropTypes.number.isRequired,
  side: PropTypes.number.isRequired,
  show: PropTypes.number,
  left: siteLinkShape.isRequired,
  right: siteLinkShape.isRequired,
  using: PropTypes.arrayOf(siteLinkShape.isRequired).isRequired,
});

export const relationShape = PropTypes.shape(relationProperties);

export const relationsContainerShape = PropTypes.shape({
  loading: PropTypes.string,
  relations: PropTypes.arrayOf(relationShape.isRequired),
});

export const relationTypeSideShape = PropTypes.shape({
  label: PropTypes.string.isRequired,
  labelSpec: PropTypes.string,
  pagetype: PropTypes.string.isRequired,
  rule: PropTypes.number,
  specificItemId: PropTypes.number,
});

const relationTypeProperties = {
  id: PropTypes.number.isRequired,
  alias: PropTypes.string.isRequired,
  hierarchically: PropTypes.bool,
  left: relationTypeSideShape.isRequired,
  right: relationTypeSideShape.isRequired,
  using: relationTypeSideShape,
};

export const relationTypeShape = PropTypes.shape(relationTypeProperties);

export const relationTypeWithSideShape = PropTypes.shape({
  ...relationTypeProperties,
  side: PropTypes.number.isrequired,
});

export const typedRelationShape = PropTypes.shape({
  ...relationProperties,
  type: relationTypeShape.isRequired,
});

export const applicatieGegevensShape = PropTypes.shape({
  gegevens: PropTypes.arrayOf(applicatieGegevenShape.isRequired).isRequired,
});

export const koppelingApplicatieShape = PropTypes.shape({
  fields: applicatieGegevensShape.isRequired,
  page: pageShape.isRequired,
  relations: PropTypes.arrayOf(typedRelationShape.isRequired).isRequired,
});

export const koppelingApplicationsShape = PropTypes.shape({
  from: koppelingApplicatieShape.isRequired,
  to: koppelingApplicatieShape.isRequired,
});

export const globalItemShape = PropTypes.shape(
  Object.freeze({
    ...idNaam,
    bieb: PropTypes.number,
  })
);

export const entiteitShape = PropTypes.shape(
  Object.freeze({
    ...idNaam,
    bieb: PropTypes.number,
    gegevens: PropTypes.arrayOf(kernGegevenShape.isRequired).isRequired,
  })
);

export const zibOnderdeelShape = PropTypes.shape({
  ...idNaam,
  type: PropTypes.oneOf(["Gegeven", "SubBouwsteen", "Container"]),
  onderdelen: PropTypes.arrayOf(
    PropTypes.shape({
      ...idNaam,
      type: PropTypes.oneOf(["Gegeven", "SubBouwsteen", "Container"]),
      onderdelen: PropTypes.arrayOf(
        PropTypes.shape({
          ...idNaam,
          type: PropTypes.oneOf(["Gegeven", "SubBouwsteen", "Container"]),
        })
      ),
    })
  ),
});

export const zibShape = PropTypes.shape({
  ...idNaam,
  bieb: PropTypes.number,
  onderdelen: PropTypes.arrayOf(zibOnderdeelShape.isRequired),
});

export const clusterShape = PropTypes.shape({
  itmIdt: PropTypes.number.isRequired,
  pagClsIdt: PropTypes.number.isRequired,
  naam: PropTypes.string.isRequired,
});

export const koppelingRowShape = PropTypes.shape({
  left: clusterShape.isRequired,
  right: clusterShape,
});

export const contentPlugShape = PropTypes.shape({
  sort: PropTypes.func /* content-collection => [member] */,
  collapse: PropTypes.func /* cluster => string | null */,
  bulkExpand: PropTypes.func /* cluster => string | null */,
  search: PropTypes.func /* cluster => string | null */,
  show: PropTypes.func /* content => bool */,
  fullWidth: PropTypes.func /* field => bool | undefined */,
});

export const pageActionsShape = PropTypes.shape({
  edit: PropTypes.bool.isRequired,
  dirty: PropTypes.bool.isRequired,
  pageId: PropTypes.number.isRequired,
  version: PropTypes.number,
  // TODO: more (plus version is required)
});

export const fieldDefinitionShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  dataTypeCode: PropTypes.number.isRequired,
  explanation: PropTypes.string,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  controlPlaceholder: PropTypes.string,
  sourcePlaceholder: PropTypes.string,
  valuePlaceholder: PropTypes.string,
});

export const clusterWithFieldsShape = PropTypes.shape({
  clusterId: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  list: PropTypes.arrayOf(fieldDefinitionShape),
});

export const fieldShape = PropTypes.shape({
  id: PropTypes.number,
  iproxType: PropTypes.oneOf(["field"]),
  names: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  definition: fieldDefinitionShape.isRequired,
  isEmpty: PropTypes.bool,
  value: PropTypes.any,
});

// TODO: rewrite as PropTypes.objectOf (?)
export const metaFieldDefinitionsShape = PropTypes.shape(empty.object);

export const styleShape = PropTypes.shape(empty.object);

PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]);

export const selectedGegevenShape = PropTypes.shape({
  entiteitId: PropTypes.number.isRequired,
  gegevenId: PropTypes.number.isRequired,
});

export const structureShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
});

PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.func,
  PropTypes.instanceOf(React.Component),
  PropTypes.instanceOf(React.PureComponent),
]);

const linkIdProperties = {
  itemId: PropTypes.number.isRequired,
  pageClusterId: PropTypes.number,
};

export const linkIdShape = PropTypes.shape(linkIdProperties);

const linkProperties = {
  ...linkIdProperties,
  slug: PropTypes.string,
  label: PropTypes.string.isRequired,
};

export const linkShape = PropTypes.shape(linkProperties);

export const relationLinkShape = PropTypes.shape({
  item: linkShape.isRequired,
  using: PropTypes.arrayOf(linkShape.isRequired),
});

export const relationDisplayShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  side: PropTypes.number.isRequired,
  show: PropTypes.number,
  label: PropTypes.string.isRequired,
  rule: PropTypes.number,
  links: PropTypes.arrayOf(relationLinkShape.isRequired).isRequired,
});

PropTypes.shape({
  alias: PropTypes.string.isRequired,
  side: PropTypes.number.isRequired,
  clusterFirst: PropTypes.bool,
  show: PropTypes.number,
});

export const itemListShape = PropTypes.shape({
  loading: PropTypes.string,
  items: PropTypes.arrayOf(linkShape.isRequired).isRequired,
});

export const selectionItemShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  value: PropTypes.string.isRequired,
});

export const selectionListShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  value: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(selectionItemShape.isRequired).isRequired,
});

export const fileFieldSingleValueShape = PropTypes.shape({
  fileName: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
});

export const fileFieldValueShape = PropTypes.shape({
  files: PropTypes.arrayOf(fileFieldSingleValueShape).isRequired,
});

export const fileFieldShape = PropTypes.shape({
  value: fileFieldValueShape,
  assetPath: PropTypes.string.isRequired,
  isEmpty: PropTypes.bool,
});

const taskShape = PropTypes.shape({
  prfIdt: PropTypes.number.isRequired,
  scope: PropTypes.shape({
    gebIdt: PropTypes.arrayOf(PropTypes.number),
  }),
});

export const userShape = PropTypes.shape({
  changePassword: PropTypes.bool,
  checked: PropTypes.bool,
  email: PropTypes.string,
  id: PropTypes.string,
  iznet: PropTypes.string,
  mayAdd: PropTypes.bool,
  mayAddToBieb: PropTypes.bool,
  mayEdit: PropTypes.bool,
  name: PropTypes.string,
  sites: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.arrayOf(PropTypes.number.isRequired),
  ]),
  tasks: PropTypes.arrayOf(taskShape),
  username: PropTypes.string,
});

export const sessionShape = PropTypes.shape({
  user: userShape,
});

export const overzichtFieldShape = PropTypes.shape({
  field: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
});

export const overzichtRelationShape = PropTypes.shape({
  relation: PropTypes.shape({
    id: PropTypes.number.isRequired,
    side: PropTypes.number.isRequired,
  }).isRequired,
});

export const overzichtColumnType = PropTypes.oneOfType([
  overzichtFieldShape.isRequired,
  overzichtRelationShape.isRequired,
]);

export const overzichtShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  columns: PropTypes.arrayOf(overzichtColumnType.isRequired).isRequired,
});

export const versionUserShape = PropTypes.shape({
  userId: PropTypes.number.isRequired,
  name: PropTypes.string,
  login: PropTypes.string,
});

export const versionMetaShape = PropTypes.shape({
  itemId: PropTypes.number.isRequired,
  versionId: PropTypes.number,
  relationVersionId: PropTypes.number,
  relationItemId: PropTypes.number,
  relationItemVersionId: PropTypes.number,
  created: PropTypes.string.isRequired,
  user: versionUserShape.isRequired,
});

export const versionListShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  items: PropTypes.arrayOf(versionMetaShape.isRequired).isRequired,
});

export const versionsDrawerShape = PropTypes.shape({ show: PropTypes.bool });

export const restoreShape = PropTypes.shape({ busy: PropTypes.bool });

export const oneDriveFileShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  driveId: PropTypes.string.isRequired,
  uri: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
});

export const oneDriveFileShapes = PropTypes.shape({
  files: PropTypes.arrayOf(oneDriveFileShape.isRequired),
});

export const clustersForRelationType = PropTypes.shape({
  pagClsIdt: PropTypes.number.isRequired,
  clsIdt: PropTypes.number.isRequired,
  nam: PropTypes.string.isRequired,
  lbl: PropTypes.string.isRequired,
});
