import empty from "empty";
import { get, includes, some } from "lodash/fp";
import IconButton from "material-ui/IconButton";
import HourglassFull from "material-ui/svg-icons/action/hourglass-full";
import Search from "material-ui/svg-icons/action/search";
import LibraryBooks from "material-ui/svg-icons/av/library-books";
import NavigationMenu from "material-ui/svg-icons/navigation/menu";
import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import { compose, setDisplayName, withHandlers } from "recompose";

import { useJobActive } from "../../actions/utils";
import {
  relationShape,
  structureShape,
  userShape,
  versionListShape,
  versionsDrawerShape,
} from "../../business/prop-types";
import { flown } from "../../lodash";
import { isNonEmptyArray } from "../../utils";
import EditMenu from "../editMenu";
import { PathLink } from "../relation.pathlink";
import UserMenu from "../userMenu";
import ExcelButton from "./ExcelButton";
import Releases from "./releases";
import WordMaker from "./wordMaker";

const IconElementRight = ({
  addToBieb,
  biebBackgroundColor,
  biebButton,
  biebColor,
  biebLink,
  busy,
  deleteItem,
  linkItem,
  unlinkItem,
  edit,
  enableWord,
  isBiebItem,
  isFullscreen,
  itemId,
  legoblokStructures,
  logout,
  pagetype,
  password,
  siteId,
  toggle,
  user,
  versions,
  versionsDrawer,
  showVersionsDrawer,
  setVersionDiff,
  mobile,
  toggleSearch,
}) => {
  const sharedStructureIds = useSelector(
    (store) => store.data.miniSaars.sharedStructureIds || empty.array
  );
  const jobActive = useJobActive();
  const isEngineer = flown(
    user,
    get("tasks"),
    some(({ prfIdt }) => prfIdt === 9)
  );
  const hasBieb = isNonEmptyArray(legoblokStructures);
  const insideBieb = some({ id: siteId })(legoblokStructures);
  const insideShared = !insideBieb && includes(siteId)(sharedStructureIds);

  return (
    <div style={{ display: "flex", justifyContent: "flex-end" }}>
      {mobile && user.id && (
        <div style={{ lineHeight: "48px" }}>
          <IconButton
            iconStyle={{ color: "#fff" }}
            style={{
              padding: 0,
              width: 48,
              display: "inline-block",
              height: 34,
              overflow: "hidden",
              verticalAlign: "middle",
            }}
            onClick={toggleSearch}
          >
            <Search />
          </IconButton>
        </div>
      )}
      {!mobile && user.id && (
        <ExcelButton
          biebColor={biebColor}
          biebBackgroundColor={biebBackgroundColor}
        />
      )}
      {!mobile && enableWord && user.id && itemId > 0 && (
        <WordMaker
          itemId={itemId}
          biebColor={biebColor}
          biebBackgroundColor={biebBackgroundColor}
          enableCopy={isEngineer}
        />
      )}
      {user.id && hasBieb && (
        <Releases
          biebColor={biebColor}
          biebBackgroundColor={biebBackgroundColor}
        />
      )}
      {user.id && hasBieb && biebButton.show && (
        <div style={{ lineHeight: "48px" }}>
          <IconButton
            iconStyle={{ color: biebColor }}
            style={{
              backgroundColor: biebBackgroundColor,
              borderWidth: 5,
              borderStyle: "solid",
              borderColor: biebBackgroundColor,
              borderRadius: 5,
              padding: 0,
              width: 34,
              display: "inline-block",
              height: 34,
              overflow: "hidden",
              verticalAlign: "middle",
            }}
            containerElement={<PathLink path={biebButton.link} />}
          >
            {jobActive ? (
              <span title="Bezig met overnemen/bijwerken uit bieb">
                <HourglassFull color={biebColor} />
              </span>
            ) : (
              <LibraryBooks color={biebColor} />
            )}
          </IconButton>
        </div>
      )}
      {user.id && pagetype ? (
        <EditMenu
          user={{
            ...user,
            mayEdit:
              user.mayEdit &&
              (insideShared
                ? isEngineer
                : user.iznet === "Write" || !insideBieb),
          }}
          itemId={itemId}
          deleteItem={deleteItem}
          biebLink={biebLink}
          isBiebItem={isBiebItem}
          linkItem={linkItem}
          unlinkItem={unlinkItem}
          hasBieb={hasBieb}
          addToBieb={addToBieb}
          pagetype={pagetype}
          disabled={!itemId || edit || busy}
          insideBieb={insideBieb}
          versions={versions}
          versionsDrawer={versionsDrawer}
          showVersionsDrawer={showVersionsDrawer}
          setVersionDiff={setVersionDiff}
        />
      ) : null}
      {user.id ? (
        <UserMenu
          user={user}
          logout={logout}
          password={password}
          itemId={itemId}
          mobile={mobile}
        />
      ) : null}
      {isFullscreen && (
        <IconButton onClick={toggle} iconStyle={{ color: "#fff" }}>
          <NavigationMenu />
        </IconButton>
      )}
    </div>
  );
};

const biebButtonShape = PropTypes.shape({
  link: PropTypes.string,
  show: PropTypes.bool,
});

IconElementRight.propTypes = {
  addToBieb: PropTypes.func.isRequired,
  biebBackgroundColor: PropTypes.string.isRequired,
  biebButton: biebButtonShape.isRequired,
  biebColor: PropTypes.string.isRequired,
  busy: PropTypes.bool,
  deleteItem: PropTypes.func.isRequired,
  linkItem: PropTypes.func.isRequired,
  unlinkItem: PropTypes.func.isRequired,
  edit: PropTypes.bool,
  enableWord: PropTypes.bool.isRequired,
  isFullscreen: PropTypes.bool,
  itemId: PropTypes.number.isRequired,
  legoblokRelation: relationShape,
  legoblokStructures: PropTypes.arrayOf(structureShape).isRequired,
  logout: PropTypes.func.isRequired,
  pagetype: PropTypes.string,
  password: PropTypes.func.isRequired,
  siteId: PropTypes.number.isRequired,
  toggle: PropTypes.func.isRequired,
  user: userShape.isRequired,
  versions: versionListShape.isRequired,
  versionsDrawer: versionsDrawerShape.isRequired,
  showVersionsDrawer: PropTypes.func.isRequired,
  setVersionDiff: PropTypes.func.isRequired,
};

export default compose(
  setDisplayName("IconElementRight"),
  withHandlers({
    toggle:
      ({ toggleSecondaryDrawer }) =>
      () =>
        toggleSecondaryDrawer(),
  })
)(IconElementRight);
