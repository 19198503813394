/* eslint  react/prop-types: off */
import empty from "empty";
import {
  every,
  filter,
  find,
  findIndex,
  flatMap,
  flow,
  get,
  groupBy,
  head,
  includes,
  join,
  keys,
  map,
  mapValues,
  partial,
  some,
  sortBy,
  uniqBy,
  values,
} from "lodash/fp";
import { MenuItem } from "material-ui/Menu";
import SelectField from "material-ui/SelectField";
import React, { Fragment, useCallback, useMemo, useState } from "react";

import { Position } from "../../../../business";
import { Side, shownRelations } from "../../../../business/relations";
import {
  AddGegeven,
  GegevenDialog,
} from "../../../../containers/pagetypes/application/gegevens";
import KernGegeven from "../../../../containers/pagetypes/application/kerngegeven";
import KernVelden from "../../../../containers/pagetypes/application/kernvelden";
import ZibGegeven from "../../../../containers/pagetypes/application/zibgegeven";
import ZibVelden from "../../../../containers/pagetypes/application/zibvelden";
import { flown, leftJoin } from "../../../../lodash";
import ClustersOrderDialog from "../../../ClustersOrderDialog";
import ClusterItemLink from "../../../content/ClusterItemLink";
import defaultRenderRelationLinks from "../../../content/defaultRenderRelationLinks";
import { showRelatedPagetypes } from "../../../ShowPagetypeIcons";
import ModuleVelden from "../../application/ModuleVelden";
import icons from "../../icons";
import AddModule from "./addModule";
import AddProcess from "./addProcess";
import AddVelden from "./addVelden";
import AddVerantwoording from "./addVerantwoording";
import MoveVelden from "./MoveVelden";

const entiteitTypes = [
  { alias: "entiteit_applicatiegegeven", side: Side.right },
  {
    alias: "kerngegeven_applicatiegegeven",
    side: Side.right,
    clusterFirst: true,
  },
  { alias: "entiteit_applicatieveld", side: Side.right },
  { alias: "kerngegeven_applicatieveld", side: Side.right, clusterFirst: true },
];

const verwerkingTypes = [
  { alias: "applicatiegegeven_verantwoording", side: Side.left },
  { alias: "activiteit_applicatiegegeven", side: Side.right },
  { alias: "bedrijfsproces_applicatiegegeven", side: Side.right },
  { alias: "veld_koppeling_veld", side: Side.left, show: Side.using },
  { alias: "veld_koppeling_veld", side: Side.right, show: Side.using },
];

const zibTypes = [
  { alias: "applicatiegegeven_zib", side: Side.left, clusterFirst: true },
  { alias: "applicatiegegeven_zib_ggv1", side: Side.left, clusterFirst: true },
  { alias: "applicatiegegeven_zib_ggv2", side: Side.left, clusterFirst: true },
  { alias: "applicatiegegeven_zib_ggv3", side: Side.left, clusterFirst: true },
  { alias: "applicatiegegeven_zib_sub1", side: Side.left, clusterFirst: true },
  { alias: "applicatiegegeven_zib_sub2", side: Side.left, clusterFirst: true },
  { alias: "applicatiegegeven_zib_sub3", side: Side.left, clusterFirst: true },
  { alias: "applicatieveld_zib_ggv1", side: Side.left, clusterFirst: true },
  { alias: "applicatieveld_zib_ggv2", side: Side.left, clusterFirst: true },
  { alias: "applicatieveld_zib_ggv3", side: Side.left, clusterFirst: true },
];

const allTypes = [
  ...entiteitTypes,
  ...zibTypes,
  ...verwerkingTypes,
  { alias: "applicatieveld_module", side: Side.left, clusterFirst: true },
];

const renderGegevenEdit = (
  page,
  { clusterId },
  name,
  { pageActions = empty.object, relations } = empty.object
) =>
  name === "Gegeven" && pageActions.edit ? (
    <>
      <KernGegeven
        pageActions={pageActions}
        relations={relations}
        clusterId={clusterId}
        page={page}
      />
      <ZibGegeven
        pageActions={pageActions}
        relations={relations}
        clusterId={clusterId}
        page={page}
      />
    </>
  ) : undefined;

const specialLinkRenderingFor = [
  "activiteit_applicatiegegeven",
  "applicatiegegeven_verantwoording",
  "bedrijfsproces_applicatiegegeven",
];

const renderRelationsUsingEntiteiten = ({
  links,
  relation,
  pageActions,
  renderRelationLinkParentheses,
}) => {
  if (pageActions.edit || !specialLinkRenderingFor.includes(relation.alias)) {
    // default render behaviour
    return defaultRenderRelationLinks({
      links,
      relation,
      pageActions,
      renderRelationLinkParentheses,
    });
  }

  return flow(
    groupBy("item.itemId"),
    mapValues((relationsPerGroup) => {
      const first = relationsPerGroup[0];
      return {
        item: first.item,
        original: first.relation,
        entiteiten: flatMap("using")(relationsPerGroup),
      };
    }),
    values,
    map(({ item, original, entiteiten }) => {
      const parentheses = flow(map("label"), join(", "))(entiteiten);
      return (
        <ClusterItemLink
          key={`grouped:${item.itemId}_${item.pageClusterId}_${original.id}`}
          clusterFirst={relation.clusterFirst}
          mayDelete={relation.mayDelete}
          pageActions={pageActions}
          item={item}
          relation={original}
          renderRelationLinkParentheses={() => parentheses}
        />
      );
    })
  )(links);
};

const renderVeldEdit = (
  page,
  { clusterId, naam: { value: fieldName } = empty.object },
  name,
  { pageActions = empty.object, relations } = empty.object
) =>
  name === "Veld" && pageActions.edit ? (
    <>
      <KernVelden
        pageActions={pageActions}
        relations={relations}
        clusterId={clusterId}
        page={page}
      />
      <ZibVelden
        pageActions={pageActions}
        relations={relations}
        clusterId={clusterId}
        page={page}
      />
      <ModuleVelden
        pageActions={pageActions}
        clusterId={clusterId}
        fieldName={fieldName}
      />
    </>
  ) : undefined;

const renderContent = (props, entiteitId, clusterFilter, clusterAlter) => {
  const {
    itemId,
    pageActions,
    getContent,
    page,
    relations: allRelations,
    types,
  } = props;
  const hasEntiteitFilter = entiteitId > 0;

  const relations = hasEntiteitFilter
    ? flown(
        allRelations,
        filter(({ using }) =>
          every(
            (item) => item.itemId === entiteitId || item.type !== "entiteit"
          )(using)
        )
      )
    : allRelations;

  const hasEntiteit = new Set(
    flown(
      relations,
      filter(({ left = empty.object }) => left.type === "entiteit"),
      map("right.pageClusterId")
    )
  );

  const hasVerantwoording = new Set(
    flown(
      relations,
      filter(({ right = empty.object }) => right.type === "verantwoording"),
      map("left.pageClusterId")
    )
  );

  const hasZib = new Set(
    flown(
      relations,
      filter(({ right = empty.object }) => right.type === "zib"),
      map("left.pageClusterId")
    )
  );

  const hasModule = props.disableModuleIcon
    ? new Set()
    : new Set(
        flown(
          relations,
          filter(({ right = empty.object }) => right.type === "module"),
          map("left.pageClusterId")
        )
      );

  const hasVerwerking = new Set(
    flown(
      relations,
      filter(
        ({ left = empty.object }) =>
          left.type === "activiteit" || left.type === "bedrijfsproces"
      ),
      map("right.pageClusterId")
    )
  );

  const koppelingRelatieType =
    find({ alias: "veld_koppeling_veld" })(types) ?? empty.object;
  const hasKoppeling = new Set(
    flown(
      relations,
      filter(
        ({ relationTypeId }) => relationTypeId === koppelingRelatieType.id
      ),
      map(({ left = empty.object, right = empty.object }) =>
        left.itemId === itemId ? left.pageClusterId : right.pageClusterId
      )
    )
  );

  const renderRelationLinkParentheses =
    !hasEntiteitFilter || pageActions.edit
      ? ({ using: [metItem = undefined] = empty.array }) =>
          metItem && metItem.type === "entiteit" ? metItem.label : undefined
      : empty.functionThatReturns(undefined);

  const renderRelationLinks =
    !entiteitId || entiteitId <= 0 ? renderRelationsUsingEntiteiten : undefined;

  return getContent({
    ...props.plug,
    path: "gegeven",
    omitTitle: true,
    clusterFilter,
    clusterAlter,
    renderRelationLinkParentheses,
    renderRelationLinks,
    collapse: (cluster, name) =>
      name === "Gegeven" && cluster.gegeven
        ? `Applicatiegegeven: ${
            (cluster.gegeven.naam && cluster.gegeven.naam.value) || ""
          }`
        : name === "Veld"
        ? `Applicatieveld: ${(cluster.naam && cluster.naam.value) || ""}`
        : null,
    suffix: (cluster, name) => {
      if (name === "Gegeven" && cluster.gegeven) {
        const veldClusterIds = flown(
          cluster,
          get("veld.clusters"),
          map("clusterId")
        );

        return (
          <span
            style={{
              display: "inline-block",
              marginLeft: "1em",
              verticalAlign: "middle",
              opacity: 0.7,
              position: "absolute",
              top: 8,
              right: 48,
            }}
          >
            {get("veld.clusters.length")(cluster) > 0 && (
              <span title="Heeft veld">
                <icons.veld />
              </span>
            )}
            {some((clusterId) => hasModule.has(clusterId))(veldClusterIds) && (
              <span title="Heeft module">
                <icons.module />
              </span>
            )}
            {(hasZib.has(cluster.clusterId) ||
              some((clusterId) => hasZib.has(clusterId))(veldClusterIds)) && (
              <span title="Heeft zib">
                <icons.zib />
              </span>
            )}
            {hasVerantwoording.has(cluster.clusterId) && (
              <span title="Heeft verantwoording">
                <icons.verantwoording />
              </span>
            )}
            {hasVerwerking.has(cluster.clusterId) && (
              <span title="Heeft proces">
                <icons.verwerking />
              </span>
            )}
            {hasEntiteit.has(cluster.clusterId) && (
              <span title="Heeft entiteit">
                <icons.entiteit />
              </span>
            )}
            {some((clusterId) => hasKoppeling.has(clusterId))(
              veldClusterIds
            ) && (
              <span title="Heeft koppeling">
                <icons.koppeling />
              </span>
            )}
          </span>
        );
      } else if (name === "Veld") {
        return showRelatedPagetypes(itemId)(cluster, name, {
          relations: allRelations,
        });
      } else {
        return undefined;
      }
    },
    gegeven: (_, clusterId, name) => {
      switch (name) {
        case "Veld":
          return flown(
            relations,
            filter(
              ({ right: { pageClusterId } = empty.object }) =>
                pageClusterId === clusterId
            ),
            map(({ left: { itemId, pageClusterId } = empty.object }) => ({
              entiteitId: itemId,
              gegevenId: pageClusterId,
            })),
            head
          );
        default:
          return undefined;
      }
    },
    relationsPosition: (_, name) =>
      name === "Gegeven" ? Position.second : Position.bottom,
    renderSecond: partial(renderGegevenEdit, [page]),
    renderEnd: partial(renderVeldEdit, [page]),
    omitAddButton: true,
    relations: shownRelations(relations, types)(allTypes),
    renderAfterAddButton: (cluster, clusterId, name, props) => {
      if (name !== "Veld") {
        return undefined;
      }

      const gegevens = page.gegeven?.clusters ?? [];
      const gegeven = gegevens.find(
        ({ clusterId }) => clusterId === props.clusterId
      );

      return (
        <>
          <AddVelden {...props} />
          {gegeven.veld.clusters.length > 0 && (
            <MoveVelden
              pageActions={props.pageActions}
              gegeven={gegeven}
              gegevens={gegevens.filter(
                ({ clusterId }) => clusterId !== props.clusterId
              )}
            />
          )}
        </>
      );
    },
  });
};

const renderGegevens = (props, grouping) => {
  if (grouping === "list") {
    return renderContent(props);
  }

  const { page, relations } = props;
  const { gegeven: { clusters: gegevens = empty.array } = empty.object } = page;

  const entiteitRelations = filter(
    ({ left = empty.object }) => left.type === "entiteit"
  )(relations);

  const join = leftJoin(
    (g) => g.clusterId,
    entiteitRelations,
    (r) => r.right.pageClusterId
  )(gegevens);

  const entiteiten = flow(
    map(([_, { left = empty.object } = empty.object]) => left),
    filter(empty.functionThatReturnsArgument),
    uniqBy(({ itemId }) => itemId),
    sortBy(({ label }) => label)
  )(join);

  const entiteitGroup = flow(
    map(
      ([
        { clusterId },
        { left: { itemId } = empty.object } = empty.object,
      ]) => ({ itemId: itemId ?? -1, clusterId })
    ),
    groupBy("itemId"),
    mapValues(map("clusterId"))
  )(join);

  const clusterFilter =
    (entiteitId) =>
    ({ clusterId, gegeven }) =>
      !gegeven || includes(clusterId)(entiteitGroup[entiteitId]);

  const entiteitVeldGrouping = flow(
    map(
      ([
        { clusterId },
        { left: { itemId } = empty.object } = empty.object,
      ]) => ({ itemId: itemId ?? -1, clusterId })
    ),
    groupBy("itemId"),
    mapValues(map("clusterId"))
  );

  const clusterAlter = (entiteitId) => (cluster) => {
    if (entiteitId > 0 && cluster.veld && cluster.veld.clusters?.length > 0) {
      // filter `cluster.veld.clusters[]`
      const joinVelden = leftJoin(
        (g) => g.clusterId,
        entiteitRelations,
        (r) => r.right.pageClusterId
      )(cluster.veld.clusters);
      const group = entiteitVeldGrouping(joinVelden);

      const clustersInGroup = flown(
        cluster.veld.clusters,
        filter(({ clusterId }) => includes(clusterId)(group[entiteitId]))
      );
      const clustersNotInGroup =
        clustersInGroup.length === cluster.veld.clusters.length
          ? undefined
          : flown(
              cluster.veld.clusters,
              filter(({ clusterId }) => !includes(clusterId)(group[entiteitId]))
            );
      return {
        ...cluster,
        veld: {
          ...cluster.veld,
          clusters: clustersInGroup,
          moreClusters: clustersNotInGroup,
        },
      };
    }

    return cluster;
  };

  return flow(
    keys,
    map(Number),
    sortBy(
      (groupKey) => findIndex({ itemId: groupKey })(entiteiten) ?? groupKey
    ),
    map((groupKey) => {
      const { itemId = groupKey, label } =
        find({ itemId: groupKey })(entiteiten) ?? empty.object;
      const headingLevel0 = label ? `Entiteit: ${label}` : "Geen entiteit";
      return (
        <Fragment key={groupKey}>
          {renderContent(
            { ...props, plug: { ...props.plug, headingLevel0 } },
            itemId,
            clusterFilter(itemId),
            clusterAlter(itemId)
          )}
        </Fragment>
      );
    })
  )(entiteitGroup);
};

const ApplicatieGegevens = (props) => {
  const { pageActions, hasGegevens, gegevens } = props;
  const insideBieb = some({ id: props.item?.siteId ?? 0 })(
    props.biebStructures
  );

  const [grouping, setGrouping] = useState("grouped");
  const handleGrouping = useCallback((_event, _index, value) => {
    setGrouping(value);
  }, []);

  const clusters = useMemo(
    () =>
      gegevens.map(
        ({
          clusterId,
          gegeven: { naam: { value } = empty.object } = empty.object,
        }) => ({
          id: clusterId,
          label: value,
        })
      ),
    [gegevens]
  );

  return (
    <>
      {pageActions.edit && (
        <div className="cluster-buttons-wrap">
          <div className="add-button-wrap">
            <GegevenDialog pageActions={pageActions} />
            <AddGegeven pageActions={pageActions}>
              {!insideBieb && <AddVerantwoording pageActions={pageActions} />}
              {!insideBieb && <AddProcess pageActions={pageActions} />}
              <AddModule pageActions={pageActions} />
            </AddGegeven>
          </div>
          <div className="order-button-wrap">
            <ClustersOrderDialog
              name="Gegeven"
              enabled={grouping === "list" && gegevens.length > 1}
              clusters={clusters}
              pageActions={pageActions}
            />
          </div>
        </div>
      )}
      {hasGegevens && (
        <div style={{ padding: 0, marginBottom: 16, textAlign: "right" }}>
          <span
            style={{
              verticalAlign: "middle",
              display: "inline-block",
              textAlign: "left",
              marginRight: 0,
            }}
          >
            <SelectField
              value={grouping}
              autoWidth
              floatingLabelText="Groepering"
              onChange={handleGrouping}
            >
              <MenuItem value="grouped" primaryText="Naar entiteit" />
              <MenuItem value="list" primaryText="Platte weergave" />
            </SelectField>
          </span>
        </div>
      )}
      {renderGegevens(props, grouping)}
    </>
  );
};

const renderApplicatieGegevens = (props) => {
  const {
    pageActions,
    page: { gegeven: { clusters: gegevens = empty.array } = empty.object },
  } = props;

  const hasGegevens = gegevens.length > 0;
  if (!pageActions.edit && !hasGegevens) {
    return empty.array;
  }

  return [
    <ApplicatieGegevens
      key="gegevens"
      {...props}
      hasGegevens={hasGegevens}
      gegevens={gegevens}
    />,
  ];
};

export default renderApplicatieGegevens;
