/* eslint  react/prop-types: off */
import empty from "empty";
import FlatButton from "material-ui/FlatButton";
import MenuItem from "material-ui/MenuItem";
import SelectField from "material-ui/SelectField";
import React from "react";
import { branch, compose, renderNothing, setDisplayName } from "recompose";

import Dialog from "../containers/dialog";

const tabbladen = ["Kennis", "Instructie", "Hulpmiddel", "Goede praktijk"];

const LegoblokAddDialog = ({
  close = empty.func,
  structures = empty.array,
  updateSite = empty.func,
  updateTab = empty.func,
  newLegoblok: { siteId, tab } = empty.object,
  valid = false,
  createLegoblok = empty.func,
}) => (
  <Dialog
    title="Legoblok maken"
    open
    autoScrollBodyContent
    actions={[
      <FlatButton key="close" secondary label="Annuleren" onClick={close} />,
      <FlatButton
        primary
        key="save"
        disabled={!valid}
        label="Opslaan"
        onClick={createLegoblok}
      />,
    ]}
  >
    <div>
      {structures && structures.length > 1 && (
        <SelectField
          floatingLabelText="Kies een structuur"
          onChange={updateSite}
          value={siteId}
        >
          {structures.map(({ id, name }) => (
            <MenuItem key={id} value={id} primaryText={name} />
          ))}
        </SelectField>
      )}
      <br />
      <SelectField
        floatingLabelText="Kies een tabblad"
        onChange={updateTab}
        value={tab}
      >
        {tabbladen.map(
          (name, idx) =>
            idx > 0 && (
              <MenuItem key={`tab_${idx}`} value={name} primaryText={name} />
            )
        )}
      </SelectField>
    </div>
  </Dialog>
);

export default compose(
  setDisplayName("LegoblokAddDialog"),
  branch(({ open }) => !open, renderNothing)
)(LegoblokAddDialog);
