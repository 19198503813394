import empty from "empty";
import { find, flatten, flow, get, map, sortBy, uniqBy } from "lodash/fp";
import React from "react";
import { compose, mapProps, pure, setDisplayName } from "recompose";

import { Position } from "../../../business";
import { Side, shownRelations } from "../../../business/relations";
import { flown } from "../../../lodash";
import { RelationListWrapper, RelationsList } from "../../relations";

const zibTypes = [
  { alias: "applicatiegegeven_zib", side: Side.right },
  { alias: "applicatiegegeven_zib_ggv1", side: Side.right },
  { alias: "applicatiegegeven_zib_ggv2", side: Side.right },
  { alias: "applicatiegegeven_zib_ggv3", side: Side.right },
  { alias: "applicatiegegeven_zib_sub1", side: Side.right },
  { alias: "applicatiegegeven_zib_sub2", side: Side.right },
  { alias: "applicatiegegeven_zib_sub3", side: Side.right },
  { alias: "applicatieveld_zib_ggv1", side: Side.right },
  { alias: "applicatieveld_zib_ggv2", side: Side.right },
  { alias: "applicatieveld_zib_ggv3", side: Side.right },
  { alias: "zib_sub1_zib", side: Side.left },
  { alias: "zib_sub2_zib", side: Side.left },
  { alias: "zib_sub3_zib", side: Side.left },
];

const ZibRelationsComponent = ({ itemId, relations, miniSaars, label }) =>
  relations && relations.length > 0 ? (
    <RelationListWrapper sideBarList>
      <RelationsList
        itemId={itemId}
        type="applicatiegegeven_zib"
        side={Side.left}
        list={relations}
        miniSaars={miniSaars}
        label={label}
        showHeader
      />
    </RelationListWrapper>
  ) : null;

const ZibRelations = compose(
  setDisplayName("ZibRelations"),
  pure,
  mapProps(({ relations = empty.array, types = empty.array, ...rest }) => {
    const zibTypeToShow = zibTypes.filter(
      ({ alias }) => alias === "applicatiegegeven_zib"
    );
    const label = flown(
      types,
      find(({ alias }) => alias === "applicatiegegeven_zib"),
      get("right.label")
    );

    return {
      ...rest,
      relations: flow(
        map(({ links = empty.array }) => links),
        flatten,
        uniqBy(
          ({ item: { itemId, pageClusterId } }) => `${itemId}-${pageClusterId}`
        ),
        sortBy(
          ({ item: { itemLabel, pageClusterLabel } }) =>
            `${itemLabel}~${pageClusterLabel}`
        )
      )(shownRelations(relations, types)(zibTypeToShow)),
      label,
    };
  })
)(ZibRelationsComponent);

const renderZibOnderdelen = (props) => {
  const { itemId, miniSaars, getContent, relations, types } = props;

  return [
    <ZibRelations
      key="appggv_zib-rels"
      itemId={itemId}
      relations={relations}
      types={types}
      miniSaars={miniSaars}
    />,
    ...getContent({
      path: "onderdelen",
      relationsPosition: () => Position.second,
      relations: shownRelations(relations, types)(zibTypes),
    }),
  ];
};

export default renderZibOnderdelen;
