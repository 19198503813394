/* eslint  react/prop-types: off */
import empty from "empty";
import Checkbox from "material-ui/Checkbox";
import Uncheck from "material-ui/svg-icons/content/remove";
import Check from "material-ui/svg-icons/navigation/check";
import PropTypes from "prop-types";
import React from "react";
import {
  compose,
  defaultProps,
  setDisplayName,
  setPropTypes,
  withHandlers,
} from "recompose";

import { propTypes, withDefaultPageActions } from "./ggvtyp";

/**
 * Gegevenstype 'Bool' (1)
 */
const Bool = ({
  value,
  className,
  pageActions: { edit = false } = empty.object,
  field: { definition: { explanation } = empty.object } = empty.object,
  handleCheck,
}) => {
  const Icon = value ? Check : Uncheck;
  return edit ? (
    <Checkbox checked={value} onCheck={handleCheck} label={explanation} />
  ) : (
    <Icon className={className} style={{ width: "16px", height: "16px" }} />
  );
};

const enhance = compose(
  setDisplayName("Bool"),
  setPropTypes({
    ...propTypes,
    field: PropTypes.shape({
      value: PropTypes.bool,
    }).isRequired,
  }),
  defaultProps({
    className: "bool",
    title: "Bool",
  }),
  withDefaultPageActions(),
  withHandlers({
    handleCheck:
      ({ setValue }) =>
      (e, checked) =>
        setValue({ value: checked, string: checked ? "1" : "0" }),
  })
);

export default enhance(Bool);
