import classNames from "classnames";
import ContentCreate from "material-ui/svg-icons/content/create";
import { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { PageActions, Relation, RelationType } from "../../../business/models";
import { Label } from "../../content/field";
import InlineIconButton from "../../material/InlineIconButton";
import { ItemLink } from "../../relation.itemlink";
import ModuleVeldenDialoog from "./ModuleVeldenDialoog";

const ModuleVelden = ({
  fieldName,
  pageActions,
  clusterId,
}: {
  fieldName?: string;
  pageActions: PageActions;
  clusterId: number;
}) => {
  const { pageId, edit } = pageActions;
  const relationTypes: RelationType[] = useSelector(
    (state: any) => state.data.relationTypes?.types ?? []
  );

  const moduleType = useMemo(
    () =>
      relationTypes.find(
        ({ alias }: RelationType) => alias === "applicatie_module"
      ),
    [relationTypes]
  );

  const veldModuleType = useMemo(
    () =>
      relationTypes.find(
        ({ alias }: RelationType) => alias === "applicatieveld_module"
      ),
    [relationTypes]
  );

  const relations: Relation[] = useSelector(
    (state: any) => state.data.relations?.[pageId]?.relations ?? []
  );

  const filteredModules = useMemo(
    () =>
      relations
        .filter(({ relationTypeId }) => relationTypeId === moduleType?.id)
        .map(({ right }) => right),
    [moduleType?.id, relations]
  );

  const veldRelations = useMemo(
    () =>
      relations.filter(
        ({ relationTypeId, left }) =>
          left.itemId === pageId &&
          left.pageClusterId === clusterId &&
          relationTypeId === veldModuleType?.id
      ),
    [clusterId, pageId, relations, veldModuleType?.id]
  );

  const [show, setShow] = useState(false);
  const openDialog = useCallback(() => setShow(true), []);
  const closeDialog = useCallback(() => setShow(false), []);
  const saveSelection = useCallback(
    (selection: number[]) => {
      pageActions.add("LinkModuleFromField", {
        clusterId,
        selection,
      });
    },
    [clusterId, pageActions]
  );

  if (!veldModuleType || !moduleType) {
    return null;
  }

  const dirty = pageActions.staged.some(
    (action) =>
      /LinkModuleFromField/.test(action.$type) &&
      // @ts-ignore : action with this type has clusterId
      action.clusterId === clusterId
  );
  return (
    <div
      className={classNames("field cluster-relation", { "field-edit": edit })}
    >
      <Label>{veldModuleType.left?.label}</Label>
      <div className="value">
        {dirty ? (
          <em>Wacht op opslaan</em>
        ) : veldRelations.length > 0 ? (
          <div style={{ display: "inline-block" }}>
            {veldRelations.map(({ right }) => (
              <div key={`module-${right.itemId}`}>
                <ItemLink item={right} />
              </div>
            ))}
          </div>
        ) : (
          <em>Modules koppelen of ontkoppelen</em>
        )}{" "}
        <InlineIconButton onClick={openDialog}>
          <ContentCreate />
        </InlineIconButton>
        {show && (
          <ModuleVeldenDialoog
            fieldName={fieldName ?? "Onbekend"}
            value={veldRelations}
            onClose={closeDialog}
            onSave={saveSelection}
            modules={filteredModules}
          />
        )}
      </div>
    </div>
  );
};

export default ModuleVelden;
