import MenuItem from "material-ui/MenuItem";
import ContentAdd from "material-ui/svg-icons/content/add";
import React, { memo, useCallback } from "react";

import { Pagetype } from "../../../business/models";
import PagetypeIcon from "../../pagetypeIcon";
import IconButtonMenu from "../shared/iconButtonMenu";
import { AddButtonProps } from "./models";

const AddButton = memo<AddButtonProps>(
  ({ pagetypes, onClick, onHyperlinkClick, disabled }) => {
    const handleClick = useCallback(
      (pagetype: Pagetype) => (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        onClick(pagetype);
      },
      [onClick]
    );

    const handleHyperlinkClick = useCallback(() => {
      onHyperlinkClick();
    }, [onHyperlinkClick]);

    return (
      <IconButtonMenu
        title="Items koppelen"
        className="raamwerk_item_add"
        buttonIcon={<ContentAdd />}
        subheader="Items koppelen"
        buttonDisabled={disabled}
      >
        {pagetypes.map((pagetype) => {
          const { id, name, alias } = pagetype;
          return (
            <MenuItem
              key={id}
              leftIcon={
                <PagetypeIcon
                  type={alias}
                  containerStyle={undefined}
                  iconStyle={undefined}
                  style={undefined}
                  colorProperty={undefined}
                  insideBieb={undefined}
                  simpleIcon
                />
              }
              primaryText={name}
              onClick={handleClick(pagetype)}
            />
          );
        })}
        <MenuItem
          key="externLink"
          leftIcon={
            <PagetypeIcon
              type="hyperlink"
              containerStyle={undefined}
              iconStyle={undefined}
              style={undefined}
              colorProperty={undefined}
              insideBieb={undefined}
              simpleIcon
            />
          }
          primaryText="Hyperlink"
          onClick={handleHyperlinkClick}
        />
      </IconButtonMenu>
    );
  }
);

export default AddButton;
