import { composeAction } from "async-lifecycle";
import empty from "empty";
import { defaultTo, filter, find, flatten, get, last } from "lodash/fp";

import {
  addItem,
  deleteItem,
  findItem,
  getFieldDefinitionsByPagetype,
  searchLinks,
} from "../api";
import { flown } from "../lodash";
import {
  combineCallbacks,
  findBiebItems,
  linkItem,
  unlinkItem,
} from "../sagas";
import { addStage, redirectAction } from "./common";
import { invalidateRecentlyCreatedAction } from "./dashboard";
import {
  requireNavigationAction,
  requireRecentAction,
  requireRelationsOnlyAction,
} from "./data";
import { pageEditAction } from "./page";
import { invalidateRelations } from "./relations.invalidate";
import { persistentMultiLine, snackbarShowAction } from "./ui";
import {
  invalidateVerantwoordingen,
  requireVerantwoordingen,
} from "./verantwoordingen";

export const pickCluster =
  (itemId, name, { entiteitId, gegevenId } = empty.object) =>
  (dispatch, getState) => {
    const state = getState();
    const { clusterId, name: clusterAddName } = flown(
      state,
      get(`form.page.${itemId}.staged`),
      defaultTo(empty.array),
      filter(({ $type: type }) => /ClusterAddAction/.test(type)),
      last,
      defaultTo(empty.object)
    );
    const clusterKey = `${itemId}:${clusterAddName}${clusterId ? "_" : ""}${
      clusterId || ""
    }`;
    const template = flown(
      state,
      get(`data.clusters.${clusterKey}.templates`),
      defaultTo(empty.array),
      find({ name })
    );

    if (template === undefined) {
      throw new Error(`Template ${name} not found`);
    }

    const gegevens =
      entiteitId &&
      gegevenId &&
      flown(
        state,
        get("data.entiteiten.entiteiten"),
        flatten,
        find({ id: entiteitId }),
        get("gegevens")
      );
    const gegeven = flown(gegevens, find({ id: gegevenId }));
    dispatch({
      type: "CLUSTERDEFINITION_PICK",
      payload: {
        id: itemId,
        name,
        templates: [template],
        gegeven: gegeven && {
          ...gegeven,
          entiteitId,
          gegevens: gegevens || empty.array,
        },
      },
    });
  };

export const clearPageActions = () => ({ type: "FORM_PAGE_CLEAR" });

const addItemBaseAction =
  (redirect) =>
  (newItem, ...callbacks) =>
  (dispatch, getState) => {
    const {
      session: { miniSaar: { path = "iznet" } = empty.object } = empty.object,
    } = getState();

    dispatch(
      composeAction(
        {
          group: "CMS_ADD_ITEM",
          fire: (newItem) => addItem(newItem),
          args: [newItem],
          callbackSuccess: (payload, dispatch) => {
            dispatch(invalidateRecentlyCreatedAction());
            if (redirect && payload.itemId) {
              dispatch(
                redirectAction(
                  `/${path}/page/${payload.itemId}/${payload.slug}`
                )
              );
              dispatch(pageEditAction(payload.itemId));
            }
          },
        },
        ...callbacks
      )
    );
  };

export const addItemAction = addItemBaseAction(true);

export const addItemSilentAction = addItemBaseAction(false);

const realDeleteItemAction =
  (
    itemId,
    { noredirect = false, afterActionCreators = empty.array } = empty.object
  ) =>
  (dispatch, getState) => {
    const {
      session: { miniSaar: { home } = empty.object },
    } = getState();
    dispatch(
      composeAction({
        group: "CMS_DELETE_ITEM",
        fire: (itemId) => deleteItem(itemId),
        args: [itemId],
        callbackAfter: (_, dispatch) => {
          if (!noredirect) {
            dispatch(redirectAction(home));
          }

          dispatch(requireNavigationAction());
          dispatch(requireRecentAction());
          dispatch({ type: "PAGE_DELETE", payload: { id: itemId } });
          for (const afterActionCreator of afterActionCreators) {
            dispatch(afterActionCreator());
          }
        },
      })
    );
  };

export const deleteItemAction = (itemId, options) =>
  addStage({
    message: "Dit item wordt definitief verwijderd.",
    action: realDeleteItemAction(itemId, options),
  });

export const resetRelations = (linkModel, dispatch) => ({
  onSuccess: () => {
    dispatch(invalidateRelations(linkModel.saarItemId));
    dispatch(requireRelationsOnlyAction(linkModel.saarItemId));
    dispatch(invalidateRelations(linkModel.biebItemId));
  },
});

export const findBiebItemsAction = (saarItemId, onSuccess) => (dispatch) => {
  dispatch(
    findBiebItems.require(saarItemId, {
      onSuccess: ({ body: items }) => onSuccess(items?.[0]),
    })
  );
};

export const linkItemAction = (linkModel) => (dispatch) => {
  dispatch(
    linkItem.require(
      linkModel,
      combineCallbacks(resetRelations(linkModel, dispatch), {
        onSuccess: () => {
          dispatch(
            snackbarShowAction(
              "Het item wordt gekoppeld aan de Saar Bieb.\r\nHet kan enkele minuten duren voordat het bijwerken van clusters mogelijk is.",
              persistentMultiLine
            )
          );
        },
      })
    )
  );
};

export const unlinkItemAction = (linkModel) => (dispatch) => {
  dispatch(
    addStage({
      message:
        "Dit item wordt losgekoppeld van de Saar Bieb. Bijwerken is daarna niet meer mogelijk.",
      action: unlinkItem.require(
        linkModel,
        resetRelations(linkModel, dispatch)
      ),
    })
  );
};

export const deleteVerantwoordingAction = (itemId) =>
  addStage({
    message: "Deze verantwoording wordt definitief verwijderd.",
    action: realDeleteItemAction(itemId, {
      noredirect: true,
      afterActionCreators: [
        invalidateVerantwoordingen,
        requireVerantwoordingen,
      ],
    }),
  });

export const leaveRouteAction = (nextPath) =>
  addStage({
    message: "Als je de pagina verlaat, worden de wijzigingen niet opgeslagen.",
    action: (dispatch) => {
      dispatch({ type: "LANDSCAPEDIRTY_SET", payload: { dirty: false } });
      dispatch(redirectAction(nextPath));
    },
  });

export const findItemAction = (label, pagetype) =>
  composeAction({
    group: "CMS_FIND_ITEM",
    fire: (label, pagetype) => findItem(label, pagetype),
    args: [label, pagetype],
  });

export const resetFindItemAction = () => ({ type: "CMS_FIND_ITEM_CLEAR" });

export const searchItemAction = (query) =>
  composeAction({
    group: "SEARCH_ITEM_LINKDIALOG",
    fire: (query) =>
      searchLinks({
        ...query,
        pageSize: 25,
        useWildcard: true,
        labelOnly: !Boolean(query.clusterName),
      }),
    args: [query],
  });

export const clearSearchItemAction = () => ({
  type: "SEARCH_ITEM_LINKDIALOG_CLEAR",
});

export const fieldDefinitionsByPagetypeAction = (pagetypeAlias) =>
  composeAction({
    group: "FIELDDEFINITIONS_BY_PAGETYPE",
    fire: getFieldDefinitionsByPagetype,
    args: [pagetypeAlias],
    key: "id",
    cachekey: "fieldDefinitionsByPagetype",
  });
