import { loadingPresent } from "async-lifecycle";
import empty from "empty";
/* eslint  react/prop-types: off */
import { defaultTo, filter, get } from "lodash/fp";
import React, { Fragment } from "react";

import { Position } from "../../../../business";
import {
  Side,
  relationDisplayByRelationSide,
  shownRelations,
} from "../../../../business/relations";
import { flown } from "../../../../lodash";
import ClusterRelations from "../../../content/ClusterRelations";
import { filterClusterRelations } from "../../../content/query";
import { showRelatedPagetypes } from "../../../ShowPagetypeIcons";
import KerngegevenVerantwoording from "../../entiteit/verantwoording";

const gegevensClusterTypes = [
  { alias: "kerngegeven_applicatiegegeven", side: Side.left },
  { alias: "kerngegeven_applicatieveld", side: Side.left },
  { alias: "activiteit_kerngegeven", side: Side.right },
  { alias: "bedrijfsproces_kerngegeven", side: Side.right },
];

const gegevensTypes = [
  { alias: "entiteit_applicatiegegeven", side: Side.left },
  { alias: "entiteit_applicatieveld", side: Side.left },
];

const renderEntiteitGegevens = ({
  itemId,
  biebStructures,
  getContent,
  getRelations,
  gotoVerantwoordingen,
  relations,
  types,
  verantwoording,
  chainRelations = empty.array,
}) => {
  const shown = shownRelations(relations, types)(gegevensClusterTypes);

  // Create a fake `entiteit_module` relationtype with relations
  const chainedModuleRelations = {
    alias: "entiteit_module",
    label: "Komt voor in module",
    links: empty.array,
    ...relationDisplayByRelationSide(
      filter(({ right: { type } = empty.object }) => type === "module")(
        chainRelations.relations
      ),
      false,
      loadingPresent(chainRelations.loading)
    )({ side: Side.left }),
  };
  const chainedKoppelingRelations = {
    alias: "entiteit_koppeling",
    label: "Komt voor in koppeling",
    links: empty.array,
    ...relationDisplayByRelationSide(
      filter(({ right: { type } = empty.object }) => type === "koppeling")(
        chainRelations.relations
      ),
      false,
      loadingPresent(chainRelations.loading)
    )({ side: Side.left }),
  };

  return [
    ...getContent({
      addAbove: true,
      path: "gegeven",
      collapse: (cluster, name) =>
        name === "Gegeven" && cluster.gegeven
          ? `Entiteitgegeven: ${flown(
              cluster,
              get("gegeven.naam.value"),
              defaultTo("")
            )}`
          : name === "Veld"
          ? `Entiteitveld: ${flown(cluster, get("naam.value"), defaultTo(""))}`
          : null,
      renderSecond: (cluster, name, { pageActions, relations }) => {
        if (name !== "Gegeven") {
          return undefined;
        }
        return (
          <Fragment>
            <ClusterRelations
              pageActions={pageActions}
              relations={flown(
                relations,
                filter(({ alias }) => alias.indexOf("_kerngegeven") < 0),
                filterClusterRelations(cluster.clusterId)
              )}
            />
            <KerngegevenVerantwoording
              biebStructures={biebStructures}
              page={verantwoording}
              pageActions={pageActions}
              relations={flown(
                relations,
                filter(({ alias }) => alias.indexOf("_kerngegeven") > 0),
                filterClusterRelations(cluster.clusterId)
              )}
              gotoVerantwoordingen={gotoVerantwoordingen}
            />
          </Fragment>
        );
      },
      relationsPosition: (_, name) =>
        name === "Gegeven" ? Position.none : Position.bottom,
      relations: [...shown, chainedModuleRelations, chainedKoppelingRelations],
      suffix: showRelatedPagetypes(itemId),
    }),
    getRelations(...gegevensTypes),
  ];
};

export default renderEntiteitGegevens;
