/* eslint  react/prop-types: off */
import { map } from "lodash/fp";
import Show from "material-ui/svg-icons/action/visibility";
import Hide from "material-ui/svg-icons/action/visibility-off";
import PropTypes from "prop-types";
import React from "react";
import {
  compose,
  defaultProps,
  setDisplayName,
  setPropTypes,
  withHandlers,
  withState,
} from "recompose";

import { propTypes, withDefaultPageActions } from "./ggvtyp";
import PlainInput from "./plainInput";

/**
 * Gegevenstype 'Wachtwoord' (20)
 */
const Wachtwoord = ({
  value,
  setValue,
  className,
  pageActions: { edit },
  show = false,
  toggleShow,
  Icon = show ? Hide : Show,
}) => (
  <div className={className}>
    <Icon
      onClick={toggleShow}
      style={{
        width: "16px",
        height: "16px",
        cursor: "pointer",
        marginRight: "1em",
      }}
    />
    {edit ? (
      <PlainInput
        value={value}
        setValue={setValue}
        type={show ? undefined : "password"}
        style={{ width: "calc(100% - 1em - 16px)" }}
      />
    ) : (
      <span className="wachtwoord-value">
        {show ? value : map((_) => "*")(value)}
      </span>
    )}
  </div>
);

const enhance = compose(
  setDisplayName("Wachtwoord"),
  setPropTypes({
    ...propTypes,
    field: PropTypes.shape({
      value: PropTypes.any,
    }).isRequired,
  }),
  defaultProps({
    className: "wachtwoord",
  }),
  withState("show", "setShow", false),
  withHandlers({
    toggleShow: (props) => (event) => {
      event.preventDefault();
      props.setShow(!props.show);
    },
  }),
  withDefaultPageActions()
);

export default enhance(Wachtwoord);
