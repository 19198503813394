/* eslint  react/prop-types: off */
import { loadingPresent, loadingProgress } from "async-lifecycle";
import classNames from "classnames";
import empty from "empty";
import {
  defaultTo,
  find,
  findIndex,
  flow,
  get,
  has,
  includes,
  isEqual,
  map,
  omit,
  some,
} from "lodash/fp";
import { Card } from "material-ui/Card";
import Drawer from "material-ui/Drawer";
import FlatButton from "material-ui/FlatButton";
import Paper from "material-ui/Paper";
import RaisedButton from "material-ui/RaisedButton";
import { deepOrange500 } from "material-ui/styles/colors";
import ImageBlurOff from "material-ui/svg-icons/image/blur-off";
import Toggle from "material-ui/Toggle";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  compose,
  mapProps,
  onlyUpdateForKeys,
  pure,
  setDisplayName,
  setPropTypes,
  withProps,
} from "recompose";
import { createSelector } from "reselect";

import { redirectLocalAction } from "../../actions/common";
import {
  requireNavigationAction,
  requireOverzichtAction,
  requirePagetypeListAction,
  requireRelationTypesAction,
  requireSelectionListsAction,
} from "../../actions/data";
import { allowedSaar, pageStatus } from "../../business";
import { Side } from "../../business/relations";
import AddRelation from "../../containers/addRelation";
import BiebAddDialog from "../../containers/bieb/biebAddDialog";
import BiebUseDialog from "../../containers/bieb/biebUseDialog";
import ClusterDialog from "../../containers/clusterDialog";
import consoleConnect from "../../containers/connect";
import CopyItemDialog from "../../containers/CopyItemDialogContainer";
import LegoblokAddDialog from "../../containers/legoblokAddDialog";
import LegoblokUseDialog from "../../containers/legoblokUseDialog";
import RecentItems from "../../containers/recentItems";
import { flown } from "../../lodash";
import { pagetypeGroupFromAlias, pagetypeGroups } from "../../pagetypes";
import {
  execOnChange,
  updateOnDataChanged,
  withClassNames,
} from "../../recompose.contrib";
import AddItem from "../../saar-add-item/addItem";
import { indexFilter } from "../../sagas";
import {
  biebRelationsSelector,
  hierarchicalRelationsSelector,
  legoblokRelationsSelector,
} from "../../selectors/relations";
import { isNonEmptyTab } from "../../selectors/tabs";
import { getMiniSaarPath, saarHistory } from "../../store";
import { emptyLookup, isEmpty, nullIfEmpty } from "../../utils";
import BiebUpdateDialog from "../bieb/BiebUpdateDialog";
import Field from "../content/field";
import ButtonBar from "../material/buttonBar";
import HintCard from "../material/HintCard";
import { TabTemplate, Tabs } from "../material/Tabs";
import { PathLink } from "../relation.pathlink";
import SafeInnerHtml from "../safeInnerHtml";
import Glass from "../utils/Glass";
import ImageLightbox from "./imageLightbox";
import PageRefreshIndicator from "./PageRefreshIndicator";
import Pagetitle from "./pagetitle";
import PagetypeIndicator from "./pagetypeIndicator";
import ResultSetNavigation from "./shared/ResultSetNavigation";

const pagePropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  siteId: PropTypes.number.isRequired,
  page: PropTypes.shape({
    title: PropTypes.string.isRequired,
    pagetype: PropTypes.string.isRequired,
  }).isRequired,
});
const zonePropTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  style: PropTypes.object,
};
const zonePropType = PropTypes.shape(zonePropTypes);
const tabPropType = PropTypes.shape({
  className: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.object.isRequired,
  ]),
  children: PropTypes.any,
});

const notFoundStyle = Object.freeze({ clear: "both", marginTop: 20 });

const SpecialCard = ({ children, ...rest }) => (
  <Card {...rest}>{nullIfEmpty(children)}</Card>
);

const SpecialComponent = (props) => {
  const { children, style } = props;
  return isEmpty(style) ? (
    isEmpty(children) ? null : (
      <SpecialCard {...props} />
    )
  ) : (
    <SpecialCard {...props} />
  );
};

const Special = compose(
  setDisplayName("Special"),
  setPropTypes(zonePropTypes),
  onlyUpdateForKeys(["style", "children"])
)(SpecialComponent);

const FirstSpecial = compose(
  setDisplayName("FirstSpecial"),
  pure,
  withClassNames("firstSpecial")
)(Special);

const RegularComponent = ({
  children,
  className,
  style,
  renderIfEmpty = false,
}) =>
  isEmpty(children) && !renderIfEmpty ? null : isEmpty(className) &&
    isEmpty(style) ? (
    children
  ) : (
    <div className={className} style={style}>
      {children}
    </div>
  );

const Regular = compose(
  setDisplayName("Regular"),
  pure,
  setPropTypes(zonePropTypes)
)(RegularComponent);

const prependChildren = (children, ...hierarchicalRelations) =>
  Array.isArray(children)
    ? [...hierarchicalRelations, ...children]
    : [...hierarchicalRelations, children];

const Rightbar = compose(
  setDisplayName("Rightbar"),
  pure,
  withProps({ renderIfEmpty: true }),
  mapProps((props) => {
    const { children, usePresentation, ...rest } = props;

    if (usePresentation) {
      return { ...props, children };
    }

    return pagetypeGroupFromAlias(props.pagetype) === pagetypeGroups.proces
      ? {
          children: prependChildren(
            children,
            legoblokRelationsSelector(rest),
            biebRelationsSelector(rest)
          ),
          ...rest,
        }
      : {
          children: prependChildren(
            children,
            hierarchicalRelationsSelector(rest, Side.right),
            hierarchicalRelationsSelector(rest, Side.left),
            legoblokRelationsSelector(rest),
            biebRelationsSelector(rest)
          ),
          ...rest,
        };
  })
)(Regular);

const lastModified = ({ loading, items } = empty.object, lastPublished) =>
  loadingPresent(loading) || loadingProgress(loading)
    ? get("[0].created")(items)
    : lastPublished;

const BasePage = ({
  id,
  extraClasses = empty.array,
  page: {
    page: { title, pagetype } = empty.object,
    page = empty.object,
    parentId,
    siteId,
    titleDiff,
    lastPublished,
  } = empty.object,
  page: item = empty.object,
  pagetypeAllowed,
  loading = false,
  error = item.loading === "error",
  user: { iznet = "None", mayAdd = false, mayEdit = false } = empty.object,
  header = empty.object,
  tabs = empty.array,
  firstSpecial,
  rightbar = empty.object,
  selectedTab,
  tabChanged,
  pageActions = empty.object,
  pageActions: {
    busy = true,
    dirty = false,
    edit = false,
    revert = empty.func,
    save = empty.func,
    toggleEdit = empty.func,
  } = empty.object,
  relations = empty.array,
  types = emptyLookup("alias"),
  reloading,
  secondaryDrawer = empty.object,
  secondaryDrawerOpen = false,
  updateSecondaryDrawer = empty.func,
  imageLightbox = empty.object,
  viewport: { documentSize = empty.object } = empty.object,
  toggleImageLightbox,
  createLegoblok = empty.func,
  enableCreateLegoblok,
  legoblokBusy = false,
  biebStructures = empty.array,
  legoblokStructures = empty.array,
  moveLegoblokTo = empty.func,
  applicationGegevenBusy = false,
  doUseFromBiebDialog = empty.func,
  pageTitle,
  section = false,
  hideRightbar = true,
  miniSaars,
  simplePage,
  sharedStructureIds,
  usePresentation,
  showVersionsDrawer,
  versions,
  orderTreeviewBusy = false,
  narrow,
  metaFields = empty.array,
}) => {
  const dispatch = useDispatch();
  const notFound = error && !title;
  const rightbarProps = {
    id,
    relations,
    types,
    pageActions,
    loading,
    reloading,
    miniSaars,
  };

  const legoblokRelationType = find({ alias: `${pagetype}_legoblok` })(types);
  const legoblokRelation = legoblokRelationType
    ? find({ relationTypeId: legoblokRelationType.id })(relations)
    : undefined;

  const fromLegoblokRelationType = find({ alias: `legoblok_${pagetype}` })(
    types
  );
  const fromLegoblokRelation = fromLegoblokRelationType
    ? find({ relationTypeId: fromLegoblokRelationType.id })(relations)
    : undefined;

  const isBiebItem =
    includes(siteId)(map("id")(biebStructures)) && pagetype !== "legoblok";
  const fromBiebRelationType = types.find(
    ({ alias }) => alias === `bieb_${pagetype}`
  );
  const fromBiebRelation =
    fromBiebRelationType && !isBiebItem
      ? relations.find(
          ({ relationTypeId }) => relationTypeId === fromBiebRelationType.id
        )
      : undefined;
  const Wrapper = section ? "section" : "main";

  const pagetypeIndicatorLabel =
    pagetype === "applicatie" && has("inhoud.kenmerk.value.value")(page)
      ? get("inhoud.kenmerk.value.value")(page)
      : pagetype;

  const editPagetypeIndicatorLabelComponent =
    pagetype === "applicatie" ? (
      <Field
        alias="kenmerk"
        clusterId={get("inhoud.clusterId")(page)}
        field={get("inhoud.kenmerk")(page)}
        name="Kenmerk"
        pageActions={pageActions}
        omitLabel
      />
    ) : undefined;

  const statusField = find({ name: "Status", dataTypeCode: 3 })(metaFields);
  const currentStatus = get("meta.status.value.value")(page) ?? "Definitief";
  const zieOok = get("meta.alternatieveWeergave.value")(page);
  const editStatusComponent =
    statusField && edit && mayEdit ? (
      <Field
        className="meta-status"
        alias="status"
        clusterId={get("meta.clusterId")(page)}
        field={get("meta.status")(page)}
        name="Status"
        pageActions={pageActions}
        omitLabel
      />
    ) : has("meta.status.diff")(page) ? (
      <SafeInnerHtml className="meta-status version-diff" wrapper="span">
        {get("meta.status.diff")(page)}
      </SafeInnerHtml>
    ) : currentStatus !== "Definitief" ? (
      <span className="meta-status">{currentStatus}</span>
    ) : zieOok ? (
      <FlatButton
        className="link-button"
        primary
        label={zieOok.label}
        style={{ padding: 0 }}
        onClick={() => dispatch(redirectLocalAction(`page/${zieOok.linkId}`))}
      />
    ) : null;

  const pagetypeFromFilter = useMemo(
    () =>
      pagetype === "index"
        ? get(
            "selectie.selectieInstellingen.eigenschappen.paginatypefilter.value[0].alias"
          )(page)
        : undefined,
    [page, pagetype]
  );
  const filter = useSelector(indexFilter.select);
  const showGlass =
    busy || applicationGegevenBusy || legoblokBusy || orderTreeviewBusy;

  const iconStatus = pageStatus(page, pagetype);
  return (
    <Wrapper
      className={classNames("item", pagetype, extraClasses, {
        wide: !narrow,
        filter,
        "page-edit": edit,
      })}
      key={id}
    >
      {showGlass && <Glass />}
      {!section && <ResultSetNavigation itemId={id} />}
      {!section && (
        <AddRelation
          id={id}
          selfTitle={page.title}
          siteId={siteId}
          isBiebItem={includes(siteId)(map("id")(biebStructures))}
        />
      )}
      {!section && mayEdit && edit && (
        <ClusterDialog pageActions={pageActions} />
      )}
      {!section && mayAdd && (
        <AddItem
          initialPagetype={pagetypeFromFilter ?? pagetype}
          disabled={edit}
        />
      )}
      {!section && mayEdit && !edit && <LegoblokAddDialog page={simplePage} />}
      {!section && mayAdd && !edit && <LegoblokUseDialog page={simplePage} />}
      {!section && mayEdit && !edit && <BiebAddDialog page={simplePage} />}
      {!section && mayAdd && !edit && <BiebUseDialog page={simplePage} />}
      {!section && mayAdd && !edit && <BiebUpdateDialog />}
      {!section && mayAdd && !edit && (
        <CopyItemDialog
          source={{
            sourceItemId: simplePage.id,
            label: simplePage.title,
            withHierarchyRelations: false,
          }}
        />
      )}
      {imageLightbox && imageLightbox.show && (
        <ImageLightbox
          imageLightbox={imageLightbox}
          toggleImageLightbox={toggleImageLightbox}
          documentSize={documentSize}
        />
      )}
      <div className="page">
        <Paper className="page-paper-title" style={{ padding: "16px" }}>
          <div style={{ position: "absolute", top: "16px", right: "16px" }}>
            {notFound ? (
              false
            ) : (loading !== false && !loadingPresent(loading)) ||
              busy ||
              legoblokBusy ||
              applicationGegevenBusy ||
              orderTreeviewBusy ||
              !title ? (
              <PageRefreshIndicator />
            ) : dirty ? (
              <div>
                <FlatButton
                  onClick={revert}
                  label="Annuleren"
                  style={{ backgroundColor: "white" }}
                />
                <RaisedButton
                  primary
                  onClick={save}
                  style={{ marginLeft: "1em" }}
                  label="Opslaan"
                />
              </div>
            ) : id > 0 &&
              !section &&
              mayEdit &&
              (iznet === "Write" || !includes(siteId)(sharedStructureIds)) ? (
              <Toggle
                toggled={edit}
                onToggle={toggleEdit}
                title={edit ? "Bekijk" : "Wijzig"}
                labelStyle={{ color: "white" }}
                labelPosition="right"
                style={{
                  flex: "0 0",
                  fontSize: "1rem",
                  width: "auto",
                  marginLeft: "1rem",
                  alignSelf: "center",
                }}
                className={`toggle-edit toggle-edit-${edit}`}
              />
            ) : null}
          </div>
          <h1>
            <Pagetitle
              title={pageTitle || title}
              titleDiff={titleDiff}
              pagetype={pagetype}
              pageActions={pageActions}
              legoblokRelation={legoblokRelation}
              fromLegoblokRelation={fromLegoblokRelation}
              fromBiebRelation={fromBiebRelation}
              insideBieb={some({ id: siteId })(biebStructures)}
              iconStatus={iconStatus}
              validity={get("meta.status.value.alias")(page)}
            />
          </h1>
          <Regular {...header} />
          {notFound && (
            <HintCard
              primaryText="De gevraagde pagina is mogelijk verwijderd"
              secondaryText={
                <>
                  Ga naar het{" "}
                  <PathLink path={getMiniSaarPath("home")}>dashboard</PathLink>.
                </>
              }
              avatar={<ImageBlurOff color={deepOrange500} />}
              style={notFoundStyle}
            />
          )}
          {pagetype && (
            <PagetypeIndicator
              pagetype={pagetype}
              pagetypeLabel={pagetypeIndicatorLabel}
              show
              mayEdit={
                !section &&
                mayEdit &&
                (iznet === "Write" || !includes(siteId)(sharedStructureIds))
              }
              edit={!section && edit}
              dirty={dirty}
              createLegoblok={createLegoblok}
              enableCreateLegoblok={
                enableCreateLegoblok && legoblokRelation === undefined
              }
              legoblokStructures={legoblokStructures}
              moveLegoblokTo={moveLegoblokTo}
              itemId={id}
              parentId={parentId}
              siteId={siteId}
              editLabelComponent={editPagetypeIndicatorLabelComponent}
              modified={lastModified(versions, lastPublished)}
              showVersionsDrawer={showVersionsDrawer}
              section={section}
              editStatusComponent={editStatusComponent}
              extraButtons={
                !isBiebItem &&
                !section &&
                !edit &&
                mayAdd &&
                allowedSaar(pagetypeAllowed) &&
                fromBiebRelation ? (
                  <ButtonBar
                    pagetype={pagetype}
                    openDialog={doUseFromBiebDialog}
                    biebRelations={relations.filter(
                      ({ relationTypeId }) =>
                        fromBiebRelationType &&
                        relationTypeId === fromBiebRelationType.id
                    )}
                    lastPublished={lastPublished}
                    isBiebItem={false}
                    fromBiebRelation={fromBiebRelation}
                  />
                ) : undefined
              }
            />
          )}
        </Paper>
        {tabs && tabs.filter(isNonEmptyTab).length > 0 && (
          <Tabs
            keepRendered={edit}
            value={selectedTab}
            onChange={tabChanged}
            tabs={tabs}
            tabTemplate={TabTemplate}
            firstTabChild={
              isBiebItem &&
              !section &&
              !edit &&
              mayAdd &&
              allowedSaar(pagetypeAllowed) && (
                <ButtonBar
                  pagetype={pagetype}
                  openDialog={doUseFromBiebDialog}
                  biebRelations={relations.filter(
                    ({ relationTypeId }) =>
                      fromBiebRelationType &&
                      relationTypeId === fromBiebRelationType.id
                  )}
                  lastPublished={lastPublished}
                  isBiebItem
                  fromBiebRelation={fromBiebRelation}
                />
              )
            }
          />
        )}
      </div>
      {!section && narrow && (
        <div className="leftbar">
          <RecentItems />
        </div>
      )}
      {(!section || (section && !hideRightbar)) && (
        <div className="rightbar">
          {firstSpecial && <FirstSpecial {...firstSpecial} />}
          <Rightbar
            itemId={id}
            pagetype={pagetype}
            usePresentation={usePresentation}
            {...rightbar}
            {...rightbarProps}
          />
        </div>
      )}
      {!section &&
        secondaryDrawer.children &&
        includes("fullscreen")(extraClasses) && (
          <Drawer
            docked={false}
            open={secondaryDrawerOpen}
            onRequestChange={(open) => updateSecondaryDrawer(open)}
            openSecondary
            containerStyle={{ padding: "16px" }}
          >
            {secondaryDrawer.children}
          </Drawer>
        )}
    </Wrapper>
  );
};

BasePage.propTypes = {
  id: PropTypes.number.isRequired,
  page: pagePropType,
  header: zonePropType,
  tabs: PropTypes.arrayOf(tabPropType).isRequired,
  firstSpecial: zonePropType,
  rightbar: zonePropType,
  selectedTab: PropTypes.number.isRequired,
  tabChanged: PropTypes.func.isRequired,
  wrapper: PropTypes.string,
};

const simplePageSelector = createSelector(
  [get("id"), flow(get("page.page"), defaultTo(empty.object))],
  (id, { title, pagetype }) => ({ id, title, pagetype })
);

const pageContent = omit(["loading", "_expiry"]);

export default compose(
  setDisplayName("BasePage"),
  pure,
  withProps((props) => ({
    simplePage: simplePageSelector(props),
    pathname: saarHistory.getCurrentLocation().pathname,
  })),
  consoleConnect(
    (store) => ({
      sharedStructureIds:
        store.data.miniSaars.sharedStructureIds ?? empty.array,
    }),
    (dispatch) => ({
      requireSaar: () => {
        window.setTimeout(() => {
          dispatch(requireNavigationAction());
          dispatch(requireOverzichtAction());
          dispatch(requireSelectionListsAction());
          dispatch(requirePagetypeListAction());
          dispatch(requireRelationTypesAction());
        }, 100);
      },
    })
  ),
  execOnChange("requireSaar", "pathname"),
  updateOnDataChanged(
    "enableCreateLegoblok",
    "error",
    "extraClasses",
    "firstSpecial",
    "header",
    "id",
    "imageLightbox",
    "legoblokBusy",
    "loading",
    "miniSaars",
    "narrow",
    "page",
    "pageActions",
    "relations",
    "reloading",
    "rightbar",
    "secondaryDrawer",
    "secondaryDrawerOpen",
    "selectedTab",
    "tabs",
    "types",
    "user",
    "viewport"
  ),
  execOnChange(
    ({
      selectedTab = 0,
      tabChanged = empty.func,
      pageActions,
      edit = flown(pageActions, get("edit"), defaultTo(false)),
      tabs = empty.array,
    }) => {
      const firstNonEmpty = tabs.filter(isNonEmptyTab)[0];
      const nextSelectedTab =
        !edit && firstNonEmpty && !isNonEmptyTab(tabs[selectedTab])
          ? findIndex(({ alias }) => alias === firstNonEmpty.alias)(tabs)
          : selectedTab;
      if (nextSelectedTab !== selectedTab) {
        tabChanged(nextSelectedTab);
      }
    },
    (
      { page: prevPage = empty.object, pageActions: { edit: prevEdit } },
      {
        page: nextPage = empty.object,
        pageActions: { edit: nextEdit, busy: nextBusy },
      }
    ) =>
      (prevPage !== nextPage &&
        !isEqual(pageContent(prevPage))(pageContent(nextPage))) ||
      (prevEdit && !nextEdit && !nextBusy)
  )
)(BasePage);
