import PropTypes from "prop-types";
import React, { Fragment, useEffect } from "react";
import { useDispatch } from "react-redux";

import { snackbarShowAction } from "../actions/ui";

const fixedErrors = Object.freeze({
  0: "Onbekende fout.",
  100: "Gaat u verder.",
  299: "U heeft een nieuw bericht.",
  400: "Ongeldig verzoek aan de server.",
  401: "Uw gebruikersnaam of wachtwoord is niet correct.",
  403: "Onvoldoende rechten voor de actie.",
  404: "Gegevens niet gevonden.",
  408: "De server geeft niet tijdig antwoord.",
  409: "Dit account heeft geen toegang tot Saar.",
  499: "De toegangscode is niet correct.",
  500: "Fout op de server.",
});

const Console = ({ error }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    const notify = error.notify;
    const status = error.status;
    const friendly = error.friendly;
    const operationId = error.operationId;
    if (!notify) {
      return;
    }

    const headLine =
      friendly || fixedErrors[status] || status || fixedErrors[0];
    const logIdRef = operationId ? ` (log id = #${operationId})` : "";
    const logLine = `De fout is gelogd en wordt door ons onderzocht${logIdRef}.`;
    const message = (
      <>
        <span style={{ fontWeight: 500 }}>{headLine}</span>
        <br />
        <span style={{ fontSize: "smaller", fontStyle: "italic" }}>
          {logLine}
        </span>
      </>
    );
    dispatch(
      snackbarShowAction(message, {
        persistent: Boolean(operationId),
        singleLine: false,
      })
    );
  }, [error.notify, error.status, error.friendly, error.operationId, dispatch]);

  return null;
};

Console.propTypes = {
  error: PropTypes.object.isRequired,
};

export default Console;
