import {
  Checkbox,
  FlatButton,
  MenuItem,
  SelectField,
  TextField,
} from "material-ui";
import React, { useCallback, useEffect, useState } from "react";

import { CopyItemModel } from "../business/models";
import Dialog from "../containers/dialog";
import HintCard from "./material/HintCard";

const CopyItemDialog = ({
  source,
  busy,
  onClose,
  onCopy,
  structures,
}: {
  source: CopyItemModel;
  busy: boolean;
  onClose: () => void;
  onCopy: (data: CopyItemModel) => void;
  structures: { id: number; name: string }[];
}) => {
  const [data, setData] = useState(source);

  const handleLabelChange = useCallback(
    (event: unknown, newValue: string) => {
      setData({ ...data, label: newValue });
    },
    [data]
  );

  const handleStructureChange = useCallback(
    (event: unknown, index: number, selectedValue: number) => {
      setData({ ...data, structureId: selectedValue });
    },
    [data]
  );

  useEffect(() => {
    if (structures.length === 1 && !data.structureId) {
      // select single structure, no selectbox
      setData({ ...data, structureId: structures[0].id });
    }
  }, [data, structures]);

  const handleCheckboxChange = useCallback(
    (event: unknown, isInputChecked: boolean) => {
      setData({ ...data, withHierarchyRelations: isInputChecked });
    },
    [data]
  );

  const handleCopy = useCallback(() => {
    onCopy(data);
  }, [data, onCopy]);

  return (
    <Dialog
      title="Pagina kopiëren"
      open
      autoScrollBodyContent
      actions={[
        <FlatButton
          key="close"
          secondary
          label="Annuleren"
          onClick={onClose}
        />,
        <FlatButton
          primary
          key="save"
          label="Kopie maken"
          onClick={handleCopy}
          disabled={busy || source.label === data.label || !data.structureId}
        />,
      ]}
    >
      <TextField
        autoFocus
        hintText="Naam"
        floatingLabelText="Voer een naam in"
        onChange={handleLabelChange}
        value={data.label}
        disabled={busy}
      />

      <br />

      {structures.length > 1 && (
        <SelectField
          floatingLabelText="Kies een locatie"
          onChange={handleStructureChange}
          value={data.structureId}
        >
          {structures.map(({ id, name }) => (
            <MenuItem key={id} value={id} primaryText={name} />
          ))}
        </SelectField>
      )}

      <div style={{ margin: "8px 0 24px 0" }}>
        <Checkbox
          checked={data.withHierarchyRelations}
          onCheck={handleCheckboxChange}
          label="Onderliggende items meenemen (indien beschikbaar)"
        />
      </div>

      <HintCard
        primaryText="Wijzig de naam"
        secondaryText="De naam van de kopie dient te verschillen van het origineel."
      />
    </Dialog>
  );
};

export default CopyItemDialog;
