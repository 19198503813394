import { composeAction } from "async-lifecycle";
import empty from "empty";
import { has } from "lodash/fp";
import { hashHistory } from "react-router";

import { getLegoblokStructures } from "../api";
import { firstSlug } from "../selectors";

export const legoblokStructuresAction = () =>
  composeAction({
    group: "CMS_LEGOBLOK_STRUCTURES",
    fire: () => getLegoblokStructures(),
    cachekey: "addLegoblok",
    cacheValidation: (found, getState) => {
      if (!has("siteId")(found)) {
        return false;
      }
      const { session: { miniSaar = empty.object } = empty.object } =
        getState();
      const { pathname } = hashHistory.getCurrentLocation();
      return (
        firstSlug(pathname) === miniSaar.path &&
        found.siteId === miniSaar.siteId
      );
    },
  });
