import "moment/locale/nl";

import empty from "empty";
import moment from "moment";
import * as momentTZ from "moment-timezone";
import numeral from "numeral";
import locale from "numeral/src/locales/nl-nl";

import config from "./config";

let addI18n = empty.func;
/* eslint-disable global-require */
if (config.loadTinyMce) {
  require("tinymce");
  require("tinymce/themes/modern/theme.min");
  require("tinymce/plugins/autolink/plugin.min");
  require("tinymce/plugins/image/plugin.min");
  require("tinymce/plugins/imagetools/plugin.min");
  require("tinymce/plugins/link/plugin.min");
  require("tinymce/plugins/lists/plugin.min");
  require("tinymce/plugins/paste/plugin.min");
  require("tinymce/plugins/searchreplace/plugin.min");
  require("tinymce/plugins/table/plugin.min");
  require("tinymce/skins/lightgray/skin.min.css");
  require("tinymce/skins/lightgray/content.inline.min.css");
  addI18n = require("./business/tinymce-nl").default;
}
/* eslint-enable global-require */

const boot = () => {
  addI18n();
  momentTZ.tz.setDefault("Europe/Amsterdam");
  moment.locale("nl");
  numeral.locale("nl-nl", locale);
  numeral.locale("nl-nl");
};
export default boot;
