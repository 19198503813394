import { loadingProgress } from "async-lifecycle";
import empty from "empty";
import { defaultTo, find, get, includes, map } from "lodash/fp";
import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router";
import { compose } from "recompose";

import { redirectLocalAction } from "../actions/common";
import { getSinglePageAction } from "../actions/data";
import { legoblokStructuresAction } from "../actions/legoblok.structures";
import PageRefreshIndicator from "../components/pagetypes/PageRefreshIndicator";
import { flown } from "../lodash";
import connect from "./connect";

const RaamwerkRedirect = ({
  selectionLists,
  navigation,
  redirect,
  params: { slug, fromItemId },
}) => {
  const dispatch = useDispatch();
  const [fromPage, legoblok] = useSelector(
    ({ data: { pages, addLegoblok } }) => [pages[fromItemId], addLegoblok]
  );

  useEffect(() => {
    if (fromItemId && !fromPage?.id) {
      dispatch(getSinglePageAction(fromItemId));
    }
    if (fromItemId && !legoblok.structures) {
      dispatch(legoblokStructuresAction());
    }
  }, [legoblok.structures, dispatch, fromItemId, fromPage]);

  const loading =
    loadingProgress(fromPage?.loading ?? "init") ||
    loadingProgress(legoblok?.loading);

  const isFromBiebItem = useMemo(() => {
    if (!fromPage || !legoblok.structures) {
      return false;
    }

    return (
      includes(fromPage.siteId)(map("id")(legoblok.structures)) &&
      fromPage.page.pagetype !== "legoblok"
    );
  }, [legoblok.structures, fromPage]);

  if (!selectionLists || !navigation || (fromItemId && loading)) {
    return (
      <div className="login-loader">
        <PageRefreshIndicator />
      </div>
    );
  }

  const alias = flown(
    selectionLists,
    find({ id: parseInt(slug, 10) }),
    get("alias")
  );
  const raamwerk = flown(
    navigation,
    get(isFromBiebItem ? "raamwerkItemsBieb" : "raamwerkItems"),
    find(
      ({ type, status }) =>
        type === "raamwerk" && status && status.alias === alias
    ),
    defaultTo(get("homeLink")(navigation))
  );

  if (raamwerk) {
    redirect(`page/${raamwerk.itemId}/${raamwerk.slug}`);
  }

  return <></>;
};

export default compose(
  withRouter,
  connect(
    ({
      data: {
        navigation,
        selectionLists: { data: selectionLists = empty.array },
      },
    }) => ({ navigation, selectionLists }),
    (dispatch) => ({
      redirect: (path) =>
        window.setTimeout(() => dispatch(redirectLocalAction(path)), 0),
    })
  )
)(RaamwerkRedirect);
