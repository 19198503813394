import { indigo500 as raamwerkColor } from "material-ui/styles/colors";
import AlertWarning from "material-ui/svg-icons/alert/warning";
import React, { memo } from "react";

import { Disabled, Meta } from "../../ggvtyp/raamwerk";
import CountedIcon from "./countedIcon";
import { TreeItemStateProps } from "./models";
import RemarkButton from "./remarkButton";

const TreeItemState = memo<TreeItemStateProps>(
  ({
    edit,
    allowed,
    statusItems,
    statusLinkCount,
    emptyItemCount,
    disabledSelectionItem,
    selectionId,
    remark,
    openRemarkDialog,
    hasStagedRemark,
  }) => (
    <span style={{ float: "right", marginLeft: edit ? 8 : 0 }}>
      {!disabledSelectionItem && emptyItemCount > 0 && (
        <CountedIcon count={emptyItemCount} title="Niet gekoppeld">
          <AlertWarning style={{ color: raamwerkColor }} />
        </CountedIcon>
      )}
      {(disabledSelectionItem || emptyItemCount === 0) && edit && (
        <span className="raamwerk_alert_spacer" />
      )}
      <RemarkButton
        edit={edit && allowed.canRemarkSelectionItem}
        selectionId={selectionId}
        remark={remark}
        openDialog={openRemarkDialog}
        hasStagedRemark={hasStagedRemark}
      />
      {statusItems.map(({ id, alias = "", value }) => (
        <CountedIcon key={id} count={statusLinkCount[alias] || 0}>
          {disabledSelectionItem ? (
            <Disabled key={id} item={{ alias, value }} />
          ) : (
            <Meta item={{ alias, value }} />
          )}
        </CountedIcon>
      ))}
    </span>
  )
);

export default TreeItemState;
