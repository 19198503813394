import { cancelResult, composeAction } from "async-lifecycle";
import empty from "empty";
import {
  find,
  flow,
  get,
  isEqual,
  map,
  orderBy,
  take,
  union,
  without,
} from "lodash/fp";

import { searchFull, searchLinks } from "../api";
import { updateGuiSettings } from "../api/auth";
import {
  defaultSearchScope,
  isLibraryOnly,
  nonEmptyScope,
} from "../components/search/constants";
import config from "../config";
import { flown } from "../lodash";
import { legoblokStructuresAction } from "./legoblok.structures";

export const updateAutoCompleteAction = () => (dispatch, getState) => {
  const {
    form: {
      searchQuery: { search: query = empty.object },
    },
  } = getState();
  if (query.trefwoord && !config.msie) {
    dispatch(
      composeAction({
        group: "AUTOCOMPLETE_SEARCH",
        fire: (...args) =>
          searchLinks(...args).then((result) =>
            get("form.searchQuery.search")(getState()) === query
              ? result
              : cancelResult
          ),
        args: [{ ...query, pageSize: 10, useWildcard: true }],
      })
    );
  }
};

export const clearAutoCompleteAction = () => ({
  type: "AUTOCOMPLETE_SEARCH_CLEAR",
  payload: empty.object,
});

const globalSearchAction =
  (full = false) =>
  (dispatch, getState) => {
    const { query, scope } = get("form.globalQuery")(getState());
    const wildcardQuery = { useWildcard: true, ...query };
    dispatch(
      composeAction({
        group: "GLOBAL_SEARCH",
        fire: (...args) =>
          (full ? searchFull : searchLinks)(...args).then((result) =>
            get("form.globalQuery.query")(getState()) === query
              ? result
              : cancelResult
          ),
        args: [undefined, wildcardQuery],
        callbackAfter: (
          { documents = empty.array } = empty.object,
          dispatch
        ) => {
          if (documents.length > 0 && isLibraryOnly(scope) && !full) {
            dispatch(globalSearchAction(true));
          }
        },
      })
    );
  };

export const searchByQueryAction = (query, scope) => (dispatch, getState) => {
  const current = getState().form.globalQuery;
  const payload = { query, scope };
  if (isEqual(current)(payload)) {
    return;
  }

  dispatch({
    type: "GLOBALQUERY_SET",
    payload,
  });
  dispatch(globalSearchAction());
};

export const searchByQueryClearAction = () => ({
  type: "GLOBAL_SEARCH_CLEAR",
  payload: empty.object,
});

export const updateQueryAction = (uid, property, value) => ({
  type: "QUERY_SET",
  payload: {
    uid,
    property,
    value,
  },
});

const getScopeQuery = (
  { site, library, allSites } = defaultSearchScope,
  biebStructures = empty.array,
  siteStructures = empty.array,
  statusIds = empty.array,
  sharedStructureIds = empty.array
) => {
  const libraryIds = map("id")(biebStructures);
  const siteIds = flown(
    siteStructures,
    map("siteId"),
    union(sharedStructureIds),
    without(libraryIds)
  );
  const searchInAllSites = library || allSites;
  const forceViewInSite = !allSites;
  const includeStructures = allSites
    ? empty.array
    : [
        ...(library ? libraryIds : empty.array),
        ...(site ? siteIds : empty.array),
      ];
  const excludeStructures = [
    ...(library ? empty.array : libraryIds),
    ...(site ? empty.array : siteIds),
  ];

  return {
    searchInAllSites,
    forceViewInSite,
    includeStructures,
    excludeStructures,
    statusIds,
  };
};

export const getQuery = (
  trefwoord = "",
  scope = defaultSearchScope,
  biebStructures = empty.array,
  siteStructures = empty.array,
  statusIds = empty.array,
  sharedStructureIds = empty.array
) => ({
  ...getScopeQuery(
    scope,
    biebStructures,
    siteStructures,
    statusIds,
    sharedStructureIds
  ),
  trefwoord,
});

export const updateScopeAction =
  (nextScope, nextStructures) => (dispatch, getState) => {
    const {
      data: {
        addLegoblok: {
          structures: prevStructures = empty.array,
        } = empty.object,
        miniSaars: {
          data = empty.array,
          sharedStructureIds = empty.array,
        } = empty.object,
      },
      form: {
        searchQuery: {
          ui: { scope: prevScope } = empty.object,
          search: { statusIds = empty.array } = empty.object,
        } = empty.object,
      },
      session: {
        miniSaar: { siteId = 0 },
      },
    } = getState();
    const siteStructures = flown(data, find({ siteId }), get("sites"));
    const scope = nonEmptyScope(nextScope || prevScope || defaultSearchScope);
    const scopeQuery = getScopeQuery(
      scope,
      nextStructures || prevStructures,
      siteStructures,
      statusIds,
      sharedStructureIds
    );
    for (const [key, value] of Object.entries(scopeQuery)) {
      dispatch(updateQueryAction("search", key, value));
    }

    dispatch(clearAutoCompleteAction());
    dispatch(updateQueryAction("ui", "scope", scope));
    dispatch(updateAutoCompleteAction());
  };

export const requireSearchScope =
  ({
    location: { pathname = "" } = empty.object,
    params: { scope } = empty.object,
  }) =>
  (dispatch) =>
    dispatch(
      composeAction(legoblokStructuresAction(), {
        callbackSuccess: ({ structures }, dispatch) => {
          if (pathname.indexOf("/search") > -1) {
            dispatch(clearAutoCompleteAction());
          }

          dispatch(updateScopeAction(scope, structures));
        },
      })
    );

const releasesQuery = Object.freeze({
  searchInAllSites: true,
  forceViewInSite: true,
  paginatypen: ["nieuws"],
  pageSize: 100,
});
const fromDtmTyd = (dtmTyd) => {
  if (typeof dtmTyd !== "string" || dtmTyd.length !== 12) {
    return undefined;
  }

  return new Date(
    dtmTyd.replace(
      /([0-9]{4})([0-9]{2})([0-9]{2})([0-9]{2})([0-9]{2})/,
      "$1-$2-$3T$4:$5"
    )
  );
};
export const requireReleasesAction = (dispatch, getState) => {
  const releases =
    get("session.gui.releases.dateTime")(getState()) || new Date(0);
  return dispatch(
    composeAction({
      group: "RELEASES",
      cachekey: "releases",
      fire: () =>
        searchLinks(0, releasesQuery).then(({ documents }) => {
          const list = flow(
            orderBy(["fields.pubDtmTyd"], ["desc"]),
            take(10)
          )(documents);
          return {
            list,
            fresh: list.filter(
              ({ fields: { pubDtmTyd } }) => fromDtmTyd(pubDtmTyd) > releases
            ).length,
          };
        }),
      args: [],
    })
  );
};

export const dismissReleasesAction = (dispatch) => {
  const now = new Date();
  dispatch({
    type: "RELEASES_PATCH",
    payload: { fresh: 0 },
  });
  dispatch({
    type: "GUI_PATCH",
    payload: { releases: { dateTime: now } },
  });
  updateGuiSettings({
    key: "releases",
    dateTime: new Date(now - now.getTimezoneOffset() * 60000).toJSON(),
  });
};
