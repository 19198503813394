import empty from "empty";
import { compose, setDisplayName } from "recompose";

import { uploadImageAction } from "../../actions/page";
import Html from "../../components/ggvtyp/html";
import { dispatchWrap } from "../../utils";
import connect from "../connect";

const mapState = () => empty.object;

const mapDispatch = (dispatch) => ({
  uploadImage: dispatchWrap(uploadImageAction, dispatch),
});

export default compose(
  setDisplayName("Html"),
  connect(mapState, mapDispatch)
)(Html);
