import React from "react";
import { Router } from "react-router";

import routes from "./routes";
import createSaarStore, { StoreProvider, saarHistory } from "./store";

const App = () => {
  const { dispatch } = createSaarStore();
  return (
    <StoreProvider>
      <Router history={saarHistory} routes={routes(dispatch)} />
    </StoreProvider>
  );
};

export default App;
