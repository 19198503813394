/* eslint  react/prop-types: off */
import empty from "empty";
import { get } from "lodash/fp";
import React from "react";
import Helmet from "react-helmet";
import { useSelector } from "react-redux";
import { hashHistory } from "react-router";

import { toQueryString } from "../api/fetch";
import Pagetype from "../containers/pagetype";

const appTitle = "Saar";

/**
 * Page component
 */
const Page = (props) => {
  const {
    section,
    page: { page: { pagetype, title } = empty.object } = empty.object,
    location: {
      pathname,
      query: { tab, clusterId } = empty.object,
      query,
    } = empty.object,
  } = props;

  // Redirect `clusterId` link to correct tab
  const tabsForCluster = useSelector(get("sagas.tabForCluster"));
  if (clusterId && tab === undefined) {
    if (!tabsForCluster.status.loading && tabsForCluster.value) {
      // loading ready => redirect to tab
      const queryString = toQueryString({
        ...query,
        tab: tabsForCluster.value.tab ?? 0,
      });
      hashHistory.replace(`${pathname}?${queryString}`);
    }

    return null;
  }

  return (
    <div>
      {!section && (
        <Helmet title={title ? `${title} - ${appTitle}` : appTitle} />
      )}
      <Pagetype pagetype={pagetype} {...props} />
    </div>
  );
};

export default Page;
