import { loadingProgress } from "async-lifecycle";
import classNames from "classnames";
import empty from "empty";
import { get, map, reject, uniqBy } from "lodash/fp";
import FlatButton from "material-ui/FlatButton";
import IconButton from "material-ui/IconButton";
import ActionDelete from "material-ui/svg-icons/action/delete";
import AttachFile from "material-ui/svg-icons/editor/attach-file";
import PropTypes from "prop-types";
import React from "react";
import {
  compose,
  defaultProps,
  setDisplayName,
  withHandlers,
  withProps,
} from "recompose";

import {
  oneDriveFileShapes,
  pageActionsShape,
} from "../../business/prop-types";
import { execOnChange } from "../../recompose.contrib";
import { propTypes, withDefaultPageActions } from "./ggvtyp";

const OneDriveLink = withHandlers({
  handleDelete:
    ({ onDelete, id }) =>
    () =>
      onDelete(id),
})(({ uri, label, className, edit, accessToken, handleDelete }) => (
  <div className="onedrive-filename-wrapper" key={uri}>
    <span className={classNames("onedrive-filename", className)}>
      <a href={uri} target="_blank" rel="noopener noreferrer">
        {label}
      </a>
    </span>
    {edit && accessToken && (
      <IconButton
        style={{
          display: "inline-block",
          width: 24,
          height: 24,
          border: 0,
          padding: 0,
        }}
        onClick={handleDelete}
        title="Verwijder bestand"
        className="verwijder-bestand"
      >
        <ActionDelete />
      </IconButton>
    )}
  </div>
));

OneDriveLink.defaultProps = { className: undefined };

OneDriveLink.propTypes = {
  uri: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  accessToken: PropTypes.string,
  id: PropTypes.string.isRequired,
  edit: PropTypes.bool,
  onDelete: PropTypes.func.isRequired,
};

/**
 * Gegevenstype 'OneDrive'
 */
const OneDrive = ({
  value,
  className,
  pageActions: { edit },
  accessToken,
  handleOpenOneDrive,
  handleDelete,
  loading,
}) => {
  const { files: newFiles = empty.array } = value || empty.object;
  return (
    <div className={className}>
      <div className="onedrive-list">
        {map(({ id, uri, label }) => (
          <OneDriveLink
            key={id}
            id={id}
            uri={uri}
            label={label}
            className={classNames({ edit })}
            onDelete={handleDelete}
            edit={edit}
            accessToken={accessToken}
          />
        ))(newFiles)}
      </div>
      {loading && <div>Even geduld...</div>}
      {edit && accessToken && (
        <FlatButton
          primary
          label="Kies bestand"
          onClick={handleOpenOneDrive}
          icon={<AttachFile />}
        />
      )}
    </div>
  );
};

OneDrive.propTypes = {
  ...propTypes,
  value: oneDriveFileShapes,
  pageActions: pageActionsShape,
  accessToken: PropTypes.string,
  handleOpenOneDrive: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

const enhance = compose(
  setDisplayName("OneDrive"),
  defaultProps({
    className: "onedrive",
  }),
  withDefaultPageActions(),
  withProps(({ oneDriveFile: { loading } = empty.object }) => ({
    loading: loadingProgress(loading),
  })),
  withHandlers({
    handleDelete:
      ({ setValue, value: origValue }) =>
      (id) => {
        const { files: origFiles = empty.array } = origValue || empty.object;
        const files = reject({ id })(origFiles);
        const value = { ...origValue, files };
        setValue({ value, string: JSON.stringify(value) });
      },

    handleSetValue:
      ({ setValue, value: origValue = empty.object }) =>
      ({ oneDriveFile: { files = empty.array } = empty.object }) => {
        const { files: origFiles = empty.array, ...rest } = origValue;
        if (files.length > 0) {
          const value = {
            ...rest,
            files: uniqBy("id")([
              ...origFiles,
              ...map(({ id, driveId, webUrl, name }) => ({
                id,
                driveId,
                uri: webUrl,
                label: name,
              }))(files),
            ]),
          };

          setValue({ value, string: JSON.stringify(value) });
        }
      },

    handleOpenOneDrive:
      ({
        accessToken,
        clientId,
        fileInfo,
        field = empty.object,
        resetFileInfo,
        oneDriveEnpointHint,
      }) =>
      () => {
        const fieldId = get("id")(field) || -1;
        resetFileInfo();
        window.OneDrive.open({
          action: "query",
          multiSelect: true,
          clientId,
          advanced: {
            accessToken,
            endpointHint: oneDriveEnpointHint,
          },
          success: (files) => {
            /* success handler */
            const value = map(
              ({ id, parentReference: { driveId } = empty.object }) => ({
                id,
                driveId,
              })
            )(files.value);

            // get file info
            return fileInfo(fieldId, value);
          },
          cancel: () => {
            /* cancel handler */
          },
          error: (e) => {
            /* error handler */
            // eslint-disable-next-line no-console
            console.error(e);
          },
        });
      },
  }),
  execOnChange("handleSetValue", "oneDriveFile")
);

export default enhance(OneDrive);
