import empty from "empty";
import { compose, setDisplayName } from "recompose";

import { exportDataAction } from "../actions/utils";
import ItemList from "../components/list";
import { dispatchWrap } from "../utils";
import connect from "./connect";

export default compose(
  setDisplayName("ItemList"),
  connect(
    () => empty.object,
    (dispatch) => ({
      exportData: dispatchWrap(exportDataAction, dispatch),
    })
  )
)(ItemList);
