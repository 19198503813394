import { compose, setDisplayName } from "recompose";

import Raamwerk from "../../components/ggvtyp/raamwerk";
import { RaamwerkProps } from "../../components/ggvtyp/raamwerk.models";
import { raamwerkFieldSelector } from "../../selectors/definition";
import connect from "../connect";

export default compose<RaamwerkProps, RaamwerkProps>(
  setDisplayName("Raamwerk"),
  connect(raamwerkFieldSelector)
)(Raamwerk);
