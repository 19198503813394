import {
  defaultTo,
  eq,
  filter,
  flatten,
  flow,
  get,
  map,
  pick,
  uniq,
} from "lodash/fp";
import { connect } from "react-redux";
import { compose, pure, setDisplayName } from "recompose";
import { createSelector } from "reselect";

import { redirectAction } from "../../actions/common";
import { getSinglePageAction } from "../../actions/data";
import { chainRelationsAction } from "../../actions/relation";
import { flown } from "../../lodash";
import { execOnChange } from "../../recompose.contrib";
import { chainRelationsSelector } from "../../selectors/relations";
import enhanceLandscape from "../enhanceLandscape";

const chainModel = Object.freeze({
  chains: [
    {
      from: {
        aliases: [
          "entiteit_applicatiegegeven",
          "kerngegeven_applicatiegegeven",
          "entiteit_applicatieveld",
          "kerngegeven_applicatieveld",
        ],
        side: "left",
      },
      to: {
        aliases: ["applicatie_verwerker"],
        side: "left",
      },
    },
    {
      needsClusters: true,
      from: {
        aliases: ["entiteit_applicatieveld", "kerngegeven_applicatieveld"],
        side: "left",
      },
      to: { aliases: ["applicatieveld_module"], side: "left" },
    },
    {
      needsClusters: true,
      from: {
        aliases: ["entiteit_applicatieveld", "kerngegeven_applicatieveld"],
        side: "left",
      },
      to: { aliases: ["veld_koppeling_veld"], side: "left", using: true },
    },
    {
      needsClusters: true,
      from: {
        aliases: ["entiteit_applicatieveld", "kerngegeven_applicatieveld"],
        side: "left",
      },
      to: { aliases: ["veld_koppeling_veld"], side: "right", using: true },
    },
  ],
});

const verantwoordingIds = flow(
  map("using"),
  flatten,
  filter(flow(get("type"), eq("verantwoording"))),
  map("itemId"),
  uniq
);

const verantwoordingSelector = createSelector(
  [get("data.pages"), (_, { relations }) => relations],
  (pages, relations) => pick(verantwoordingIds(relations))(pages)
);

export const enhanceEntiteit = compose(
  setDisplayName("Entiteit"),
  pure,
  connect(
    (state, props) => ({
      chainRelations: chainRelationsSelector(state, props),
      verantwoording: verantwoordingSelector(state, props),
    }),
    (dispatch, { relations }) => ({
      gotoVerantwoordingen: ({ itemId, slug }) =>
        dispatch((_, getState) => {
          const path = flown(
            getState(),
            get("session.miniSaar.path"),
            defaultTo("iznet")
          );
          dispatch(redirectAction(`/${path}/page/${itemId}/${slug}`));
        }),
      requireVerantwoording: () => {
        for (const id of verantwoordingIds(relations)) {
          dispatch(getSinglePageAction(id));
        }
      },
      init: ({ id: itemId }) => {
        dispatch(chainRelationsAction(itemId, chainModel));
      },
    })
  ),
  execOnChange("init", "id"),
  execOnChange("requireVerantwoording", "relations"),
  enhanceLandscape
);
