import {
  compact,
  filter,
  flatten,
  flattenDeep,
  groupBy,
  has,
  map,
  orderBy,
  uniq,
} from "lodash/fp";
import React from "react";

import { Relation, emptyArray, emptyObject } from "../business/models";
import { flown } from "../lodash";
import { deepClusterIds } from "./content/query";
import pagetypeIcons, { MaterialSvgIcon } from "./pagetypes/icons";

export const showRelatedPagetypes =
  (itemId: number) =>
  (cluster: unknown, name: string, props: { relations: Relation[] }) => {
    const relations: Relation[] = has("relations.[0].links")(props)
      ? flown(
          props.relations,
          map((r) => (r as { links: Relation[] }).links ?? r),
          flatten,
          map("relation")
        )
      : props.relations;

    const filteredRelations = flown(
      relations,
      filter(
        ({ left = emptyObject(), right = emptyObject() }: Relation): boolean =>
          (left.itemId === itemId && Boolean(left.pageClusterId)) ||
          (right.itemId === itemId && Boolean(right.pageClusterId))
      )
    );

    const clusterIdsWithRelation = new Set(
      flown(
        filteredRelations,
        map(
          ({
            left = emptyObject(),
            right = emptyObject(),
            using = emptyArray(),
          }: Relation) => [
            left.itemId === itemId
              ? left.pageClusterId
              : right.itemId === itemId
              ? right.pageClusterId
              : using.find((u) => u.itemId === itemId)
              ? using[0].pageClusterId
              : null,
          ]
        ),
        flatten,
        compact
      )
    );

    const clusterPagetypeMapping = flown(
      filteredRelations,
      map(
        ({
          left = emptyObject(),
          right = emptyObject(),
          using = emptyArray(),
        }: Relation) => [
          left.itemId === itemId
            ? left.type === right.type && using.length > 0
              ? { pageClusterId: left.pageClusterId, type: using[0].type }
              : { pageClusterId: left.pageClusterId, type: right.type }
            : null,
          right.itemId === itemId
            ? left.type === right.type && using.length > 0
              ? { pageClusterId: right.pageClusterId, type: using[0].type }
              : { pageClusterId: right.pageClusterId, type: left.type }
            : null,
          using.find((u) => u.itemId === itemId)
            ? [
                {
                  pageClusterId: using[0].pageClusterId,
                  type: left.type,
                },
                {
                  pageClusterId: using[0].pageClusterId,
                  type: right.type,
                },
              ]
            : null,
        ]
      ),
      flattenDeep,
      compact,
      groupBy((i: { pageClusterId: number }) => i.pageClusterId)
    );

    const filtered = flown(
      cluster,
      deepClusterIds,
      filter((cid) => clusterIdsWithRelation.has(cid))
    );

    if (filtered.length === 0) {
      return undefined;
    }

    const types = flown(
      filtered,
      map((cid: number) => clusterPagetypeMapping[cid]),
      flatten,
      compact,
      orderBy(["type"], ["asc"]),
      map("type"),
      uniq
    );

    return <ShowPagetypeIcons types={types} />;
  };

const ShowPagetypeIcons = ({ types }: { types: string[] }) => {
  if (types.length === 0) {
    return null;
  }

  return (
    <span
      style={{
        display: "inline-block",
        marginLeft: "1em",
        verticalAlign: "middle",
        opacity: 0.7,
        position: "absolute",
        top: 8,
        right: 48,
      }}
    >
      {map((t: string) => {
        const Icon = pagetypeIcons[t] as MaterialSvgIcon;
        return (
          <span key={t} title={`Heeft ${t}`}>
            <Icon />
          </span>
        );
      })(types)}
    </span>
  );
};
export default ShowPagetypeIcons;
