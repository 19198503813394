import PropTypes from "prop-types";
import React, { useMemo } from "react";

import { entiteitShape, linkIdShape } from "../../../business/prop-types";
import { Label } from "../../content/field";
import ClusterPicker from "../../pickers/ClusterPicker";

const gegevenItem =
  (itemId) =>
  ({ id: pageClusterId, naam: label }) => ({
    itemId,
    pageClusterId,
    label,
    type: "kerngegeven_applicatiegegeven",
    items: [],
  });

const entiteitItem = ({ id: itemId, naam: label, gegevens }) => ({
  itemId,
  label,
  type: "entiteit_applicatiegegeven",
  items: gegevens.map(gegevenItem(itemId)),
});

const Value = ({ entiteiten, selected, name, onChange }) => {
  const items = useMemo(() => entiteiten.map(entiteitItem), [entiteiten]);

  return (
    <div className="value">
      <ClusterPicker
        items={items}
        selected={selected}
        onChange={onChange}
        multiple
        labelText="Entiteit"
      />
    </div>
  );
};

Value.propTypes = {
  entiteiten: PropTypes.arrayOf(entiteitShape.isRequired).isRequired,
  selected: PropTypes.arrayOf(linkIdShape.isRequired).isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const KernGegeven = ({ label, ...valueProps }) => (
  <div className="field field-edit cluster-relation">
    <Label>{label}</Label>
    <Value {...valueProps} />
  </div>
);
KernGegeven.propTypes = {
  ...Value.propTypes,
  label: PropTypes.string.isRequired,
};

export default KernGegeven;
