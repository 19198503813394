import MenuItem from "material-ui/MenuItem";
import Done from "material-ui/svg-icons/action/done";
import Block from "material-ui/svg-icons/content/block";
import NavigationArrowDropDown from "material-ui/svg-icons/navigation/arrow-drop-down";
import React, { memo, useCallback } from "react";

import IconButtonMenu from "../shared/iconButtonMenu";
import { NvtMenuProps } from "./models";

const NvtMenu = memo<NvtMenuProps>(
  ({ selectionId, onChange, disabled, disabledNvtItem }) => {
    const handleMarkAsDisabled = useCallback(() => {
      onChange(selectionId, true);
    }, [onChange, selectionId]);

    const handleMarkAsEnabled = useCallback(() => {
      onChange(selectionId, false);
    }, [onChange, selectionId]);

    return (
      <IconButtonMenu
        title="Extra"
        className="raamwerk_item_nvt"
        buttonIcon={<NavigationArrowDropDown />}
        subheader="Acties op dit onderdeel"
      >
        {disabled ? (
          <MenuItem
            leftIcon={<Done />}
            primaryText="Van toepassing"
            onClick={handleMarkAsEnabled}
            disabled={disabledNvtItem}
          />
        ) : (
          <MenuItem
            leftIcon={<Block />}
            primaryText="Niet van toepassing"
            onClick={handleMarkAsDisabled}
            disabled={disabledNvtItem}
          />
        )}
      </IconButtonMenu>
    );
  }
);

export default NvtMenu;
