import RaisedButton from "material-ui/RaisedButton";

import { saarHistory } from "../../../store";

export const renderHomeLink = () => (
  <div style={{ textAlign: "center" }}>
    <RaisedButton
      secondary
      onClick={() => saarHistory.push("/")}
      label="Naar startpunt"
    />
  </div>
);
