/* eslint  react/prop-types: off */
import empty from "empty";
import { includes } from "lodash/fp";
import IconButton from "material-ui/IconButton";
import IconMenu from "material-ui/IconMenu";
import MenuItem from "material-ui/MenuItem";
import Subheader from "material-ui/Subheader";
import ActionDelete from "material-ui/svg-icons/action/delete";
import ActionHistory from "material-ui/svg-icons/action/history";
import LibraryBooks from "material-ui/svg-icons/av/library-books";
import ContentClear from "material-ui/svg-icons/content/clear";
import ContentCopy from "material-ui/svg-icons/content/content-copy";
import ContentLink from "material-ui/svg-icons/content/link";
import MoreVertIcon from "material-ui/svg-icons/navigation/more-vert";
import React, { Fragment, useCallback } from "react";
import { useDispatch } from "react-redux";
import {
  compose,
  defaultProps,
  mapProps,
  setDisplayName,
  withHandlers,
  withState,
} from "recompose";

import { noLegoBlokTypes } from "../business";
import { execOnChange } from "../recompose.contrib";
import VersionsDrawer from "./versionsDrawer";

const EditMenu = ({
  handleDeleteItem,
  handleLinkItem,
  handleUnlinkItem,
  toggleOpen,
  canDelete = false,
  canAddToBieb = false,
  canLinkItem = false,
  canUnlinkItem = false,
  handleAddToBieb,
  disabled = false,
  handleVersions,
  handleCloseVersions,
  versions,
  versionsDrawer: { show } = empty.object,
  setVersionDiff,
  canCopyItem = false,
}) => {
  const dispatch = useDispatch();
  const handleCopyItemDialog = useCallback(() => {
    dispatch({
      type: "TOGGLE_COPYDIALOG",
      payload: { open: true },
    });
  }, [dispatch]);

  return (
    <Fragment>
      <IconMenu
        iconButtonElement={
          <IconButton disabled={disabled} className="item-actions">
            <MoreVertIcon color="#ffffff" />
          </IconButton>
        }
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        targetOrigin={{ horizontal: "left", vertical: "top" }}
        onRequestChange={toggleOpen}
        title={"Acties met deze pagina"}
      >
        <Subheader>Acties met deze pagina</Subheader>
        {canDelete && (
          <MenuItem
            primaryText={"Verwijderen"}
            onClick={handleDeleteItem}
            rightIcon={<ActionDelete />}
          />
        )}
        {canAddToBieb && (
          <MenuItem
            primaryText={"Toevoegen aan Saar Bieb"}
            onClick={handleAddToBieb}
            rightIcon={<LibraryBooks />}
          />
        )}
        {canLinkItem && (
          <MenuItem
            primaryText={"Koppelen aan Saar Bieb"}
            onClick={handleLinkItem}
            rightIcon={<ContentLink />}
          />
        )}
        {canUnlinkItem && (
          <MenuItem
            primaryText={"Ontkoppelen van Saar Bieb"}
            onClick={handleUnlinkItem}
            rightIcon={<ContentClear />}
          />
        )}
        {canCopyItem && (
          <MenuItem
            primaryText={"Pagina kopiëren"}
            onClick={handleCopyItemDialog}
            rightIcon={<ContentCopy />}
          />
        )}
        <MenuItem
          primaryText={"Versiegeschiedenis"}
          onClick={handleVersions}
          rightIcon={<ActionHistory />}
        />
      </IconMenu>
      {show && (
        <VersionsDrawer
          versions={versions}
          open={show}
          handleClose={handleCloseVersions}
          setVersionDiff={setVersionDiff}
        />
      )}
    </Fragment>
  );
};

export default compose(
  setDisplayName("EditMenu"),
  withState("open", "setOpen", false),
  withHandlers({
    toggleOpen:
      ({ setOpen, open }) =>
      () =>
        setOpen(!open),

    handleDeleteItem:
      ({ deleteItem, itemId }) =>
      () => {
        deleteItem(itemId);
      },

    handleLinkItem:
      ({ linkItem, itemId }) =>
      () => {
        linkItem(itemId);
      },

    handleUnlinkItem:
      ({ unlinkItem, itemId, biebLink }) =>
      () => {
        unlinkItem({ saarItemId: itemId, biebItemId: biebLink.itemId });
      },

    handleAddToBieb:
      ({ addToBieb }) =>
      () =>
        addToBieb(true),

    handleCloseVersions:
      ({ showVersionsDrawer }) =>
      () =>
        showVersionsDrawer(false),

    resetVersionsDrawer:
      ({ showVersionsDrawer }) =>
      () =>
        showVersionsDrawer(false),

    handleVersions:
      ({ showVersionsDrawer, setOpen }) =>
      () => {
        showVersionsDrawer(true);
        setOpen(false);
      },
  }),
  defaultProps({
    noLegoBlokTypes,
  }),
  mapProps(
    ({
      user: { mayAddToBieb = false },
      user,
      pagetype,
      insideBieb,
      noLegoBlokTypes,
      biebLink,
      hasBieb,
      ...rest
    }) => ({
      ...rest,
      canDelete: user.mayEdit && pagetype !== "dashboard",
      canCopyItem: user.mayEdit && pagetype !== "dashboard",
      canLinkItem:
        hasBieb &&
        !biebLink &&
        !insideBieb &&
        user.mayEdit &&
        !includes(pagetype)(noLegoBlokTypes),
      canUnlinkItem: user.mayEdit && biebLink !== undefined,
      canAddToBieb:
        hasBieb &&
        !insideBieb &&
        mayAddToBieb &&
        !includes(pagetype)(noLegoBlokTypes),
    })
  ),
  execOnChange("resetVersionsDrawer", "versions")
)(EditMenu);
