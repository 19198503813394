import empty from "empty";
import { compose, setDisplayName, withProps } from "recompose";

import BasePage from "./basePage";

/**
 * Empty page component
 */
const Empty = compose(
  setDisplayName("Empty"),
  withProps(empty.functionThatReturns({ tabs: empty.array }))
)(BasePage);

export default Empty;
