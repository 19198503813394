import { PayloadType, fromStorage, resetAuth, toStorage } from "./auth.common";
import { fetchVariantJSON } from "./fetch";

export const user = () => fromStorage("user");

export const possession = () => fromStorage("possession");

const addToken = (token) => {
  const payloadUser = unqualify(token.payload.u);
  if (user() !== payloadUser) {
    resetAuth();
    toStorage("user", payloadUser);
  }

  toStorage(PayloadType[token.payload.t], token);
  if (token.payload.t === PayloadType.access) {
    toStorage(PayloadType[PayloadType.knowledgeFactor]);
    toStorage("possession");
  }
};

const unqualify = (qualifiedUserName) => {
  if (typeof qualifiedUserName !== "string") {
    return undefined;
  }

  const parts = qualifiedUserName.split("\\");
  return parts[parts.length - 1];
};

export const addAuthenticationResult = (
  { tokens, possession },
  withOffice365 = false,
  withGoogle = false
) => {
  if (!tokens.length) {
    return;
  }

  for (const token of tokens) {
    addToken(token);
  }

  toStorage("possession", possession);
  toStorage("office365", !!withOffice365);
  toStorage("google_sso", !!withGoogle);
};

export const isOfficeUser = () => Boolean(fromStorage("office365"));

export const isExternalUser = () => Boolean(fromStorage("saar_loginsource"));

export const updateGuiSettings = (...settings) =>
  fetchVariantJSON("settings", settings);
