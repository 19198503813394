import empty from "empty";

const fixRegex =
  /^(https?)?:?\/{0,2}((?:[-a-zA-Z0-9]{1,256})(?:\.[-a-zA-Z0-9]{1,256})*)\b(?![.-])(:\d+)?([?/#]?)(.*)/;

export const pattern =
  /(https?):\/{2}((?:[-a-zA-Z0-9]{1,256})(?:\.[-a-zA-Z0-9]{1,256})*)(\.([a-z]{2,256}|\d{1,3})\b(?![.-]))(:\d+)?([?/#][-a-zA-Z0-9@:%_+.~#?&/=]*)?/;

export const transformLink = (value) => {
  const match = pattern.exec(value);
  if (match && match[0] === value) {
    return value;
  }

  const matches = fixRegex.exec(value);
  if (!matches) {
    // This is hopeless
    return value;
  }

  return [
    matches[1] || "http",
    "://",
    matches[2],
    /\.([a-z]{2,256}|\d{1,3})$/i.test(matches[2]) ? "" : ".nl",
    matches[3] || "",
    matches[4] || "/",
    matches[5]
      .trim()
      .replace(/[^-a-zA-Z0-9@:%_+.~#?&/=]/, (match) =>
        encodeURIComponent(match)
      ),
  ].join("");
};

const InternetView = ({
  className,
  value: { address, label } = empty.object,
}) => (
  <a
    className={className}
    href={`${address}`}
    target="_blank"
    rel="noopener noreferrer"
  >
    {label || address}
  </a>
);

export default InternetView;
