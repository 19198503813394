import { loadingProgress } from "async-lifecycle";
import IconButton from "material-ui/IconButton";
import { List, ListItem } from "material-ui/List";
import Popover from "material-ui/Popover";
import {
  purple500 as freshColor,
  purple100 as seenColor,
} from "material-ui/styles/colors";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hashHistory } from "react-router";

import {
  dismissReleasesAction,
  requireReleasesAction,
} from "../../actions/search";
import News from "../../svg/news";
import { formatDate } from "../../utils";
import Subheader from "../material/Subheader";
import PagetypeIcon from "../pagetypeIcon";
import Badged from "./Badged";

const Releases = ({ biebBackgroundColor }) => {
  // Hooks
  const { loading, list, fresh, miniSaar } = useSelector(
    ({
      data: {
        releases: { loading, list, fresh },
      },
      session: {
        miniSaar: { path: miniSaar },
      },
    }) => ({ loading, list, fresh, miniSaar })
  );

  const [time, setTime] = useState(Date.now());
  useEffect(() => {
    const interval = setInterval(() => setTime(Date.now()), 600_000);
    return () => clearInterval(interval);
  }, []);

  const busy = loadingProgress(loading);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!busy) {
      dispatch(requireReleasesAction);
    }
  }, [busy, dispatch, time]);
  const anchor = useRef(null);
  const [open, setOpen] = useState(false);

  // Handler hooks
  const handleIconClick = useCallback(() => {
    dispatch(dismissReleasesAction);
    setOpen(true);
  }, [dispatch, setOpen]);
  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  useEffect(() => {
    if (!fresh) {
      return;
    }

    dispatch(dismissReleasesAction);
    const { fields: item } = list[0];
    const url = `/${miniSaar}/page/${item.itmIdt}/${item.slug}`;
    hashHistory.push(url);
  }, [fresh, list, miniSaar, dispatch]);

  const style = {
    backgroundColor: busy ? "white" : biebBackgroundColor,
    borderWidth: 5,
    borderStyle: "solid",
    borderColor: biebBackgroundColor,
    borderRadius: 5,
    padding: 0,
    width: 34,
    display: "inline-block",
    height: 34,
    overflow: "hidden",
    verticalAlign: "middle",
    marginRight: 8,
    opacity: busy ? 0.5 : 1,
  };

  const disabled = !list || list.length === 0 || busy;
  return (
    <Badged
      count={fresh}
      style={{ color: "white", backgroundColor: freshColor }}
    >
      <div style={{ lineHeight: "48px" }} ref={anchor}>
        <IconButton
          disabled={disabled}
          iconStyle={{ color: disabled ? seenColor : freshColor }}
          style={style}
          onClick={handleIconClick}
          title="Berichten"
        >
          <News />
        </IconButton>
        {open && (
          <Popover
            open
            anchorEl={anchor.current}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            targetOrigin={{ vertical: "top", horizontal: "right" }}
            onRequestClose={handleClose}
          >
            <Subheader>Berichten</Subheader>
            <List>
              {list.map(({ fields: item }) => {
                const url = `/${miniSaar}/page/${item.itmIdt}/${item.slug}`;
                const handleClick = () => {
                  hashHistory.push(url);
                  handleClose();
                };
                return (
                  <ListItem
                    key={item.itmIdt}
                    leftIcon={<PagetypeIcon type={item.pagetype} simpleIcon />}
                    secondaryText={formatDate(item.pubSttDtm)}
                    onClick={handleClick}
                    style={{ cursor: "pointer" }}
                  >
                    {item.lbl}
                  </ListItem>
                );
              })}
            </List>
          </Popover>
        )}
      </div>
    </Badged>
  );
};

export default Releases;
