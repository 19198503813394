import { loadingProgress } from "async-lifecycle";
import { joinLoading } from "async-lifecycle/volatile";
import empty from "empty";
import { defaultTo, filter, flow, get, map, pick } from "lodash/fp";
import { compose, setDisplayName } from "recompose";
import { createSelector } from "reselect";

import { deleteVerantwoordingAction } from "../actions/cms";
import {
  getPageAction,
  pageIncludeRelations,
  pageOmitAll,
  requireFieldDefinitionsAction,
  requireRelationTypesAction,
  setVisitedAction,
} from "../actions/data";
import { pageActions } from "../actions/page";
import { requireVerantwoordingen } from "../actions/verantwoordingen";
import Verantwoordingen from "../components/verantwoordingen";
import { flown } from "../lodash";
import { execOnChange } from "../recompose.contrib";
import { sessionSelector } from "../selectors";
import { dispatchWrap } from "../utils";
import connect from "./connect";

const verantwoordingenSelector = createSelector(
  [
    get("data.pages"),
    get("data.relations"),
    get("data.relationTypes"),
    get("data.verantwoordingen"),
    flow(get("form.page.-2"), defaultTo(empty.object)),
    flow(sessionSelector, get("user")),
    flow(get("data.selectionLists.data"), defaultTo(empty.array)),
  ],
  (
    pages,
    relations,
    relationTypes,
    verantwoordingen,
    formPage,
    user,
    selectionLists
  ) => ({
    loading: joinLoading(relationTypes.loading, verantwoordingen.loading),
    pages: pick(map("id")(verantwoordingen.list || empty.array))(pages),
    relations: pick(map("id")(verantwoordingen.list || empty.array))(relations),
    types: relationTypes.types || empty.array,
    user,
    verantwoordingen: verantwoordingen.list || empty.array,
    formPage,
    selectionLists,
  })
);

export default compose(
  setDisplayName("Verantwoordingen"),
  connect(
    verantwoordingenSelector,
    (dispatch, { location: { pathname } }) => ({
      init: (missingIds) => {
        dispatch(requireFieldDefinitionsAction());
        dispatch(requireVerantwoordingen());
        dispatch(requireRelationTypesAction());
        dispatch(
          setVisitedAction({ title: "Verantwoordingen", path: pathname })
        );

        for (const id of missingIds) {
          dispatch(
            getPageAction(id, { ...pageOmitAll, ...pageIncludeRelations })
          );
        }
      },
      deleteVerantwoording: dispatchWrap(deleteVerantwoordingAction, dispatch),
      pageActions: (formPage) => pageActions(dispatch, -2, formPage),
    }),
    (stateProps, { init, pageActions, ...dispatchProps }, ownProps) => {
      const missingIds = flown(
        stateProps.verantwoordingen,
        map("id"),
        filter((id) => {
          const { loading: pageLoading } = stateProps.pages[id] || empty.object;
          const { loading: relationsLoading } =
            stateProps.relations[id] || empty.object;
          return (
            (pageLoading !== "success" && !loadingProgress(pageLoading)) ||
            (relationsLoading !== "success" &&
              !loadingProgress(relationsLoading))
          );
        })
      );
      return {
        ...stateProps,
        ...dispatchProps,
        ...ownProps,
        pageActions: pageActions(stateProps.formPage),
        init: () => init(missingIds),
      };
    }
  ),
  execOnChange("init", "verantwoordingen")
)(Verantwoordingen);
