/* eslint  react/prop-types: off */
import empty from "empty";
import { isEqual } from "lodash/fp";
import { amber900, lightGreen300 } from "material-ui/styles/colors";
import LibraryBooks from "material-ui/svg-icons/av/library-books";
import Widgets from "material-ui/svg-icons/device/widgets";
import React from "react";
import {
  compose,
  pure,
  setDisplayName,
  withHandlers,
  withPropsOnChange,
  withState,
} from "recompose";

import { isEmpty } from "../../utils";
import PlainInput from "../ggvtyp/plainInput";
import PagetypeIcon from "../pagetypeIcon";
import { ItemLink } from "../relation.itemlink";
import SafeInnerHtml from "../safeInnerHtml";

const LegoblokLink = ({ item, title }) => (
  <ItemLink
    item={item}
    title={title}
    style={{ display: "inline-blok", marginLeft: 8 }}
  >
    <Widgets color={amber900} />
  </ItemLink>
);

const BiebLink = ({ item, title }) => (
  <ItemLink
    item={item}
    title={title}
    style={{ display: "inline-blok", marginLeft: 8 }}
  >
    <LibraryBooks color={amber900} />
  </ItemLink>
);

const Pagetitle = ({
  value,
  setValue,
  titleDiff,
  pagetype,
  pageActions: { edit = false } = empty.object,
  legoblokRelation,
  fromLegoblokRelation,
  fromBiebRelation,
  insideBieb,
  iconStatus,
  validity,
}) => (
  <div
    className="page-title"
    style={{
      display: "flex",
      paddingTop: pagetype === "verantwoording" ? 8 : undefined,
    }}
  >
    <PagetypeIcon
      insideBieb={insideBieb}
      containerStyle={{
        alignSelf: "center",
        marginRight: "16px",
        height: "32px",
        width: "32px",
        paddingTop: edit ? "48px" : undefined,
      }}
      iconStyle={{
        height: "32px",
        width: "32px",
      }}
      type={pagetype}
      colorProperty={iconStatus}
      validity={validity}
    />
    <div
      className="title-text"
      style={{
        alignSelf: "center",
        flex: "1 1",
        paddingTop: edit ? 48 : undefined,
      }}
    >
      {pagetype === "verantwoording" && (
        <div
          style={{
            color: "rgba(0, 0, 0, 0.5)",
            fontSize: "14px",
            fontWeight: 500,
            marginTop: -14,
          }}
        >
          Doeleinde
        </div>
      )}
      {edit ? (
        <PlainInput
          autoComplete="off"
          value={value || ""}
          setValue={setValue}
          style={{
            width: "100%",
            borderBottomWidth: 1,
            borderBottomStyle: "solid",
            borderColor: lightGreen300,
          }}
        />
      ) : titleDiff ? (
        <SafeInnerHtml className="version-diff">{titleDiff}</SafeInnerHtml>
      ) : (
        value
      )}
      {legoblokRelation && !edit && (
        <LegoblokLink
          item={legoblokRelation.right}
          title="Bron voor legoblok"
        />
      )}
      {fromLegoblokRelation && !edit && (
        <LegoblokLink
          item={fromLegoblokRelation.left}
          title="Overgenomen uit legoblok"
        />
      )}
      {fromBiebRelation && !edit && (
        <BiebLink
          item={fromBiebRelation.left}
          title="Overgenomen uit Saar Bieb"
        />
      )}
    </div>
  </div>
);

const cleanValue = (value) => (isEmpty(value) ? undefined : value);

const enhance = compose(
  setDisplayName("Pagetitle"),
  pure,
  withState("value", "setValue", ({ title }) => cleanValue(title)),
  withState("dirty", "setDirty", false),
  withState("valid", "setValid", true),
  withHandlers({
    setValue:
      ({
        title,
        setDirty,
        setValid,
        setValue,
        pageActions: {
          titleRevert = empty.func,
          titleUpdate = empty.func,
        } = empty.object,
      }) =>
      ({
        value: object,
        string = isEmpty(object) ? null : object.toString(),
        valid = true,
      }) => {
        const originalValue = cleanValue(title);
        const nextValue = cleanValue(object);
        const dirty = !isEqual(nextValue, originalValue);
        setDirty(dirty);
        setValid(valid);
        setValue(nextValue === undefined ? null : nextValue);
        titleRevert();
        if (dirty && valid) {
          titleUpdate({ value: string });
        }
      },
  }),
  withPropsOnChange(
    (
      { title: currentValue, pageActions: { edited: currentEdited } },
      { title: pendingValue, pageActions: { edited: pendingEdited } }
    ) => (currentEdited && !pendingEdited) || currentValue !== pendingValue,
    ({ edited, title: value, value: localValue, setValue }) => {
      if (edited || cleanValue(value) !== localValue) {
        setValue({ value, reset: true });
      }

      return empty.object;
    }
  )
);

export default enhance(Pagetitle);
