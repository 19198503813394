import {
  entries,
  eq,
  filter,
  flatten,
  flow,
  get,
  isEmpty,
  map,
  reject,
  sortBy,
  uniq,
} from "lodash/fp";
import PropTypes from "prop-types";
import React from "react";
import { compose, setDisplayName, withHandlers, withState } from "recompose";

import {
  itemShape,
  pageActionsShape,
  relationDisplayShape,
  structureShape,
} from "../../../business/prop-types";
import { flown } from "../../../lodash";
import { structuredMap } from "../../../utils";
import VerantwoordingBlok from "./verantwoordingBlok";

const KerngegevenVerantwoording = ({
  biebStructures,
  page,
  pageActions,
  relations,
  gotoVerantwoordingen,
}) =>
  flown(
    relations,
    map("links"),
    flatten,
    map("using"),
    flatten,
    map("itemId"),
    uniq,
    map((verantwoordingId) => {
      const verantwoordingRelations = flown(
        relations,
        map(
          structuredMap({
            links: filter(flow(get("using[0].itemId"), eq(verantwoordingId))),
          })
        ),
        reject(flow(get("links"), isEmpty))
      );
      return {
        verantwoordingId,
        relations: verantwoordingRelations,
        label: verantwoordingRelations[0].links[0].using[0].label,
      };
    }),
    sortBy("label"),
    entries,
    map(([n, { verantwoordingId, relations }]) => (
      <VerantwoordingBlok
        key={verantwoordingId}
        biebStructures={biebStructures}
        nthChild={parseInt(n, 0)}
        pageActions={pageActions}
        relations={relations}
        verantwoording={page[verantwoordingId]}
        gotoVerantwoordingen={gotoVerantwoordingen}
      />
    ))
  );

KerngegevenVerantwoording.propTypes = {
  biebStructures: PropTypes.arrayOf(structureShape.isRequired).isRequired,
  page: PropTypes.objectOf(itemShape).isRequired,
  pageActions: pageActionsShape.isRequired,
  relations: PropTypes.arrayOf(relationDisplayShape.isRequired).isRequired,
  gotoVerantwoordingen: PropTypes.func.isRequired,
};

export default compose(
  setDisplayName("KerngegevenVerantwoording"),
  withState("addOpen", "setOpen", false),
  withHandlers({
    addInit:
      ({ addInit }) =>
      () =>
        addInit(),
  })
)(KerngegevenVerantwoording);
