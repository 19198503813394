import { flow, get, has, map, negate } from "lodash/fp";
import {
  branch,
  compose,
  renderComponent,
  setDisplayName,
  withProps,
} from "recompose";

import { setup } from "../../lodash";
import { makePagetypes } from "../../pagetypes";
import { extractFirstSpecial, extractRightbar, extractTabs } from "../tabs";
import BasePage from "./basePage";
import EmptyPage from "./emptyPage";
import {
  renderLandscapeFilters,
  renderLandscapeLegend,
} from "./specials/landscape";

export default compose(
  setDisplayName("DefaultPage"),
  branch(negate(has("page.page")), renderComponent(EmptyPage)),
  withProps(flow(get("page.page.pagetype"), makePagetypes, setup("pagetypes"))),
  withProps((props) => {
    const tabs = extractTabs(props);
    const tabAliasses = map("alias")(tabs);
    const tabInfo = {
      aliasses: tabAliasses,
      active: tabAliasses[props.selectedTab],
    };

    return {
      extraClasses:
        tabInfo.active === "Landschap" && props.uiClasses
          ? props.uiClasses
          : undefined,
      firstSpecial: extractFirstSpecial(props, tabInfo),
      rightbar: extractRightbar(props, tabInfo),
      secondaryDrawer:
        tabInfo.active === "Landschap"
          ? {
              children: [
                renderLandscapeLegend({ ...props, tabInfo }),
                renderLandscapeFilters({ ...props, tabInfo }),
              ],
            }
          : undefined,
      tabs,
      usePresentation: true,
    };
  })
)(BasePage);
