import { fetchVariantJSON } from "./fetch";

export const getEntiteitOverview = () => fetchVariantJSON("entiteit/overview");

export const getVerantwoordingOverview = () =>
  fetchVariantJSON("entiteit/verantwoordingen");

export const getVerwerkingen = () => fetchVariantJSON("verwerkingen");

export const saveEntiteitRelations = (entiteitRelationObj) =>
  fetchVariantJSON(
    `cms/koppeling/${entiteitRelationObj.pageId}/entiteitrelaties`,
    entiteitRelationObj
  );

export const addKoppelingOverdracht = (itemId, pageClusterId, selected) =>
  fetchVariantJSON(`cms/koppeling/${itemId}/overdracht`, {
    itemId,
    pageClusterId,
    selected,
  });

export const getZibOverview = () => fetchVariantJSON("zib/overview");

export const getZibExpansion = () => fetchVariantJSON("zib/expansion");
