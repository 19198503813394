import { get, has } from "lodash/fp";

import { labelize } from "../utils";

export const Position = labelize(["auto", "none", "top", "bottom", "second"]);

export const SelectionScope = labelize(["none", "saar", "shared", "all"]);

export const hasSelectionScope = (expected) => (actual) =>
  (expected & actual) === expected;

export const Mutations = labelize(["created", "deleted"]);

export const allowedSaar = (allowed) => allowed % 2 === 1;

export const Scope = labelize([
  "auto",
  "iznet",
  "mini",
  "sites",
  "biebIntern",
  "scope5",
  "scope6",
  "scope7",
  "biebExtern",
  "scope9",
  "scope10",
  "scope11",
  "bieb",
  "scope13",
  "scope14",
  "all",
]);

// eslint-disable-next-line no-bitwise
export const scopeBieb = (scope) => (scope & Scope.bieb) !== 0;

export const deprecatedPagetypes = Object.freeze([
  "applicatieset",
  "basisset",
  "casus",
  "procesgroep",
  "procesonderdeel",
]);

export const infozorgOnlyPagetypes = Object.freeze(["inzicht", "legoblok"]);

export const nonAddablePagetypes = Object.freeze([
  "actielijsten",
  "applicatielandschap",
  "casus",
  "competentie",
  "dashboard",
  "enquete",
  "footer",
  "header",
  "index",
  "meter",
  "nieuws",
  "organogram",
  "overzicht",
  "procesgroep",
  "procesonderdeel",
  "raamwerk",
  "tariefkaart",
  "treeview",
  "zoeken",
]);

export const noLegoBlokTypes = Object.freeze([
  "applicatielandschap",
  "dashboard",
  "index",
  "legoblok",
  "raamwerk",
  "treeview",
]);

export const lifecycleStatus = (page) => {
  const startValue = get("meta.startDatum.value")(page);
  const eindeValue = get("meta.eindDatum.value")(page);
  if (!startValue && !eindeValue) {
    return undefined;
  }

  if (startValue && new Date(startValue) > Date.now()) {
    return "nieuw";
  }

  if (eindeValue && new Date(eindeValue) < Date.now()) {
    return "oud";
  }

  return "actief";
};

export const pageStatus = (page, pagetype) => {
  const lcStatus = lifecycleStatus(page);
  return lcStatus === "nieuw"
    ? lcStatus
    : pagetype === "project" &&
      has("inhoud.omschrijving.projectstatus.value.alias")(page)
    ? get("inhoud.omschrijving.projectstatus.value.alias")(page)
    : pagetype === "project" &&
      has("inhoud.omschrijving.status.value.alias")(page) // oldskool
    ? get("inhoud.omschrijving.status.value.alias")(page)
    : pagetype === "applicatie" && has("inhoud.kenmerk")(page)
    ? get("inhoud.kenmerk.value.alias")(page)
    : lcStatus;
};
