/* eslint  react/prop-types: off */
import empty from "empty";
import React from "react";
import { compose, setDisplayName, withProps } from "recompose";
import { createSelector } from "reselect";

import RecentOverzicht from "../../containers/recentOverzicht";
import { makePagetypes } from "../../pagetypes";
import { pageActionsSelector } from "../../selectors/tabs";
import RecentItems from "../recentItems";
import BasePage from "./basePage";
import DashboardBlokken from "./dashboard/dashboardBlokken";

const tabsSelector = createSelector(
  [
    ({
      page: {
        page: {
          blok: { clusters: blokken = empty.array } = empty.object,
        } = empty.object,
      } = empty.object,
    }) => blokken,
    pageActionsSelector,
  ],
  (blokken, pageActions) => [
    {
      label: "Blokken",
      children: (
        <DashboardBlokken
          key="blokken"
          blokken={blokken}
          pageActions={pageActions}
        />
      ),
    },
  ]
);

/**
 * Dashboard component
 */
const Dashboard = (props) => (
  <BasePage
    narrow
    {...props}
    rightbar={{
      children: [
        <RecentItems
          key="recentlyCreated"
          recent={props.recentlyCreated}
          alles={props.alles}
          miniSaar={props.miniSaar}
          label="Recent toegevoegd"
          legoblokStructures={props.legoblokStructures}
          maxLength={6}
        />,
        <RecentOverzicht key="recent-overzicht" />,
      ],
    }}
  />
);

export default compose(
  setDisplayName("Dashboard"),
  withProps(
    ({ page: { page: { pagetype } = empty.object } = empty.object }) => ({
      pagetypes: makePagetypes(pagetype),
    })
  ),
  withProps((props) => ({ tabs: tabsSelector(props) }))
)(Dashboard);
