import PropTypes from "prop-types";

import {
  overzichtFieldShape,
  overzichtRelationShape,
} from "../../business/prop-types";

export const listItemShape = PropTypes.shape({
  value: PropTypes.any.isRequired,
  label: PropTypes.string.isRequired,
});

export const pagetypeListItemShape = PropTypes.shape({
  alias: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
});

export const overviewFormShape = PropTypes.shape({
  currentStep: PropTypes.number.isRequired,
  pagetype: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  fields: PropTypes.arrayOf(overzichtFieldShape.isRequired).isRequired,
  relations: PropTypes.arrayOf(overzichtRelationShape.isRequired).isRequired,
});
