import moment from "moment";

import { labelize } from "../utils";

export const PayloadType = labelize([
  "none",
  "access",
  "knowledgeFactor",
  "accessAndKnowledgeFactor",
  "possessionFactor",
  "possessionFactorAndAccess",
  "possessionFactorAndKnowledgeFactor",
  "possessionFactorAndAccessAndKnowledgeFactor",
  "inherenceFactor",
  "inherenceFactorAndAccess",
  "inherenceFactorAndKnowledgeFactor",
  "inherenceFactorAndAccessAndKnowledgeFactor",
  "inherenceFactorAndPossessionFactor",
  "inherenceFactorAndPossessionFactorAndAccess",
  "inherenceFactorAndPossessionFactorAndKnowledgeFactor",
  "inherenceFactorAndPossessionFactorAndAccessAndKnowledgeFactor",
]);

const storage = (key) =>
  key === "user" || key === "office365" || key in PayloadType
    ? localStorage
    : sessionStorage;

export const fromStorage = (key) => {
  const json = storage(key).getItem(key);
  return json ? JSON.parse(json) : undefined;
};

export const toStorage = (key, value) => {
  if (value !== undefined && value !== null) {
    storage(key).setItem(key, JSON.stringify(value));
  } else {
    storage(key).removeItem(key);
  }
};

export const tokenFor = (payloadType) => {
  const model = fromStorage(PayloadType[payloadType]);
  if (!model) {
    return undefined;
  }

  const {
    payload: { exp },
    token,
  } = model;
  if (moment(exp).valueOf() <= moment().valueOf()) {
    return undefined;
  }

  return token;
};

export const resetAuth = (resetPossessionFactor = false) => {
  toStorage("user");
  toStorage("possession");
  toStorage("office365");
  for (const type of PayloadType) {
    if (resetPossessionFactor || type !== "possessionFactor") {
      toStorage(type);
    }
  }
};
