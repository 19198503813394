import empty from "empty";
import { find, get, isEqual } from "lodash/fp";
import { compose, setDisplayName } from "recompose";
import { createSelector } from "reselect";

import {
  biebSelectSet,
  doUseFromBiebDialogAction,
  setPreview,
} from "../actions/bieb";
import {
  requirePagetypeListAction,
  requireSelectionListsAction,
  setVisitedAction,
} from "../actions/data";
import {
  getQuery,
  searchByQueryAction,
  searchByQueryClearAction,
} from "../actions/search";
import Search from "../components/search";
import { deserializeScope } from "../components/search/constants";
import { flown } from "../lodash";
import { execOnChange } from "../recompose.contrib";
import { searchSelector } from "../selectors/search";
import { createUndoubler, dispatchWrap } from "../utils";
import connect from "./connect";
import enhanceWithFacets from "./facetsContainer";

const searchStructuresUndoubler = createUndoubler();

const setPreviewSelector = createSelector(
  [({ scopeParam }) => scopeParam, ({ dispatch }) => dispatch],
  (scopeParam, dispatch) =>
    scopeParam === "library"
      ? (itemId) => dispatch(setPreview(itemId))
      : undefined
);

export default compose(
  setDisplayName("Search"),
  connect(
    ({
      data: {
        addLegoblok: {
          structures: biebStructures = empty.array,
        } = empty.object,
        miniSaars: {
          data = empty.array,
          sharedStructureIds = empty.array,
        } = empty.object,
      },
      session: {
        miniSaar: { siteId = 0 },
      },
    }) => ({
      searchStructures: searchStructuresUndoubler({
        biebStructures,
        sharedStructureIds,
        siteStructures: flown(data, find({ siteId }), get("sites")),
      }),
    })
  ),
  connect(
    searchSelector,
    (
      dispatch,
      {
        location: {
          query: { q: trefwoord = "", statusIds: statusIdsFromUri = "" },
          pathname,
        },
        router: {
          params: { scope: scopeParam = "site" },
        },
        router,
        searchStructures: {
          biebStructures,
          sharedStructureIds,
          siteStructures,
        } = empty.object,
      }
    ) => {
      const scope = deserializeScope(scopeParam);
      const statusIds = statusIdsFromUri
        ? statusIdsFromUri.split(",").map(Number)
        : undefined;

      return {
        init: () => {
          dispatch(requireSelectionListsAction());
          dispatch(requirePagetypeListAction());
          if (scopeParam === "library") {
            dispatch(setVisitedAction({ title: "Saar Bieb", path: pathname }));
          }
        },

        setFilter: (trefwoord) => {
          const { params: { minisaar = "", scope = "site" } = empty.object } =
            router;
          router.push(
            `/${minisaar}/search/${scope}?q=${encodeURIComponent(
              trefwoord
            )}&statusIds=${statusIds?.join(",") ?? ""}`
          );
        },

        updateSelected: dispatchWrap(biebSelectSet, dispatch),

        setPreview: setPreviewSelector({ scopeParam, dispatch }),

        search: () => {
          if (trefwoord !== "" || scopeParam === "library") {
            dispatch(
              searchByQueryAction(
                getQuery(
                  trefwoord,
                  scope,
                  biebStructures,
                  siteStructures,
                  statusIds,
                  sharedStructureIds
                ),
                scope
              )
            );
          } else {
            dispatch(searchByQueryClearAction());
          }
        },

        doUseBiebItemsDialog:
          scopeParam === "library"
            ? (selection) =>
                dispatch(doUseFromBiebDialogAction(true, { multi: selection }))
            : undefined,
      };
    }
  ),
  enhanceWithFacets(),
  execOnChange("init"),
  execOnChange(
    "search",
    (
      {
        location: { pathname, query } = empty.object,
        searchStructures = empty.object,
      } = empty.object,
      {
        location: { pathname: nextPathname, query: nextQuery } = empty.object,
        searchStructures: nextSearchStructures = empty.object,
      } = empty.object
    ) =>
      pathname !== nextPathname ||
      !isEqual(query)(nextQuery) ||
      !isEqual(searchStructures)(nextSearchStructures)
  )
)(Search);
