import { Position } from "../../../business";
import ZibExpandedSelect from "./zibExpandedSelect";

export const informatiestandaardSectieContentPlug = {
  renderSecond: (cluster, name, { pageActions, relations, types }) =>
    name === "Onderdeel" || name === "Inhoud" ? (
      <ZibExpandedSelect
        label="Zibgegevens"
        pageClusterId={cluster.clusterId}
        pageClusterName={name.toLowerCase()}
        pageActions={pageActions}
        relations={relations}
        types={types}
      />
    ) : null,
  relationsPosition: () => Position.none,
};
