import { AsyncSingleValue } from "async-lifecycle-saga";
import { some } from "lodash/fp";
import { createSelector } from "reselect";

import {
  BiebRelatedPagetypeMapping,
  BiebUpdateDialogType,
  ContentAction,
  PageData,
  RelationData,
  RelationTypes,
  StoreRoot,
  StructureModel,
  emptyArray,
  emptyObject,
} from "../business/models";
import { flown } from "../lodash";

const formbiebUpdateDialogSelector = (store: StoreRoot) =>
  store.form.biebUpdateDialog;

const biebStructuresSelector = (store: StoreRoot) =>
  store.data.addLegoblok.structures;

const pagesSelector = (store: StoreRoot) => store.data.pages;

const biebPageSelector = createSelector(
  [formbiebUpdateDialogSelector, pagesSelector],
  (formbiebUpdateDialog, pages): PageData => {
    if (!formbiebUpdateDialog.biebItemId) {
      return emptyObject();
    }

    return pages[formbiebUpdateDialog.biebItemId] ?? emptyObject();
  }
);

const localPageSelector = createSelector(
  [formbiebUpdateDialogSelector, pagesSelector],
  (formbiebUpdateDialog, pages): PageData => {
    if (!formbiebUpdateDialog.localItemId) {
      return emptyObject();
    }

    return pages[formbiebUpdateDialog.localItemId] ?? emptyObject();
  }
);

const relationsSelector = (store: StoreRoot) => store.data.relations;

const biebRelationsSelector = createSelector(
  [formbiebUpdateDialogSelector, relationsSelector],
  (formbiebUpdateDialog, relations): RelationData => {
    if (!formbiebUpdateDialog.biebItemId) {
      return emptyObject();
    }

    return relations[formbiebUpdateDialog.biebItemId] ?? emptyObject();
  }
);

const localRelationsSelector = createSelector(
  [formbiebUpdateDialogSelector, relationsSelector],
  (formbiebUpdateDialog, relations): RelationData => {
    if (!formbiebUpdateDialog.localItemId) {
      return emptyObject();
    }

    return relations[formbiebUpdateDialog.localItemId] ?? emptyObject();
  }
);

const relationTypesSelector = (store: StoreRoot) => store.data.relationTypes;

const biebPagetypeMappingSelector = (store: StoreRoot) =>
  store.sagas.biebPagetypeMapping;

const isIznetSelector = (store: StoreRoot) =>
  store.session.miniSaar?.alias === "iznet";

const formPageStagedSelector = createSelector(
  [formbiebUpdateDialogSelector, (store: StoreRoot) => store.form.page],
  (formbiebUpdateDialog, formPage): ContentAction[] => {
    if (!formbiebUpdateDialog.localItemId) {
      return emptyArray();
    }

    return formPage[formbiebUpdateDialog.localItemId]?.staged ?? emptyArray();
  }
);

const dirtySelector = createSelector(
  [formPageStagedSelector],
  (formPageStaged) =>
    flown(
      formPageStaged,
      some((action: { autoStaged?: boolean }) => action.autoStaged!)
    )
);

export const biebUpdateDialogSelector = createSelector(
  [
    formbiebUpdateDialogSelector,
    biebStructuresSelector,
    biebPageSelector,
    localPageSelector,
    biebRelationsSelector,
    localRelationsSelector,
    relationTypesSelector,
    biebPagetypeMappingSelector,
    isIznetSelector,
    dirtySelector,
    formPageStagedSelector,
  ],
  (
    formbiebUpdateDialog,
    biebStructures,
    biebPage,
    localPage,
    biebRelations,
    localRelations,
    relationTypes,
    biebPagetypeMapping,
    isIznet,
    dirty,
    formPageStaged
  ): [
    BiebUpdateDialogType,
    StructureModel[],
    PageData,
    PageData,
    RelationData,
    RelationData,
    RelationTypes,
    AsyncSingleValue<BiebRelatedPagetypeMapping>,
    boolean,
    boolean,
    ContentAction[]
  ] => [
    formbiebUpdateDialog,
    biebStructures,
    biebPage,
    localPage,
    biebRelations,
    localRelations,
    relationTypes,
    biebPagetypeMapping,
    isIznet,
    dirty,
    formPageStaged,
  ]
);
