/* eslint  react/prop-types: off */
import empty from "empty";
import { includes } from "lodash/fp";
import React from "react";
import { compose } from "recompose";
import { createSelector } from "reselect";

import enhanceLandscape from "../../containers/enhanceLandscape";
import { contentSelector } from "../../selectors/tabs";
import LandscapeFilters from "../filters";
import Delay from "../utils/Delay";
import BasePage from "./basePage";
import DefaultPage from "./defaultPage";
import Landscape from "./landscape";
import LandscapeAddButton from "./landscape/addbutton";
import LandscapeLegend from "./landscape/legend";
import { isLandscape } from "./landscape/utils";

const tabsSelector = createSelector(
  [
    contentSelector,
    ({ expandedLandscape }) => expandedLandscape,
    ({ uiActions }) => uiActions,
    ({ uiClasses }) => uiClasses,
    ({ landscapeFilters }) => landscapeFilters,
    ({ page }) => page,
    ({ user }) => user?.mayAdd || false,
    ({ user }) => user?.mayEdit || false,
    ({ types }) => types,
  ],
  (
    getContent,
    landscape,
    uiActions,
    uiClasses,
    landscapeFilters,
    page,
    mayAdd,
    mayEdit,
    types
  ) => [
    {
      label: "Landschap",
      alias: "Landschap",
      children: [
        getContent({ path: "inhoud.inleiding", omitTitle: true }),
        isLandscape(landscape) && (
          <Landscape
            key="landscape"
            itemId={page?.id}
            landscape={landscape}
            uiActions={uiActions}
            isFullscreen={includes("fullscreen")(uiClasses)}
            filters={landscapeFilters}
            mayEdit={mayEdit}
          />
        ),
        mayAdd &&
          isLandscape(landscape) &&
          !includes("fullscreen")(uiClasses) &&
          page?.page?.inhoud?.relatietypealias?.value ===
            "entiteit_entiteit" && (
            <LandscapeAddButton
              key="landscape-add-button"
              itemId={page?.id}
              landscape={landscape}
              relationType={types?.find((t) => t.alias === "entiteit_entiteit")}
            />
          ),
      ],
    },
  ]
);

/**
 * ApplicationLandscape component
 */
const ApplicationLandscape = (props) => {
  const { landscapeFilters = empty.object } = props;
  const { selectionLists = empty.array } = props;
  const automatiseringsgraadList = selectionLists.find(
    (l) => l.alias === "automatiseringsgraad"
  );
  const pagetype = props.page?.page?.inhoud?.relatietypealias?.value?.includes(
    "entiteit"
  )
    ? "entiteit"
    : "applicatie";

  return (
    <BasePage
      {...props}
      tabs={tabsSelector(props)}
      extraClasses={props.uiClasses}
      rightbar={{
        children: [
          props.page && (
            <LandscapeLegend
              key="landscapeLegend"
              automatiseringsgraadList={automatiseringsgraadList}
              pagetype={pagetype}
            />
          ),
          <LandscapeFilters
            key="landscapeFilters"
            filters={landscapeFilters}
          />,
        ],
      }}
      secondaryDrawer={{
        children: [
          props.page && (
            <LandscapeLegend
              key="landscapeLegend"
              automatiseringsgraadList={automatiseringsgraadList}
              pagetype={pagetype}
            />
          ),
          <LandscapeFilters
            key="landscapeFilters"
            filters={landscapeFilters}
          />,
        ],
      }}
    />
  );
};

const EnhancedLandscape = compose(enhanceLandscape)(ApplicationLandscape);

const DelayedLandscape = (props) => (
  <Delay
    delay={50}
    Main={EnhancedLandscape}
    Fallback={DefaultPage}
    props={props}
  />
);

export default DelayedLandscape;
