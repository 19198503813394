import "tinymce";

import empty from "empty";

import {
  getMediaWidgetBySourceCode,
  getMediaWidgetByUrl,
  getMediaWidgetTypes,
  storeMediaWidget,
} from "../api/mediawidgets";

const prefix = "saar_mediawidget";
const urlRegex = new RegExp("^(https?:)?//");
global.tinymce.PluginManager.add("mediawidget", (editor) => {
  let bookmark = null;

  const initialize = () => {
    let dialogObject = empty.object;
    let mediawidgetObject = { Properties: empty.array };

    const invalid = (method) =>
      editor.windowManager.alert(
        method
          ? `Not a valid media widget ${method}`
          : "Not a valid media widget"
      );

    const updateDialog = ({ sourceCode, url }) => {
      if (sourceCode && sourceCode !== "") {
        // genereer widget op basis van de geleverde code
        return getMediaWidgetBySourceCode({
          type: mediawidgetObject.TypeAlias || "",
          sourceCode,
        })
          .then(({ getMediaWidgetBySourceCode }) => {
            mediawidgetObject = {
              ...mediawidgetObject,
              ...getMediaWidgetBySourceCode,
            };

            return mediawidgetObject;
          })
          .catch(() => invalid("embedcode"));
      }

      if (url && url !== "") {
        // genereer widget op basis van de geleverde url
        return getMediaWidgetByUrl({
          type: mediawidgetObject.TypeAlias || "",
          url,
        })
          .then(({ getMediaWidgetByUrl }) => {
            mediawidgetObject = {
              ...mediawidgetObject,
              ...getMediaWidgetByUrl,
            };

            return mediawidgetObject;
          })
          .catch(() => invalid("url"));
      }

      return mediawidgetObject;
    };

    const code = {
      name: `${prefix}_code`,
      id: `${prefix}_code`,
      type: "textbox",
      multiline: true,
      minHeight: 100,
      minWidth: 300,
      onChange() {
        const value = this.value();

        if (urlRegex.test(value)) {
          // code is eigenlijk een URL
          const url = encodeURI(value);
          this.value(url);
          mediawidgetObject.Url = url;
          if (url !== "" && !mediawidgetObject.submit) {
            updateDialog({ url });
          }
        } else {
          mediawidgetObject.SourceCode = value;
          if (value !== "" && !mediawidgetObject.submit) {
            updateDialog({ sourceCode: value });
          }
        }
      },
    };

    const submit = () => {
      mediawidgetObject.submit = true;

      return new Promise((resolve, reject) => {
        if (document.getElementById(`${prefix}_code`).value !== "") {
          const value = document.getElementById(`${prefix}_code`).value;
          const prop = urlRegex.test(value) ? "url" : "sourceCode";
          return updateDialog({
            [prop]: document.getElementById(`${prefix}_code`).value,
          })
            .then(
              // sla de widget op
              ({ TypeId, TypeAlias }) => {
                if (!TypeId && !TypeAlias) {
                  invalid();
                  return reject();
                }

                return storeMediaWidget(mediawidgetObject).then(
                  ({ retrieve = empty.array }) => {
                    const [{ Id, Title }] = retrieve || empty.array;
                    if (Id && Title) {
                      editor.selection.moveToBookmark(bookmark);
                      const html = editor.dom.createHTML(
                        "div",
                        { id: `Mwt_${Id}`, class: "mediawidget" },
                        Title
                      );
                      editor.insertContent(`${html}<p></p>`);

                      return resolve();
                    }
                    return reject();
                  }
                );
              }
            )
            .catch(reject);
        }
        return reject();
      });
    };

    dialogObject = editor.windowManager.open({
      title: "Insert media widget",
      body: [code],
      buttons: [
        {
          text: "Ok",
          classes: "widget btn primary",
          id: "ok",
          onClick: (e) =>
            submit()
              .then(() => dialogObject.submit())
              .catch(() => e.preventDefault()),
        },
        {
          text: "Cancel",
          id: "close",
          onclick: "close",
        },
      ],
      // onsubmit: submit
    });
  };

  const startMediawidgetDialog = () => {
    // sla positie op
    bookmark = editor.selection.getBookmark(2, true);
    // haal alle types op, vervolgens open het dialoog
    getMediaWidgetTypes().then(({ getTypes }) => initialize(getTypes));
  };

  // Add a button that opens a window
  editor.addButton("mediawidget", {
    tooltip: "Mediawidget",
    icon: "code",
    onclick: startMediawidgetDialog,
  });
});
