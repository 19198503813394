/* eslint  react/prop-types: off */
import empty from "empty";
import { filter, flow, includes, map } from "lodash/fp";
import React from "react";
import { createSelector } from "reselect";

import { Side } from "../../../business/relations";
import AddButtons from "../../../containers/pagetypes/legoblok";
import {
  legoblokRelaties,
  relationsSelector,
} from "../../../selectors/relations";
import { contentSelector, formSelector } from "../../../selectors/tabs";
import Heading from "../../content/Heading";
import { showStatic } from "../../content/query";
import ButtonBar from "../../material/buttonBar";
import ItemNotes from "../../notes/itemNotes";
import ItemNotesBadge from "../../notes/itemNotesBadge";
import BasePage from "../basePage";

const rightbarTypeSelector = createSelector(
  [({ types }) => types],
  flow(
    filter(
      ({ alias }) =>
        includes(alias)(legoblokRelaties) ||
        (alias.length > 9 &&
          alias.lastIndexOf("_legoblok") === alias.length - 9)
    ),
    map(({ alias }) => ({
      alias,
      side: alias.indexOf("relatie_legoblok_") === 0 ? Side.left : Side.right,
    }))
  )
);

const goedePraktijkTypeSelector = createSelector(
  [({ types }) => types],
  flow(
    filter(({ alias }) => alias.indexOf("legoblok_") === 0),
    map(({ alias }) => ({ alias, side: Side.left, forceMiniSaar: true }))
  )
);

const goedePraktijkSelector = createSelector(
  [relationsSelector, goedePraktijkTypeSelector],
  (selector, goedePraktijkTypes) => selector(...goedePraktijkTypes)
);

const renderUseButton = (
  { mayAdd = false },
  doUseLegoblokDialog,
  { edit = false }
) =>
  mayAdd && !edit ? (
    <ButtonBar key="use-legoblok" openDialog={doUseLegoblokDialog} />
  ) : null;

const tabsSelector = createSelector(
  [
    contentSelector,
    goedePraktijkSelector,
    ({ user }) => user,
    ({ doUseLegoblokDialog }) => doUseLegoblokDialog,
    ({ page: { page = empty.object } = empty.object }) => page,
    ({ pageActions }) => pageActions,
    ({ section }) => section,
    ({ id }) => id,
    formSelector,
    ({ notes }) => notes,
    ({ page: { siteId } = empty.object }) => siteId,
  ],
  (
    getContent,
    goedePraktijkRelations,
    user,
    doUseLegoblokDialog,
    page,
    pageActions,
    section,
    id,
    form,
    notes,
    siteId
  ) => {
    const kennis = getContent({ path: "value.kennis", omitTitle: true });

    const instructieText = getContent({
      path: "value.instructie",
      omitTitle: true,
    });
    const instructieParts = getContent(
      { path: "documentenInstructie", omitTitle: true },
      { path: "instructie", omitTitle: true, omitAddButton: true }
    );
    const instructie = [...instructieText, ...instructieParts];

    const hulpmiddelText = getContent({
      path: "value.hulpmiddel",
      omitTitle: true,
    });
    const hulpmiddelParts = getContent(
      { path: "documentenHulpmiddel", omitTitle: true },
      { path: "hulpmiddel", omitTitle: true, omitAddButton: true }
    );
    const hulpmiddel = [...hulpmiddelText, ...hulpmiddelParts];

    const goedePraktijkText = getContent({
      path: "value.goedePraktijk",
      omitTitle: true,
    });
    const goedePraktijkParts = getContent(
      { path: "documentenPraktijk", omitTitle: true },
      { path: "goedePraktijk", omitTitle: true, omitAddButton: true }
    );
    const goedePraktijk = [...goedePraktijkText, ...goedePraktijkParts];

    return [
      {
        label: "Kennis",
        alias: "Kennis",
        children: [
          !section &&
            showStatic(kennis, pageActions) &&
            renderUseButton(user, doUseLegoblokDialog, pageActions),
          kennis,
        ],
      },
      {
        label: "Instructie",
        alias: "Instructie",
        children: [
          !section &&
            showStatic(instructie, pageActions) &&
            renderUseButton(user, doUseLegoblokDialog, pageActions),
          instructieText,
          showStatic(instructieParts, pageActions) && (
            <Heading key="onderdelen">Legoblok onderdelen</Heading>
          ),
          instructieParts,
          showStatic(instructie, pageActions) && (
            <AddButtons
              key="add"
              siteId={siteId}
              clusters={page.instructie}
              pageActions={pageActions}
            />
          ),
        ],
      },
      {
        label: "Hulpmiddel",
        alias: "Hulpmiddel",
        children: [
          !section &&
            showStatic(hulpmiddel, pageActions) &&
            renderUseButton(user, doUseLegoblokDialog, pageActions),
          hulpmiddelText,
          showStatic(hulpmiddelParts, pageActions) && (
            <Heading key="onderdelen">Legoblok onderdelen</Heading>
          ),
          hulpmiddelParts,
          showStatic(hulpmiddel, pageActions) && (
            <AddButtons
              key="add"
              siteId={siteId}
              clusters={page.hulpmiddel}
              pageActions={pageActions}
            />
          ),
        ],
      },
      {
        label: "Goede praktijk",
        alias: "Goede praktijk",
        children: [
          !section &&
            showStatic(goedePraktijk, pageActions, goedePraktijkRelations) &&
            renderUseButton(user, doUseLegoblokDialog, pageActions),
          goedePraktijkText,
          showStatic(goedePraktijkParts, pageActions) && (
            <Heading key="onderdelen">Legoblok onderdelen</Heading>
          ),
          goedePraktijkParts,
          showStatic(goedePraktijk, pageActions, goedePraktijkRelations) && (
            <AddButtons
              key="add"
              siteId={siteId}
              clusters={page.goedePraktijk}
              pageActions={pageActions}
            />
          ),
          showStatic(undefined, pageActions, goedePraktijkRelations) && (
            <Heading key="overgenomen">Overgenomen</Heading>
          ),
          goedePraktijkRelations,
        ],
      },
      {
        label: (
          <ItemNotesBadge counter={notes ? notes.length : 0}>
            Opmerkingen
          </ItemNotesBadge>
        ),
        alias: "Opmerkingen",
        children: <ItemNotes id={id} form={form} notes={notes} user={user} />,
      },
    ];
  }
);

const rightbarSelector = createSelector(
  [relationsSelector, rightbarTypeSelector],
  (selector, rightbarTypes) => ({
    children: selector(...rightbarTypes),
  })
);

/**
 * Legoblok component
 */
const Legoblok = (props) => (
  <BasePage
    {...props}
    tabs={tabsSelector(props)}
    rightbar={rightbarSelector(props)}
  />
);

export default Legoblok;
