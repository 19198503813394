import { get } from "lodash/fp";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router";
import {
  branch,
  compose,
  renderComponent,
  setDisplayName,
  withHandlers,
  withState,
} from "recompose";

import {
  loginWithAzureAdAction,
  loginWithGoogleAction,
  loginWithOfficeAction,
} from "../actions/session";
import { fromStorage } from "../api/auth.common";
import PageRefreshIndicator from "../components/pagetypes/PageRefreshIndicator";
import { execOnChange } from "../recompose.contrib";
import connect from "./connect";

const ExternalLoginError = ({ error }) => (
  <div className="office-message">
    <p>Er is iets mis gegaan met het inloggen via Office 365.</p>
    {error && <p>Fout-code: {error}.</p>}
    <p>
      <Link to="/inloggen">Opnieuw inloggen</Link>
    </p>
  </div>
);

ExternalLoginError.propTypes = {
  error: PropTypes.string.isRequired,
};

const ExternalLogin = () => (
  <div className="login-loader">
    <PageRefreshIndicator />
  </div>
);

export default compose(
  setDisplayName("ExternalLogin"),
  withState("done", "update", false),
  connect(
    (state, props) => ({
      code: get("params.code")(props),
      error: get("params.error")(props),
    }),
    (dispatch) => ({
      loginWith: ({ code }) => {
        const loginSource = fromStorage("saar_loginsource");

        // eslint-disable-next-line default-case
        switch (loginSource) {
          case "office365":
            dispatch(loginWithOfficeAction(code));
            break;
          case "azureadb2c":
            dispatch(loginWithAzureAdAction(code));
            break;
          case "google":
            dispatch(loginWithGoogleAction(code));
            break;
        }
      },
    })
  ),
  branch(({ error }) => !!error, renderComponent(ExternalLoginError)),
  withHandlers({
    login:
      ({ loginWith, done, update }) =>
      ({ code }) => {
        if (code && !done) {
          update(true);
          loginWith(code);
        }
      },
  }),
  execOnChange("loginWith", "code")
)(ExternalLogin);
