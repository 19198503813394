import pagetypeIcons, { MaterialSvgIcon } from "../icons";
import { RaamwerkFilterItem } from "./models";
import RemarkTooltipIcon from "./remarkTooltipIcon";

const PracticalSituation = ({
  practicalSituation,
}: {
  practicalSituation: RaamwerkFilterItem;
}) => {
  const Icon = pagetypeIcons["praktijksituatie"] as MaterialSvgIcon;
  return (
    <RemarkTooltipIcon
      style={{
        float: "right",
        margin: "0 4px",
        display: "inline-block",
        position: "relative",
        padding: 0,
        width: 24,
        height: 24,
      }}
      text={practicalSituation.filterItem.label}
    >
      <Icon />
    </RemarkTooltipIcon>
  );
};

export default PracticalSituation;
