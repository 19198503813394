import empty from "empty";
import React from "react";

import { readOnlyPageActions } from "../../../business/models";
import Field from "../../content/field";
import ClusterCard from "../../material/clusterCard";

const SaarIdLabel = "Saar ID";

const renderSaarId = ({ pageActions: { pageId } = empty.object }) =>
  pageId ? (
    <ClusterCard>
      <Field
        field={{
          names: [SaarIdLabel],
          definition: {
            name: SaarIdLabel,
            dataTypeCode: 2,
          },
          value: pageId.toString(),
        }}
        pageActions={readOnlyPageActions}
      />
    </ClusterCard>
  ) : null;
export default renderSaarId;
