import {
  ExternLink,
  PageActions,
  Pagetype,
  RaamwerkAction,
  RaamwerkTarget,
  RaamwerkTreeItem,
  SelectionListItem,
  SiteLink,
  StatusLink,
} from "../../../business/models";

export type Open = { [key: number]: boolean };

export interface NvtMenuProps {
  selectionId: number;
  onChange: (selectionId: number, disabled: boolean) => void;
  disabled: boolean;
  disabledNvtItem: boolean;
}

export interface LinkListItemProps {
  item: SelectionListItem;
  leftItemId?: number;
  link?: SiteLink;
  externLink?: ExternLink;
  status: string;
  statusItems: SelectionListItem[];
  metaItem: { alias?: string; value: string };
  remark?: string;
  onChange: (
    action: RaamwerkAction,
    link?: SiteLink,
    externLink?: ExternLink
  ) => void;
  openRemarkDialog: (remarkForm?: RemarkForm) => void;
  openHyperlinkDialog: (hyperlinkForm?: HyperlinkForm) => void;
  stagedRemarks: StagedRemarkAction[];
  edit: boolean;
  allowed: RaamwerkActionsAllowed;
  stagedHyperlink?: StagedHyperlinkAction;
  practicalSituation?: RaamwerkFilterItem;
}

export interface HyperlinkProps {
  selectionId: number;
  externLink: ExternLink;
  edit: boolean;
  status: string;
  openHyperlinkDialog: (hyperlinkForm?: HyperlinkForm) => void;
}

export type LinkItemsProps = {
  edit: boolean;
  allowed: RaamwerkActionsAllowed;
  item: SelectionListItem;
  statusItems: SelectionListItem[];
  links: StatusLink[];
  onChange: (
    action: RaamwerkAction,
    link?: SiteLink,
    externLink?: ExternLink
  ) => void;
  openRemarkDialog: (remarkForm?: RemarkForm) => void;
  openHyperlinkDialog: (hyperlinkForm?: HyperlinkForm) => void;
  stagedRemarks: StagedRemarkAction[];
  stagedHyperlinks: StagedHyperlinkAction[];
  practicalSituations?: RaamwerkFilterItem[];
};

export interface HyperlinkActionInput {
  action: string;
  selectionId: number;
  status: string;
  externLink: ExternLink;
}

type hyperlinkActionFunc = (
  input: HyperlinkActionInput,
  callbackSuccess: () => void
) => void;

export type RemarkAction =
  | "RaamwerkSelectionItemRemark"
  | "RaamwerkItemRemark"
  | "RaamwerkExternLinkRemark";

type remarkActionFunc = (
  action: RemarkAction,
  form: RemarkForm,
  callbackSuccess: () => void
) => void;

export type RaamwerkTreeProps = {
  items: RaamwerkTreeItem[];
  organizations: RaamwerkFilterItem[];
  practicalSituations: RaamwerkFilterItem[];
  statusItems: SelectionListItem[];
  pagetypes: Pagetype[];
  edit: boolean;
  local?: boolean;
  open: Open;
  toggleOpen: (key: number) => void;
  targets: RaamwerkTarget[];
  targetsLoading: boolean;
  requireRaamwerkTargets: (
    pagetypeId: number,
    selectionId: number,
    organizationIds: number[]
  ) => void;
  linkRaamwerk?: (raamwerkLinks: {
    selectionItemId: number;
    links: {
      id: string;
      status: string;
      link?: { itemId: number };
      externLink?: { id: number; url: string; label: string };
    }[];
  }) => void;
  hyperlinkAction?: hyperlinkActionFunc;
  remarkAction: remarkActionFunc;
  pageActions: PageActions;
  raamwerkLijst: SelectionListItem;
  numberAdded?: number[];
};

export interface RaamwerkFilterItem {
  filterItem: SiteLink;
  itemIds: number[];
}

export type StagedSelectionItemAction = {
  $type: string;
  selectionId: number;
  disabled: boolean;
};

export type StagedRemarkAction = {
  $type: string;
  selectionId?: number;
  itemId?: number;
  externLinkId?: number;
};

export type StagedHyperlinkAction = {
  $type: string;
  selectionId: number;
  status: string;
  externLink: ExternLink;
};

export type TreeItemProps = {
  item: RaamwerkTreeItem;
  statusItems: SelectionListItem[];
  level: number;
  open: Open;
  toggleOpen: (key: number) => void;
  pagetypes: Pagetype[];
  openDialog: (selectionItemId: number) => (pagetype: Pagetype) => void;
  openHyperlinkDialog: (
    selectionId: number
  ) => (hyperlinkForm?: HyperlinkForm) => void;
  openRemarkDialog: (remarkForm?: RemarkForm) => void;
  edit: boolean;
  allowed: RaamwerkActionsAllowed;
  pageActions: PageActions;
  raamwerkAlias: string;
  onNvtChange: (selectionId: number, disabled: boolean) => void;
  numberAdded?: number[];
  practicalSituations?: RaamwerkFilterItem[];
};

export interface RaamwerkActionsAllowed {
  canAddLink: boolean;
  canLink: boolean;
  canHyperlink: boolean;
  canRemarkSelectionItem: boolean;
  canRemarkItem: boolean;
  canRemarkExternLink: boolean;
  canNvt: boolean;
}

export const raamwerkActionsAllowAll: RaamwerkActionsAllowed = {
  canAddLink: true,
  canLink: true,
  canHyperlink: true,
  canNvt: true,
  canRemarkExternLink: true,
  canRemarkItem: true,
  canRemarkSelectionItem: true,
};

export const raamwerkActionsAllowLocal: RaamwerkActionsAllowed = {
  canAddLink: false,
  canLink: true,
  canHyperlink: false,
  canNvt: false,
  canRemarkExternLink: false,
  canRemarkItem: false,
  canRemarkSelectionItem: true,
};

export type NakedTreeProps = {
  items: RaamwerkTreeItem[];
  statusItems: SelectionListItem[];
  level: number;
  open: Open;
  toggleOpen: (key: number) => void;
  pagetypes: Pagetype[];
  openDialog: (selectionItemId: number) => (pagetype: Pagetype) => void;
  openHyperlinkDialog: (
    selectionId: number
  ) => (hyperlinkForm?: HyperlinkForm) => void;
  openRemarkDialog: (remarkForm?: RemarkForm) => void;
  edit: boolean;
  allowed: RaamwerkActionsAllowed;
  pageActions: PageActions;
  raamwerkAlias: string;
  onNvtChange: (selectionId: number, disabled: boolean) => void;
  numberAdded?: number[];
  practicalSituations?: RaamwerkFilterItem[];
};

export interface HyperlinkForm {
  selectionId: number;
  externLink: ExternLink;
  status?: string;
}

export type HyperlinkDialogState = {
  show: boolean;
  hyperlinkForm?: HyperlinkForm;
};

export interface HyperlinkDialogProps {
  hyperlinkForm: HyperlinkForm;
  statusItems: SelectionListItem[];
  closeDialog(): void;
  hyperlinkAction: hyperlinkActionFunc;
}

export interface RemarkForm {
  remark?: string;
  itemId?: number;
  selectionId?: number;
  externLinkId?: number;
}

export type RemarkDialogState = {
  show: boolean;
  remarkForm?: RemarkForm;
};

export interface RemarkDialogProps {
  remarkForm?: RemarkForm;
  onSave: (form: RemarkForm) => void;
  onDelete: (form: RemarkForm) => void;
  onCancel: () => void;
}

export type AddDialogState = {
  show: boolean;
  selectionItemId?: number;
  pagetype?: Pagetype;
};

export interface AddDialogProps {
  selectionItemId: number;
  pagetype: Pagetype;
  statusItems: SelectionListItem[];
  targets: RaamwerkTarget[];
  targetsLoading: boolean;
  closeDialog(): void;
  linkRaamwerk: (
    raamwerkLinks: {
      selectionItemId: number;
      links: { id: string; status: string; link: { itemId: number } }[];
    },
    onSuccess: () => void
  ) => void;
}

export type TreeItemStateProps = {
  edit: boolean;
  allowed: RaamwerkActionsAllowed;
  statusItems: SelectionListItem[];
  statusLinkCount: { [key: string]: number };
  emptyItemCount: number;
  disabledSelectionItem: boolean;
  remark?: string;
  selectionId: number;
  openRemarkDialog: (remarkForm?: RemarkForm) => void;
  hasStagedRemark: boolean;
};

export interface CountedIconProps {
  children: JSX.Element;
  count: number;
  title?: string;
}

export interface AddButtonProps {
  pagetypes: Pagetype[];
  onClick: (pagetype: Pagetype) => void;
  onHyperlinkClick: () => void;
  disabled: boolean;
}
