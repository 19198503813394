import empty from "empty";
import { compose, setDisplayName, withProps } from "recompose";

import BasePage from "./basePage";

const emptyPageProps = ({ page = empty.object }) => {
  const error = page.loading === "error";
  return {
    page: undefined,
    tabs: empty.array,
    error,
    pageTitle: error ? "Niet gevonden" : undefined,
  };
};
export default compose(
  setDisplayName("EmptyPage"),
  withProps(emptyPageProps)
)(BasePage);
