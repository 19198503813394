import empty from "empty";
import { compose, setDisplayName } from "recompose";

import { requireFieldDefinitionsAction } from "../actions/data";
import {
  exportOverzichtAction,
  resetOverzichtAction,
  setOverzichtAction,
} from "../actions/utils";
import Overzicht from "../components/overzicht";
import { execOnChange } from "../recompose.contrib";
import { formSelector, overzichtSelector } from "../selectors/overzicht";
import { dispatchWrap } from "../utils";
import connect from "./connect";

export default compose(
  setDisplayName("Overzicht"),
  connect(formSelector, (dispatch) => ({
    update: dispatchWrap(setOverzichtAction, dispatch),
  })),
  connect(
    overzichtSelector,
    (
      dispatch,
      { form: { title, pagetype, fields, relations, all } = empty.object }
    ) => ({
      requireFieldDefinitions: () => dispatch(requireFieldDefinitionsAction()),
      exportOverzicht: () =>
        dispatch(
          exportOverzichtAction({
            title,
            pagetype,
            columns: [...fields, ...relations],
            all,
          })
        ),
      resetOverzicht: () => dispatch(resetOverzichtAction()),
    })
  ),
  execOnChange("requireFieldDefinitions")
)(Overzicht);
