import classNames from "classnames";
import empty from "empty";
import { get } from "lodash/fp";
import CircularProgress from "material-ui/CircularProgress";
import FlatButton from "material-ui/FlatButton";
import PropTypes from "prop-types";
import React from "react";

import { PageActions } from "../../actions/page";
import { readOnlyPageActions } from "../../business/models";
import {
  itemShape,
  relationShape,
  restoreShape,
} from "../../business/prop-types";
import connect from "../../containers/connect";
import Dialog from "../../containers/dialog";
import DefaultPage from "../../containers/pagetypes/defaultPage";
import {
  clusterRelationsSelector,
  restoreVersionSelector,
} from "../../selectors";
import { EntiteitenLoadingIndicator } from "./application/gegevenDialog";

const versionDialogStyle = {
  width: "80%",
  maxWidth: "1160px",
};

const PreviewInner = ({ hasPage, versionDialog, page, ...pageProps }) => (
  <>
    {hasPage && (
      <DefaultPage
        section
        hideRightbar={!versionDialog}
        page={page}
        pageActions={readOnlyPageActions}
        {...pageProps}
      />
    )}
    {!hasPage && <EntiteitenLoadingIndicator />}
  </>
);

const DiffLegend = () => (
  <div className="diff-legend">
    Nieuwe tekst is <ins className="diffmod">uitgelicht</ins>, verwijderd is{" "}
    <del className="diffmod">doorgestreept</del>.
  </div>
);

const Preview = ({
  onClose,
  onRestore,
  page,
  versionDialog,
  isCurrentVersion,
  restore: { busy = false } = empty.object,
  hasClusterRelations,
  ...pageProps
}) => {
  const pagetype = get("page.pagetype")(page);
  const hasPage = typeof pagetype === "string";
  return (
    <Dialog
      open
      autoScrollBodyContent
      repositionOnUpdate={hasPage}
      actions={
        <>
          {versionDialog && <DiffLegend />}
          {busy && <CircularProgress size={16} thickness={3.5} />}
          {versionDialog && (
            <FlatButton
              label="Versie terughalen"
              onClick={onRestore}
              disabled={
                isCurrentVersion || busy || !hasPage || hasClusterRelations
              }
            />
          )}
          <FlatButton
            secondary
            label="Sluiten"
            onClick={onClose}
            disabled={busy}
          />
        </>
      }
      className={classNames({ "version-dialog": versionDialog })}
      contentStyle={versionDialog ? versionDialogStyle : undefined}
    >
      <PreviewInner
        hasPage={hasPage}
        versionDialog={versionDialog}
        page={page}
        {...pageProps}
      />
    </Dialog>
  );
};

Preview.defaultProps = {
  versionDialog: false,
};

Preview.propTypes = {
  onClose: PropTypes.func.isRequired,
  onRestore: PropTypes.func.isRequired,
  versionDialog: PropTypes.bool,
  isCurrentVersion: PropTypes.bool,
  page: itemShape,
  relations: PropTypes.arrayOf(relationShape.isRequired).isRequired,
  restore: restoreShape.isRequired,
  hasClusterRelations: PropTypes.bool,
};

export default connect(
  (state, props) => ({
    restore: restoreVersionSelector(state, props),
    hasClusterRelations: clusterRelationsSelector(state, props),
  }),
  (dispatch, { itemId, versionId }) => ({
    onRestore: () => new PageActions(dispatch, itemId).restore(versionId),
  })
)(Preview);
