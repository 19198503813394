import {
  filter,
  find,
  findIndex,
  flow,
  groupBy,
  includes,
  keys,
  map,
  mapValues,
  sortBy,
  uniqBy,
} from "lodash/fp";
import Checkbox from "material-ui/Checkbox";
import CircularProgress from "material-ui/CircularProgress";
import React, { Fragment, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getPageAction } from "../../actions/data";
import { leftJoin } from "../../lodash";
import { SelectedApplicatieGegeven } from "../pagetypes/specials/application/models";

const checkSet = <T,>(set: Array<T>, value: T, checked: boolean): Array<T> => {
  if (checked && includes(value)(set)) {
    return set;
  }

  return checked ? [...set, value] : set.filter((v) => v !== value);
};

interface Props {
  itemId: number;
  values: Array<SelectedApplicatieGegeven>;
  onChange: (values: Array<SelectedApplicatieGegeven>) => void;
}

const AppGegevensPicker = (props: Props): JSX.Element => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getPageAction(props.itemId));
  }, [dispatch, props.itemId]);
  const page = useSelector((state: any) => state.data.pages[props.itemId]);
  const relations = useSelector(
    (state: any) => state.data.relations[props.itemId]
  );

  return page.page && relations.relations ? (
    <Component {...props} page={page.page} relations={relations.relations} />
  ) : (
    <CircularProgress />
  );
};

interface GegevenProps {
  cluster: any;
  checked: boolean;
  entiteitId: number;
  onCheck(gegeven: SelectedApplicatieGegeven, checked: boolean): void;
}

const Gegeven = ({
  cluster,
  entiteitId,
  checked,
  onCheck,
}: GegevenProps): JSX.Element => {
  const handleCheck = useCallback(
    (_: unknown, checked: boolean): void => {
      onCheck({ clusterId: cluster.clusterId, entiteitId }, checked);
    },
    [cluster.clusterId, entiteitId, onCheck]
  );

  return (
    <Checkbox
      value={cluster.clusterId}
      checked={checked}
      onCheck={handleCheck}
      label={cluster.gegeven.naam.value}
    />
  );
};

interface ComponentProps extends Props {
  page: any;
  relations: any[];
}

interface ApplicatieGegeven {
  clusterId: number;
}

const emptyObject: any = {};
const Component = ({
  page,
  relations,
  values,
  onChange,
}: ComponentProps): JSX.Element => {
  const handleChange = useCallback(
    (selected: SelectedApplicatieGegeven, checked: boolean): void => {
      onChange(checkSet(values, selected, checked));
    },
    [onChange, values]
  );
  const gegevens: ApplicatieGegeven[] = page.gegeven?.clusters ?? [];

  const join = leftJoin(
    (g: any): number => g.clusterId,
    filter((r: any): boolean => r.left.type === "entiteit")(relations),
    (r: any): number => r.right.pageClusterId
  )(gegevens);

  const entiteiten = flow(
    map((t: any[]) => t[1]?.left),
    filter((item) => item !== undefined),
    uniqBy(({ itemId }) => itemId),
    sortBy(({ label }) => label)
  )(join);

  const entiteitGroup = flow(
    filter(([_, { left: { itemId } = emptyObject } = emptyObject]) => itemId),
    map(
      ([{ clusterId }, { left: { itemId } = emptyObject } = emptyObject]) => ({
        itemId: itemId,
        clusterId,
      })
    ),
    groupBy("itemId"),
    mapValues(map("clusterId"))
  )(join);

  return (
    <>
      {flow(
        keys,
        map(Number),
        sortBy(
          (groupKey) => findIndex({ itemId: groupKey })(entiteiten) ?? groupKey
        ),
        map((groupKey) => {
          const { itemId = groupKey, label } =
            find({ itemId: groupKey })(entiteiten) ?? emptyObject;
          return (
            <Fragment key={groupKey}>
              {label && (
                <h3 style={{ fontWeight: 500 }} key={label}>
                  Entiteit: {label}
                </h3>
              )}
              {flow(
                filter(({ clusterId }) =>
                  includes(clusterId)(entiteitGroup[itemId])
                ),
                sortBy(["gegeven.naam.value"]),
                map((cluster: ApplicatieGegeven) => {
                  const checked =
                    find({
                      clusterId: cluster.clusterId,
                      entiteitId: itemId,
                    })(values) !== undefined;

                  return (
                    <Gegeven
                      cluster={cluster}
                      entiteitId={itemId}
                      key={cluster.clusterId}
                      checked={checked}
                      onCheck={handleChange}
                    />
                  );
                })
              )(gegevens)}
            </Fragment>
          );
        })
      )(entiteitGroup)}
    </>
  );
};

export default AppGegevensPicker;
