import empty from "empty";
import PropTypes from "prop-types";
import React from "react";
import Helmet from "react-helmet";

import {
  clusterWithFieldsShape,
  relationTypeSideShape,
  sessionShape,
} from "../business/prop-types";
import RecentOverzicht from "../containers/recentOverzicht";
import OverzichtForm from "./overzicht/overzichtForm";
import { overviewFormShape, pagetypeListItemShape } from "./overzicht/shapes";
import BasePage from "./pagetypes/basePage";

const OverzichtPage = Object.freeze({
  id: -1,
  siteId: -1,
  page: {
    title: "Overzicht",
    pagetype: "overzicht",
  },
});

const Overzicht = ({
  session: { user } = empty.object,
  fields,
  relations,
  exportOverzicht,
  resetOverzicht,
  pagetypeList,
  update,
  form,
  exportLoading,
  ...pageProps
}) => {
  const title = OverzichtPage.page.title;
  return (
    <div>
      <Helmet title={`${title} - Saar`} />

      <BasePage
        {...pageProps}
        id={-1}
        page={OverzichtPage}
        pageTitle={title}
        pageActions={{ busy: false, edit: false, dirty: false }}
        loading={false}
        selectedTab={0}
        tabChanged={empty.func}
        user={user}
        tabs={[
          {
            label: "Overzicht",
            children: pagetypeList.length > 0 && (
              <OverzichtForm
                fields={fields}
                relations={relations}
                exportOverzicht={exportOverzicht}
                resetOverzicht={resetOverzicht}
                pagetypeList={pagetypeList}
                update={update}
                form={form}
                loading={exportLoading}
                showAll={user.iznet === "Read" || user.iznet === "Write"}
              />
            ),
          },
        ]}
        rightbar={{
          children: [<RecentOverzicht key="recent-overzicht" />],
        }}
      />
    </div>
  );
};

Overzicht.propTypes = {
  session: sessionShape.isRequired,
  fields: PropTypes.arrayOf(clusterWithFieldsShape.isRequired).isRequired,
  relations: PropTypes.arrayOf(relationTypeSideShape.isRequired).isRequired,
  exportOverzicht: PropTypes.func.isRequired,
  resetOverzicht: PropTypes.func.isRequired,
  pagetypeList: PropTypes.arrayOf(pagetypeListItemShape.isRequired).isRequired,
  update: PropTypes.func.isRequired,
  form: overviewFormShape.isRequired,
  exportLoading: PropTypes.bool,
};

export default Overzicht;
