import empty from "empty";
import { compose, setDisplayName } from "recompose";

import connect from "./connect";
import DefaultPage from "./pagetypes/defaultPage";

export default compose(
  setDisplayName("Pagetype"),
  connect(
    ({
      data: {
        settings: { data: { enableKoppeling = "true" } = empty.object },
      },
    }) => ({
      enableKoppeling: enableKoppeling === "true",
    })
  )
)(DefaultPage);
