import { loadingProgress } from "async-lifecycle";
import empty from "empty";
import {
  compact,
  defaultTo,
  filter,
  flow,
  get,
  includes,
  map,
  some,
} from "lodash/fp";
import { createSelector } from "reselect";

import { prohibitEditInfozorgOnly } from "../business/authorization";
import { Side } from "../business/relations";
import { flown } from "../lodash";
import { isEmpty } from "../utils";

export const firstSlug = (pathname) => compact(pathname.split("/"))[0];

const miniSaarPathSelector = (
  { session: { miniSaar: { path } = empty.object } = empty.object },
  {
    params = empty.object,
    location: {
      pathname = "",
      query: { next = "" } = empty.object,
    } = empty.object,
  }
) =>
  (next || pathname.indexOf("/inloggen") === 0
    ? firstSlug(next)
    : params.minisaar || firstSlug(pathname)) || path;

export const miniSaarsSelector = ({
  data: {
    miniSaars: { data: list = empty.array } = empty.object,
  } = empty.object,
}) => list;

export const miniSaarSelector = createSelector(
  [miniSaarPathSelector, miniSaarsSelector],
  (selectedPath, saars) => {
    if (selectedPath) {
      const selectedSaar = saars.find((s) => s.path === selectedPath);
      if (selectedSaar) {
        return selectedSaar;
      }
    }

    return saars && saars.length > 0 ? saars[0] : undefined;
  }
);

export const userSelector = ({ session: { user = empty.object } }) => user;
const office365Selector = ({ session: { office365 = empty.object } }) =>
  office365;

const azureAdSelector = createSelector(
  [
    flow(get("session.azureAd"), defaultTo(empty.object)),
    flow(get("data.settings.data"), defaultTo(empty.object)),
  ],
  (azureAd, settings) =>
    settings["azuread-b2c_enabled"] === "true" ? azureAd : empty.object
);

const googleSsoSelector = get("data.settings.data.google_client_id");

export const requestPasswordSelector = createSelector(
  flow(get("session.request"), defaultTo(empty.object)),
  (request) => {
    const loading = loadingProgress(request.loading);
    return {
      loading,
    };
  }
);

export const sisenseSelector = createSelector(
  [flow(get("sagas.sisenseLogin"), defaultTo(empty.object))],
  ({
    value: { result: { redirect, logout } = empty.object } = empty.object,
    status: { loading } = empty.object,
  }) => ({ redirect, logout, loading })
);

export const sessionSelector = createSelector(
  [
    miniSaarSelector,
    userSelector,
    office365Selector,
    azureAdSelector,
    (_, props) => get("location.query.code")(props),
    sisenseSelector,
    googleSsoSelector,
  ],
  (
    { siteId } = empty.object,
    user,
    office365,
    azureAd,
    code,
    sisense,
    googleSsoClientId
  ) => {
    const { tasks = empty.array } = user;
    const mayEdit = flow(
      filter(({ prfIdt }) => prfIdt === 6),
      map(({ scope = empty.object }) => scope),
      some(
        ({ sitIdt = empty.array }) =>
          sitIdt === true || includes(siteId)(sitIdt)
      )
    )(tasks);
    const loading =
      loadingProgress(user.loading) ||
      loadingProgress(office365.loading) ||
      loadingProgress(sisense.loading);

    return {
      user: {
        ...user,
        mayAdd: mayEdit,
        mayEdit,
        mayAddToBieb: mayEdit && !prohibitEditInfozorgOnly(user),
      },
      changePassword: user.changePassword || false,
      isAuthenticated: !isEmpty(user.id),
      checked: user.checked === true,
      office365,
      azureAd,
      code,
      loading,
      sisense,
      googleSsoClientId,
    };
  }
);

export const mediaWidgetSelector = createSelector(
  [
    (_, { id }) => (id ? parseInt(id, 10) : undefined),
    ({ data: { mediaWidgets = empty.object } = empty.object }) => mediaWidgets,
  ],
  (id, mediaWidgets) => (id ? mediaWidgets[id] : undefined)
);

export const legoblokStructuresSelector = flow(
  get("data.addLegoblok.structures"),
  defaultTo(empty.array)
);

export const restoreVersionSelector = createSelector(
  [flow(get("form.restoreVersion"), defaultTo(empty.object))],
  (restoreVersion) => ({ busy: loadingProgress(restoreVersion.loading) })
);

export const getOneDriveFileSelector = createSelector(
  [
    flow(get("form.oneDriveFile"), defaultTo(empty.object)),
    (_, props) => flow(get("field.id"), defaultTo(-1))(props),
  ],
  (oneDriveFile, fieldId) => get(fieldId)(oneDriveFile)
);

export const clusterRelationsSelector = createSelector(
  [
    ({ data: { relations } }, { itemId }) =>
      flown(relations, get(`${itemId}.relations`), defaultTo(empty.array)),
  ],
  (relations) =>
    some((r) =>
      r.side === Side.using
        ? some((using) => Boolean(using.pageClusterId))(r[Side[r.side]])
        : Boolean(r[Side[r.side]].pageClusterId)
    )(relations)
);
