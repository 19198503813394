import { ListItemProps } from "material-ui";

import { PageActions } from "../../actions/page";
import {
  BiebRelatedPagetypeMapping,
  ContentAction,
  FieldData,
  KoppelingClusterMapping,
  NonEmptyFieldData,
  PageContent,
  PageData,
  Relation,
  RelationData,
  RelationSide,
  RelationType,
  RelationTypes,
  SiteLink,
  StructureItem,
  StructureModel,
  WithClusterId,
} from "../../business/models";
import { CollectedCluster, RelatedCollectedCluster } from "./utils";

export interface BiebCompareAndSelectWizardProps {
  biebPage: PageData;
  localPage: PageData;
  biebStepper: {
    activeStep: number;
    setActiveStep: (step: number) => void;
  };
  pageActions: PageActions;
  biebRelations: RelationData;
  localRelations: RelationData;
  relationTypes: RelationTypes;
  biebStructures: StructureModel[];
  biebRelatedPagetypeMapping: BiebRelatedPagetypeMapping;
  isIznet: boolean;
  close: () => void;
  setDisableAllActions: (value: boolean) => void;
  staged: ContentAction[];
}

export interface BiebUseWizardProps {
  biebItem: StructureItem;
  withHierarchyRelations: boolean;
  updateHierarchyRelations: (
    event: React.MouseEvent<{}>,
    checked: boolean
  ) => void;
  biebStepper: {
    activeStep: number;
    setActiveStep: (step: number) => void;
  };
  value: BiebUseSelection;
  onChange: (value: BiebUseSelection) => void;
  close: () => void;
  setDisableAllActions: (value: boolean) => void;
}

export interface BiebUseSelection {
  fieldIds: number[];
  relations: number[];
  applications:
    | { left: number | undefined; right: number | undefined }
    | undefined;
}

export const emptySelection: BiebUseSelection = {
  fieldIds: [],
  relations: [],
  applications: undefined,
};

export type GroupedRelation = [itemId: string, groupedRelations: Relation[]];

export type ClusterRelationViewProps = {
  item: SiteLink;
  side: RelationSide;
  relation: Relation;
  types: RelationType[];
  isMissing?: boolean;
  close?: () => void;
} & ListItemProps;

export interface CompareClusterTreeProps {
  biebItemId: number;
  localItemId: number;
  biebTree: CollectedCluster[];
  localTree: CollectedCluster[];
  relatedItems: RelatedCollectedCluster[];
  pageActions: PageActions;
  showAllFields: boolean;
  types: RelationType[];
  selected: number[];
  isKoppeling: boolean;
  koppelingClusterMapping?: KoppelingClusterMapping;
  staged: ContentAction[];
}

export interface CompareStaticFieldsProps {
  biebItemId: number;
  localItemId: number;
  zip: [
    string[],
    (FieldData & WithClusterId) | undefined,
    (FieldData & WithClusterId) | undefined
  ][];
  pageActions: PageActions;
  showAllFields: boolean;
  showEmptyMessage: boolean;
  extraRows?: JSX.Element;
  selected: number[];
  withCheckAll?: boolean;
  disabled?: boolean;
}

export type RepTypContent =
  | ClusterContent
  | ClusterContent[]
  | NonEmptyFieldData[];

export type ClusterContent = {
  name?: string;
  clusterId?: number;
  content: ClusterContent[] | NonEmptyFieldData[];
  relations?: Relation[];
  isContainer: boolean;
};

export interface RepetitiveClusterTreeProps {
  page: PageContent;
  relations: Relation[];
  types: RelationType[];
  onCheck: (isInputChecked: boolean, fieldIds: number[]) => void;
  value: BiebUseSelection;
  allFieldIds: number[];
  koppelingWithApplications: SiteLink[];
  koppelingClusterMapping?: KoppelingClusterMapping;
  close: () => void;
}

export interface WarnAboutMissingAppsProps {
  biebPage: PageData;
  koppelingCanBeUsed: boolean;
  koppelingWithApplications: SiteLink[];
  biebPagetypeMapping?: BiebRelatedPagetypeMapping;
  close: () => void;
}
