import React, { memo, useCallback } from "react";
import { useDispatch } from "react-redux";

import { toggleImageLightboxAction } from "../actions/utils";

interface ZoomImageProps {
  src: string;
  alt: string;
  width: number;
  height: number;
  ["data-id"]: string;
  id: string;
  className?: string;
}

const ZoomImage = memo(
  ({
    src,
    alt,
    width,
    height,
    "data-id": dataId,
    id,
    className,
  }: ZoomImageProps) => {
    const dispatch = useDispatch();
    const handleClick = useCallback(
      (event: React.MouseEvent<HTMLAnchorElement>) => {
        dispatch(toggleImageLightboxAction(true, src));
        event.preventDefault();
      },
      [dispatch, src]
    );

    return (
      <a
        href={src}
        onClick={handleClick}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={src}
          alt={alt}
          width={width}
          height={height}
          data-id={dataId}
          id={id}
          className={className}
        />
      </a>
    );
  }
);

export default ZoomImage;
