import { useEffect } from "react";

const TriggerResize = () => {
  useEffect(() => {
    /**
     * Onderstaande triggert een "resize" event waardoor het dialoog
     * opnieuw positie en grootte bepaalt, waardoor hopelijk altijd
     * de knoppen netjes in beeld blijven.
     */
    window.setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 256);
  }, []);
  return null;
};

export default TriggerResize;
