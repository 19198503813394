import { find, get } from "lodash/fp";
import { Step, StepButton, StepContent, Stepper } from "material-ui/Stepper";
import { grey600 } from "material-ui/styles/colors";
import PageView from "material-ui/svg-icons/action/pageview";
import PropTypes from "prop-types";
import React from "react";

import {
  entiteitShape,
  globalItemShape,
  selectedGegevenShape,
} from "../../business/prop-types";
import { flown } from "../../lodash";
import SelectKerngegevens from "./selectKerngegevens";
import VerantwoordingStep from "./verantwoordingStep";

const previewStyle = Object.freeze({ color: grey600 });

const AddKerngegevenStepper = ({
  entiteiten,
  onGegevenToggle,
  onVerantwoordingChange,
  currentGegevens,
  selectedGegevens,
  verantwoordingen,
  verantwoordingId,
  stepIndex,
  setStepIndex,
  onAdd,
  setPreview,
}) => (
  <Stepper activeStep={stepIndex} orientation="vertical">
    <Step>
      <StepButton onClick={() => setStepIndex(0)}>
        <span>
          Verantwoording
          {stepIndex > 0 &&
            flown(
              verantwoordingen,
              find({ id: verantwoordingId }),
              get("naam"),
              (naam) => naam && `: ${naam}`
            )}
          {stepIndex > 0 && verantwoordingId > 0 && (
            <span
              className="step-button-preview"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setPreview(verantwoordingId);
              }}
            >
              <PageView style={previewStyle} />
            </span>
          )}
        </span>
      </StepButton>
      <StepContent>
        <VerantwoordingStep
          verantwoordingen={verantwoordingen}
          verantwoordingId={verantwoordingId}
          onAdd={onAdd}
          onChange={onVerantwoordingChange}
          setPreview={setPreview}
        />
      </StepContent>
    </Step>
    <Step>
      <StepButton onClick={() => setStepIndex(0)}>
        <span>Entiteitgegevens</span>
      </StepButton>
      <StepContent>
        <SelectKerngegevens
          entiteiten={entiteiten}
          selectedGegevens={selectedGegevens}
          currentGegevens={currentGegevens}
          onGegevenToggle={onGegevenToggle}
        />
      </StepContent>
    </Step>
  </Stepper>
);
AddKerngegevenStepper.propTypes = {
  entiteiten: PropTypes.arrayOf(entiteitShape.isRequired).isRequired,
  onGegevenToggle: PropTypes.func.isRequired,
  onVerantwoordingChange: PropTypes.func.isRequired,
  currentGegevens: PropTypes.arrayOf(selectedGegevenShape.isRequired)
    .isRequired,
  selectedGegevens: PropTypes.arrayOf(selectedGegevenShape.isRequired)
    .isRequired,
  verantwoordingen: PropTypes.arrayOf(globalItemShape.isRequired).isRequired,
  verantwoordingId: PropTypes.number,
  stepIndex: PropTypes.number.isRequired,
  setStepIndex: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  setPreview: PropTypes.func.isRequired,
};

export default AddKerngegevenStepper;
