/* eslint  react/prop-types: off */
import IconButton from "material-ui/IconButton";
import Popover from "material-ui/Popover";
import ActionHistory from "material-ui/svg-icons/action/history";
import ActionHome from "material-ui/svg-icons/action/home";
import NavigationMenu from "material-ui/svg-icons/navigation/menu";
import React from "react";

import RecentItems from "../../containers/recentItems";
import { StoreProvider } from "../../store";
import { PathLink } from "../relation.pathlink";

const AppBar = ({
  authenticated,
  children,
  iconElementLeft,
  iconElementRight,
  onLeftIconButtonClick,
  showRecent,
  toggleRecent,
}) => {
  return (
    <div className="appBar">
      <div className="wrapper">
        <div className="first">
          <IconButton
            iconStyle={{ color: "#fff" }}
            onClick={onLeftIconButtonClick}
          >
            <NavigationMenu />
          </IconButton>
          {authenticated && (
            <IconButton
              iconStyle={{ color: "#fff" }}
              containerElement={<PathLink path="home" />}
            >
              <ActionHome />
            </IconButton>
          )}
          {authenticated && (
            <span>
              <IconButton
                iconStyle={{ color: "#fff" }}
                onClick={toggleRecent}
                title="Toon/verberg Recent bezocht"
              >
                <ActionHistory />
              </IconButton>
            </span>
          )}
          {iconElementLeft}
        </div>
        <div className="center">{children}</div>
        <div className="last">{iconElementRight}</div>
      </div>
      {showRecent && (
        <Popover
          open
          useLayerForClickAway
          onRequestClose={toggleRecent}
          style={{ margin: "50px 0 0 16px" }}
        >
          <StoreProvider>
            <RecentItems always />
          </StoreProvider>
        </Popover>
      )}
    </div>
  );
};

export default AppBar;
