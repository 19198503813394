import empty from "empty";
import { hashHistory } from "react-router";
import {
  branch,
  compose,
  pure,
  renderNothing,
  setDisplayName,
  withHandlers,
  withProps,
  withState,
} from "recompose";

import {
  doUseFromBiebAction,
  doUseFromBiebDialogAction,
} from "../../actions/bieb";
import BiebUseDialog from "../../components/bieb/biebUseDialog";
import { emptySelection } from "../../components/bieb/models";
import { dispatchWrap } from "../../utils";
import connect from "../connect";

export default compose(
  setDisplayName("BiebUseDialog"),
  pure,
  connect(
    ({
      form: {
        biebUseDialog: {
          open = false,
          busy = false,
          error = false,
          multi,
          created,
        } = empty.object,
      },
    }) => ({ open, busy, error, multi, created }),
    (dispatch) => ({
      close: () => dispatch(doUseFromBiebDialogAction(false)),

      goHome: () => {
        dispatch(doUseFromBiebDialogAction(false));
        dispatch((dispatch, getState) => {
          const {
            session: {
              miniSaar: { home },
            },
          } = getState();
          hashHistory.push(home);
        });
      },

      doUseBiebItem: dispatchWrap(doUseFromBiebAction, dispatch),
    })
  ),
  branch(({ open }) => !open, renderNothing),
  withState(
    "biebItem",
    "updateBiebItem",
    ({ page: { id, title, pagetype } = empty.object }) => ({
      itemId: id,
      label: title,
      pagetype,
    })
  ),
  withState("withHierarchyRelations", "updateWithHierarchyRelations", false),
  withState("biebUseSelection", "setBiebUseSelection", emptySelection),
  withProps(({ biebItem: { itemId, label } = empty.object }) => ({
    valid: itemId && itemId !== "" && label && label !== "",
  })),
  withHandlers({
    updateLabel:
      ({ biebItem, updateBiebItem }) =>
      ({ target: { value } = empty.object }) =>
        updateBiebItem({ ...biebItem, label: value }),
    updateHierarchyRelations:
      ({ updateWithHierarchyRelations }) =>
      ({ target: { checked } = empty.object }) =>
        updateWithHierarchyRelations(checked),
    doUseBiebItem:
      ({
        multi,
        biebItem,
        doUseBiebItem,
        withHierarchyRelations,
        biebUseSelection,
      }) =>
      () =>
        multi
          ? doUseBiebItem(multi)
          : doUseBiebItem({
              model: { ...biebItem, useSelection: biebUseSelection },
              withHierarchyRelations,
            }),
  })
)(BiebUseDialog);
