import { AsyncSingleValue } from "async-lifecycle-saga";
import classNames from "classnames";
import {
  compact,
  defaultTo,
  entries,
  filter,
  find,
  flatten,
  fromPairs,
  groupBy,
  has,
  identity,
  keys,
  map,
  orderBy,
  sortBy,
  uniq,
} from "lodash/fp";
import {
  Checkbox,
  CircularProgress,
  List,
  ListItem,
  Step,
  StepButton,
  StepContent,
  Stepper,
  Subheader,
} from "material-ui";
import React, {
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";

import { getPageAction, requireRelationsOnlyAction } from "../../actions/data";
import { invalidateRelations } from "../../actions/relations.invalidate";
import {
  BiebRelatedPagetypeMapping,
  KoppelingClusterMapping,
  PageData,
  Relation,
  RelationData,
  RelationType,
  RelationTypes,
  SiteLink,
  StoreRoot,
  StructureModel,
  emptyArray,
  emptyObject,
} from "../../business/models";
import { flown } from "../../lodash";
import { sagas } from "../../sagas";
import { arrayFlatMap, isNonEmptyArray } from "../../utils";
import Field from "../content/field";
import PreviewButton from "../material/PreviewButton";
import PagetypeIcon from "../pagetypeIcon";
import KoppelingApplicationsSelect from "./KoppelingApplicationsSelect";
import { BiebUseWizardProps, GroupedRelation } from "./models";
import RepetitiveClusterTree from "./RepetitiveClusterTree";
import TriggerResize from "./TriggerResize";
import { getAllFieldIds, pageFields } from "./utils";
import WarnAboutMissingApps from "./WarnAboutMissingApps";
import WithEnlarge from "./WithEnlarge";

export const groupedRelationComparer = (
  [_aItemId, a]: GroupedRelation,
  [_bItemId, b]: GroupedRelation
): number => {
  const aItem = a[0].side === 2 ? a[0].left : a[0].right;
  const bItem = b[0].side === 2 ? b[0].left : b[0].right;
  return (
    aItem.type.localeCompare(bItem.type) ||
    aItem.label.localeCompare(bItem.label)
  );
};

const BiebUseWizard = ({
  biebItem,
  withHierarchyRelations,
  updateHierarchyRelations,
  biebStepper: { activeStep, setActiveStep },
  value,
  onChange,
  close,
}: BiebUseWizardProps) => {
  const dispatch = useDispatch();
  const [
    biebStructures,
    biebPage,
    biebRelations,
    relationTypes,
    isIznet,
    biebPagetypeMapping,
  ]: [
    StructureModel[],
    PageData,
    RelationData,
    RelationTypes,
    boolean,
    AsyncSingleValue<BiebRelatedPagetypeMapping>
  ] = useSelector(
    ({
      data: {
        addLegoblok: { structures: biebStructures = emptyArray() },
        pages,
        relations,
        relationTypes,
      },
      session: { miniSaar },
      sagas: { biebPagetypeMapping },
    }: StoreRoot) => [
      biebStructures,
      pages[biebItem.itemId],
      relations[biebItem.itemId],
      relationTypes,
      miniSaar?.alias === "iznet",
      biebPagetypeMapping ?? emptyObject(),
    ]
  );
  const biebIntern =
    !isIznet && biebStructures.length > 1 ? biebStructures[0] : undefined;
  const biebStructureIds = biebStructures.map(({ id }) => id);

  useEffect(() => {
    if (!biebPage?.id) {
      dispatch(getPageAction(biebItem.itemId));
    }
  }, [biebItem, biebPage?.id, dispatch]);

  const fields = useMemo(() => pageFields(biebPage), [biebPage]);
  const relationTypesTypes = relationTypes.types ?? emptyArray();
  const hierarchyRelationTypeIds = useMemo(
    () =>
      relationTypesTypes
        .filter((type) => type.hierarchically)
        .map((type) => type.id),
    [relationTypesTypes]
  );
  const typeIds = useMemo(
    () =>
      relationTypesTypes
        .filter(
          (t) =>
            (biebPage?.page.pagetype === "koppeling" &&
            (t.alias === "applicatie_applicatie" ||
              t.alias === "veld_koppeling_veld")
              ? true
              : !t.using) &&
            !t.alias.startsWith("bieb_") &&
            !t.alias.startsWith("relatie_legoblok_") &&
            !t.alias.startsWith("legoblok_") &&
            !t.alias.endsWith("_bieb") &&
            !t.alias.endsWith("_legoblok")
        )
        .map((t) => t.id),
    [biebPage?.page.pagetype, relationTypesTypes]
  );
  const clusterTypes = useMemo(
    () =>
      relationTypesTypes.filter(
        (t) =>
          t.needsCluster &&
          !t.alias.startsWith("bieb_") &&
          !t.alias.startsWith("relatie_legoblok_") &&
          !t.alias.startsWith("legoblok_") &&
          !t.alias.endsWith("_bieb") &&
          !t.alias.endsWith("_legoblok")
      ),
    [relationTypesTypes]
  );

  /* Koppeling: find relationtypes */
  const koppelingApplicationType = useMemo(
    () => relationTypesTypes.find((t) => t.alias === "applicatie_applicatie"),
    [relationTypesTypes]
  );

  const koppelingVeldType = useMemo(
    () => relationTypesTypes.find((t) => t.alias === "veld_koppeling_veld"),
    [relationTypesTypes]
  );

  const biebRelationsRelations = biebRelations.relations ?? emptyArray();
  const relations = useMemo(() => {
    return biebRelationsRelations.filter((relation) => {
      if (!typeIds.includes(relation.relationTypeId)) return false;
      if (
        biebPage.page.pagetype === "koppeling" &&
        (relation.relationTypeId === koppelingApplicationType?.id ||
          relation.relationTypeId === koppelingVeldType?.id)
      ) {
        return (
          biebStructureIds.includes(relation.left.structureId) &&
          biebStructureIds.includes(relation.right.structureId)
        );
      }

      const other = relation.side === 2 ? relation.left : relation.right;
      return biebStructureIds.includes(other.structureId);
    });
  }, [
    biebRelationsRelations,
    typeIds,
    biebPage.page.pagetype,
    koppelingApplicationType?.id,
    koppelingVeldType?.id,
    biebStructureIds,
  ]);

  const grouped: GroupedRelation[] = useMemo(
    () =>
      flown(
        relations,
        groupBy(
          (r: Relation) =>
            (r.side === 3 ? r.using[0] : r.side === 2 ? r.left : r.right).itemId
        ),
        entries,
        defaultTo(emptyArray())
      ).sort(groupedRelationComparer),
    [relations]
  );

  const clusterRelations = useMemo(() => {
    return biebRelationsRelations.filter(
      (r) =>
        clusterTypes.map((t) => t.id).includes(r.relationTypeId) &&
        (r.side === 3 ? r.using[0] : r.side === 1 ? r.left : r.right)
          .pageClusterId !== undefined &&
        (r.side === 3 || !isNonEmptyArray(r.using))
    );
  }, [biebRelationsRelations, clusterTypes]);

  /* Koppeling: create array with related application items */
  const koppelingWithApplications = useMemo(
    () =>
      biebRelationsRelations
        .filter((relation) =>
          !koppelingApplicationType ||
          koppelingApplicationType.id !== relation.relationTypeId
            ? false
            : biebStructureIds.includes(relation.left.structureId) &&
              biebStructureIds.includes(relation.right.structureId)
        )
        .flatMap((relation) => [relation.left, relation.right]),
    [biebRelationsRelations, biebStructureIds, koppelingApplicationType]
  );

  const biebApplicationType = useMemo(
    () => relationTypesTypes.find((t) => t.alias === "bieb_applicatie"),
    [relationTypesTypes]
  );

  const isKoppeling = useMemo(
    () => biebPage?.page.pagetype === "koppeling",
    [biebPage?.page.pagetype]
  );

  const koppelingApplications: {
    left: SiteLink[] | undefined;
    right: SiteLink[] | undefined;
  } = useSelector((store: StoreRoot) => {
    if (!isKoppeling || !koppelingWithApplications || !biebApplicationType) {
      return emptyObject();
    }

    return {
      left:
        has("[0].itemId")(koppelingWithApplications) &&
        has(`data.relations[${koppelingWithApplications[0].itemId}].relations`)(
          store
        )
          ? store.data.relations[koppelingWithApplications[0].itemId].relations
              .filter(
                (relation) =>
                  relation.relationTypeId === biebApplicationType.id &&
                  !relation.right.variantId
              )
              .map((relation) => relation.right)
          : emptyArray(),
      right:
        has("[1].itemId")(koppelingWithApplications) &&
        has(`data.relations[${koppelingWithApplications[1].itemId}].relations`)(
          store
        )
          ? store.data.relations[koppelingWithApplications[1].itemId].relations
              .filter(
                (relation) =>
                  relation.relationTypeId === biebApplicationType.id &&
                  !relation.right.variantId
              )
              .map((relation) => relation.right)
          : emptyArray(),
    };
  });

  useEffect(() => {
    /* Koppeling: get related "used items"  */
    if (biebPage?.id && biebPage.page.pagetype === "koppeling") {
      dispatch({
        type: sagas.biebPagetypeMapping.require,
        payload: { biebItemId: biebPage.id },
        onSuccess: (data: { body: BiebRelatedPagetypeMapping }) => {
          for (const key in data.body) {
            dispatch(invalidateRelations(key));
            dispatch(requireRelationsOnlyAction(key));
          }
        },
      });
    } else {
      dispatch({ type: sagas.biebPagetypeMapping.clear });
    }
  }, [biebPage.id, biebPage.page.pagetype, dispatch]);

  /* Koppeling: check if the koppeling can be used */
  const koppelingCanBeUsed = useMemo(() => {
    if (biebPage?.page.pagetype !== "koppeling") {
      return false;
    }

    if (!koppelingWithApplications || koppelingWithApplications.length !== 2) {
      return false;
    }

    if (!biebPagetypeMapping.value) {
      return false;
    }

    return (
      flown(
        biebPagetypeMapping.value[koppelingWithApplications[0].itemId],
        defaultTo(emptyObject),
        keys
      ).length > 0 &&
      flown(
        biebPagetypeMapping.value[koppelingWithApplications[1].itemId],
        defaultTo(emptyObject),
        keys
      ).length > 0
    );
  }, [
    biebPage?.page.pagetype,
    biebPagetypeMapping.value,
    koppelingWithApplications,
  ]);

  const koppelingVeldRelations = useMemo(() => {
    return biebRelationsRelations.filter(
      (r) => r.relationTypeId === koppelingVeldType?.id
    );
  }, [biebRelationsRelations, koppelingVeldType?.id]);

  const koppelingClusterMapping = useMemo<KoppelingClusterMapping>(() => {
    if (!koppelingCanBeUsed || !biebPagetypeMapping.value) return emptyObject();

    const leftMapping =
      biebPagetypeMapping.value[koppelingWithApplications[0].itemId];
    const selectedLeftLocalItemId = flown(
      leftMapping,
      keys,
      find((id) => Number(id) === Number(value.applications?.left)),
      defaultTo(koppelingApplications?.left?.[0]?.itemId)
    );

    const rightMapping =
      biebPagetypeMapping.value[koppelingWithApplications[1].itemId];
    const selectedRightLocalItemId = flown(
      rightMapping,
      keys,
      find((id) => Number(id) === Number(value.applications?.right)),
      defaultTo(koppelingApplications?.right?.[0]?.itemId)
    );

    return flown(
      koppelingVeldRelations,
      defaultTo(emptyArray()),
      filter((r: Relation) => has("using[0].pageClusterId")(r)),
      map((r: Relation) => {
        if (!r.using[0].pageClusterId) {
          return undefined;
        }

        const leftLocalClusterId = (
          leftMapping?.[selectedLeftLocalItemId] ?? emptyArray()
        ).find((m) => m.vanPagClsIdt === r.left.pageClusterId)?.narPagClsIdt;
        const rightLocalClusterId = (
          rightMapping?.[selectedRightLocalItemId] ?? emptyArray()
        ).find((m) => m.vanPagClsIdt === r.right.pageClusterId)?.narPagClsIdt;

        return [
          r.using[0].pageClusterId,
          [leftLocalClusterId, rightLocalClusterId],
        ];
      }),
      fromPairs
    );
  }, [
    biebPagetypeMapping.value,
    koppelingApplications?.left,
    koppelingApplications?.right,
    koppelingCanBeUsed,
    koppelingVeldRelations,
    koppelingWithApplications,
    value.applications?.left,
    value.applications?.right,
  ]);

  /* Initially select all fields */
  const allFieldIds = useMemo(
    () => getAllFieldIds(biebPage, koppelingClusterMapping),
    [biebPage, koppelingClusterMapping]
  );

  const koppelingMappingBusy = useMemo(
    () =>
      biebPage?.page.pagetype === "koppeling"
        ? !biebPagetypeMapping.value || biebPagetypeMapping?.status.loading
        : false,
    [
      biebPage?.page.pagetype,
      biebPagetypeMapping?.status.loading,
      biebPagetypeMapping.value,
    ]
  );

  const koppelingRelationIds = useMemo(
    () =>
      flown(
        grouped,
        filter(
          ([_itemId, groupedRelations]) =>
            koppelingCanBeUsed &&
            groupedRelations.some(
              (r: Relation) => r.relationTypeId === koppelingApplicationType?.id
            )
        ),
        map(([_itemId, groupedRelations]) =>
          groupedRelations
            .filter(
              (r: Relation) => r.relationTypeId === koppelingApplicationType?.id
            )
            .map((r: Relation) => r.id)
        ),
        flatten
      ),
    [grouped, koppelingApplicationType?.id, koppelingCanBeUsed]
  );

  const [initialized, setInitialized] = useState(false);
  useEffect(() => {
    if (
      biebPage?.id &&
      !koppelingMappingBusy &&
      koppelingClusterMapping &&
      (!isKoppeling || koppelingApplications) &&
      !initialized
    ) {
      onChange({
        fieldIds: allFieldIds,
        relations: koppelingRelationIds,
        applications:
          isKoppeling && koppelingApplications
            ? {
                left: koppelingApplications.left?.[0]?.itemId,
                right: koppelingApplications.right?.[0]?.itemId,
              }
            : undefined,
      });

      setInitialized(true);
    }
  }, [
    allFieldIds,
    biebPage?.id,
    biebPagetypeMapping.status.loading,
    initialized,
    isKoppeling,
    koppelingApplications,
    koppelingClusterMapping,
    koppelingMappingBusy,
    koppelingRelationIds,
    onChange,
  ]);

  const handleStaticFieldClick = useCallback(
    (event: MouseEvent, isInputChecked: boolean) => {
      const fieldId = Number((event.target as HTMLInputElement).value);
      onChange({
        ...value,
        fieldIds: isInputChecked
          ? [...value.fieldIds, fieldId]
          : value.fieldIds.filter((v) => v !== fieldId) ?? [],
      });
    },
    [onChange, value]
  );

  const handleRelationClick = useCallback(
    (event: MouseEvent, isInputChecked: boolean) => {
      const relationIds = (event.target as HTMLInputElement).value
        .split(",")
        .map((relId: string) => Number(relId));

      onChange({
        ...value,
        relations: isInputChecked
          ? [...value.relations, ...relationIds]
          : value.relations.filter((v) => !relationIds.includes(v)) ?? [],
      });
    },
    [onChange, value]
  );

  const allOptionalFields = useMemo(
    () =>
      fields
        .filter((field) => !field.content.definition.required)
        .map((f) => f.content.id),
    [fields]
  );

  const handleAllFieldsClick = useCallback(
    (_: unknown, isInputChecked: boolean) => {
      onChange({
        ...value,
        fieldIds: isInputChecked
          ? uniq([...value.fieldIds, ...allOptionalFields])
          : value.fieldIds.filter((f) => !allOptionalFields.includes(f)),
      });
    },
    [allOptionalFields, onChange, value]
  );

  const forbiddenRelation = useCallback(
    ({ left, right, using }: Relation): boolean =>
      (biebItem?.pagetype !== "koppeling" &&
        (right.type === "koppeling" || left.type === "koppeling")) ||
      (biebPage?.page.pagetype !== "koppeling" &&
        using?.[0]?.type === "koppeling") ||
      right.type === "legoblok" ||
      left.type === "legoblok" ||
      using?.[0]?.type === "legoblok",
    [biebItem?.pagetype, biebPage?.page.pagetype]
  );

  const forbiddenRelationType = useCallback(
    ({ left, right, using }: RelationType): boolean =>
      (biebItem?.pagetype !== "koppeling" &&
        (right?.pagetype === "koppeling" || left?.pagetype === "koppeling")) ||
      (biebPage?.page.pagetype !== "koppeling" &&
        using?.pagetype === "koppeling") ||
      right?.pagetype === "legoblok" ||
      left?.pagetype === "legoblok" ||
      using?.pagetype === "legoblok",
    [biebItem?.pagetype, biebPage?.page.pagetype]
  );

  const eligibleRelations = useMemo<Relation[]>(
    () =>
      flown(
        grouped,
        arrayFlatMap(([_, relations]: GroupedRelation) =>
          relations.some(forbiddenRelation) ? emptyArray() : relations
        )
      ),
    [forbiddenRelation, grouped]
  );

  const handleAllRelationsClick = useCallback(
    (_: unknown, isInputChecked: boolean) => {
      onChange({
        ...value,
        relations: [
          ...koppelingRelationIds,
          ...(isInputChecked ? eligibleRelations.map((r) => r.id) : []),
        ],
      });
    },
    [onChange, value, koppelingRelationIds, eligibleRelations]
  );

  const handleClusterClick = useCallback(
    (isInputChecked: boolean, fieldIds: number[]) => {
      onChange({
        fieldIds: uniq(
          isInputChecked
            ? [...value.fieldIds, ...fieldIds]
            : value.fieldIds.filter((v) => !fieldIds.includes(v))
        ),
        relations: value.relations,
        applications: value.applications,
      });
    },
    [onChange, value]
  );

  if (!biebPage?.id || !initialized || koppelingMappingBusy) {
    return <CircularProgress />;
  }

  return (
    <div>
      <WarnAboutMissingApps
        biebPage={biebPage}
        koppelingCanBeUsed={koppelingCanBeUsed}
        koppelingWithApplications={koppelingWithApplications}
        biebPagetypeMapping={biebPagetypeMapping?.value}
        close={close}
      />

      {biebPage?.page.pagetype === "koppeling" &&
        ((koppelingApplications.left?.length ?? 0) > 1 ||
          (koppelingApplications.right?.length ?? 0) > 1) && (
          <KoppelingApplicationsSelect
            key={JSON.stringify(koppelingApplications)}
            value={value}
            onChange={onChange}
            left={koppelingApplications.left}
            right={koppelingApplications.right}
          />
        )}

      <Subheader>Over te nemen</Subheader>
      <Stepper activeStep={activeStep} orientation="vertical">
        <Step key={0}>
          <StepButton onClick={() => setActiveStep(0)}>
            <span>Welke inhoud</span>
          </StepButton>
          <StepContent>
            {fields.length > 0 ? (
              <List>
                <ListItem
                  primaryText="Alles selecteren"
                  className="bieb-use-wizard-field select-all"
                  leftCheckbox={
                    <Checkbox
                      checked={allOptionalFields.every((f) =>
                        value.fieldIds.includes(f)
                      )}
                      onCheck={handleAllFieldsClick}
                      style={{ top: "calc(50% - 12px)" }}
                    />
                  }
                />
                {fields.map((field) => {
                  const required = Boolean(field.content.definition.required);
                  const fieldNode = (
                    <Field
                      className={classNames({ required })}
                      omitLabel={false}
                      fullWidth={false}
                      field={field.content}
                      title={required ? "Verplicht veld" : ""}
                    />
                  );
                  return (
                    <ListItem
                      key={field.content.id}
                      className="bieb-use-wizard-field"
                      primaryText={
                        field.content.definition.dataTypeCode === 5 ? (
                          <WithEnlarge field={fieldNode} />
                        ) : (
                          fieldNode
                        )
                      }
                      leftCheckbox={
                        <Checkbox
                          disabled={required}
                          value={field.content.id}
                          checked={
                            required ||
                            value.fieldIds.includes(field.content.id)
                          }
                          onCheck={handleStaticFieldClick}
                          style={{ top: "calc(50% - 12px)" }}
                        />
                      }
                    />
                  );
                })}
              </List>
            ) : (
              <p>Er is geen inhoud gevonden om over te nemen.</p>
            )}
            <TriggerResize />
          </StepContent>
        </Step>
        <Step key={1}>
          <StepButton onClick={() => setActiveStep(1)}>
            <span>Welke repeterende clusters</span>
          </StepButton>
          <StepContent>
            <RepetitiveClusterTree
              page={biebPage.page}
              relations={clusterRelations}
              types={clusterTypes}
              onCheck={handleClusterClick}
              value={value}
              allFieldIds={allFieldIds}
              koppelingWithApplications={koppelingWithApplications}
              koppelingClusterMapping={koppelingClusterMapping}
              close={close}
            />

            <TriggerResize />
          </StepContent>
        </Step>
        <Step key={2}>
          <StepButton onClick={() => setActiveStep(2)}>
            <span>Welke relaties</span>
          </StepButton>
          <StepContent>
            {relations.length > 0 ? (
              <List>
                {relations.some((r) =>
                  hierarchyRelationTypeIds.includes(r.relationTypeId)
                ) && (
                  <ListItem
                    primaryText="Onderliggende items meenemen"
                    className="bieb-use-wizard-field"
                    leftCheckbox={
                      <Checkbox
                        checked={withHierarchyRelations}
                        onCheck={updateHierarchyRelations}
                        style={{ top: "calc(50% - 12px)" }}
                      />
                    }
                  />
                )}
                <ListItem
                  primaryText="Alles selecteren"
                  className="bieb-use-wizard-field select-all"
                  leftCheckbox={
                    <Checkbox
                      checked={eligibleRelations
                        .map((r) => r.id)
                        .every((r) => value.relations.includes(r))}
                      onCheck={handleAllRelationsClick}
                      style={{ top: "calc(50% - 12px)" }}
                    />
                  }
                />
                {flown(
                  grouped,
                  map(([_itemId, groupedRelations]: GroupedRelation) => {
                    const relTypeIds = groupedRelations.map(
                      (r) => r.relationTypeId
                    );
                    const types = relationTypesTypes.filter((t) =>
                      relTypeIds.includes(t.id)
                    );

                    const typeLabels = (spec: boolean): string[] =>
                      flown(
                        groupedRelations,
                        map((r: Relation): string | undefined => {
                          const type = types.find(
                            (t) => t.id === r.relationTypeId
                          );
                          return !type
                            ? undefined
                            : r.side === 3
                            ? spec
                              ? type.using?.labelSpec
                              : type.using?.label
                            : r.side === 1
                            ? type.left?.label
                            : type.right?.label;
                        }),
                        compact,
                        uniq,
                        sortBy(identity)
                      );

                    const relationWillBeCreatedForKoppeling =
                      koppelingCanBeUsed &&
                      groupedRelations[0].relationTypeId ===
                        koppelingApplicationType?.id;

                    const relationWillBeCreatedByHierarchy =
                      withHierarchyRelations &&
                      types.some((t) => t.hierarchically);

                    const relationForbidden = types.some(forbiddenRelationType);

                    const extraText = relationForbidden
                      ? " - Niet mogelijk om over te nemen"
                      : "";

                    const others =
                      groupedRelations[0].side === 3
                        ? [groupedRelations[0].left, groupedRelations[0].right]
                        : [
                            groupedRelations[0].side === 2
                              ? groupedRelations[0].left
                              : groupedRelations[0].right,
                          ];

                    const relationIds = groupedRelations.map((r) => r.id);

                    return others.map((other, i) => ({
                      other,
                      options: {
                        typeLabels: typeLabels(i === 1),
                        relationWillBeCreatedForKoppeling,
                        relationWillBeCreatedByHierarchy,
                        relationForbidden,
                        extraText,
                        relationIds,
                      },
                    }));
                  }),
                  flatten,
                  orderBy(["other.label"], ["asc"]),
                  map(
                    ({
                      other,
                      options: {
                        typeLabels,
                        relationWillBeCreatedForKoppeling,
                        relationWillBeCreatedByHierarchy,
                        relationForbidden,
                        extraText,
                        relationIds,
                      },
                    }) => (
                      <ListItem
                        key={`item-${other.itemId}`}
                        className="bieb-use-wizard-field relation"
                        primaryText={other.label}
                        secondaryText={`${typeLabels.join(", ")}${extraText}`}
                        leftIcon={
                          <PagetypeIcon
                            type={other.type}
                            biebIntern={
                              biebIntern && other.structureId === biebIntern.id
                                ? biebIntern.name
                                : undefined
                            }
                            style={{
                              top: "calc(50% - 12px)",
                              margin: "0 0 0 48px",
                            }}
                          />
                        }
                        leftCheckbox={
                          <Checkbox
                            value={relationIds.join(",")}
                            checked={
                              relationWillBeCreatedForKoppeling ||
                              relationWillBeCreatedByHierarchy ||
                              relationIds.every((relId: number) =>
                                value.relations.includes(relId)
                              )
                            }
                            title={
                              relationWillBeCreatedForKoppeling
                                ? "Relatie naar dit item is verplicht"
                                : undefined
                            }
                            onCheck={handleRelationClick}
                            disabled={
                              relationWillBeCreatedForKoppeling ||
                              relationWillBeCreatedByHierarchy ||
                              relationForbidden
                            }
                            style={{ top: "calc(50% - 12px)" }}
                          />
                        }
                        rightIcon={<PreviewButton itemId={other.itemId} />}
                      />
                    )
                  )
                )}
              </List>
            ) : (
              <p>Er zijn geen relaties gevonden om over te nemen.</p>
            )}

            <TriggerResize />
          </StepContent>
        </Step>
      </Stepper>
    </div>
  );
};

export default BiebUseWizard;
