import classNames from "classnames";
import { PropsWithChildren } from "react";
import { useCurrentBreakpointName } from "react-socks";

export interface ResponsiveWrapperProps {
  className?: string;
  classNameDesktop?: string;
  classNameMobile?: string;
}

const ResponsiveWrapper = ({
  className,
  classNameDesktop = "desktop",
  classNameMobile = "mobile",
  children,
}: PropsWithChildren<ResponsiveWrapperProps>) => {
  const breakpointName = useCurrentBreakpointName();
  const mobile = breakpointName.endsWith("small");
  const wrapperClassName = classNames(className, {
    [classNameMobile]: mobile,
    [classNameDesktop]: !mobile,
  });
  return <div className={wrapperClassName}>{children}</div>;
};

export default ResponsiveWrapper;
