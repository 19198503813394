import arrayMove from "array-move";
import { loadingProgress } from "async-lifecycle";
import classNames from "classnames";
import empty from "empty";
import { every, includes, some } from "lodash/fp";
import IconButton from "material-ui/IconButton";
import Popover from "material-ui/Popover";
import RaisedButton from "material-ui/RaisedButton";
import Delete from "material-ui/svg-icons/action/delete";
import Add from "material-ui/svg-icons/content/add";
import React, { useCallback, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SortableContainer, SortableElement } from "react-sortable-hoc";

import { bulkcopyAction, exportToWordAction } from "../../actions/data";
import { flown } from "../../lodash";
import Word from "../../svg/word";
import Subheader from "../material/Subheader";
import PagetypeIcon from "../pagetypeIcon";
import Badged from "./Badged";

const add = (id) => ({ type: "WORDBASKET_ADD", payload: { id } });
const del = (id) => ({ type: "WORDBASKET_DELETE", payload: { id } });
const set = (items) => ({ type: "WORDBASKET_SET", payload: items });

const WordMaker = ({ itemId, biebColor, biebBackgroundColor, enableCopy }) => {
  // Hooks
  const { loading, pages, basket, biebStructures } = useSelector(
    ({
      data: {
        addLegoblok: { structures: biebStructures = empty.array },
        exportWord: { loading },
        pages,
      },
      form: { wordBasket: basket },
    }) => ({ loading, pages, basket, biebStructures })
  );
  const dispatch = useDispatch();
  const anchor = useRef(null);
  const [open, setOpen] = useState(false);

  // Handler hooks
  const handleWordExport = useCallback(() => {
    dispatch(exportToWordAction(basket));
    setOpen(false);
  }, [basket, dispatch, setOpen]);
  const handleCopyBasket = useCallback(() => {
    dispatch(bulkcopyAction(basket));
    setOpen(false);
  }, [basket, dispatch, setOpen]);
  const handleIconClick = useCallback(() => {
    setOpen(true);
  }, [setOpen]);
  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);
  const handleAdd = useCallback(() => {
    dispatch(add(itemId));
  }, [dispatch, itemId]);
  const handleDelete = useCallback((id) => () => dispatch(del(id)), [dispatch]);

  const busy = loadingProgress(loading);
  const style = {
    backgroundColor: busy ? "white" : biebBackgroundColor,
    borderWidth: 5,
    borderStyle: "solid",
    borderColor: biebBackgroundColor,
    borderRadius: 5,
    padding: 0,
    width: 34,
    display: "inline-block",
    height: 34,
    overflow: "hidden",
    verticalAlign: "middle",
    marginRight: 8,
    opacity: busy ? 0.5 : 1,
  };

  const DivListItem = ({ inbasket, id, Icon, onClick, ...props }) => (
    <div
      className={classNames("wordmaker-draggable", {
        "wordmaker-basket": inbasket,
      })}
      {...props}
    >
      <div>
        <PagetypeIcon
          type={pages[id].page.pagetype}
          simpleIcon
          insideBieb={some({ id: pages[id].siteId })(biebStructures)}
        />
      </div>
      <div className="wordmaker-title">{pages[id].title}</div>
      <div className="wordmaker-icon">
        <Icon onClick={onClick} />
      </div>
    </div>
  );
  const SortableListItem = SortableElement(DivListItem);

  const DivList = ({ ...props }) => (
    <div {...props}>
      {basket.map((id, index) => (
        <SortableListItem
          inbasket
          index={index}
          key={id}
          id={id}
          Icon={Delete}
          onClick={handleDelete(id)}
        />
      ))}
    </div>
  );
  const SortableList = SortableContainer(DivList);

  const renderAddItem = (id, Icon, onClick) => (
    <DivListItem key={id} id={id} onClick={onClick} Icon={Icon} />
  );

  return (
    <div style={{ lineHeight: "48px" }} ref={anchor}>
      <Badged
        count={basket.length}
        style={{
          color: "#fff",
          backgroundColor: "#103f91",
        }}
      >
        <IconButton
          disabled={busy}
          iconStyle={{ color: biebColor }}
          style={style}
          onClick={handleIconClick}
        >
          <Word />
        </IconButton>
      </Badged>
      {open && (
        <Popover
          open
          anchorEl={anchor.current}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          targetOrigin={{ vertical: "top", horizontal: "right" }}
          onRequestClose={handleClose}
        >
          <Subheader>Word maker</Subheader>
          <div style={{ padding: 16 }}>
            {flown(
              basket,
              every((id) => pages[id])
            ) && (
              <SortableList
                distance={5}
                lockAxis="y"
                onSortEnd={({ oldIndex, newIndex }) =>
                  dispatch(set(arrayMove(basket, oldIndex, newIndex)))
                }
              />
            )}
            {!includes(itemId)(basket) &&
              pages[itemId] &&
              renderAddItem(itemId, Add, handleAdd)}
            <div style={{ display: "flex", justifyContent: "center" }}>
              <RaisedButton
                primary
                onClick={handleWordExport}
                disabled={basket.length === 0}
                label="Exporteer naar Word"
              />
            </div>
            {enableCopy && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 10,
                }}
              >
                <RaisedButton
                  secondary
                  onClick={handleCopyBasket}
                  disabled={basket.length === 0}
                  label="Kopieer naar deze Saar"
                />
              </div>
            )}
          </div>
        </Popover>
      )}
    </div>
  );
};

export default WordMaker;
