import { useSelector } from "react-redux";
import { createSelector } from "reselect";

import { StoreRoot } from "../../business/models";
import {
  Embed,
  EmbedProps,
  NoEmbedAccount,
} from "../../components/pagetypes/Embed";
import {
  currentMiniSaarSelector,
  miniSaarsSelector,
} from "../../selectors/list";

const embedSelector = createSelector(
  [
    ({
      sagas: {
        sisenseLogin: { status, value },
      },
    }: StoreRoot) => ({
      loading: status.loading,
      success: Boolean(value?.success),
    }),
    miniSaarsSelector,
    currentMiniSaarSelector,
  ],
  (sisense, miniSaars, currentMiniSaar) => ({
    sisense,
    miniSaar: miniSaars.find(
      ({ key }: { key: string }) => key === currentMiniSaar.key
    ),
  })
);

const EmbedContainer = (props: EmbedProps) => {
  const embedData = useSelector(embedSelector);
  if (embedData.sisense.loading || !embedData.miniSaar) {
    return null;
  }

  if (!embedData.sisense.success) {
    return <NoEmbedAccount />;
  }

  return <Embed {...props} miniSaar={embedData.miniSaar} />;
};

export default EmbedContainer;
