import { FlatButton } from "material-ui";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router";

import { doUpdateFromBiebDialogAction } from "../../actions/bieb";
import { getPageAction } from "../../actions/data";
import { PageActions, invalidatePage } from "../../actions/page";
import { invalidateRelations } from "../../actions/relations.invalidate";
import { getCurrentPageAction, startPageJobWatcher } from "../../actions/utils";
import Dialog from "../../containers/dialog";
import { sagas } from "../../sagas";
import { biebUpdateDialogSelector } from "../../selectors/bieb";
import PageRefreshIndicator from "../pagetypes/PageRefreshIndicator";
import BiebCompareAndSelectWizard from "./BiebCompareAndSelectWizard";

const BiebUpdateDialog = () => {
  const [
    biebUpdateDialog,
    biebStructures,
    biebPage,
    localPage,
    biebRelations,
    localRelations,
    relationTypes,
    biebPagetypeMapping,
    isIznet = false,
    dirty = false,
    staged,
  ] = useSelector(biebUpdateDialogSelector);

  const dispatch = useDispatch();

  const [activeBiebStep, setActiveBiebStep] = useState(0);
  const handleActiveStep = useCallback((step: number) => {
    setActiveBiebStep(step);

    /**
     * Onderstaande triggert een "resize" event waardoor het dialoog
     * opnieuw positie en grootte bepaalt, waardoor hopelijk altijd
     * de knoppen netjes in beeld blijven.
     */
    window.setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 256);
  }, []);

  const localPageActions = useMemo(
    () => (localPage ? new PageActions(dispatch, localPage.id) : undefined),
    [dispatch, localPage]
  );

  const resetDialog = useCallback(() => {
    dispatch(doUpdateFromBiebDialogAction(false));
    dispatch(dispatch({ type: sagas.biebPagetypeMapping.clear }));
    setActiveBiebStep(0);
    setBusy(false);
  }, [dispatch]);

  const closeDialog = useCallback(() => {
    resetDialog();
    localPageActions?.clear();
  }, [localPageActions, resetDialog]);

  const [busy, setBusy] = useState(false);
  const submitDialog = useCallback(
    (update: boolean) => {
      setBusy(true);
      const [id] = [localPage?.id!];
      if (update) {
        localPageActions!.add(
          "RelationsRestore",
          { itemId: id, sourceItemId: biebPage?.id! },
          true
        );
      } else {
        localPageActions!.clear();
      }

      localPageActions!.add("UpdateBiebRelation", {}, true);
      localPageActions!.save(false, (job: { id: number } | null) => {
        if (job?.id) {
          startPageJobWatcher(dispatch);
        } else {
          dispatch(invalidatePage(biebPage?.id!));
          dispatch(invalidateRelations(biebPage?.id!));
          dispatch(invalidatePage(localPage?.id!));
          dispatch(invalidateRelations(localPage?.id!));
          dispatch(getCurrentPageAction());
        }

        closeDialog();
      });
    },
    [biebPage?.id, closeDialog, dispatch, localPage?.id, localPageActions]
  );

  useEffect(() => {
    if (biebUpdateDialog.open && !biebPage?.id && biebUpdateDialog.biebItemId) {
      dispatch(getPageAction(biebUpdateDialog.biebItemId));
    }
    if (
      biebUpdateDialog.open &&
      !localPage?.id &&
      biebUpdateDialog.localItemId
    ) {
      dispatch(getPageAction(biebUpdateDialog.localItemId));
    }

    // Mapping
    if (biebUpdateDialog.open && biebPage?.id) {
      dispatch({
        type: sagas.biebPagetypeMapping.require,
        payload: { biebItemId: biebPage.id },
      });
    }
  }, [
    biebPage,
    biebUpdateDialog.biebItemId,
    biebUpdateDialog.localItemId,
    biebUpdateDialog.open,
    dispatch,
    localPage,
  ]);

  const [disableAllActions, setDisableAllActions] = useState(false);

  const biebMappingBusy = useMemo(
    () => biebPagetypeMapping?.status.loading ?? false,
    [biebPagetypeMapping?.status.loading]
  );

  const showWizard = useMemo(
    () =>
      localPageActions &&
      biebPage?.id &&
      localPage?.id &&
      biebRelations?.relations &&
      localRelations?.relations &&
      relationTypes?.types &&
      (biebStructures?.length ?? 0) >= 0,
    [
      biebPage?.id,
      biebRelations?.relations,
      biebStructures?.length,
      localPage?.id,
      localPageActions,
      localRelations?.relations,
      relationTypes?.types,
    ]
  );

  if (!biebUpdateDialog.open) {
    return null;
  }

  return (
    <Dialog
      title="Vergelijken en bijwerken"
      open
      autoScrollBodyContent
      contentStyle={{ minWidth: 960, maxWidth: "100vw" }}
      actions={[
        <FlatButton
          key="close"
          label="Sluiten"
          onClick={closeDialog}
          disabled={busy}
        />,
        activeBiebStep <= 2 && (
          <FlatButton
            key="previous"
            label="Vorige stap"
            onClick={() => handleActiveStep(activeBiebStep - 1)}
            disabled={busy || disableAllActions}
          />
        ),
        activeBiebStep === 2 &&
          !dirty &&
          Boolean(biebUpdateDialog.hasUpdate) && (
            <FlatButton
              key="secondary"
              secondary
              label="Negeren"
              onClick={() => submitDialog(false)}
              disabled={busy || disableAllActions}
            />
          ),
        activeBiebStep === 2 && (
          <FlatButton
            key="primary"
            primary
            label="Bijwerken"
            onClick={() => submitDialog(true)}
            disabled={busy || disableAllActions}
          />
        ),
        activeBiebStep < 2 && (
          <FlatButton
            key="next"
            primary
            label="Volgende stap"
            onClick={() => handleActiveStep(activeBiebStep + 1)}
            disabled={busy || disableAllActions}
          />
        ),
      ]}
    >
      {busy || biebMappingBusy || !showWizard ? (
        <PageRefreshIndicator />
      ) : (
        <div>
          <BiebCompareAndSelectWizard
            biebStepper={{
              activeStep: activeBiebStep,
              setActiveStep: handleActiveStep,
            }}
            biebPage={biebPage!}
            localPage={localPage!}
            pageActions={localPageActions!}
            biebRelations={biebRelations!}
            localRelations={localRelations!}
            relationTypes={relationTypes!}
            biebStructures={biebStructures!}
            biebRelatedPagetypeMapping={biebPagetypeMapping!.value!}
            isIznet={isIznet}
            close={closeDialog}
            setDisableAllActions={setDisableAllActions}
            staged={staged}
          />
        </div>
      )}
    </Dialog>
  );
};

export default withRouter(BiebUpdateDialog);
