import { DropDownMenu, FlatButton, IconButton, MenuItem } from "material-ui";
import Save from "material-ui/svg-icons/content/save";

import { LandscapeView, SiteLink } from "../../../business/models";
import CenterFocusWeak from "../../../svg/CenterFocusWeak";
import FullscreenIcon from "../../../svg/Fullscreen";
import StacksIcon from "../../../svg/Stacks";
import { ItemLink } from "../../relation.itemlink";
import ToolMenu, { ToolMenuProps } from "./toolMenu";

export interface ToolbarProps {
  landscapeItem?: SiteLink;

  view?: LandscapeView;

  views?: LandscapeView[];

  onViewSelect(view?: LandscapeView): void;

  onFullscreen(): void;

  saveCurrentView?(): void;
}

interface ToolbarEvents extends ToolMenuProps {
  createCompoundNode(): void;

  fitViewport(): void;

  layout: cytoscape.LayoutOptions;

  onChangeLayout(layout: cytoscape.LayoutOptions): void;
}

const Toolbar = ({
  landscapeItem,
  view,
  views,
  onViewSelect,
  onFullscreen,
  saveCurrentView,
  createCompoundNode,
  fitViewport,
  layout,
  onChangeLayout,
  ...toolMenuProps
}: ToolbarProps & ToolbarEvents) => (
  <div style={{ display: "flex", gap: 2, justifyContent: "space-between" }}>
    <div>
      {landscapeItem && (
        <FlatButton
          style={{ marginTop: "8px", marginRight: "auto" }}
          label={`Toon ${landscapeItem.label}`}
          containerElement={<ItemLink item={landscapeItem} />}
        />
      )}
    </div>
    <div>
      <DropDownMenu
        value={view?.id ?? ""}
        style={{ height: 40, overflow: "hidden" }}
      >
        {!views?.some((v) => v.default) && (
          <MenuItem
            primaryText="Standaard"
            onClick={() => onViewSelect()}
            checked={!view}
            value=""
          />
        )}
        {views?.map((v) => (
          <MenuItem
            key={v.id}
            value={v.id}
            primaryText={v.name}
            onClick={() => onViewSelect(v)}
            checked={view?.id === v.id}
          />
        ))}
      </DropDownMenu>
      <ToolMenu {...toolMenuProps} />
      <IconButton
        title="Opslaan"
        onClick={saveCurrentView}
        disabled={!saveCurrentView}
      >
        <Save />
      </IconButton>
      <IconButton title="Groepering maken" onClick={createCompoundNode}>
        <StacksIcon />
      </IconButton>
      <DropDownMenu
        value={layout.name}
        style={{ height: 40, overflow: "hidden" }}
      >
        <MenuItem
          primaryText="Origineel"
          onClick={() => onChangeLayout({ name: "preset" })}
          checked={layout.name === "preset"}
          value="preset"
          style={{ fontVariant: "italic" }}
        />
        <MenuItem
          primaryText="Breed"
          onClick={() => onChangeLayout({ name: "breadthfirst" })}
          checked={layout.name === "breadthfirst"}
          value="breadthfirst"
        />
        <MenuItem
          primaryText="Concentrisch"
          onClick={() => onChangeLayout({ name: "concentric" })}
          checked={layout.name === "concentric"}
          value="concentric"
        />
        <MenuItem
          primaryText="Grid"
          onClick={() => onChangeLayout({ name: "grid" })}
          checked={layout.name === "grid"}
          value="grid"
        />
      </DropDownMenu>
      <IconButton title="Venster opvullen" onClick={fitViewport}>
        <CenterFocusWeak />
      </IconButton>
      <IconButton title="Volledig scherm" onClick={onFullscreen}>
        <FullscreenIcon />
      </IconButton>
    </div>
  </div>
);

export default Toolbar;
