import Checkbox from "material-ui/Checkbox";
import IconButton from "material-ui/IconButton";
import { List, ListItem } from "material-ui/List";
import Done from "material-ui/svg-icons/action/done";
import Block from "material-ui/svg-icons/content/block";
import HardwareKeyboardArrowDown from "material-ui/svg-icons/hardware/keyboard-arrow-down";
import HardwareKeyboardArrowUp from "material-ui/svg-icons/hardware/keyboard-arrow-up";
import Todo from "material-ui/svg-icons/social/sentiment-dissatisfied";
import Draft from "material-ui/svg-icons/social/sentiment-neutral";
import Ok from "material-ui/svg-icons/social/sentiment-satisfied";
import TextField from "material-ui/TextField";
import React, {
  ChangeEvent,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from "react";

import {
  PageActions,
  RaamwerkAction,
  RaamwerkValue,
  SelectionList,
  SelectionListItem,
} from "../../business/models";
import { getMiniSaarPath } from "../../store";
import { isNonEmptyArray, regexEscape } from "../../utils";
import { PathLink } from "../relation.pathlink";
import { getValue } from "./ggvtyp";
import {
  RaamwerkListItemProps,
  RaamwerkProps,
  RaamwerkStatusButtonsProps,
  RaamwerkStatusCheckboxProps,
  RaamwerkStatusProps,
} from "./raamwerk.models";

export const Disabled = React.memo<{
  item: { alias?: string; value: string };
  small?: boolean;
  style?: object;
}>(({ item: { value }, small = false, style }) => {
  const size = small
    ? { width: 16, height: 16, verticalAlign: "middle" }
    : undefined;

  return (
    <span style={style} title={value}>
      <Block style={size} />
    </span>
  );
});

export const Meta = React.memo<{
  item: { alias?: string; value: string };
  small?: boolean;
  style?: object;
}>(({ item: { alias, value }, small = false, style }) => (
  <span style={style} title={value}>
    {(() => {
      const size = small
        ? { width: 16, height: 16, verticalAlign: "middle" }
        : undefined;
      switch (alias) {
        case "ok":
          return <Ok className="meta_ok" style={size} />;
        case "draft":
          return <Draft className="meta_draft" style={size} />;
        case "todo":
          return <Todo className="meta_todo" style={size} />;
        default:
          return <></>;
      }
    })()}
  </span>
));

export const RaamwerkStatusButtons = React.memo<RaamwerkStatusButtonsProps>(
  ({ item, status, onClick, value, style, statusStyle }) => (
    <div className="raamwerk_status_buttons" style={style}>
      {status.map((s) => (
        <RaamwerkStatus
          style={statusStyle}
          key={s.id}
          item={item}
          meta={{ selectionId: s.id, alias: s.alias, value: s.value }}
          onClick={onClick}
          checked={
            value && value.meta ? value.meta.selectionId === s.id : false
          }
        />
      ))}
    </div>
  )
);

export const RaamwerkStatusCheckbox = React.memo<RaamwerkStatusCheckboxProps>(
  ({ item, meta, checked, onClick, style }) => {
    const handleClick = useCallback(
      (event: React.MouseEvent<{}>, isInputChecked: boolean) => {
        event.stopPropagation();
        onClick(item, isInputChecked);
      },
      [item, onClick]
    );

    return (
      <Checkbox
        style={style}
        value={meta.alias}
        className="raamwerk_status_checkbox"
        onCheck={handleClick}
        checked={checked}
        checkedIcon={
          <div
            className="raamwerk_status_checkbox_icon checked"
            title={meta.value}
          >
            <Meta item={meta} />
            <Done className="done" />
          </div>
        }
        uncheckedIcon={
          <div
            className="raamwerk_status_checkbox_icon unchecked"
            title={meta.value}
          >
            <Meta item={meta} />
          </div>
        }
        iconStyle={{ margin: "0 4px 0 4px" }}
      />
    );
  }
);

const RaamwerkStatus = React.memo<RaamwerkStatusProps>(
  ({ item, meta, checked, onClick, style }) => {
    const handleClick = useCallback(
      (item: SelectionListItem, isInputChecked: boolean) => {
        onClick({
          type: isInputChecked ? "ADD" : "REMOVE",
          payload: {
            item: {
              selectionId: item.id,
              alias: item.alias,
              value: item.value,
            },
            meta,
          },
        });
      },
      [meta, onClick]
    );

    return (
      <RaamwerkStatusCheckbox
        item={item}
        meta={meta}
        checked={checked}
        onClick={handleClick}
        style={style}
      />
    );
  }
);

const RaamwerkListItem = React.memo<RaamwerkListItemProps>(
  ({ item, status, depth, onClick, value, filter }) => {
    return (
      <>
        {filter.test(item.value) && (
          <ListItem
            innerDivStyle={{ padding: "16px 144px 16px 16px" }}
            primaryText={
              <div title={item.value} className="raamwerk_list_item_value">
                {Array(depth).join("\xa0\xa0\xa0")}
                {item.value}
              </div>
            }
            rightIcon={
              <RaamwerkStatusButtons
                item={item}
                value={
                  value && value.items
                    ? value.items.find((i) => i.item.selectionId === item.id)
                    : undefined
                }
                status={status}
                onClick={onClick}
              />
            }
          />
        )}
        {item.items &&
          item.items.map((i) => (
            <RaamwerkListItem
              key={i.id}
              item={i}
              status={status}
              depth={depth + 1}
              onClick={onClick}
              value={value}
              filter={filter}
            />
          ))}
      </>
    );
  }
);

export const convertRaamwerkValue = (
  value: RaamwerkValue
): string | undefined => {
  if (!value || !value.items) {
    return undefined;
  }

  return value.items
    .map((i) => `${i.item.alias || i.item.value}=${i.meta.alias}`)
    .join(";");
};

interface RaamwerkLinkProps {
  fieldDefinition: { name: ReactNode; selectionListId: number };
  fromItemId: number;
}

export const RaamwerkLink = ({
  fieldDefinition,
  fromItemId,
}: RaamwerkLinkProps) =>
  Boolean(fieldDefinition) ? (
    <PathLink
      path={getMiniSaarPath(
        `raamwerk/${fieldDefinition.selectionListId}/${fromItemId}`
      )}
    >
      {fieldDefinition.name}
    </PathLink>
  ) : null;

/**
 * Gegevenstype 'Raamwerk' (274)
 */
const Raamwerk = React.memo<RaamwerkProps>((props) => {
  const {
    selectionList: { items } = {} as SelectionList,
    raamwerkStatus: { items: raamwerkStatusList } = {} as SelectionList,
    pageActions: { edit = false } = {} as PageActions,
    setValue,
  } = props;

  const value =
    (getValue(props) as RaamwerkValue) || ({ items: [] } as RaamwerkValue);
  const [open, setOpen] = useState(false);
  const [keyword, setKeyword] = useState("");
  const toggleItem = useCallback(() => {
    if (open) {
      setKeyword("");
    }
    setOpen(!open);
  }, [open, setKeyword, setOpen]);
  const onClick = useCallback(
    (action: RaamwerkAction) => {
      if (action.type === "ADD") {
        const newValue = {
          items: [
            ...value.items.filter(
              (i) => !(i.item.selectionId === action.payload.item.selectionId)
            ),
            action.payload,
          ],
        } as RaamwerkValue;

        setValue({ value: newValue, string: convertRaamwerkValue(newValue) });
      } else if (action.type === "REMOVE") {
        const newValue = {
          items: value.items.filter(
            (i) =>
              !(
                i.item.selectionId === action.payload.item.selectionId &&
                i.meta.selectionId === action.payload.meta.selectionId
              )
          ),
        } as RaamwerkValue;

        setValue({ value: newValue, string: convertRaamwerkValue(newValue) });
      }
    },
    [setValue, value.items]
  );
  const filter = useMemo(
    () => new RegExp(regexEscape(keyword) || ".", "i"),
    [keyword]
  );
  const onKeywordChange = useCallback(
    (e: ChangeEvent) => setKeyword((e.target as HTMLInputElement).value),
    [setKeyword]
  );

  const display = isNonEmptyArray(value.items) ? (
    <div className="raamwerk">
      {value.items.map(({ item, meta }) => (
        <div className="raamwerk-item" key={item.selectionId}>
          <Meta item={meta} small />{" "}
          {item.alias?.includes(">") ? item.alias : item.value}
        </div>
      ))}
    </div>
  ) : null;

  if (edit && isNonEmptyArray(items)) {
    return (
      <>
        {!open && (
          <div className="raamwerk_toggle" onClick={toggleItem}>
            <span className="raamwerk_open">
              <IconButton onClick={toggleItem}>
                <HardwareKeyboardArrowDown />
              </IconButton>
            </span>
            {display}
          </div>
        )}
        {open && (
          <div className="raamwerk_tools">
            <TextField
              className="raamwerk_filter"
              floatingLabelText="Filter onderdelen..."
              value={keyword}
              onChange={onKeywordChange}
            />
            <span className="raamwerk_close">
              <IconButton onClick={toggleItem}>
                <HardwareKeyboardArrowUp />
              </IconButton>
            </span>
          </div>
        )}
        {open && (
          <List className="raamwerk_list">
            {items
              .filter(({ disabled }) => !disabled)
              .map((i) => (
                <RaamwerkListItem
                  key={i.id}
                  item={i}
                  status={raamwerkStatusList}
                  depth={1}
                  onClick={onClick}
                  value={value}
                  filter={filter}
                />
              ))}
          </List>
        )}
      </>
    );
  }

  return display;
});

export default Raamwerk;
