const icons = {
  iconLink: {
    display: "flex",
    height: "24px",
    lineHeight: "24px",
  },
  iconSvg: {
    width: "24px",
  },
  iconTitle: {
    display: "block",
    marginLeft: ".5em",
  },
};
export default icons;
