import empty from "empty";
import {
  filter,
  findLastIndex,
  get,
  isEmpty,
  isEqual,
  map,
  reject,
  some,
} from "lodash/fp";

import { flown } from "../lodash";

export const emptyPageForm = Object.freeze({
  busy: false,
  dirty: false,
  edit: false,
  edited: false,
  version: 0,
  staged: Object.freeze(empty.array),
});

const pickTemplate = (state, id, name) => {
  const { [id]: current = empty.object } = state;
  const { staged = empty.array } = current;
  const setChoiceName = (action) => {
    const { $type: type, choiceName } = action;
    return /ClusterAddAction/.test(type) && choiceName === undefined
      ? { ...action, choiceName: name }
      : action;
  };
  return {
    ...state,
    [id]: {
      ...current,
      staged: map(setChoiceName)(staged),
    },
  };
};

const stashPage = (page) => {
  const { staged = empty.array } = page;
  let clusterId = 0;
  const stash = (action) => {
    if (/ClusterAddAction/.test(action.$type)) {
      clusterId -= 1;
      if (action.ready !== true) {
        return { ...action, ready: true };
      }
    }
    if (
      /FieldAddAction|ClusterReplaceRelationsAction/.test(action.$type) &&
      action.clusterId === 0 &&
      clusterId !== 0
    ) {
      return { ...action, clusterId };
    }
    return action;
  };
  return {
    ...page,
    staged: map(stash)(staged),
  };
};

const unstashPage = (page) => {
  const { staged = empty.array } = page;
  let keep = true;
  const unstash = (action) => {
    if (/ClusterAddAction/.test(action.$type) && action.ready !== true) {
      keep = false;
    }

    return keep;
  };
  return {
    ...page,
    staged: filter(unstash)(staged),
  };
};

const page = (
  state = empty.object,
  { type, payload: { id, ...payload } = empty.object }
) => {
  switch (type) {
    case "CLUSTERDEFINITION_PICK": {
      const { name } = payload;
      return pickTemplate(state, id, name);
    }
    default:
      break;
  }

  if (type.indexOf("FORM_PAGE_") !== 0) {
    return state;
  }

  switch (type.substring(10)) {
    case "REVERT": {
      const { [id]: page = emptyPageForm } = state;
      return {
        ...state,
        [id]: {
          ...page,
          staged: empty.array,
          dirty: false,
          edited: false,
          edit: false,
          version: page.version + 1,
        },
      };
    }
    case "STASH": {
      const { [id]: page = emptyPageForm } = state;
      return {
        ...state,
        [id]: stashPage(page),
      };
    }
    case "UNSTASH": {
      const { [id]: page = emptyPageForm } = state;
      return {
        ...state,
        [id]: unstashPage(page),
      };
    }
    case "STAGE": {
      const { [id]: page = emptyPageForm } = state;
      const { revert, ...rest } = payload;
      const staged = !isEmpty(revert)
        ? reject(revert)(page.staged)
        : page.staged;
      return {
        ...state,
        [id]: {
          ...page,
          dirty: true,
          edited: true,
          staged: [...staged, { ...rest }],
        },
      };
    }
    case "UNSTAGE": {
      const { [id]: page = emptyPageForm } = state;
      const staged = reject(payload)(page.staged);
      return { ...state, [id]: { ...page, dirty: staged.length > 0, staged } };
    }
    case "CLEAR":
      return id
        ? { ...state, [id]: { ...emptyPageForm, ...payload } }
        : empty.object;
    case "SET": {
      const { [id]: page = emptyPageForm } = state;
      return { ...state, [id]: { ...page, ...payload } };
    }
    case "SELECT": {
      const { relation } = payload;
      const { [id]: page = emptyPageForm } = state;
      const { staged } = page;
      const index = flown(
        staged,
        findLastIndex(({ $type: type }) => /ClusterAddAction/.test(type))
      );
      const clusterAdd = staged[index];
      if (flown(clusterAdd, get("relations"), some(isEqual(relation)))) {
        return state;
      }

      const relations = clusterAdd.relations
        ? [...clusterAdd.relations, relation]
        : [relation];
      return {
        ...state,
        [id]: {
          ...page,
          staged: [
            ...staged.slice(0, index),
            { ...clusterAdd, relations },
            ...staged.slice(index + 1),
          ],
        },
      };
    }
    case "UNSELECT": {
      const { relation } = payload;
      const { [id]: page = emptyPageForm } = state;
      const { staged } = page;
      const index = flown(
        staged,
        findLastIndex(({ $type: type }) => /ClusterAddAction/.test(type))
      );
      const clusterAdd = staged[index];
      if (!flown(clusterAdd, get("relations"), some(isEqual(relation)))) {
        return state;
      }

      const relations = flown(clusterAdd.relations, reject(isEqual(relation)));
      return {
        ...state,
        [id]: {
          ...page,
          staged: [
            ...staged.slice(0, index),
            { ...clusterAdd, relations },
            ...staged.slice(index + 1),
          ],
        },
      };
    }
    default:
      return state;
  }
};

export default page;
