import { loadingPresent } from "async-lifecycle";
import empty from "empty";
import {
  filter,
  find,
  findLast,
  flatten,
  flow,
  get,
  has,
  map,
  reject,
  some,
} from "lodash/fp";
import {
  branch,
  compose,
  mapProps,
  renderNothing,
  setDisplayName,
} from "recompose";

import { requireEntiteiten } from "../../../actions/application";
import { RelationTypeGroups } from "../../../actions/page";
import { requireVerantwoordingen } from "../../../actions/verantwoordingen";
import KernGegevenComponent from "../../../components/pagetypes/application/kerngegeven";
import { flown } from "../../../lodash";
import { execOnChange } from "../../../recompose.contrib";
import { dispatchWrap } from "../../../utils";
import connect from "../../connect";

const mapGegevens = ({
  pageActions,
  clusterId,
  siteId,
  entiteiten: { loading, entiteiten } = empty.object,
  bieb,
  relations,
  staged,
  types,
}) => {
  if (!loadingPresent(loading)) {
    return empty.object;
  }

  const entiteitType =
    find({
      alias: "entiteit_applicatiegegeven",
    })(types) || empty.object;
  const { id: entiteitTypeId } = entiteitType;
  const gegevenType =
    find({
      alias: "kerngegeven_applicatiegegeven",
    })(types) || empty.object;
  const { id: gegevenTypeId } = gegevenType;
  const label = `${entiteitType.right.label}/${gegevenType.right.label}`;

  if (!entiteitTypeId || !gegevenTypeId) {
    return empty.object;
  }

  const entiteitTypes = [entiteitType, gegevenType];

  const kerngegevenRelations = flow(
    filter(
      ({ alias }) =>
        alias === "entiteit_applicatiegegeven" ||
        alias === "kerngegeven_applicatiegegeven"
    ),
    map("links"),
    flatten,
    filter({ pageClusterId: clusterId })
  )(relations);

  const list = (bieb ? filter({ bieb: siteId }) : reject(has("bieb")))(
    entiteiten
  );

  const selected =
    staged?.map((link) => ({
      itemId: link.vanItmIdt,
      pageClusterId: link.vanPagClsIdt,
    })) ?? kerngegevenRelations.map(get("item"));

  return {
    label,
    name: `kerngegeven_${clusterId}`,
    entiteiten: list,
    edit: pageActions.edit,
    selected,
    onChange: (links) => {
      const relations = flown(
        links,
        map((link) => ({
          itmRelTypIdt: find({ alias: link.type })(entiteitTypes).id,
          vanItmIdt: link.itemId,
          vanPagClsIdt: link.pageClusterId,
        }))
      );
      pageActions.clusterSetRelations(
        {
          clusterId,
          relations,
          relationTypeGroup: RelationTypeGroups.entiteitApplicatieGegeven,
        },
        false // TODO: check whether links differs from stored
      );
    },
  };
};

const KernGegeven = compose(
  setDisplayName("KernGegeven"),
  connect(
    (
      {
        data: {
          pages,
          entiteiten,
          addLegoblok: { structures = empty.array } = empty.object,
          relationTypes: { types = empty.array },
        },
        form: { page },
      },
      { clusterId, pageActions: { pageId } = empty.object }
    ) => {
      const { siteId } = pages[pageId];
      const bieb = flown(
        structures,
        some(({ id }) => id === siteId)
      );

      const stagedAction = flown(
        page,
        get(`${pageId}.staged`),
        findLast(
          ({ $type: type, clusterId: stagedClusterId, relationTypeGroup }) =>
            clusterId === stagedClusterId &&
            relationTypeGroup ===
              RelationTypeGroups.entiteitApplicatieGegeven &&
            /ClusterReplaceRelations/.test(type)
        )
      );

      return {
        page,
        siteId,
        bieb,
        entiteiten,
        types,
        staged: stagedAction?.relations,
      };
    },
    (dispatch) => ({
      requireEntiteiten: dispatchWrap(requireEntiteiten, dispatch),
      requireVerantwoordingen: dispatchWrap(requireVerantwoordingen, dispatch),
      dispatch,
    })
  ),
  execOnChange("requireEntiteiten"),
  execOnChange("requireVerantwoordingen"),
  mapProps(mapGegevens),
  branch(({ edit, selected }) => !edit && !selected, renderNothing)
)(KernGegevenComponent);

export default KernGegeven;
