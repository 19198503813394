import RaisedButton from "material-ui/RaisedButton";
import { compose, mapProps } from "recompose";

import { insertAfter, withoutProps } from "../../recompose.contrib";
import SelectVerantwoording from "./selectVerantwoording";

const asAddButton = mapProps(({ onAdd }) => ({
  secondary: true,
  label: "Nieuwe verantwoording toevoegen",
  onClick: () => onAdd(),
  style: { marginTop: "20px" },
}));

const VerantwoordingStep = compose(
  insertAfter(asAddButton(RaisedButton)),
  withoutProps("onAdd")
)(SelectVerantwoording);

export default VerantwoordingStep;
