import { branchPresent } from "async-lifecycle/hoc";
import { intoVolatile } from "async-lifecycle/volatile";
import empty from "empty";
import { get } from "lodash/fp";
import { compose, renderComponent, setDisplayName } from "recompose";

import {
  getApplicationPageAction,
  koppelingFields,
} from "../../../actions/data";
import Empty from "../../../components/pagetypes/empty";
import { execOnChange } from "../../../recompose.contrib";
import { applicationsSelector } from "../../../selectors/koppeling";
import connect from "../../connect";
import enhanceLandscape from "../../enhanceLandscape";

export const enhanceKoppeling = compose(
  setDisplayName("Koppeling"),
  connect(
    ({ data: { koppelingFields } }, { id: itemId }) => ({
      koppelingFields: intoVolatile()(koppelingFields[itemId]),
    }),
    (dispatch, { id: itemId }) => ({
      updateFields: () => dispatch(koppelingFields(itemId)),
      updateApplication: ({
        koppelingFields: {
          from: { id: vanItmIdt } = empty.object,
          to: { id: narItmIdt } = empty.object,
        } = empty.object,
      }) => {
        if (vanItmIdt) {
          dispatch(getApplicationPageAction(vanItmIdt));
        }

        if (narItmIdt) {
          dispatch(getApplicationPageAction(narItmIdt));
        }
      },
    })
  ),
  execOnChange("updateFields", "id"),
  branchPresent("koppelingFields", renderComponent(Empty)),
  execOnChange(
    "updateApplication",
    ({ koppelingFields: curr }, { koppelingFields: next }) =>
      get("from.id")(curr) !== get("from.id")(next) ||
      get("to.id")(curr) !== get("to.id")(next)
  ),
  connect((state, props) => ({
    applications: applicationsSelector(state, props),
  })),
  enhanceLandscape
);
