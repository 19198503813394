import empty from "empty";
import { entries, map } from "lodash/fp";
import { Step, StepButton, StepContent } from "material-ui/Stepper";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { useCurrent } from "../../actions/relation";
import { patchNameLinksRechts } from "../../business/definitions";
import { metaFieldDefinitionsShape } from "../../business/prop-types";
import { Side } from "../../business/relations";
import { flown } from "../../lodash";
import Field from "../content/field";
import ItemsPicker from "./itemsPicker";

export const MetaDataStepContent = ({
  metaFieldAdd,
  metaFieldDefinitions,
  metaFieldRevert,
  links,
  rechts,
}) => {
  const metaDataPagetypeActions = Object.freeze({
    edit: true,
    fieldRevert: metaFieldRevert,
    fieldAdd: metaFieldAdd,
  });

  return flown(
    metaFieldDefinitions,
    entries,
    map(([key, field]) => (
      <Field
        key={key}
        alias={key}
        field={field}
        name={patchNameLinksRechts(field.definition.name, links, rechts)}
        clusterId={0}
        pageActions={metaDataPagetypeActions}
        pagetypes={empty.array}
      />
    ))
  );
};
MetaDataStepContent.propTypes = {
  metaFieldAdd: PropTypes.func.isRequired,
  metaFieldDefinitions: metaFieldDefinitionsShape.isRequired,
  metaFieldRevert: PropTypes.func.isRequired,
  links: PropTypes.string.isRequired,
  rechts: PropTypes.string.isRequired,
};

const MetaDataSteps = ({
  types: { peertype, peerLabel } = empty.object,
  peer,
  using,
  form,
  handleNextStep,
  setStepIndex,
  toggleStatus,
  toggleFilter,
  metaFieldAdd,
  metaFieldDefinitions,
  metaFieldRevert,
}) => {
  const formPeer = form.peer?.peers?.[0];
  const stepOneItem = formPeer
    ? peer.items.find(({ itemId }) => itemId === formPeer)
    : undefined;
  const { itemId, side } = useCurrent();
  const myTitle = useSelector((state) => state.data.pages?.[itemId]?.title);
  const otherTitle = stepOneItem?.label ?? formPeer;
  const links = side === Side.left ? myTitle : otherTitle;
  const rechts = side === Side.right ? myTitle : otherTitle;

  return [
    <Step key={0}>
      <StepButton onClick={() => setStepIndex(0)}>
        <span>{peerLabel}</span>
        {stepOneItem && <span>: {stepOneItem.label}</span>}
        {!stepOneItem && formPeer && <span>: {formPeer} (Nieuw)</span>}
      </StepButton>
      <StepContent>
        <ItemsPicker
          {...peer}
          multiple={false}
          toggleStatus={toggleStatus}
          toggleFilter={using.specificItemId ? toggleFilter : undefined}
          onSelect={handleNextStep}
          pagetype={peertype}
        />
      </StepContent>
    </Step>,
    <Step key={1}>
      <StepButton onClick={() => setStepIndex(1)}>
        <span>
          Meta gegevens bij &quot;
          {peerLabel}
          &quot;
        </span>
      </StepButton>
      <StepContent>
        <MetaDataStepContent
          metaFieldAdd={metaFieldAdd}
          metaFieldDefinitions={metaFieldDefinitions}
          metaFieldRevert={metaFieldRevert}
          links={links}
          rechts={rechts}
        />
      </StepContent>
    </Step>,
  ];
};
MetaDataSteps.propTypes = Object.freeze({
  // eslint-disable-next-line react/forbid-prop-types
  types: PropTypes.any,
  // eslint-disable-next-line react/forbid-prop-types
  peer: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  using: PropTypes.shape({ specificItemId: PropTypes.number }),
  // eslint-disable-next-line react/forbid-prop-types
  form: PropTypes.any,
  // eslint-disable-next-line react/forbid-prop-types
  handleNextStep: PropTypes.any,
  // eslint-disable-next-line react/forbid-prop-types
  setStepIndex: PropTypes.any,
  toggleStatus: PropTypes.bool.isRequired,
  toggleFilter: PropTypes.func.isRequired,
  metaFieldAdd: PropTypes.func.isRequired,
  metaFieldDefinitions: metaFieldDefinitionsShape.isRequired,
  metaFieldRevert: PropTypes.func.isRequired,
});

export default MetaDataSteps;
