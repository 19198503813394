/* eslint  react/prop-types: off */
import empty from "empty";
import { get, keys, map } from "lodash/fp";
import { CardText, CardTitle } from "material-ui/Card";
import React from "react";

import { patchNameLinksRechts } from "../../../business/definitions";
import { flown } from "../../../lodash";
import Field, { LabelValue } from "../../content/field";
import ClusterCard from "../../material/clusterCard";
import { ItemLink } from "../../relation.itemlink";

const toObject = (a) => {
  if (!Array.isArray(a)) {
    return a;
  }

  const result = {};

  a.forEach(({ name, value }) => {
    result[name] = toObject(value);
  });

  return result;
};

const LandscapePopup = ({
  popup: { grouped = empty.object } = empty.object,
  rectangles = empty.array,
  pages = empty.object,
}) =>
  grouped && Object.keys(grouped).length > 0 ? (
    <div className="landscape-popup">
      {flown(
        grouped,
        keys,
        map((dir) => {
          const [labelVan, labelNar] = dir
            .split(":")
            .map((id) => rectangles[id].text);
          return grouped[dir].map(({ siteLink = empty.object }) => {
            const { itemId, label } = siteLink;
            const {
              page: {
                inhoud: {
                  omschrijving: { typeKoppeling } = empty.object,
                  gegeven: { clusters = empty.array } = empty.object,
                } = empty.object,
              } = empty.object,
            } = pages[itemId] || empty.object;
            const gegevens = clusters.map(
              (gegeven) =>
                get("gegeven.naam.value")(gegeven) || get("naam.value")(gegeven)
            );
            const metaFields = grouped[dir]
              .flatMap(({ meta }) =>
                Array.isArray(meta) ? [toObject(meta)] : empty.array
              )
              .flatMap(({ page = empty.object }) =>
                Object.values(page).filter(
                  (f) => typeof f === "object" && f?.iproxType === "field"
                )
              );

            return (
              <ClusterCard key={itemId}>
                <CardTitle
                  className="card-title"
                  title={<ItemLink item={siteLink}>{label}</ItemLink>}
                />
                <CardText>
                  <LabelValue label="Route">
                    Van {labelVan} naar {labelNar}
                  </LabelValue>
                  {typeKoppeling && <Field field={typeKoppeling} />}
                  {metaFields.map((field) => {
                    const label = patchNameLinksRechts(
                      field.definition.name,
                      labelVan,
                      labelNar
                    );
                    return <Field key={field.id} field={field} name={label} />;
                  })}
                  {gegevens.length > 0 && (
                    <LabelValue label="Gegevens">
                      {gegevens.join(", ")}
                    </LabelValue>
                  )}
                </CardText>
              </ClusterCard>
            );
          });
        })
      )}
    </div>
  ) : null;

export default LandscapePopup;
