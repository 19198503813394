import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import "./styles/main.scss";

import React from "react";
import { createRoot } from "react-dom/client";

import App from "./app";
import boot from "./boot";

// Render all components/routes
boot();

const container = document.getElementById("app");
const root = createRoot(container);
root.render(<App />);
