/* eslint  react/prop-types: off */
import empty from "empty";
import Card from "material-ui/Card";
import { List, ListItem } from "material-ui/List";
import RaisedButton from "material-ui/RaisedButton";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { compose, setDisplayName, setPropTypes, withHandlers } from "recompose";

import { doUpdateFromBiebDialogAction } from "../../actions/bieb";
import { pagetypeViewName } from "../../pagetypes";
import PagetypeIcon from "../pagetypeIcon";
import { ItemLink } from "../relation.itemlink";
import Subheader from "./Subheader";
import UpdateItemButton from "./UpdateItemButton";
import UpdateRelatedButton from "./UpdateRelatedButton";

const buttonWrapperListStyle = { padding: 0 };
const buttonWrapperListItemStyle = {
  paddingTop: 0,
  display: "flex",
  justifyContent: "center",
};

const ButtonWrapper = ({ children }) => (
  <List className="button-wrapper" style={buttonWrapperListStyle}>
    <ListItem
      disabled
      className="button-wrapper-item"
      style={buttonWrapperListItemStyle}
    >
      {children}
    </ListItem>
  </List>
);

const ButtonBar = ({
  handleClick = empty.func,
  pagetype = "legoblok",
  biebRelations,
  lastPublished,
  isBiebItem,
  fromBiebRelation,
}) => {
  const dispatch = useDispatch();
  const handleUpdateClick = useCallback(
    (biebItemId, localItemId, hasUpdate) => {
      dispatch(
        doUpdateFromBiebDialogAction(true, {
          biebItemId,
          localItemId,
          hasUpdate,
        })
      );
    },
    [dispatch]
  );
  const biebRelationsForCurrentSaar = useMemo(
    () => biebRelations?.filter((r) => !r.right.variantId) ?? empty.array,
    [biebRelations]
  );
  const miniSaarName = useSelector((store) => {
    const siteId = store.session?.miniSaar?.siteId;
    return (
      store.data.miniSaars.data?.find((s) => s.siteId === siteId)?.name ??
      "Saar"
    );
  });

  const alreadyUsed = biebRelationsForCurrentSaar.length > 0;

  if (fromBiebRelation) {
    return (
      <ButtonWrapper>
        <UpdateItemButton
          onClick={handleUpdateClick}
          biebRelation={fromBiebRelation}
        />
      </ButtonWrapper>
    );
  }

  if (!isBiebItem) {
    return false;
  }

  if (!alreadyUsed) {
    return (
      <ButtonWrapper>
        <RaisedButton
          label={`${pagetypeViewName(pagetype)} overnemen`}
          onClick={handleClick}
          secondary
        />
      </ButtonWrapper>
    );
  }

  return (
    <Card style={{ marginBottom: "16px" }}>
      <Subheader bieb>Overgenomen in {miniSaarName}</Subheader>
      <List className="relations">
        {biebRelationsForCurrentSaar.map((relation) => (
          <ListItem
            key={relation.id}
            primaryText={<ItemLink className="primary" item={relation.right} />}
            leftIcon={<PagetypeIcon type={pagetype} />}
            rightIconButton={
              <span
                style={{
                  display: "inline-block",
                  marginTop: 6,
                  marginRight: 16,
                }}
              >
                <UpdateRelatedButton
                  onClick={handleUpdateClick}
                  biebRelation={relation}
                  lastPublished={moment(lastPublished)}
                />
              </span>
            }
          />
        ))}
        <ListItem
          primaryText={
            <RaisedButton
              label={`${pagetypeViewName(pagetype)} nogmaals overnemen`}
              onClick={handleClick}
            />
          }
        />
      </List>
    </Card>
  );
};

export default compose(
  setDisplayName("ButtonBar"),
  setPropTypes({
    openDialog: PropTypes.func.isRequired,
    pagetype: PropTypes.string,
  }),
  withHandlers({
    handleClick:
      ({ openDialog }) =>
      () =>
        openDialog(true),
  })
)(ButtonBar);
