import empty from "empty";
import { debounce } from "lodash/fp";
import { compose, setDisplayName } from "recompose";

import {
  clearAutoCompleteAction,
  updateAutoCompleteAction,
  updateQueryAction,
  updateScopeAction,
} from "../actions/search";
import AutoComplete from "../components/search/autoComplete";
import { statusListSelector } from "../selectors/definition";
import { autoCompleteWithMiniSaarSelector } from "../selectors/list";
import connect from "./connect";

const mapState = (state, props) => {
  const {
    form: {
      searchQuery: { search = empty.object, ui = empty.object } = empty.object,
    } = empty.object,
  } = state;
  return {
    data: autoCompleteWithMiniSaarSelector(state, props),
    searchQuery: search,
    searchUi: ui,
    statusList: statusListSelector(state, props),
  };
};

const mapDispatch = (dispatch) => {
  const lazyAutoComplete = debounce(500)((query) => {
    dispatch(updateQueryAction("search", "trefwoord", query.trefwoord));
    dispatch(updateAutoCompleteAction());
  });

  return {
    lazyAutoComplete,

    updateUi: (key, value) => {
      dispatch(updateQueryAction("ui", key, value));
    },

    updateScope: (scope) => dispatch(updateScopeAction(scope)),

    updateStatus: (statusIds = empty.array) => {
      dispatch(updateQueryAction("search", "statusIds", statusIds));
      dispatch(clearAutoCompleteAction());
      dispatch(updateAutoCompleteAction());
    },

    updateSearchInAllSites: (searchInAllSites) => {
      dispatch(
        updateQueryAction("search", "searchInAllSites", searchInAllSites)
      );
      dispatch(updateAutoCompleteAction());
    },

    reset: () => {
      dispatch(updateQueryAction("search", "trefwoord", ""));
      dispatch(clearAutoCompleteAction());
    },
  };
};

export default compose(
  setDisplayName("AutoComplete"),
  connect(mapState, mapDispatch)
)(AutoComplete);
