import { loadingProgress } from "async-lifecycle";
import empty from "empty";
import {
  compact,
  defaultTo,
  filter,
  find,
  flatten,
  flow,
  get,
  groupBy,
  identity,
  includes,
  isEqual,
  map,
  nthArg,
  pick,
  sortBy,
  uniqBy,
} from "lodash/fp";
import {
  createSelector,
  createStructuredSelector,
  defaultMemoize,
} from "reselect";

import { Side } from "../business/relations";
import { flown, setup } from "../lodash";
import { biebStructures } from "./page";
import {
  legoblokStructuresSelector,
  miniSaarSelector,
  sessionSelector,
} from ".";

const selectionListsSelector = ({
  data: { selectionLists: { data } = empty.object } = empty.object,
}) => data || empty.array;

const pagetypeListSelector = ({
  data: { pagetypeList: { data } = empty.object } = empty.object,
}) => data || empty.array;

const fieldsSelector = createSelector(
  [
    flow(get("data.fieldDefinitions.definitions"), defaultTo(empty.array)),
    flow(nthArg(1), get("form.pagetype")),
  ],
  (definitions, pagetype) =>
    flown(
      definitions,
      filter(flow(get("pagetypes"), includes(pagetype))),
      map((field) => ({
        ...field,
        label: field.name,
        value: { field: pick(["id"])(field) },
      })),
      sortBy([get("clusterId")])
    )
);

const clusterSelector = createSelector(
  [
    flow(get("data.fieldDefinitions.definitions"), defaultTo(empty.array)),
    flow(nthArg(1), get("form.pagetype")),
  ],
  (definitions, pagetype) =>
    flown(
      definitions,
      filter(flow(get("pagetypes"), includes(pagetype))),
      map(({ cluster }) => ({
        ...cluster,
        label: cluster.name,
        value: { cluster: pick(["clusterId"])(cluster) },
      })),
      uniqBy("clusterId"),
      sortBy([get("clusterId")])
    )
);

const fieldsByClusterSelector = createSelector(
  [fieldsSelector, clusterSelector],
  (fields, clusters) => {
    const fieldsHash = groupBy("clusterId")(fields);
    return map((cluster) => ({
      ...cluster,
      list: fieldsHash[cluster.clusterId],
    }))(clusters);
  }
);

const relationsSelector = createSelector(
  [
    flow(get("data.relationTypes.types"), defaultTo(empty.array)),
    flow(nthArg(1), get("form.pagetype")),
  ],
  (types, pagetype) =>
    flown(
      types,
      map(({ id, left, right, using = empty.object }) => [
        { ...left, id, side: Side.left },
        { ...right, id, side: Side.right },
        { ...using, id, side: Side.using },
      ]),
      flatten,
      filter({ pagetype }),
      map((relation) => ({
        ...relation,
        value: { relation: pick(["id", "side"])(relation) },
      }))
    )
);

const exportLoadingSelector = createSelector(
  [flow(get("form.exportOverzicht"), defaultTo(empty.object))],
  (exportObj) => loadingProgress(exportObj.loading)
);

const findValue = (source) => {
  const values = map("value")(source);
  return (value) => flown(values, find(isEqual(value)));
};

const bareFormSelector = defaultMemoize(
  flow(get("form.overzicht"), setup("form"))
);

export const formSelector = createSelector(
  [identity, bareFormSelector],
  (state, props) => {
    const fields = fieldsSelector(state, props);
    const relations = relationsSelector(state, props);
    const { form } = props;
    return {
      form: {
        ...form,
        fields: flown(form.fields, map(findValue(fields)), compact),
        relations: flown(form.relations, map(findValue(relations)), compact),
      },
    };
  }
);

export const overzichtSelector = createStructuredSelector({
  biebStructures,
  fields: fieldsByClusterSelector,
  relations: relationsSelector,
  selectionLists: selectionListsSelector,
  legoblokStructures: legoblokStructuresSelector,
  saar: miniSaarSelector,
  selected: ({
    form: {
      biebSelect: { selected },
    },
  }) => selected,
  session: sessionSelector,
  pagetypeList: pagetypeListSelector,
  exportLoading: exportLoadingSelector,
});
