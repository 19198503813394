import SvgIcon from "material-ui/SvgIcon";
import React from "react";
import pure from "recompose/pure";

const Word = (props) => (
  <SvgIcon {...props} width="32" height="32" viewBox="0 0 32 32">
    <g>
      <path
        d="M30.66665,2H9.33327A1.33332,1.33332,0,0,0,8,3.33331V9l12,3.5L32,9V3.33331A1.33333,1.33333,0,0,0,30.66665,2Z"
        fill="#41a5ee"
      />
      <polygon points="32 9 8 9 8 16 20 19.5 32 16 32 9" fill="#2b7cd3" />
      <polygon points="32 16 8 16 8 23 20 26.5 32 23 32 16" fill="#185abd" />
      <path
        d="M32,23H8v5.66666A1.33334,1.33334,0,0,0,9.33334,30H30.6667A1.33334,1.33334,0,0,0,32,28.66666Z"
        fill="#103f91"
      />
      <path
        d="M16.66665,7H8V26h8.66663A1.33732,1.33732,0,0,0,18,24.66663V8.33331A1.33727,1.33727,0,0,0,16.66665,7Z"
        opacity="0.1"
      />
      <path
        d="M15.66665,8H8V27h7.66663A1.33732,1.33732,0,0,0,17,25.66663V9.33331A1.33727,1.33727,0,0,0,15.66665,8Z"
        opacity="0.2"
      />
      <path
        d="M15.66665,8H8V25h7.66663A1.33732,1.33732,0,0,0,17,23.66663V9.33331A1.33727,1.33727,0,0,0,15.66665,8Z"
        opacity="0.2"
      />
      <path
        d="M14.66665,8H8V25h6.66663A1.33732,1.33732,0,0,0,16,23.66663V9.33331A1.33727,1.33727,0,0,0,14.66665,8Z"
        opacity="0.2"
      />
      <path
        d="M1.33329,8H14.66666A1.33333,1.33333,0,0,1,16,9.33333V22.66666A1.33334,1.33334,0,0,1,14.66665,24H1.3333A1.33334,1.33334,0,0,1,0,22.66666V9.33333A1.33333,1.33333,0,0,1,1.33329,8Z"
        fill="#185abd"
      />
      <path
        id="Letter"
        d="M11.95,21h-1.8l-2.1-6.9L5.85,21H4.05l-2-10h1.8l1.4,7,2.1-6.8h1.5l2,6.8,1.4-7h1.7Z"
        fill="#fff"
      />
      <rect x="-0.00004" width="32" height="32" fill="none" />
    </g>
  </SvgIcon>
);

export default pure(Word);
