import { asyncReducer } from "async-lifecycle";
import empty from "empty";
import { combineReducers } from "redux";

import { mapValues, structuredMap } from "../utils";

const parseDateTimes = mapValues(
  structuredMap({
    dateTime: (value) => (typeof value === "string" ? new Date(value) : value),
  })
);

/**
 * GUI reducer
 * @return Redux reducer
 */
const gui = (
  state = empty.object,
  {
    type,
    payload: patch = empty.object,
    payload: { data: { gui: full } = empty.object } = empty.object,
  }
) => {
  switch (type) {
    case "CHECKLOGIN_ERROR":
    case "CHECKLOGIN_EXIT":
    case "CHECKLOGIN_INIT":
    case "LOGIN_ERROR":
    case "LOGIN_EXIT":
    case "LOGIN_INIT":
    case "CHECKLOGIN_SUCCESS":
    case "LOGIN_SUCCESS":
      return full ? parseDateTimes(full) : state;
    case "GUI_PATCH":
      return { ...state, ...patch };
    case "LOGIN_INVALIDATE":
    case "LOGOUT":
      return empty.object;
    default:
      return state;
  }
};

/**
 * User-reducer
 * @return Redux reducer
 */
const user = (state = empty.object, { type, payload = empty.object }) => {
  switch (type) {
    case "CHECKLOGIN_ERROR":
    case "CHECKLOGIN_EXIT":
    case "CHECKLOGIN_INIT":
    case "LOGIN_ERROR":
    case "LOGIN_EXIT":
    case "LOGIN_INIT":
      return { ...state, checked: state.checked, loading: payload.loading };
    case "CHECKLOGIN_SUCCESS":
    case "LOGIN_SUCCESS":
      return { ...state, checked: true, ...payload.data.user };
    case "LOGOUT":
      return { checked: state.checked };
    case "LOGIN_INVALIDATE":
      return empty.object;
    default:
      return state;
  }
};

const tfa = (
  state = empty.object,
  {
    type,
    payload: {
      data: {
        possession,
        possessionInvalid: invalid = false,
        user: { username, id } = empty.object,
      } = empty.object,
    } = empty.object,
  }
) => {
  switch (type) {
    case "CHECKLOGIN_SUCCESS":
    case "LOGIN_SUCCESS":
      return (
        possession || (username && !id ? { ...state, invalid } : empty.object)
      );
    case "LOGIN_INVALIDATE":
    case "LOGOUT":
      return empty.object;
    default:
      return state;
  }
};

export default combineReducers({
  gui,
  miniSaar: asyncReducer("MINISAAR"),
  tfa,
  user,
  request: asyncReducer("REQUEST_PASSWORD"),
  office365: asyncReducer("OFFICEURL"),
  officeInfo: asyncReducer("OFFICEINFO"),
  azureAd: asyncReducer("AZUREADURL"),
  azureAdInfo: asyncReducer("AZUREADINFO"),
});
