/* eslint  react/prop-types: off */
import { has } from "lodash/fp";
import FlatButton from "material-ui/FlatButton";
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import {
  branch,
  compose,
  renderNothing,
  setDisplayName,
  setPropTypes,
} from "recompose";

import Dialog from "../../containers/dialog";

const BiebPreviewDialog = ({ close, selectItem, Page, ...props }) => {
  const pagetype = has("page.page.pagetype")(props);
  const hasPage = !!pagetype;
  return (
    <Dialog
      title="Preview pagina uit Saar Bieb"
      open
      repositionOnUpdate={hasPage}
      autoScrollBodyContent
      actions={
        <Fragment>
          <FlatButton secondary label="Annuleren" onClick={close} />
          <FlatButton
            primary
            label="Selecteren"
            disabled={!hasPage}
            onClick={() => {
              selectItem({ itemId: props.itemId });
              close();
            }}
          />
        </Fragment>
      }
    >
      <Page section params={props} />
    </Dialog>
  );
};

export default compose(
  setDisplayName("BiebPreviewDialog"),
  setPropTypes({
    close: PropTypes.func.isRequired,
    selectItem: PropTypes.func.isRequired,
  }),
  branch(({ open }) => !open, renderNothing)
)(BiebPreviewDialog);
