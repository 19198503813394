import { IconButton } from "material-ui";
import React, { ReactNode } from "react";

interface Props {
  onClick(): void;
  children: ReactNode;
}

const InlineIconButton = ({ onClick, children }: Props): JSX.Element => (
  <div className="icon-button-inline">
    <IconButton onClick={onClick}>{children}</IconButton>
  </div>
);

export default InlineIconButton;
