import { filter, flatten, flow, includes, map } from "lodash/fp";
import { Card } from "material-ui/Card";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";

import {
  deselectRaamwerkFilterItemAction,
  resetRaamwerkFilterAction,
  selectRaamwerkFilterItemAction,
} from "../../actions/ui";
import { SiteLink, StatusLink } from "../../business/models";
import Facets from "../list/facets";
import { RaamwerkFilterItem } from "./raamwerk/models";

interface FacetProps {
  facets: Facet[];
  onSelect(facet: object, siteLink: SiteLink): void;
  onDeselect(facet: object, siteLink: SiteLink): void;
  onReset(): void;
}

interface Facet {
  id: object;
  value: string;
  items: FacetItem[];
}

interface FacetItem {
  id: SiteLink;
  value: string;
  count: number;
  selected: boolean;
}

interface RaamwerkFilterCollection {
  name: string;
  items: RaamwerkFilterItem[];
  emptyId: number;
  filter: (item: StatusLink) => boolean;
}

// ID for "Niet gevuld" in the list of "organisaties"
export const emptyOrganisationFacetId = -1;

// ID for "Niet gevuld" in the list of "praktijksituaties"
export const emptyPracticalSituationFacetId = -2;

const toFacets = (
  organizations: RaamwerkFilterItem[],
  practicalSituations: RaamwerkFilterItem[],
  selected: number[],
  dispatch: (action: Action<string>) => void,
  all: StatusLink[]
): FacetProps => {
  const facets = flow(
    filter(({ items }: RaamwerkFilterCollection) => items.length > 0),
    map(({ name, items, emptyId, filter }: RaamwerkFilterCollection) => {
      const allWithout = all.filter(filter);
      return {
        id: { label: name },
        value: name,
        items: [
          ...items.map(
            ({ filterItem, itemIds }: RaamwerkFilterItem): FacetItem => ({
              id: filterItem,
              value: filterItem.label,
              count: itemIds.length,
              selected: includes(filterItem.itemId)(selected),
            })
          ),
          {
            id: { itemId: emptyId },
            value: "Niet gevuld",
            count: allWithout.length,
            selected: includes(emptyId)(selected),
          } as FacetItem,
        ],
      };
    })
  )([
    {
      name: "Organisatie",
      items: organizations,
      emptyId: emptyOrganisationFacetId,
      filter: ({ link }) =>
        !flow(
          map("itemIds"),
          flatten
        )(organizations).includes(link?.itemId ?? 0),
    },
    {
      name: "Praktijksituatie",
      items: practicalSituations,
      emptyId: emptyPracticalSituationFacetId,
      filter: ({ leftItemId }) => !leftItemId,
    },
  ]);

  return {
    facets,
    onSelect(_, siteLink) {
      dispatch(selectRaamwerkFilterItemAction(siteLink.itemId));
    },
    onDeselect(_, siteLink) {
      dispatch(deselectRaamwerkFilterItemAction(siteLink.itemId));
    },
    onReset() {
      dispatch(resetRaamwerkFilterAction());
    },
  };
};

interface Props {
  organizations: RaamwerkFilterItem[];
  practicalSituations: RaamwerkFilterItem[];
  all: StatusLink[];
}

const RaamwerkFilters = ({
  organizations,
  practicalSituations,
  all,
}: Props) => {
  const selected = useSelector(
    (store: { ui: { raamwerkFilter: number[] } }) => store.ui.raamwerkFilter
  );
  const dispatch = useDispatch();
  const facetProps = toFacets(
    organizations,
    practicalSituations,
    selected,
    dispatch,
    all
  );

  return (
    <Card style={{ marginBottom: "16px" }}>
      <Facets {...facetProps} />
    </Card>
  );
};

export default RaamwerkFilters;
