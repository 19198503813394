/* eslint  react/prop-types: off */
import empty from "empty";
import React, { Component } from "react";
import SafeInnerHtml from "react-safeinnerhtml";

const getWidth = (element) => {
  const parsed = parseInt(getComputedStyle(element).width, 10);
  return Number.isNaN(parsed) ? undefined : parsed;
};

class MediaWidget extends Component {
  componentDidMount = () => {
    const { id, mediaWidget, init = empty.func } = this.props;
    if (id && !mediaWidget && this.domNode) {
      init(id, getWidth(this.domNode.parentNode));
    }
  };

  render = () => {
    const { id, mediaWidget: { rewrite } = empty.object } = this.props;
    return (
      <div
        key={id}
        style={{ overflow: "hidden" }}
        ref={(domNode) => (this.domNode = domNode)}
      >
        {id && rewrite ? <SafeInnerHtml>{rewrite}</SafeInnerHtml> : null}
      </div>
    );
  };
}

export default MediaWidget;
