/* eslint  react/prop-types: off */
import classNames from "classnames";
import empty from "empty";
import { find } from "lodash/fp";
import { ListItem } from "material-ui/List";
import Delete from "material-ui/svg-icons/action/delete";
import React from "react";
import { compose, mapProps, setDisplayName } from "recompose";

import { Mutations } from "../business";
import PagetypeIcon from "./pagetypeIcon";
import { ItemLink, postscriptInParentheses } from "./relation.itemlink";
import {
  defaultLabelRender,
  extraFromUsing,
  followPrimary,
  remove,
} from "./relation.utils";

/**
 * Relation component implementation
 */
export const Relation = ({
  id,
  item,
  using = empty.array,
  labelRender = defaultLabelRender,
  clusterFirst,
  edit = false,
  removeRelation,
  showPagetypeIcon = true,
  insideBieb,
  onClick = empty.func,
  legoblokStructures,
  mutation,
  secondaryText,
  secondaryTextLines,
}) => {
  const {
    itemId,
    structureId,
    type,
    status: { alias } = empty.object,
    validity: { alias: validity = "definitief" } = empty.object,
  } = item;
  const [{ itemId: usingItemId } = empty.object] = using;
  const biebStructure = find({ id: structureId })(legoblokStructures);
  const extra = extraFromUsing(using, { clusterFirst }, biebStructure);
  const labelRenderWrap =
    mutation === Mutations.created
      ? (...args) => <ins className="diff-mod">{labelRender(...args)}</ins>
      : mutation === Mutations.deleted
      ? (...args) => <del className="diff-mod">{labelRender(...args)}</del>
      : labelRender;
  return (
    <ListItem
      className={classNames({ mutation: mutation !== undefined })}
      leftIcon={
        showPagetypeIcon ? (
          <PagetypeIcon
            type={clusterFirst && item.pageClusterLabel ? "cluster" : type}
            colorProperty={alias}
            insideBieb={insideBieb || biebStructure !== undefined}
            validity={validity}
          />
        ) : undefined
      }
      innerDivStyle={{ paddingLeft: "56px" }}
      rightIcon={
        edit ? (
          <Delete onClick={remove(removeRelation, id, itemId, usingItemId)} />
        ) : undefined
      }
      onClick={followPrimary(onClick)}
      primaryText={
        <ItemLink className="primary" item={item} clusterFirst={clusterFirst}>
          {labelRenderWrap(item, { clusterFirst })}{" "}
        </ItemLink>
      }
      secondaryText={secondaryText || postscriptInParentheses(extra)}
      secondaryTextLines={
        secondaryTextLines || (extra && extra.length > 1 ? 2 : 1)
      }
    />
  );
};

export default compose(
  setDisplayName("RelationView"),
  mapProps(
    ({
      relation: { id, item = empty.object, using = empty.array, mutation },
      ...other
    }) => ({ ...other, item, using, id, mutation })
  )
)(Relation);
