import React from "react";

import { pagetypeGroupFromAlias, pagetypeGroups } from "../../../pagetypes";
import { isNonEmptyArray } from "../../../utils";
import Tree from "../tree";

const renderProcessTree = (props) => {
  const { id, currentSteps, tree, pagetype, ...rest } = props;
  const isProcessPagetype =
    pagetypeGroupFromAlias(pagetype) === pagetypeGroups.proces;
  return (
    isNonEmptyArray(currentSteps) && (
      <Tree
        key="tree"
        {...rest}
        tree={tree}
        currentSteps={isProcessPagetype ? [id] : currentSteps}
        enableCollapse={!isProcessPagetype}
        itemId={isProcessPagetype ? id : undefined}
      />
    )
  );
};

export default renderProcessTree;
