import { composeAction } from "async-lifecycle";
import empty from "empty";

import { addKoppelingOverdracht } from "../api/application";
import { getPageAction } from "./data";
import { invalidatePage } from "./page";
import { invalidateRelations } from "./relations.invalidate";

export const openKoppelingGegevenAction = (pageClusterId, selected) => ({
  type: "KOPPELING_GEGEVEN_DIALOG",
  payload: { open: true, pageClusterId, selected },
});

export const closeKoppelingGegevenAction = () => ({
  type: "KOPPELING_GEGEVEN_DIALOG",
  payload: { open: false, pageClusterId: undefined, selected: empty.array },
});

export const addKoppelingGegevenAction = (itemId, pageClusterId, selected) =>
  composeAction({
    group: `ADDKOPPELINGGEGEVEN`,
    fire: (itemId, selected) =>
      addKoppelingOverdracht(itemId, pageClusterId, selected),
    args: [itemId, selected],
    callbackSuccess: (payload, dispatch) => {
      dispatch(invalidatePage(itemId));
      dispatch(invalidateRelations(itemId));
      dispatch(invalidateRelations(selected[0].left.itmIdt));
      dispatch(invalidateRelations(selected[0].right.itmIdt));
      dispatch(getPageAction(itemId));
      dispatch(closeKoppelingGegevenAction());
    },
  });
