import {
  entries,
  filter,
  flatten,
  flow,
  get,
  groupBy,
  head,
  last,
  map,
  sortBy,
  values,
} from "lodash/fp";
import PropTypes from "prop-types";
import React from "react";
import { compose, setDisplayName, withHandlers, withState } from "recompose";

import { entiteitSortKey } from "../../../business/gegevens";
import { readOnlyPageActions } from "../../../business/models";
import {
  itemShape,
  pageActionsShape,
  relationShape,
  relationTypeShape,
  structureShape,
} from "../../../business/prop-types";
import { flown } from "../../../lodash";
import ClusterAddButton from "../../material/clusterAddButton";
import HintCard from "../../material/HintCard";
import VerantwoordingBlok from "./verantwoordingBlok";

const expandUsing = flow(
  map((relation) =>
    relation.using.length <= 1
      ? [relation]
      : flown(
          relation.using,
          filter({ type: "verantwoording" }),
          map((verantwoording) => ({
            ...relation,
            using: [relation.using[0], verantwoording],
          }))
        )
  ),
  flatten
);

const ProcesVerantwoording = ({
  addInit,
  biebStructures,
  page,
  pageActions,
  relations,
  removeAllRelations,
  removeRelation,
  type,
  gotoVerantwoordingen,
}) => (
  <div>
    {flown(
      relations,
      expandUsing,
      groupBy(
        flow(
          get("using"),
          filter({ type: "verantwoording" }),
          last,
          get("itemId")
        )
      ),
      values,
      sortBy(flow(head, get("using"), last, entiteitSortKey)),
      entries,
      map(([n, relationsGroup]) => {
        const verantwoordingId = flow(
          get("using"),
          last,
          get("itemId")
        )(relationsGroup[0]);
        return (
          <VerantwoordingBlok
            key={verantwoordingId || 0}
            biebStructures={biebStructures}
            nthChild={Number(n)}
            pageActions={readOnlyPageActions}
            relations={relationsGroup}
            removeAllRelations={() => removeAllRelations(verantwoordingId)}
            removeRelation={removeRelation}
            verantwoording={page[verantwoordingId]}
            gotoVerantwoordingen={gotoVerantwoordingen}
          />
        );
      })
    )}
    {/* TODO: remove this and restore button */}
    {pageActions.edit && type.mayAdd && (
      <HintCard
        primaryText="Dit tabblad toont alleen gegevens."
        secondaryText="Koppel gegevens en verantwoordingen bij de applicatiegegevens."
      />
    )}

    {Date.now() === 0 && pageActions.edit && type.mayAdd && (
      <ClusterAddButton
        onClick={addInit}
        label="Verantwoording en gegevens toevoegen"
      />
    )}
  </div>
);

ProcesVerantwoording.propTypes = {
  addInit: PropTypes.func.isRequired,
  biebStructures: PropTypes.arrayOf(structureShape.isRequired).isRequired,
  page: PropTypes.objectOf(itemShape).isRequired,
  pageActions: pageActionsShape.isRequired,
  relations: PropTypes.arrayOf(relationShape.isRequired).isRequired,
  removeAllRelations: PropTypes.func.isRequired,
  removeRelation: PropTypes.func.isRequired,
  type: relationTypeShape.isRequired,
  gotoVerantwoordingen: PropTypes.func.isRequired,
};

export default compose(
  setDisplayName("ProcesVerantwoording"),
  withState("addOpen", "setOpen", false),
  withHandlers({
    addInit:
      ({ addInit }) =>
      () =>
        addInit(),
  })
)(ProcesVerantwoording);
