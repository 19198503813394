/* eslint  react/prop-types: off */

import FlatButton from "material-ui/FlatButton";
import OpenInNew from "material-ui/svg-icons/action/open-in-new";
import React, { memo } from "react";

const AddClusterRelationButton = memo(({ onClick }) => (
  <FlatButton
    label="Relatie toevoegen"
    labelPosition="before"
    onClick={onClick}
    icon={<OpenInNew style={{ width: 16, height: 16 }} />}
    labelStyle={{
      textTransform: "none",
      fontWeight: "normal",
      padding: "0 8px 0 0",
    }}
    style={{ textAlign: "left", height: 24, lineHeight: "24px" }}
    fullWidth
  />
));

export default AddClusterRelationButton;
