import classNames from "classnames";
import { FlatButton, IconButton, List, ListItem } from "material-ui";
import IconSwapVertical from "material-ui/svg-icons/action/swap-vert";
import IconDragHandle from "material-ui/svg-icons/editor/drag-handle";
import React, { CSSProperties, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  SortableContainer,
  SortableContainerProps,
  SortableElement,
  SortableElementProps,
  arrayMove,
} from "react-sortable-hoc";

import { getPageAction } from "../../actions/data";
import { invalidateRelations } from "../../actions/relations.invalidate";
import {
  RelationItem as RelationItemType,
  RelationListItem as RelationListItemType,
  RelationSide,
  RelationType,
} from "../../business/models";
import Dialog from "../../containers/dialog";
import { sagas } from "../../sagas";
import HintCard from "../material/HintCard";
import { defaultLabelRender } from "../relation.utils";

interface RelationListItemProps {
  label: string;
  style?: CSSProperties;
  className?: string;
}

const RelationListItem = ({
  label,
  style,
  className,
  ...props
}: RelationListItemProps) => (
  <ListItem
    leftIcon={<IconDragHandle />}
    className={classNames("relations-sort-draggable", className)}
    style={style}
    {...props}
  >
    {label}
  </ListItem>
);

const SortableItem: React.ComponentClass<
  RelationListItemProps & SortableElementProps
> = SortableElement(RelationListItem);

interface RelationListProps {
  relations: RelationListItemType[];
  labelRender: (relationItem: RelationItemType) => string;
  style?: CSSProperties;
  className?: string;
}

const RelationList = ({
  relations,
  labelRender,
  style,
  className,
  ...props
}: RelationListProps) => (
  <List
    className={classNames("relations-sort-list", className)}
    style={style}
    {...props}
  >
    {relations.map((r, i) => (
      <SortableItem key={r.id} label={labelRender(r.item)} index={i} />
    ))}
  </List>
);

const SortableList: React.ComponentClass<
  RelationListProps & SortableContainerProps
> = SortableContainer(RelationList);

export interface RelationsSortDialogProps {
  itemId: number;
  type: RelationType;
  side: RelationSide;
  relations: RelationListItemType[];
  enabled: boolean;
  labelRender: (relationItem: RelationItemType) => string;
}

const RelationsSortDialog = ({
  itemId,
  side,
  relations,
  enabled,
  labelRender = defaultLabelRender,
}: RelationsSortDialogProps) => {
  const [sortedRelations, setSortedRelations] = useState(relations);
  const [show, setShow] = useState(false);
  const [busy, setBusy] = useState(false);

  useEffect(() => {
    if (!show) {
      setSortedRelations(relations);
    }
  }, [relations, show]);

  const sortByAlphabet = useCallback(() => {
    setSortedRelations(
      sortedRelations
        .slice()
        .sort((a, b) => labelRender(a.item).localeCompare(labelRender(b.item)))
    );
  }, [labelRender, sortedRelations]);

  const openDialog = useCallback(() => {
    setShow(true);
  }, []);

  const closeDialog = useCallback(() => {
    setShow(false);
  }, []);

  const dispatch = useDispatch();
  const saveSortedList = useCallback(() => {
    setBusy(true);
    dispatch({
      type: sagas.orderRelations.require,
      payload: {
        itemId,
        direction: side === 1 ? "van" : side === 2 ? "nar" : "met",
        ids: sortedRelations.flatMap((r) => r.ids ?? [r.id]),
      },
      onSuccess: () => {
        dispatch(invalidateRelations(itemId));
        dispatch(getPageAction(itemId));
        setBusy(false);
        closeDialog();
      },
      onFail: () => {
        setBusy(false);
      },
    });
  }, [closeDialog, dispatch, itemId, side, sortedRelations]);

  return (
    <>
      <IconButton
        style={{ padding: 0, margin: 0, width: 24, height: 24 }}
        disabled={!enabled}
        onClick={openDialog}
      >
        <IconSwapVertical style={{ padding: 0, margin: 0 }} />
      </IconButton>
      {show && (
        <Dialog
          title="Volgorde wijzigen"
          open
          autoScrollBodyContent
          actions={[
            <FlatButton
              style={{ float: "left" }}
              label="Alfabetiseren"
              disabled={busy}
              onClick={sortByAlphabet}
            />,
            <FlatButton
              key="close"
              secondary
              label="Annuleren"
              onClick={closeDialog}
              disabled={busy}
            />,
            <FlatButton
              primary
              key="save"
              label="Opslaan"
              onClick={saveSortedList}
              disabled={busy}
            />,
          ]}
        >
          <SortableList
            relations={sortedRelations}
            helperClass="relations-sort"
            labelRender={labelRender}
            distance={5}
            lockAxis="y"
            onSortEnd={({ oldIndex, newIndex }) =>
              setSortedRelations(arrayMove(sortedRelations, oldIndex, newIndex))
            }
          />
          <HintCard primaryText="Sleep de items in de goede volgorde." />
        </Dialog>
      )}
    </>
  );
};

export default RelationsSortDialog;
