import { composeAction } from "async-lifecycle";
import empty from "empty";

import {
  createLegoblok,
  doUseLegoblok,
  getLegoblokReferences,
  moveLegoblok,
} from "../api";
import { addStage, redirectAction } from "./common";
import { invalidateRecentlyCreatedAction } from "./dashboard";
import { getPageAction } from "./data";
import { invalidatePage, pageEditAction } from "./page";
import { invalidateRelations } from "./relations.invalidate";
import { snackbarShowAction } from "./ui";
import { startBiebJobWatcher } from "./utils";

export const initializeCreateLegoblok = (open) => ({
  type: "CMS_LEGOBLOK_CREATE",
  payload: { open },
});

export const legoblokCreateAction = (newLegoblok) => (dispatch, getState) => {
  const {
    session: { miniSaar: { path = "iznet" } = empty.object } = empty.object,
  } = getState();

  return dispatch(
    composeAction({
      group: "CMS_LEGOBLOK_CREATE",
      fire: (newLegoblok) => createLegoblok(newLegoblok),
      args: [newLegoblok],
      callbackSuccess: (payload, dispatch) => {
        dispatch(invalidateRecentlyCreatedAction());
        if (payload.itemId) {
          dispatch(
            redirectAction(`/${path}/page/${payload.itemId}/${payload.slug}`)
          );
          dispatch(pageEditAction(payload.itemId));
        }
      },
    })
  );
};

export const legoblokInitUseAction = (open) => ({
  type: "CMS_LEGOBLOK_USEINIT",
  payload: { open },
});

export const legoblokReferencesAction = (itemId) =>
  composeAction({
    group: "CMS_LEGOBLOK_REFERENCES",
    fire: (itemId) => getLegoblokReferences(itemId),
    args: [itemId],
    key: "id",
    cachekey: "legoblokReferences",
  });

export const doUseLegoblokAction =
  (legoblokUseModel) => (dispatch, getState) => {
    const {
      session: { miniSaar: { path = "iznet" } = empty.object } = empty.object,
    } = getState();

    return dispatch(
      composeAction({
        group: "CMS_LEGOBLOK_USE",
        fire: (legoblokUseModel) => doUseLegoblok(legoblokUseModel),
        args: [legoblokUseModel],
        callbackSuccess: (payload, dispatch) => {
          dispatch(invalidateRelations(legoblokUseModel.legoblokId));

          if (payload.length === 0) {
            dispatch(
              snackbarShowAction(
                "Het legoblok wordt overgenomen in de achtergrond"
              )
            );
            startBiebJobWatcher(dispatch);
          } else {
            const item = payload[0];
            dispatch(
              redirectAction(`/${path}/page/${item.itemId}/${item.slug}`)
            );
            dispatch(pageEditAction(item.itemId));
          }
        },
      })
    );
  };

const pageEditOff = (itemId) => ({
  type: "FORM_PAGE_SET",
  payload: { id: itemId, edit: false },
});

export const realLegoblokMoveAction =
  (itemId, newStructureId) => (dispatch) => {
    dispatch(pageEditOff(itemId));
    return dispatch(
      composeAction({
        group: "LEGOBLOK_MOVE_ITEM",
        fire: (itemId, newStructureId) => moveLegoblok(itemId, newStructureId),
        args: [itemId, newStructureId],
        callbackSuccess: (_, dispatch) => {
          dispatch(invalidatePage(itemId));
          dispatch(getPageAction(itemId));
        },
      })
    );
  };

export const legoblokMoveAction = (itemId, newStructureId) =>
  addStage({
    message: "Weet u zeker dat deze pagina verplaatst moet worden?",
    action: realLegoblokMoveAction(itemId, newStructureId),
  });
