/* eslint  react/prop-types: off */
import empty from "empty";
import { defaultTo, filter, map } from "lodash/fp";
import { Card } from "material-ui/Card";
import React, { useEffect, useMemo } from "react";
import { compose, setDisplayName, withProps } from "recompose";

import enhanceWithFacets from "../../containers/facetsContainer";
import { flown } from "../../lodash";
import { makePagetypes } from "../../pagetypes";
import Facets from "../list/facets";
import { selItmFacet, selLstFacet } from "../list/selectors";
import { extractTabs } from "../tabs";
import BasePage from "./basePage";

/**
 * Index component
 */
const Index = (props) => {
  const { selectionLists, selections, setSelections, page, loading } = props;
  const hasStatusFilter =
    page?.page?.selectie?.selectieInstellingen?.eigenschappen?.statusfilter
      ?.isEmpty === false;
  const statusList = useMemo(
    () => selectionLists.find(({ alias }) => alias === "status"),
    [selectionLists]
  );

  // set default status-selection on initial load of the index
  useEffect(() => {
    if (loading || hasStatusFilter) {
      return;
    }

    const defaultStatusItems = flown(
      statusList?.items,
      filter(({ alias }) => alias !== "archief"),
      map(({ id }) => selItmFacet(id)),
      defaultTo(empty.array)
    );
    if (defaultStatusItems.length > 0 && selections?.size === 0) {
      const nextSelections = new Map();
      nextSelections.set(selLstFacet(statusList.id), [...defaultStatusItems]);
      setSelections(nextSelections);
    }
  }, [hasStatusFilter, loading, selections, setSelections, statusList]);

  return (
    <BasePage
      {...props}
      rightbar={{
        children: (
          <Card key="index-filters">
            {props.facets?.length > 0 && (
              <Facets
                label="Filters"
                facets={props.facets}
                onSelect={props.onSelect}
                onDeselect={props.onDeselect}
                onReset={props.onReset}
              />
            )}
          </Card>
        ),
      }}
    />
  );
};

export default compose(
  setDisplayName("Index"),
  withProps(
    ({ page: { page: { pagetype } = empty.object } = empty.object }) => ({
      pagetypes: makePagetypes(pagetype),
    })
  ),
  enhanceWithFacets(),
  withProps((props) => ({ tabs: extractTabs(props) }))
)(Index);
