import { ReactNode, useEffect, useRef } from "react";

interface Props {
  main?: boolean;
  backgroundColor?: string;
  children: ReactNode;
}

let mainElement: HTMLDivElement | null = null;

const offset = (element: HTMLElement, f: (e: HTMLElement) => number) => {
  let accu = 0;
  while (element) {
    accu += f(element) || 0;
    element = element.offsetParent as HTMLElement;
  }
  return accu;
};

const StickOnScroll = ({ main, backgroundColor, children }: Props) => {
  const stick = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const element = stick.current;
    if (element) {
      const offsetTop = offset(element, (e) => e.offsetTop);
      const appBar = document.getElementsByClassName(
        "appBar"
      )[0] as HTMLDivElement;
      let clone: HTMLDivElement | null = null;
      const getClone = (): HTMLDivElement => {
        if (!clone) {
          clone = element.cloneNode(true) as HTMLDivElement;
          clone.style.display = "none";
          clone.style.position = "fixed";
          clone.style.left = offset(element, (e) => e.offsetLeft) + "px";
          clone.style.width = element.offsetWidth + "px";
          clone.style.height = element.offsetHeight + "px";
          clone.style.zIndex = "1000";
          element.parentElement!.appendChild(clone);

          if (main) {
            mainElement = clone;
          }
        }
        return clone;
      };
      const scrollCallBack = () => {
        const top =
          appBar.offsetHeight -
          (main ? 0 : 1) +
          (main || !mainElement ? 0 : mainElement?.offsetHeight || 0);
        const show = window.scrollY + top + element.offsetHeight > offsetTop;
        const local = show ? getClone() : clone;
        if (local) {
          local.style.top = `${top}px`;
          local.style.display = show ? "block" : "none";
        }
      };
      window.addEventListener("scroll", scrollCallBack);
      return () => {
        window.removeEventListener("scroll", scrollCallBack);
        if (clone) {
          element.parentElement?.removeChild(clone);
        }
        if (main) {
          mainElement = null;
        }
      };
    }
  }, [main]);
  return (
    <div
      ref={stick}
      style={{
        boxSizing: "border-box",
        backgroundColor,
        border: "1px solid transparent",
      }}
    >
      {children}
    </div>
  );
};

export default StickOnScroll;
