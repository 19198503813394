import empty from "empty";
import {
  compose,
  pure,
  setDisplayName,
  withHandlers,
  withProps,
  withState,
} from "recompose";

import {
  initializeCreateLegoblok,
  legoblokCreateAction,
} from "../actions/legoblok";
import LegoblokAddDialog from "../components/legoblokAddDialog";
import connect from "./connect";

const initialLegoblok = {
  label: "",
  root: "",
  siteId: "",
  sourceItemId: "",
  tab: "",
};

export default compose(
  setDisplayName("LegoblokAddDialog"),
  pure,
  withState(
    "newLegoblok",
    "updateLegoblok",
    ({ page: { id, title } = empty.object }) => ({
      ...initialLegoblok,
      label: title,
      sourceItemId: id,
    })
  ),
  withProps(
    ({
      newLegoblok: { siteId, tab, root, sourceItemId, label } = empty.object,
    }) => ({
      valid:
        siteId &&
        siteId !== "" &&
        tab &&
        tab !== "" &&
        root &&
        root !== "" &&
        sourceItemId &&
        sourceItemId !== "" &&
        label &&
        label !== "",
    })
  ),
  connect(
    ({
      form: { legoblokDialog: { open = false, busy = false } = empty.object },
      data: { addLegoblok: { structures = empty.array } = empty.object },
    }) => ({
      structures,
      open,
      busy,
    }),
    (dispatch, { newLegoblok, valid, updateLegoblok }) => ({
      close: () => {
        dispatch(initializeCreateLegoblok(false));
        updateLegoblok(initialLegoblok);
      },
      create: () => {
        if (valid) {
          dispatch(initializeCreateLegoblok(false));
          dispatch(legoblokCreateAction(newLegoblok));
        }
      },
    })
  ),
  withHandlers({
    updateSite:
      ({ structures, newLegoblok, updateLegoblok }) =>
      (event, index, value) => {
        updateLegoblok({
          ...newLegoblok,
          siteId: value,
          root: structures[index].root,
        });
      },
    updateTab:
      ({ structures, newLegoblok, updateLegoblok }) =>
      (event, index, value) => {
        if (
          structures &&
          structures.length === 1 &&
          (!newLegoblok.siteId || newLegoblok.siteId === "")
        ) {
          updateLegoblok({
            ...newLegoblok,
            siteId: structures[0].id,
            root: structures[0].root,
            tab: value,
          });
        } else {
          updateLegoblok({ ...newLegoblok, tab: value });
        }
      },
    createLegoblok:
      ({ create }) =>
      (event) => {
        event.preventDefault();
        create();
      },
  }),
  withProps(({ newLegoblok: { siteId, tab } = empty.object }) => ({
    valid: siteId && siteId !== "" && tab && tab !== "",
  }))
)(LegoblokAddDialog);
