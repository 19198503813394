import { Card, CardHeader } from "material-ui/Card";
import { deepOrange500 } from "material-ui/styles/colors";
import SocialSchool from "material-ui/svg-icons/social/school";
import React, { CSSProperties, ReactNode } from "react";

interface Props {
  primaryText: ReactNode;
  secondaryText?: ReactNode;
  avatar?: ReactNode;
  style?: CSSProperties;
}

const HintCard = ({
  avatar,
  primaryText,
  secondaryText,
  style,
}: Props): JSX.Element => (
  <Card style={style}>
    <CardHeader
      avatar={avatar ?? <SocialSchool color={deepOrange500} />}
      title={primaryText}
      subtitle={secondaryText}
    />
  </Card>
);

export default HintCard;
