import { defaultTo, flow, get, pick } from "lodash/fp";
import { compose, setDisplayName } from "recompose";

import {
  oneDriveFileInfoAction,
  oneDriveFileInfoResetAction,
} from "../../actions/data";
import OneDrive from "../../components/ggvtyp/onedrive";
import { getOneDriveFileSelector } from "../../selectors";
import connect from "../connect";

export default compose(
  setDisplayName("OneDrive"),
  connect(
    (state, props) => ({
      ...flow(
        get("session.officeInfo"),
        pick(["accessToken", "clientId"])
      )(state),
      oneDriveFile: getOneDriveFileSelector(state, props),
      oneDriveEnpointHint: flow(
        get("data.settings.data.microsoftonline_endpointhint"),
        defaultTo("https://infozorg.sharepoint.com/Shared%20Documents/")
      )(state),
    }),
    (dispatch) => ({
      fileInfo: (fieldId, files) =>
        dispatch(oneDriveFileInfoAction(fieldId, files)),
      resetFileInfo: () => dispatch(oneDriveFileInfoResetAction()),
    })
  )
)(OneDrive);
