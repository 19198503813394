export const labelStyle = {
  fontSize: 12,
  color: "rgba(0, 0, 0, 0.3)",
};

export const requiredStyle = {
  borderLeft: "2px solid #ff5722",
  paddingLeft: 8,
  marginBottom: 8,
};

export const validStyle = {
  borderLeft: "2px solid #aed581",
  paddingLeft: 8,
  marginBottom: 8,
};
