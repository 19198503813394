import { loadingProgress } from "async-lifecycle";
import empty from "empty";
import { debounce, includes, map } from "lodash/fp";
import {
  compose,
  setDisplayName,
  withHandlers,
  withProps,
  withState,
} from "recompose";

import { clearSearchItemAction, searchItemAction } from "../actions/cms";
import VerwijzingSearchDialog from "../components/verwijzingSearchDialog";
import { execOnChange } from "../recompose.contrib";
import connect from "./connect";

export default compose(
  setDisplayName("VerwijzingSearchDialog"),
  withState("open", "toggleDialog", false),
  withState("query", "updateQuery", empty.object),
  withState("selectedItem", "updateSelection", empty.object),
  connect(
    ({
      data: {
        addLegoblok: { structures = empty.array } = empty.object,
        verwijzingSearch: {
          documents = empty.array,
          loading = "",
        } = empty.object,
      } = empty.object,
    }) => {
      const biebStructures = map("id")(structures);
      return {
        biebStructures,
        found: map(
          ({ fields: { sitIdt, ...restFields } = empty.object, ...rest }) => ({
            ...rest,
            fields: { ...restFields, sitIdt },
            insideBieb: includes(parseInt(sitIdt, 10))(biebStructures),
          })
        )(documents),
        loading,
      };
    },
    (dispatch, { updateQuery }) => {
      const searchItem = debounce(500)((query) => {
        updateQuery(query);
        dispatch(searchItemAction(query));
      });
      return {
        searchItem,
        clear: () => dispatch(clearSearchItemAction()),
      };
    }
  ),
  withHandlers({
    reset:
      ({ updateQuery, clear }) =>
      () => {
        updateQuery(empty.object);
        clear();
      },
  }),
  withProps(({ loading = "" }) => ({
    busy: loadingProgress(loading),
  })),
  withHandlers({
    openDialog:
      ({ toggleDialog }) =>
      () =>
        toggleDialog(true),
    closeDialog:
      ({ toggleDialog, onSave = empty.func, reset }) =>
      () => {
        toggleDialog(false);
        onSave(empty.object);
        reset();
      },
    save:
      ({
        onSave = empty.func,
        toggleDialog,
        selectedItem: { itemId, pageClusterId, label } = empty.object,
        reset,
      }) =>
      () => {
        if (itemId) {
          onSave({
            value: { linkId: itemId, clusterId: pageClusterId, label },
            string: pageClusterId
              ? `${itemId}#PagCls_${pageClusterId}`
              : itemId,
          });
        }

        toggleDialog(false);
        reset();
      },
    update:
      ({
        searchItem,
        paginatypen = empty.array,
        clusterName,
        sitesInclude,
        sitesExclude,
      }) =>
      (event) => {
        event.preventDefault();
        const { target: { value } = empty.object } = event;
        if (value && value.length > 2) {
          searchItem.cancel();
          searchItem({
            trefwoord: value,
            paginatypen,
            clusterName,
            includeStructures: sitesInclude,
            excludeStructures: sitesExclude,
          });
        }
      },
    select:
      ({ updateSelection, found = empty.array }) =>
      (event, selectedId) => {
        const item = found.find(
          ({ fields: { itemId, pageClusterId } = empty.object }) =>
            (pageClusterId ? `${itemId}_${pageClusterId}` : itemId) ===
            selectedId
        );

        const { itemId, label, pageClusterId, pageClusterLabel } = item.fields;
        updateSelection({
          itemId,
          pageClusterId,
          label: pageClusterLabel ? `${label} (${pageClusterLabel})` : label,
        });
      },
  }),
  execOnChange((props) => {
    props.updateQuery(empty.object);
    props.clear();
  })
)(VerwijzingSearchDialog);
