import { loadingPresent, loadingProgress } from "async-lifecycle";
import empty from "empty";
import {
  defaultTo,
  filter,
  find,
  flow,
  get,
  identity,
  includes,
  nthArg,
  some,
  values,
} from "lodash/fp";
import { createSelector, createStructuredSelector } from "reselect";

import { infozorgOnlyPagetypes } from "../business";
import {
  mayAddRelationType,
  mayDeleteRelationType,
  prohibitEditInfozorgOnly,
} from "../business/authorization";
import { emptyPageForm } from "../reducers/page";
import { emptyLookup, mapValues, withLookup } from "../utils";
import { versionsSelectorBuilder } from "./versions";
import { miniSaarSelector, miniSaarsSelector, sessionSelector } from ".";

const createLabeledSelector = (selector, property) => {
  const result = createSelector(selector, identity);
  result.property = property;
  return result;
};

export const allLegoblokStructures = flow(
  get("structures"),
  defaultTo(empty.array)
);

export const writeableLegoblokStructures = createSelector(
  allLegoblokStructures,
  filter({ writeAccess: true })
);

export const biebStructures = flow(
  get("data.addLegoblok.structures"),
  defaultTo(empty.array)
);

export const typesSelector = createSelector(
  [
    flow(get("data.relationTypes.types"), defaultTo(emptyLookup("alias"))),
    flow(get("data.pagetypeList.data"), defaultTo(empty.array)),
    sessionSelector,
  ],
  (types, pagetypes, { user }) =>
    withLookup("alias")(
      types.map((type) => {
        const missingPagetype = (side) =>
          side && !some({ alias: side.pagetype })(pagetypes);
        const canExist = !(
          missingPagetype(type.left) ||
          missingPagetype(type.right) ||
          missingPagetype(type.using)
        );
        const mayAdd = mayAddRelationType(user, type);
        const rightShow = type.right.show || 0;
        return {
          ...type,
          canExist,
          mayAdd,
          mayAddToBieb: mayAdd && user.mayAddToBieb,
          mayDelete: mayDeleteRelationType(user, type),
          rightShow,
        };
      })
    )
);

export const otherItems = createSelector(
  [
    ({ items = empty.array } = empty.object) => items,
    (_, id) => {
      const itemId = parseInt(id, 10);
      return itemId > 0 ? itemId : undefined;
    },
  ],
  (items, id) => items.filter(({ itemId }) => itemId !== id).slice(0, 10)
);

const coreLoadingProperties = ["page", "relations"];

const loadingProperties = [
  "addLegoblok",
  "chainRelations",
  // "expandedLandscape",
  // "landscape",
  "legoblokReferences",
  "notes",
  "page",
  "raamwerk",
  "raamwerkPagetypes",
  "recentlyCreated",
  "relations",
  "selection",
  "siblings",
  // "tree",
  // "versions",
];

const propertyIdLoadingSelector =
  (property) =>
  ({ data: { [property]: container = empty.object } }, { id }) => {
    const loading = container[id]?.loading;
    return coreLoadingProperties.includes(property)
      ? loadingProgress(loading)
      : !loadingPresent(loading);
  };

export const loadingSelector = createSelector(
  loadingProperties.map(propertyIdLoadingSelector),
  some(identity)
);

const propertyIdReloadingSelector =
  (property) =>
  ({ data: { [property]: container = empty.object } }, { id }) =>
    container[id]?.loading === "reinit";

export const reloadingSelector = createSelector(
  loadingProperties.map(propertyIdReloadingSelector),
  some(identity)
);

export const listColumnsSelector = createSelector(
  [({ data: { pages = empty.object } = empty.object }, { id }) => pages[id]],
  (
    {
      page: {
        selectie: {
          selectieInstellingen: {
            eigenschappen: {
              kolommen: { value = empty.array } = empty.object,
            } = empty.object,
          } = empty.object,
        } = empty.object,
      } = empty.object,
    } = empty.object
  ) =>
    value && value.length > 0 ? value.map((v) => v.alias || v.value) : undefined
);

const idSelector = (_, { id }) => parseInt(id, 10);
export const pageSelector = ({ data: { pages = empty.object } }, { id }) =>
  pages[id];
export const eligibleRelationTypeIdsSelector = createSelector(
  identity,
  (types = empty.array) => {
    const ids = types
      .filter(
        (type) =>
          !(type.alias.includes("bieb_") && type.alias.includes("_cluster_"))
      )
      .map(({ id }) => id);
    return new Set(ids);
  }
);
const relationsSelector = (
  {
    data: {
      relations = empty.object,
      relationTypes: { types = empty.array },
    },
  },
  { id }
) => {
  const ids = eligibleRelationTypeIdsSelector(types);
  return ((relations[id] && relations[id].relations) || empty.array).filter(
    ({ relationTypeId }) => ids.has(relationTypeId)
  );
};

const pagetypeAllowedSelector = createSelector(
  [pageSelector, flow(get("data.pagetypeList.data"), defaultTo(empty.array))],
  (page, pagetypeList) => {
    const alias = get("page.pagetype")(page);
    const pagetype = alias && find({ alias })(pagetypeList);
    return (pagetype && pagetype.allowed) || 0;
  }
);

const secondaryDrawerOpen = flow(
  get("ui.secondaryDrawerOpen"),
  defaultTo(false)
);

const versionsSelector = versionsSelectorBuilder(idSelector);

const metaFieldsSelector = createSelector(
  [
    pageSelector,
    flow(get("data.fieldDefinitions.definitions"), defaultTo(empty.array)),
  ],
  (page, definitions) => {
    const pagetype = get("page.pagetype")(page);
    return filter(
      ({ cluster, pagetypes }) =>
        pagetypes.includes(pagetype) && get("name")(cluster) === "Meta"
    )(definitions);
  }
);

const pageSelectors = mapValues(createLabeledSelector)({
  form: ({ form: { page, ...form } }, { id }) => ({
    ...form,
    page: page[id] || emptyPageForm,
  }),
  id: idSelector,
  entitylandscape: ({ data: { entitylandscape = empty.object } }, { id }) =>
    entitylandscape[id],
  landscape: ({ data: { landscape = empty.object } }, { id }) => landscape[id],
  landscapeItem: ({
    data: {
      navigation: { footerLinks = empty.array } = empty.object,
    } = empty.object,
  }) => footerLinks.find((l) => l.type === "applicatielandschap"),
  expandedLandscape: ({ data: { expandedLandscape = empty.object } }, { id }) =>
    expandedLandscape[id],
  miniSaars: miniSaarsSelector,
  selectedSaar: miniSaarSelector,
  notes: ({ data: { notes = empty.object } }, { id }) =>
    (notes[id] || empty.object).notes || empty.array,
  page: pageSelector,
  pagetypeAllowed: pagetypeAllowedSelector,
  relations: relationsSelector,
  selection: ({ data: { selection = empty.object } }, { id }) => selection[id],
  selectionLists: ({
    data: {
      selectionLists: { data = empty.array },
    },
  }) => data,
  pagetypeList: ({
    data: {
      pagetypeList: { data = empty.array },
    },
  }) => data,
  legoblokStructures: ({
    data: {
      addLegoblok: { structures = empty.array } = empty.object,
    } = empty.object,
  }) => structures,
  siblings: ({ data: { siblings = empty.object } }, { id }) => siblings[id],
  tree: ({ data: { tree = empty.object } }, { id }) => tree[id],
  types: typesSelector,
  user: createSelector(
    [
      sessionSelector,
      ({ data: { pages = empty.object } }, { id }) => pages[id],
    ],
    ({ user }, { page: { pagetype } = empty.object } = empty.object) => ({
      ...user,
      mayEdit:
        user.mayEdit &&
        !(
          includes(pagetype)(infozorgOnlyPagetypes) &&
          prohibitEditInfozorgOnly(user)
        ),
    })
  ),
  loading: loadingSelector,
  reloading: reloadingSelector,
  listColumns: listColumnsSelector,
  uiClasses: ({ ui: { classes = empty.array } = empty.object }) => classes,
  secondaryDrawerOpen,
  imageLightbox: ({ data: { imageLightbox } = empty.object }) => imageLightbox,
  viewport: ({ ui: { viewport } = empty.object }) => viewport,
  versions: versionsSelector,
  metaFields: metaFieldsSelector,
});

export const createMapState = (...args) =>
  createStructuredSelector(
    args.reduce((obj, arg) => ({ ...obj, [arg.property]: arg }), empty.object)
  );

export const mapPageState = createMapState(...values(pageSelectors));

export const pageSimpleSelectors = mapValues(createLabeledSelector)({
  id: idSelector,
  page: pageSelector,
  relations: relationsSelector,
  types: typesSelector,
  secondaryDrawerOpen,
});

export const mapPageSimpleState = createMapState(
  ...values(pageSimpleSelectors)
);

const presentationDataSelector = createSelector(
  [
    flow(nthArg(1), get("page.page.pagetype"), defaultTo("")),
    flow(get("data.presentation"), defaultTo(empty.object)),
  ],
  (pagetype, presentation) => presentation[pagetype] || empty.object
);

export const presentationSelector = createSelector(
  [presentationDataSelector],
  (presentation) => ({ ...presentation })
);
