import empty from "empty";
import {
  compact,
  concat,
  entries,
  filter,
  find,
  findIndex,
  flatten,
  flow,
  get,
  groupBy,
  has,
  head,
  identity,
  includes,
  intersection,
  join,
  keys,
  last,
  map,
  some,
  sortBy,
  union,
  uniq,
  uniqBy,
  values,
} from "lodash/fp";

import { Side } from "../../../business/relations";
import { flown, innerJoin, leftJoin } from "../../../lodash";
import {
  arrayFilterMap,
  arrayFlatMap,
  flatMapHead,
  mapValues,
} from "../../../utils";

export const filterEntiteitRelations = (pagClsIdt) =>
  filter(
    ({ right: { pageClusterId }, type: { alias } }) =>
      pageClusterId === pagClsIdt && alias === "entiteit_applicatiegegeven"
  );

export const filterKerngegevenRelations = (pagClsIdt) =>
  filter(
    ({ right: { pageClusterId }, type: { alias } }) =>
      pageClusterId === pagClsIdt && alias === "kerngegeven_applicatiegegeven"
  );

export const filterModuleRelations = (pagClsIdt) =>
  filter(
    ({ left: { pageClusterId }, type: { alias } }) =>
      pageClusterId === pagClsIdt && alias === "applicatieveld_module"
  );

export const filterZibRelations = (pagClsIdt) =>
  filter(
    ({ left: { pageClusterId }, type: { alias } }) =>
      pageClusterId === pagClsIdt && alias.indexOf("_zib_") > -1
  );

export const findVeldCluster =
  (pagClsIdt) =>
  ({ gegeven: { clusters: gegevens } = empty.object }) => {
    const mapping = (gegeven) => {
      const veld = flown(
        gegeven,
        get("veld.clusters"),
        find({ clusterId: pagClsIdt })
      );
      return veld ? { gegevenClusterId: gegeven.clusterId, veld } : undefined;
    };
    return flatMapHead(mapping)(gegevens);
  };

export const findField =
  (
    {
      id: itmIdt,
      gegevens = empty.array,
      entiteiten = empty.array,
    } = empty.object
  ) =>
  (pagClsIdt) => {
    const findGegevens = flatMapHead(({ id: parPagClsIdt, naam, velden }) => {
      const veld = find({ id: pagClsIdt })(velden);
      return veld
        ? { ...veld, itmIdt, pagClsIdt, parPagClsIdt, parentNaam: naam }
        : undefined;
    });

    const allGegevens = [
      ...gegevens,
      ...flow(map("gegevens"), flatten)(entiteiten),
    ];

    return findGegevens(allGegevens);
  };

export const koppelingIsReady = (koppelingFields, applications) =>
  has("from")(koppelingFields) &&
  has("to")(koppelingFields) &&
  has("from")(applications) &&
  has("to")(applications);

const relationsToApps = (koppelingFields, side) =>
  flow(
    map(`${side === Side.left ? "van" : "nar"}.pageClusterId`),
    uniq,
    map(findField(koppelingFields[side === Side.left ? "from" : "to"])),
    compact
  );

export const collapse = (
  { clusterId: gegevenClusterId },
  name,
  {
    plug: {
      koppelingFields,
      koppelingVeldenRelations,
      applications,
    } = empty.object,
  }
) => {
  if (!koppelingIsReady(koppelingFields, applications)) {
    return "Laden...";
  }

  if (!(gegevenClusterId in koppelingVeldenRelations)) {
    return name === "Gegeven" ? "Gegeven" : undefined;
  }

  const relationsPerRow = koppelingVeldenRelations[gegevenClusterId];

  const phrases = flown(
    relationsPerRow,
    groupBy(({ van: { pageClusterId } }) => pageClusterId),
    mapValues(map(({ nar: { pageClusterId } }) => pageClusterId)),
    entries,
    groupBy(flow(last, sortBy(identity), join(","))),
    entries,
    map(([_, mappings]) => {
      const fromNames = flown(
        mappings,
        map(head), // Gets the keys
        map(Number), // Converts back to numbers
        map(findField(koppelingFields.from)),
        map("naam")
      );
      const toNames = flown(
        mappings,
        head, // First row (all rows have equal values)
        last, // Gets the value
        map(findField(koppelingFields.to)),
        map("naam")
      );
      return `${fromNames.join(" + ")} \u00a0>\u00a0 ${toNames.join(" + ")}`;
    })
  );

  return flown(phrases, sortBy(identity), join(", "));
};

export const getUsedFields = (side) =>
  flow(
    values,
    flatten,
    map(`${side === Side.left ? "van" : "nar"}.pageClusterId`)
  );

export const alreadySelected = (koppelingVeldenRelations, selected) => {
  const leftPageClusterId = flown(selected, last, get("left.pagClsIdt"));
  const stored = flown(
    koppelingVeldenRelations,
    values,
    flatten,
    filter(({ van: { pageClusterId } }) => pageClusterId === leftPageClusterId),
    map(({ nar: { pageClusterId } }) => pageClusterId)
  );
  const current = flown(
    selected,
    filter(
      ({ left: { pagClsIdt }, right }) =>
        pagClsIdt === leftPageClusterId && right !== undefined
    ),
    map(({ right: { pagClsIdt } }) => pagClsIdt)
  );
  return concat(stored)(current);
};

export const entiteitNames = (app) => (veldId) => {
  const namesByVeldId = flown(
    app.relations,
    filter(
      (rel) =>
        rel.left.type === "entiteit" && rel.right.pageClusterId === veldId
    ),
    map("left.label")
  );
  if (namesByVeldId.length > 0) {
    return namesByVeldId;
  }

  const gegevenId = app.fields.gegevens.find(({ velden } = empty.array) =>
    some(({ id }) => id === veldId)(velden)
  )?.id;
  return flown(
    app.relations,
    filter(
      (rel) =>
        rel.left.type === "entiteit" && rel.right.pageClusterId === gegevenId
    ),
    map("left.label")
  );
};

export const gegevenNames = (app) => (veldId) => {
  return app.fields.gegevens
    .filter(({ velden } = empty.array) =>
      some(({ id }) => id === veldId)(velden)
    )
    .map((gegeven) => gegeven.naam);
};

export const getEntiteit = (
  cluster,
  koppelingFields,
  koppelingVeldenRelations,
  applications
) => {
  if (
    !has(cluster?.clusterId)(koppelingVeldenRelations) ||
    !koppelingIsReady(koppelingFields, applications)
  ) {
    return null;
  }

  const relations = koppelingVeldenRelations[cluster.clusterId];
  const vanNames = flown(
    relations,
    map("van.pageClusterId"),
    map(entiteitNames(applications.from)),
    flatten,
    uniq
  );
  const narNames = flown(
    relations,
    map("nar.pageClusterId"),
    map(entiteitNames(applications.to)),
    flatten,
    uniq
  );
  const all = union(vanNames, narNames);
  switch (all.length) {
    case 0:
      return "Geen entiteit";
    case 1:
      return `  ${all[0]}`;
    default:
      break;
  }

  const distinct = intersection(vanNames, narNames);
  switch (distinct.length) {
    case 1:
      return `  ${distinct[0]}`;
    default:
      return " Meerdere entiteiten";
  }
};

export const getEntiteitLeft = (
  cluster,
  koppelingFields,
  koppelingVeldenRelations,
  applications
) => {
  if (
    !has(cluster?.clusterId)(koppelingVeldenRelations) ||
    !koppelingIsReady(koppelingFields, applications)
  ) {
    return null;
  }

  const relations = koppelingVeldenRelations[cluster.clusterId];
  const vanNames = flown(
    relations,
    map("van.pageClusterId"),
    map(entiteitNames(applications.from)),
    flatten,
    uniq
  );
  switch (vanNames.length) {
    case 0:
      return "Geen entiteit";
    case 1:
      return `  ${vanNames[0]}`;
    default:
      return " Meerdere entiteiten";
  }
};

export const getEntiteitRight = (
  cluster,
  koppelingFields,
  koppelingVeldenRelations,
  applications
) => {
  if (
    !has(cluster?.clusterId)(koppelingVeldenRelations) ||
    !koppelingIsReady(koppelingFields, applications)
  ) {
    return null;
  }

  const relations = koppelingVeldenRelations[cluster.clusterId];
  const narNames = flown(
    relations,
    map("nar.pageClusterId"),
    map(entiteitNames(applications.to)),
    flatten,
    uniq
  );
  switch (narNames.length) {
    case 0:
      return "Geen entiteit";
    case 1:
      return `  ${narNames[0]}`;
    default:
      return " Meerdere entiteiten";
  }
};

export const getGegevenLeft = (
  cluster,
  koppelingFields,
  koppelingVeldenRelations,
  applications
) => {
  if (
    !has(cluster?.clusterId)(koppelingVeldenRelations) ||
    !koppelingIsReady(koppelingFields, applications)
  ) {
    return null;
  }

  const relations = koppelingVeldenRelations[cluster.clusterId];
  const vanNames = flown(
    relations,
    map("van.pageClusterId"),
    map(gegevenNames(applications.from)),
    flatten,
    uniq
  );
  switch (vanNames.length) {
    case 0:
      return "Geen applicatiegegeven";
    case 1:
      return `  ${vanNames[0]}`;
    default:
      return " Meerdere applicatiegegevens";
  }
};

export const getGegevenRight = (
  cluster,
  koppelingFields,
  koppelingVeldenRelations,
  applications
) => {
  if (
    !has(cluster?.clusterId)(koppelingVeldenRelations) ||
    !koppelingIsReady(koppelingFields, applications)
  ) {
    return null;
  }

  const relations = koppelingVeldenRelations[cluster.clusterId];
  const narNames = flown(
    relations,
    map("nar.pageClusterId"),
    map(gegevenNames(applications.to)),
    flatten,
    uniq
  );
  switch (narNames.length) {
    case 0:
      return "Geen applicatiegegeven";
    case 1:
      return `  ${narNames[0]}`;
    default:
      return " Meerdere applicatiegegevens";
  }
};

export const renderStartProps = (
  { clusterId: gegevenClusterId },
  name,
  {
    plug: {
      koppelingFields,
      koppelingVeldenRelations,
      applications,
    } = empty.object,
  }
) => {
  if (
    !has(gegevenClusterId)(koppelingVeldenRelations) ||
    !koppelingIsReady(koppelingFields, applications)
  ) {
    return null;
  }

  const relationsPerRow = get(gegevenClusterId)(koppelingVeldenRelations);
  const fromApps = flown(
    relationsPerRow,
    relationsToApps(koppelingFields, Side.left)
  );
  const toApps = flown(
    relationsPerRow,
    relationsToApps(koppelingFields, Side.right)
  );

  return { applications, fromApps, toApps };
};

/**
 * Filter "gegevens" and "velden" by "entiteit"
 */
export const filterByEntiteit = ({
  page,
  relations,
  fields: { gegevens = empty.array, ...restFields } = empty.object,
}) => {
  const entiteitRelations = filter((r) => r.left.type === "entiteit")(
    relations
  );

  const join = leftJoin(
    (g) => g.id,
    entiteitRelations,
    (r) => r.right.pageClusterId
  )(gegevens);

  const entiteiten = flow(
    map(([_, { left } = empty.object]) => left),
    filter(empty.functionThatReturnsArgument),
    uniqBy(({ itemId }) => itemId),
    sortBy(({ label }) => label)
  )(join);

  const entiteitGroup = flow(
    map(([{ id }, { left: { itemId } = empty.object } = empty.object]) => ({
      itemId: itemId ?? -1,
      clusterId: id,
    })),
    groupBy("itemId"),
    mapValues(map("clusterId"))
  )(join);

  const entiteitVeldGrouping = flow(
    map(([{ id }, { left: { itemId } = empty.object } = empty.object]) => ({
      itemId: itemId ?? -1,
      clusterId: id,
    })),
    groupBy("itemId"),
    mapValues(map("clusterId"))
  );

  return {
    fields: {
      ...restFields,
      entiteiten: flow(
        keys,
        map(Number),
        sortBy(
          (groupKey) => findIndex({ itemId: groupKey })(entiteiten) ?? groupKey
        ),
        map((groupKey) => {
          const { itemId = groupKey, label } =
            find({ itemId: groupKey })(entiteiten) ?? empty.object;
          return {
            entiteitId: itemId,
            entiteit: label,
            gegevens: flow(
              filter(({ id }) => includes(id)(entiteitGroup[itemId])),
              map(({ id, naam, velden }) => {
                // filter `cluster.veld.clusters[]`
                const joinVelden = leftJoin(
                  (v) => v.id,
                  entiteitRelations,
                  (r) => r.right.pageClusterId
                )(velden);
                const group = entiteitVeldGrouping(joinVelden);
                const filteredVelden = filter(({ id }) =>
                  includes(id)(group[itemId])
                )(velden);

                return filteredVelden.length > 0
                  ? {
                      id,
                      naam,
                      velden: filteredVelden,
                    }
                  : null;
              }),
              compact
            )(gegevens),
          };
        })
      )(entiteitGroup),
    },
    page,
    relations,
  };
};

const lowExp = (search) => {
  const lower = search.toLowerCase();
  return {
    test: (s = "") => s.toLowerCase().includes(lower),
  };
};

export const searchInVelden = ({ fields, ...rest }, keyword) => {
  const regexp = lowExp(keyword);

  const naamFilter = (item) => {
    const { entiteit = "Geen entiteit", naam = entiteit, ...rest } = item;
    if (regexp.test(naam)) {
      return item;
    }

    const entry = flown(
      rest,
      entries,
      find(([, arr]) => Array.isArray(arr))
    );
    if (!entry) {
      return undefined;
    }

    const [key, arr] = entry;
    const filtered = arrayFilterMap(naamFilter)(arr);
    return filtered.length > 0 ? { ...item, [key]: filtered } : undefined;
  };

  const { entiteiten, gegevens } = fields;
  const filteredFields = {
    ...fields,
    entiteiten: entiteiten && arrayFilterMap(naamFilter)(entiteiten),
    gegevens: gegevens && arrayFilterMap(naamFilter)(gegevens),
  };

  return { fields: filteredFields, ...rest };
};

const getSet = (relations, side, ...types) =>
  new Set(
    flown(
      relations,
      filter(([r]) => types.includes(get(side)(r)?.type)),
      map(([_, [clusterId]]) => clusterId)
    )
  );

export const getSets = (
  applications,
  koppelingVeldenRelations,
  vanNar,
  itemId
) => {
  const fromTo = vanNar === "van" ? "from" : "to";
  const clusterVeldIds = flown(
    koppelingVeldenRelations,
    entries,
    arrayFlatMap(([key, value]) =>
      value.map((r) => [Number(key), r[vanNar].pageClusterId])
    )
  );
  const gegevenVeldIds = flown(
    applications?.[fromTo]?.fields?.gegevens ?? empty.array,
    arrayFlatMap(({ id: gegevenId, velden }) =>
      velden.map(({ id: veldId }) => [gegevenId, veldId])
    )
  );
  const clusterGegevenIds = flown(
    clusterVeldIds,
    innerJoin(last, gegevenVeldIds, last),
    map(([[clusterId], [gegevenId]]) => [clusterId, gegevenId])
  );
  const clusterAllIds = concat(clusterVeldIds)(clusterGegevenIds);

  const relations = flown(
    applications?.[fromTo]?.relations ?? empty.array,
    filter((r) => r.using?.[0]?.itemId !== itemId)
  );

  const relationsLeft = flown(
    relations,
    innerJoin((r) => r.left.pageClusterId, clusterAllIds, last)
  );
  const relationsRight = flown(
    relations,
    innerJoin((r) => r.right.pageClusterId, clusterAllIds, last)
  );

  const hasModule = getSet(relationsLeft, "right", "module");
  const hasZib = getSet(relationsLeft, "right", "zib");
  const hasVerantwoording = getSet(relationsLeft, "right", "verantwoording");
  const hasVerwerking = getSet(
    relationsRight,
    "left",
    "activiteit",
    "bedrijfsproces"
  );
  const hasEntiteit = getSet(relationsRight, "left", "entiteit");
  const hasKoppeling = getSet(
    vanNar === "van" ? relationsLeft : relationsRight,
    "using[0]",
    "koppeling"
  );

  return [
    hasModule,
    hasZib,
    hasVerantwoording,
    hasVerwerking,
    hasEntiteit,
    hasKoppeling,
  ];
};

export const getSubName = (veldenRelations, dir, getNames, single, many) => {
  const nameLeft = flown(
    veldenRelations,
    arrayFlatMap(({ [dir]: { pageClusterId } }) => getNames(pageClusterId)),
    uniq,
    sortBy(identity)
  );
  switch (nameLeft.length) {
    case 0:
      return `Geen ${single}`;
    case 1:
      return nameLeft[0];
    default:
      return `Meerdere ${many}`;
  }
};
