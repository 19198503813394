import { entries, flow, map } from "lodash/fp";
import { RaisedButton } from "material-ui";
import PropTypes from "prop-types";
import React from "react";
import { CSSTransition } from "react-transition-group";

import { readOnlyPageActions } from "../../business/models";
import { pageActionsShape } from "../../business/prop-types";
import { relationMultiple, relationRequired } from "../../business/relations";
import { structuredMap, takeIf } from "../../utils";
import Subheader from "../material/Subheader";
import RelationMetaView from "./relationMetaView";

const RelationMetaList = ({
  type,
  side,
  itemId,
  relations,
  initAddRelation,
  edit,
  label,
  pageActions,
  /* eslint-disable react/prop-types */
  maxLength,
  all,
  selectedSaar,
  labelRender,
  removeRelation,
  biebStructureIds,
  /* eslint-enable react/prop-types */
}) => (
  <div className="relation-meta-list">
    <Subheader>{label}</Subheader>
    {flow(
      takeIf(maxLength - 1, !all && relations.length > maxLength),
      entries,
      map(([nthChild, relation]) => (
        <CSSTransition
          key={`rel-${relation.item.itemId}-${
            relation.item.pageClusterId || 0
          }`}
          classNames="relations"
          timeout={{ enter: 500, exit: 200 }}
        >
          <RelationMetaView
            label={label}
            relation={
              type.forceMiniSaar
                ? structuredMap({
                    item: (item) => ({ miniSaar: selectedSaar, ...item }),
                  })(relation)
                : relation
            }
            labelRender={labelRender}
            removeRelation={removeRelation}
            clusterFirst={type.clusterFirst}
            biebStructureIds={biebStructureIds}
            pageActions={edit ? pageActions : readOnlyPageActions}
            nthChild={parseInt(nthChild, 10)}
          />
        </CSSTransition>
      ))
    )(relations)}

    {type.canExist &&
      type.mayAdd &&
      (relationRequired(type.rule) ||
        (edit && (relations.length === 0 || relationMultiple(type.rule)))) && (
        <div
          className="add-button-wrap"
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: 16,
          }}
        >
          <RaisedButton
            label={`${label} toevoegen`}
            onClick={() => initAddRelation(type, side, itemId)}
            secondary
          />
        </div>
      )}
  </div>
);

RelationMetaList.propTypes = Object.freeze({
  // eslint-disable-next-line react/forbid-prop-types
  type: PropTypes.shape({}).isRequired,
  side: PropTypes.number.isRequired,
  itemId: PropTypes.number.isRequired,
  relations: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
  initAddRelation: PropTypes.func.isRequired,
  edit: PropTypes.bool,
  label: PropTypes.string.isRequired,
  pageActions: pageActionsShape.isRequired,
});

export default RelationMetaList;
