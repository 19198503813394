/* eslint  react/prop-types: off */
import React from "react";

import style from ".";

const BasecampIcon = ({ style }) => (
  <svg version="1.1" x="0px" y="0px" viewBox="0 0 126 105" style={style}>
    <g>
      <g>
        <path
          style={{ fill: "#BADCF4" }}
          d="M63,0C29.1,0,2.5,43.2,0,79.6C10.6,98.1,36.3,105,63,105c26.7,0,52.4-6.9,63-25.4C123.5,43.2,96.9,0,63,0z"
        />
        <path
          style={{ fill: "#70C167" }}
          d="M102,45.3c-7.4-9.6-16.9-19.7-22.4-19.7c-5.5,0-20.9,27.2-27.9,27.2c-7.1,0-10.7-12.4-19.4-12.3
        C20.5,40.5,8.9,75.2,8.9,75.2s4.8,21.2,54.9,21.2c50.1,0,54.1-20.9,54.1-20.9S112.7,59.1,102,45.3z"
        />
        <g>
          <path
            style={{ fill: "#1BAD4B" }}
            d="M52.5,64.4c-7.8,0-11.8-7.7-13.9-12.4c-1.9-4.3-2.3-11-7.8-11.2c0.5-0.2,1.1-0.3,1.6-0.3
          c8.7,0,12.3,12.3,19.4,12.3c7.1,0,22.4-27.2,27.9-27.2c1,0,2.2,0.4,3.5,1c-0.9-0.1-2.1,0-3.1,0.9C78.2,29.2,62.1,64.4,52.5,64.4z
          "
          />
        </g>
      </g>
      <g>
        <path
          style={{ fill: "#010101" }}
          d="M36.5,73c0,3.4,2.8,6.1,6.1,6.1s6.1-2.8,6.1-6.1c0-3.4-2.8-6.1-6.1-6.1S36.5,69.6,36.5,73"
        />
        <path
          style={{ fill: "#010101" }}
          d="M77.2,73c0,3.4,2.8,6.1,6.1,6.1s6.1-2.8,6.1-6.1c0-3.4-2.8-6.1-6.1-6.1S77.2,69.6,77.2,73"
        />
      </g>
      <path
        style={{ fill: "#010101" }}
        d="M63.2,89.2c-8.8,0-12.8-6.4-13-6.7c-0.5-0.9-0.3-2,0.6-2.5c0.9-0.5,2-0.3,2.5,0.6l0,0c0.1,0.2,3.2,4.9,9.8,4.9
      c6.9,0,9.4-4.8,9.5-4.8c0.5-0.9,1.6-1.2,2.5-0.8c0.9,0.5,1.2,1.6,0.8,2.5C75.7,82.7,72.3,89.2,63.2,89.2z"
      />
    </g>
  </svg>
);

const Basecamp = ({
  className,
  title = "Open Basecamp",
  label = "Basecamp",
  id,
}) =>
  id ? (
    <a
      className={className}
      target="_blank"
      rel="noopener noreferrer"
      title={title}
      href={`https://basecamp.com/1833994/projects/${id}`}
      style={style.iconLink}
    >
      <BasecampIcon style={style.iconSvg} />
      <span style={style.iconTitle}>{label}</span>
    </a>
  ) : null;

export default Basecamp;
