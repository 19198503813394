/* eslint  react/prop-types: off */

import classNames from "classnames";
import { filter, findIndex, get, nth } from "lodash/fp";
import { Tabs as NativeTabs, Tab } from "material-ui/Tabs";
import React, { useEffect, useState } from "react";

import { flown } from "../../lodash";
import { isNonEmptyTab } from "../../selectors/tabs";
import { nullIfEmpty } from "../../utils";
import ResponsiveWrapper from "./ResponsiveWrapper";
import TabContent from "./TabContent";

export const TabTemplate = ({ children, selected, style }) => {
  const templateStyle = {
    position: "relative",
    textAlign: "initial",
    width: "100%",
    ...style,
  };

  if (!selected) {
    templateStyle.height = 0;
    templateStyle.overflow = "hidden";
  } else {
    templateStyle.marginTop = "16px";
  }

  return (
    <div className="tabs-wrapper" style={templateStyle}>
      {children}
    </div>
  );
};

const renderTab = (tab, firstTabChild, value, visible, selected) => {
  const { label, className, children, toc } = tab;
  return (
    <Tab
      key={value}
      buttonStyle={{ textTransform: "none" }}
      label={
        <TabContent
          enabled={Boolean(visible && isNonEmptyTab(tab) && selected)}
          label={label}
          content={toc}
        />
      }
      value={value}
      className={classNames(className, "tab", {
        empty: !isNonEmptyTab(tab),
        selected,
      })}
    >
      {visible && firstTabChild}
      {visible && nullIfEmpty(children)}
    </Tab>
  );
};

export const Tabs = ({
  firstTabChild,
  onChange,
  tabs,
  value,
  keepRendered = false,
  ...other
}) => {
  // Determine the non-empty tabs
  const alias = flown(tabs, nth(value), get("alias"));
  const nonEmptyTabs = filter(isNonEmptyTab)(tabs);
  const className = classNames(
    other.className,
    "tabs",
    `tabs_${nonEmptyTabs.length}`,
    `tab_${findIndex({ alias })(nonEmptyTabs)}`,
    { "tabs-single": nonEmptyTabs.length === 1 }
  );

  // Manage the visible tabs
  const [visible, setVisible] = useState({ [value]: true });
  const changeTab = () => {
    if (!isNonEmptyTab(tabs[value])) {
      const nonEmptyValue = findIndex(isNonEmptyTab)(tabs);
      if (nonEmptyValue >= 0) {
        onChange(nonEmptyValue);
      }
    }
    if (!visible[value]) {
      setVisible(
        keepRendered ? { ...visible, [value]: true } : { [value]: true }
      );
    }
  };
  useEffect(changeTab, [keepRendered, onChange, tabs, value, visible]);

  return (
    <ResponsiveWrapper>
      <NativeTabs
        {...other}
        className={className}
        onChange={(e) => onChange(e)}
        value={value}
      >
        {tabs.map((tab, i) =>
          renderTab(tab, firstTabChild, i, visible[i], value === i)
        )}
      </NativeTabs>
    </ResponsiveWrapper>
  );
};
