import {
  AppInsightsContext,
  useAppInsightsContext,
  useTrackMetric,
} from "@microsoft/applicationinsights-react-js";
import React, { PropsWithChildren, memo, useEffect, useState } from "react";

import { ai, emptyInstrumentationKey } from "./service";

export interface TelemetryProviderProps {
  instrumentationKey: string;
}

const Tracked = ({ children }: PropsWithChildren<{}>) => {
  const appInsights = useAppInsightsContext();
  useTrackMetric(appInsights, "Tracked");

  return <>{children}</>;
};

const TelemetryProvider = ({
  instrumentationKey,
  children,
}: PropsWithChildren<TelemetryProviderProps>): JSX.Element => {
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (
      !initialized &&
      Boolean(instrumentationKey) &&
      instrumentationKey !== emptyInstrumentationKey
    ) {
      ai.initialize(instrumentationKey);
      setInitialized(true);
    }
  }, [initialized, instrumentationKey]);

  if (!initialized || !instrumentationKey || !ai.reactPlugin) {
    return <>{children}</>;
  }

  return (
    <AppInsightsContext.Provider value={ai.reactPlugin}>
      <Tracked>{children}</Tracked>
    </AppInsightsContext.Provider>
  );
};

export default memo(TelemetryProvider);
