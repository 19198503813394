import { loadingPresent } from "async-lifecycle";
import empty from "empty";
import {
  debounce,
  defaultTo,
  entries,
  eq,
  every,
  filter,
  flow,
  fromPairs,
  get,
  includes,
  map,
  reject,
  some,
  values,
} from "lodash/fp";
import {
  branch,
  compose,
  renderNothing,
  setDisplayName,
  withHandlers,
  withProps,
  withState,
} from "recompose";
import { createSelector } from "reselect";

import { flown } from "../lodash";
import { execOnChange } from "../recompose.contrib";
import { drop, isEmpty } from "../utils";
import ItemDialog from "./components/itemDialog";

const lazyFind = debounce(500)((find, label, pagetype) =>
  find(label, pagetype)
);

const validate = (definition, title, fields) =>
  title !== "" &&
  flown(
    definition,
    values,
    filter(get("definition.required")),
    every(({ definition: { name: key } }) => flown(fields, some({ name: key })))
  );

const fieldsFromDefinition = createSelector(
  [
    flow(get("fieldDefinitionsByPagetype"), defaultTo(empty.object)),
    get("newItem.pagetype"),
  ],
  (definitions, pagetypeAlias) => ({
    fields: flown(
      flow(get(`${pagetypeAlias}.page`), defaultTo(empty.object))(definitions),
      entries,
      filter(flow(get("[1].iproxType"), eq("field"))),
      filter(get("[1].definition.required")),
      fromPairs
    ),
    loadingFields: !loadingPresent(
      flow(
        get(`${pagetypeAlias}.loading`),
        defaultTo(empty.object)
      )(definitions)
    ),
  })
);

export default compose(
  setDisplayName("ItemDialog"),
  branch(({ open }) => !open, renderNothing),
  withState(
    "newItem",
    "updateItem",
    ({
      initialPagetype,
      pagetypes = empty.array,
      structures = empty.array,
    }) => ({
      label: "",
      pagetype: some({ alias: initialPagetype })(pagetypes)
        ? initialPagetype
        : "",
      structureId: structures[0] ? structures[0].id : undefined,
    })
  ),
  withState("sent", "updateSent", false),
  withState("stepIndex", "updateStep", 0),
  withHandlers({
    previousStep:
      ({ stepIndex, updateStep }) =>
      () =>
        updateStep(stepIndex - 1),
    nextStep:
      ({ stepIndex, updateStep }) =>
      () =>
        updateStep(stepIndex + 1),
  }),
  withProps(
    ({ legoblokStructures, newItem: { structureId } = empty.object }) => ({
      lazyFind,
      isBiebStructure: includes(structureId)(map("id")(legoblokStructures)),
    })
  ),
  withHandlers({
    updateLabel:
      ({
        pagetypes = empty.array,
        newItem,
        updateItem,
        find = empty.func,
        lazyFind,
      }) =>
      (event, label) => {
        lazyFind.cancel();
        updateItem({
          ...newItem,
          label,
          valid: !isEmpty(label) && !isEmpty(newItem.pagetype),
        });
        if (
          !isEmpty(label) &&
          pagetypes.find(({ alias }) => alias === newItem.pagetype)
        ) {
          lazyFind(find, label, newItem.pagetype);
        }
      },

    updatePagetype:
      ({
        pagetypes = empty.array,
        newItem,
        updateItem,
        find = empty.func,
        lazyFind,
        requireDefinition,
      }) =>
      (event, index, value) => {
        lazyFind.cancel();
        updateItem({
          ...newItem,
          extraAction: empty.object,
          pagetype: value,
          valid: !isEmpty(newItem.label) && !isEmpty(value),
        });
        requireDefinition(value);
        if (
          !isEmpty(newItem.label) &&
          pagetypes.find(({ alias }) => alias === value)
        ) {
          lazyFind(find, newItem.label, value);
        }
      },

    updateStructureId:
      ({ newItem, updateItem }) =>
      (_event, _index, structureId) => {
        updateItem(
          structureId
            ? {
                ...newItem,
                structureId,
              }
            : drop(newItem, "structureId")
        );
      },

    updateExternCheckbox:
      ({ newItem, updateItem }) =>
      (_event, checked) => {
        if (newItem.pagetype !== "applicatie") {
          return;
        }

        const action = {
          $type:
            "InfoZorgSAAR.Iprox.ContentActions.ApplicatieAction, SAAR.Iprox",
          externalApplication: checked,
        };

        updateItem({
          ...newItem,
          extraAction: action,
          validExtras: true,
        });
      },
  }),
  execOnChange("reset"),
  execOnChange(({ initialPagetype, requireDefinition, open }) => {
    if (open && initialPagetype) {
      requireDefinition(initialPagetype);
    }
  }, "initialPagetype"),
  withProps(fieldsFromDefinition),
  withHandlers({
    fieldAdd:
      ({ fields: definition, newItem, updateItem }) =>
      ({ name, value }) => {
        const rootFields = flown(newItem.rootFields, reject({ name }));
        if (value && value !== "<div></div>") {
          rootFields.push({
            name,
            value,
          });
        }

        updateItem({
          ...newItem,
          rootFields,
          validFields: validate(definition, newItem.title, rootFields),
        });
      },

    fieldRevert:
      ({ fields: definition, newItem, updateItem }) =>
      ({ name }) => {
        const rootFields = flown(newItem.rootFields, reject({ name }));
        updateItem({
          ...newItem,
          rootFields,
          validFields: validate(definition, newItem.title, rootFields),
        });
      },
  })
)(ItemDialog);
