/* eslint  react/prop-types: off */
import Badge from "material-ui/Badge";
import React from "react";

const ItemNotesBadge = ({ counter = 0, children }) =>
  counter > 0 ? (
    <Badge
      badgeContent={counter}
      style={{ padding: 0, display: "inline-flex", placeContent: "center" }}
      badgeStyle={{
        display: "inline-flex",
        position: "unset",
        top: "unset",
        right: "unset",
        fontSize: 8,
        width: 16,
        minWidth: 16,
        height: 16,
        marginLeft: 8,
      }}
    >
      <span>{children}</span>
    </Badge>
  ) : (
    <span>{children}</span>
  );

export default ItemNotesBadge;
