import classNames from "classnames";
import IconButton from "material-ui/IconButton";
import LinkIcon from "material-ui/svg-icons/content/link";
import { MouseEvent, memo, useCallback } from "react";
import { useDispatch } from "react-redux";

import { snackbarShowAction } from "../../actions/ui";

const clusterIdRegExp = /clusterId=(\d+)/;

const CopyClusterLink = ({
  clusterId,
  absolute,
}: {
  clusterId: number;
  absolute?: boolean;
}) => {
  const dispatch = useDispatch();
  const handleCopy = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();

      const currentUrl = `${window.location.href}`;
      const seperator = currentUrl.includes("?") ? "&" : "?";
      const withClusterId = clusterIdRegExp.test(currentUrl)
        ? currentUrl.replace(clusterIdRegExp, `clusterId=${clusterId}`)
        : `${currentUrl}${seperator}clusterId=${clusterId}`;
      navigator.clipboard.writeText(withClusterId);
      dispatch(snackbarShowAction("Link naar cluster gekopieerd"));
    },
    [clusterId, dispatch]
  );

  return (
    <span className={classNames("copy-cluster-link", { absolute })}>
      <IconButton
        onClick={handleCopy}
        style={{
          border: 0,
          padding: 0,
          margin: 0,
          width: 24,
          height: 24,
        }}
        title="Link naar cluster kopiëren"
      >
        <LinkIcon />
      </IconButton>
    </span>
  );
};

export default memo(CopyClusterLink);
