/* eslint  react/prop-types: off */
import { loadingPresent } from "async-lifecycle";
import empty from "empty";
import { filter } from "lodash/fp";
import React from "react";
import { compose, mapProps, pure, setDisplayName } from "recompose";

import {
  Side,
  relationDisplayByRelationSide,
} from "../../../../business/relations";
import { RelationListWrapper, RelationsList } from "../../../relations";

const VerwerkerRelationsComponent = ({ itemId, relations, miniSaars }) =>
  relations && relations.length > 0 ? (
    <RelationListWrapper sideBarList>
      <RelationsList
        itemId={itemId}
        type="entiteit_verwerker"
        side={1}
        label="Externe verwerker"
        list={relations}
        miniSaars={miniSaars}
        showHeader
      />
    </RelationListWrapper>
  ) : null;

const VerwerkerRelations = compose(
  setDisplayName("VerwerkerRelations"),
  pure,
  mapProps(({ chainRelations = empty.object, ...rest }) => {
    const relations = chainRelations
      ? relationDisplayByRelationSide(
          filter(
            ({ right: { type } = empty.object }) => type === "leverancier"
          )(chainRelations.relations),
          false,
          loadingPresent(chainRelations.loading)
        )({ side: Side.left })
      : undefined;

    return {
      ...rest,
      relations: relations && relations.links ? relations.links : undefined,
    };
  })
)(VerwerkerRelationsComponent);

const renderVerwerkerRelations = ({ itemId, chainRelations, miniSaars }) => (
  <VerwerkerRelations
    key="verwerker-rels"
    itemId={itemId}
    chainRelations={chainRelations}
    miniSaars={miniSaars}
  />
);

export default renderVerwerkerRelations;
