import empty from "empty";
import { defaultTo, flow, groupBy, map, values } from "lodash/fp";

import { flown } from "../lodash";

export const groupFilters = (filter, selectionLists) => {
  if (filter.length === 0 || !selectionLists?.length) {
    return empty.array;
  }

  return flown(
    filter,
    groupBy((item) => {
      const [prefix, value] = item.split(":");
      switch (prefix) {
        case "sel":
          const id = Number(value);
          const listId = selectionLists.find((list) =>
            list.items.some((item) => item.id === id)
          )?.id;
          return `lst:${listId}`;
        case "lst":
          return item;
        default:
          return prefix;
      }
    }),
    values,
    defaultTo(empty.array)
  );
};

const directFilterValue = (prefix) => (value) => `${prefix}:${value}`;

const propertyFilterValue =
  (prefix, propertyName) =>
  ({ [propertyName]: propertyValue }) =>
    `${prefix}:${propertyValue}`;

export const filterValues = (prefix, propertyName) =>
  flow(
    defaultTo(empty.array),
    map(
      propertyName
        ? propertyFilterValue(prefix, propertyName)
        : directFilterValue(prefix)
    )
  );
