import empty from "empty";
import { connect } from "react-redux";
import { compose, pure, setDisplayName } from "recompose";

import { recentlyCreatedAction } from "../../actions/dashboard";
import Dashboard from "../../components/pagetypes/dashboard";
import { execOnChange } from "../../recompose.contrib";

export default compose(
  setDisplayName("Dashboard"),
  pure,
  connect(
    ({
      data: {
        navigation: { footerLinks = empty.array } = empty.object,
        recentlyCreated: { items = empty.array } = empty.object,
      } = empty.object,
      session: { miniSaar },
    }) => ({
      recentlyCreated: items,
      miniSaar,
      alles: footerLinks.find(({ alias }) => alias === "alles"),
    }),
    (dispatch) => ({
      init: () => dispatch(recentlyCreatedAction()),
    })
  ),
  execOnChange("init")
)(Dashboard);
