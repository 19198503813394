import empty from "empty";
import { find, get } from "lodash/fp";
import Checkbox from "material-ui/Checkbox";
import Paper from "material-ui/Paper";
import { Step, StepButton, StepContent, Stepper } from "material-ui/Stepper";
import TextField from "material-ui/TextField";
import PropTypes from "prop-types";
import React from "react";
import { compose, setDisplayName, withHandlers, withProps } from "recompose";

import {
  clusterWithFieldsShape,
  relationTypeSideShape,
} from "../../business/prop-types";
import MultiSelect from "./multiSelect";
import OverzichtStepButtons from "./overzichtStepButtons";
import PagetypeSelect from "./pagetypeSelect";
import { overviewFormShape, pagetypeListItemShape } from "./shapes";

const OverzichtForm = ({
  fields,
  relations,
  pagetypeList,
  form,
  form: { currentStep, pagetype, title, all = false },
  handleSubmit,
  handleReset,
  handlePagetype,
  handleStep,
  handleName,
  handleAll,
  handleFields,
  handleRelationTypes,
  validStep1,
  validStep2,
  loading,
  showAll,
}) => (
  <Paper className="page-paper-title" style={{ padding: "16px" }}>
    <Stepper activeStep={currentStep} linear={false} orientation="vertical">
      <Step>
        <StepButton onClick={() => handleStep(0)}>
          <span>Kies een paginatype</span>
          {pagetype && (
            <span>
              {": "}
              {get("name")(find({ alias: pagetype })(pagetypeList))}
            </span>
          )}
        </StepButton>
        <StepContent>
          <PagetypeSelect
            onChange={handlePagetype}
            value={pagetype}
            pagetypeList={pagetypeList}
            disabled={loading}
          />
          {showAll && (
            <Checkbox
              checked={all}
              onCheck={handleAll}
              label="Pagina's van alle Saars"
            />
          )}
          <OverzichtStepButtons
            step={0}
            enabled={pagetype !== "" && !loading}
            stepChange={handleStep}
          />
        </StepContent>
      </Step>
      <Step disabled={!validStep1}>
        <StepButton onClick={() => handleStep(1)}>
          <span>Naam voor het overzicht</span>
          {title && (
            <span>
              {": "}
              {title}
            </span>
          )}
        </StepButton>
        <StepContent>
          <TextField
            fullWidth
            hintText="Naam"
            floatingLabelText="Naam"
            value={title}
            onChange={handleName}
            disabled={loading}
          />
          <OverzichtStepButtons
            step={1}
            enabled={validStep1 && validStep2 && !loading}
            stepChange={handleStep}
          />
        </StepContent>
      </Step>
      <Step disabled={!(validStep1 && validStep2 && fields.length > 0)}>
        <StepButton onClick={() => handleStep(2)}>
          <span>Kies de velden</span>
          {form.fields.length > 0 && (
            <span>
              {": "}
              {form.fields.length} veld(en) geselecteerd
            </span>
          )}
        </StepButton>
        <StepContent>
          <MultiSelect
            label="Velden"
            onChange={handleFields}
            values={form.fields}
            list={fields}
            disabled={loading}
          />
          <OverzichtStepButtons
            step={2}
            enabled={validStep1 && validStep2 && !loading}
            stepChange={handleStep}
          />
        </StepContent>
      </Step>
      <Step disabled={!(validStep1 && validStep2)}>
        <StepButton onClick={() => handleStep(3)}>
          <span>Kies de relatietypen</span>
          {form.relations.length > 0 && (
            <span>
              {": "}
              {form.relations.length} relatie(s) geselecteerd
            </span>
          )}
        </StepButton>
        <StepContent>
          <MultiSelect
            label="Relatie typen"
            onChange={handleRelationTypes}
            values={form.relations}
            list={relations}
            disabled={loading}
          />
          <OverzichtStepButtons
            step={3}
            enabled={validStep1 && validStep2 && !loading}
            stepChange={handleStep}
            submit
            onSubmit={handleSubmit}
            onReset={handleReset}
            loading={loading}
          />
        </StepContent>
      </Step>
    </Stepper>
  </Paper>
);

OverzichtForm.propTypes = {
  fields: PropTypes.arrayOf(clusterWithFieldsShape.isRequired).isRequired,
  relations: PropTypes.arrayOf(relationTypeSideShape.isRequired).isRequired,
  pagetypeList: PropTypes.arrayOf(pagetypeListItemShape.isRequired).isRequired,
  form: overviewFormShape.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired,
  handlePagetype: PropTypes.func.isRequired,
  handleStep: PropTypes.func.isRequired,
  handleName: PropTypes.func.isRequired,
  handleFields: PropTypes.func.isRequired,
  handleRelationTypes: PropTypes.func.isRequired,
  validStep1: PropTypes.bool,
  validStep2: PropTypes.bool,
  loading: PropTypes.bool,
};

export default compose(
  setDisplayName("OverzichtForm"),
  withHandlers({
    handleSubmit: ({ exportOverzicht }) => exportOverzicht,

    handleReset: ({ resetOverzicht }) => resetOverzicht,

    handlePagetype:
      ({ update, form }) =>
      (pagetype) =>
        update({
          ...form,
          pagetype,
          fields: empty.array,
          relations: empty.array,
        }),

    handleName:
      ({ update, form }) =>
      (_, value) =>
        update({ ...form, title: value }),

    handleAll:
      ({ update, form }) =>
      (_, value) =>
        update({ ...form, all: value }),

    handleFields:
      ({ update, form }) =>
      (fields) =>
        update({ ...form, fields }),

    handleRelationTypes:
      ({ update, form }) =>
      (relations) =>
        update({ ...form, relations }),

    handleStep:
      ({ update, form }) =>
      (currentStep) =>
        update({ ...form, currentStep }),
  }),
  withProps(({ form: { pagetype, title } = empty.object }) => ({
    validStep1: pagetype !== undefined && pagetype !== "",
    validStep2: title !== undefined && title !== "",
  }))
)(OverzichtForm);
