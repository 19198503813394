import empty from "empty";
import { defaultTo, eq, flow, get } from "lodash/fp";
import { withRouter } from "react-router";
import { compose, setDisplayName, withHandlers, withState } from "recompose";
import { createSelector } from "reselect";

import { addToBiebDialogAction } from "../actions/bieb";
import {
  deleteItemAction,
  findBiebItemsAction,
  linkItemAction,
  unlinkItemAction,
} from "../actions/cms";
import { updateMiniSaarAction } from "../actions/data";
import { initializeCreateLegoblok } from "../actions/legoblok";
import { requireSearchScope } from "../actions/search";
import { logoutAction } from "../actions/session";
import {
  setVersionDiffAction,
  showVersionsDrawerAction,
  updateSecondaryDrawerAction,
} from "../actions/ui";
import { isOfficeUser } from "../api/auth";
import Header from "../components/header";
import { execOnChange } from "../recompose.contrib";
import { emptyPageForm } from "../reducers/page";
import { sessionSelector } from "../selectors";
import { headerSelector } from "../selectors/header";
import { mapPageSimpleState } from "../selectors/page";
import { dispatchWrap } from "../utils";
import connect from "./connect";

const mapDispatch = (
  dispatch,
  { secondaryDrawerOpen = false, setBiebLinkDialogProps }
) => ({
  logout: (officeLogoutUrl, sisenseLogout) =>
    dispatch(logoutAction(officeLogoutUrl, sisenseLogout)),
  deleteItem: (itemId) => {
    dispatch(deleteItemAction(itemId));
  },
  linkItem: (saarItemId) => {
    dispatch(
      findBiebItemsAction(saarItemId, (item) =>
        setBiebLinkDialogProps({ open: true, saarItemId, item })
      )
    );
  },
  okBiebLinkDialog: (saarItemId, biebItemId) => {
    setBiebLinkDialogProps(defaultBiebLinkDialogProps);
    dispatch(linkItemAction({ saarItemId, biebItemId }));
  },
  closeBiebLinkDialog: () => setBiebLinkDialogProps(defaultBiebLinkDialogProps),
  unlinkItem: (linkModel) => {
    dispatch(unlinkItemAction(linkModel));
  },
  updateMiniSaar: (miniSaar) => dispatch(updateMiniSaarAction(miniSaar)),
  toggleSecondaryDrawer: () =>
    dispatch(updateSecondaryDrawerAction(!secondaryDrawerOpen)),
  requireSearchScope: dispatchWrap(requireSearchScope, dispatch),
  showVersionsDrawer: (show) => dispatch(showVersionsDrawerAction(show)),
  setVersionDiff: (
    itemId,
    versionId,
    relationVersionId,
    relationItemId,
    relationItemVersionId,
    created,
    current = false
  ) =>
    dispatch(
      setVersionDiffAction(
        itemId,
        versionId,
        relationVersionId,
        relationItemId,
        relationItemVersionId,
        created,
        current
      )
    ),
  createLegoblok: (open) => dispatch(initializeCreateLegoblok(open)),
  addToBieb: (open) => dispatch(addToBiebDialogAction(open)),
});

const pageStateSelector = createSelector(
  [
    flow(get("form.page"), defaultTo(emptyPageForm)),
    flow(get("data.settings.data.enableWord"), defaultTo("true"), eq("true")),
    (_, props) => get("id")(props),
  ],
  (page, enableWord, id) => ({ pageState: page[id], enableWord })
);

const defaultBiebLinkDialogProps = {
  open: false,
  saarItemId: undefined,
  item: undefined,
};

export default compose(
  setDisplayName("Header"),
  withRouter,
  withState(
    "biebLinkDialogProps",
    "setBiebLinkDialogProps",
    defaultBiebLinkDialogProps
  ),
  connect(
    (state, props) => ({
      ...headerSelector(state, props),
      ...sessionSelector(state, props),
      ...mapPageSimpleState(state, props.params),
      ...pageStateSelector(state, props.params),
    }),
    mapDispatch
  ),
  withHandlers({
    logout:
      ({
        office365: { logoutUri } = empty.object,
        azureAd: { logoutUri: loginAzureAdUri } = empty.object,
        logout,
        sisense: { logout: sisenseLogoutUrl },
      }) =>
      () =>
        logout(isOfficeUser() ? logoutUri : loginAzureAdUri, sisenseLogoutUrl),

    password:
      ({ router }) =>
      () =>
        router.push("/inloggen?changePassword=true"),
  }),
  execOnChange("requireSearchScope", "selectedSaar")
)(Header);
