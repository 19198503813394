import { defaultTo, get } from "lodash/fp";
import { hashHistory } from "react-router";

import { flown } from "../lodash";

export const addStage = (payload) => ({ type: "STAGE_PUSH", payload });

export const cancelStage = () => ({ type: "STAGE_CANCEL" });

export const confirmStage = (action) => (dispatch) => {
  dispatch({ type: "STAGE_CONFIRM" });

  dispatch(action);
};

export const redirectAction = (next) => (dispatch) => {
  const sessionNext = window.sessionStorage.getItem("saar_next");

  if (sessionNext) {
    window.sessionStorage.removeItem("saar_next");
    hashHistory.replace(`${next}?next=${encodeURIComponent(sessionNext)}`);
  } else {
    hashHistory.replace(next);
  }

  return dispatch({
    type: "REDIRECT",
    next,
  });
};

export const redirectLocalAction = (local) => (dispatch, getState) => {
  const path = flown(
    getState(),
    get("session.miniSaar.path"),
    defaultTo("iznet")
  );
  dispatch(redirectAction(`/${path}/${local}`));
};

export const gotoAction = (next) => (dispatch) => {
  hashHistory.push(next);
  return dispatch({
    type: "REDIRECT",
    next,
  });
};

export const gotoLocalAction = (local) => (dispatch, getState) => {
  const path = flown(
    getState(),
    get("session.miniSaar.path"),
    defaultTo("iznet")
  );
  dispatch(gotoAction(`/${path}/${local}`));
};
