import { loadingPresent, loadingProgress } from "async-lifecycle";
import empty from "empty";
import { flow, get, includes, map, min, sortBy } from "lodash/fp";
import IconButton from "material-ui/IconButton";
import { MenuItem } from "material-ui/Menu";
import Paper from "material-ui/Paper";
import RaisedButton from "material-ui/RaisedButton";
import SelectField from "material-ui/SelectField";
import NietNodig from "material-ui/svg-icons/av/not-interested";
import Fullscreen from "material-ui/svg-icons/navigation/fullscreen";
import FullscreenExit from "material-ui/svg-icons/navigation/fullscreen-exit";
import NietGetekend from "material-ui/svg-icons/social/sentiment-dissatisfied";
import Getekend from "material-ui/svg-icons/social/sentiment-satisfied";
import Table from "material-ui/Table/Table";
import TableBody from "material-ui/Table/TableBody";
import TableHeader from "material-ui/Table/TableHeader";
import TableHeaderColumn from "material-ui/Table/TableHeaderColumn";
import TableRow from "material-ui/Table/TableRow";
import TableRowColumn from "material-ui/Table/TableRowColumn";
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { requireVerwerkingen } from "../../actions/application";
import { exportDataAction } from "../../actions/utils";
import { ItemLink } from "../relation.itemlink";
import BasePage from "./basePage";

const TitleDiv = memo(({ title, className, children = title }) => (
  <div title={title} className={className}>
    {children}
  </div>
));

const StatusIcon = memo(({ status }) => {
  const iconProps = {
    title: status,
    width: 16,
    height: 16,
  };

  switch (status) {
    case "Verwerkersovereenkomst getekend":
      return <Getekend {...iconProps} />;
    case "Verwerkersovereenkomst niet nodig":
      return <NietNodig {...iconProps} />;
    case "Verwerkersovereenkomst niet getekend":
    default:
      return <NietGetekend {...iconProps} />;
  }
});

const ExterneVerwerker = memo(({ leverancier, overeenkomstStatus }) => {
  const label = `${leverancier.label} (${overeenkomstStatus})`;
  return (
    <TitleDiv title={label} className="externe-verwerker--with-icon">
      <StatusIcon status={overeenkomstStatus} />
      <ItemLink item={leverancier} />
    </TitleDiv>
  );
});

const VerwerkingenTable = ({ list, full }) => (
  <Table selectable={false} multiSelectable={false}>
    <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
      <TableRow>
        <TableHeaderColumn>Applicatie</TableHeaderColumn>
        <TableHeaderColumn>Entiteit</TableHeaderColumn>
        <TableHeaderColumn>Applicatie&shy;gegevens</TableHeaderColumn>
        <TableHeaderColumn>Doeleinde</TableHeaderColumn>
        {full && <TableHeaderColumn>Grondslag</TableHeaderColumn>}
        {full && <TableHeaderColumn>Toelichting</TableHeaderColumn>}
        <TableHeaderColumn>Proces</TableHeaderColumn>
        {full && <TableHeaderColumn>Classificatie</TableHeaderColumn>}
        {full && <TableHeaderColumn>Externe verwerker</TableHeaderColumn>}
      </TableRow>
    </TableHeader>
    <TableBody displayRowCheckbox={false}>
      {list.map((v, i) => (
        <TableRow key={i}>
          <TableRowColumn>
            <TitleDiv title={v.applicatie.label}>
              <ItemLink item={v.applicatie} />
            </TitleDiv>
          </TableRowColumn>
          <TableRowColumn>
            <TitleDiv title={v.entiteit.label}>
              <ItemLink item={v.entiteit} />
            </TitleDiv>
          </TableRowColumn>

          <TableRowColumn>
            {v.applicatiegegevens.map(({ id, naam }) => (
              <TitleDiv title={naam} key={id}>
                <ItemLink
                  item={{
                    itemId: v.applicatie.itemId,
                    slug: v.applicatie.slug,
                    label: naam,
                    pageClusterId: id,
                  }}
                />
              </TitleDiv>
            ))}
          </TableRowColumn>
          <TableRowColumn>
            {v.verantwoording && (
              <TitleDiv title={v.verantwoording.label}>
                <ItemLink item={v.verantwoording} />
              </TitleDiv>
            )}
          </TableRowColumn>
          {full && (
            <TableRowColumn>
              {v.verantwoording && (
                <TitleDiv title={v.verantwoording.grondslag} />
              )}
            </TableRowColumn>
          )}
          {full && (
            <TableRowColumn>
              {v.verantwoording && (
                <TitleDiv title={v.verantwoording.toelichting} />
              )}
            </TableRowColumn>
          )}
          <TableRowColumn>
            {v.processItem && (
              <TitleDiv title={v.processItem.label}>
                <ItemLink item={v.processItem} />
              </TitleDiv>
            )}
          </TableRowColumn>
          {full && <TableRowColumn>{v.privacyClassification}</TableRowColumn>}
          {full && (
            <TableRowColumn>
              {map(({ leverancier, overeenkomstStatus }) => (
                <ExterneVerwerker
                  key={`verwerker-${i}-${leverancier.itemId}`}
                  leverancier={leverancier}
                  overeenkomstStatus={overeenkomstStatus}
                />
              ))(v.externeVerwerkers)}
            </TableRowColumn>
          )}
        </TableRow>
      ))}
    </TableBody>
  </Table>
);

const columnValues = (v) => [
  v.applicatie.label,
  v.entiteit.label,
  v.applicatiegegevens.map((g) => g.naam).join(", "),
  v.verantwoording ? v.verantwoording.label : "",
  v.verantwoording ? v.verantwoording.grondslag : "",
  v.verantwoording ? v.verantwoording.toelichting : "",
  v.processItem ? v.processItem.label : "",
  v.privacyClassification,
  v.externeVerwerkers
    ? map(
        ({ leverancier, overeenkomstStatus }) =>
          `${leverancier.label} (${overeenkomstStatus})`
      )(v.externeVerwerkers).join(", ")
    : "",
];

const FullscreenPaper = ({
  fullscreen,
  filter,
  onFilter,
  sort,
  onSort,
  onToggle,
  list,
  onExport,
}) => (
  <Paper style={{ padding: "16px", textAlign: "right" }}>
    <div>
      <span
        style={{
          verticalAlign: "middle",
          display: "inline-block",
          textAlign: "left",
          marginRight: 16,
        }}
      >
        <SelectField
          autoWidth
          value={filter}
          onChange={onFilter}
          floatingLabelText="Filter"
        >
          <MenuItem value="all" primaryText="Volledig" />
          <MenuItem value="privacy" primaryText="Persoonsgegevens" />
          <MenuItem value="verantwoording" primaryText="Verantwoordingen" />
          <MenuItem value="verwerking" primaryText="Processen" />
        </SelectField>
      </span>
      <span
        style={{
          verticalAlign: "middle",
          display: "inline-block",
          textAlign: "left",
          marginRight: 16,
        }}
      >
        <SelectField
          autoWidth
          value={sort}
          onChange={onSort}
          floatingLabelText="Sortering"
        >
          <MenuItem value="applicatie" primaryText="Applicatie" />
          <MenuItem value="entiteit" primaryText="Entiteit" />
          <MenuItem value="verwerking" primaryText="Proces" />
          <MenuItem value="classificatie" primaryText="Classificatie" />
          <MenuItem
            value="status"
            primaryText="Status verwerkersovereenkomst"
          />
        </SelectField>
      </span>
      <span style={{ verticalAlign: "middle", display: "inline-block" }}>
        <RaisedButton label="Export naar Excel" onClick={onExport} />
      </span>
      <span style={{ verticalAlign: "middle", display: "inline-block" }}>
        <IconButton onClick={onToggle}>
          {fullscreen ? <FullscreenExit /> : <Fullscreen />}
        </IconButton>
      </span>
    </div>
    <VerwerkingenTable list={list} full={fullscreen} />
  </Paper>
);

/**
 * Verwerkingen component
 */
const Verwerkingen = (props) => {
  const { loading, list } = useSelector((store) => store.data.verwerkingen);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(requireVerwerkingen());
  }, [dispatch]);

  const fullscreen = useMemo(
    () => Boolean(includes("fullscreen")(props.uiClasses)),
    [props.uiClasses]
  );
  const toggle = useCallback(() => {
    if (fullscreen) {
      props.uiActions.removeClass("fullscreen");
    } else {
      props.uiActions.addClass("fullscreen");
    }
  }, [fullscreen, props.uiActions]);

  const [filter, setFilter] = useState("all");
  const [sort, setSort] = useState("applicatie");

  const onFilter = useCallback((_, _0, value) => {
    setFilter(value);
  }, []);

  const onSort = useCallback((_, _0, value) => {
    setSort(value);
  }, []);

  const data = useMemo(() => {
    let filtered;
    switch (filter) {
      case "privacy":
        filtered = list.filter(
          (r) => r.privacyClassification.indexOf("Geen") !== 0
        );
        break;
      case "verantwoording":
        filtered = list.filter((r) => Boolean(r.verantwoording));
        break;
      case "verwerking":
        filtered = list.filter((r) => Boolean(r.processItem));
        break;
      default:
        filtered = list;
        break;
    }

    switch (sort) {
      case "entiteit":
        return sortBy((r) => r.entiteit.label)(filtered);
      case "verwerking":
        return sortBy((r) => (r.processItem ? r.processItem.label : "~"))(
          filtered
        );
      case "classificatie":
        return sortBy(flow(get("privacyClassification"), min))(filtered);
      case "status":
        return sortBy(
          flow(get("externeVerwerkers"), map("overeenkomstStatus"), min)
        )(filtered);
      default:
        return filtered;
    }
  }, [list, filter, sort]);

  const onExport = useCallback(() => {
    dispatch(
      exportDataAction(
        "Verwerkingsoverzicht",
        [
          "Applicatie",
          "Entiteit",
          "Applicatiegegevens",
          "Doeleinde",
          "Grondslag",
          "Toelichting",
          "Proces",
          "Classificatie",
          "Externe verwerker",
        ],
        map(columnValues)(data)
      )
    );
  }, [data, dispatch]);

  const tabs = loadingPresent(loading)
    ? [
        {
          label: "Verwerkingen",
          children: (
            <FullscreenPaper
              fullscreen={fullscreen}
              onToggle={toggle}
              list={data}
              filter={filter}
              onFilter={onFilter}
              sort={sort}
              onSort={onSort}
              onExport={onExport}
            />
          ),
        },
      ]
    : empty.array;
  const anyLoading =
    props.loading === true ||
    loadingProgress(props.loading) ||
    loadingProgress(loading);

  return (
    <BasePage
      {...props}
      loading={anyLoading}
      tabs={tabs}
      extraClasses={props.uiClasses}
    />
  );
};

export default Verwerkingen;
