import Badge from "material-ui/Badge";
import React, { CSSProperties, ReactNode } from "react";

interface BadgedProps {
  count?: number;
  children: ReactNode;
  style: CSSProperties;
}

const Badged = ({ count = 0, children, style }: BadgedProps): JSX.Element =>
  count > 0 ? (
    <Badge
      badgeContent={count}
      style={{ padding: 0 }}
      badgeStyle={{
        width: 16,
        height: 16,
        top: 4,
        right: 4,
        fontSize: 12,
        ...style,
      }}
    >
      {children}
    </Badge>
  ) : (
    <>{children}</>
  );

export default Badged;
