import empty from "empty";
import { get } from "lodash/fp";
import React from "react";

import RisicoScore from "./score/risicoScore";

const renderRisicoScore = ({
  getContent,
  pageActions: { edit = false } = empty.object,
}) =>
  getContent({
    path: "analyse",
    renderEnd: ({ kans, impact }) =>
      !edit && (
        <RisicoScore kans={get("value")(kans)} impact={get("value")(impact)} />
      ),
  });

export default renderRisicoScore;
