/* eslint react/prop-types: off */

import empty from "empty";
import getMuiTheme from "material-ui/styles/getMuiTheme";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import React from "react";
import { useSelector } from "react-redux";
import { BreakpointProvider } from "react-socks";

import Analytics from "../containers/analytics";
import Confirm from "../containers/confirm";
import Console from "../containers/console";
import Header from "../containers/header";
import { theme } from "../reducers/uiReducers";
import TelemetryProvider from "../telemetry/TelemetryProvider";
import ContactWidget from "./ContactWidget";
import SnackbarWidget from "./SnackbarWidget";

export const Themed = ({ children }) => (
  <MuiThemeProvider muiTheme={getMuiTheme(theme())}>
    {children}
  </MuiThemeProvider>
);

/**
 * App root React component
 * @return {React Element}
 */
const App = ({ ui: { theme }, children }) => {
  const settings = useSelector(
    ({
      data: { settings: { data = empty.object } = empty.object } = empty.object,
    }) => data
  );

  if (!settings) {
    return null;
  }

  const instrumentationKey = settings["appinsights.instrumentationkey"];
  return (
    <TelemetryProvider instrumentationKey={instrumentationKey}>
      <MuiThemeProvider muiTheme={getMuiTheme(theme)}>
        <BreakpointProvider>
          <Analytics>
            <Header />
            <div>{children}</div>
            <Confirm />
            <Console />
            <ContactWidget />
            <SnackbarWidget />
          </Analytics>
        </BreakpointProvider>
      </MuiThemeProvider>
    </TelemetryProvider>
  );
};

export default App;
