/* eslint  react/prop-types: off */
import empty from "empty";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hashHistory } from "react-router";

import { requireRecentAction } from "../actions/data";
import Component from "../components/recentItems";
import { otherItems } from "../selectors/page";

/**
 * RecentItems component implementation
 */
const RecentItems = (props) => {
  const dispatch = useDispatch();
  const location = hashHistory.getCurrentLocation();
  const idmatch = /\/page\/(\d+)/.exec(location.pathname);
  const pageId = idmatch?.[1];
  useEffect(() => {
    dispatch(requireRecentAction());
  }, [dispatch, pageId]);

  const { biebStructures, recent } = useSelector((store) => ({
    biebStructures: store.data.addLegoblok.structures ?? empty.array,
    recent: otherItems(store.data.recent, pageId),
    showRecent: store.ui.recent,
  }));

  return (
    <Component {...props} recent={recent} legoblokStructures={biebStructures} />
  );
};

export default RecentItems;
