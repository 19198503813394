import empty from "empty";
import { map } from "lodash/fp";
import { Step, StepButton, StepContent } from "material-ui/Stepper";
import React from "react";

import { editPageActions } from "../../business/models";
import Verwijzing from "../../components/ggvtyp/verwijzing";
import { labelStyle, requiredStyle, validStyle } from "./shared";

const actionType =
  "InfoZorgSAAR.Iprox.ContentActions.KoppelingAction, SAAR.Iprox";

const getLegoblokIds = (legoblokStructures) =>
  map(({ id }) => parseInt(id, 10))(legoblokStructures);

const KoppelingForm = ({
  newItem: {
    extraAction: {
      fromApplicationId,
      fromApplicationLabel,
      toApplicationId,
      toApplicationLabel,
    } = empty.object,
    extraAction,
  } = empty.object,
  newItem,
  updateItem,
  updateStep,
  isBiebStructure,
  legoblokStructures,
}) => [
  <Step key="koppeling-step">
    <StepButton onClick={() => updateStep(1)}>
      <span>Relatie toevoegen</span>
    </StepButton>
    <StepContent>
      <div style={fromApplicationId !== undefined ? validStyle : requiredStyle}>
        <label style={labelStyle}>Applicatie van</label>
        <Verwijzing
          key="applicatie-van"
          field={{ id: 1, isEmpty: true }}
          value={{ linkId: fromApplicationId, label: fromApplicationLabel }}
          dirty={fromApplicationId !== undefined}
          setValue={({ value: { linkId, label } = empty.object }) => {
            const action = {
              ...extraAction,
              $type: actionType,
              fromApplicationId: linkId ? parseInt(linkId, 10) : undefined,
              fromApplicationLabel: label,
            };
            updateItem({
              ...newItem,
              extraAction: action,
              validExtras: linkId !== undefined && toApplicationId > 0,
            });
          }}
          pageActions={editPageActions}
          paginatypen={["applicatie"]}
          sitesInclude={
            isBiebStructure && newItem.structureId
              ? [newItem.structureId]
              : undefined
          }
          sitesExclude={
            !isBiebStructure && newItem.structureId
              ? getLegoblokIds(legoblokStructures)
              : undefined
          }
        />
      </div>
      <div style={toApplicationId !== undefined ? validStyle : requiredStyle}>
        <label
          style={{
            fontSize: 12,
            color: "rgba(0, 0, 0, 0.3)",
          }}
        >
          Applicatie naar
        </label>
        <Verwijzing
          key="applicatie-naar"
          field={{ id: 1, isEmpty: true }}
          value={{ linkId: toApplicationId, label: toApplicationLabel }}
          dirty={toApplicationId !== undefined}
          setValue={({ value: { linkId, label } = empty.object }) => {
            const action = {
              ...extraAction,
              $type: actionType,
              toApplicationId: linkId ? parseInt(linkId, 10) : undefined,
              toApplicationLabel: label,
            };
            updateItem({
              ...newItem,
              extraAction: action,
              validExtras: linkId !== undefined && fromApplicationId > 0,
            });
          }}
          pageActions={editPageActions}
          paginatypen={["applicatie"]}
          sitesInclude={
            isBiebStructure && newItem.structureId
              ? [newItem.structureId]
              : undefined
          }
          sitesExclude={
            !isBiebStructure && newItem.structureId
              ? getLegoblokIds(legoblokStructures)
              : undefined
          }
        />
      </div>
    </StepContent>
  </Step>,
];

export default KoppelingForm;
