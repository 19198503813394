/* eslint  react/prop-types: off */
import classNames from "classnames";
import empty from "empty";
import { grey600 } from "material-ui/styles/colors";
import VisibilityIcon from "material-ui/svg-icons/action/visibility";
import React from "react";
import { compose, setDisplayName, withHandlers } from "recompose";

import { updateOnDataChanged } from "../recompose.contrib";
import { highlight } from "../utils";
import { PathLink } from "./relation.pathlink";
import SafeInnerHtml from "./safeInnerHtml";

export const postscriptInParentheses = (children) =>
  children.length > 0 && (
    <div className="postscript-in-parentheses" key="postscriptInParentheses">
      {children}
    </div>
  );

const previewStyle = Object.freeze({ color: grey600 });

const ItemLinkComponent = ({
  item: {
    itemId,
    miniSaar,
    path,
    slug,
    label,
    pageClusterId,
    pageClusterLabel,
    discouraged,
    setPreview,
  },
  extra = empty.array,
  parentheses = "",
  highlightWord = "",
  clusterFirst = false,
  children,
  className,
  handlePreview,
  ...rest
}) => {
  const fullLabel = pageClusterLabel
    ? clusterFirst
      ? `${pageClusterLabel} (${label})`
      : `${label} (${pageClusterLabel})`
    : parentheses
    ? `${label} (${parentheses})`
    : label;
  const inner = children || [
    highlightWord !== "" ? (
      <SafeInnerHtml key="html">
        {highlight(fullLabel, highlightWord)}
      </SafeInnerHtml>
    ) : (
      fullLabel
    ),
    postscriptInParentheses(extra),
  ];
  const extraArgs = pageClusterId ? `?clusterId=${pageClusterId}` : "";
  return typeof setPreview === "function" ? (
    <div className="item-link-preview">
      <PathLink
        {...rest}
        className={classNames(className, { discouraged })}
        miniSaar={miniSaar}
        path={`${
          itemId ? `page/${itemId}${slug ? `/${slug}` : ""}` : path
        }${extraArgs}`}
      >
        {inner}
      </PathLink>
      <div className="preview" onClick={handlePreview}>
        <VisibilityIcon style={previewStyle} />
      </div>
    </div>
  ) : (
    <PathLink
      {...rest}
      title={rest.title ?? (children ? fullLabel : undefined)}
      className={classNames(className, { discouraged })}
      miniSaar={miniSaar}
      path={`${
        itemId ? `page/${itemId}${slug ? `/${slug}` : ""}` : path
      }${extraArgs}`}
    >
      {inner}
    </PathLink>
  );
};

export const ItemLink = compose(
  setDisplayName("ItemLink"),
  updateOnDataChanged("item", "children"),
  withHandlers({
    handlePreview:
      ({ item: { itemId, setPreview } = empty.object }) =>
      (e) => {
        e.preventDefault();
        e.stopPropagation();
        setPreview(itemId);
      },
  })
)(ItemLinkComponent);
