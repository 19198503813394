import empty from "empty";
import { compose } from "recompose";

import { pickCluster } from "../../actions/cms";
import AddButtonsComponent from "../../components/pagetypes/legoblok/addButtons";
import { updateShowDialog } from "../../saar-add-item/redux";
import connect from "../connect";

const AddButtons = compose(
  connect(
    empty.functionThatReturns(empty.object),
    (
      _,
      {
        siteId = undefined,
        pageActions: {
          dispatch,
          pageId,
          clusterAdd = empty.func,
        } = empty.object,
      }
    ) => ({
      clusterAdd: (name) =>
        clusterAdd({ name }).then(() => dispatch(pickCluster(pageId, name))),
      clusterNew: (name) =>
        dispatch(
          updateShowDialog(true, {
            legoblokId: pageId,
            legoblokTab: name,
            legoblokStructureId: siteId,
          })
        ),
    })
  )
)(AddButtonsComponent);

export default AddButtons;
