import empty from "empty";
import { get } from "lodash/fp";
import PropTypes from "prop-types";
import React from "react";
import { withProps } from "recompose";

import { itemShape, pageActionsShape } from "../../../business/prop-types";
import AddButtons from "../../../containers/pagetypes/legoblok";

const ClusterAddButtons = ({
  clusterProp,
  page,
  pageActions,
  item: { siteId } = empty.object,
}) => {
  if (!get("edit")(pageActions)) {
    return null;
  }

  const cluster = get(clusterProp)(page);
  if (!cluster) {
    return null;
  }

  return (
    <AddButtons siteId={siteId} clusters={cluster} pageActions={pageActions} />
  );
};
ClusterAddButtons.defaultProps = {
  page: undefined,
  pageActions: undefined,
};
ClusterAddButtons.propTypes = {
  clusterProp: PropTypes.string.isRequired,
  page: itemShape,
  pageActions: pageActionsShape,
};

const legoblok = (clusterProp) =>
  withProps({ clusterProp, key: clusterProp })(ClusterAddButtons);
export default legoblok;
