import empty from "empty";
import { defaultTo, flow, get } from "lodash/fp";

const defaults = {
  logOnConsole: ["warn", "error"],
  throwOnConsole: empty.array,
  ignoreConsoleMessages: [
    /Wrapper div has no height or width/,
    /unrecognized in this browser/,
    /console\.\w+ called with/,
    /validateDOMNesting/,
  ],
  usersessionAvailable: true,
  msie:
    flow(get("userAgent"), defaultTo(""))(navigator).indexOf("Trident/") >= 0,
  iproxUrl: "",
  iproxRedactieUrl: "",
  loadTinyMce: true,
};
const specifics = {
  development: {
    logOnConsole: [],
    // DISABLED throwOnConsole: ["warn", "error"],
  },
  production: {
    // all default
  },
  test: {
    loadTinyMce: false,
  },
};
const config = {
  ...defaults,
  ...(specifics[process.env.NODE_ENV || "production"] || empty.object),
};
const { usersessionAvailable, iproxUrl, iproxRedactieUrl } = config;
export { usersessionAvailable, iproxUrl, iproxRedactieUrl };
export default config;
