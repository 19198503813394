import empty from "empty";
import { filter, get, map, sortBy } from "lodash/fp";
import Menu from "material-ui/Menu";
import MenuItem from "material-ui/MenuItem";
import Paper from "material-ui/Paper";
import PropTypes from "prop-types";
import React, { memo } from "react";
import { compose, setDisplayName, withHandlers } from "recompose";

import { koppelingApplicatieShape } from "../../../business/prop-types";
import { flown } from "../../../lodash";
import { isNonEmptyArray } from "../../../utils";
import Icon from "../icon";
import { findField } from "./functions";

const FieldUsed = () => (
  <span className="icon-inline" title="komt al voor in deze koppeling">
    <Icon pagetype="koppeling" />
  </span>
);

// eslint-disable-next-line react/prop-types
const renderVeld =
  (disabled, usedFields, forbiddenFields) =>
  ({ id, naam }) =>
    (
      <MenuItem
        key={`veld-${id}`}
        disabled={disabled || forbiddenFields.includes(id)}
        value={id}
        className="menu-item-veld"
        primaryText={
          <>
            {`\u00a0\u00a0\u00a0\u00a0${naam}`}
            {usedFields.includes(id) ? <FieldUsed /> : null}
          </>
        }
      />
    );

// eslint-disable-next-line react/prop-types
const renderGegeven =
  (disabled, usedFields, forbiddenFields) =>
  ({ naam, id, velden }) =>
    [
      <MenuItem
        key={`gegeven-${id}`}
        disabled
        primaryText={`\u00a0\u00a0${naam}`}
        value={id}
        className="menu-item-gegeven"
      />,
      ...map(renderVeld(disabled, usedFields, forbiddenFields))(velden),
    ];

// eslint-disable-next-line react/prop-types
const renderEntiteit =
  (disabled, usedFields, forbiddenFields) =>
  ({ entiteit = "Geen entiteit", entiteitId, gegevens }) =>
    [
      <MenuItem
        key={`gegeven-${entiteitId}`}
        disabled
        primaryText={entiteit}
        title={entiteit}
        value={entiteitId}
        className="menu-item-entiteit"
      />,
      ...map(renderGegeven(disabled, usedFields, forbiddenFields))(gegevens),
    ];

const renderSet =
  (disabled, usedFields, forbiddenFields) =>
  ({ fields: { gegevens = empty.array, entiteiten } = empty.object }) => {
    const items = isNonEmptyArray(entiteiten)
      ? flown(
          entiteiten,
          filter(get("gegevens.length")),
          sortBy(["entiteit"]),
          map(renderEntiteit(disabled, usedFields, forbiddenFields))
        )
      : flown(
          gegevens,
          filter(get("velden.length")),
          sortBy(["naam"]),
          map(renderGegeven(disabled, usedFields, forbiddenFields))
        );

    if (items.length === 0) {
      return <MenuItem primaryText="Geen velden" />;
    }

    return items;
  };

const KoppelingSelectField = ({
  application,
  usedFields,
  forbiddenFields = empty.array,
  select,
  disabled,
}) => {
  const onItemClick = React.useCallback(
    (e, menuItem, index) => {
      if (menuItem.props.value) {
        select(e, menuItem, index);
      } else {
        e.preventDefault();
      }
    },
    [select]
  );
  return (
    <Paper style={{ boxSizing: "border-box", overflow: "hidden" }}>
      <Menu onItemClick={onItemClick} disableAutoFocus>
        {renderSet(disabled, usedFields, forbiddenFields)(application)}
      </Menu>
    </Paper>
  );
};

KoppelingSelectField.propTypes = Object.freeze({
  application: koppelingApplicatieShape.isRequired,
  usedFields: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
  select: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
});

const EnhancedKoppelingSelectField = compose(
  setDisplayName("KoppelingSelectField"),
  withHandlers({
    select:
      ({ application: { fields }, onChange }) =>
      (event, { props: { value } = empty.object }) => {
        const selection = findField(fields)(value);
        onChange(selection);
      },
  })
)(KoppelingSelectField);

export default memo(EnhancedKoppelingSelectField);
