import empty from "empty";
import { findIndex, includes, memoize } from "lodash/fp";

export const pagetypeGroups = {
  applicatie: 1,
  proces: 2,
  applicatielandschap: 3,
  treeview: 4,
  document: 5,
  entiteit: 6,
  koppeling: 7,
  raamwerk: 8,
};

export const makePagetypes = memoize((pagetype) =>
  pagetype ? [pagetype] : empty.array
);

const pagetypeGroupAliases = [
  empty.array,
  ["applicatie", "module"],
  ["activiteit", "bedrijfsproces", "procesgroep", "procesonderdeel"],
  ["applicatielandschap"],
  ["treeview"],
  [
    "bedrijfsregel",
    "birapportage",
    "casus",
    "document",
    "locatie",
    "organisatie",
    "organisatieonderdeel",
  ],
  ["entiteit"],
  ["koppeling"],
  ["raamwerk", "praktijksituatie"],
];

const pagetypesWithProcessTree = [
  ...pagetypeGroupAliases[1],
  ...pagetypeGroupAliases[4],
  ...pagetypeGroupAliases[5],
  "rol",
  "groep",
  "applicatieset",
  "basisset",
  "persoon",
];

export const pagetypeViewName = (alias) => {
  switch (alias) {
    case "applicatielandschap":
      return "landschap";
    case "dashboard":
      return "startpunt";
    case "index":
      return "lijstweergave";
    case "organisatieonderdeel":
      return "organisatorische eenheid";
    case "treeview":
      return "boomweergave";
    default:
      return alias;
  }
};

export const pagetypeHasProcessTree = (pagetype) =>
  includes(pagetype)(pagetypesWithProcessTree);

export const pagetypeGroupFromAlias = (pagetype) => {
  if (!pagetype) {
    return undefined;
  }

  const index = findIndex(includes(pagetype))(pagetypeGroupAliases);
  return index < 0 ? null : index;
};
