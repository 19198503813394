import { Component as ReactComponent, ReactNode, useRef } from "react";

import { deepEqualIgnoreFunctions } from "../../utils";

const FALSE = Date.now() === 0;
const logEnabled = FALSE;
const inspectEnabled = FALSE;
const fun = "log";
const log = logEnabled ? console[fun] : () => {};

const shallowEqual = <T extends Record<string, U>, U = unknown>(
  a: T,
  b: T,
  title: string
) => {
  const keys = Array.from(new Set([...Object.keys(a), ...Object.keys(b)]));
  let equal = true;
  for (const key of keys) {
    if (a[key] !== b[key]) {
      log(
        "Inspect changed",
        title,
        deepEqualIgnoreFunctions(a, b),
        key,
        a[key],
        b[key]
      );
      equal = false;
    }
  }

  return equal;
};

interface InspectProps {
  aka: string;
  children: ReactNode;
}

const Inspect = <T,>({ aka, children, ...props }: InspectProps & T) => {
  const count = useRef(0);
  count.current++;
  const title = `${aka}: ${count.current}`;
  const prev = useRef(props);
  if (count.current > 1) {
    if (shallowEqual(prev.current, props, title)) {
      console.error("Inspect unchanged", title, props);
    } else {
      prev.current = props;
    }
  }

  return <>{children}</>;
};

export const withInspect = <T,>(select: (props: T) => string) =>
  inspectEnabled
    ? (Component: typeof ReactComponent<T>) => (props: T) =>
        (
          <Inspect aka={select(props)} {...props}>
            <Component {...props} key="sub" />
          </Inspect>
        )
    : (Component: typeof ReactComponent<T>) => Component;
