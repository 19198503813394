import Autolinker from "autolinker";
import classNames from "classnames";
import FloatingActionButton from "material-ui/FloatingActionButton";
import ActionDelete from "material-ui/svg-icons/action/delete";
import ActionDone from "material-ui/svg-icons/action/done";
import ContentClear from "material-ui/svg-icons/content/clear";
import TextField from "material-ui/TextField";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import ReactMarkdown from "react-markdown";
import {
  compose,
  mapProps,
  onlyUpdateForKeys,
  setDisplayName,
  setPropTypes,
  withState,
} from "recompose";

const ItemNote = compose(
  setDisplayName("ItemNote"),
  setPropTypes({
    deleteNote: PropTypes.func.isRequired,
    note: PropTypes.object.isRequired,
    save: PropTypes.func.isRequired,
    userId: PropTypes.number.isRequired,
  }),
  withState("editable", "update", { show: false, text: "" }),
  mapProps(({ note, deleteNote, save, update, editable, ...rest }) => ({
    toggleEdit: () =>
      update({
        show: !editable.show,
        text: editable.show ? note.text : editable.text,
      }),
    updateNote: (event) => {
      event.preventDefault();
      if (event.target) {
        update({
          show: true,
          text: event.target.value,
        });
      }
    },
    saveNote: (event) => {
      event.preventDefault();
      if (editable.text !== "") {
        save(editable.text, note.id);
        update({
          show: false,
          text: editable.text,
        });
      }
    },
    log: (event) => {
      switch (event.keyCode) {
        case 13:
          if (event.ctrlKey && editable.text !== "") {
            save(editable.text, note.id);
            update({
              show: !editable.show,
              text: editable.text,
            });
          }

          break;
        case 9:
        case 27:
          update({
            show: !editable.show,
            text: editable.show ? note.text : editable.text,
          });

          break;
        default:
          break;
      }
    },
    deleteNote: () => deleteNote(note.id),
    note,
    editable,
    ...rest,
  })),
  onlyUpdateForKeys(["note", "userId", "editable"])
)(
  ({
    note = {},
    editable,
    toggleEdit,
    updateNote,
    deleteNote,
    userId,
    saveNote,
    log,
  }) => {
    const text = editable.text ? editable.text : note.text;
    const autolinker = new Autolinker({
      urls: {
        schemeMatches: true,
        wwwMatches: true,
        tldMatches: true,
      },
      email: true,
      phone: false,
      mention: false,
      hashtag: false,
      replaceFn: (match) =>
        `[${match.getMatchedText()}](${match.getAnchorHref()})`,
    });

    return (
      <div
        className={classNames("note-item", {
          "my-note": userId && userId === note.userId,
          editable: userId && userId === note.userId && editable.show,
        })}
      >
        {(!editable.show || (userId && userId !== note.userId)) && text && (
          <div className="text" onClick={toggleEdit}>
            <ReactMarkdown
              source={autolinker.link(text)}
              softBreak="br"
              disallowedTypes={["Heading"]}
            />
          </div>
        )}
        {userId && userId === note.userId && editable.show && (
          <TextField
            hintText="Typ hier jouw bericht..."
            floatingLabelText="Jouw bericht aanpassen?"
            multiLine
            fullWidth
            value={text}
            onChange={updateNote}
            onKeyDown={log}
            rows={4}
          />
        )}
        <div className="meta">
          Opmerking van{" "}
          <a href={`mailto:${note.userEmail}`}>{note.userFullName}</a> op{" "}
          {moment(note.timestamp).format("LLL")}
        </div>
        {userId && userId === note.userId && (
          <div className="buttons">
            {!editable.show && (
              <FloatingActionButton
                mini
                className="delete-button"
                secondary
                onClick={deleteNote}
                title="Verwijder opmerking"
              >
                <ActionDelete />
              </FloatingActionButton>
            )}
            {editable.show && (
              <FloatingActionButton
                mini
                className="cancel-button"
                onClick={toggleEdit}
                title="Annuleren"
              >
                <ContentClear />
              </FloatingActionButton>
            )}
            {editable.show ? " " : null}
            {editable.show && (
              <FloatingActionButton
                mini
                disabled={editable.text === ""}
                className="save-button"
                onClick={saveNote}
                title="Wijzigingen opslaan"
              >
                <ActionDone />
              </FloatingActionButton>
            )}
          </div>
        )}
      </div>
    );
  }
);

export default ItemNote;
