import IconButton from "material-ui/IconButton";
import RaisedButton from "material-ui/RaisedButton";
import ActionDelete from "material-ui/svg-icons/action/delete";
import NavigationClose from "material-ui/svg-icons/navigation/close";
import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";
import { compose, setDisplayName, setPropTypes, withHandlers } from "recompose";

import { iproxUrl } from "../../config";

const BestandView = compose(
  setDisplayName("BestandView"),
  setPropTypes({
    fileName: PropTypes.string,
  }),
  withHandlers({
    handleDelete:
      ({ fileName, onDelete }) =>
      (event) => {
        event.preventDefault();
        onDelete(fileName);
      },
  })
)(({ className, path, title, extension, edit, handleDelete, onDelete }) => {
  const [open, setOpen] = useState(false);
  const clickOpen = useCallback(
    (e) => {
      if (!/\.pdf$/i.test(extension)) {
        return;
      }

      e.preventDefault();
      setOpen(true);
    },
    [extension]
  );
  const clickAway = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setOpen(false);
  }, []);
  return (
    <span className={className}>
      <span className="fileName-wrapper">
        <a
          className={className}
          href={`${iproxUrl}/aspx/download.aspx?attach=true&File=${path}&${Date.now()}`}
          title={path}
          onClick={clickOpen}
        >
          {title}
        </a>
        {extension && (
          <span className="file-extension">
            {extension.substring(1).toLowerCase()}
          </span>
        )}
      </span>
      {open && (
        <>
          <span className="file-toolbox" onClick={clickAway}>
            <RaisedButton
              primary
              icon={<NavigationClose />}
              onClick={clickAway}
              label="Sluiten"
            />
          </span>
          <iframe
            title={title}
            src={`${iproxUrl}/aspx/download.aspx?attach=false&File=${path}&${Date.now()}`}
            className="file-viewer"
          />
        </>
      )}
      {edit && onDelete && (
        <IconButton
          style={{
            display: "inline-block",
            width: 24,
            height: 24,
            border: 0,
            padding: 0,
          }}
          onClick={handleDelete}
          title="Verwijder bestand"
          className="verwijder-bestand"
        >
          <ActionDelete />
        </IconButton>
      )}
    </span>
  );
});

BestandView.propTypes = {
  className: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  extension: PropTypes.string,
  onDelete: PropTypes.func,
  handleDelete: PropTypes.func,
  edit: PropTypes.bool,
};

export default BestandView;
