import empty from "empty";
import { compose, setDisplayName } from "recompose";
import { createStructuredSelector } from "reselect";

import Console from "../components/console";
import connect from "./connect";

const errorSelector = createStructuredSelector({
  error: ({ error: { errors = empty.array } }) => errors[0] || empty.object,
});

export default compose(
  setDisplayName("Console"),
  connect(errorSelector)
)(Console);
