import empty from "empty";
import { get, includes } from "lodash/fp";
import { createSelector, createStructuredSelector } from "reselect";

import { versionsSelectorBuilder } from "./versions";
import {
  legoblokStructuresSelector,
  miniSaarSelector,
  miniSaarsSelector,
} from ".";

const homeLinkSelector = ({
  data: { navigation: { homeLink } = empty.object } = empty.object,
}) => homeLink;

const footerLinksSelector = ({
  data: { navigation: { footerLinks } = empty.object } = empty.object,
}) => footerLinks;

const isFullscreenSelector = createSelector(
  [({ ui: { classes } = empty.object }) => classes || empty.array],
  includes("fullscreen")
);

const idSelector = (_, { params: { id } = empty.object }) => id;

const enableCreateLegoblokSelector = ({
  data: {
    settings: {
      data: { enableCreateLegoblok = "true" } = empty.object,
    } = empty.object,
  } = empty.object,
}) => enableCreateLegoblok === "true";

const biebButtonSelector = createSelector(
  [legoblokStructuresSelector, enableCreateLegoblokSelector],
  (structures, enabled) =>
    enabled && structures.length > 0
      ? { show: true, link: "search/library" }
      : { show: false }
);

const relationsSelector = createSelector(
  [idSelector, (store) => store.data.relations],
  (id, relations) => relations[id]?.relations ?? empty.array
);

const pageSelector = createSelector(
  [idSelector, (store) => store.data.pages],
  (id, pages) => pages[id] ?? empty.object
);

const isBiebItemSelector = createSelector(
  [legoblokStructuresSelector, pageSelector],
  (structures, page) => structures.some(({ id }) => id === page.siteId)
);

const biebTypesSelector = createSelector(
  [(store) => store.data.relationTypes.types ?? empty.array],
  (types) =>
    types.filter(
      ({ alias }) => alias.startsWith("bieb_") && !alias.includes("_cluster_")
    )
);

const biebLinkSelector = createSelector(
  [idSelector, relationsSelector, biebTypesSelector],
  (id, relations, types) => {
    const itemId = Number(id);
    return relations.find(
      ({ relationTypeId, left: { itemId: leftItemId } }) => {
        return (
          itemId !== leftItemId &&
          types.some(({ id: typeId }) => typeId === relationTypeId)
        );
      }
    )?.left;
  }
);

const versionsSelector = versionsSelectorBuilder(idSelector);

// eslint-disable-next-line import/prefer-default-export
export const headerSelector = createStructuredSelector({
  homeLink: homeLinkSelector,
  footerLinks: footerLinksSelector,
  selectedSaar: miniSaarSelector,
  miniSaarList: miniSaarsSelector,
  legoblokStructures: legoblokStructuresSelector,
  isFullscreen: isFullscreenSelector,
  biebButton: biebButtonSelector,
  isBiebItem: isBiebItemSelector,
  biebLink: biebLinkSelector,
  versions: versionsSelector,
  versionsDrawer: get("ui.versionsDrawer"),
});

export const enableScopeSelector = createSelector(
  [
    ({ miniSaarList }) => miniSaarList,
    ({ biebButton }) => (biebButton || empty.object).show,
    ({ legoblokStructures }) => (legoblokStructures || empty.array).length,
  ],
  (miniSaarList, biebButtonShow, legoblokStructuresLength) => ({
    allSites: miniSaarList.length > 1,
    library: biebButtonShow && legoblokStructuresLength > 0,
  })
);
