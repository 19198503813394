import { SvgIcon } from "material-ui";
import ActionAssessment from "material-ui/svg-icons/action/assessment";
import ActionAssignment from "material-ui/svg-icons/action/assignment";
import ActionAssignmentInd from "material-ui/svg-icons/action/assignment-ind";
import ActionBook from "material-ui/svg-icons/action/book";
import ActionClass from "material-ui/svg-icons/action/class";
import ActionDashboard from "material-ui/svg-icons/action/dashboard";
import ActionDescription from "material-ui/svg-icons/action/description";
import ActionDns from "material-ui/svg-icons/action/dns";
import ActionExtension from "material-ui/svg-icons/action/extension";
import ActionInfo from "material-ui/svg-icons/action/info";
import ActionLoyalty from "material-ui/svg-icons/action/loyalty";
import ActionSearch from "material-ui/svg-icons/action/search";
import ActionSettings from "material-ui/svg-icons/action/settings";
import ActionSupervisorAccount from "material-ui/svg-icons/action/supervisor-account";
import ActionTimeline from "material-ui/svg-icons/action/timeline";
import ActionVerifiedUser from "material-ui/svg-icons/action/verified-user";
import ActionWork from "material-ui/svg-icons/action/work";
import AvAvTimer from "material-ui/svg-icons/av/av-timer";
import CallSplit from "material-ui/svg-icons/communication/call-split";
import ContentAddBox from "material-ui/svg-icons/content/add-box";
import ContentFontDownload from "material-ui/svg-icons/content/font-download";
import ContentLink from "material-ui/svg-icons/content/link";
import Widgets from "material-ui/svg-icons/device/widgets";
import EditorFormatListBulleted from "material-ui/svg-icons/editor/format-list-bulleted";
import FileDownload from "material-ui/svg-icons/file/file-download";
import HardwareDeveloperBoard from "material-ui/svg-icons/hardware/developer-board";
import HardwareDeviceHub from "material-ui/svg-icons/hardware/device-hub";
import HardwareKeyboardArrowRight from "material-ui/svg-icons/hardware/keyboard-arrow-right";
import HardwareSmartphone from "material-ui/svg-icons/hardware/smartphone";
import BlurCircular from "material-ui/svg-icons/image/blur-circular";
import ImageBlurOn from "material-ui/svg-icons/image/blur-on";
import ImageGridOn from "material-ui/svg-icons/image/grid-on";
import Texture from "material-ui/svg-icons/image/texture";
import ImageViewCompact from "material-ui/svg-icons/image/view-compact";
import Directions from "material-ui/svg-icons/maps/directions";
import MapsPlace from "material-ui/svg-icons/maps/place";
import MapsStoreMallDirectory from "material-ui/svg-icons/maps/store-mall-directory";
import MapsTraffic from "material-ui/svg-icons/maps/traffic";
import Kitchen from "material-ui/svg-icons/places/kitchen";
import SocialDomain from "material-ui/svg-icons/social/domain";
import SocialPerson from "material-ui/svg-icons/social/person";
import SocialPoll from "material-ui/svg-icons/social/poll";
import SocialSchool from "material-ui/svg-icons/social/school";

import AccountBalance from "../../svg/AccountBalance";
import AccountTree from "../../svg/AccountTree";
import FactCheck from "../../svg/FactCheck";
import News from "../../svg/news";
import {
  Activiteit,
  Bedrijfsproces,
  Procesgroep,
  Procesonderdeel,
} from "./processIcons";

export type MaterialSvgIcon = typeof SvgIcon;
export interface PageTypeIcons {
  [key: string]: MaterialSvgIcon | undefined;
}

const pagetypeIcons: PageTypeIcons = {
  actielijsten: ActionAssignment,
  activiteit: Activiteit,
  app: HardwareSmartphone,
  applicatie: ContentFontDownload,
  applicatielandschap: HardwareDeviceHub,
  applicatieset: ActionSettings,
  basisset: HardwareDeveloperBoard,
  bedrijfsproces: Bedrijfsproces,
  bedrijfsregel: Directions,
  bieb: ActionBook,
  birapportage: ActionTimeline,
  casus: ContentAddBox,
  cluster: HardwareKeyboardArrowRight,
  competentie: undefined,
  dashboard: ActionDashboard,
  document: ActionDescription,
  empty: ImageBlurOn,
  enquete: SocialPoll,
  entiteit: BlurCircular,
  filedownload: FileDownload,
  groep: ActionSupervisorAccount,
  hyperlink: ContentLink,
  kostenplaats: AccountBalance as MaterialSvgIcon,
  index: EditorFormatListBulleted,
  informatiestandaard: ActionInfo,
  infrastructuur: Kitchen,
  inzicht: ActionAssessment,
  klant: ActionLoyalty,
  koppeling: HardwareDeviceHub,
  legoblok: Widgets,
  legoblokpart: Texture,
  leverancier: MapsStoreMallDirectory,
  locatie: MapsPlace,
  meter: AvAvTimer,
  meters: AvAvTimer,
  module: ActionExtension,
  nieuws: News as MaterialSvgIcon,
  offerte: undefined,
  organisatie: SocialDomain,
  organisatieonderdeel: AccountTree as MaterialSvgIcon,
  organogram: undefined,
  overzicht: ImageGridOn,
  persoon: SocialPerson,
  praktijksituatie: FactCheck as MaterialSvgIcon,
  procesgroep: Procesgroep,
  procesonderdeel: Procesonderdeel,
  project: ActionWork,
  raamwerk: MapsTraffic,
  risico: ActionVerifiedUser,
  rol: ActionAssignmentInd,
  standaard: ActionDescription,
  tariefkaart: undefined,
  training: SocialSchool,
  treeview: CallSplit,
  veld: ActionDns,
  verantwoording: ActionClass,
  verantwoordingen: EditorFormatListBulleted,
  verwerking: CallSplit,
  zib: ImageViewCompact,
  zoeken: ActionSearch,
};

export type Pagetype = keyof PageTypeIcons;

export default pagetypeIcons;
