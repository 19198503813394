import { asyncReducer } from "async-lifecycle";
import empty from "empty";
import {
  defaultTo,
  defaults,
  filter,
  find,
  flow,
  get,
  includes,
  uniq,
} from "lodash/fp";
import { createSelector, createStructuredSelector } from "reselect";

import {
  addItemAction,
  fieldDefinitionsByPagetypeAction,
  findItemAction,
  resetFindItemAction,
} from "../actions/cms";
import { invalidatePage } from "../actions/page";
import {
  deprecatedPagetypes,
  infozorgOnlyPagetypes,
  nonAddablePagetypes,
} from "../business";
import { prohibitEditInfozorgOnly } from "../business/authorization";
import connect from "../containers/connect";
import { flown } from "../lodash";
import { legoblokStructuresSelector, sessionSelector } from "../selectors";
import { writeableLegoblokStructures } from "../selectors/page";
import { dispatchWrap } from "../utils";

export const prefix = "ADD_ITEM";
export const defaultValues = {
  sent: false,
  showDialog: false,
  legoblokId: undefined,
  legoblokTab: undefined,
  legoblokStructureId: undefined,
};
export const updateSent = (sent) => ({ type: `${prefix}_SET`, data: { sent } });
export const updateShowDialog = (showDialog, extra = empty.object) => ({
  type: `${prefix}_SET`,
  data: { ...(showDialog ? extra : defaultValues), showDialog },
});

const pagetypeList = flow(
  get("data.pagetypeList.data"),
  defaultTo(empty.array)
);

const ignorePagetypes = uniq([...nonAddablePagetypes, ...deprecatedPagetypes]);

const legoblokChildAction =
  "InfoZorgSAAR.Iprox.ContentActions.LegoblokChildAction, SAAR.Iprox";

const pagetypeListSelector = createSelector([pagetypeList], (pagetypes) =>
  pagetypes.filter(
    ({ alias }) =>
      typeof alias === "string" &&
      alias.indexOf("_") < 0 &&
      !includes(alias)(ignorePagetypes)
  )
);

const addablePagetypesSelector = createSelector(
  [pagetypeListSelector, sessionSelector],
  (pagetypes, { user }) =>
    prohibitEditInfozorgOnly(user)
      ? pagetypes.filter(({ alias }) => !includes(alias)(infozorgOnlyPagetypes))
      : pagetypes
);

const writeableLegoblokStructuresSelector = createSelector(
  [get("data.addLegoblok")],
  writeableLegoblokStructures
);

const addItemSelector = flow(get("form.addItem"), defaults(defaultValues));

export const structuresSelector = createSelector(
  [
    writeableLegoblokStructuresSelector,
    flow(get("session.miniSaar"), defaultTo(empty.object)),
    flow(get("data.miniSaars.data"), defaultTo(empty.array)),
    addItemSelector,
  ],
  (legoblokStructures, { siteId }, miniSaars, { legoblokStructureId }) => {
    const { name } = flown(
      miniSaars,
      find({ siteId }),
      defaultTo(empty.object)
    );
    const structures = name
      ? [{ id: siteId, name }, ...legoblokStructures]
      : empty.array;
    return legoblokStructureId
      ? filter({ id: legoblokStructureId })(structures)
      : structures;
  }
);

export const reducer = asyncReducer(prefix, {
  SET: (state = empty.object, { data }) => ({ ...state, ...data }),
});

export const connector = connect(
  createStructuredSelector({
    form: addItemSelector,
    foundItems: flow(get("data.foundItems.list"), defaultTo(empty.array)),
    legoblokStructures: legoblokStructuresSelector,
    pagetypes: addablePagetypesSelector,
    structures: structuresSelector,
    selectionLists: flow(
      get("data.selectionLists.data"),
      defaultTo(empty.array)
    ),
    fieldDefinitionsByPagetype: flow(
      get("data.fieldDefinitionsByPagetype"),
      defaultTo(empty.object)
    ),
  }),
  (dispatch) => ({
    addItem: (item) =>
      dispatch(
        addItemAction(item, {
          callbackBefore: (dispatch) => dispatch(updateSent(true)),
          callbackError: (dispatch) => {
            dispatch(updateShowDialog(false));
            dispatch(updateSent(false));
          },
          callbackSuccess: (_, dispatch) => {
            dispatch(updateShowDialog(false));
            dispatch(updateSent(false));
            if (
              item.extraAction &&
              item.extraAction.$type === legoblokChildAction
            ) {
              dispatch(invalidatePage(item.extraAction.legoblokId));
            }
          },
        })
      ),
    find: (label, pagetype) => {
      if (label && label.length >= 3 && pagetype) {
        dispatch(findItemAction(label, pagetype));
      }
    },
    reset: () => dispatch(resetFindItemAction()),
    updateShowDialog: dispatchWrap(updateShowDialog, dispatch),
    requireDefinition: (pagetypeAlias) =>
      dispatch(fieldDefinitionsByPagetypeAction(pagetypeAlias)),
  }),
  (stateProps, dispatchProps, ownProps) => {
    const { form: { legoblokId, legoblokTab } = empty.object } = stateProps;
    const merged = {
      ...ownProps,
      ...stateProps,
      ...dispatchProps,
    };
    return legoblokId && legoblokTab
      ? {
          ...merged,
          addItem: (item) =>
            dispatchProps.addItem({
              ...item,
              extraAction: {
                $type: legoblokChildAction,
                legoblokId,
                legoblokTab,
              },
            }),
        }
      : merged;
  }
);
