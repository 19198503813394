/* eslint  react/prop-types: off */
import empty from "empty";
import { compact, flatten, filter as fpFilter, includes, map } from "lodash/fp";
import { Card } from "material-ui/Card";
import Checkbox from "material-ui/Checkbox";
import Divider from "material-ui/Divider";
import IconButton from "material-ui/IconButton";
import { List, ListItem } from "material-ui/List";
import Subheader from "material-ui/Subheader";
import ActionDelete from "material-ui/svg-icons/action/delete";
import HardwareKeyboardArrowDown from "material-ui/svg-icons/hardware/keyboard-arrow-down";
import HardwareKeyboardArrowUp from "material-ui/svg-icons/hardware/keyboard-arrow-up";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { flown } from "../lodash";
import SaarSubheader from "./material/Subheader";
import pagetypeIcons from "./pagetypes/icons";
import { PathLink } from "./relation.pathlink";

const FacetList = ({
  label,
  items,
  filter,
  addFilter,
  removeFilter,
  type,
  first,
}) => {
  const [open, setOpen] = useState(false);
  const toggleOpen = useCallback(() => setOpen(!open), [open]);
  return (
    <>
      {!first && <Divider />}
      <Subheader
        style={{
          paddingBottom: open ? undefined : 8,
          paddingTop: 8,
          lineHeight: "inherit",
          cursor: "pointer",
        }}
        onClick={toggleOpen}
      >
        <span
          style={{ marginTop: -4, float: "right", transform: "scale(0.75)" }}
        >
          {open ? <HardwareKeyboardArrowUp /> : <HardwareKeyboardArrowDown />}
        </span>
        {label}
      </Subheader>
      {open &&
        flown(
          items,
          map(({ id, listId, value, itemId: linkedItemId, type: pagetype }) => {
            const itemId = listId || id;
            const itemType = listId ? "lst" : type;
            const key = `${itemType}:${itemId}`;

            const isItem = !!pagetype;
            const Icon = pagetypeIcons[pagetype || "empty"];

            return (
              <ListItem
                className="filter-item"
                key={key}
                leftCheckbox={
                  <Checkbox
                    checked={includes(key)(filter)}
                    onCheck={(_, isInputChecked) =>
                      isInputChecked
                        ? addFilter(itemId, itemType)
                        : removeFilter(itemId, itemType)
                    }
                    style={{ top: "calc(50% - 12px)" }}
                  />
                }
                primaryText={value}
                rightIcon={
                  isItem ? (
                    <PathLink
                      style={{ margin: "6px 8px 0px 8px", padding: 0 }}
                      path={
                        linkedItemId
                          ? `page/${linkedItemId}?clusterId=${id}`
                          : `page/${itemId}`
                      }
                    >
                      <Icon style={{ opacity: 0.65, width: 18 }} />
                    </PathLink>
                  ) : undefined
                }
                style={{ paddingTop: "8px", paddingBottom: "8px" }}
                innerDivStyle={{ paddingLeft: "56px" }}
              />
            );
          })
        )}
    </>
  );
};

const renderLists = (lists) =>
  lists.map((list, i) => <FacetList key={i} {...list} first={i === 0} />);

const Filters = ({
  filters: {
    lists = empty.array,
    filter = empty.array,
    addFilter,
    removeFilter,
    removeAllFilters,
    applicatieList = empty.array,
    bewerkingList = empty.array,
    entiteitList = empty.array,
    kerngegevenList = empty.array,
    kernveldList = empty.array,
    zibList = empty.array,
    applicatieMozKoppelingList = empty.array,
    koppelingMozGegevensList = empty.array,
  } = empty.object,
}) => {
  const statusList = useMemo(
    () => lists.find(({ alias }) => alias === "status"),
    [lists]
  );

  // set default status-selection on initial load of the index
  useEffect(() => {
    if (filter.length > 0 || !statusList || statusList.length === 0) {
      return;
    }

    const defaultFilter = statusList?.items
      ?.filter(({ alias }) => alias !== "archief")
      ?.flatMap(({ id }) => [id, "sel"]);
    addFilter(...(defaultFilter ?? empty.array));
  }, [addFilter, filter, statusList]);

  return (
    <Card style={{ marginBottom: "16px" }}>
      <SaarSubheader style={{ display: "flex" }}>
        <div style={{ flexGrow: "1", alignSelf: "center" }}>Filters</div>
        <IconButton
          style={{
            alignSelf: "center",
            padding: "0",
            margin: "0 12px",
            width: "24px",
            height: "24px",
          }}
          onClick={() => removeAllFilters()}
        >
          <ActionDelete />
        </IconButton>
      </SaarSubheader>
      <List className="filter-list" style={{ padding: "0 0 8px 0" }}>
        {flown(
          [
            flown(
              lists,
              fpFilter(
                (list) => !list.disabled && list.value === "Status van pagina"
              ),
              map(({ id, items = empty.array, value: label }) => ({
                id,
                label,
                items,
                filter,
                addFilter,
                removeFilter,
                type: "sel",
              }))
            ),
            applicatieList.length > 0 && {
              id: "applicatie",
              label: "Applicaties",
              items: applicatieList,
              filter,
              addFilter,
              removeFilter,
              type: "app",
            },
            bewerkingList.length > 0 && {
              id: "bewerking",
              label: "Bewerkingsapplicaties",
              items: bewerkingList,
              filter,
              addFilter,
              removeFilter,
              type: "bew",
            },
            entiteitList.length > 0 && {
              id: "entiteit",
              label: "Entiteiten",
              items: entiteitList,
              filter,
              addFilter,
              removeFilter,
              type: "ent",
            },
            kerngegevenList.length > 0 && {
              id: "kerngegeven",
              label: "Entiteitgegevens",
              items: kerngegevenList,
              filter,
              addFilter,
              removeFilter,
              type: "ggv",
            },
            zibList.length > 0 && {
              id: "zib",
              label: "Zibs",
              items: zibList,
              filter,
              addFilter,
              removeFilter,
              type: "zib",
            },
            applicatieMozKoppelingList.length > 0 && {
              id: "applicatieMozKoppeling",
              label: "Applicatie met/zonder koppeling",
              items: applicatieMozKoppelingList,
              filter,
              addFilter,
              removeFilter,
              type: "apk",
            },
            koppelingMozGegevensList.length > 0 && {
              id: "koppelingMozGegevens",
              label: "Koppeling met/zonder gegevens",
              items: koppelingMozGegevensList,
              filter,
              addFilter,
              removeFilter,
              type: "kog",
            },
            flown(
              lists,
              fpFilter(
                (list) => !list.disabled && list.value !== "Status van pagina"
              ),
              map(({ id, items = empty.array, value: label }) => ({
                id,
                label,
                items,
                filter,
                addFilter,
                removeFilter,
                type: "sel",
              }))
            ),
            kernveldList.length > 0 && {
              id: "kernveld",
              label: "Entiteitvelden",
              items: kernveldList,
              filter,
              addFilter,
              removeFilter,
              type: "vld",
            },
          ],
          compact,
          flatten,
          renderLists
        )}
      </List>
    </Card>
  );
};

export default Filters;
