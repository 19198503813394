/* eslint  react/prop-types: off */
import empty from "empty";
import { defaultTo, flow, get, isEqual } from "lodash/fp";
import React, { Fragment } from "react";
import ReactGA from "react-ga4";
import { withRouter } from "react-router";
import {
  compose,
  lifecycle,
  pure,
  setDisplayName,
  withHandlers,
  withProps,
} from "recompose";
import { createStructuredSelector } from "reselect";

import { pageSelector } from "../selectors/page";
import connect from "./connect";

/**
 * Slechts een doorgeefluik...
 */
const Analytics = pure(({ children }) => <Fragment>{children}</Fragment>);

export default compose(
  setDisplayName("Analytics"),
  pure,
  withRouter,
  withProps(({ params: { id } = empty.object }) => ({
    id: id ? Number(id) : undefined,
  })),
  connect(
    createStructuredSelector({
      googleAnalyticsId: get("data.settings.data.googleAnalyticsId"),
      user: flow(get("session.user"), defaultTo(empty.object)),
      page: pageSelector,
      search: get("form.globalQuery.query"),
    })
  ),
  withHandlers({
    onLocationChange:
      () =>
      (
        pathname,
        title,
        id,
        minisaar,
        { username, id: gebIdt } = empty.object,
        { trefwoord } = empty.object
      ) => {
        const dimensions = {
          userId: username,
          page: pathname,
          title,
          content_id: id,
          content_group: minisaar,
          search_term: trefwoord,
          gebIdt: gebIdt,
        };
        ReactGA.set(dimensions);
        ReactGA.send({ ...dimensions, hitType: "pageview" });
      },
  }),
  lifecycle({
    componentDidUpdate({
      location: { pathname: prevPathname },
      page: { page: { title: prevTitle } = empty.object } = empty.object,
      search: prevSearch,
      googleAnalyticsId: prevGoogleAnalyticsId,
    }) {
      const {
        location: { pathname } = empty.object,
        page: { page: { title } = empty.object } = empty.object,
        params: { id, minisaar } = empty.object,
        user,
        googleAnalyticsId,
        onLocationChange = empty.func,
        search,
      } = this.props;

      // Google Analytics setup
      if (
        googleAnalyticsId &&
        !isEqual(googleAnalyticsId, prevGoogleAnalyticsId)
      ) {
        ReactGA.initialize(googleAnalyticsId, {
          alwaysSendReferrer: true,
          allowAnchor: true,
        });
      }

      const isSearch = /search\/?/g.test(pathname);
      const isPage = /\/page\//g.test(pathname);
      const isOther = !isSearch && !isPage;
      const isHome = /home\/?/g.test(pathname);
      const isLogin = /inloggen\/?/g.test(pathname);

      const pageChanged =
        pathname && isPage && id && title && !isEqual(title, prevTitle);
      const otherChanged =
        pathname && isOther && !isEqual(pathname, prevPathname);
      const searchChanged =
        pathname && isSearch && !isEqual(search, prevSearch);

      if (googleAnalyticsId && (pageChanged || otherChanged || searchChanged)) {
        // pagina is anders
        const pageTitle =
          title ||
          (isSearch
            ? "Zoeken"
            : isHome
            ? "Home"
            : isLogin
            ? "Inloggen"
            : undefined);
        onLocationChange(
          pathname,
          pageTitle,
          id,
          minisaar,
          user,
          isSearch ? search : undefined
        );
      }
    },
  })
)(Analytics);
