import PropTypes from "prop-types";

export const ScopeShape = PropTypes.shape({
  site: PropTypes.bool.isRequired,
  library: PropTypes.bool.isRequired,
  allSites: PropTypes.bool.isRequired,
});

export const defaultSearchScope = Object.freeze({
  site: true,
  library: false,
  allSites: false,
});

export const ScopeLabels = Object.freeze({
  library: "Saar Bieb",
  allSites: "Andere Saars",
});

export const SearchPage = Object.freeze({
  id: -1,
  siteId: -1,
  page: {
    title: "Zoeken",
    pagetype: "zoeken",
  },
});

export const deserializeScope = (csv = "") => {
  const parts = csv.split("-");
  return {
    site: parts.includes("site"),
    library: parts.includes("library"),
    allSites: parts.includes("allSites"),
  };
};

export const serializeScope = (scope = {}) => {
  const parts = [];
  if (scope.site) {
    parts.push("site");
  }
  if (scope.library) {
    parts.push("library");
  }
  if (scope.allSites) {
    parts.push("allSites");
  }
  return parts.join("-");
};

export const isLibraryOnly = (scope = {}) =>
  !scope.site && scope.library && !scope.allSites;

export const scopeLabel = (scope = {}, siteLabel = "") => {
  const parts = [];
  if (scope.site) {
    parts.push(siteLabel);
  }
  if (scope.library) {
    parts.push(ScopeLabels.library);
  }
  if (scope.allSites) {
    parts.push(ScopeLabels.allSites);
  }
  switch (parts.length) {
    case 0:
      return "Niets";
    case 3:
      return "Alles";
    default:
      return parts.join(" en ");
  }
};

export const nonEmptyScope = (scope = {}) =>
  scope.site || scope.library || scope.allSites ? scope : defaultSearchScope;
