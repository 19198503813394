import empty from "empty";
import { debounce } from "lodash/fp";
import DatePicker from "material-ui/DatePicker";
import IconButton from "material-ui/IconButton";
import ActionHighlightOff from "material-ui/svg-icons/action/highlight-off";
import ActionToday from "material-ui/svg-icons/action/today";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import {
  compose,
  defaultProps,
  setDisplayName,
  setPropTypes,
  withHandlers,
  withProps,
  withState,
} from "recompose";

import { fieldShape, pageActionsShape } from "../../business/prop-types";
import { formatDate, parseDate, shortDate, structuredMap } from "../../utils";
import SemiControlledTextField from "../material/SemiControlledTextField";
import { propTypes, withDefaultPageActions } from "./ggvtyp";

const parseAndHandleDate = (handleChange) => (e, value) =>
  handleChange(
    !value
      ? null
      : /^[0-3]?\d-[01]?\d-(19|20)\d{2}$/.test(value)
      ? parseDate(value, "D-M-YYYY")
      : false
  );

const unwrapDate = (handleChange) => (e, value) => handleChange(value || null);

const datumEditStyle = Object.freeze({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "flex-start",
});

const textFieldStyle = Object.freeze({ height: "48px" });

const datePickerStyle = Object.freeze({
  display: "inline-block",
  width: "24px",
  overflow: "hidden",
});
const datePickerTextStyle = Object.freeze({ cursor: "pointer" });

const buttonOuterDivStyle = Object.freeze({
  fontSize: "16px",
  lineHeight: "24px",
  width: "48px",
  height: "48px",
  display: "inline-block",
  position: "relative",
  backgroundColor: "transparent",
  fontFamily: "Roboto, sans-serif",
  transition: "height 200ms cubic-bezier(0.23, 1, 0.32, 1) 0ms",
});
const buttonInnerDivStyle = Object.freeze({
  position: "absolute",
  opacity: 1,
  color: "rgba(0, 0, 0, 0.3)",
  transition: "all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms",
  transform: "translateY(5px)",
  bottom: "12px",
});
const buttonHr1Style = Object.freeze({
  border: "none",
  borderBottom: "1px solid rgb(224, 224, 224)",
  bottom: "8px",
  boxSizing: "content-box",
  margin: 0,
  position: "absolute",
  width: "100%",
});
const buttonHr2Style = Object.freeze({
  border: "none",
  borderBottom: "2px solid rgb(139, 195, 74)",
  bottom: "8px",
  boxSizing: "content-box",
  margin: 0,
  position: "absolute",
  width: "100%",
  transform: "scaleX(0)",
  transition: "all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms",
});

const floatingLabelNormalStyle = Object.freeze({ top: "16px" });
const floatingLabelErrorStyle = Object.freeze({
  ...floatingLabelNormalStyle,
  color: "#f00",
});

const DatumEdit = ({
  isEmpty,
  dirty,
  explanation,
  value,
  error,
  handleBlur,
  handleChange,
  handleDelete,
}) => {
  const noDate = dirty ? !value : isEmpty;
  const dateValue = parseDate(value);
  return (
    <div style={datumEditStyle}>
      <SemiControlledTextField
        hintText="dd-mm-jjjj"
        pattern="[0-3]?\d-[01]?\d-(19|20)\d{2}"
        floatingLabelFixed
        floatingLabelStyle={
          error ? floatingLabelErrorStyle : floatingLabelNormalStyle
        }
        floatingLabelText={error ? "Ongeldig, gebruik dd-mm-jjjj" : explanation}
        onBlur={handleBlur}
        onChange={parseAndHandleDate(handleChange)}
        value={noDate ? "" : shortDate(dateValue)}
        name="stringdate"
        style={textFieldStyle}
        inputStyle={{ marginTop: 0 }}
      />
      <DatePicker
        style={datePickerStyle}
        textFieldStyle={datePickerTextStyle}
        hintText={<ActionToday />}
        value={noDate ? empty.object : dateValue}
        firstDayOfWeek={1}
        formatDate={empty.functionThatReturns("")}
        onChange={unwrapDate(handleChange)}
        okLabel="OK"
        cancelLabel="Annuleren"
        mode="landscape"
      />
      <div style={buttonOuterDivStyle}>
        <div style={buttonInnerDivStyle}>
          <IconButton
            disabled={noDate}
            onClick={handleDelete}
            title="Verwijder datum"
          >
            <ActionHighlightOff />
          </IconButton>
        </div>
        <div>
          <hr aria-hidden="true" style={buttonHr1Style} />
          <hr aria-hidden="true" style={buttonHr2Style} />
        </div>
      </div>
    </div>
  );
};
DatumEdit.propTypes = Object.freeze({
  isEmpty: PropTypes.bool.isRequired,
  dirty: PropTypes.bool.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.instanceOf(Date).isRequired,
  ]),
  error: PropTypes.bool.isRequired,
  explanation: PropTypes.string,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
});

/**
 * Gegevenstype 'Datum' (7)
 */
const Datum = ({
  field: {
    isEmpty = true,
    definition: { explanation } = empty.object,
  } = empty.object,
  value,
  dirty,
  error,
  pageActions: { edit },
  className,
  handleBlur,
  handleChange,
  handleDelete,
}) =>
  edit ? (
    <DatumEdit
      isEmpty={isEmpty}
      dirty={dirty}
      error={error}
      value={value}
      explanation={explanation}
      handleBlur={handleBlur}
      handleChange={handleChange}
      handleDelete={handleDelete}
    />
  ) : (
    <span className={className}>
      {formatDate(isEmpty && !dirty ? null : value)}
    </span>
  );
Datum.propTypes = Object.freeze({
  field: fieldShape.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.instanceOf(Date).isRequired,
  ]),
  dirty: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  pageActions: pageActionsShape.isRequired,
  className: PropTypes.string.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
});

const enhance = compose(
  setDisplayName("Datum"),
  setPropTypes({
    ...propTypes,
    field: PropTypes.shape({
      value: PropTypes.string.isRequired,
    }).isRequired,
  }),
  defaultProps({
    className: "datum",
  }),
  withDefaultPageActions(),
  withState("error", "setError", false),
  withProps(structuredMap({ setError: debounce(1000) })),
  withHandlers({
    handleBlur: ({ setError }) => setError.flush,
    handleChange:
      ({ setError, setValue }) =>
      (date) => {
        switch (date) {
          case null:
            setError(false);
            setError.flush();
            setValue({
              value: null,
              string: null,
            });
            break;

          case false:
            setError(true);
            break;

          default: {
            const selectedDate = moment(date).startOf("day");
            setError(false);
            setError.flush();
            setValue({
              value: selectedDate.toDate(),
              string: selectedDate.format("YYYYMMDD"),
            });
            break;
          }
        }
      },

    handleDelete:
      ({ setValue }) =>
      () =>
        setValue(empty.object),
  })
);

export default enhance(Datum);
