import { loadingProgress } from "async-lifecycle";
/* eslint  react/prop-types: off */
import classNames from "classnames";
import empty from "empty";
import { defaultTo, flow, get, map, reject } from "lodash/fp";
import { Card, CardText } from "material-ui/Card";
import Checkbox from "material-ui/Checkbox";
import CircularProgress from "material-ui/CircularProgress";
import Menu from "material-ui/Menu";
import MenuItem from "material-ui/MenuItem";
import Popover from "material-ui/Popover";
import Subheader from "material-ui/Subheader";
import React, { useCallback, useEffect } from "react";
import { withRouter } from "react-router";
import {
  compose,
  pure,
  setDisplayName,
  withHandlers,
  withState,
} from "recompose";

import { iproxUrl } from "../../config";
import { execOnChange } from "../../recompose.contrib";
import { highlight, isNonEmptyArray } from "../../utils";
import PagetypeIcon from "../pagetypeIcon";
import { ItemLink } from "../relation.itemlink";
import AutoCompleteTextField from "./autoCompleteTextField";
import {
  ScopeLabels,
  defaultSearchScope,
  scopeLabel,
  serializeScope,
} from "./constants";

const SearchResults = ({
  onClose,
  onFull,
  documents = empty.array,
  searchQuery,
  isLoading,
  totalCount,
}) => (
  <>
    <Menu disableAutoFocus>
      <Subheader style={{ margin: 0, padding: 0 }}>
        {isLoading ? (
          <>Zoeken naar {searchQuery.trefwoord}...</>
        ) : (
          <div className="spread">
            <span>
              {totalCount === 0
                ? "Geen"
                : totalCount <= 10
                ? totalCount
                : "Eerste 10"}{" "}
              {totalCount === 1 ? "resultaat" : "resultaten"} met{" "}
              {searchQuery.trefwoord}
            </span>
            {totalCount > 10 && (
              <a
                href="/"
                onClick={onFull}
                style={{
                  textAlign: "right",
                  marginRight: 20,
                  fontWeight: "normal",
                }}
              >
                Toon alle {totalCount} resultaten
              </a>
            )}
          </div>
        )}
      </Subheader>

      {isLoading && documents.length === 0 && <CircularProgress />}

      {documents.map(({ fields = empty.object, insideBieb }, index) => {
        const isDownload = fields.docTyp.indexOf("attachment") > -1;
        const key = isDownload ? `${fields.itmIdt}-${index}` : fields.itmIdt;
        return (
          <MenuItem
            key={key}
            onClick={onClose}
            leftIcon={
              <PagetypeIcon
                type={
                  fields.legoblok
                    ? "legoblokpart"
                    : isDownload
                    ? "filedownload"
                    : fields.pagetype
                }
                insideBieb={insideBieb}
                style={{ marginTop: "7px" }}
              />
            }
            containerElement={
              isDownload ? (
                <a href={`${iproxUrl}${fields.pad}`}>{fields.assLbl}</a>
              ) : (
                <ItemLink
                  item={{
                    itemId: fields.itmIdt,
                    slug: fields.slug,
                    label: fields.label,
                    variantId: fields.variantId,
                    miniSaar: fields.miniSaar,
                  }}
                />
              )
            }
            primaryText={
              <div
                className="autocomplete-label"
                dangerouslySetInnerHTML={{
                  __html: highlight(
                    isDownload ? fields.assLbl : fields.label,
                    searchQuery.trefwoord
                  ),
                }}
              />
            }
            secondaryText={
              isDownload
                ? fields.filSiz
                : fields.miniSaarName || fields.legoblok
            }
            className="autoCompleteMenuItem"
          />
        );
      })}
    </Menu>
  </>
);

const SearchOptions = ({
  anchor,
  enableScope,
  updateScope,
  searchUi: { expanded, scope = defaultSearchScope },
  close,
  full,
  saar,
  width,
  searchQuery,
  statusList,
  updateStatus,
  documents,
  totalCount,
  isLoading,
  mobile,
}) => {
  const bcount = (x) => (x ? 1 : 0);
  const count = 1 + bcount(enableScope.allSites) + bcount(enableScope.library);
  const optionStyle = { display: "inline-block", width: `${90 / count}%` };
  const handleSite = useCallback(
    (_, checked) => updateScope({ ...scope, site: checked }),
    [scope, updateScope]
  );
  const handleLibrary = useCallback(
    (_, checked) => updateScope({ ...scope, library: checked }),
    [scope, updateScope]
  );
  const handleAllSites = useCallback(
    (_, checked) => updateScope({ ...scope, allSites: checked }),
    [scope, updateScope]
  );
  useEffect(() => {
    if (statusList && !(searchQuery.statusIds?.length > 0)) {
      updateStatus(
        flow(
          get("items"),
          defaultTo(empty.object),
          reject({ alias: "archief" }),
          map("id")
        )(statusList)
      );
    }
  }, [searchQuery.statusIds, statusList, updateStatus]);
  const handleStatus = useCallback(
    (event, checked) => {
      const currentStatusId = Number(event.target.value);
      updateStatus(
        checked
          ? [...searchQuery.statusIds, currentStatusId]
          : searchQuery.statusIds.filter((s) => s !== currentStatusId)
      );
    },
    [searchQuery.statusIds, updateStatus]
  );
  return (
    <Popover
      anchorEl={anchor}
      autoCloseWhenOffScreen={false}
      canAutoPosition={false}
      open={!!saar && expanded}
      useLayerForClickAway={false}
    >
      <Card
        className={classNames({ "mobile-search-options": mobile })}
        style={{ width, position: "relative" }}
        id="search-options"
      >
        <CardText>
          <div className="spread">
            <Subheader inset style={{ margin: 0, padding: 0 }}>
              Zoekbereik
            </Subheader>
            <Checkbox
              value="site"
              label={saar}
              style={optionStyle}
              checked={scope.site}
              onCheck={handleSite}
            />
            <Checkbox
              style={{
                ...optionStyle,
                visibility: enableScope.library ? "visible" : "hidden",
              }}
              value="library"
              label={ScopeLabels.library}
              checked={scope.library}
              onCheck={handleLibrary}
            />
            <Checkbox
              style={{
                ...optionStyle,
                visibility: enableScope.allSites ? "visible" : "hidden",
              }}
              value="allSites"
              label={ScopeLabels.allSites}
              checked={scope.allSites}
              onCheck={handleAllSites}
            />
          </div>
          {statusList?.items && searchQuery.statusIds?.length > 0 && (
            <div className="spread">
              <Subheader inset style={{ margin: 0, padding: 0 }}>
                Status
              </Subheader>
              {flow(
                get("items"),
                map((i) => (
                  <Checkbox
                    key={i.id}
                    value={i.id}
                    label={i.value}
                    style={optionStyle}
                    onCheck={handleStatus}
                    checked={searchQuery.statusIds.includes(i.id)}
                  />
                ))
              )(statusList)}
            </div>
          )}
          {searchQuery.trefwoord && (
            <SearchResults
              documents={documents}
              searchQuery={searchQuery}
              isLoading={isLoading}
              totalCount={totalCount}
              onClose={close}
              onFull={full}
            />
          )}
        </CardText>
      </Card>
    </Popover>
  );
};

const AutoComplete = ({
  anchor,
  setAnchor,
  self,
  setSelf,
  data: {
    documents = empty.array,
    totalCount = 0,
    loading = "",
  } = empty.object,
  saar: { name: saar } = empty.object,
  handleAutoComplete,
  handleFull,
  handleResetAutoComplete,
  searchQuery,
  searchUi,
  submitAutoComplete,
  enableScope,
  updateScope,
  handleAutoCompleteToggle,
  handleSearchOptionsOpen,
  handleSearchOptionsClose,
  statusList,
  updateStatus,
  mobile,
}) => {
  useEffect(() => {
    const handler = (e) => {
      const path = isNonEmptyArray(e.path) ? e.path : e.composedPath?.();

      // Ignore click on autocomplete and on the search options
      if (
        path?.some((el) => el.className === "autoCompleteTextFieldWrapper") ||
        path?.some((el) => el.id === "search-options")
      ) {
        return;
      }

      handleSearchOptionsClose();
    };
    window.addEventListener("click", handler);
    return () => window.removeEventListener("click", handler);
  }, [handleSearchOptionsClose]);

  return (
    <div className="autoComplete" ref={setSelf}>
      <div className="textFieldWrapper">
        <AutoCompleteTextField
          anchor={anchor}
          defaultValue={searchQuery.trefwoord || ""}
          hintText={
            saar ? `Zoek in ${scopeLabel(searchUi.scope, saar)}` : undefined
          }
          isLoading={loadingProgress(loading)}
          handleAutoComplete={handleAutoComplete}
          searchOptionsExpanded={Boolean(searchUi.expanded)}
          toggleExpanded={handleAutoCompleteToggle}
          onFocus={handleSearchOptionsOpen}
          onReset={handleResetAutoComplete}
          onSubmit={submitAutoComplete}
          setAnchor={setAnchor}
        />
      </div>
      <SearchOptions
        anchor={self}
        enableScope={enableScope}
        updateScope={updateScope}
        searchUi={searchUi}
        saar={saar}
        close={handleSearchOptionsClose}
        full={handleFull}
        width={self ? window.getComputedStyle(self).width : undefined}
        searchQuery={searchQuery}
        statusList={statusList}
        updateStatus={updateStatus}
        isLoading={loadingProgress(loading)}
        totalCount={totalCount}
        documents={documents}
        mobile={mobile}
      />
    </div>
  );
};

export default compose(
  setDisplayName("AutoComplete"),
  pure,
  withState("anchor", "setAnchor"),
  withState("self", "setSelf"),
  withRouter,
  withHandlers({
    submitAutoComplete:
      ({
        lazyAutoComplete,
        updateUi,
        searchQuery,
        router,
        searchUi: { scope } = empty.object,
      }) =>
      (e) => {
        if (e.type === "react-click") {
          lazyAutoComplete.cancel();
          updateUi("expanded", false);

          if (!(searchQuery && searchQuery.trefwoord)) {
            e.preventDefault();
          }
        }

        if (e.which === 13 && e.target.value) {
          // Enter
          lazyAutoComplete.cancel();
          updateUi("expanded", false);
          const { params: { minisaar = "" } = empty.object } = router;
          const target = `/${minisaar}/search/${serializeScope(
            scope
          )}?q=${encodeURIComponent(
            e.target.value
          )}&statusIds=${encodeURIComponent(searchQuery.statusIds)}`;
          router.push(target);
        }

        if (e.which === 27) {
          // Escape
          lazyAutoComplete.cancel();
          updateUi("expanded", false);
        }
      },

    handleFull:
      ({
        lazyAutoComplete,
        updateUi,
        searchQuery,
        router,
        searchUi: { scope } = empty.object,
      }) =>
      (e) => {
        e.preventDefault();
        e.stopPropagation();
        lazyAutoComplete.cancel();
        updateUi("expanded", false);
        const { params: { minisaar = "" } = empty.object } = router;
        const target = `/${minisaar}/search/${serializeScope(
          scope
        )}?q=${encodeURIComponent(
          searchQuery.trefwoord
        )}&statusIds=${encodeURIComponent(searchQuery.statusIds)}`;
        router.push(target);
      },

    handleResetAutoComplete: ({ reset }) => reset,

    handleAutoComplete:
      ({
        lazyAutoComplete,
        reset,
        updateUi,
        searchQuery: { statusIds = empty.array } = empty.object,
      }) =>
      (trefwoord, searchInAllSites) => {
        lazyAutoComplete.cancel();
        if (trefwoord) {
          updateUi("expanded", true);
          lazyAutoComplete({
            trefwoord,
            useWildcard: true,
            pageSize: 10,
            searchInAllSites,
            statusIds,
          });
        } else {
          updateUi("expanded", false);
          reset();
        }
      },

    handleAutoCompleteToggle:
      ({ updateUi, searchUi }) =>
      () => {
        updateUi("expanded", !searchUi.expanded);
      },

    handleSearchOptionsClose:
      ({ updateUi }) =>
      () =>
        updateUi("expanded", false),

    handleSearchOptionsOpen:
      ({ updateUi }) =>
      () =>
        updateUi("expanded", true),
  }),
  execOnChange(
    ({ handleSearchOptionsClose }) => handleSearchOptionsClose(),
    (
      { location: prevLocation = empty.object },
      { location: nextLocation = empty.object }
    ) => prevLocation.pathname !== nextLocation.pathname
  )
)(AutoComplete);
