import { flow, intersection } from "lodash/fp";

const mapFromArray = <TItem, TValue>(
  a: TItem[],
  keySelector: (x: TItem, i: number) => string,
  valueSelector: (x: TItem, i: number) => TValue
): Map<string, TValue> => {
  const m = new Map<string, TValue>();
  for (let index = 0; index < a.length; index++) {
    m.set(keySelector(a[index], index), valueSelector(a[index], index));
  }
  return m;
};

type OneOrBoth =
  | [number, number, ...Array<unknown>]
  | [number, undefined, ...Array<unknown>]
  | [undefined, number, ...Array<unknown>];
const zipComparer = ([ax, ay]: OneOrBoth, [bx, by]: OneOrBoth): number =>
  (ax ?? ay ?? 0) - (bx ?? by ?? 0);

const zipOuterBy = <TItem, TKey, TValue>(
  one: TItem[],
  two: TItem[],
  keySelector: (item: TItem) => TKey,
  matchSelector: (key: TKey) => string,
  valueSelector: (item: TItem) => TValue
): (
  | [TKey, TValue, TValue]
  | [TKey, TValue, undefined]
  | [TKey, undefined, TValue]
)[] => {
  const mapper = (items: TItem[]): Map<string, [number, TKey, TValue]> =>
    mapFromArray(items, flow(keySelector, matchSelector), (item, i) => [
      i,
      keySelector(item),
      valueSelector(item),
    ]);
  const oneMap = mapper(one);
  const oneMatches = Array.from(oneMap.keys());
  const twoMap = mapper(two);
  const twoMatches = Array.from(twoMap.keys());

  const sharedMatches = intersection(oneMatches, twoMatches);

  const shared: [number, number, TKey, TValue, TValue][] = sharedMatches.map(
    (match) => {
      const [oneIndex, key, oneValue] = oneMap.get(match)!;
      const [twoIndex, , twoValue] = twoMap.get(match)!;
      return [oneIndex, twoIndex, key, oneValue, twoValue];
    }
  );

  const matchesSet = new Set(sharedMatches);
  const oneOnly: [number, undefined, TKey, TValue, undefined][] = Array.from(
    oneMap.entries()
  )
    .filter(([match]) => !matchesSet.has(match))
    .map(([, [index, key, value]]) => [
      index,
      undefined,
      key,
      value,
      undefined,
    ]);
  const twoOnly: [undefined, number, TKey, undefined, TValue][] = Array.from(
    twoMap.entries()
  )
    .filter(([match]) => !matchesSet.has(match))
    .map(([, [index, key, value]]) => [
      undefined,
      index,
      key,
      undefined,
      value,
    ]);
  const all = [...shared, ...oneOnly, ...twoOnly];
  all.sort(zipComparer);
  return all.map(([, , ...tail]) => tail);
};

export default zipOuterBy;
