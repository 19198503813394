import empty from "empty";
import { isEmpty as _isEmpty } from "lodash/fp";
import { RadioButton, RadioButtonGroup } from "material-ui/RadioButton";
import { Step, StepButton, StepContent, Stepper } from "material-ui/Stepper";
import PropTypes from "prop-types";
import React, { useEffect } from "react";

import {
  clustersForRelationType,
  metaFieldDefinitionsShape,
} from "../../business/prop-types";
import { Side } from "../../business/relations";
import { isEmpty } from "../../utils";
import ClustersSteps from "./clustersSteps";
import ItemsPicker from "./itemsPicker";
import MetaDataSteps from "./metaDataSteps";

const Steps = (props) => {
  const {
    types: { peertype, peerLabel, usingtype, usingLabel } = empty.object,
    type,
    side,
    selfTitle,
    peer,
    using,
    form,
    updateStartFromSide,
    handleNextStep,
    personRoleFilter,
    stepIndex,
    setStepIndex,
    toggleStatus,
    toggleFilter,
    filterScope,
    metaFieldDefinitions,
    clusterRequired,
  } = props;

  const isSso = type.alias === "applicatie_sso";
  const startFrom = form.startFrom;
  useEffect(() => {
    if (!isSso) {
      return;
    }

    if (stepIndex < 1) {
      setStepIndex(1);
    }

    if (startFrom !== "peer") {
      updateStartFromSide("peer");
    }
  }, [isSso, stepIndex, setStepIndex, startFrom, updateStartFromSide]);

  if (!_isEmpty(metaFieldDefinitions)) {
    return MetaDataSteps(props);
  }

  if (clusterRequired) {
    return ClustersSteps(props);
  }

  const stepOne = startFrom !== "peer" ? using : peer;
  const stepTwo = startFrom !== "peer" ? peer : using;
  const stepOneItem =
    stepOne &&
    stepOne.items &&
    form &&
    form[startFrom] &&
    !isEmpty(form[startFrom].peers)
      ? stepOne.items.find(({ itemId }) => itemId === form[startFrom].peers[0])
      : undefined;

  return [
    <Step key={0}>
      <StepButton onClick={() => setStepIndex(0)}>
        <span>Kies een paginatype</span>
        {startFrom && (
          <span>: {startFrom === "using" ? usingLabel : peerLabel}</span>
        )}
      </StepButton>
      <StepContent>
        <RadioButtonGroup
          name="startFrom"
          valueSelected={startFrom}
          onChange={(_, value) => {
            updateStartFromSide(value);
            if (peertype === "persoon" && usingtype === "rol") {
              personRoleFilter();
            }
            handleNextStep();
          }}
        >
          <RadioButton value="using" label={usingLabel} title={usingtype} />
          <RadioButton value="peer" label={peerLabel} title={peertype} />
        </RadioButtonGroup>
      </StepContent>
    </Step>,
    <Step key={1}>
      <StepButton onClick={() => setStepIndex(1)}>
        <span>
          {stepIndex === 0
            ? `${usingLabel} / ${peerLabel}`
            : startFrom === "using"
            ? usingLabel
            : peerLabel}
        </span>
        {stepOneItem && <span>: {stepOneItem.label}</span>}
        {!stepOneItem &&
          form &&
          startFrom &&
          form[startFrom] &&
          form[startFrom].peers[0] && (
            <span>: {form[startFrom].peers[0]} (Nieuw)</span>
          )}
      </StepButton>
      <StepContent>
        <ItemsPicker
          multiple={false}
          startFrom={startFrom}
          {...stepOne}
          onSelect={(itemId) => {
            if (itemId && peertype === "persoon" && usingtype === "rol") {
              personRoleFilter(
                startFrom === "peer" ? "persoon" : "rol",
                itemId
              );
            }

            handleNextStep();
          }}
          toggleStatus={toggleStatus}
          toggleFilter={
            filterScope && startFrom === filterScope ? toggleFilter : undefined
          }
          pagetype={startFrom === "peer" ? peertype : usingtype}
        />
      </StepContent>
    </Step>,
    <Step key={2}>
      <StepButton onClick={() => setStepIndex(2)}>
        {stepIndex === 0
          ? `${peerLabel} / ${usingLabel}`
          : startFrom === "peer"
          ? usingLabel
          : peerLabel}
      </StepButton>
      <StepContent>
        <ItemsPicker
          {...stepTwo}
          refresh={props.refresh}
          toggleStatus={toggleStatus}
          toggleFilter={
            filterScope && startFrom !== filterScope ? toggleFilter : undefined
          }
          multiple={stepOneItem !== undefined} // alleen wanneer er in de eerste stap al een bestaand item is gekozen
          createEnabled={stepOneItem !== undefined} // alleen wanneer er in de eerste stap al een bestaand item is gekozen
          pagetype={startFrom === "peer" ? usingtype : peertype}
          suggestion={
            isSso && side === Side.left && startFrom === "peer" && stepOneItem
              ? `SSO - ${stepOneItem.label} naar ${selfTitle}`
              : undefined
          }
          ssoItmIdt={
            isSso && side === Side.left && startFrom === "peer"
              ? stepOneItem?.itemId
              : undefined
          }
        />
      </StepContent>
    </Step>,
  ];
};
Steps.propTypes = Object.freeze({
  // eslint-disable-next-line react/forbid-prop-types
  types: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  peer: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  using: PropTypes.shape({ specificItemId: PropTypes.number }),
  // eslint-disable-next-line react/forbid-prop-types
  form: PropTypes.any,
  // eslint-disable-next-line react/forbid-prop-types
  updateStartFromSide: PropTypes.any,
  // eslint-disable-next-line react/forbid-prop-types
  handleNextStep: PropTypes.any,
  // eslint-disable-next-line react/forbid-prop-types
  setStepIndex: PropTypes.any,
  // eslint-disable-next-line react/forbid-prop-types
  personRoleFilter: PropTypes.any,
  // eslint-disable-next-line react/forbid-prop-types
  stepIndex: PropTypes.any,
  toggleStatus: PropTypes.bool.isRequired,
  toggleFilter: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  filterScope: PropTypes.any,
  metaFieldDefinitions: metaFieldDefinitionsShape,
  clusterRequired: PropTypes.bool,
  clusters: PropTypes.arrayOf(clustersForRelationType),
});

const Form = (props) => {
  const {
    stepIndex = 0,
    usingRequired,
    peer,
    using: { specificItemId } = empty.object,
    toggleStatus,
    toggleFilter,
    metaFieldDefinitions,
    types: { peertype },
    clusterRequired,
    biebStructures,
    structures,
    refresh,
    maySelectLocation,
    siteId,
  } = props;
  const singleStep =
    !usingRequired && _isEmpty(metaFieldDefinitions) && !clusterRequired;

  return (
    <div className="form">
      {!singleStep && (
        <Stepper activeStep={stepIndex} orientation="vertical">
          {Steps(props)}
        </Stepper>
      )}
      {singleStep && (
        <ItemsPicker
          {...peer}
          toggleStatus={toggleStatus}
          toggleFilter={specificItemId ? toggleFilter : undefined}
          pagetype={peertype}
          biebStructures={biebStructures}
          structures={structures}
          refresh={refresh}
          siteId={siteId}
          maySelectLocation={maySelectLocation}
        />
      )}
    </div>
  );
};
Form.propTypes = Object.freeze({
  // eslint-disable-next-line react/forbid-prop-types
  types: PropTypes.any,
  stepIndex: PropTypes.number,
  usingRequired: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  peer: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  using: PropTypes.shape({ specificItemId: PropTypes.number }),
  toggleStatus: PropTypes.bool.isRequired,
  toggleFilter: PropTypes.func.isRequired,
  metaFieldDefinitions: metaFieldDefinitionsShape,
  clusterRequired: PropTypes.bool,
  clusters: PropTypes.arrayOf(clustersForRelationType),
});

export default Form;
