import {
  amber100 as biebBgColor,
  lightBlue100,
  lightGreen100,
} from "material-ui/styles/colors";
import Subheader from "material-ui/Subheader";
import { compose, mapProps, setDisplayName } from "recompose";

import { mergeStyles, withClassNames } from "../../recompose.contrib";

export default compose(
  setDisplayName("Subheader"),
  mapProps(({ bieb, secondary, ...props }) => ({
    ...props,
    ...mergeStyles({
      color: "inherit",
      fontSize: "inherit",
      backgroundColor: bieb
        ? biebBgColor
        : secondary
        ? lightBlue100
        : lightGreen100,
      paddingBottom: "12px",
      paddingTop: "12px",
      lineHeight: "24px",
    })(props),
  })),
  withClassNames("subheader")
)(Subheader);
