import classNames from "classnames";
import empty from "empty";
import {
  get,
  head,
  identity,
  map,
  reject,
  uniq,
  uniqBy,
  without,
} from "lodash/fp";
import { lightGreen100 } from "material-ui/styles/colors";
import React, { Fragment } from "react";
import Dropzone from "react-dropzone";
import {
  branch,
  compose,
  defaultProps,
  mapProps,
  renderNothing,
  setDisplayName,
  setPropTypes,
  withHandlers,
} from "recompose";

import { fileFieldShape, fileFieldValueShape } from "../../business/prop-types";
import { flown, innerJoin } from "../../lodash";
import {
  concat,
  getExtension,
  isNonEmptyArray,
  structuredMap,
  uploadMulti,
} from "../../utils";
import { appendExtension } from "./bestand";
import BestandView from "./bestandView";
import { propTypes, withDefaultPageActions } from "./ggvtyp";

/**
 * Tonen 'Meervoudig Bestand' (272)
 */
const MeervoudigBestand = ({
  field: { assetPath = "/", isEmpty } = empty.object,
  value: { files = empty.array } = empty.object,
  className,
  pageActions: { edit } = empty.object,
  handleDelete,
  handleDrop,
}) => (
  <Fragment>
    {map(
      ({ fileName, title, path = concat(assetPath, fileName), extension }) => (
        <BestandView
          key={fileName}
          className={className}
          fileName={fileName}
          path={path}
          title={title}
          extension={extension}
          edit={edit}
          onDelete={handleDelete}
        />
      )
    )(files)}

    {edit && (
      <Dropzone
        className={classNames("multi-upload-dropzone", {
          "has-values": !isEmpty,
        })}
        multiple
        onDrop={handleDrop}
        activeStyle={{
          backgroundColor: lightGreen100,
        }}
      >
        <span>
          Sleep bestanden hierin of <span className="fake-link">selecteer</span>
        </span>
      </Dropzone>
    )}
  </Fragment>
);

MeervoudigBestand.propTypes = {
  ...propTypes,
  field: fileFieldShape.isRequired,
};

export default compose(
  setDisplayName("MeervoudigBestand"),
  setPropTypes({
    ...propTypes,
    field: fileFieldShape.isRequired,
    value: fileFieldValueShape,
  }),
  defaultProps({
    className: "meervoudig-bestand",
  }),
  branch(
    (props) => get("field.isEmpty")(props) && get("pageActions.edit") === false,
    renderNothing
  ),
  withDefaultPageActions(),
  withHandlers({
    handleDrop:
      ({
        setValue,
        value: origValue = empty.object,
        allowedFiles: allowedExtensions,
        notAllowed,
      }) =>
      (files) => {
        const { files: origFiles = empty.array } = origValue;
        const append = (newFiles) => {
          const nextFiles = uniqBy("fileName")([...origFiles, ...newFiles]);
          setValue({ value: { ...origValue, files: nextFiles } });
        };

        const wrongExtensions = flown(
          files,
          map(getExtension),
          uniq,
          without(allowedExtensions)
        );
        for (const wrongExtension of wrongExtensions) {
          notAllowed(wrongExtension);
        }

        const goodFiles = flown(
          files,
          innerJoin(getExtension, allowedExtensions, identity),
          map(head)
        );
        if (isNonEmptyArray(goodFiles)) {
          uploadMulti(append)(goodFiles);
        }
      },
    handleDelete:
      ({ setValue, value: origValue }) =>
      (fileName) => {
        const { files: origFiles = empty.array } = origValue || empty.object;
        const files = reject({ fileName })(origFiles);
        setValue({ value: { ...origValue, files } });
      },
  }),
  mapProps(({ value = empty.array, ...props }) => ({
    value: structuredMap({ files: map(appendExtension) })(value),
    ...props,
  }))
)(MeervoudigBestand);
