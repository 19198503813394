import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getOfficeUrlAction } from "../actions/session";
import { toStorage } from "../api/auth.common";
import { Loading } from "../components/login";

const LoginMicrosoft365 = () => {
  const dispatch = useDispatch();
  const loginUri = useSelector((state) => state.session.office365?.loginUri);

  useEffect(() => {
    if (!loginUri) {
      dispatch(getOfficeUrlAction());
    }
  }, [dispatch, loginUri]);

  if (loginUri) {
    toStorage("saar_loginsource", "office365");
    window.location.href = loginUri;
  }

  return <Loading />;
};

export default LoginMicrosoft365;
