import TableHeaderColumn from "material-ui/Table/TableHeaderColumn";
import TableRow from "material-ui/Table/TableRow";

import { emptyArray } from "../../business/models";
import { upperize } from "../../business/string";

export const cellClassNames = (properties = emptyArray(), property: string) =>
  `${property} one-of-${properties.length}`;

export const cellStyle = (properties = emptyArray(), property: string) => {
  const titleWidth =
    properties.length < 5 ? 50 : properties.length === 5 ? 40 : 30;

  if (property === "titel") {
    return { width: `${titleWidth}%` };
  }

  const myWidth = Math.round((100 - titleWidth) / (properties.length - 1));
  return { width: `${myWidth}%` };
};

export const preheader = (properties: string[]) => (
  <TableRow className="preheader">
    {properties.map((property) => (
      <TableHeaderColumn
        key={property}
        style={cellStyle(properties, property)}
        className={cellClassNames(properties, property)}
      >
        {property !== "icoonlink" && (
          <span className="sort">{upperize(property)}</span>
        )}
      </TableHeaderColumn>
    ))}
  </TableRow>
);
