import empty from "empty";
import React from "react";
import { compose, setDisplayName, withHandlers } from "recompose";

import { withoutProps } from "../../recompose.contrib";

const defaultStyle = {
  border: "none",
  color: "inherit",
  fontFamily: "inherit",
  fontSize: "inherit",
  margin: 0,
  padding: 0,
  width: "100%",
};

export const onChange =
  ({ regex, setValue }) =>
  ({
    target: {
      value,
      validity: { valid },
    },
  }) =>
    setValue({
      value,
      valid: valid && (!value || !regex || regex.test(value)),
    });

export const onBlur =
  ({ regex, transformValue, setValue }) =>
  ({ target: { value } }) => {
    if (typeof transformValue !== "function") {
      return;
    }

    const transformedValue = transformValue(value);
    setValue({
      value: transformedValue,
      valid: !transformedValue || !regex || regex.test(transformedValue),
    });
  };

const PlainInput = ({
  value,
  style = empty.object,
  explanation,
  placeholder,
  onChange,
  onBlur,
  ...props
}) => [
  explanation && (
    <div className="explanation" key="explanation">
      {explanation}
    </div>
  ),
  <input
    key="input"
    {...props}
    style={{ ...defaultStyle, ...style }}
    onChange={onChange}
    onBlur={onBlur}
    value={value}
    placeholder={placeholder}
  />,
];

export default compose(
  setDisplayName("PlainInput"),
  withHandlers({ onChange, onBlur }),
  withoutProps("transformValue", "setValue")
)(PlainInput);
