/* eslint  react/prop-types: off */
import empty from "empty";
import DropDownMenu from "material-ui/DropDownMenu";
import MenuItem from "material-ui/MenuItem";
import { amber900 } from "material-ui/styles/colors";
import React from "react";
import { compose, setDisplayName, withHandlers, withState } from "recompose";

import { execOnChange } from "../../../recompose.contrib";

const legoblokMoveStyle = Object.freeze({
  display: "inline-flex",
  position: "relative",
  letterSpacing: "0",
  fontSize: "inherit",
  marginTop: "-3px",
  height: "auto",
});

const legoblokMoveLabelStyle = Object.freeze({
  color: amber900,
  paddingLeft: "0",
  height: "auto",
  lineHeight: "inherit",
});

const legoblokMoveUnderlineStyle = Object.freeze({
  borderTop: "none",
});

const LegoblokMoveButton = ({
  structures = empty.array,
  moveLegoblok = empty.func,
  selected,
  disabled,
}) => (
  <DropDownMenu
    className="legoblok-move"
    value={selected}
    style={legoblokMoveStyle}
    onChange={moveLegoblok}
    disabled={disabled}
    labelStyle={legoblokMoveLabelStyle}
    underlineStyle={legoblokMoveUnderlineStyle}
  >
    {structures.map(({ id, name }) => (
      <MenuItem primaryText={name} key={id} value={id} />
    ))}
  </DropDownMenu>
);

export default compose(
  setDisplayName("LegoblokMoveButton"),
  withState("selected", "update", ({ structureId }) => structureId),
  withHandlers({
    moveLegoblok:
      ({ structureId, moveLegoblokTo, update, itemId }) =>
      (event, index, value) => {
        event.preventDefault();
        update(value);

        if (value && structureId !== value) {
          moveLegoblokTo(itemId, value);
        }
      },
  }),
  execOnChange(({ structureId, update }) => {
    update(structureId);
  }, "structureId")
)(LegoblokMoveButton);
