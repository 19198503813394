import { RaisedButton } from "material-ui";
import React, { useCallback, useState } from "react";

import { PageActions } from "../../../../business/models";
import { buttonStyle } from "../../../material/addButton";
import AddVerantwoordingDialog from "./addVerantwoordingDialog";

interface Props {
  pageActions: PageActions;
}

const AddVerantwoording = ({ pageActions }: Props): JSX.Element => {
  const [open, setOpen] = useState(false);
  const toggleOpen = useCallback(() => setOpen(!open), [open]);

  return (
    <>
      <RaisedButton
        label="Verantwoording koppelen"
        style={buttonStyle}
        secondary
        onClick={toggleOpen}
      />
      {open && (
        <AddVerantwoordingDialog
          onClose={toggleOpen}
          pageActions={pageActions}
        />
      )}
    </>
  );
};

export default AddVerantwoording;
