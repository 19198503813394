import { loadingProgress } from "async-lifecycle";
import empty from "empty";
import {
  compact,
  filter,
  find,
  flow,
  get,
  groupBy,
  includes,
  isBoolean,
  map,
  orderBy,
  some,
} from "lodash/fp";
import { createSelector } from "reselect";

import { Side, projectType } from "../business/relations";
import { getRelationsCreator } from "../containers/relations";
import { flown } from "../lodash";
import { pagetypeGroupFromAlias, pagetypeGroups } from "../pagetypes";
import { isNonEmptyArray } from "../utils";
import { pageActionsSelector } from "./tabs";

export const scopeSelector = ({
  page: { siteId } = empty.object,
  biebStructures = empty.array,
  miniSaars = empty.array,
}) => {
  const iznetSites =
    flown(miniSaars, find({ alias: "iznet" }), get("sites")) || empty.array;
  switch (true) {
    case some({ id: siteId })(biebStructures):
      return 12;
    case some({ siteId })(iznetSites):
      return 1;
    default:
      return 0;
  }
};

const initialLoading = ({ loading, reloading }) =>
  (isBoolean(loading) ? loading : loadingProgress(loading)) && !reloading;

export const relationsSelector = createSelector(
  [
    ({ id }) => id,
    ({ relations }) => relations,
    ({ types }) => types,
    ({ miniSaars }) => miniSaars,
    ({ pageActions }) => pageActions,
    initialLoading,
    ({ selectedSaar }) => selectedSaar,
    ({ section = false }) => section,
    scopeSelector,
  ],
  (
    id,
    relations,
    types,
    miniSaars,
    pageActions,
    loading,
    selectedSaar = empty.object,
    section,
    scope
  ) =>
    getRelationsCreator(id, relations, types, miniSaars, pageActions, {
      edit: pageActions.edit === true,
      loading,
      selectedSaar,
      prohibitEdit: section,
      scope,
    })
);

export const presentationRelationsSelector = createSelector(
  [
    ({ id }) => id,
    ({ relations }) => relations,
    ({ types }) => types,
    ({ miniSaars }) => miniSaars,
    ({ pageActions }) => pageActions,
    initialLoading,
    ({ selectedSaar }) => selectedSaar,
    ({ section = false }) => section,
    scopeSelector,
  ],
  (
      id,
      relations,
      types,
      miniSaars,
      pageActions,
      loading,
      selectedSaar = empty.object,
      section,
      scope
    ) =>
    (relatieToevoegen, authorizationMask) =>
      getRelationsCreator(id, relations, types, miniSaars, pageActions, {
        edit: pageActions.edit === true,
        loading,
        selectedSaar,
        prohibitEdit: section,
        hideEmpty: isNonEmptyArray(relatieToevoegen),
        scope,
        authorizationMask,
      })
);

const processItemIds = (id, relations) =>
  flow(
    map(({ left, right }) => (right.itemId === id ? left : right)),
    filter(
      ({ type: pagetype }) =>
        pagetypeGroupFromAlias(pagetype) === pagetypeGroups.proces
    ),
    map(({ itemId }) => itemId)
  )(relations);

export const processItemsSelector = createSelector(
  [({ id }) => id, ({ relations }) => relations],
  (id, relations) => processItemIds(id, relations)
);

const getHierarchicalRelationTypesSelector = createSelector(
  [({ types }) => types],
  (types) => types.filter((t) => t.hierarchically)
);

const relationTypesByPagetypeSelector = createSelector(
  [
    ({ pagetype }) => pagetype,
    getHierarchicalRelationTypesSelector,
    (_, side) => side,
  ],
  (pagetype, types, side) =>
    types.filter(
      ({ [Side[side]]: { pagetype: pagetypeSelf = "" } = empty.object }) =>
        pagetypeSelf === pagetype
    )
);

const createRelationTypeObject = (side) => (t) =>
  t[Side[Side.rev(side)]].pagetype === "project"
    ? projectType({ alias: t.alias, side })
    : { alias: t.alias, side };

export const hierarchicalRelationTypesSelector = createSelector(
  [relationTypesByPagetypeSelector, (_, side) => side],
  (types, side) =>
    orderBy(
      ["side", "alias"],
      ["desc", "asc"]
    )(types.map(createRelationTypeObject(side)))
);

export const hierarchicalRelationsSelector = createSelector(
  [relationsSelector, hierarchicalRelationTypesSelector],
  (getRelations, types) => getRelations(...types)
);

export const legoblokRelaties = Object.freeze([
  "relatie_legoblok_groep",
  "relatie_legoblok_persoon",
  "relatie_legoblok_procesgroep",
  "relatie_legoblok_bedrijfsproces",
  "relatie_legoblok_procesonderdeel",
  "relatie_legoblok_activiteit",
]);

export const legoblokRelationsSelector = createSelector(
  [relationsSelector, ({ pagetype }) => pagetype],
  (getRelations, pagetype) =>
    pagetype && includes(`relatie_legoblok_${pagetype}`)(legoblokRelaties)
      ? getRelations({
          alias: `relatie_legoblok_${pagetype}`,
          side: Side.right,
        })
      : undefined
);

export const biebRelationsSelector = createSelector(
  [relationsSelector, ({ pagetype }) => pagetype],
  (getRelations, pagetype) =>
    pagetype
      ? getRelations({ alias: `bieb_${pagetype}`, side: Side.left })
      : undefined
);

const filterProcessRelationType = (side, pagetype) => (type) =>
  // geen bieb of legoblok relatietypen
  !/bieb|legoblok/.test(type.alias) &&
  // deze kant is gelijk aan huidige paginatype
  type[Side[side]].pagetype === pagetype &&
  // andere kant is van het type proces
  pagetypeGroupFromAlias(type[Side[Side.rev(side)]].pagetype) ===
    pagetypeGroups.proces &&
  // als beide kanten proces, dan ook hie = 1
  ((pagetypeGroupFromAlias(pagetype) === pagetypeGroups.proces &&
    type.hierarchically) ||
    // of huidige paginatype is geen procestype
    pagetypeGroupFromAlias(pagetype) !== pagetypeGroups.proces);

const createProcessRelationTypeObject =
  (side) =>
  ({ alias }) => ({
    alias,
    side,
  });

export const processRelationTypesSelector = createSelector(
  [
    ({ types }) => types,
    ({ page: { page: { pagetype } = empty.object } = empty.object }) =>
      pagetype,
    pageActionsSelector,
  ],
  (types, pagetype, { edit = false }) => {
    if (!edit) {
      return empty.array;
    }

    const processTypesLeft = flow(
      filter(filterProcessRelationType(Side.left, pagetype)),
      map(createProcessRelationTypeObject(Side.left))
    )(types);
    const processTypesRight = flow(
      filter(filterProcessRelationType(Side.right, pagetype)),
      map(createProcessRelationTypeObject(Side.right))
    )(types);

    const processTypes = compact([...processTypesLeft, ...processTypesRight]);
    return orderBy(["side", "alias"], ["desc", "asc"])(processTypes);
  }
);

export const koppelingFieldsSelector = ({ koppelingFields }) => koppelingFields;

export const koppelingVeldenRelationsSelector = createSelector(
  [
    ({ relations }) => relations,
    ({ types }) => types.alias.veld_koppeling_veld,
  ],
  (relations = empty.array, { id } = empty.object) =>
    flow(
      filter({ relationTypeId: id }),
      map(
        ({
          left,
          right,
          using: [
            {
              itemId: metItemId,
              pageClusterId: metPageClusterId,
              ...rest
            } = empty.object,
          ] = empty.array,
        }) => ({
          van: { ...left },
          nar: { ...right },
          met: {
            itemId: metItemId,
            pageClusterId: metPageClusterId,
            ...rest,
          },
          id: `${left.itemId}-${left.pageClusterId}-${metItemId}-${metPageClusterId}-${right.itemId}-${right.pageClusterId}`,
        })
      ),
      groupBy(({ met: { pageClusterId } = empty.object }) => pageClusterId)
    )(relations)
);

// noinspection JSUnusedLocalSymbols
export const chainRelationsSelector = createSelector(
  [
    ({ data: { chainRelations = empty.object } = empty.object }) =>
      chainRelations,
    (_, { id }) => id,
  ],
  (chainRelations, itemId) => chainRelations[`${itemId}`]
);
