import React from "react";

import { CountedIconProps } from "./models";

const CountedIcon = React.memo<CountedIconProps>(
  ({ children, count, title }) => (
    <span
      title={title}
      style={{
        display: "inline-block",
        position: "relative",
        padding: "0 8px 0 4px",
        opacity: count === 0 ? 0.3 : undefined,
      }}
    >
      {count > 0 && (
        <span
          style={{
            display: "inline-block",
            position: "absolute",
            textAlign: "center",
            top: 0,
            right: 0,
            fontSize: 12,
            width: 16,
            height: 16,
            borderRadius: "50%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            color: "white",
          }}
        >
          {count}
        </span>
      )}
      {children}
    </span>
  )
);

export default CountedIcon;
