import { FlatButton, MenuItem, SelectField } from "material-ui";
import React, { useCallback, useState } from "react";

import { PageActions } from "../../../../business/models";
import Dialog from "../../../../containers/dialog";
import { Label } from "../../../content/field";
import { ApplicatieGegeven, VeldCluster } from "./models";

export interface MoveVeldenDialogProps {
  velden: VeldCluster[];
  gegevens: ApplicatieGegeven[];
  pageActions: PageActions;
  onClose: () => void;
}

export interface FormData {
  veldIds: number[];
  gegevenId?: number;
}

const initialFormData: FormData = {
  veldIds: [],
  gegevenId: undefined,
};

const MoveVeldenDialog = ({
  velden,
  gegevens,
  onClose,
  pageActions,
}: MoveVeldenDialogProps): JSX.Element => {
  const [form, setForm] = useState<FormData>(initialFormData);
  const handleVeldenChange = useCallback(
    (_event: unknown, _key: unknown, values: number[]) => {
      setForm({ ...form, veldIds: values });
    },
    [form]
  );

  const handleGegevenChange = useCallback(
    (_event: unknown, _key: unknown, value: number) => {
      setForm({ ...form, gegevenId: value });
    },
    [form]
  );

  const handleMove = useCallback(() => {
    pageActions.add("ApplicatieVeldenMove", form);
    pageActions.save(true);
    onClose();
  }, [form, onClose, pageActions]);

  return (
    <Dialog
      open
      autoScrollBodyContent
      title="Applicatievelden verplaatsen"
      actions={[
        <FlatButton
          key="cancel"
          label="Annuleren"
          secondary
          onClick={onClose}
        />,
        <FlatButton
          key="ok"
          label="Verplaatsen"
          primary
          disabled={form.veldIds.length === 0 || !form.gegevenId}
          onClick={handleMove}
        />,
      ]}
    >
      <div style={{ display: "flex", flex: "1 1 0" }}>
        <div style={{ width: "50%" }}>
          <Label>Velden</Label>
          <SelectField
            hintText="Selecteer de velden om te verplaatsen"
            multiple
            fullWidth
            value={form.veldIds}
            onChange={handleVeldenChange}
            style={{ marginRight: 8 }}
          >
            {velden.map(({ clusterId, naam: { value: naam } }) => (
              <MenuItem
                key={clusterId}
                insetChildren
                value={clusterId}
                primaryText={naam}
                checked={form.veldIds.includes(clusterId)}
              />
            ))}
          </SelectField>
        </div>
        <div style={{ width: "50%" }}>
          <Label>Gegeven</Label>
          <SelectField
            hintText="Selecteer het doelgegeven"
            fullWidth
            value={form.gegevenId}
            onChange={handleGegevenChange}
            style={{ marginLeft: 8 }}
          >
            {gegevens.map(
              ({
                clusterId,
                gegeven: {
                  naam: { value: naam },
                },
              }) => (
                <MenuItem
                  key={clusterId}
                  value={clusterId}
                  primaryText={naam}
                />
              )
            )}
          </SelectField>
        </div>
      </div>
    </Dialog>
  );
};

export default MoveVeldenDialog;
