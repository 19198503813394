import { composeAction } from "async-lifecycle";

import {
  getEntiteitOverview,
  getVerwerkingen,
  getZibExpansion,
  getZibOverview,
  saveEntiteitRelations,
} from "../api/application";
import { clearPageActions } from "./cms";
import { getPageAction } from "./data";
import { legoblokStructuresAction } from "./legoblok.structures";
import { invalidatePage } from "./page";
import { invalidateRelations } from "./relations.invalidate";

export const addGegevenAction = (open) => ({
  type: "APPLICATION_GEGEVEN_ADD_DIALOG",
  payload: { open },
});

const getEntiteiten = () =>
  composeAction({
    group: "ENTITEITEN",
    fire: getEntiteitOverview,
    cachekey: "entiteiten",
    timeout: 10000,
  });

export const requireEntiteiten = () =>
  composeAction(legoblokStructuresAction(), {
    callbackQuit: (dispatch) => dispatch(getEntiteiten()),
    callbackSuccess: (_, dispatch) => dispatch(getEntiteiten()),
  });

export const requireVerwerkingen = () =>
  composeAction({
    group: "VERWERKINGEN",
    fire: getVerwerkingen,
    cachekey: "verwerkingen",
    timeout: 10000,
  });

export const initGegevenDialog = requireEntiteiten;

export const saveGegevensAction = (gegeven) =>
  composeAction({
    group: "APPLICATION_GEGEVEN_SAVE",
    fire: saveEntiteitRelations,
    args: [gegeven],
    callbackSuccess: (_, dispatch) => {
      dispatch(clearPageActions());
      dispatch(invalidateRelations(gegeven.pageId));
      dispatch(invalidateRelations(gegeven.entiteitId));
      dispatch(invalidatePage(gegeven.pageId));
      dispatch(getPageAction(gegeven.pageId));
    },
  });

const getZibs = () =>
  composeAction({
    group: "ZIBS",
    fire: getZibOverview,
    cachekey: "zibs",
    timeout: 10000,
  });

export const requireZibs = () =>
  composeAction(legoblokStructuresAction(), {
    callbackQuit: (dispatch) => dispatch(getZibs()),
    callbackSuccess: (_, dispatch) => dispatch(getZibs()),
  });

const getZibExpansionAction = () =>
  composeAction({
    group: "ZIBEXPANSION",
    fire: async () => {
      return await getZibExpansion();
    },
    cachekey: "zibExpansion",
    timeout: 10000,
  });

export const requireZibExpansion = () =>
  composeAction(legoblokStructuresAction(), {
    callbackQuit: (dispatch) => dispatch(getZibExpansionAction()),
    callbackSuccess: (_, dispatch) => dispatch(getZibExpansionAction()),
  });
