import empty from "empty";
import {
  compact,
  defaultTo,
  entries,
  filter,
  flatten,
  flattenDeep,
  flow,
  get,
  has,
  head,
  keys,
  map,
  reduce,
  some,
  uniq,
  values,
} from "lodash/fp";

import { flown } from "../../lodash";
import { arrayFlatMap, structuredMap } from "../../utils";

export const descendants = (cluster) => {
  let idsList = [];
  if (!cluster || !cluster.clusterId) {
    return idsList;
  }

  idsList.push(cluster.clusterId);

  const objKeys = Object.keys(cluster);
  for (let i = 0; i < objKeys.length; i++) {
    const key = objKeys[i];
    const child = cluster[key];
    if (child.iproxType !== "field") {
      idsList.push(child.clusterId);
      if (child.clusters) {
        for (let j = 0; j < child.clusters.length; j++) {
          idsList.push(...descendants(child.clusters[j]));
        }
      } else {
        idsList.push(...descendants(child));
      }
    }
  }

  return flow(compact, uniq)(idsList);
};

const visibleFieldTypes = [
  1, 2, 3, 4, 5, 6, 7, 10, 11, 13, 16, 17, 20, 21, 97, 272, 273, 274,
].reduce((a, ggvtyp) => ({ ...a, [ggvtyp]: true }), empty.object);

export const isField = (obj) => obj?.iproxType === "field";

export const isCluster = ({ iproxType } = empty.object) =>
  iproxType === "cluster";

const isExplicitlyHiddenField = (
  { definition: { dataTypeCode, name } = empty.object, value },
  { busy = false, edit = false } = empty.object
) =>
  (!busy && !edit && dataTypeCode === 1 && value === false) ||
  (dataTypeCode === 10 && name === "Foto") ||
  (dataTypeCode === 10 && name === "Afbeelding");

export const isVisibleField = (obj = empty.object, pageActions) => {
  const { busy = false, edit = false } = pageActions ?? empty.object;
  return (
    isField(obj) &&
    obj.definition &&
    visibleFieldTypes[obj.definition.dataTypeCode] &&
    (!obj.isEmpty || busy || edit) &&
    !isExplicitlyHiddenField(obj, pageActions)
  );
};

export const isVisible = (obj, pageActions) =>
  obj &&
  /* eslint-disable @typescript-eslint/no-use-before-define */
  (isVisibleField(obj, pageActions) ||
    hasVisibleField(obj, pageActions) ||
    isAddable(obj, pageActions));
/* eslint-enable @typescript-eslint/no-use-before-define */

export const hasVisibleField = (obj = empty.array, pageActions) =>
  Array.isArray(obj)
    ? some((obj) => isVisible(obj, pageActions))(obj)
    : isVisibleField(obj, pageActions) ||
      (!obj.selectieInstellingen &&
        !(obj.iproxType === "cluster" && obj.names[0] === "Meta") &&
        flow(
          keys,
          map((key) => obj[key]),
          filter(
            (o) => o && o !== obj && typeof (o[o.iproxType] || o) === "object"
          ),
          some((o) => hasVisibleField(o[o.iproxType] || o, pageActions))
        )(obj));

export const containsText = (text) => {
  const lower = text.toLowerCase();
  return function includesText(obj) {
    switch (true) {
      case !text:
        return true;
      case Array.isArray(obj):
        return flown(obj, some(includesText));
      case typeof obj === "object":
        return flown(obj, values, includesText);
      case typeof obj === "string":
        return obj.toLowerCase().includes(lower);
      default:
        return false;
    }
  };
};

export const repetitionDepth = (obj) => {
  switch (obj?.iproxType) {
    case "clusters":
      const sub = flown(
        obj.clusters,
        map(values),
        flatten,
        map(repetitionDepth),
        reduce((x, y) => {
          const n = Number(y);
          return n > x ? n : x;
        }, 0)
      );
      return 1 + sub;
    case "cluster":
      return flown(
        obj,
        values,
        map(repetitionDepth),
        reduce((x, y) => {
          const n = Number(y);
          return n > x ? n : x;
        }, 0)
      );
    default:
      return 0;
  }
};

export const containsClusterId = (clusterId) =>
  flow(
    values,
    filter({ iproxType: "clusters" }),
    arrayFlatMap(
      (
        {
          clusters: one = empty.array,
          moreClusters: two = empty.array,
        } = empty.object
      ) => [...one, ...two]
    ),
    some(
      (cluster) =>
        cluster.clusterId === clusterId || containsClusterId(clusterId)(cluster)
    )
  );

const isAddable = (
  { iproxType } = empty.object,
  { edit = false } = empty.object
) => edit && iproxType === "clusters";

export const members = ({ iproxType, [iproxType]: members }) => members;

export const filterClusterRelations = (clusterId) => {
  return flow(
    map(structuredMap({ links: filter({ pageClusterId: clusterId }) })),
    filter(({ links }) => links.length > 0)
  );
};

export const getChildClusters = (cluster = empty.object, pageActions) =>
  flow(
    keys,
    map((key) => cluster[key]),
    filter(
      (obj) =>
        has("iproxType")(obj) &&
        obj.iproxType !== "field" &&
        hasVisibleField(obj, pageActions)
    )
  )(cluster);

export const getFirstChild = (cluster = empty.object, pageActions) =>
  flow(
    keys,
    map((key) => cluster[key]),
    filter((obj) => has("iproxType")(obj) && hasVisibleField(obj, pageActions)),
    head,
    defaultTo(null)
  )(cluster);

export const getClusterLabel = (cluster, pageActions, clusterName) => {
  const child = getFirstChild(cluster, pageActions);
  if (!child) {
    return clusterName;
  }

  const isLabelField = ({
    iproxType,
    definition: { dataTypeCode } = empty.object,
    isEmpty,
  }) =>
    iproxType === "field" && [2, 6, 13, 272].includes(dataTypeCode) && !isEmpty;
  if (isLabelField(child) && typeof child.value === "string") {
    // plain text
    return child.value;
  } else if (isLabelField(child) && get("value.label")(child)) {
    // link field
    return get("value.label")(child);
  } else if (isLabelField(child) && get("value.files[0].title")(child)) {
    // first file in multiple files field
    return get("value.files[0].title")(child);
  }

  const childClusters = getChildClusters(child, pageActions);
  if (childClusters.length > 0) {
    // clusters found
    return getClusterLabel(childClusters[0], pageActions);
  } else if (child.iproxType === "cluster") {
    // cluster, may contain fields
    return getClusterLabel(child, pageActions);
  } else {
    return clusterName;
  }
};

export const showStatic = (
  content = empty.array,
  { edit = false } = empty.object,
  relations = false
) => some(Boolean)(content) || edit || relations;

const ownClusterIds = (cluster) => {
  switch (cluster.iproxType) {
    case "cluster":
      return [cluster.clusterId];
    case "clusters":
      return cluster.clusters.map((c) => deepClusterIds(c));
    default:
      return [];
  }
};

export const deepClusterIds = (content) => {
  if (content.clusterId) {
    const children = flow(
      entries,
      filter(([_, c]) => c.iproxType),
      flatten,
      compact,
      map(ownClusterIds),
      flattenDeep
    )(content);
    return [content.clusterId, ...children];
  }
};
