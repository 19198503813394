import classNames from "classnames";
import { isEqual } from "lodash/fp";
import {
  Checkbox,
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
} from "material-ui";
import { useCallback } from "react";

import {
  FieldData,
  FieldDataValue,
  WithClusterId,
} from "../../business/models";
import Field from "../content/field";
import { CompareStaticFieldsProps } from "./models";
import WithEnlarge from "./WithEnlarge";

const stripTags = (s: string) => s.replace(/<[^>]*>/g, "").trim();

export const areValuesEqual = (
  a: FieldDataValue | undefined,
  b: FieldDataValue | undefined
) => {
  if (isEqual(a, b)) {
    return true;
  }

  if (typeof a !== "string" || typeof b !== "string") {
    return false;
  }

  return stripTags(a) === stripTags(b);
};

const CompareStaticFields = ({
  biebItemId,
  localItemId,
  zip,
  pageActions,
  showAllFields,
  showEmptyMessage,
  extraRows,
  selected,
  withCheckAll,
  disabled,
}: CompareStaticFieldsProps) => {
  const handleAddUpdate = useCallback(
    (
      biebField: FieldData & WithClusterId,
      localField: FieldData & WithClusterId
    ) => {
      if (localField && biebField) {
        pageActions.fieldCopy({
          itemId: localItemId,
          sourceItemId: biebItemId,
          sourceFieldId: biebField.id,
        });
      }
    },
    [biebItemId, localItemId, pageActions]
  );

  const handleRemoveUpdate = useCallback(
    (biebField: FieldData & WithClusterId) => {
      pageActions.fieldCopyRevert({
        itemId: localItemId,
        sourceItemId: biebItemId,
        sourceFieldId: biebField.id,
      });
    },
    [biebItemId, localItemId, pageActions]
  );

  const onlyChangesZip = zip.filter(
    ([_path, biebField, localField]) =>
      !(biebField && areValuesEqual(biebField?.value, localField?.value))
  );

  const filteredZip = zip.filter(([_path, biebField, localField]) => {
    return showAllFields
      ? true
      : !(biebField && areValuesEqual(biebField?.value, localField?.value));
  });

  return filteredZip.length > 0 || extraRows ? (
    <>
      {withCheckAll && (
        <div
          style={{
            margin: 0,
            padding: "16px 0 8px 16px",
            borderBottom: "1px solid rgb(224, 224, 224)",
          }}
        >
          <Checkbox
            style={{ top: "calc(50% - 12px)" }}
            labelStyle={{ color: "rgba(0, 0, 0, 0.87)", fontStyle: "italic" }}
            onCheck={(event, isChecked) => {
              if (isChecked) {
                filteredZip.forEach(([_path, biebField, localField]) => {
                  handleAddUpdate(biebField!, localField!);
                });
              } else {
                filteredZip.forEach(([_path, biebField]) => {
                  handleRemoveUpdate(biebField!);
                });
              }
            }}
            checked={
              onlyChangesZip.length === 0 ||
              filteredZip.every(([_path, biebField]) =>
                selected?.includes(biebField!.id)
              )
            }
            disabled={disabled || onlyChangesZip.length === 0}
            label="Alles selecteren"
          />
        </div>
      )}

      <Table selectable={false} className="bieb-compare-table">
        <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
          <TableRow>
            <TableHeaderColumn></TableHeaderColumn>
            <TableHeaderColumn colSpan={2}>Bieb</TableHeaderColumn>
            <TableHeaderColumn colSpan={2}>Saar</TableHeaderColumn>
          </TableRow>
          {
            extraRows ?? (
              <></>
            ) /* Moet blijven staan, anders wordt om de een of andere reden de header verdubbeld?! */
          }
        </TableHeader>
        <TableBody displayRowCheckbox={false}>
          {filteredZip.map(([path, biebField, localField]) => {
            const key = path.join(".");
            const areEqual =
              biebField && areValuesEqual(biebField?.value, localField?.value);
            const required = Boolean(biebField?.definition.required);

            const biebFieldNode = !biebField?.isEmpty ? (
              <Field
                className={classNames({ required })}
                fullWidth
                field={biebField}
                title={required ? "Verplicht veld" : ""}
              />
            ) : (
              <em>Leeg veld</em>
            );

            const localFieldNode = !localField?.isEmpty ? (
              <Field
                className={classNames({ required })}
                fullWidth
                field={localField}
                title={required ? "Verplicht veld" : ""}
              />
            ) : (
              <em>Leeg veld</em>
            );

            return (
              <TableRow key={key}>
                <TableRowColumn>
                  {(biebField || localField) && (
                    <strong
                      className={classNames("label", { required })}
                      title={required ? "Verplicht veld" : ""}
                    >
                      {(biebField || localField)?.names[0]}
                    </strong>
                  )}
                </TableRowColumn>
                <TableRowColumn className="radio-btn">
                  {!areEqual && !disabled && (
                    <input
                      type="radio"
                      name={key}
                      value={biebField?.id}
                      style={{ top: "calc(50% - 9px)" }}
                      onClick={() => handleAddUpdate(biebField!, localField!)}
                      checked={selected?.includes(biebField!.id)}
                    />
                  )}
                </TableRowColumn>
                <TableRowColumn>
                  {biebField?.definition.dataTypeCode === 5 ? (
                    <WithEnlarge field={biebFieldNode} />
                  ) : (
                    biebFieldNode
                  )}
                </TableRowColumn>
                <TableRowColumn className="radio-btn">
                  {!areEqual && !disabled && (
                    <input
                      type="radio"
                      name={key}
                      value={localField?.id}
                      style={{ top: "calc(50% - 9px)" }}
                      defaultChecked
                      onClick={() => handleRemoveUpdate(biebField!)}
                      checked={
                        selected ? !selected.includes(biebField!.id) : undefined
                      }
                    />
                  )}
                </TableRowColumn>
                <TableRowColumn>
                  {localField?.definition.dataTypeCode === 5 ? (
                    <WithEnlarge field={localFieldNode} />
                  ) : (
                    localFieldNode
                  )}
                </TableRowColumn>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </>
  ) : showEmptyMessage ? (
    <p style={{ padding: "0 16px 0 16px" }}>
      Er is geen inhoud gevonden om bij te werken.
    </p>
  ) : null;
};

export default CompareStaticFields;
