import classNames from "classnames";
import { FlatButton, List, ListItem, RaisedButton } from "material-ui";
import ActionSwapVert from "material-ui/svg-icons/action/swap-vert";
import IconDragHandle from "material-ui/svg-icons/editor/drag-handle";
import React, { CSSProperties, useCallback, useEffect, useState } from "react";
import {
  SortableContainer,
  SortableContainerProps,
  SortableElement,
  SortableElementProps,
  arrayMove,
} from "react-sortable-hoc";

import { PageActions } from "../business/models";
import Dialog from "../containers/dialog";
import HintCard from "./material/HintCard";

const ClusterListItem = ({
  label,
  style,
  className,
  ...props
}: {
  label: string;
  style?: CSSProperties;
  className?: string;
}) => (
  <ListItem
    leftIcon={<IconDragHandle />}
    className={classNames("relations-sort-draggable", className)}
    style={style}
    {...props}
  >
    {label}
  </ListItem>
);

const SortableItem: React.ComponentClass<
  {
    label: string;
    style?: CSSProperties;
    className?: string;
  } & SortableElementProps
> = SortableElement(ClusterListItem);

interface ClusterListProps {
  clusters: ClusterItem[];
  style?: CSSProperties;
  className?: string;
}

const ClusterList = ({
  clusters,
  style,
  className,
  ...props
}: ClusterListProps) => (
  <List
    className={className}
    style={{ userSelect: "none", ...style }}
    {...props}
  >
    {clusters.map((c, i) => (
      <SortableItem key={c.id} label={c.label} index={i} />
    ))}
  </List>
);

const SortableList: React.ComponentClass<
  ClusterListProps & SortableContainerProps
> = SortableContainer(ClusterList);

export interface ClusterItem {
  id: number;
  label: string;
}

export interface ClustersOrderDialogProps {
  clusterId?: number;
  name: string;
  enabled: boolean;
  clusters: ClusterItem[];
  pageActions: PageActions;
}

const ClustersOrderDialog = ({
  clusterId,
  name,
  enabled,
  clusters,
  pageActions,
}: ClustersOrderDialogProps) => {
  const [sortedClusters, setSortedClusters] = useState(clusters);
  const [show, setShow] = useState(false);
  const [busy, setBusy] = useState(false);

  useEffect(() => {
    if (!show) {
      setSortedClusters(clusters);
    }
  }, [clusters, show]);

  const sortByAlphabet = useCallback(() => {
    setSortedClusters(
      sortedClusters.slice().sort((a, b) => a.label.localeCompare(b.label))
    );
  }, [sortedClusters]);

  const openDialog = useCallback(() => {
    setShow(true);
  }, []);

  const closeDialog = useCallback(() => {
    setShow(false);
  }, []);

  const saveSortedList = useCallback(() => {
    setBusy(true);
    pageActions.clusterOrder({
      clusterId,
      name,
      clusterIds: sortedClusters.map((c) => c.id),
    });
    pageActions.save();
    closeDialog();
    setBusy(false);
  }, [closeDialog, clusterId, name, pageActions, sortedClusters]);

  return (
    <>
      <span title="Volgorde wijzigen">
        <RaisedButton
          icon={<ActionSwapVert />}
          secondary
          style={{ margin: "16px 0", minWidth: 36 }}
          disabled={!enabled}
          onClick={openDialog}
        />
      </span>

      {show && (
        <Dialog
          title="Volgorde wijzigen"
          open
          autoScrollBodyContent
          actions={[
            <FlatButton
              style={{ float: "left" }}
              label="Alfabetiseren"
              disabled={busy}
              onClick={sortByAlphabet}
            />,
            <FlatButton
              key="close"
              secondary
              label="Annuleren"
              onClick={closeDialog}
              disabled={busy}
            />,
            <FlatButton
              primary
              key="save"
              label="Opslaan"
              onClick={saveSortedList}
              disabled={busy}
            />,
          ]}
        >
          <SortableList
            helperClass="clusters-sort"
            clusters={sortedClusters}
            distance={5}
            lockAxis="y"
            onSortEnd={({ oldIndex, newIndex }) =>
              setSortedClusters(arrayMove(sortedClusters, oldIndex, newIndex))
            }
          />
          <HintCard primaryText="Sleep de items in de goede volgorde." />
        </Dialog>
      )}
    </>
  );
};

export default ClustersOrderDialog;
