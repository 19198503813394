import { memo, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { redirectLocalAction } from "../actions/common";
import { pageEditAction } from "../actions/page";
import { CopyItemModel, StructureItem } from "../business/models";
import CopyItemDialog from "../components/CopyItemDialog";
import { structuresSelector } from "../saar-add-item/redux";
import { sagas } from "../sagas";

const CopyItemDialogContainer = ({ source }: { source: CopyItemModel }) => {
  const [busy, setBusy] = useState(false);

  const dispatch = useDispatch();

  const onClose = useCallback(() => {
    dispatch({
      type: "TOGGLE_COPYDIALOG",
      payload: { open: false },
    });
  }, [dispatch]);

  const onCopy = useCallback(
    (data: CopyItemModel) => {
      setBusy(true);
      dispatch({
        type: sagas.copyItem.require,
        payload: data,
        onSuccess: ({
          body: { itemId, slug },
        }: {
          status: number;
          body: StructureItem;
        }) => {
          setBusy(false);
          onClose();
          dispatch(redirectLocalAction(`page/${itemId}/${slug}`));
          dispatch(pageEditAction(itemId));
        },
        onError: () => {
          setBusy(false);
        },
      });
    },
    [onClose, dispatch]
  );

  const open = useSelector(
    (store: { ui: { copyItemDialog: { open: boolean } } }) =>
      Boolean(store.ui.copyItemDialog.open)
  );

  const structures = useSelector(structuresSelector);

  return open ? (
    <CopyItemDialog
      source={source}
      busy={busy}
      onClose={onClose}
      onCopy={onCopy}
      structures={structures}
    />
  ) : null;
};

export default memo(CopyItemDialogContainer);
