import classNames from "classnames";
import { filter, map } from "lodash/fp";
import PropTypes from "prop-types";
import React, { useMemo } from "react";

import { linkIdShape, zibShape } from "../../../business/prop-types";
import { flown } from "../../../lodash";
import { Label } from "../../content/field";
import ClusterPicker from "../../pickers/ClusterPicker";

const linkForCluster =
  (itemId, prefix, level) =>
  ({ id, naam, type, onderdelen }) => ({
    itemId,
    pageClusterId: id,
    label: naam,
    type:
      type === "Container"
        ? undefined
        : `${prefix}_zib_${type === "Gegeven" ? "ggv" : "sub"}${level}`,
    items: flown(
      onderdelen,
      filter((o) => prefix === "applicatieveld" || o.type !== "SubBouwsteen"),
      map(linkForCluster(itemId, prefix, level + 1)) ?? []
    ),
  });

const itemForGegeven = ({ id, naam, onderdelen }) => ({
  itemId: id,
  label: naam,
  type: "applicatiegegeven_zib",
  items: flown(
    onderdelen,
    map(linkForCluster(id, "applicatiegegeven", 1)) ?? []
  ),
});

const itemForVeld = ({ id, naam, onderdelen }) => ({
  itemId: id,
  label: naam,
  items: flown(
    onderdelen,
    filter((o) => o.type !== "SubBouwsteen"),
    map(linkForCluster(id, "applicatieveld", 1)) ?? []
  ),
});

const Value = ({ zibs, type, selected, onChange, edit }) => {
  const items = useMemo(
    () => (type === "veld" ? zibs.map(itemForVeld) : zibs.map(itemForGegeven)),
    [type, zibs]
  );

  return (
    <div className="value">
      <ClusterPicker
        items={items}
        selected={selected}
        onChange={onChange}
        clusterRequired={type === "veld"}
        multiple
        labelText="Zib"
        disabled={!edit}
      />
    </div>
  );
};

Value.propTypes = {
  zibs: PropTypes.arrayOf(zibShape.isRequired).isRequired,
  selected: PropTypes.arrayOf(linkIdShape.isRequired).isRequired,
  type: PropTypes.oneOf(["veld", "gegeven"]).isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  edit: PropTypes.bool.isRequired,
};

const ZibSelect = ({ label, edit, ...valueProps }) => (
  <div className={classNames("field cluster-relation", { "field-edit": edit })}>
    <Label>{label}</Label>
    <Value {...valueProps} edit={edit} />
  </div>
);

ZibSelect.propTypes = {
  ...Value.propTypes,
  label: PropTypes.string.isRequired,
};

export default ZibSelect;
