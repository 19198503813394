import empty from "empty";
import {
  compact,
  defaultTo,
  every,
  filter,
  find,
  flow,
  get,
  map,
  max,
  some,
} from "lodash/fp";
import { createSelector } from "reselect";

import { getContentName } from "../business/contentPlug";
import { shownRelations } from "../business/relations";
import { getContent } from "../components/content";
import { getChildClusters, getFirstChild } from "../components/content/query";
import { informatiestandaardSectieContentPlug } from "../components/pagetypes/informatiestandaard";
import { flown, setup } from "../lodash";
import { makePagetypes } from "../pagetypes";
import { isChildren, weakMemoize } from "../utils";

export const itemIdSelector = ({ page: { id = -1 } = empty.object }) => id;
export const pageSelector = ({
  page: { page = empty.object } = empty.object,
}) => page;
export const pageActionsSelector = ({ pageActions = empty.object }) =>
  pageActions;
export const pagetypesSelector = ({ pagetypes = empty.array }) => pagetypes;
export const selectionListsSelector = ({ selectionLists }) => selectionLists;
export const pagetypeListSelector = ({ pagetypeList }) => pagetypeList;
export const selectionSelector = ({ selection }) => selection;
export const notesSelector = ({ notes }) => notes;
export const formSelector = ({ form }) => form;
export const userSelector = ({ user }) => user;

export const facetsSelector = ({ facets }) => facets;
export const rowsSelector = ({ rows }) => rows;
export const onSelectSelector = ({ onSelect }) => onSelect;
export const onDeselectSelector = ({ onDeselect }) => onDeselect;

export const setFilterSelector = ({ setFilter }) => setFilter;
export const setColumnFilterSelector = ({ setColumnFilter }) => setColumnFilter;
export const setSelectionsSelector = ({ setSelections }) => setSelections;
export const setOrderSelector = ({ setOrder }) => setOrder;

export const selectionsSelector = ({ selections }) => selections;
export const filterSelector = ({ filter }) => filter;
export const columnFiltersSelector = ({ columnFilters }) => columnFilters;
export const orderSelector = ({ order }) => order;
export const profielPropsSelector = createSelector(
  [get("toggleImageLightbox")],
  setup("toggleImageLightbox")
);

export const isNonEmptyTab = ({ children, showEmpty = false } = empty.object) =>
  showEmpty || isChildren(children);

const presentationSelector = ({ presentation }) => presentation;

const getCollapseName = (cluster, collapseName) => {
  if (!collapseName) {
    return null;
  }

  const child = getFirstChild(cluster);
  if (!child) {
    return collapseName;
  }

  const isLabelField = ({
    iproxType,
    definition: { dataTypeCode, name } = empty.object,
    isEmpty,
  }) =>
    iproxType === "field" &&
    /^naam|naam$/i.test(name) &&
    dataTypeCode === 2 &&
    !isEmpty;
  if (isLabelField(child)) {
    // plain-text found
    return `${collapseName}: ${child.value}`;
  }

  const childClusters = getChildClusters(child);
  if (childClusters.length > 0) {
    // clusters found
    return getCollapseName(childClusters[0], collapseName);
  } else if (child.iproxType === "cluster") {
    // cluster, may contain fields
    return getCollapseName(child, collapseName);
  } else {
    return collapseName;
  }
};

const customOverride = (aka) => {
  switch (aka) {
    case "informatiestandaard-sectie":
      return informatiestandaardSectieContentPlug;
    default:
      return empty.object;
  }
};

const getContentPlug = weakMemoize(
  (
    zone,
    collapse,
    bulkExpand,
    search,
    fullWidth,
    obj,
    show,
    clusterNames,
    fieldNames,
    relations,
    types
  ) => {
    const relationTypes = flown(
      zone,
      get("items"),
      map("relationType"),
      compact
    );
    const relationTypeIds = map("id")(relationTypes);
    return {
      collapse: (cluster, name) => {
        const collapseName = flown(
          collapse,
          find({ name, value: true }),
          get("name"),
          defaultTo(null)
        );

        return collapseName ? getCollapseName(cluster, collapseName) : null;
      },
      bulkExpand: (cluster, name) =>
        flown(
          bulkExpand,
          find({ name, value: true }),
          get("name"),
          defaultTo(null)
        ),
      search: (cluster, name) =>
        flown(
          search,
          find({ name, value: true }),
          get("name"),
          defaultTo(null)
        ),
      fullWidth: ({ names: [name] = empty.array }) =>
        name !== undefined &&
        flown(
          fullWidth,
          find((field) => field.name === name),
          (field) => field && Boolean(field.value)
        ),
      show: ({ names: [childName] = empty.array }) => {
        const objName = get("entity.nam")(obj);
        return (
          childName !== undefined &&
          (objName === childName ||
            flown(
              show,
              every(({ name, value }) => value || name !== childName)
            ))
        );
      },
      clusterName: (child, name) => {
        const clusterName = getContentName(child, name);
        return flown(
          clusterNames,
          find({ name: clusterName }),
          get("value"),
          defaultTo(clusterName)
        );
      },
      fieldName: (child, name) => {
        const fieldName = getContentName(child, name);
        return flown(
          fieldNames,
          find({ name: fieldName }),
          get("value"),
          defaultTo(fieldName)
        );
      },
      relations: shownRelations(relations, types)(relationTypes),
      types: types.filter((t) => relationTypeIds.includes(t.id)),
      relationsPosition: (_, name) =>
        flown(
          relationTypes,
          filter(({ alias }) => types.alias[alias]?.left.clusterName === name),
          map("position"),
          max
        ) ?? 0,
      ...obj,
      ...customOverride(obj.entity?.aka),
    };
  }
);

export const contentSelector = createSelector(
  [
    pageSelector,
    createSelector([pageSelector], ({ pagetype }) => makePagetypes(pagetype)),
    pageActionsSelector,
    presentationSelector,
    get("relations"),
    get("types"),
  ],
  (
    page,
    pagetypes,
    pageActions,
    {
      zones,
      clusterNames,
      fieldNames,
      collapse,
      bulkExpand,
      search,
      fullWidth,
      show,
    },
    relations,
    types
  ) => {
    if (!zones) {
      return getContent(page, pagetypes, { pageActions });
    }

    return (obj) => {
      const clsIdt = obj.entity?.clsIdt;
      const zone = flown(
        zones,
        find(flow(get("items"), some({ content: { entity: { clsIdt } } })))
      );
      const contentPlug = getContentPlug(
        zone,
        collapse,
        bulkExpand,
        search,
        fullWidth,
        obj,
        show,
        clusterNames,
        fieldNames,
        relations,
        types
      );
      return getContent(page, pagetypes, { pageActions })(contentPlug);
    };
  }
);

export const presentationItemsSelector = (zoneCode) =>
  flow(
    presentationSelector,
    get("zones"),
    find({ zoneCode }),
    get("items"),
    defaultTo(empty.array)
  );

export const presentationTabsSelector = flow(
  presentationSelector,
  get("zones"),
  filter({ zoneCode: 1 }),
  defaultTo(empty.array)
);

export const propertiesSelector = ({ properties }) => properties;

export const treeSelector = ({ tree }) => tree;

export const relationsListSelector = ({ relations }) => relations;

export const typesSelector = ({ types }) => types;

export const entitylandscapeSelector = ({ entitylandscape }) => entitylandscape;
export const landscapeSelector = ({ landscape }) => landscape;
export const raamwerkSelector = ({ raamwerk }) => raamwerk;
export const landscapeItemSelector = ({ landscapeItem }) => landscapeItem;
export const landscapeFiltersSelector = ({ landscapeFilters }) =>
  landscapeFilters;
export const uiActionsSelector = ({ uiActions }) => uiActions;

export const biebStructuresSelector = get("biebStructures");
export const verantwoordingSelector = get("verantwoording");
export const gotoVerantwoordingenSelector = get("gotoVerantwoordingen");

export const applicationsPropSelector = ({ applications }) => applications;

export const chainRelationsSelector = ({ chainRelations }) => chainRelations;
export const miniSaarsSelector = ({ miniSaars }) => miniSaars;

export const pagetypeSelector = get("page.page.pagetype");

export const siblingsSelector = ({ siblings: { siblings } = empty.object }) =>
  siblings;

export const useLegoblokDialogSelector = ({ doUseLegoblokDialog }) =>
  doUseLegoblokDialog;
