import empty from "empty";
import { get, sortBy } from "lodash/fp";
import React, { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { createSelector } from "reselect";

import { setResultSet } from "../../business/store/resultset";
import enhanceTreeview from "../../containers/enhanceTreeview";
import { pagetypeGroupFromAlias, pagetypeGroups } from "../../pagetypes";
import { contentSelector } from "../../selectors/tabs";
import TreeFilters from "../filters";
import BasePage from "./basePage";
import Tree from "./tree";

const usedPagetypeSelector = createSelector(
  [({ pagetypeList = empty.array }) => pagetypeList],
  (pagetypeList) =>
    pagetypeList.filter(
      ({ alias }) => pagetypeGroupFromAlias(alias) === pagetypeGroups.proces
    )
);

const usedTypesSelector = createSelector(
  [({ types }) => types, usedPagetypeSelector],
  (types, pagetypes) => ({
    pagetypes,
    relationTypes: sortBy(["label", "alias"])(
      types.filter(
        ({ hierarchically, left: { pagetype } = empty.object }) =>
          hierarchically &&
          pagetypes.find(({ alias } = empty.object) => alias === pagetype) !==
            undefined
      )
    ),
  })
);

const tabsSelector = createSelector(
  [
    contentSelector,
    ({ tree }) => tree,
    ({ pageActions }) => pageActions,
    usedTypesSelector,
    ({ id }) => id,
  ],
  (getContent, tree, pageActions, { relationTypes, pagetypes }, itemId) => [
    {
      label: "Treeview",
      alias: "Treeview",
      children: [
        getContent({ path: "instellingen.inleiding", omitTitle: true }),
        <Tree
          key="treeview"
          tree={tree}
          currentSteps={empty.array}
          showAll
          pageActions={pageActions}
          types={relationTypes}
          pagetypes={pagetypes}
          itemId={itemId}
        />,
      ],
    },
  ]
);

const rightbarSelector = createSelector(
  [get("treeviewFilters")],
  (treeFilters) => ({
    children: <TreeFilters key="filters" filters={treeFilters} />,
  })
);

const flattenActive = (item) => {
  switch (true) {
    case !item?.isActive && !item?.hasActive:
      return empty.array;
    case !item.hasActive:
      return [item];
    case !item.isActive:
      return item.children.flatMap(flattenActive);
    default:
      return [item, ...item.children.flatMap(flattenActive)];
  }
};

/**
 * Treeview component
 */
const Treeview = (props) => {
  const dispatch = useDispatch();
  const activeList = useMemo(() => {
    return (props.tree?.roots ?? empty.array).flatMap(flattenActive);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(props.tree)]);
  const { id: itemId, slug, title } = props.page;
  useEffect(() => {
    dispatch(setResultSet(itemId, slug, title, activeList));
  }, [dispatch, itemId, slug, title, activeList]);

  return (
    <BasePage
      {...props}
      tabs={tabsSelector(props)}
      rightbar={rightbarSelector(props)}
    />
  );
};

export default enhanceTreeview(Treeview);
