import empty from "empty";
import { withProps } from "recompose";

import { weakMemoize } from "../utils";
import { Position } from ".";

export const getContentName = (child, name) =>
  child.names?.[0] ??
  name ??
  child.naam?.value ??
  child.omgeving?.value ??
  null;

const defaultContentPlug = Object.freeze({
  sort: empty.functionThatReturnsArgument,
  collapse: empty.functionThatReturns(null),
  bulkExpand: empty.functionThatReturns(null),
  search: empty.functionThatReturns(null),
  show: empty.functionThatReturns(true),
  clusterFilter: empty.functionThatReturns(true),
  clusterAlter: empty.functionThatReturnsArgument,
  clusterName: getContentName,
  fieldName: getContentName,
  fullWidth: empty.functionThatReturns(undefined),
  gegeven: empty.functionThatReturns(undefined),
  addAbove: false,
  relationsPosition: empty.functionThatReturns(Position.bottom),
  renderBefore: empty.functionThatReturns(undefined),
  renderStart: empty.functionThatReturns(undefined),
  renderSecond: empty.functionThatReturns(undefined),
  renderEnd: empty.functionThatReturns(undefined),
  renderAfter: empty.functionThatReturns(undefined),
  renderRelationLinkParentheses: empty.functionThatReturns(undefined),
  renderRelationLinks: undefined,
  renderAfterAddButton: empty.functionThatReturns(undefined),
});

export const consolidatePlug = weakMemoize((plug = empty.object) => ({
  ...defaultContentPlug,
  ...plug,
}));
export const withContentPlug = withProps(({ plug }) => ({
  plug: consolidatePlug(plug),
}));
