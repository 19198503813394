import classNames from "classnames";
import { find } from "lodash/fp";
import { ListItem } from "material-ui/List";
import React, { UIEvent, memo, useCallback } from "react";

import { SelectionListItem, SelectionValue } from "../../../business/models";
import { flown } from "../../../lodash";
import { Disabled, Meta, RaamwerkStatusButtons } from "../../ggvtyp/raamwerk";
import { ItemLink } from "../../relation.itemlink";
import { followPrimary } from "../../relation.utils";
/* eslint-disable jsx-a11y/anchor-is-valid */
import { HyperlinkProps, LinkListItemProps } from "./models";
import PageIcon from "./pageIcon";
import PracticalSituation from "./practicalSituation";
import RemarkButton from "./remarkButton";

const toValue = (
  { id, alias, value }: SelectionListItem = { id: 0, value: "" }
): SelectionValue => ({
  selectionId: id,
  alias,
  value,
});

const Hyperlink = memo(
  ({
    selectionId,
    externLink,
    edit,
    status,
    openHyperlinkDialog,
  }: HyperlinkProps) => {
    const handleEditHyperlink = useCallback(
      (event: UIEvent) => {
        event.preventDefault();
        if (externLink) {
          openHyperlinkDialog({
            status,
            selectionId,
            externLink,
          });
        }
      },
      [externLink, openHyperlinkDialog, selectionId, status]
    );

    return edit ? (
      <a className="externLink" href="" onClick={handleEditHyperlink}>
        {externLink.label}
      </a>
    ) : (
      <a
        className="externLink"
        href={externLink.url}
        target="_blank"
        rel="noopener noreferrer"
      >
        {externLink.label}
      </a>
    );
  }
);

const metaWidth = 36;

const LinkListItem = memo(
  ({
    item,
    leftItemId,
    link,
    externLink,
    status,
    statusItems,
    metaItem,
    remark,
    stagedRemarks,
    edit,
    allowed,
    openRemarkDialog,
    openHyperlinkDialog,
    onChange,
    stagedHyperlink,
    practicalSituation,
    ...rest
  }: LinkListItemProps) => {
    const marginLeft =
      (status === "ok" ? 0 : status === "draft" ? 1 : 2) * metaWidth + 8;
    const marginRight =
      (status === "ok" ? 2 : status === "draft" ? 1 : 0) * metaWidth + 8;

    const hasStagedRemark = stagedRemarks.some(({ $type, ...payload }) => {
      const currentId = link
        ? link.itemId
        : externLink
        ? externLink.id
        : undefined;
      const remarkItem = payload as {
        itemId?: number;
        externLinkId?: number;
      };
      return currentId
        ? (remarkItem.itemId !== undefined &&
            remarkItem.itemId === currentId) ||
            (remarkItem.externLinkId !== undefined &&
              remarkItem.externLinkId === currentId)
        : false;
    });

    return (
      <ListItem
        {...rest}
        leftIcon={<PageIcon link={link} externLink={externLink} />}
        rightIcon={<></>}
        onClick={edit ? undefined : followPrimary()}
        className={classNames("raamwerk_link raamwerk_tree_item", {
          disabledSelectionItem: item.disabled,
        })}
      >
        {item.disabled ? (
          <Disabled
            item={metaItem}
            style={{ float: "right", marginLeft, marginRight }}
          />
        ) : edit && allowed.canLink && !leftItemId ? (
          <RaamwerkStatusButtons
            style={{ float: "right", marginRight: 4 }}
            statusStyle={{ marginRight: 4 }}
            item={item}
            status={statusItems}
            onClick={(action) =>
              onChange(
                action,
                link,
                stagedHyperlink && stagedHyperlink.externLink
                  ? stagedHyperlink.externLink
                  : externLink
              )
            }
            value={{
              item: toValue(item),
              meta: flown(statusItems, find({ alias: status }), toValue),
            }}
          />
        ) : (
          <Meta
            item={metaItem}
            style={{ float: "right", marginLeft, marginRight }}
          />
        )}

        {!leftItemId && (
          <RemarkButton
            edit={edit && allowed.canRemarkItem}
            selectionId={item.id}
            itemId={link ? link.itemId : undefined}
            externLinkId={externLink ? externLink.id : undefined}
            remark={remark}
            openDialog={openRemarkDialog}
            hasStagedRemark={hasStagedRemark}
            style={{ float: "right", margin: "0 4px" }}
          />
        )}

        {practicalSituation && (
          <PracticalSituation practicalSituation={practicalSituation} />
        )}

        {link && <ItemLink item={link} className="primary" />}
        {externLink && (
          <Hyperlink
            selectionId={item.id}
            status={status}
            externLink={
              stagedHyperlink && stagedHyperlink.externLink
                ? stagedHyperlink.externLink
                : externLink
            }
            edit={edit}
            openHyperlinkDialog={openHyperlinkDialog}
          />
        )}
      </ListItem>
    );
  }
);

export default LinkListItem;
