import empty from "empty";
import { findLast } from "lodash/fp";
import React from "react";
import { connect } from "react-redux";
import { compose, setDisplayName } from "recompose";

import KernVelden from "./kernvelden";
import ZibVelden from "./zibvelden";

const ApplicatieVeldExtras = ({
  pageActions,
  relations,
  clusterId: gegevensClusterId,
  page,
}) => (
  <>
    <KernVelden
      pageActions={pageActions}
      relations={relations}
      gegevensClusterId={gegevensClusterId}
      clusterId={0}
      page={page}
    />
    <ZibVelden
      pageActions={pageActions}
      relations={relations}
      gegevensClusterId={gegevensClusterId}
      clusterId={0}
      page={page}
    />
  </>
);

export default compose(
  setDisplayName("ApplicatieVeldExtras"),
  connect((state, props) => {
    const { pageActions: { pageId } = empty.object } = props;
    const {
      data: {
        pages: { [pageId]: { page = empty.object } = empty.object },
        relations: { [pageId]: { relations = empty.array } = empty.object },
      },
      form: {
        page: {
          [pageId]: { staged = empty.array } = empty.object,
        } = empty.object,
      },
    } = state;

    const clusterId = findLast({
      $type: "InfoZorgSAAR.Iprox.ContentActions.ClusterAddAction, SAAR.Iprox",
    })(staged)?.clusterId;

    return { clusterId, page, relations };
  })
)(ApplicatieVeldExtras);
