import { loadingPresent, loadingProgress } from "async-lifecycle";
import { joinLoading } from "async-lifecycle/volatile";
import empty from "empty";
import { entries, filter, keys, map, reduce, sortBy, values } from "lodash/fp";
import PropTypes from "prop-types";
import React from "react";
import Helmet from "react-helmet";

import { readOnlyPageActions } from "../../business/models";
import {
  globalItemShape,
  itemShape,
  pageActionsShape,
  relationShape,
  relationTypeShape,
  relationsContainerShape,
} from "../../business/prop-types";
import { Side } from "../../business/relations";
import { getRelationsCreator } from "../../containers/relations";
import { flown } from "../../lodash";
import Field from "../content/field";
import { isVisibleField } from "../content/query";
import ClusterCard from "../material/clusterCard";
import BasePage from "../pagetypes/basePage";

export const VerantwoordingenData = Object.freeze({
  id: -2,
  siteId: -2,
  page: {
    title: "Verantwoordingen",
    pagetype: "verantwoordingen",
  },
});

const Verantwoording = ({
  page,
  pageActions,
  relations,
  types,
  deleteVerantwoording,
}) => (
  <ClusterCard
    title={page.title}
    collapse={page.title}
    onTitleUpdate={
      pageActions.edit
        ? (value) => pageActions.titleUpdate({ itemId: page.id, value })
        : undefined
    }
    onDelete={
      pageActions.edit && !pageActions.dirty
        ? () => deleteVerantwoording(page.id)
        : undefined
    }
  >
    <ClusterCard>
      {flown(
        page.page,
        entries,
        filter(([_, value]) => isVisibleField(value, pageActions)),
        map(([key, field]) => (
          <Field
            key={key}
            field={field}
            itemId={page.id}
            pageActions={pageActions}
          />
        ))
      )}
    </ClusterCard>
    {getRelationsCreator(
      page.id,
      relations,
      types,
      empty.array,
      readOnlyPageActions
    )(
      {
        alias: "bedrijfsproces_kerngegeven",
        side: Side.using,
        clusterFirst: true,
      },
      { alias: "activiteit_kerngegeven", side: Side.using, clusterFirst: true }
    )}
  </ClusterCard>
);
Verantwoording.propTypes = {
  pageActions: pageActionsShape.isRequired,
  page: itemShape.isRequired,
  relations: PropTypes.arrayOf(relationShape.isRequired).isRequired,
  types: PropTypes.arrayOf(relationTypeShape.isRequired).isRequired,
  deleteVerantwoording: PropTypes.func.isRequired,
};

export const verantwoordingenPresent = (verantwoordingen, pages, relations) =>
  verantwoordingen.length === keys(pages).length &&
  verantwoordingen.length === keys(relations).length &&
  flown(
    pages,
    values,
    map("loading"),
    reduce(joinLoading, "success"),
    loadingPresent
  ) &&
  flown(
    relations,
    values,
    map("loading"),
    reduce(joinLoading, "success"),
    loadingPresent
  );

const VerantwoordingenPage = ({
  loading,
  pageActions,
  pages,
  relations,
  types,
  verantwoordingen,
  deleteVerantwoording,
  ...pageProps
}) => {
  const present =
    loadingPresent(loading) &&
    verantwoordingenPresent(verantwoordingen, pages, relations);
  return (
    <div>
      <Helmet title={`${VerantwoordingenData.page.title} - Saar`} />

      <BasePage
        {...pageProps}
        id={-1}
        page={VerantwoordingenData}
        pageActions={pageActions}
        pageTitle={VerantwoordingenData.page.title}
        loading={loadingProgress(loading) || !present}
        selectedTab={0}
        tabChanged={empty.func}
        tabs={[
          {
            label: VerantwoordingenData.page.title,
            children:
              present &&
              flown(
                pages,
                values,
                sortBy("page.title"),
                entries,
                map(([n, page]) => (
                  <Verantwoording
                    key={page.id}
                    nthChild={parseInt(n, 10)}
                    page={page}
                    pageActions={pageActions}
                    relations={relations[page.id].relations}
                    types={types}
                    deleteVerantwoording={deleteVerantwoording}
                  />
                ))
              ),
          },
        ]}
        rightbar={empty.object}
      />
    </div>
  );
};

VerantwoordingenPage.propTypes = {
  loading: PropTypes.string,
  pageActions: pageActionsShape.isRequired,
  pages: PropTypes.objectOf(itemShape.isRequired).isRequired,
  relations: PropTypes.objectOf(relationsContainerShape.isRequired).isRequired,
  types: PropTypes.arrayOf(relationTypeShape.isRequired).isRequired,
  verantwoordingen: PropTypes.arrayOf(globalItemShape.isRequired).isRequired,
  deleteVerantwoording: PropTypes.func.isRequired,
};

export default VerantwoordingenPage;
