import { ReactNode, useState } from "react";

interface Props {
  condition?: boolean;
  keepRendered?: boolean;
  Wrapper: (props: { children?: ReactNode; hidden?: boolean }) => JSX.Element;
  children?: ReactNode;
}

export const RenderIf = ({
  condition = true,
  keepRendered = false,
  Wrapper,
  children,
}: Props) => {
  const [mustRender, setMustRender] = useState(condition);
  if (condition !== mustRender && (condition || !keepRendered)) {
    setMustRender(condition);
  }

  return mustRender && <Wrapper hidden={!condition}>{children}</Wrapper>;
};

export default RenderIf;
