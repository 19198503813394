import { connect } from "react-redux";
import { compose, pure, setDisplayName } from "recompose";

import { chainRelationsAction } from "../../actions/relation";
import { execOnChange } from "../../recompose.contrib";
import { chainRelationsSelector } from "../../selectors/relations";

const chainModel = Object.freeze({
  chains: [
    {
      from: {
        aliases: ["applicatie_verwerker"],
        side: "right",
      },
      to: {
        aliases: [
          "entiteit_applicatiegegeven",
          "kerngegeven_applicatiegegeven",
          "entiteit_applicatieveld",
          "kerngegeven_applicatieveld",
        ],
        side: "right",
      },
    },
  ],
});

export const enhanceSupplier = compose(
  setDisplayName("Leverancier"),
  pure,
  connect(
    (state, props) => ({
      chainRelations: chainRelationsSelector(state, props),
    }),
    (dispatch) => ({
      init: ({ id: itemId }) =>
        dispatch(chainRelationsAction(itemId, chainModel)),
    })
  ),
  execOnChange("init", "id")
);
