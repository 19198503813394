import empty from "empty";
import {
  compact,
  concat,
  defaultTo,
  entries,
  every,
  filter,
  find,
  flatten,
  flow,
  get,
  has,
  includes,
  intersection,
  isEqual,
  map,
  memoize,
  nth,
  orderBy,
  pullAt,
  range,
  reduce,
  some,
  sortBy,
  uniq,
  uniqBy,
  values,
} from "lodash/fp";
import { createSelector } from "reselect";

import { flatItems, flown } from "../../lodash";
import { arrayFilterMap, countToMap, isEmpty, labelize } from "../../utils";
import { extractValues, orderValue, stringValue } from "./values";

export const selItmFacet = memoize((selItmIdt) => ({ selItmIdt }));
export const selLstFacet = memoize((selLstIdt) => ({ selLstIdt }));
const pagetypeFacet = memoize((pagetype) => ({ pagetype }));
const legoblokStructureFacet = memoize((legoblokSitIdt) => ({
  legoblokSitIdt,
}));
const biebItemBijgewerktFacet = memoize((bijgewerkt) => ({ bijgewerkt }));
const itemFacet = memoize((itmIdt) => ({ itmIdt }));
const simpleLstId = memoize((listName) => ({ listName }));
const relationFacet = memoize((relIdt) => ({ relIdt }));
const relationLstId = memoize((rel) => ({ rel }));
const relationTypeFacet = memoize((relTypIdt) => ({ relTypIdt }));
const relationTypeLstId = memoize((relTyp) => ({ relTyp }));
const facetMemos = {
  selItmIdt: selItmFacet,
  selLstIdt: selLstFacet,
  pagetype: pagetypeFacet,
  legoblokSitIdt: legoblokStructureFacet,
  bijgewerkt: biebItemBijgewerktFacet,
  itmIdt: itemFacet,
  listName: simpleLstId,
  relIdt: relationFacet,
  rel: relationLstId,
  relTypIdt: relationTypeFacet,
  relTyp: relationTypeLstId,
};
export const parseFacet = (facet) => {
  const [prefix, value] = facet.split(":");
  const n = Number(value);
  return facetMemos[prefix]?.(isNaN(n) ? value : n) ?? empty.object;
};
export const stringifyFacet = (facet) => {
  const [[key, value]] = Object.entries(facet);
  return `${key}:${value}`;
};

const expandItem =
  (properties, listsByPagetype = empty.array, props = empty.object) =>
  (item) => {
    const fieldSelItmIdts = item.fields.selItmIdt
      ? item.fields.selItmIdt.split(" ").map((id) => parseInt(id, 10))
      : empty.array;
    const relationItems = flown(
      item.lookup,
      values,
      flatten,
      filter(
        (value) =>
          (Number.isInteger(value.itemId) && typeof value.type === "string") ||
          (Number.isInteger(value.id) &&
            typeof value.alias === "string" &&
            value.meta)
      )
    );
    const relationSelItmIdts = flown(
      relationItems,
      map("meta.page"),
      map(values),
      flatten,
      filter(flow(get("definition.dataTypeCode"), isEqual(3))),
      map("value.selectionId"),
      uniq
    );

    const selItmIdtValues = flown(
      fieldSelItmIdts,
      concat(relationSelItmIdts),
      uniq
    );

    const selItmIdts = flown(selItmIdtValues, map(selItmFacet));

    const selLstIdts = flown(
      listsByPagetype,
      find(({ alias: pagetype }) => pagetype === item.fields.pagetype),
      get("lists"),
      filter(flow(flatItems, intersection(selItmIdtValues), isEmpty)),
      map(({ id }) => selLstFacet(id)),
      defaultTo(empty.array)
    );

    const pagetypeFacets = (type) => {
      if (item.fields.pagetype === type && type !== "applicatie") {
        return empty.array;
      }

      const facets = flown(
        relationItems,
        filter({ type }),
        map("itemId"),
        uniq,
        map(itemFacet)
      );

      if (facets.length === 0) {
        // geen facet => 'Niet gevuld'
        return [simpleLstId(type)];
      }

      return facets;
    };

    const relationFacets = (type, fromPagetype, toPagetype) => {
      if (item.fields.pagetype !== fromPagetype) {
        return empty.array;
      }

      const facets = flown(
        item.lookup?.[type] ?? empty.array,
        filter(({ itemId }) => Number.isInteger(itemId)),
        map(({ itemId }) => relationFacet(`${type}_${itemId}`))
      );

      if (facets.length === 0) {
        // geen facet => 'Niet gevuld'
        return [relationLstId(`${type}_${toPagetype}`)];
      }

      return facets;
    };

    const relationTypeFacets = (type, pagetype) => {
      if (item.fields.pagetype !== pagetype) {
        return empty.array;
      }

      return [
        item.lookup?.[type]?.length > 0
          ? relationTypeFacet(type)
          : relationTypeLstId(type),
      ];
    };

    const biebItemBijgewerkt = biebItemBijgewerktFacet(
      item.lookup?.biebItemBijgewerkt?.length > 0
        ? item.lookup.biebItemBijgewerkt[0]
          ? Bijgewerkt.Ja
          : Bijgewerkt.Nee
        : Bijgewerkt["Niet overgenomen"]
    );

    const facets = [
      ...selItmIdts,
      ...pagetypeFacets("applicatie"),
      ...pagetypeFacets("module"),
      ...pagetypeFacets("entiteit"),
      ...pagetypeFacets("zib"),
      ...relationFacets("bewerkingsapplicatie", "koppeling", "applicatie"),
      ...relationTypeFacets("applicatie_applicatie", "applicatie"),
      ...relationTypeFacets("veld_koppeling_veld", "koppeling"),
      pagetypeFacet(item.fields.pagetype),
      legoblokStructureFacet(parseInt(item.fields.sitIdt, 10)),
      ...selLstIdts,
      biebItemBijgewerkt,
    ];

    const extractPropertyValues = extractValues(item, props);
    const keys = ["titel", "icoonlink", "datum", "projectid", "highrise-ID"]
      .concat(Object.keys(item.fields || {}))
      .concat(Object.keys(item.lookup || {}));
    return keys.reduce(
      (obj, property) => {
        const v = extractPropertyValues(property);
        obj[property] = v?.[0];
        obj.values[property] = v;
        return obj;
      },
      { facets, values: {} }
    );
  };

const rowsFromList = createSelector(
  [
    ({ list }) => list,
    ({ properties }) => properties,
    ({ listsByPagetype }) => listsByPagetype,
    ({ setPreview }) => setPreview,
    ({ pagetypeList }) => pagetypeList,
    ({ saar: { sites = empty.array } = empty.object }) => sites,
  ],
  (list, properties, listsByPagetype, setPreview, pagetypeList, sites) => {
    const structureIds = map("siteId")(sites);
    return list.map(
      expandItem(properties, listsByPagetype, {
        pagetypeList,
        setPreview,
        structureIds,
      })
    );
  }
);

const filteredRows = createSelector(
  [rowsFromList, ({ filter }) => filter, ({ columnFilters }) => columnFilters],
  (rows, filterValue, columnFilters) => {
    const filtered = filterValue
      ? filter(flow(values, map(stringValue), some(includes(filterValue))))(
          rows
        )
      : rows;
    return flown(
      columnFilters,
      entries,
      filter(([, value]) => Boolean(value)),
      reduce(
        (filtered, [property, value]) =>
          filtered.filter((row) => {
            const values = row.values?.[property];
            return Array.isArray(values)
              ? values.some((v) => stringValue(v)?.includes(value))
              : stringValue(row[property])?.includes(value);
          }),
        filtered
      )
    );
  }
);

const selectedRows = createSelector(
  [filteredRows, ({ selections }) => selections],
  (rows, selections) => {
    if (selections.size === 0) {
      return rows;
    }

    const filters = flown(
      selections.values(),
      Array.from,
      sortBy("length"),
      map((a) => (v) => a.includes(v))
    );

    return filter(({ facets }) =>
      every((filter) => some(filter)(facets))(filters)
    )(rows);
  }
);

const selectionListsFacets = createSelector(
  [({ selectionLists }) => selectionLists || empty.array],
  map((list) => {
    const { id, alias, value } = list;
    const items = flatItems(list);
    return {
      id: selLstFacet(id),
      alias,
      value,
      items: [
        ...items.map(({ id, alias, value }) => ({
          id: selItmFacet(id),
          alias,
          value,
        })),
        { id: selLstFacet(id), alias, value: "Niet gevuld" },
      ],
    };
  })
);

const pagetypeFacets = createSelector(
  [({ pagetypeList }) => pagetypeList || empty.array],
  (pagetypeList) => ({
    id: simpleLstId("pagetypes"),
    value: "Paginatype",
    items: pagetypeList.map(({ alias, name }) => ({
      id: pagetypeFacet(alias),
      value: name,
    })),
  })
);

const itemFacets = (property, pagetype, label) =>
  createSelector([(obj) => obj[property] || empty.array], (items) => ({
    id: simpleLstId(pagetype),
    value: label,
    items: [
      ...items.map(({ id, naam }) => ({
        id: itemFacet(id),
        value: naam,
      })),
      { id: simpleLstId(pagetype), value: "Niet gevuld" },
    ],
  }));

const foundItemFacets = memoize((pagetype, label) =>
  createSelector([get("list")], (list) => {
    const items = flown(
      list,
      map("lookup"),
      map(values),
      flatten,
      flatten,
      map(({ itemId, label, variantId, type } = empty.object) =>
        itemId && label && !variantId && type === pagetype
          ? { id: itemFacet(itemId), value: label }
          : undefined
      ),
      compact,
      uniqBy("id"),
      sortBy("value")
    );
    const id = simpleLstId(pagetype);
    return {
      id,
      value: label,
      items: [...items, { id, value: "Niet gevuld" }],
    };
  })
);

const foundRelationFacets = memoize((type, label, pagetype) =>
  createSelector([get("list")], (list) => {
    const items = flown(
      list,
      map("lookup"),
      map(type),
      flatten,
      map(({ itemId, label, variantId } = empty.object) =>
        itemId && label && !variantId
          ? { id: relationFacet(`${type}_${itemId}`), value: label }
          : undefined
      ),
      compact,
      uniqBy("id"),
      sortBy("value")
    );
    const id = relationLstId(`${type}_${pagetype}`);
    return {
      id,
      value: label,
      items: [...items, { id, value: "Niet gevuld" }],
    };
  })
);

const foundRelationTypeFacets = memoize(
  (type, pagetype, subject, shortObject, longObject) =>
    createSelector([get("list")], (list) => {
      const id = relationTypeLstId(type);
      const itemsOfType = list.filter(
        (item) => item.fields.pagetype === pagetype
      );
      const found = itemsOfType.filter(has(`lookup.${type}`));
      const items = [];

      if (found.length > 0) {
        items.push({
          id: relationTypeFacet(type),
          value: `${subject} met ${longObject}`,
        });
      }

      if (has.length < itemsOfType.length) {
        items.push({ id, value: `${subject} zonder ${longObject}` });
      }

      return {
        id,
        value: `${subject} met/zonder ${shortObject}`,
        items,
      };
    })
);

const entiteitFacets = itemFacets(
  "entiteiten",
  "entiteit",
  "Relatie met entiteit"
);
const zibFacets = itemFacets("zibs", "zib", "Relatie met zib");
const applicatieFacets = foundItemFacets(
  "applicatie",
  "Relatie met applicatie"
);
const bewerkingFacets = foundRelationFacets(
  "bewerkingsapplicatie",
  "Bewerkingsapplicatie",
  "applicatie"
);
const applicatieMozKoppelingFacets = foundRelationTypeFacets(
  "applicatie_applicatie",
  "applicatie",
  "Applicatie",
  "koppeling",
  "koppeling"
);
const koppelingMozGegevensFacets = foundRelationTypeFacets(
  "veld_koppeling_veld",
  "koppeling",
  "Koppeling",
  "gegevens",
  "ingevulde gegevensoverdracht"
);
const moduleFacets = foundItemFacets("module", "Relatie met module");

const legoblokStructureFacets = createSelector(
  [({ legoblokStructures }) => legoblokStructures || empty.array],
  (legoblokStructures) => ({
    id: simpleLstId("legoblokStructures"),
    value: "Saar Bieb",
    items: legoblokStructures.map(({ name, id }) => ({
      id: legoblokStructureFacet(id),
      value: name,
    })),
  })
);

export const Bijgewerkt = labelize(["Ja", "Nee", "Niet overgenomen"]);

const biebItemBijgewerktFacets = createSelector(
  [() => Array.from(Bijgewerkt.keys())],
  (biebItemBijgewerktValues) => ({
    id: simpleLstId("biebItemBijgewerkt"),
    value: "Bieb item bijgewerkt",
    items: biebItemBijgewerktValues.map((value) => ({
      id: biebItemBijgewerktFacet(value),
      value: Bijgewerkt[value],
    })),
  })
);

const allFacets = createSelector(
  [
    selectionListsFacets,
    pagetypeFacets,
    legoblokStructureFacets,
    applicatieFacets,
    bewerkingFacets,
    moduleFacets,
    entiteitFacets,
    zibFacets,
    biebItemBijgewerktFacets,
    applicatieMozKoppelingFacets,
    koppelingMozGegevensFacets,
  ],
  (
    selectionLists,
    pagetypeList,
    legoblokStructures,
    applicaties,
    bewerkingen,
    modules,
    entiteiten,
    zibs,
    biebItemBijgewerkt,
    applicatieMozKoppeling,
    koppelingMozGegevens
  ) =>
    sortBy([
      ({ id: { listName } = empty.object }) => listName !== "pagetypes",
      ({ id: { listName } = empty.object }) =>
        listName !== "biebItemBijgewerkt",
      ({ id: { listName } = empty.object }) =>
        listName !== "legoblokStructures",
      "value",
    ])([
      ...selectionLists,
      pagetypeList,
      legoblokStructures,
      applicaties,
      bewerkingen,
      modules,
      entiteiten,
      zibs,
      biebItemBijgewerkt,
      applicatieMozKoppeling,
      koppelingMozGegevens,
    ])
);

const getCountOrNothing = (rows, selections, id = undefined) => {
  const facetItems = flown(
    selections.entries(),
    Array.from,
    filter(([key]) => !isEqual(key, id))
  );
  const numberOfResultsPerFacetId = flown(
    rows,
    filter(({ facets }) =>
      flown(
        facetItems,
        every(([_, items]) => flown(facets, intersection(items), nth(0)))
      )
    ),
    map("facets"),
    flatten,
    countToMap
  );
  return (selectedIds = empty.array) =>
    (obj) => {
      const selected = selectedIds.includes(obj.id);
      const count = numberOfResultsPerFacetId.get(obj.id) || 0;
      return selected || count > 0 ? { ...obj, count, selected } : null;
    };
};

export const currentFacets = createSelector(
  [filteredRows, allFacets, ({ selections }) => selections],
  (rows, facetLists, selections) => {
    const defaultCountOrNothing = getCountOrNothing(rows, selections);
    const filterList = ({ items: facetItems, id, alias, ...list }) => {
      const countOrNothing = selections.has(id)
        ? getCountOrNothing(rows, selections, id)
        : defaultCountOrNothing;
      const items = arrayFilterMap(countOrNothing(selections.get(id)))(
        facetItems
      );
      return items.length > 1 || selections.get(id)
        ? { items, id, alias, ...list }
        : null;
    };
    return arrayFilterMap(filterList)(facetLists);
  }
);

export const orderedRows = createSelector(
  [
    selectedRows,
    ({ properties }) => properties,
    ({ order: { property } }) => property,
    ({ order: { descending } }) => Boolean(descending),
  ],
  (rows, properties, orderProperty, orderDescending) => {
    switch (typeof orderProperty) {
      case "number":
        return orderBy(
          [
            (row) =>
              orderValue(row[properties[orderProperty]], orderDescending),
          ],
          [orderDescending ? "desc" : "asc"]
        )(rows);

      case "string":
        return orderBy(
          [(row) => orderValue(row[orderProperty], orderDescending)],
          [orderDescending ? "desc" : "asc"]
        )(rows);

      default:
        return rows;
    }
  }
);

const itemIdsOfRows = createSelector([orderedRows], map("titel.itemId"));

const orderedRowsWithSelection = createSelector(
  [orderedRows, itemIdsOfRows, ({ selected }) => selected],
  (rows, itemIds, selected) => {
    return flown(
      selected,
      filter(({ itemId }) => !includes(`${itemId}`)(itemIds)),
      map(({ itemId, label, pagetype }) => ({
        pagetype,
        titel: { itemId: `${itemId}`, label },
        fromSelection: true,
      })),
      concat(rows),
      orderBy(
        [
          ({ titel: { itemId } }) => {
            const found = find({ itemId: parseInt(itemId, 10) })(selected);
            return found !== undefined;
          },
        ],
        ["desc"]
      )
    );
  }
);

export const orderedPropsAndRows = createSelector(
  [orderedRowsWithSelection, ({ properties }) => properties],
  (rows, properties) => {
    const invisible = filter((i) => !some((row) => row[properties[i]])(rows))(
      range(0, properties.length)
    );
    return {
      rows,
      properties: pullAt(invisible)(properties),
    };
  }
);
