import { get, orderBy } from "lodash/fp";

import { flown } from "../../../lodash";
import { formatDate } from "../../../utils";

const renderRapportage = ({ getContent }) =>
  getContent({
    path: "rapportage",
    omitTitle: true,
    addAbove: true,
    sort: orderBy(["datumRapportage.value"], ["desc"]),
    collapse: (cluster, name) => {
      if (name !== "Voortgang") {
        return null;
      }

      const date = flown(cluster, get("datumRapportage.value"), formatDate);
      const titel = flown(cluster, get("titel.value"));
      const start = date ? `Voortgang ${date}` : "Voortgang";
      return titel ? `${start}: ${titel}` : start;
    },
  });

export default renderRapportage;
