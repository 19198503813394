import empty from "empty";
import {
  compact,
  defaultTo,
  eq,
  filter,
  flatten,
  flow,
  get,
  identity,
  map,
  omit,
  some,
  uniq,
  zipObject,
} from "lodash/fp";
import { compose } from "recompose";
import { createSelector } from "reselect";

import { redirectAction } from "../../../actions/common";
import { getSinglePageAction } from "../../../actions/data";
import { init, remove, removeAll } from "../../../actions/relation";
import { Side } from "../../../business/relations";
import { flown, setup } from "../../../lodash";
import { execOnChange } from "../../../recompose.contrib";
import { dispatchWrap } from "../../../utils";
import connect from "../../connect";

const propsToVerantwoordingRelations = createSelector(
  [
    (_, props) => get("page.page.pagetype")(props),
    (_, props) => flow(get("relations"), defaultTo(empty.array))(props),
    (_, props) => get("types")(props),
  ],
  (pagetype, allRelations, types) => {
    if (!pagetype || !types) {
      return empty.object;
    }

    const type = flown(
      types,
      filter(
        ({ alias }) =>
          alias === `${pagetype}_applicatiegegeven` ||
          alias === `${pagetype}_kerngegeven`
      )
    );
    if (type.length === 0) {
      return empty.object;
    }

    return {
      type: type.find(({ alias }) => alias.indexOf("_applicatiegegeven") > 0),
      relations: filter(({ relationTypeId }) =>
        some({ id: relationTypeId })(type)
      )(allRelations),
    };
  }
);

const mapStateToVerantwoording = createSelector(
  [
    flow(get("session.miniSaar.path"), defaultTo("iznet")),
    get("data.pages"),
    propsToVerantwoordingRelations,
  ],
  (path, pages, { relations = empty.array }) => {
    const pageIds = flown(
      relations,
      map("using"),
      flatten,
      filter({ type: "verantwoording" }),
      map("itemId"),
      compact,
      uniq
    );
    const page = flown(
      pageIds,
      map((id) => pages[id]),
      zipObject(pageIds)
    );
    return { page, pageIds, path };
  }
);

const mapDispatchToVerantwoording = createSelector(
  [
    identity,
    (_, props) => get("page.id")(props),
    (_, props) => flow(get("pageActions"), defaultTo(empty.object))(props),
    propsToVerantwoordingRelations,
  ],
  (
    dispatch,
    itemId,
    pageActions,
    { relations = empty.array, type = empty.object }
  ) => {
    if (relations.length === 0 && !(pageActions.edit && type.mayAdd)) {
      return undefined;
    }

    return {
      itemId,
      pageActions,
      relations,
      type,
      redirect: dispatchWrap(redirectAction, dispatch),
      addInit: () => dispatch(init(type, Side.left, itemId)),
      requirePage: (id) => {
        dispatch(getSinglePageAction(id));
      },
      removeAllRelations: (usingItemId) => {
        const list = flown(
          relations,
          filter(flow(get("using[0].itemId"), eq(usingItemId))),
          map(({ id, right: { itemId: otherItemId } }) => ({
            id,
            selfItemId: itemId,
            otherItemId,
            usingItemId,
          }))
        );
        dispatch(removeAll(type.id, list));
      },
      removeRelation: (id, rightItemId, usingItemId) => {
        dispatch(remove(type.id, itemId, id, rightItemId, usingItemId));
      },
    };
  }
);

const mergeVerantwoording = (
  { verantwoording: { path, ...state }, ...stateProps },
  { verantwoording, ...dispatchProps },
  ownProps
) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,

  verantwoording: verantwoording && {
    ...state,
    ...omit("redirect")(verantwoording),
    gotoVerantwoordingen: ({ itemId, slug }) =>
      verantwoording.redirect(`/${path}/page/${itemId}/${slug}`),
  },
  requireVerantwoording: verantwoording
    ? () => {
        for (const pageId of state.pageIds) {
          if (pageId > 0 && !state.page[pageId]) {
            verantwoording.requirePage(pageId);
          }
        }
      }
    : empty.func,
});

export const enhanceProcess = compose(
  connect(
    flow(mapStateToVerantwoording, setup("verantwoording")),
    flow(mapDispatchToVerantwoording, setup("verantwoording")),
    mergeVerantwoording
  ),
  execOnChange("requireVerantwoording", "verantwoording")
);
