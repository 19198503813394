import empty from "empty";
import { defaultTo, filter, find, flow, get, includes, map } from "lodash/fp";
import { createSelector } from "reselect";

import { initialKoppelingGegevenDialogState } from "../reducers/form";

const appRelationTypes = [
  "entiteit_applicatiegegeven",
  "kerngegeven_applicatiegegeven",
  "entiteit_applicatieveld",
  "kerngegeven_applicatieveld",
  "applicatiegegeven_zib",
  "applicatiegegeven_zib_ggv1",
  "applicatiegegeven_zib_ggv2",
  "applicatiegegeven_zib_ggv3",
  "applicatiegegeven_zib_sub1",
  "applicatiegegeven_zib_sub2",
  "applicatiegegeven_zib_sub3",
  "applicatieveld_zib_ggv1",
  "applicatieveld_zib_ggv2",
  "applicatieveld_zib_ggv3",
  "applicatieveld_module",
  "veld_koppeling_veld",
  "applicatiegegeven_verantwoording",
  "activiteit_applicatiegegeven",
  "bedrijfsproces_applicatiegegeven",
];

const typesSelector = ({
  data: {
    relationTypes: { types = empty.array } = empty.object,
  } = empty.object,
}) => filter(({ alias }) => includes(alias)(appRelationTypes))(types);

const pageSelector =
  (type) =>
  (
    { data: { pages = empty.object } = empty.object },
    { koppelingFields: { [type]: { id } = empty.object } = empty.object }
  ) =>
    flow(get(`${id}.page`), defaultTo(empty.object))(pages);

const fromPageSelector = pageSelector("from");
const toPageSelector = pageSelector("to");

const relationsSelector =
  (type) =>
  (
    { data: { relations } },
    { koppelingFields: { [type]: { id } = empty.object } = empty.object }
  ) =>
    flow(get(`${id}.relations`), defaultTo(empty.array))(relations);

const filterRelations = (types, relations) => {
  const typeIds = map("id")(types);
  return flow(
    filter(({ relationTypeId }) => includes(relationTypeId)(typeIds)),
    map((relation) => ({
      ...relation,
      type: find({ id: relation.relationTypeId })(types),
    }))
  )(relations);
};

const fromRelationsSelector = relationsSelector("from");
const toRelationsSelector = relationsSelector("to");

const filteredFromRelationsSelector = createSelector(
  [typesSelector, fromRelationsSelector],
  filterRelations
);

const filteredToRelationsSelector = createSelector(
  [typesSelector, toRelationsSelector],
  filterRelations
);

export const applicationsSelector = createSelector(
  [
    (_, { koppelingFields }) => koppelingFields,
    fromPageSelector,
    filteredFromRelationsSelector,
    toPageSelector,
    filteredToRelationsSelector,
  ],
  (
    { from: fromFields, to: toFields },
    fromPage,
    fromRelations,
    toPage,
    toRelations
  ) => ({
    from: { fields: fromFields, page: fromPage, relations: fromRelations },
    to: { fields: toFields, page: toPage, relations: toRelations },
  })
);

export const koppelingGegevenDialogSelector = flow(
  get("form.koppelingGegevenDialog"),
  defaultTo(initialKoppelingGegevenDialogState)
);
