import empty from "empty";
import { entries, filter, groupBy, map, some, sortBy, values } from "lodash/fp";
import IconButton from "material-ui/IconButton";
import { List, ListItem } from "material-ui/List";
import Paper from "material-ui/Paper";
import PropTypes from "prop-types";
import React from "react";

import { readOnlyPageActions } from "../../../business/models";
import {
  itemShape,
  relationDisplayShape,
  siteLinkShape,
  structureShape,
} from "../../../business/prop-types";
import { flown } from "../../../lodash";
import Field from "../../content/field";
import { isVisibleField } from "../../content/query";
import ClusterCard from "../../material/clusterCard";
import { Relation } from "../../relation";
import icons from "../icons";

export const ProcessList = ({
  biebStructures,
  list,
  label,
  ...relationProps
}) => (
  <div className="relations">
    <List>
      <h3 style={{ fontWeight: 500, marginBottom: 0 }}>{label}</h3>
      {flown(
        list,
        sortBy("label"),
        map((item) => (
          <Relation
            key={item.itemId}
            item={item}
            Wrap={ListItem}
            insideBieb={some({ id: item.structureId })(biebStructures)}
            {...relationProps}
          />
        ))
      )}
    </List>
  </div>
);
ProcessList.propTypes = {
  biebStructures: PropTypes.arrayOf(structureShape.isRequired).isRequired,
  label: PropTypes.string.isRequired,
  list: PropTypes.arrayOf(siteLinkShape.isRequired).isRequired,
};

const VerantwoordingBlok = ({
  biebStructures,
  relations,
  verantwoording: { page: verantwoording } = empty.object,
  gotoVerantwoordingen,
}) => {
  const { itemId, label } = relations[0].links[0].using[0];
  return (
    <Paper style={{ marginBottom: 16 }}>
      <ClusterCard
        titlePrefix="Verantwoording: "
        title={label}
        titleSuffix={
          <div className="icon-button-inline">
            <IconButton
              title={label}
              onClick={(e) => {
                e.stopPropagation();
                gotoVerantwoordingen(relations[0].links[0].using[0]);
              }}
            >
              <icons.verantwoording />
            </IconButton>
          </div>
        }
        expandable
      >
        {verantwoording &&
          flown(
            verantwoording,
            entries,
            filter(([_, value]) => isVisibleField(value, readOnlyPageActions)),
            map(([key, field]) => (
              <Field
                key={key}
                field={field}
                itemId={itemId}
                pageActions={readOnlyPageActions}
              />
            ))
          )}
        {flown(
          relations,
          groupBy("id"),
          values,
          sortBy("[0].label"),
          map((list) => {
            const [{ id, links, label }] = list;
            return (
              <ProcessList
                key={id}
                label={label}
                list={map("item")(links)}
                biebStructures={biebStructures}
                clusterFirst
                showHeader
              />
            );
          })
        )}
      </ClusterCard>
    </Paper>
  );
};

VerantwoordingBlok.propTypes = {
  biebStructures: PropTypes.arrayOf(structureShape.isRequired).isRequired,
  relations: PropTypes.arrayOf(relationDisplayShape.isRequired).isRequired,
  verantwoording: itemShape,
  gotoVerantwoordingen: PropTypes.func.isRequired,
};

export default VerantwoordingBlok;
