import empty from "empty";
import { filter, flatMapDeep, flow, get } from "lodash/fp";
import React from "react";

import { arrayFlatMap } from "../../../utils";
import RaamwerkTree from "../raamwerk";
import RaamwerkFilters from "../raamwerkFilters";

const getItems = (item) => {
  if (!item.items || item.items.length === 0) {
    return item;
  }

  return [item, flatMapDeep(getItems)(item.items)];
};

export const renderRaamwerkFilters = ({
  raamwerk: {
    list: { items = empty.array } = empty.object,
    organizations = empty.array,
    practicalSituations = empty.array,
  } = empty.object,
}) =>
  organizations.length === 0 && practicalSituations.length === 0 ? null : (
    <RaamwerkFilters
      key="raamwerkFilters"
      organizations={organizations}
      practicalSituations={practicalSituations}
      all={flow(
        flatMapDeep(getItems),
        arrayFlatMap((i) => i.links),
        filter((l) => !l.externLink)
      )(items)}
    />
  );

const renderRaamwerk = ({ page, pageActions, raamwerk }) => {
  const items = get("list.items")(raamwerk);
  return (
    raamwerk && (
      <RaamwerkTree
        key="raamwerk"
        items={items || empty.array}
        organizations={raamwerk.organizations}
        practicalSituations={raamwerk.practicalSituations}
        statusItems={raamwerk.statusItems || empty.array}
        pagetypes={raamwerk.pagetypes || empty.array}
        targets={raamwerk.targets || empty.array}
        targetsLoading={raamwerk.targetsLoading}
        edit={pageActions.edit}
        local={page.pagetype !== "raamwerk"}
        requireRaamwerkTargets={raamwerk.requireRaamwerkTargets || empty.func}
        linkRaamwerk={raamwerk.linkRaamwerk || empty.func}
        hyperlinkAction={raamwerk.hyperlinkAction || empty.func}
        remarkAction={raamwerk.remarkAction || empty.func}
        open={raamwerk.open}
        toggleOpen={raamwerk.toggleOpen}
        raamwerkLijst={raamwerk.raamwerkLijst}
        pageActions={pageActions}
        numberAdded={raamwerk.numberAdded}
      />
    )
  );
};

export default renderRaamwerk;
