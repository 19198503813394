import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getPageAction } from "../../../actions/data";
import {
  PageActions,
  Relation,
  RelationType,
  readOnlyPageActions,
} from "../../../business/models";
import { getContent } from "../../content";
import HintCard from "../../material/HintCard";
import ApplicatieGegevens from "./application/applicatieGegevens";

export interface ModuleGegevensProps {
  applicationItemId?: number;
  relations: Relation[];
  pageActions: PageActions;
}

const ModuleGegevens = (props: Partial<ModuleGegevensProps>) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getPageAction(props.applicationItemId));
  }, [dispatch, props.applicationItemId]);

  const applicationOverride = useSelector((state: any) => ({
    page: state.data.pages[`${props.applicationItemId}`]?.page ?? {},
    relations:
      state.data.relations[`${props.applicationItemId}`]?.relations ?? [],
  }));

  const usedClusterIds = useMemo(
    () => props.relations?.map((relation) => relation.left.pageClusterId),
    [props.relations]
  );

  const { page: { gegeven: { clusters: gegevens = [] } = {} } = {} } =
    applicationOverride;

  const filteredPage = useMemo(
    () => ({
      ...applicationOverride.page,
      gegeven: {
        ...applicationOverride.page.gegeven,
        clusters: gegevens
          .filter(({ veld: { clusters = [] } = {} }) =>
            clusters
              .map(({ clusterId }) => clusterId)
              .some((clusterId) => usedClusterIds?.includes(clusterId))
          )
          .map(({ veld: { clusters = [], ...veldRest } = {}, ...rest }) => ({
            ...rest,
            veld: {
              ...veldRest,
              clusters: clusters.filter(({ clusterId }) =>
                usedClusterIds?.includes(clusterId)
              ),
            },
          })),
      },
    }),
    [applicationOverride.page, gegevens, usedClusterIds]
  );

  const newProps = useMemo(
    () => ({
      ...props,
      page: filteredPage,
      relations: applicationOverride.relations,
      id: props.applicationItemId,
      itemId: props.applicationItemId,
      pagetype: "applicatie",
      pagetypes: ["applicatie"],
      getContent: getContent(filteredPage, ["applicatie"], {
        pageActions: readOnlyPageActions,
      }),
    }),
    [applicationOverride.relations, filteredPage, props]
  );

  const hasGegevens = filteredPage.gegeven.clusters.length > 0;
  return (
    <>
      {(props.pageActions?.edit || !hasGegevens) && (
        <HintCard
          primaryText="Dit tabblad toont alleen gegevens."
          secondaryText="Koppel gegevens van de module bij de applicatiegegevens."
        />
      )}
      <ApplicatieGegevens
        key="gegevens"
        {...newProps}
        pageActions={readOnlyPageActions}
        hasGegevens={hasGegevens}
        gegevens={gegevens}
        disableModuleIcon
      />
    </>
  );
};

const renderModuleGegevens = (props: any) => {
  const veldModuleType: RelationType | undefined = props.types.find(
    ({ alias }: RelationType) => alias === "applicatieveld_module"
  );

  const relations = (props.relations ?? []).filter(
    ({ relationTypeId }: Relation) => relationTypeId === veldModuleType?.id
  );

  const application = relations.map(({ left: { itemId } }: Relation) => itemId);
  return [
    <ModuleGegevens
      {...props}
      applicationItemId={application[0]}
      relations={relations}
    />,
  ];
};

export default renderModuleGegevens;
