import { RaisedButton } from "material-ui";
import React, { useCallback, useState } from "react";

import { PageActions } from "../../../../business/models";
import { ApplicatieGegeven } from "./models";
import MoveVeldenDialog from "./MoveVeldenDialog";

interface MoveVeldenProps {
  pageActions: PageActions;
  gegeven: ApplicatieGegeven;
  gegevens: ApplicatieGegeven[];
}

const MoveVelden = ({
  gegeven,
  gegevens,
  pageActions,
}: MoveVeldenProps): JSX.Element => {
  const [open, setOpen] = useState(false);
  const toggleOpen = useCallback(() => setOpen(!open), [open]);

  return (
    <>
      <RaisedButton
        label="Velden verplaatsen"
        secondary
        onClick={toggleOpen}
        style={{ marginLeft: 16 }}
      />
      {open && (
        <MoveVeldenDialog
          velden={gegeven.veld.clusters}
          gegevens={gegevens}
          onClose={toggleOpen}
          pageActions={pageActions}
        />
      )}
    </>
  );
};

export default MoveVelden;
