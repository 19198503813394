import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
interface Cell<TValue, TStore> {
  select: (state: TStore) => TValue;
  set: (value: TValue) => { type: string; payload: TValue };
}
const useReduxState = <TValue, TStore>(
  cell: Cell<TValue, TStore>
): [TValue, (value: TValue) => void] => {
  const dispatch = useDispatch();
  const state = useSelector(cell.select);
  const setter = useCallback(
    (value: TValue) => dispatch(cell.set(value)),
    [cell, dispatch]
  );
  return [state, setter];
};

export default useReduxState;
