import classNames from "classnames";
import empty from "empty";
import { sortBy } from "lodash";
import { concat, map, uniqBy } from "lodash/fp";
import PropTypes from "prop-types";
import React, { Fragment } from "react";

import { readOnlyPageActions } from "../../../business/models";
import {
  clusterShape,
  koppelingApplicatieShape,
  koppelingApplicationsShape,
  pageShape,
  relationShape,
} from "../../../business/prop-types";
import { Side } from "../../../business/relations";
import { flown } from "../../../lodash";
import Field, { LabelValue } from "../../content/field";
import { isVisibleField } from "../../content/query";
import { ItemLink } from "../../relation.itemlink";
import {
  filterEntiteitRelations,
  filterKerngegevenRelations,
  filterModuleRelations,
  filterZibRelations,
  findVeldCluster,
} from "./functions";

const RelationsLabelValue = ({ label, items }) =>
  items.length > 0 ? (
    <LabelValue
      label={
        items.length === 1
          ? label
          : /t$/.test(label)
          ? `${label}en`
          : `${label}s`
      }
    >
      {items.map((item, i) => (
        <Fragment key={item.itemId}>
          {i > 0 && ", "}
          <ItemLink item={item} />
        </Fragment>
      ))}
    </LabelValue>
  ) : null;

const KoppelingField = ({ app, page, relations }) => {
  const { gegevenClusterId, veld } =
    findVeldCluster(app.pagClsIdt)(page) || empty.object;
  const entities = flown(
    relations,
    filterEntiteitRelations(gegevenClusterId),
    map((rel) => rel.left),
    uniqBy((item) => item.itemId),
    map(({ pageClusterId, pageClusterLabel, ...item }) => item),
    sortBy((item) => item.label)
  );
  const kerngegevens = flown(
    relations,
    filterKerngegevenRelations(gegevenClusterId),
    map((rel) => rel.left),
    uniqBy((item) => item.itemId),
    map(({ pageClusterId, pageClusterLabel, ...item }) => item),
    sortBy((item) => item.label)
  );
  const zibs = flown(
    relations,
    filterZibRelations(gegevenClusterId),
    concat(veld ? filterZibRelations(veld.clusterId)(relations) : empty.array),
    map((zib) => zib.right),
    uniqBy((item) => item.itemId),
    map(({ pageClusterId, pageClusterLabel, ...item }) => item),
    sortBy((item) => item.label)
  );
  const modules = flown(
    veld ? filterModuleRelations(veld.clusterId)(relations) : empty.array,
    map((rel) => rel.right),
    uniqBy((item) => item.itemId),
    map(({ pageClusterId, pageClusterLabel, ...item }) => item),
    sortBy((item) => item.label)
  );

  if (!veld || !veld.typeVeld) {
    return null;
  }

  return (
    <div className="koppeling-field">
      <LabelValue label="Applicatieveld">
        <ItemLink
          item={{
            itemId: app.itmIdt,
            label: app.naam,
            pageClusterId: app.pagClsIdt,
          }}
          title="Applicatieveld"
        />
        {" ("}
        <ItemLink
          item={{
            itemId: app.itmIdt,
            label: app.parentNaam,
            pageClusterId: app.parPagClsIdt,
          }}
          title="Applicatiegegeven"
        />
        )
      </LabelValue>
      <RelationsLabelValue label="Entiteit" items={entities} />
      <RelationsLabelValue label="Entiteitgegeven" items={kerngegevens} />
      <RelationsLabelValue label="Zib" items={zibs} />
      <RelationsLabelValue label="Module" items={modules} />
      {isVisibleField(veld.typeVeld, readOnlyPageActions) && (
        <Field
          field={veld.typeVeld}
          clusterId={veld.clusterId}
          pageActions={readOnlyPageActions}
        />
      )}
      {isVisibleField(veld.veldnaamInDatabase, readOnlyPageActions) && (
        <Field
          field={veld.veldnaamInDatabase}
          clusterId={veld.clusterId}
          pageActions={readOnlyPageActions}
        />
      )}
      {isVisibleField(veld.veldnaamInAPI, readOnlyPageActions) && (
        <Field
          field={veld.veldnaamInAPI}
          clusterId={veld.clusterId}
          pageActions={readOnlyPageActions}
        />
      )}
      {isVisibleField(veld.verplicht, readOnlyPageActions) && (
        <Field
          field={veld.verplicht}
          clusterId={veld.clusterId}
          pageActions={readOnlyPageActions}
        />
      )}
    </div>
  );
};

KoppelingField.propTypes = Object.freeze({
  app: clusterShape.isRequired,
  page: pageShape.isRequired,
  relations: PropTypes.arrayOf(relationShape.isRequired).isRequired,
});

const KoppelingFields = ({
  side,
  application: { page, relations, fields },
  apps,
}) => (
  <div
    className={classNames(
      "koppeling-field-list",
      `koppeling-field-list-${side === Side.left ? "van" : "nar"}`
    )}
  >
    {apps.map((app) => (
      <KoppelingField
        key={`${app.itmIdt}-${app.pagClsIdt}`}
        app={app}
        page={page}
        fields={fields}
        relations={relations}
      />
    ))}
  </div>
);

KoppelingFields.propTypes = Object.freeze({
  side: PropTypes.oneOf([Side.left, Side.right]).isRequired,
  application: koppelingApplicatieShape.isRequired,
  apps: PropTypes.arrayOf(clusterShape.isRequired).isRequired,
});

const KoppelingFieldList = ({ applications, fromApps, toApps }) => (
  <div className="koppeling-field-list-wrapper">
    <KoppelingFields
      side={Side.left}
      application={applications.from}
      apps={fromApps}
    />
    <KoppelingFields
      side={Side.right}
      application={applications.to}
      apps={toApps}
    />
  </div>
);

KoppelingFieldList.propTypes = Object.freeze({
  applications: koppelingApplicationsShape.isRequired,
  fromApps: PropTypes.arrayOf(clusterShape.isRequired).isRequired,
  toApps: PropTypes.arrayOf(clusterShape.isRequired).isRequired,
});

export default KoppelingFieldList;
