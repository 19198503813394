/* eslint  react/prop-types: off */
import { map, range } from "lodash/fp";
import SvgIcon from "material-ui/SvgIcon";
import React from "react";
import { compose, pure, setDisplayName, withProps } from "recompose";

const BaseIcon = ({ active = 2, ...props }) => (
  <SvgIcon className="yo" {...props}>
    <g>
      {map((i) => {
        const dv = i === active ? 0.5 : 0;
        const v = 2 + dv;
        const y = 6 + 4 * i + dv / 2;
        const hr = 16 - 2 * i;
        const hl = 4 + 2 * i;
        const opacity = i === active ? undefined : 0.5;
        return (
          <path
            key={i}
            d={`M${hl} ${y}h${hr}v-${v}H${hl}v${v}z`}
            style={{ opacity }}
          />
        );
      })(range(0, 4))}
    </g>
  </SvgIcon>
);

const processIcon = (level, displayName) => {
  const result = compose(
    setDisplayName(displayName),
    pure,
    withProps({ active: level })
  )(BaseIcon);
  result.muiName = "SvgIcon";
  return result;
};

const Procesgroep = processIcon(0, "Procesgroep");
const Bedrijfsproces = processIcon(1, "Bedrijfsproces");
const Procesonderdeel = processIcon(2, "Procesonderdeel");
const Activiteit = processIcon(3, "Activiteit");
export { Procesgroep, Bedrijfsproces, Procesonderdeel, Activiteit };
