/* eslint  react/prop-types: off */
import classNames from "classnames";
import empty from "empty";
import { map } from "lodash/fp";
import PropTypes from "prop-types";
import React from "react";
import {
  compose,
  defaultProps,
  setDisplayName,
  setPropTypes,
  withHandlers,
  withProps,
} from "recompose";

import { flown } from "../../lodash";
import { isNonEmptyArray } from "../../utils";
import { propTypes, withDefaultPageActions } from "./ggvtyp";

/**
 * Gegevenstype 'Selectie' (3)
 */
const Selectie = ({
  value = empty.object,
  selectionList: { items } = empty.object,
  pageActions: { edit = false },
  className,
  showEmpty,
  field: {
    definition: { controlPlaceholder, explanation } = empty.object,
  } = empty.object,
  handleChange,
}) =>
  edit && isNonEmptyArray(items) ? (
    [
      explanation && (
        <div className="explanation" key="explanation">
          {explanation}
        </div>
      ),
      <select
        key="select"
        className={classNames("selectie", className, {
          novalue: !value.selectionId,
        })}
        onChange={handleChange}
        value={value.selectionId}
      >
        {showEmpty && <option value="">{controlPlaceholder || "---"}</option>}
        {flown(
          items,
          map((item) => (
            <option key={item.id} value={item.id}>
              {item.value}
            </option>
          ))
        )}
      </select>,
    ]
  ) : (
    <span className={className}>{value.value}</span>
  );

const enhance = compose(
  setDisplayName("Selectie"),
  setPropTypes({
    ...propTypes,
    field: PropTypes.shape({
      value: PropTypes.shape({
        selectionId: PropTypes.number.isRequired,
        value: PropTypes.string,
        alias: PropTypes.string,
      }),
    }).isRequired,
  }),
  defaultProps({
    className: "selectie",
  }),
  withDefaultPageActions(),
  withProps(
    ({
      field: {
        isEmpty = false,
        definition: { required = false } = empty.object,
      },
    }) => ({
      showEmpty: isEmpty || !required,
    })
  ),
  withHandlers({
    handleChange:
      ({ setValue, selectionList: { items } = empty.object, showEmpty }) =>
      (e) => {
        const selectedValue =
          items[e.target.selectedIndex - (showEmpty ? 1 : 0)];
        setValue(
          selectedValue
            ? {
                value: { ...selectedValue, selectionId: selectedValue.id },
                string: selectedValue.value,
              }
            : empty.object
        );
      },
  })
);

export default enhance(Selectie);
