import empty from "empty";
import { get } from "lodash/fp";
import {
  branch,
  compose,
  renderNothing,
  setDisplayName,
  withHandlers,
  withState,
} from "recompose";

import { getVersionAction } from "../actions/data";
import { clearVersionDiffAction } from "../actions/ui";
import PreviewDialog from "../components/pagetypes/preview";
import { execOnChange } from "../recompose.contrib";
import { versionDiffSelector } from "../selectors/versions";
import { dispatchWrap } from "../utils";
import connect from "./connect";

const VersionDiff = compose(
  setDisplayName("VersionDiff"),
  connect(
    ({
      data: {
        relationTypes: { types = empty.array },
      },
      data,
      ui: {
        versionDiff: {
          open,
          itemId,
          versionId,
          relationVersionId,
          relationItemId,
          relationItemVersionId,
          created,
          current,
        },
      },
    }) => ({
      id: itemId,
      itemId,
      versionId,
      relationVersionId,
      relationItemId,
      relationItemVersionId,
      open,
      ...versionDiffSelector(data, {
        itemId,
        versionId,
        relationVersionId,
        relationItemId,
        relationItemVersionId,
        created,
      }),
      isCurrentVersion: current,
      types,
    }),
    (dispatch) => ({
      requireVersion: dispatchWrap(getVersionAction, dispatch),
      onClose: dispatchWrap(clearVersionDiffAction, dispatch),
    }),
    (stateProps, { requireVersion, ...dispatchProps }, ownProps) => ({
      ...stateProps,
      ...dispatchProps,
      ...ownProps,
      loading: get("loading")(stateProps.page),
      requireVersion: () => {
        if (stateProps.itemId) {
          requireVersion(stateProps.itemId, stateProps);
        }
      },
      notes: empty.array,
      form: { mail: empty.object, note: empty.object },
      selectionLists: empty.array,
      versionDialog: true,
    })
  ),
  branch(({ open }) => !open, renderNothing),
  withState("selectedTab", "setTab", 0),
  withHandlers({
    tabChanged:
      ({ setTab }) =>
      (tab) =>
        setTab(tab),
  }),
  execOnChange(
    "requireVersion",
    "itemId",
    "versionId",
    "relationVersionId",
    "relationItemId",
    "relationItemVersionId"
  )
)(PreviewDialog);

export default VersionDiff;
