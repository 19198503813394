import empty from "empty";
import { map } from "lodash/fp";
import { hashHistory, withRouter } from "react-router";
import {
  compose,
  lifecycle,
  pure,
  setDisplayName,
  withHandlers,
  withProps,
  withState,
} from "recompose";

import {
  checkLoginAction,
  getAzureAdUrlAction,
  getOfficeUrlAction,
  loginAction,
  loginInvalidateAction,
  logoutAction,
  passwordAction,
} from "../actions/session";
import { toStorage } from "../api/auth.common";
import Login from "../components/login";
import { flown } from "../lodash";
import { execOnChange } from "../recompose.contrib";
import { firstSlug, miniSaarSelector, sessionSelector } from "../selectors";
import { trimFirst } from "../utils";
import connect from "./connect";

/**
 * Map state to props
 */
const mapState = (state, props) => {
  const { session: { tfa } = empty.object } = state;
  return {
    tfa,
    miniSaar: miniSaarSelector(state, props),
    ...sessionSelector(state, props),
  };
};

/**
 * Map dispatch to props
 */
const mapDispatch = (dispatch) => ({
  check: () => dispatch(checkLoginAction()),
  invalidate: () => dispatch(loginInvalidateAction()),
  login: (user) => dispatch(loginAction(user)),
  logout: (officeLogoutUrl, sisenseLogout) =>
    dispatch(logoutAction(officeLogoutUrl, sisenseLogout)),
  password: (model) => dispatch(passwordAction(model)),
  officeUrl: () => dispatch(getOfficeUrlAction()),
  azureAdUrl: () => dispatch(getAzureAdUrlAction()),
});

/**
 * Login higher-order component
 */
export default compose(
  setDisplayName("Login"),
  connect(mapState, mapDispatch),
  withState("myLogin", "update", { username: "", password: "" }),
  withRouter,
  withProps(
    ({
      changePassword,
      location: {
        query: { changePassword: cpParam } = empty.object,
      } = empty.object,
    }) => ({ changePassword: changePassword || cpParam === "true" })
  ),
  pure,
  withHandlers({
    handleChange: (props) => (event) => {
      event.preventDefault();
      const name = event.target.name;
      const value = event.target.value;
      const myLogin = { ...props.myLogin, [name]: value };
      props.update(myLogin);
    },

    handleLogin:
      ({ myLogin, login, update }) =>
      (e) => {
        e.preventDefault();
        const [username, password] = flown(
          e.target.querySelectorAll("input"),
          map("value")
        );
        const credentials = {
          username: username || myLogin.username,
          password: password || myLogin.password,
        };
        update(credentials);
        if (credentials.username && credentials.password) {
          login(credentials);
        }
      },

    handlePassword:
      ({ update, myLogin, password }) =>
      (event) => {
        event.preventDefault();
        password(myLogin).then(() => update({ ...myLogin, done: true }));
      },

    handleTfa:
      ({
        myLogin: { username, password, secret } = empty.object,
        tfa: { seed, challenge } = empty.object,
        login,
      }) =>
      (event) => {
        event.preventDefault();
        login({ username, password, seed, secret, challenge });
      },

    handleLogout:
      ({
        office365: { logoutUri } = empty.object,
        logout,
        sisense: { logout: sisenseLogoutUrl },
      }) =>
      (event) => {
        event.preventDefault();
        logout(logoutUri, sisenseLogoutUrl);
      },

    handleOfficeClick:
      ({
        office365: { loginUri } = empty.object,
        location: { query: { next } = empty.object } = empty.object,
      }) =>
      () => {
        toStorage("saar_loginsource", "office365");
        if (next) {
          window.sessionStorage.setItem("saar_next", next);
        }

        window.location.href = loginUri;
      },

    handleAzureAdClick:
      ({
        azureAd: { loginUri } = empty.object,
        location: { query: { next } = empty.object } = empty.object,
      }) =>
      () => {
        toStorage("saar_loginsource", "azureadb2c");
        if (next) {
          window.sessionStorage.setItem("saar_next", next);
        }

        window.location.href = loginUri;
      },

    handleInvalidate:
      ({ check, invalidate }) =>
      () => {
        invalidate();
        check();
      },
  }),
  lifecycle({
    componentDidMount() {
      const {
        isAuthenticated = false,
        changePassword = false,
        checked = false,
        location: { query: { next } = empty.object } = empty.object,
        miniSaar,
        check = empty.func,
      } = this.props;

      if (!isAuthenticated && !checked) {
        check();
      } else if (
        isAuthenticated &&
        !changePassword &&
        checked &&
        next &&
        miniSaar &&
        miniSaar.path &&
        trimFirst(next, "/").indexOf(`${miniSaar.path}/`) === 0
      ) {
        // ingelogd
        hashHistory.push(next);
      } else if (
        isAuthenticated &&
        !changePassword &&
        checked &&
        miniSaar &&
        miniSaar.path &&
        next
      ) {
        // ingelogd met next, maar onjuiste miniSaar
        const miniSaarPathInNext = `/${firstSlug(next)}/`;
        hashHistory.push(
          `/${miniSaar.path}/${next.replace(miniSaarPathInNext, "")}`
        );
      } else if (isAuthenticated && !changePassword && checked && miniSaar) {
        // ingelogd; no next
        hashHistory.push(miniSaar.home);
      }
    },
    componentDidUpdate() {
      const {
        isAuthenticated = false,
        changePassword = false,
        checked = false,
        location: { query: { next } = empty.object } = empty.object,
        miniSaar,
      } = this.props;

      if (!isAuthenticated && !checked) {
        // do nothing
      } else if (
        isAuthenticated &&
        !changePassword &&
        checked &&
        next &&
        miniSaar &&
        miniSaar.path &&
        trimFirst(next, "/").indexOf(`${miniSaar.path}/`) === 0
      ) {
        // ingelogd
        hashHistory.push(next);
      } else if (
        isAuthenticated &&
        !changePassword &&
        checked &&
        miniSaar &&
        miniSaar.path &&
        next
      ) {
        // ingelogd met next, maar onjuiste miniSaar
        const miniSaarPathInNext = `/${firstSlug(next)}/`;
        hashHistory.push(
          `/${miniSaar.path}/${next.replace(miniSaarPathInNext, "")}`
        );
      } else if (
        isAuthenticated &&
        !changePassword &&
        checked &&
        miniSaar &&
        miniSaar.path
      ) {
        // ingelogd; no next
        hashHistory.push(miniSaar.home);
      }
    },
  }),
  execOnChange("officeUrl"),
  execOnChange("azureAdUrl")
)(Login);
