/* eslint  react/prop-types: off */
import classNames from "classnames";
import empty from "empty";
import { get, includes, some } from "lodash/fp";
import { Card } from "material-ui/Card";
import IconButton from "material-ui/IconButton";
import { List, ListItem } from "material-ui/List";
import MenuItem from "material-ui/MenuItem";
import { grey300 } from "material-ui/styles/colors";
import ActionSwapVert from "material-ui/svg-icons/action/swap-vert";
import ContentAdd from "material-ui/svg-icons/content/add";
import ExpandLess from "material-ui/svg-icons/navigation/expand-less";
import ExpandMore from "material-ui/svg-icons/navigation/expand-more";
import PropTypes from "prop-types";
import React from "react";
import {
  compose,
  onlyUpdateForKeys,
  setDisplayName,
  setPropTypes,
  withHandlers,
  withProps,
  withState,
} from "recompose";

import { addNewItem } from "../../actions/relation";
import { nonAddablePagetypes } from "../../business";
import { Side } from "../../business/relations";
import connect from "../../containers/connect";
import ItemDialog from "../../saar-add-item/itemDialog";
import { getTreeSelector } from "../../selectors/tree";
import { isEmpty } from "../../utils";
import PagetypeIcon from "../pagetypeIcon";
import { ItemLink } from "../relation.itemlink";
import IconButtonMenu from "./shared/iconButtonMenu";
import RaisedIconButton from "./shared/RaisedIconButton";

const hasCurrentStep = (currentSteps, children = empty.array) => {
  const result = children.find(
    (c) =>
      currentSteps.indexOf(c.itemId) >= 0 ||
      (c.itemId !== currentSteps && hasCurrentStep(currentSteps, c.children))
  );
  return result !== undefined;
};

const AddIconButton = ({
  parent,
  item,
  edit = false,
  busy = false,
  types = empty.array,
  tempItem = empty.object,
  updateTempItem,
  open,
  toggleOpen,
  onSort,
  sibling,
  editTree,
  sortOnly,
  siblings,
}) => {
  const sortSelf = sortOnly && sibling;
  if (!(edit && (editTree || sortSelf))) {
    return null;
  }

  const filteredTypes = editTree
    ? types.filter(
        ({
          left: { pagetype } = empty.object,
          right: { pagetype: addPagetype } = empty.object,
        }) =>
          pagetype === item.pagetype &&
          !includes(addPagetype)(nonAddablePagetypes)
      )
    : empty.array;

  const setOpen = () => {
    if (!open) {
      toggleOpen();
    }
  };

  return (
    <>
      {editTree && (
        <IconButtonMenu
          buttonIcon={<ContentAdd />}
          subheader="Item toevoegen"
          title="Item toevoegen"
          buttonDisabled={busy || filteredTypes.length === 0}
          className="treeview_add"
        >
          {filteredTypes.map((type) => {
            const {
              id,
              right: { pagetype } = empty.object,
              left: { label } = empty.object,
            } = type;
            return (
              <MenuItem
                key={id}
                leftIcon={<PagetypeIcon type={pagetype} />}
                primaryText={`${label} toevoegen`}
                onClick={() => {
                  setOpen();
                  updateTempItem({
                    ...tempItem,
                    pagetype,
                    relationType: type,
                    leftItemId: item.itemId,
                  });
                }}
              />
            );
          })}
        </IconButtonMenu>
      )}
      <RaisedIconButton
        title={"Volgorde bewerken"}
        onClick={() =>
          onSort(!parent ? 0 : parent.itemId, parent?.children ?? siblings)
        }
        icon={<ActionSwapVert />}
        disabled={
          busy ||
          (!parent && siblings.length <= 1) ||
          (parent && parent.children.length <= 1)
        }
        className="treeview_sort_btn"
        secondary
      />
    </>
  );
};

const dimmed = Object.freeze({ opacity: 0.3 });

// Note: toggleOpen is re-implemented to circumvent an issue in Material-UI
const renderTreeItem = ({
  className = "child",
  currentSteps = empty.array,
  parent,
  item,
  level,
  open = true,
  palette = empty.object,
  toggleOpen = empty.func,
  showAll = false,
  updateShowAll = empty.func,
  enableCollapse = true,
  pageActions: { edit = false, busy = false } = empty.object,
  pageActions = empty.object,
  types = empty.array,
  tempItem = empty.object,
  updateTempItem,
  onSort,
  item: { isActive = true, hasActive = true },
  siblings,
}) => {
  const self = currentSteps.indexOf(item.itemId) >= 0;
  const hasCurrent = hasCurrentStep(currentSteps, item.children);
  const labelStyle = isActive ? empty.object : dimmed;
  const buttonStyle = hasActive ? empty.object : dimmed;
  const isRoot = className === "root";
  const editTree = currentSteps.length === 0;
  const sortOnly =
    currentSteps.length === 1 && currentSteps[0] === pageActions.pageId;

  return (
    <ListItem
      key={item.itemId}
      className={classNames(className, { self })}
      style={isRoot ? { backgroundColor: grey300 } : undefined}
      primaryText={
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ flexGrow: "1", alignSelf: "center", ...labelStyle }}>
            {item.label}
          </div>
          <AddIconButton
            {...{
              item,
              parent,
              edit,
              busy,
              types,
              tempItem,
              updateTempItem,
              open,
              toggleOpen,
              onSort,
              editTree,
              sortOnly,
              siblings,
            }}
            sibling={
              Boolean(parent) &&
              currentSteps.length === 1 &&
              (currentSteps[0] === parent.itemId ||
                some({ itemId: currentSteps[0] })(parent.children))
            }
          />
        </div>
      }
      primaryTogglesNestedList={false}
      containerElement={!edit ? <ItemLink item={item} /> : "div"}
      open={open}
      onNestedListToggle={toggleOpen}
      autoGenerateNestedIndicator={false}
      rightIconButton={
        isRoot ? (
          <IconButton
            style={buttonStyle}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (enableCollapse) {
                updateShowAll(!showAll);
              }
            }}
          >
            {showAll ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        ) : isEmpty(item.children) ? (
          <></>
        ) : (
          <IconButton
            style={buttonStyle}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              toggleOpen();
            }}
          >
            {open ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        )
      }
      leftIcon={
        <PagetypeIcon
          type={item.pagetype}
          colorProperty={self ? "process-self" : get("status.alias")(item)}
          style={labelStyle}
        />
      }
      insetChildren={!self}
      nestedLevel={level}
      nestedItems={item.children.map((child) => (
        <TreeItem
          key={child.itemId}
          currentSteps={currentSteps}
          parent={item}
          item={child}
          initiallyOpen={hasCurrent}
          level={level + 1}
          palette={palette}
          pageActions={pageActions}
          types={types}
          tempItem={tempItem}
          updateTempItem={updateTempItem}
          onSort={onSort}
        />
      ))}
    />
  );
};

const TreeItem = compose(
  withState("open", "setOpen", ({ initiallyOpen }) => initiallyOpen),
  withHandlers({
    toggleOpen:
      ({ open, setOpen }) =>
      () =>
        setOpen(!open),
  })
)(renderTreeItem);

const initialCollapseState = ({ showAll, enableCollapse }) =>
  enableCollapse ? showAll || false : true;

const RootItem = compose(
  withState("showAll", "updateShowAll", initialCollapseState),
  withProps((props) => ({ item: getTreeSelector(props) }))
)(renderTreeItem);

const TreeCard = ({
  currentSteps,
  palette = empty.object,
  tree: { roots = empty.array } = empty.object,
  showAll = false,
  enableCollapse = true,
  pageActions,
  types,
  pagetypes,
  tempItem = empty.object,
  updateTempItem,
  addNew,
  onSort,
}) => (
  <Card style={{ marginBottom: "16px" }}>
    <ItemDialog
      closeDialog={() => updateTempItem(empty.object)}
      open={!isEmpty(tempItem) && !isEmpty(tempItem.pagetype)}
      saveItem={({ label }) => {
        addNew(
          {
            relationType: tempItem.relationType,
            side: Side.left,
            itemId: tempItem.leftItemId,
          },
          label
        );
        updateTempItem(empty.object);
      }}
      pagetypes={pagetypes}
      title={
        !isEmpty(tempItem.pagetype)
          ? `${
              pagetypes.find(({ alias }) => alias === tempItem.pagetype).name
            } toevoegen`
          : undefined
      }
      pagetypeDisabled
      initialPagetype={tempItem.pagetype}
      structures={empty.array /* TODO: dirty, check whether these are OK */}
      legoblokStructures={empty.array}
      selectionLists={empty.array}
      requireDefinition={empty.func}
      fieldDefinitionsByPagetype={empty.object}
    />

    <List style={{ paddingTop: 0, paddingBottom: 0 }}>
      {roots.map((root) => (
        <RootItem
          key={root.itemId}
          className="root"
          currentSteps={currentSteps}
          item={root}
          siblings={roots}
          initiallyOpen
          level={0}
          palette={palette}
          showAll={showAll}
          enableCollapse={enableCollapse}
          pageActions={pageActions}
          types={types}
          tempItem={tempItem}
          updateTempItem={updateTempItem}
          onSort={onSort}
        />
      ))}
    </List>
  </Card>
);

const treeShape = PropTypes.shape({
  id: PropTypes.number,
  loading: PropTypes.string,
  roots: PropTypes.arrayOf(PropTypes.object),
});

const TreeWrap = compose(
  setDisplayName("TreeWrap"),
  connect(
    ({
      ui: { theme: { palette = empty.object } = empty.object } = empty.object,
    }) => ({ palette }),
    (dispatch, { itemId }) => ({
      addNew: (currentItem, label, usingItemId) =>
        dispatch(
          addNewItem(currentItem, { label }, usingItemId, false, itemId)
        ),
    })
  ),
  onlyUpdateForKeys(["tree", "pageActions", "tempItem"]),
  withState("tempItem", "updateTempItem", empty.object),
  setPropTypes({
    currentSteps: PropTypes.arrayOf(PropTypes.number),
    pallete: PropTypes.object,
    tree: treeShape,
    showAll: PropTypes.bool,
    enableCollapse: PropTypes.bool,
    pageActions: PropTypes.object,
    types: PropTypes.arrayOf(PropTypes.object),
  })
)(TreeCard);

export default TreeWrap;
