import {
  compact,
  flatten,
  identity,
  map,
  orderBy,
  sortBy,
  uniq,
} from "lodash/fp";
import { Checkbox, List, ListItem } from "material-ui";
import React, { useCallback, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";

import { PageActions } from "../../actions/page";
import {
  BiebRelatedPagetypeMapping,
  Relation,
  RelationType,
  SiteLink,
  StoreRoot,
  StructureModel,
  emptyArray,
  emptyObject,
} from "../../business/models";
import { flown } from "../../lodash";
import PreviewButton from "../material/PreviewButton";
import PagetypeIcon from "../pagetypeIcon";
import { GroupedRelation } from "./models";

const forbiddenRelationType =
  (pagetype: string) =>
  ({ left, right, using }: RelationType): boolean =>
    (pagetype !== "koppeling" &&
      (right?.pagetype === "koppeling" || left?.pagetype === "koppeling")) ||
    (pagetype !== "koppeling" && using?.pagetype === "koppeling") ||
    right?.pagetype === "legoblok" ||
    left?.pagetype === "legoblok" ||
    using?.pagetype === "legoblok";

const RelationListItem = ({
  other,
  options: {
    typeLabels,
    relationForbidden,
    relationWillBeCreatedForKoppeling,
    extraText,
    local,
    checked,
    relationIds,
    thumbprint,
  },
  biebIntern,
  pageActions,
  localItemId,
  biebItemId,
}: {
  other: SiteLink;
  options: {
    typeLabels: string[];
    relationForbidden: boolean;
    relationWillBeCreatedForKoppeling: boolean;
    extraText?: string;
    local?: GroupedRelation;
    checked: boolean;
    relationIds: number[];
    thumbprint: string;
  };
  biebIntern?: StructureModel;
  pageActions: PageActions;
  localItemId: number;
  biebItemId: number;
}) => {
  useEffect(
    () => {
      if (!relationWillBeCreatedForKoppeling && local && !checked) {
        pageActions.add("RelationCopy", {
          itemId: localItemId,
          sourceItemId: biebItemId,
          relationIds,
          autoStaged: true,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [localItemId, biebItemId, thumbprint]
  );

  return (
    <ListItem
      key={`item-${other.itemId}`}
      className="bieb-use-wizard-field relation"
      primaryText={other.label}
      secondaryText={`${typeLabels.join(", ")}${extraText}`}
      title={
        local !== undefined ? "Relatie naar dit item bestaat al" : undefined
      }
      leftIcon={
        <PagetypeIcon
          type={other.type}
          biebIntern={
            biebIntern && other.structureId === biebIntern.id
              ? biebIntern.name
              : undefined
          }
          style={{
            top: "calc(50% - 12px)",
            margin: "0 0 0 48px",
          }}
        />
      }
      leftCheckbox={
        <span
          title={
            relationWillBeCreatedForKoppeling
              ? "Relatie naar dit item is verplicht"
              : undefined
          }
        >
          <Checkbox
            value={relationIds.join(",")}
            checked={relationWillBeCreatedForKoppeling || checked}
            disabled={
              relationWillBeCreatedForKoppeling ||
              relationForbidden ||
              local !== undefined
            }
            style={{ top: "calc(50% - 12px)" }}
            onCheck={(event, isChecked) => {
              if (isChecked) {
                pageActions.add("RelationCopy", {
                  itemId: localItemId,
                  sourceItemId: biebItemId,
                  relationIds,
                });
              } else {
                pageActions.raise("FORM_PAGE_UNSTAGE", {
                  $type:
                    "InfoZorgSAAR.Iprox.ContentActions.RelationCopyAction, SAAR.Iprox",
                  itemId: localItemId,
                  sourceItemId: biebItemId,
                  relationIds,
                });
              }
            }}
          />
        </span>
      }
      rightIcon={<PreviewButton itemId={other.itemId} />}
    />
  );
};

const CompareRelations = ({
  localItemId,
  biebItemId,
  groupedBieb,
  groupedLocal,
  relationTypes,
  biebRelatedPagetypeMapping = emptyObject(),
  biebStructures,
  isIznet,
  pageActions,
  pagetype,
  koppelingCanBeUsed,
  koppelingApplicationType,
}: {
  localItemId: number;
  biebItemId: number;
  groupedBieb: GroupedRelation[];
  groupedLocal: GroupedRelation[];
  relationTypes: RelationType[];
  biebRelatedPagetypeMapping: BiebRelatedPagetypeMapping;
  biebStructures: StructureModel[];
  isIznet: boolean;
  pageActions: PageActions;
  pagetype: string;
  koppelingCanBeUsed: boolean;
  koppelingApplicationType?: RelationType;
}) => {
  const biebIntern =
    !isIznet && biebStructures.length > 1 ? biebStructures[0] : undefined;

  const stagedActions = useSelector(
    (store: StoreRoot) =>
      store.form.page[localItemId]?.staged?.filter((s) =>
        /ContentActions.RelationCopyAction/.test(s.$type)
      ) ?? emptyArray()
  );

  const filteredGroupedBieb = useMemo<GroupedRelation[]>(
    () =>
      groupedBieb.filter(([itemId, _relations]) => {
        const mapped =
          biebRelatedPagetypeMapping[Number(itemId)] ?? emptyObject();
        const local = groupedLocal.find((g) => mapped[Number(g[0])]);
        return local === undefined;
      }),
    [biebRelatedPagetypeMapping, groupedBieb, groupedLocal]
  );

  const filterOnlyCheckable = useCallback(
    ([itemId, relations]: GroupedRelation) => {
      const relTypeIds = relations.map((r) => r.relationTypeId);
      const types = relationTypes.filter((t) => relTypeIds.includes(t.id));
      const relationForbidden = types.some(forbiddenRelationType(pagetype));

      const relationWillBeCreatedForKoppeling =
        koppelingCanBeUsed &&
        relations[0].relationTypeId === koppelingApplicationType?.id;

      const mapped =
        biebRelatedPagetypeMapping[Number(itemId)] ?? emptyObject();
      const local = groupedLocal.find((g) => mapped[Number(g[0])]);

      if (
        relationWillBeCreatedForKoppeling ||
        relationForbidden ||
        local !== undefined
      ) {
        return null;
      }

      return [itemId, relations] as GroupedRelation;
    },
    [
      biebRelatedPagetypeMapping,
      groupedLocal,
      koppelingApplicationType?.id,
      koppelingCanBeUsed,
      pagetype,
      relationTypes,
    ]
  );
  const filteredGroupedCheckableBieb = useMemo<GroupedRelation[]>(
    () => flown(filteredGroupedBieb, map(filterOnlyCheckable), compact),
    [filterOnlyCheckable, filteredGroupedBieb]
  );

  const handleAllRelationsClick = useCallback(
    (_: unknown, isChecked: boolean) => {
      if (isChecked) {
        filteredGroupedCheckableBieb.forEach(([, relations]) => {
          const relationIds = relations.map((r) => r.id);
          pageActions.add("RelationCopy", {
            itemId: localItemId,
            sourceItemId: biebItemId,
            relationIds,
          });
        });
      } else {
        filteredGroupedCheckableBieb.forEach(([, relations]) => {
          const relationIds = relations.map((r) => r.id);
          pageActions.raise("FORM_PAGE_UNSTAGE", {
            $type:
              "InfoZorgSAAR.Iprox.ContentActions.RelationCopyAction, SAAR.Iprox",
            itemId: localItemId,
            sourceItemId: biebItemId,
            relationIds,
          });
        });
      }
    },
    [biebItemId, filteredGroupedCheckableBieb, localItemId, pageActions]
  );

  return groupedBieb.length > 0 ? (
    <List>
      <ListItem
        primaryText="Alles selecteren"
        className="bieb-use-wizard-field select-all"
        leftCheckbox={
          <Checkbox
            checked={filteredGroupedCheckableBieb
              .flatMap(([, relations]) => relations)
              .map((r) => r.id)
              .every((r) =>
                stagedActions.flatMap((a) => a.relationIds ?? []).includes(r)
              )}
            onCheck={handleAllRelationsClick}
            style={{ top: "calc(50% - 12px)" }}
            disabled={filteredGroupedCheckableBieb.length === 0}
          />
        }
      />
      {flown(
        groupedBieb,
        map(([itemId, groupedRelations]: GroupedRelation) => {
          const relTypeIds = groupedRelations.map((r) => r.relationTypeId);
          const types = relationTypes.filter((t) => relTypeIds.includes(t.id));

          const typeLabels = (spec: boolean): string[] =>
            flown(
              groupedRelations,
              map((r: Relation): string | undefined => {
                const type = types.find((t) => t.id === r.relationTypeId);
                return !type
                  ? undefined
                  : r.side === 3
                  ? spec
                    ? type.using?.labelSpec
                    : type.using?.label
                  : r.side === 1
                  ? type.left?.label
                  : type.right?.label;
              }),
              compact,
              uniq,
              sortBy(identity)
            );

          const relationForbidden = types.some(forbiddenRelationType(pagetype));

          const relationWillBeCreatedForKoppeling =
            koppelingCanBeUsed &&
            groupedRelations[0].relationTypeId === koppelingApplicationType?.id;

          const extraText = relationForbidden
            ? " - Niet mogelijk om over te nemen"
            : "";

          const others =
            groupedRelations[0].side === 3
              ? [groupedRelations[0].left, groupedRelations[0].right]
              : groupedRelations[0].side === 2
              ? [groupedRelations[0].left]
              : [groupedRelations[0].right];

          const relationIds = groupedRelations.map((r) => r.id);
          const thumbprint = relationIds.sort().join("+");

          const mapped =
            biebRelatedPagetypeMapping[Number(itemId)] ?? emptyObject();
          const local = groupedLocal.find((g) => mapped[Number(g[0])]);

          const checked = stagedActions.some(
            (a) => (a.relationIds ?? []).sort().join("+") === thumbprint
          );

          return others.map((other, i) => ({
            other,
            options: {
              typeLabels: typeLabels(i === 1),
              relationForbidden,
              relationWillBeCreatedForKoppeling,
              extraText,
              local,
              checked,
              relationIds,
              thumbprint,
            },
          }));
        }),
        flatten,
        orderBy(["other.label"], ["asc"]),
        map(({ other, options }) => (
          <RelationListItem
            key={other.itemId}
            other={other}
            options={options}
            localItemId={localItemId}
            biebItemId={biebItemId}
            pageActions={pageActions}
            biebIntern={biebIntern}
          />
        ))
      )}
    </List>
  ) : (
    <p>Er zijn geen relaties gevonden om over te nemen.</p>
  );
};

export default CompareRelations;
