import React, { memo } from "react";

import { ExternLink, SiteLink } from "../../../business/models";
import PagetypeIcon from "../../pagetypeIcon";

const PageIcon = memo<{ link?: SiteLink; externLink?: ExternLink }>(
  ({ link, externLink }) => (
    <PagetypeIcon
      type={link ? link.type : externLink ? "hyperlink" : undefined}
      containerStyle={undefined}
      iconStyle={undefined}
      style={undefined}
      colorProperty={undefined}
      simpleIcon={undefined}
      insideBieb={undefined}
    />
  )
);

export default PageIcon;
