import React, { createContext } from "react";
import { compose } from "recompose";

import { ignoreKnownIrrelevancies } from "../../recompose.contrib";

export const ClusterPresentation = createContext({
  expansion: -1,
  search: "",
});

const consumeClusterPresentationInner = <
  T extends { clusterExpansion: number }
>(
  Component: typeof React.Component
) => {
  return (props: Omit<T, "clusterExpansion" | "clusterSearch">) => (
    <ClusterPresentation.Consumer>
      {({ expansion, search }) => (
        <Component
          {...props}
          clusterExpansion={expansion}
          clusterSearch={search}
        />
      )}
    </ClusterPresentation.Consumer>
  );
};

export const consumeClusterPresentation = compose(
  consumeClusterPresentationInner,
  ignoreKnownIrrelevancies
);
