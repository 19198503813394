/* eslint  react/prop-types: off */

import classNames from "classnames";
import empty from "empty";
import {
  compact,
  filter,
  find,
  flatten,
  flow,
  includes,
  keys,
  map,
  some,
  sortBy,
  values,
} from "lodash/fp";
import PropTypes from "prop-types";
import React, { Fragment, useContext } from "react";
import { withRouter } from "react-router";
import { compose, setDisplayName, setPropTypes, withProps } from "recompose";

import { Position } from "../../business";
import { withContentPlug } from "../../business/contentPlug";
import AddButton from "../../containers/addButton";
import { flown, mapWithIndex } from "../../lodash";
import { updateOnDataChanged } from "../../recompose.contrib";
import { arrayFlatMap, isNonEmptyArray } from "../../utils";
import ClustersOrderDialog from "../ClustersOrderDialog";
import ClusterCard from "../material/clusterCard";
import StickOnScroll from "../utils/StickOnScroll";
import ClusterCardsList from "./ClusterCardsList";
import { ClusterPresentation } from "./clusterPresentation";
import ClusterRelations from "./ClusterRelations";
import Field from "./field";
import Heading from "./Heading";
import {
  containsClusterId,
  descendants,
  filterClusterRelations,
  getClusterLabel,
  isField,
  isVisible,
  members,
  repetitionDepth,
} from "./query";

const insertSecond = (array, second, key) => {
  const fragment = second && <Fragment key={key}>{second}</Fragment>;
  return fragment
    ? array.length === 0
      ? [fragment]
      : [array[0], fragment, ...array.slice(1)]
    : array;
};

const lockedClusterRelationTypeAliases = ["veld_koppeling_veld"];

const Option = ({ children }) =>
  (children && children.length > 0 ? children[0] : children) || null;

const getRenderChild =
  (
    alias,
    plug,
    pagetypes,
    pageActions,
    cluster,
    isArray,
    nextLevel,
    types,
    relations,
    clusterId
  ) =>
  ({ child, name, id }, omitTitle, showCard, nthChild) => {
    if (isField(child)) {
      return (
        <Field
          key={id}
          alias={name || alias}
          name={plug.fieldName(child, name)}
          field={child}
          pagetypes={pagetypes}
          pageActions={pageActions}
          plug={plug}
          clusterId={cluster ? cluster.clusterId : undefined}
        />
      );
    }

    const hasDeleteAndRestore =
      isArray &&
      pageActions.edit &&
      !(
        includes("legoblok")(pagetypes) &&
        flown(
          child,
          values,
          some((field) => field?.definition?.dataTypeCode === 13)
        )
      );

    return (
      <Cluster
        key={id}
        alias={name || alias}
        name={plug.clusterName(child, name)}
        level={nextLevel}
        nthChild={nthChild}
        omitTitle={omitTitle}
        showCard={showCard}
        cluster={child}
        clusterId={cluster?.clusterId}
        pagetypes={pagetypes}
        pageActions={pageActions}
        plug={plug}
        relations={relations}
        types={types}
        onDelete={
          hasDeleteAndRestore
            ? (childId) =>
                pageActions.clusterRemove({
                  parentId: clusterId,
                  clusterId: childId ?? child.clusterId,
                  name: name || alias,
                })
            : undefined
        }
        onRestore={
          hasDeleteAndRestore
            ? (childId) =>
                pageActions.clusterRevert({
                  parentId: clusterId,
                  clusterId: childId ?? child.clusterId,
                  name: plug.clusterName(child, name),
                })
            : undefined
        }
        showCopyLink={isArray && !pageActions.edit && !plug.omitCopyLink}
      />
    );
  };

const ClusterComponent = (props) => {
  const {
    cluster,
    clusterId,
    plug,
    name = plug.clusterName(cluster),
    alias,
    omitAddButton = false,
    omitTitle,
    showCard,
    pagetypes,
    pageActions: parentPageActions,
    relations = empty.array,
    types = empty.array,
    onDelete,
    onRestore,
    level = 0,
    linkedClusterId,
    showCopyLink,
  } = props;

  const clusterPresentation = useContext(ClusterPresentation);
  const { expansion: clusterExpansion } = clusterPresentation;
  const isArray =
    cluster.iproxType in cluster && Array.isArray(cluster[cluster.iproxType]);
  const isLink = (obj) =>
    isField(obj) && !obj.isEmpty && obj.definition.dataTypeCode === 18;
  const link = isArray
    ? undefined
    : flown(cluster, values, find(isLink)) ??
      flown(cluster, values, map(values), flatten, compact, find(isLink));
  const pageActions = link
    ? { ...parentPageActions, pageId: link.value.linkId }
    : parentPageActions;

  const addButton =
    !omitAddButton && isArray && pageActions.edit ? (
      <AddButton
        label={plug.clusterName(cluster, name)}
        name={cluster.names?.[0] ?? name}
        plug={plug}
        clusterId={clusterId}
        gegeven={plug.gegeven(
          cluster,
          clusterId,
          plug.clusterName(cluster, name),
          props
        )}
        pageActions={pageActions}
        enabled
        renderAfterAddButton={plug.renderAfterAddButton(
          cluster,
          clusterId,
          plug.clusterName(cluster, name),
          props
        )}
      />
    ) : null;
  const hasButton = addButton !== null;

  if (
    typeof cluster !== "object" ||
    Object.keys(cluster).length === 0 ||
    (isArray &&
      cluster[cluster.iproxType].length === 0 &&
      !hasButton &&
      !isNonEmptyArray(cluster.moreClusters))
  ) {
    return null;
  }

  const childFlow = isArray
    ? [
        members,
        filter((obj) => isVisible(obj, pageActions)),
        plug.sort,
        mapWithIndex((child, i) => ({
          child,
          name: plug.clusterName(cluster, name),
          id: child.id || `${child.clusterId}-${i}`,
        })),
      ]
    : [
        keys,
        map((key) => ({ key, child: cluster[key] })),
        filter(
          ({ child }) => isVisible(child, pageActions) && plug.show(child)
        ),
        sortBy(({ child }) => !isField(child)),
        mapWithIndex(({ child, key }, i) => ({
          child,
          id: child.id || `${key}-${i}`,
        })),
      ];
  const childObjects = flow(...childFlow)(cluster);
  const removed =
    typeof cluster.clusterId === "number" &&
    includes(link?.value.clusterId ?? cluster.clusterId)(
      parentPageActions.removed
    );
  const collapse =
    cluster.iproxType === "clusters"
      ? undefined
      : plug.collapse(cluster, plug.clusterName(cluster, name), props);
  const nextLevel = level + (typeof collapse === "string" ? 1 : 0);
  const suffix =
    Boolean(collapse) && typeof plug.suffix === "function"
      ? plug.suffix(cluster, plug.clusterName(cluster, name), props)
      : undefined;
  const position =
    cluster.iproxType !== "clusters" &&
    plug.relationsPosition(cluster, plug.clusterName(cluster, name), props);

  if (
    childObjects.length === 0 &&
    !hasButton &&
    !isNonEmptyArray(cluster.moreClusters)
  ) {
    return null;
  }

  const renderChild = getRenderChild(
    alias,
    plug,
    pagetypes,
    pageActions,
    cluster,
    isArray,
    nextLevel,
    types,
    relations,
    clusterId
  );

  if (
    showCard !== true &&
    omitTitle !== false &&
    !collapse &&
    childObjects.length === 1 &&
    !isField(childObjects[0].child) &&
    childObjects[0].name !== "Veld" &&
    !hasButton
  ) {
    return renderChild(childObjects[0], omitTitle, showCard, 0);
  }

  if (
    (showCard !== true && omitTitle === true) ||
    cluster.iproxType === "clusters"
  ) {
    const clusters = map(({ child, name }) => ({
      id: child.clusterId,
      label: getClusterLabel(child, pageActions, plug.clusterName(child, name)),
    }))(childObjects);
    const buttons = !omitAddButton && isArray && pageActions.edit && (
      <div className="cluster-buttons-wrap">
        <div className="add-button-wrap">{addButton}</div>
        <div className="order-button-wrap">
          <ClustersOrderDialog
            name={plug.clusterName(cluster, name)}
            clusterId={clusterId}
            enabled={clusters.length > 1}
            clusters={clusters}
            pageActions={pageActions}
          />
        </div>
      </div>
    );

    const expandableDepth =
      clusterExpansion < 0 &&
      childObjects.length > 0 &&
      plug.bulkExpand(
        childObjects[0].child,
        plug.clusterName(childObjects[0].child, name),
        props
      )
        ? repetitionDepth(cluster)
        : 0;
    return (
      <Fragment>
        {plug.addAbove && buttons}
        <ClusterCardsList
          name={name}
          props={props}
          parent={cluster}
          clusters={childObjects}
          moreClusters={cluster.moreClusters}
          linkedClusterId={linkedClusterId}
          plug={plug}
          level={level}
          renderCluster={(c, i) =>
            renderChild(
              c,
              isArray ? omitTitle === true : omitTitle && i === 0,
              true,
              i
            )
          }
          expandableDepth={expandableDepth}
          edit={pageActions.edit}
        />
        {!plug.addAbove && buttons}
      </Fragment>
    );
  }

  const relationsForCluster = flown(
    relations,
    filterClusterRelations(cluster.clusterId, pageActions)
  );
  const relationTypesForCluster = types.filter(
    (t) => t.needsCluster && t.left.clusterName === name
  );

  const deleteDisabled =
    flown(
      relationsForCluster,
      some(({ alias }) => lockedClusterRelationTypeAliases.includes(alias))
    ) ||
    flown(
      cluster,
      descendants,
      arrayFlatMap((pagClsIdt) =>
        flown(relations, filterClusterRelations(pagClsIdt, pageActions))
      ),
      some(({ alias }) => lockedClusterRelationTypeAliases.includes(alias))
    );

  return (
    <Fragment>
      <Option>
        {!removed &&
          plug.renderBefore(cluster, plug.clusterName(cluster, name), props)}
      </Option>
      {cluster.heading && (
        <StickOnScroll key={cluster.heading} backgroundColor="#fafafa">
          <Heading className={classNames({ "text-end": cluster.textEnd })}>
            {cluster.heading}
          </Heading>
        </StickOnScroll>
      )}
      <ClusterCard
        collapse={collapse}
        autoFocus={
          cluster.clusterId === linkedClusterId
            ? "self"
            : containsClusterId(linkedClusterId)(cluster)
            ? "descendant"
            : undefined
        }
        expanded={
          !collapse ||
          cluster.clusterId === linkedClusterId ||
          containsClusterId(linkedClusterId)(cluster)
        }
        removed={removed}
        level={nextLevel}
        title={
          omitTitle === true && !onDelete
            ? undefined
            : plug.clusterName(cluster, name)
        }
        titleRenderer={plug.titleRenderer}
        titleSuffix={suffix}
        deleteDisabled={deleteDisabled}
        onDelete={
          onDelete && link ? () => onDelete(link.value.clusterId) : onDelete
        }
        onRestore={
          onRestore && link ? () => onRestore(link.value.clusterId) : onRestore
        }
        clusterId={cluster.clusterId}
        showCopyLink={showCopyLink}
        keepRendered={pageActions.edit}
      >
        {plug.addAbove && addButton}
        {plug.renderStart(cluster, plug.clusterName(cluster, name), props)}
        {position === Position.top && (
          <ClusterRelations
            clusterId={cluster.clusterId}
            pageActions={pageActions}
            relations={relationsForCluster}
            types={relationTypesForCluster}
            renderRelationLinkParentheses={plug.renderRelationLinkParentheses}
            renderLinks={plug.renderRelationLinks}
          />
        )}
        {(() => {
          let children = childObjects.map((c, i) =>
            renderChild(
              c,
              i === 0 ||
                plug.clusterName(c) === plug.clusterName(cluster, name) ||
                undefined,
              undefined,
              undefined
            )
          );
          if (
            position === Position.second &&
            (types.length > 0 || relationsForCluster.length > 0)
          ) {
            children = insertSecond(
              children,
              <ClusterRelations
                clusterId={cluster.clusterId}
                pageActions={pageActions}
                relations={relationsForCluster}
                types={relationTypesForCluster}
                renderRelationLinkParentheses={
                  plug.renderRelationLinkParentheses
                }
                renderLinks={plug.renderRelationLinks}
              />,
              "clusterRelations"
            );
          }
          const plugChild = plug.renderSecond(
            cluster,
            plug.clusterName(cluster, name),
            props
          );
          if (plugChild) {
            children = insertSecond(children, plugChild, "plugChild");
          }
          return children;
        })()}
        {plug.renderEnd(cluster, plug.clusterName(cluster, name), props)}
        {position === Position.bottom && (
          <ClusterRelations
            clusterId={cluster.clusterId}
            pageActions={pageActions}
            relations={relationsForCluster}
            types={relationTypesForCluster}
            renderRelationLinkParentheses={plug.renderRelationLinkParentheses}
            renderLinks={plug.renderRelationLinks}
          />
        )}
        {!plug.addAbove && addButton}
      </ClusterCard>
      <Option>
        {!removed &&
          plug.renderAfter(cluster, plug.clusterName(cluster, name), props)}
      </Option>
    </Fragment>
  );
};

const Cluster = compose(
  updateOnDataChanged(
    "alias",
    "cluster",
    "clusterId",
    "expandedLevel",
    "name",
    "omitAddButton",
    "omitTitle",
    "pageActions",
    "pagetypes",
    "plug",
    "showCard",
    "relations"
  ),
  setDisplayName("Cluster"),
  setPropTypes({
    pageActions: PropTypes.object.isRequired,
  }),
  withRouter,
  withProps(({ location }) => ({
    linkedClusterId: Number(location.query.clusterId),
  })),
  withContentPlug
)(ClusterComponent);

export default Cluster;
