import empty from "empty";
import {
  find,
  flattenDeep,
  flow,
  identity,
  keys,
  pickBy,
  values,
} from "lodash/fp";
import { withRouter } from "react-router";
import { compose, setDisplayName, withHandlers, withState } from "recompose";

import {
  doUseLegoblokAction,
  legoblokInitUseAction,
} from "../actions/legoblok";
import LegoblokUseDialog from "../components/legoblokUseDialog";
import connect from "./connect";

const findReference = (itemId) => flow(values, flattenDeep, find({ itemId }));

export default compose(
  setDisplayName("LegoblokUseDialog"),
  withRouter,
  connect(
    (
      {
        form: {
          legoblokUseDialog: { open = false, busy = false } = empty.object,
        },
        data: { legoblokReferences = empty.object },
      },
      { params: { id } = empty.object }
    ) => ({
      references:
        legoblokReferences && id && legoblokReferences[id]
          ? legoblokReferences[id].references
          : undefined,
      open,
      busy,
      legoblokId: id,
    }),
    (dispatch, _) => ({
      close: () => {
        dispatch(legoblokInitUseAction(false));
      },

      use: (legoblokUseModel) => {
        dispatch(legoblokInitUseAction(false));
        dispatch(doUseLegoblokAction(legoblokUseModel));
      },
    })
  ),
  withState("checkedReferences", "updateReferences", empty.object),
  withState("labels", "updateLabels", empty.object),
  withHandlers({
    checkReference:
      ({
        references,
        checkedReferences,
        updateReferences,
        labels,
        updateLabels,
      }) =>
      (event, isInputChecked) => {
        const itemId = parseInt(event.target.getAttribute("data-itemid"), 10);
        const selected = findReference(itemId)(references);
        updateReferences({
          ...checkedReferences,
          [selected.itemId]: isInputChecked,
        });
        updateLabels({ ...labels, [selected.itemId]: selected.label });
      },

    updateLabel:
      ({ references, labels, updateLabels }) =>
      (event, newValue) => {
        const itemId = parseInt(event.target.getAttribute("data-itemid"), 10);
        const selected = findReference(itemId)(references);
        updateLabels({ ...labels, [selected.itemId]: newValue });
      },

    doUseSelection:
      ({ references, legoblokId, checkedReferences, labels, use }) =>
      (event) => {
        event.preventDefault();
        const checked = flow(pickBy(identity), keys)(checkedReferences);
        const legoblokUseModel = {
          legoblokId,
          references: checked.map((itemId) => {
            const selected = findReference(parseInt(itemId, 10))(references);
            return {
              itemId,
              label: labels[itemId],
              pagetype: selected.type,
            };
          }),
        };

        use(legoblokUseModel);
      },
  })
)(LegoblokUseDialog);
