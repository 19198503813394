import React from "react";

import ButtonBar from "../../material/buttonBar";

interface UseLegoblokButtonProps {
  user: { mayAdd: boolean };
  doUseLegoblokDialog: (open: boolean) => void;
  pageActions: { edit: boolean };
}

const renderUseLegoblokButton = ({
  doUseLegoblokDialog,
  user: { mayAdd },
  pageActions: { edit },
}: UseLegoblokButtonProps) =>
  mayAdd && !edit && doUseLegoblokDialog ? (
    <ButtonBar key="use-legoblok-special" openDialog={doUseLegoblokDialog} />
  ) : null;

export default renderUseLegoblokButton;
