import { composeAction } from "async-lifecycle";

import { getVerantwoordingOverview } from "../api/application";
import { legoblokStructuresAction } from "./legoblok.structures";

const getVerantwoordingen = () =>
  composeAction({
    group: "VERANTWOORDINGEN",
    fire: getVerantwoordingOverview,
    cachekey: "verantwoordingen",
    timeout: 10000,
  });

export const requireVerantwoordingen = () =>
  composeAction(legoblokStructuresAction(), {
    callbackQuit: (dispatch) => dispatch(getVerantwoordingen()),
    callbackSuccess: (_, dispatch) => dispatch(getVerantwoordingen()),
  });

export const invalidateVerantwoordingen = () => ({
  type: "VERANTWOORDINGEN_INVALIDATE",
});

export const clearVerantwoordingen = () => ({
  type: "VERANTWOORDINGEN_CLEAR",
});
