/* eslint  react/prop-types: off */
import React from "react";

import style from ".";

const HighriseIcon = ({ style }) => (
  <svg version="1.1" x="0px" y="0px" viewBox="0 0 304 251" style={style}>
    <g>
      <path
        style={{ fill: "#abdeff" }}
        d="M152.002,0C73.128,0,11.344,106.263,5.5,190.951C30.241,233.86,89.926,250,152.002,250
      c62.069,0,121.757-16.14,146.498-59.049C292.653,106.263,230.868,0,152.002,0z"
      />
      <path
        style={{ fill: "#15c66c" }}
        d="M278.904,181.193c-12.509,30.439-56.756,47.477-126.9,49.201
      c-70.151-1.724-115.635-19.644-126.672-50.083c0-15.221,54.437-41.602,126.673-42.883
      C224.232,138.711,278.904,166.471,278.904,181.193z"
      />
      <polygon
        style={{ fill: "#a8a382" }}
        points="236.197,91.052 206.27,83.699 178.305,90.243 178.305,184.176 205.833,193.391 236.123,180.35"
      />
      <polygon
        style={{ fill: "#cac59d" }}
        points="178.305,184.176 205.833,193.391 206.038,96.404 204.977,96.404 178.305,90.243"
      />
      <polygon
        style={{ fill: "#f8f3c9" }}
        points="236.197,91.052 206.27,98.402 178.305,90.243 206.27,83.699"
      />
      <polygon
        style={{ fill: "#a8a382" }}
        points="127.648,132.967 97.724,105.863 67.803,117.57 67.807,179.962 97.492,194.203 127.648,184.985"
      />
      <polygon
        style={{ fill: "#cac59d" }}
        points="67.807,179.962 97.492,194.203 97.492,121.761 96.435,106.368 67.803,101.013"
      />
      <polygon
        style={{ fill: "#f8f3c9" }}
        points="127.648,116.409 97.724,128.119 67.803,101.013 97.724,89.306"
      />
      <polygon
        style={{ fill: "#5d98b0" }}
        points="187.594,62.493 151.998,53.136 116.403,62.493 107.708,198.914 151.654,214.668 196.123,197.79"
      />
      <polygon
        style={{ fill: "#87c6de" }}
        points="107.708,198.914 151.654,214.668 151.721,53.725 150.461,53.725 116.403,62.493"
      />
      <polygon
        style={{ fill: "#def4ff" }}
        points="187.594,62.493 151.998,71.855 116.403,62.493 151.998,53.136"
      />
      <path
        style={{ fill: "#5d98b0" }}
        d="M142.036,31.791c0-0.392,0.331-0.702,0.734-0.702l0,0c0.41,0,0.737,0.31,0.737,0.702l0.004,17.701
      c0.526,0.257,0.885,0.781,0.885,1.386v14.154c0,0.857-0.727,1.553-1.626,1.553l0,0c-0.899,0-1.62-0.696-1.62-1.553V50.878
      c0-0.608,0.36-1.134,0.889-1.39L142.036,31.791z"
      />
      <path
        style={{ fill: "#5d98b0" }}
        d="M159.901,24.544c0-0.392,0.331-0.702,0.734-0.702l0,0c0.41,0,0.737,0.31,0.737,0.702l0.004,17.701
      c0.526,0.257,0.885,0.781,0.885,1.386v14.154c0,0.857-0.727,1.553-1.626,1.553l0,0c-0.899,0-1.62-0.696-1.62-1.553V43.63
      c0-0.608,0.36-1.134,0.889-1.39L159.901,24.544z"
      />
    </g>
  </svg>
);

const Highrise = ({
  className,
  title = "Open Highrise",
  label = "Highrise",
  folder,
  id,
}) =>
  id ? (
    <a
      className={className}
      target="_blank"
      rel="noopener noreferrer"
      title={title}
      href={`https://infozorg.highrisehq.com/${folder}/${id}`}
      style={style.iconLink}
    >
      <HighriseIcon style={style.iconSvg} />
      <span style={style.iconTitle}>{label}</span>
    </a>
  ) : null;

export default Highrise;
