import { renderItemNotes } from "../../notes/itemNotes";
import renderApplicatieGegevens from "./application/applicatieGegevens";
import renderAutorisatieProfielen from "./application/autorisatieProfielen";
import renderBiebRelations from "./biebRelations";
import renderApplicationRelations from "./entiteit/applicationRelations";
import renderEntiteitGegevens from "./entiteit/entiteitGegevens";
import renderEntiteitRelations from "./entiteit/moduleRelations";
import renderEntiteitVerwerkerRelations from "./entiteit/verwerkerRelations";
import renderHierarchicalRelations from "./hierarchicalRelations";
import { renderHomeLink } from "./homeLink";
import renderKoppelingGegevens from "./koppeling/koppelingGegevens";
import {
  renderLandscape,
  renderLandscapeFilters,
  renderLandscapeLegend,
} from "./landscape";
import renderLegoblokAdd from "./legoblok";
import renderModuleGegevens from "./moduleGegevens";
import renderProcessNavigation from "./process/processNavigation";
import renderProcessVerantwoording from "./process/verantwoording";
import renderProcessTree from "./processTree";
import renderRaamwerk, { renderRaamwerkFilters } from "./raamwerk";
import renderRapportage from "./rapportage";
import renderRelatieToevoegen from "./relatieToevoegen";
import renderRisicoScore from "./risicoScore";
import renderSaarId from "./saarId";
import renderLeverancierVerwerkerRelations from "./supplier/verwerkerRelations";
import renderUseLegoblokButton from "./useLegoblokButton";
import renderVerantwoordingGegevens from "./verantwoording/verantwoordingGegevens";
import renderZibOnderdelen from "./zib";

const specialFuncs = {
  "Applicatie gegevens": renderApplicatieGegevens,
  "Autorisatie profielen": renderAutorisatieProfielen,
  Biebrelaties: renderBiebRelations,
  "Entiteit applicatierelaties": renderApplicationRelations,
  "Entiteit gegevens": renderEntiteitGegevens,
  "Entiteit modulerelaties": renderEntiteitRelations("module"),
  "Entiteit koppelingrelaties": renderEntiteitRelations("koppeling"),
  "Entiteit verwerkerrelaties": renderEntiteitVerwerkerRelations,
  Entiteitenlandschap: renderLandscape("entiteit_entiteit"),
  "Hierarchische relaties": renderHierarchicalRelations,
  "Home link": renderHomeLink,
  "Koppeling gegevens": renderKoppelingGegevens,
  "Landschap filters": renderLandscapeFilters,
  "Landschap legenda": renderLandscapeLegend,
  Landschap: renderLandscape("applicatie_applicatie"),
  "Legoblok goede praktijk toevoegen": renderLegoblokAdd("goedePraktijk"),
  "Legoblok hulpmiddel toevoegen": renderLegoblokAdd("hulpmiddel"),
  "Legoblok instructie toevoegen": renderLegoblokAdd("instructie"),
  "Legoblok overnemen": renderUseLegoblokButton,
  "Leverancier verwerkerrelaties": renderLeverancierVerwerkerRelations,
  "Module gegevens": renderModuleGegevens,
  Opmerkingen: renderItemNotes,
  "Proces navigatie": renderProcessNavigation,
  "Proces verantwoording": renderProcessVerantwoording,
  Processen: renderProcessTree,
  "Raamwerk filters": renderRaamwerkFilters,
  "Raamwerk treeview": renderRaamwerk,
  Rapportage: renderRapportage,
  "Relatie toevoegen": (props) =>
    renderRelatieToevoegen({
      ...renderRelatieToevoegen.defaultProps,
      ...props,
    }),
  "Risico score": renderRisicoScore,
  "Saar ID": renderSaarId,
  "Verantwoording gegevens": renderVerantwoordingGegevens,
  "Zib onderdelen": renderZibOnderdelen,
};

export const isSpecial = ({ name }) => typeof specialFuncs[name] === "function";

const getSpecial = ({ special, itemId, ...props }) => {
  const specialFun = specialFuncs[special.name];
  if (specialFun && typeof specialFun === "function") {
    return specialFun({ special, id: itemId, itemId, ...props });
  }

  return null;
};

export default getSpecial;
