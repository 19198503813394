import {
  MenuItem,
  SelectField,
  Subheader,
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
} from "material-ui";
import ArrowIcon from "material-ui/svg-icons/action/swap-horiz";
import React, { SyntheticEvent, useCallback } from "react";

import { SiteLink } from "../../business/models";
import { BiebUseSelection } from "./models";

const KoppelingApplicationsSelect = ({
  value,
  onChange,
  left,
  right,
}: {
  value: BiebUseSelection;
  onChange: (v: BiebUseSelection) => void;
  left?: SiteLink[];
  right?: SiteLink[];
}) => {
  const handleLeftSelect = useCallback(
    (e: SyntheticEvent<{}>, index: number, menuItemValue: number) => {
      onChange({
        ...value,
        applications: { left: menuItemValue, right: value.applications?.right },
      });
    },
    [onChange, value]
  );

  const handleRightSelect = useCallback(
    (e: SyntheticEvent<{}>, index: number, menuItemValue: number) => {
      onChange({
        ...value,
        applications: { right: menuItemValue, left: value.applications?.left },
      });
    },
    [onChange, value]
  );

  return (
    <>
      <Subheader>Selecteer de applicaties</Subheader>
      <Table selectable={false}>
        <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
          <TableRow>
            <TableHeaderColumn>Applicatie links</TableHeaderColumn>
            <TableHeaderColumn style={{ width: 50 }}>&nbsp;</TableHeaderColumn>
            <TableHeaderColumn>Applicatie rechts</TableHeaderColumn>
          </TableRow>
        </TableHeader>
        <TableBody displayRowCheckbox={false}>
          <TableRow>
            <TableRowColumn>
              <SelectField
                fullWidth
                value={value?.applications?.left ?? left?.[0]?.itemId}
                disabled={!left || left.length <= 1}
                onChange={handleLeftSelect}
              >
                {left?.map((item) => (
                  <MenuItem
                    key={item.itemId}
                    value={item.itemId}
                    primaryText={item.label}
                  />
                ))}
              </SelectField>
            </TableRowColumn>
            <TableRowColumn style={{ width: 50 }}>
              <ArrowIcon />
            </TableRowColumn>
            <TableRowColumn>
              <SelectField
                fullWidth
                value={value?.applications?.right ?? right?.[0]?.itemId}
                disabled={!right || right.length <= 1}
                onChange={handleRightSelect}
              >
                {right?.map((item) => (
                  <MenuItem
                    key={item.itemId}
                    value={item.itemId}
                    primaryText={item.label}
                  />
                ))}
              </SelectField>
            </TableRowColumn>
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
};

export default KoppelingApplicationsSelect;
