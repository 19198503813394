/* eslint  react/prop-types: off */

import empty from "empty";
import IconButton from "material-ui/IconButton";
import ActionDelete from "material-ui/svg-icons/action/delete";
import React, { useCallback } from "react";
import { useDispatch } from "react-redux";

import { remove } from "../../actions/relation";
import { ItemLink } from "../relation.itemlink";

const ClusterItemLink = ({
  clusterFirst,
  pageActions,
  item,
  relation,
  mayDelete,
  renderRelationLinkParentheses = empty.functionThatReturns(undefined),
}) => {
  const dispatch = useDispatch();
  const handleDelete = useCallback(() => {
    dispatch(
      remove(
        relation.relationTypeId,
        pageActions.pageId,
        relation.id,
        item.itemId
      )
    );
  }, [dispatch, pageActions.pageId, item.itemId, relation]);
  return (
    <div className="cluster-item-link">
      <p>
        <ItemLink
          item={item}
          parentheses={renderRelationLinkParentheses(relation)}
          clusterFirst={clusterFirst}
        />
      </p>
      {pageActions.edit && mayDelete ? (
        <div className="icon-button-inline">
          <IconButton onClick={handleDelete}>
            <ActionDelete />
          </IconButton>
        </div>
      ) : (
        <br />
      )}
    </div>
  );
};

export default ClusterItemLink;
