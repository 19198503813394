import empty from "empty";
import { intersection, some } from "lodash/fp";

import { flown } from "../lodash";
import { deprecatedPagetypes, infozorgOnlyPagetypes } from ".";

export const prohibitEditInfozorgOnly = ({ iznet = "None" }) =>
  iznet !== "Write";

const mayEditRelationType = (
  user = empty.object,
  { id: relationTypeId = 0, alias = "" }
) =>
  some(
    ({ prfIdt, scope: { itmRelTypIdt = empty.array } }) =>
      !alias.startsWith("legoblok_") &&
      !alias.endsWith("_legoblok") &&
      !alias.startsWith("bieb_") &&
      !alias.endsWith("_bieb") &&
      alias !== "entiteit_applicatiegegeven" &&
      alias !== "kerngegeven_applicatiegegeven" &&
      alias !== "entiteit_applicatieveld" &&
      alias !== "kerngegeven_applicatieveld" &&
      alias !== "veld_koppeling_veld" &&
      !alias.startsWith("applicatiegegeven_zib") &&
      !alias.startsWith("applicatieveld_zib") &&
      alias !== "training_sectie" &&
      alias !== "training_onderdeel" &&
      alias !== "applicatieveld_module" &&
      !/^zib_sub[1-3]_zib$/.test(alias) &&
      !/^informatiestandaard_(onderdeel|inhoud)_zib_.*ggv[1-3]$/.test(alias) &&
      prfIdt === 6 &&
      (itmRelTypIdt === true ||
        (itmRelTypIdt.includes(relationTypeId) &&
          (infozorgOnlyPagetypes.every((p) => alias.indexOf(p) < 0) ||
            !prohibitEditInfozorgOnly(user))))
  )(user.tasks);

export const mayAddRelationType = (user, relationType) => {
  if (!mayEditRelationType(user, relationType)) {
    return false;
  }

  const {
    left: { pagetype: left },
    right: { pagetype: right },
    using: { pagetype: using } = empty.object,
  } = relationType;
  return !flown(
    [left, right, using],
    intersection(deprecatedPagetypes),
    some(Boolean)
  );
};

export const mayDeleteRelationType = mayEditRelationType;
