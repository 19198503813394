import md5 from "blueimp-md5";
import empty from "empty";
import { map, partial } from "lodash/fp";

import { iproxUrl, usersessionAvailable } from "../config";
import { structuredMap } from "../utils";
import { addAuthenticationResult, possession, user } from "./auth";
import { PayloadType, tokenFor } from "./auth.common";
import {
  fetchVariantJSON,
  getJSON,
  postJSON,
  session,
  toQueryString,
} from "./fetch";

const omitBearer = { bearer: "omit" };
const iproxLogin = async () => {
  const token = tokenFor(PayloadType.access);
  if (!token) {
    return false;
  }

  const now = Date.now();
  if (now - session.timestamp < 10 * 60 * 1000) {
    return true;
  }

  if (usersessionAvailable) {
    const { loggedIn, loginName } = await getJSON(
      `${iproxUrl}/appidt/usersession`,
      undefined,
      undefined,
      omitBearer
    );
    if (loggedIn && loginName === user()) {
      session.refresh(now);
      return true;
    }
  }

  const { success } = await postJSON(`${iproxUrl}/aspx/login.aspx`);
  if (`${success}` === "true") {
    session.refresh(now);
    return true;
  }
  session.clear();
  return false;
};

/**
 * Gets an IPROX page by ItmIdt-method
 * @param {number} id a IPROX page [aka `ItmIdt`]
 * @param refresh
 */
export const getPage = async (id, refresh = false) => {
  try {
    return await fetchVariantJSON(`page/${id}?refresh=${refresh}`);
  } finally {
    iproxLogin();
  }
};

/**
 * Gets the IPROX navigation
 */
export const getNavigation = () => fetchVariantJSON("navigation");

export const getFieldDefinitions = () =>
  fetchVariantJSON("cms/definition/field");

export const getClusterDefinition = (itmIdt, clusterId, nam) =>
  fetchVariantJSON(
    `cms/definition/cluster?${toQueryString({ itmIdt, clusterId, nam })}`
  );

export const getFieldDefinitionsByPagetype = (pagetypeAlias) =>
  fetchVariantJSON(`cms/definition/root?${toQueryString({ pagetypeAlias })}`);

/**
 * Gets the IPROX selection lists
 */
export const getSelectionLists = () => fetchVariantJSON("sellist");

/**
 * Gets the IPROX pagetype list
 */
export const getPagetypeList = () => fetchVariantJSON("pagtyplist");

/**
 * Gets the IPROX Relations of an item
 * @param {number} id a IPROX page [aka `ItmIdt`]
 */
export const getRelations = (id) => fetchVariantJSON(`relations/${id}`);

/**
 * Gets the raamwerk treeview for page
 * @param {number} id a IPROX page [aka `ItmIdt`]
 */
export const getRaamwerk = (id) => fetchVariantJSON(`raamwerk/${id}`);

/**
 * Gets the raamwerk pagetypelist for page
 * @param {number} id a IPROX page [aka `ItmIdt`]
 * @param {string} raamwerkAlias
 */
export const getRaamwerkPagetypes = (id, raamwerkAlias) =>
  fetchVariantJSON(`raamwerk/${id}/${raamwerkAlias}`);

/**
 * Gets the raamwerk pagetypelist for page
 * @param {number} id a IPROX page [aka `ItmIdt`]
 * @param {string} pagetypeId
 * @param {string} selectionId
 * @param {number[]} organizationIds
 */
export const getRaamwerkTargets = (
  id,
  pagetypeId,
  selectionId,
  organizationIds
) => {
  const csv = organizationIds.join(",");
  return fetchVariantJSON(
    `raamwerk/${id}/targets/${pagetypeId}/${selectionId}?organizationIds=${csv}`
  ).then((value) => ({ value }));
};

/**
 * Gets the landscape data
 */
export const getLandscape = (id, relations = empty.array) =>
  fetchVariantJSON(`diagram/${id}`, { relations });

/**
 * Gets the landscape data between given applications
 */
export const getLandscapeBetween = (
  id,
  startingPoints = empty.array,
  relationType = "applicatie_applicatie"
) =>
  fetchVariantJSON(`diagram/${id}/between`, {
    list: startingPoints,
    relationType,
  });

/**
 * Gets the landscape data
 */
export const getExpandedLandscape = (
  id,
  startingPoints = empty.array,
  relationType
) =>
  fetchVariantJSON(`diagram/${id}/expand`, {
    list: startingPoints,
    relationType,
  });

/**
 * Gets the IPROX Relationtypes
 */
export const getRelationTypes = () => fetchVariantJSON("relations/types");

/**
 * Gets the recently visited items
 */
export const getRecent = () =>
  fetchVariantJSON("recent").then(
    structuredMap({
      items: map(structuredMap({ type: (type) => type || "bieb" })),
    })
  );

/**
 * Gets the recently generated overviews
 */
export const getRecentOverzicht = () => fetchVariantJSON("export/overzicht");

/**
 * Gets the recently created items
 */
export const getRecentlyCreated = () => fetchVariantJSON("recent/created");

/**
 * Adds an item to the recently visited items
 */
export const setRecent = partial(fetchVariantJSON, ["recent"]);

const unauthorizedLogin = { user: { tasks: empty.array } };
const knowledgeExpiredLogin = (username) => ({
  user: { username, changePassword: true, tasks: empty.array },
});
const possessionRequiredLogin = (username, possession) => ({
  user: { username, tasks: empty.array },
  possession,
});
const possessionInvalidLogin = (username) => ({
  user: { username, tasks: empty.array },
  possessionInvalid: true,
});

/**
 * Gets the authorization-tasks by its context
 */
export const checkLogin = async () => {
  if (tokenFor(PayloadType.access)) {
    const login = await getJSON(`${iproxUrl}/appidt/saar/authorization/tasks`);
    return (await iproxLogin()) ? login : unauthorizedLogin;
  }

  if (tokenFor(PayloadType.knowledgeFactor)) {
    return possessionRequiredLogin(user(), possession());
  }

  return unauthorizedLogin;
};

export const login = async ({ username: log, password: pwd, secret }) => {
  if (secret && tokenFor(PayloadType.knowledgeFactor) && possession()) {
    const { seed, challenge } = possession();
    const response = md5(secret + challenge);
    const possessionResult = await postJSON(
      "/appidt/saar/authentication/possession",
      { seed, response },
      [
        {
          key: "Authorization",
          value: `Bearer ${tokenFor(PayloadType.knowledgeFactor)}`,
        },
      ]
    );
    addAuthenticationResult(possessionResult);
    return possessionResult.possessionInvalid
      ? possessionInvalidLogin(user())
      : await checkLogin();
  }

  if (!log || !pwd) {
    return unauthorizedLogin;
  }

  const possessionToken = tokenFor(PayloadType.possessionFactor);
  const knowledgeResult = await postJSON(
    "/appidt/saar/authentication/knowledge",
    { log, pwd },
    possessionToken
      ? [
          {
            key: "Authorization",
            value: `Bearer ${possessionToken}`,
          },
        ]
      : empty.array
  );
  addAuthenticationResult(knowledgeResult);

  if (knowledgeResult.knowledgeExpired) {
    return knowledgeExpiredLogin(user());
  }

  return await checkLogin();
};

/**
 * Gets the authorization-tasks by its context
 */
export const changePassword = async ({ oldPassword, newPassword }) =>
  postJSON(`${iproxUrl}/appidt/saar/authorization/password`, {
    log: user(),
    oldPassword,
    newPassword,
  });

/**
 * Send a mail
 */
export const sendMail = (message) =>
  postJSON(`${iproxUrl}/appidt/saar/mail/send`, message);

/**
 * Gets the notes for an item
 * @param {number} id a IPROX page [aka `ItmIdt`]
 */
export const getItemNotes = (id) => fetchVariantJSON(`notes/${id}`);

/**
 * Adds or updates a note for an item
 * @param  {[type]} id      IPROX item ID [aka `ItmIdt`]
 * @param  {[type]} message Message object, with `id` and `text` props
 */
export const addItemNote = (id, message) =>
  fetchVariantJSON(`notes/${id}`, message);

/**
 * Adds or updates a note for an item
 * @param  {[type]} noteId      IPROX item ID [aka `ItmIdt`]
 */
export const deleteItemNote = (noteId) =>
  fetchVariantJSON(`notes/delete/${noteId}`, empty.object);

/**
 * Gets the tree for an item
 */
export const getTree = (
  id,
  itemIds = empty.array,
  areRootItems = false,
  paginatypen = empty.array
) =>
  fetchVariantJSON(`process/${id}/multitree`, {
    itemIds,
    areRootItems,
    paginatypen,
  });

/**
 * Gets the siblings for an item
 */
export const getSiblings = (id) => fetchVariantJSON(`process/${id}/siblings`);

/**
 * Exports the supplied data
 */
export const exportData = (title, columnNames, data) =>
  fetchVariantJSON("export", { title, columnNames, data }, undefined, {
    resultMethod: "blob",
  });

/**
 * Exports the specified overzicht
 */
export const exportOverzicht = ({ title, pagetype, columns, all }) =>
  fetchVariantJSON(
    "export/overzicht",
    { title, pagetype, columns, all },
    undefined,
    {
      resultMethod: "blob",
    }
  );

export const searchLinks = (id, query = id) =>
  fetchVariantJSON("search/links", query);

export const searchFull = (id, query) => fetchVariantJSON("search", query);

export const savePage = (pageId, actions) =>
  fetchVariantJSON(`cms/page/edit/${pageId}`, { actions });

export const restorePage = (pageId, versionId) =>
  fetchVariantJSON(`cms/page/restore/${pageId}/${versionId}`, empty.object);

/**
 * Gets all Mini SAAR's
 */
export const getMiniSaars = () => fetchVariantJSON("navigation/minisaars");

/**
 *  Add an item
 */
export const addItem = (newItem) => fetchVariantJSON("cms/page/add", newItem);

/**
 * Delete IPROX item
 * @param itemId IPROX ItmIdt field
 */
export const deleteItem = (itemId) =>
  fetchVariantJSON(`cms/page/delete/${itemId}`, empty.object);

/**
 * Upload a file to IPROX
 */
export const uploadFile = (itemId, filename, data) =>
  fetchVariantJSON(`cms/page/upload/${itemId}`, { filename, data });

export const findItem = (label, pagetype) =>
  fetchVariantJSON("cms/page/find", { label, pagetype });

export const getMediaWidget = (mwtIdt, width) =>
  fetchVariantJSON(`mediawidget/${mwtIdt}?width=${width || ""}`);

/**
 * Gets the structures for 'legoblokken'
 */
export const getLegoblokStructures = () =>
  fetchVariantJSON("cms/legoblok/structures");

/**
 * Create a 'legoblok'
 */
export const createLegoblok = (newLegoblok) =>
  fetchVariantJSON("cms/legoblok/create", newLegoblok);

/**
 * Gets an object with all referenced items grouped by tab-name from the
 * specified legoblok ItmIdt
 * @param itemIdt Legoblok ItmIdt
 */
export const getLegoblokReferences = (itemIdt) =>
  fetchVariantJSON(`cms/legoblok/${itemIdt}`);

export const doUseLegoblok = (legoblokUseModel) =>
  fetchVariantJSON(
    `cms/legoblok/${legoblokUseModel.legoblokId}`,
    legoblokUseModel.references
  );

export const getKoppelingFields = (itemIdt) =>
  fetchVariantJSON(`cms/koppeling/${itemIdt}/fields`);

export const requestPassword = (emailaddress) =>
  postJSON(`${iproxUrl}/appidt/saar/authorization/forgot`, {
    type: "nopwd",
    emailaddress,
  });

export const moveLegoblok = (itemId, newStructureId) =>
  fetchVariantJSON(
    `cms/legoblok/${itemId}/move/${newStructureId}`,
    empty.object
  );

/**
 * Add item to Bieb
 */
export const createBiebItem = (newBiebItem) =>
  fetchVariantJSON("cms/bieb/create", newBiebItem);

/**
 * Use item from the `Saar Bieb`
 */
export const doUseFromBieb = (data) => {
  const { model } = data;
  return fetchVariantJSON(`cms/bieb/${model.itemId}`, data);
};

/**
 * Use multiple items from the `Saar Bieb`
 */
export const doUseMultiFromBieb = (modelsArray) =>
  fetchVariantJSON("cms/bieb/multi", modelsArray);

export const getPresentation = (pagetype) =>
  fetchVariantJSON(`presentation/${pagetype}`);

export const getOfficeUrl = () =>
  getJSON(
    `${iproxUrl}/appidt/saar/authentication/office365/url?redirectUri=${encodeURIComponent(
      window.location.href
    )}`
  );

export const loginWithOffice = (code) =>
  getJSON(`${iproxUrl}/appidt/saar/authentication/office365`, [
    { key: "code", value: code },
  ]);

export const getAzureAdUrl = () =>
  getJSON(
    `${iproxUrl}/appidt/saar/authentication/azureadb2c/url?redirectUri=${encodeURIComponent(
      window.location.origin
    )}`
  );

export const loginWithAzureAd = (code) =>
  getJSON(`${iproxUrl}/appidt/saar/authentication/azureadb2c`, [
    { key: "code", value: code },
  ]);

export const loginWithGoogle = (code) =>
  getJSON(`${iproxUrl}/appidt/saar/authentication/google`, [
    { key: "code", value: code },
  ]);

export const getVersions = (itemId) => fetchVariantJSON(`versions/${itemId}`);

export const getVersionDiff = (
  itemId,
  { versionId, relationVersionId, relationItemId, relationItemVersionId }
) =>
  fetchVariantJSON(
    `versions/${itemId}/diff?${toQueryString({
      itemId,
      versionId,
      relationVersionId,
      relationItemId,
      relationItemVersionId,
    })}`
  );

export const getOffice365Info = () =>
  getJSON(`${iproxUrl}appidt/saar/authorization/office365`);

export const getOneDriveFileInfo = (oneDriveFile) =>
  postJSON(`${iproxUrl}appidt/saar/onedrive`, oneDriveFile);

export const exportWord = (model) =>
  fetchVariantJSON("export/word", model, undefined, { resultMethod: "blob" });

export const lockPage = (itemId, force = false) =>
  fetchVariantJSON(`cms/page/lock/${itemId}?force=${force}`, {});

export const unlockPage = (itemId) =>
  fetchVariantJSON(`cms/page/unlock/${itemId}`, {});
