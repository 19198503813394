import { omit } from "lodash/fp";
import TextField from "material-ui/TextField";
import {
  compose,
  mapProps,
  setDisplayName,
  withHandlers,
  withState,
} from "recompose";

import { execOnChange } from "../../recompose.contrib";

export const semiControlled = (displayName = "SemiController") =>
  compose(
    setDisplayName(displayName),
    withState(
      "localValue",
      "setLocalValue",
      ({ value, defaultValue }) => value || defaultValue || ""
    ),
    withHandlers({
      onChange:
        ({ onChange, setLocalValue }) =>
        (e, value) => {
          setLocalValue(value);
          onChange(e, value);
        },
      syncLocalValue:
        ({ setLocalValue, value }) =>
        () =>
          setLocalValue(value),
    }),
    execOnChange("syncLocalValue", "value"),
    mapProps((props) => ({
      ...omit(["defaultValue", "localValue", "rref", "setLocalValue"])(props),
      value: props.localValue || "",
      ref: props.rref,
    }))
  );

const SemiControlledTextField = semiControlled("SemiControlledTextField")(
  TextField
);

export default SemiControlledTextField;
