import empty from "empty";
import {
  chunk,
  compact,
  concat,
  filter,
  flatten,
  flow,
  get,
  includes,
  intersection,
  intersectionWith,
  keyBy,
  map,
  mapValues,
  reject,
  some,
  sortBy,
  uniq,
  uniqBy,
  values,
  zip,
} from "lodash/fp";
import { compose, mapProps, setDisplayName, withState } from "recompose";
import { createSelector } from "reselect";

import { flatItems, flown, push, toSet } from "../lodash";
import { structuredMap } from "../utils";
import connect from "./connect";
import { filterValues, groupFilters } from "./enhanceUtil";

const idSelector = (_, { params: { id } = empty.object }) => parseInt(id, 10);

const treeviewSelectionSelector = createSelector(
  [idSelector, get("data.tree")],
  (id, tree) => tree[id] || empty.object
);

const treeviewSelectionsSelector = createSelector(
  [treeviewSelectionSelector],
  ({ selectionPerItem = empty.object }) => selectionPerItem
);

const treeviewBasesetsSelector = createSelector(
  [treeviewSelectionSelector],
  ({ basesetPerItem = empty.object }) => basesetPerItem
);

const treeviewBasesetListSelector = createSelector(
  [treeviewBasesetsSelector],
  flow(
    values,
    flatten,
    compact,
    uniqBy("itemId"),
    sortBy(["label"]),
    map(({ itemId, label, type }) => ({ id: itemId, value: label, type }))
  )
);

const treeviewEntiteitenSelector = createSelector(
  [treeviewSelectionSelector],
  ({ entiteitPerItem = empty.object }) => entiteitPerItem
);

const treeviewEntiteitListSelector = createSelector(
  [treeviewEntiteitenSelector],
  flow(
    values,
    flatten,
    compact,
    uniqBy("itemId"),
    sortBy(["label"]),
    map(({ itemId, label, type }) => ({ id: itemId, value: label, type }))
  )
);

const treeviewKerngegevensSelector = createSelector(
  [treeviewSelectionSelector],
  ({ kerngegevenPerItem = empty.object }) => kerngegevenPerItem
);

const treeviewKerngegevenListSelector = createSelector(
  [treeviewKerngegevensSelector],
  (kerngegevenPerItem) =>
    flow(
      map((k) => kerngegevenPerItem[k]),
      flatten,
      compact,
      uniqBy("pageClusterId"),
      sortBy(["pageClusterLabel"]),
      map(({ itemId, label, type, pageClusterId, pageClusterLabel }) => ({
        id: pageClusterId,
        value: `${pageClusterLabel} (${label})`,
        itemId,
        type,
      }))
    )(Object.keys(kerngegevenPerItem))
);

const treeviewKernveldenSelector = createSelector(
  [treeviewSelectionSelector],
  ({ kernveldPerItem = empty.object }) => kernveldPerItem
);

const treeviewKernveldListSelector = createSelector(
  [treeviewKernveldenSelector],
  (kernveldPerItem) =>
    flow(
      map((k) => kernveldPerItem[k]),
      flatten,
      compact,
      uniqBy("pageClusterId"),
      sortBy(["pageClusterLabel"]),
      map(({ itemId, label, type, pageClusterId, pageClusterLabel }) => ({
        id: pageClusterId,
        value: `${pageClusterLabel} (${label})`,
        itemId,
        type,
      }))
    )(Object.keys(kernveldPerItem))
);

const selectionListsSelector = ({
  data: {
    selectionLists: { data = empty.array } = empty.object,
  } = empty.object,
}) => data;

const flattenChildren = ({ itemId, children }) =>
  flown(children, map(flattenChildren), flatten, concat(itemId));

const treeviewItemIds = createSelector(
  [treeviewSelectionSelector],
  ({ roots }) => flown(roots, map(flattenChildren), flatten)
);

const mapState = createSelector(
  [
    treeviewSelectionsSelector,
    treeviewBasesetsSelector,
    treeviewBasesetListSelector,
    treeviewEntiteitenSelector,
    treeviewEntiteitListSelector,
    treeviewKerngegevensSelector,
    treeviewKerngegevenListSelector,
    treeviewKernveldenSelector,
    treeviewKernveldListSelector,
    selectionListsSelector,
    treeviewItemIds,
  ],
  (
    selections,
    basesets,
    basesetList,
    entiteiten,
    entiteitList,
    kerngegevens,
    kerngegevenList,
    kernvelden,
    kernveldList,
    selectionLists,
    itemIds
  ) => {
    const facets = flown(selections, values, flatten, compact, uniq);
    const selectionItemsPerList = flown(
      selectionLists,
      map(flatItems),
      zip(selectionLists),
      map(([list, items]) => ({ ...list, items }))
    );
    const missingLists = flown(
      selections,
      mapValues((selItmIdts) =>
        flown(
          selectionItemsPerList,
          reject(
            flow(
              get("items"),
              intersectionWith(
                (selItmIdt, { id }) => selItmIdt === id,
                selItmIdts
              ),
              some(Boolean)
            )
          ),
          map("id")
        )
      )
    );
    const lists = flown(
      selectionItemsPerList,
      filter(
        flow(
          get("items"),
          intersectionWith((facet, { id }) => facet === id, facets),
          some(Boolean)
        )
      ),
      map(({ items = empty.array, ...rest }) => {
        const hasEmpty = flown(missingLists, values, some(includes(rest.id)));
        return {
          items: flown(
            facets,
            intersectionWith(({ id }, facet) => id === facet, items),
            push(
              hasEmpty
                ? [{ id: 0, listId: rest.id, value: "Niet gevuld" }]
                : empty.array
            )
          ),
          ...rest,
        };
      })
    );
    return {
      treeviewFilters: {
        selections,
        missingLists: mapValues(intersection(map("id")(lists)))(missingLists),
        basesets,
        basesetList,
        entiteiten,
        entiteitList,
        kerngegevens,
        kerngegevenList,
        kernvelden,
        kernveldList,
        facets,
        itemIds,
        lists,
      },
    };
  }
);

const mapDispatch = (dispatch, { filter = empty.array, updateFilter }) => ({
  addFilter: (...args) => {
    const values = flown(
      args,
      chunk(2),
      map(([id, type]) => `${type}:${id}`)
    );
    updateFilter([...filter, ...values]);
  },
  removeFilter: (id, type) =>
    updateFilter(filter.filter((i) => i !== `${type}:${id}`)),
  removeAllFilters: () => updateFilter(empty.array),
});

const setActive = (selectedItemIds) => (item) => {
  const children = flown(item.children, map(setActive(selectedItemIds)));
  const hasActive = flown(
    children,
    some(({ hasActive, isActive }) => hasActive || isActive)
  );
  const isActive = selectedItemIds[item.itemId];
  return { ...item, children, hasActive, isActive };
};

const enhanceTreeview = compose(
  setDisplayName("enhanceTreeview"),
  withState("filter", "updateFilter", empty.array),
  connect(mapState),
  connect(() => empty.object, mapDispatch),
  mapProps(
    ({
      treeviewFilters,
      treeviewFilters: {
        selections = empty.object,
        missingLists = empty.object,
        basesets = empty.object,
        entiteiten = empty.object,
        kerngegevens = empty.object,
        kernvelden = empty.object,
        itemIds = empty.array,
      },
      filter = empty.array,
      addFilter,
      removeFilter,
      removeAllFilters,
      tree,
      ...rest
    }) => {
      const { selectionLists } = rest;
      const filterSets = groupFilters(filter, selectionLists);
      const selectedItemIds = flown(
        itemIds,
        map((itemId) => {
          const values = flown(
            [
              filterValues("sel")(selections[itemId]),
              filterValues("lst")(missingLists[itemId]),
              filterValues("bas", "itemId")(basesets[itemId]),
              filterValues("ent", "itemId")(entiteiten[itemId]),
              filterValues("ggv", "pageClusterId")(kerngegevens[itemId]),
              filterValues("vld", "pageClusterId")(kernvelden[itemId]),
            ],
            flatten,
            toSet
          );
          return {
            itemId,
            selected: filterSets.every((g) =>
              g.some((value) => values.has(value))
            ),
          };
        }),
        keyBy("itemId"),
        mapValues("selected")
      );
      return {
        treeviewFilters: {
          ...treeviewFilters,
          filter,
          addFilter,
          removeFilter,
          removeAllFilters,
          selectedItemIds,
        },
        tree: structuredMap({ roots: map(setActive(selectedItemIds)) })(tree),
        ...rest,
      };
    }
  )
);

export default enhanceTreeview;
