import { ListItem } from "material-ui";

import PagetypeIcon from "../pagetypeIcon";
import { ItemLink } from "../relation.itemlink";
import { ClusterRelationViewProps } from "./models";

const ClusterRelationView = ({
  item,
  side,
  relation,
  types,
  isMissing,
  close,
  ...listItemProps
}: ClusterRelationViewProps) => {
  const type = types.find((t) => t.id === relation.relationTypeId);
  let otherSideClusterLabel = item.pageClusterLabel;
  if (isMissing && otherSideClusterLabel) {
    otherSideClusterLabel += " - Niet overgenomen";
  }

  const primaryText = otherSideClusterLabel
    ? `${otherSideClusterLabel} (${item.label})`
    : item.label;

  return (
    <ListItem
      key={item.itemId}
      {...listItemProps}
      primaryText={
        isMissing ? (
          <ItemLink item={item} onClick={close}>
            {primaryText}
          </ItemLink>
        ) : (
          primaryText
        )
      }
      secondaryText={(side === 1 ? type?.left : type?.right)?.label}
      className="bieb-use-wizard-field relation-in-tree"
      leftIcon={
        <PagetypeIcon
          type={item.type}
          simpleIcon
          style={{
            top: "calc(50% - 12px)",
            margin: "0 0 0 48px",
          }}
        />
      }
      leftCheckbox={<></> /* Nodig om zelfde styling te krijgen */}
    />
  );
};

export default ClusterRelationView;
