import {
  defaultTo,
  entries,
  filter,
  flatten,
  flow,
  fromPairs,
  groupBy,
  has,
  head,
  keys,
  map,
} from "lodash/fp";
import { Step, StepButton, StepContent, Stepper, Toggle } from "material-ui";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";

import {
  KoppelingClusterMapping,
  Relation,
  StoreRoot,
  emptyArray,
  emptyObject,
} from "../../business/models";
import { flown } from "../../lodash";
import { isNonEmptyArray } from "../../utils";
import zipOuterBy from "../../zipOuterBy";
import { groupedRelationComparer } from "./BiebUseWizard";
import CompareClusterTree from "./CompareClusterTree";
import CompareRelations from "./CompareRelations";
import CompareStaticFields from "./CompareStaticFields";
import { BiebCompareAndSelectWizardProps, GroupedRelation } from "./models";
import TriggerResize from "./TriggerResize";
import {
  CollectedCluster,
  collect,
  flattenClusterTree,
  pageFields,
  relate,
} from "./utils";
import WarnAboutMissingApps from "./WarnAboutMissingApps";

const groupRelations = (relations: Relation[]) =>
  flow(
    groupBy(
      (r: Relation) =>
        (r.side === 3 ? r.using[0] : r.side === 2 ? r.left : r.right).itemId
    ),
    entries,
    defaultTo(emptyArray())
  )(relations).sort(groupedRelationComparer);

const BiebCompareAndSelectWizard = ({
  biebPage,
  localPage,
  biebStepper: { activeStep, setActiveStep },
  pageActions,
  biebRelations,
  localRelations,
  relationTypes,
  biebStructures,
  biebRelatedPagetypeMapping,
  isIznet,
  close,
  staged,
}: BiebCompareAndSelectWizardProps) => {
  const [showAllFields, setShowAllFields] = useState(false);

  const biebFields = pageFields(biebPage);
  const localFields = pageFields(localPage, true);
  const zip = useMemo(
    () =>
      zipOuterBy(
        biebFields,
        localFields,
        (item) => item.path,
        (path) => path.join("."),
        (item) => ({ ...item.content, clusterId: item.clusterId })
      ).filter(
        ([_path, biebField, localField]) =>
          localField &&
          biebField &&
          (!Boolean(biebField?.isEmpty) || !Boolean(localField?.isEmpty))
      ),
    [biebFields, localFields]
  );

  const relationTypesTypes = relationTypes.types ?? emptyArray();
  const typeIds = useMemo(
    () =>
      relationTypesTypes
        .filter(
          (t) =>
            (biebPage?.page.pagetype === "koppeling" &&
            (t.alias === "applicatie_applicatie" ||
              t.alias === "veld_koppeling_veld")
              ? true
              : !t.using) &&
            !t.pagetype &&
            !t.metaPagetype &&
            !t.alias.startsWith("bieb_") &&
            !t.alias.startsWith("relatie_legoblok_") &&
            !t.alias.startsWith("legoblok_") &&
            !t.alias.endsWith("_bieb") &&
            !t.alias.endsWith("_legoblok")
        )
        .map((t) => t.id),
    [biebPage?.page.pagetype, relationTypesTypes]
  );
  const clusterTypes = useMemo(
    () =>
      relationTypesTypes.filter(
        (t) =>
          t.needsCluster &&
          !t.alias.startsWith("bieb_") &&
          !t.alias.startsWith("relatie_legoblok_") &&
          !t.alias.startsWith("legoblok_") &&
          !t.alias.endsWith("_bieb") &&
          !t.alias.endsWith("_legoblok")
      ),
    [relationTypesTypes]
  );
  const useType = useMemo(
    () =>
      relationTypesTypes.find(
        (t) => t.alias === `bieb_${biebPage.page["pagetype"]}`
      ),
    [biebPage.page, relationTypesTypes]
  );

  /* Koppeling: find relationtype */
  const koppelingApplicationType = useMemo(
    () => relationTypesTypes.find((t) => t.alias === "applicatie_applicatie"),
    [relationTypesTypes]
  );

  const koppelingVeldType = useMemo(
    () => relationTypesTypes.find((t) => t.alias === "veld_koppeling_veld"),
    [relationTypesTypes]
  );

  const biebRelationsRelations = biebRelations.relations ?? emptyArray();
  const biebStructureIds = biebStructures.map(({ id }) => id);
  const relationsBieb = useMemo(() => {
    return biebRelationsRelations.filter((relation) => {
      if (!typeIds.includes(relation.relationTypeId)) return false;
      if (
        biebPage.page.pagetype === "koppeling" &&
        (relation.relationTypeId === koppelingApplicationType?.id ||
          relation.relationTypeId === koppelingVeldType?.id)
      ) {
        return (
          biebStructureIds.includes(relation.left.structureId) &&
          biebStructureIds.includes(relation.right.structureId)
        );
      }

      const other = relation.side === 2 ? relation.left : relation.right;
      return biebStructureIds.includes(other.structureId);
    });
  }, [
    biebRelationsRelations,
    typeIds,
    biebPage.page.pagetype,
    koppelingApplicationType?.id,
    koppelingVeldType?.id,
    biebStructureIds,
  ]);

  const groupedBieb: GroupedRelation[] = useMemo(
    () => groupRelations(relationsBieb),
    [relationsBieb]
  );

  const clusterBiebRelations = useMemo(() => {
    return biebRelationsRelations.filter(
      (r) =>
        clusterTypes.map((t) => t.id).includes(r.relationTypeId) &&
        (r.side === 3 ? r.using[0] : r.side === 1 ? r.left : r.right)
          .pageClusterId !== undefined &&
        (r.side === 3 || !isNonEmptyArray(r.using))
    );
  }, [biebRelationsRelations, clusterTypes]);

  const useRelations = useMemo(() => {
    return biebRelationsRelations
      .filter(
        (r) =>
          r.relationTypeId === useType?.id &&
          r.left.itemId === biebPage.id &&
          r.right.itemId === localPage.id &&
          isNonEmptyArray(r.clusters)
      )
      .flatMap((r) =>
        r.clusters!.map(({ van, nar }) => ({
          ...r,
          left: { ...r.left, pageClusterId: van },
          right: { ...r.right, pageClusterId: nar },
        }))
      );
  }, [biebPage.id, biebRelationsRelations, localPage.id, useType]);

  // Data for repetitive cluster-comparer
  const biebTree = useMemo(
    () =>
      flow(
        entries,
        map(([_, content]) =>
          collect(
            content,
            biebPage.page["pagetype"] as unknown as string,
            false,
            clusterBiebRelations,
            "",
            undefined
          )
        ),
        flatten
      )(biebPage.page),
    [biebPage.page, clusterBiebRelations]
  );

  const localRelationsRelations = localRelations.relations ?? emptyArray();
  const relationsLocal = useMemo(() => {
    return localRelationsRelations.filter((relation) => {
      if (!typeIds.includes(relation.relationTypeId)) return false;
      if (
        biebPage.page.pagetype === "koppeling" &&
        (relation.relationTypeId === koppelingApplicationType?.id ||
          relation.relationTypeId === koppelingVeldType?.id)
      ) {
        return (
          biebStructureIds.includes(relation.left.structureId) &&
          biebStructureIds.includes(relation.right.structureId)
        );
      }

      const other = relation.side === 2 ? relation.left : relation.right;
      return !biebStructureIds.includes(other.structureId);
    });
  }, [
    localRelationsRelations,
    typeIds,
    biebPage.page.pagetype,
    koppelingApplicationType?.id,
    koppelingVeldType?.id,
    biebStructureIds,
  ]);

  const groupedLocal: GroupedRelation[] = useMemo(
    () => groupRelations(relationsLocal),
    [relationsLocal]
  );

  const clusterLocalRelations = useMemo(() => {
    return localRelationsRelations.filter(
      (r) =>
        clusterTypes.map((t) => t.id).includes(r.relationTypeId) &&
        (r.side === 3 ? r.using[0] : r.side === 1 ? r.left : r.right)
          .pageClusterId !== undefined &&
        (r.side === 3 || !isNonEmptyArray(r.using))
    );
  }, [localRelationsRelations, clusterTypes]);

  const localTree = useMemo(
    () =>
      flow(
        entries,
        map(([_, content]) =>
          collect(
            content,
            localPage.page["pagetype"] as unknown as string,
            false,
            clusterLocalRelations,
            "",
            undefined
          )
        ),
        flatten
      )(localPage.page),
    [clusterLocalRelations, localPage.page]
  );

  const flattenedLocal = useMemo(
    () => flattenClusterTree(localTree),
    [localTree]
  );

  const relatedItems = useMemo(
    () =>
      flow(
        map((biebCluster: CollectedCluster) =>
          relate(biebCluster, flattenedLocal, useRelations)
        ),
        flatten
      )(biebTree),
    [biebTree, flattenedLocal, useRelations]
  );

  const selectedBiebFieldIds: number[] = useSelector((store: StoreRoot) =>
    localPage?.id && store.form.page[localPage.id]
      ? store.form.page[localPage.id].staged
          .filter((s) => /FieldCopyAction/.test(s.$type))
          .map((s) => s.sourceFieldId!)
      : emptyArray()
  );

  /* Koppeling: create array with related application items */
  const koppelingWithApplications = useMemo(
    () =>
      biebRelationsRelations
        .filter((relation) =>
          !koppelingApplicationType ||
          koppelingApplicationType.id !== relation.relationTypeId
            ? false
            : biebStructureIds.includes(relation.left.structureId) &&
              biebStructureIds.includes(relation.right.structureId)
        )
        .flatMap((relation) => [relation.left, relation.right]),
    [biebRelationsRelations, biebStructureIds, koppelingApplicationType]
  );

  /* Koppeling: check if the koppeling can be used */
  const koppelingCanBeUsed = useMemo(() => {
    if (biebPage?.page.pagetype !== "koppeling") {
      return false;
    }

    if (!koppelingWithApplications || koppelingWithApplications.length !== 2) {
      return false;
    }

    if (!biebRelatedPagetypeMapping) {
      return false;
    }

    return (
      flown(
        biebRelatedPagetypeMapping[koppelingWithApplications[0].itemId],
        defaultTo(emptyObject),
        keys
      ).length > 0 &&
      flown(
        biebRelatedPagetypeMapping[koppelingWithApplications[1].itemId],
        defaultTo(emptyObject),
        keys
      ).length > 0
    );
  }, [
    biebPage?.page.pagetype,
    biebRelatedPagetypeMapping,
    koppelingWithApplications,
  ]);

  const koppelingVeldRelations = useMemo(() => {
    return biebRelationsRelations.filter(
      (r) => r.relationTypeId === koppelingVeldType?.id
    );
  }, [biebRelationsRelations, koppelingVeldType?.id]);

  const koppelingClusterMapping = useMemo<KoppelingClusterMapping>(() => {
    if (!koppelingCanBeUsed || !biebRelatedPagetypeMapping) return undefined;

    const leftMapping =
      biebRelatedPagetypeMapping[koppelingWithApplications[0].itemId];
    const firstLeftLocalItemId = flown(leftMapping, keys, head);

    const rightMapping =
      biebRelatedPagetypeMapping[koppelingWithApplications[1].itemId];
    const firstRightLocalItemId = flown(rightMapping, keys, head);

    return flown(
      koppelingVeldRelations,
      defaultTo(emptyArray()),
      filter((r: Relation) => has("using[0].pageClusterId")(r)),
      map((r: Relation) => {
        const leftLocalClusterId = (
          leftMapping?.[firstLeftLocalItemId] ?? emptyArray()
        ).find((m) => m.vanPagClsIdt === r.left.pageClusterId)?.narPagClsIdt;
        const rightLocalClusterId = (
          rightMapping?.[firstRightLocalItemId] ?? emptyArray()
        ).find((m) => m.vanPagClsIdt === r.right.pageClusterId)?.narPagClsIdt;

        return [
          r.using[0].pageClusterId,
          [leftLocalClusterId, rightLocalClusterId],
        ];
      }),
      fromPairs
    );
  }, [
    biebRelatedPagetypeMapping,
    koppelingCanBeUsed,
    koppelingVeldRelations,
    koppelingWithApplications,
  ]);

  return (
    <div>
      <div className="wizardbar-sticky">
        <Toggle
          label="Toon ongewijzigde velden"
          toggled={showAllFields}
          onToggle={(_, isInputChecked) => setShowAllFields(isInputChecked)}
          style={{ width: "auto" }}
        />
      </div>

      <WarnAboutMissingApps
        biebPage={biebPage}
        koppelingCanBeUsed={koppelingCanBeUsed}
        koppelingWithApplications={koppelingWithApplications}
        biebPagetypeMapping={biebRelatedPagetypeMapping}
        close={close}
      />

      <Stepper activeStep={activeStep} orientation="vertical">
        <Step key={0}>
          <StepButton onClick={() => setActiveStep(0)}>
            <span>Welke inhoud</span>
          </StepButton>
          <StepContent>
            <CompareStaticFields
              biebItemId={biebPage.id}
              localItemId={localPage.id}
              zip={zip}
              pageActions={pageActions}
              showAllFields={showAllFields}
              showEmptyMessage
              selected={selectedBiebFieldIds}
              withCheckAll
            />

            <TriggerResize />
          </StepContent>
        </Step>
        <Step key={1}>
          <StepButton onClick={() => setActiveStep(1)}>
            <span>Welke repeterende clusters</span>
          </StepButton>
          <StepContent>
            <CompareClusterTree
              biebItemId={biebPage.id}
              localItemId={localPage.id}
              biebTree={biebTree}
              localTree={localTree}
              relatedItems={relatedItems}
              pageActions={pageActions}
              showAllFields={showAllFields}
              types={clusterTypes}
              selected={selectedBiebFieldIds}
              isKoppeling={biebPage.page.pagetype === "koppeling"}
              koppelingClusterMapping={koppelingClusterMapping}
              staged={staged}
            />

            <TriggerResize />
          </StepContent>
        </Step>
        <Step key={2}>
          <StepButton onClick={() => setActiveStep(2)}>
            <span>Welke relaties</span>
          </StepButton>
          <StepContent>
            <CompareRelations
              localItemId={localPage.id}
              biebItemId={biebPage.id}
              groupedBieb={groupedBieb}
              groupedLocal={groupedLocal}
              relationTypes={relationTypesTypes}
              biebRelatedPagetypeMapping={biebRelatedPagetypeMapping}
              biebStructures={biebStructures}
              isIznet={isIznet}
              pageActions={pageActions}
              pagetype={localPage.page.pagetype}
              koppelingCanBeUsed={koppelingCanBeUsed}
              koppelingApplicationType={koppelingApplicationType}
            />
          </StepContent>
        </Step>
      </Stepper>
    </div>
  );
};

export default BiebCompareAndSelectWizard;
