import { map } from "lodash/fp";

const relationsActionGroups = [
  "RELATIONS",
  "TREE",
  "LANDSCAPE",
  "VERSIONS",
  "RAAMWERK",
];

export const invalidateRelations = (id) => (dispatch) => {
  const actions = map((group) => ({
    type: `${group}_INVALIDATE`,
    payload: { id },
  }))(relationsActionGroups);
  for (const action of actions) {
    dispatch(action);
  }
};
