import IconButton from "material-ui/IconButton";
import Paper from "material-ui/Paper";
import ActionDelete from "material-ui/svg-icons/action/delete";
import ArrowForward from "material-ui/svg-icons/navigation/arrow-forward";
import PropTypes from "prop-types";
import React from "react";

import { koppelingRowShape } from "../../../business/prop-types";

const KoppelingSelection = ({ selected, onDelete }) => (
  <Paper>
    {selected.map(({ left, right }, i) => (
      <div key={i} className="koppeling-row">
        <div className="koppeling-left">{left.naam}</div>
        <ArrowForward
          style={{
            alignSelf: "center",
            flex: "0 0 auto",
            width: 16,
            height: 16,
            margin: 8,
          }}
        />
        <div className="koppeling-right">
          {right && (
            <div
              style={{
                float: "right",
                margin: "-12px 0",
              }}
            >
              <IconButton onClick={() => onDelete(i)}>
                <ActionDelete />
              </IconButton>
            </div>
          )}
          {right ? right.naam : <em>Kies een veld</em>}
        </div>
      </div>
    ))}
  </Paper>
);

KoppelingSelection.propTypes = Object.freeze({
  selected: PropTypes.arrayOf(koppelingRowShape.isRequired).isRequired,
  onDelete: PropTypes.func.isRequired,
});

export default KoppelingSelection;
