import empty from "empty";
import { keys } from "lodash/fp";
import FlatButton from "material-ui/FlatButton";
import { Step, StepButton, StepContent, Stepper } from "material-ui/Stepper";
import PropTypes from "prop-types";
import React from "react";

import { selectionListShape } from "../../business/prop-types";
import Dialog from "../../containers/dialog";
import ItemDialogFirstStep from "./itemDialogFirstStep";
import KoppelingForm from "./koppelingForm";

const itemForm = {
  koppeling: KoppelingForm,
};

const ItemDialog = ({
  closeDialog,
  newItem: {
    valid = false,
    validExtras = false,
    validFields = false,
  } = empty.object,
  newItem,
  open,
  pagetypes = empty.array,
  saveItem,
  updateItem,
  updateLabel,
  updatePagetype,
  updateStructureId,
  pagetypeDisabled = false,
  disabled = false,
  title = "Nieuw item toevoegen",
  foundItems = empty.array,
  stepIndex,
  updateStep,
  previousStep,
  nextStep,
  structures,
  legoblokStructures,
  isBiebStructure,
  selectionLists,
  updateExternCheckbox,
  fields,
  loadingFields,
  fieldAdd,
  fieldRevert,
}) => {
  const hasFields = keys(fields).length > 0;
  const hasExtraSteps =
    newItem && "pagetype" in newItem && newItem.pagetype in itemForm;
  const getExtraSteps = hasExtraSteps ? itemForm[newItem.pagetype] : undefined;

  const steps =
    hasExtraSteps && getExtraSteps
      ? getExtraSteps({
          newItem,
          updateItem,
          valid,
          updateStep,
          stepIndex,
          previousStep,
          nextStep,
          isBiebStructure,
          legoblokStructures,
          selectionLists,
        })
      : empty.array;

  return (
    <Dialog
      title={title}
      open={open}
      autoScrollBodyContent
      actions={[
        <FlatButton
          key="close"
          secondary
          label="Annuleren"
          onClick={closeDialog}
        />,
        hasExtraSteps && (
          <FlatButton
            label="Vorige"
            key="previous"
            disabled={stepIndex === 0}
            onClick={previousStep}
          />
        ),
        hasExtraSteps && stepIndex < steps.length ? (
          <FlatButton
            label="Volgende"
            key="next"
            disabled={!valid}
            primary
            onClick={nextStep}
          />
        ) : (
          <FlatButton
            label="Opslaan"
            key="next"
            disabled={
              !valid ||
              (hasExtraSteps && !validExtras) ||
              (hasFields && !validFields) ||
              (hasFields && loadingFields) ||
              (!isBiebStructure && newItem.pagetype === "legoblok") ||
              disabled
            }
            primary
            onClick={() => saveItem(newItem)}
          />
        ),
      ]}
    >
      {!hasExtraSteps && (
        <ItemDialogFirstStep
          newItem={newItem}
          pagetypes={pagetypes}
          updateLabel={updateLabel}
          updatePagetype={updatePagetype}
          updateStructureId={updateStructureId}
          pagetypeDisabled={pagetypeDisabled}
          foundItems={foundItems}
          structures={structures}
          legoblokStructures={legoblokStructures}
          closeDialog={closeDialog}
          updateExternCheckbox={updateExternCheckbox}
          hasFields={hasFields}
          fields={fields}
          loadingFields={loadingFields}
          fieldAdd={fieldAdd}
          fieldRevert={fieldRevert}
        />
      )}
      {hasExtraSteps && (
        <Stepper activeStep={stepIndex} orientation="vertical">
          <Step>
            <StepButton onClick={() => updateStep(0)}>
              <span>
                {newItem.label && newItem.pagetype && stepIndex > 0
                  ? `${newItem.label} (${newItem.pagetype}) toevoegen`
                  : title || "Nieuw item toevoegen"}
              </span>
            </StepButton>
            <StepContent>
              <ItemDialogFirstStep
                newItem={newItem}
                pagetypes={pagetypes}
                updateLabel={updateLabel}
                updatePagetype={updatePagetype}
                pagetypeDisabled={pagetypeDisabled}
                updateStructureId={updateStructureId}
                foundItems={foundItems}
                structures={structures}
                legoblokStructures={legoblokStructures}
                closeDialog={closeDialog}
                updateExternCheckbox={updateExternCheckbox}
              />
            </StepContent>
          </Step>
          {steps}
        </Stepper>
      )}
    </Dialog>
  );
};
ItemDialog.propTypes = {
  closeDialog: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  newItem: PropTypes.shape(
    Object.freeze({
      extraAction: PropTypes.object,
      label: PropTypes.string,
      pagetype: PropTypes.string,
      side: PropTypes.number,
      structureId: PropTypes.number,
      valid: PropTypes.bool,
      validExtras: PropTypes.bool,
      validFields: PropTypes.bool,
    })
  ).isRequired,
  open: PropTypes.bool.isRequired,
  pagetypes: PropTypes.arrayOf(
    PropTypes.shape(
      Object.freeze({
        id: PropTypes.number.isRequired,
        alias: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      })
    )
  ).isRequired,
  saveItem: PropTypes.func.isRequired,
  updateItem: PropTypes.func.isRequired,
  updateLabel: PropTypes.func.isRequired,
  updatePagetype: PropTypes.func.isRequired,
  updateStructureId: PropTypes.func.isRequired,
  updateExternCheckbox: PropTypes.func.isRequired,
  pagetypeDisabled: PropTypes.bool,
  disabled: PropTypes.bool,
  title: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  foundItems: PropTypes.array,
  stepIndex: PropTypes.number.isRequired,
  updateStep: PropTypes.func.isRequired,
  previousStep: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  isBiebStructure: PropTypes.bool,
  structures: PropTypes.arrayOf(
    PropTypes.shape(
      Object.freeze({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      })
    )
  ).isRequired,
  legoblokStructures: PropTypes.arrayOf(
    PropTypes.shape(
      Object.freeze({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      })
    )
  ).isRequired,
  selectionLists: PropTypes.arrayOf(selectionListShape).isRequired,
  fields: PropTypes.shape({}),
  loadingFields: PropTypes.bool,
  fieldAdd: PropTypes.func,
  fieldRevert: PropTypes.func,
};

export default ItemDialog;
