import FloatingActionButton from "material-ui/FloatingActionButton";
import ContentAdd from "material-ui/svg-icons/content/add";
import PropTypes from "prop-types";
import React from "react";

import { selectionListShape, structureShape } from "../../business/prop-types";
import ItemDialog from "../itemDialog";
import { defaultValues } from "../redux";

const AddItem = ({
  updateShowDialog,
  pagetypes,
  addItem,
  form: { showDialog, sent },
  initialPagetype,
  disabled = false,
  find,
  reset,
  foundItems,
  structures,
  legoblokStructures,
  selectionLists,
  requireDefinition,
  fieldDefinitionsByPagetype,
}) => (
  <div>
    <FloatingActionButton
      title="Nieuw item toevoegen"
      style={{
        position: "fixed",
        right: "1rem",
        bottom: "1rem",
        zIndex: "999",
      }}
      secondary
      onClick={() => updateShowDialog(!showDialog, defaultValues)}
      disabled={disabled}
    >
      <ContentAdd />
    </FloatingActionButton>

    <ItemDialog
      closeDialog={() => updateShowDialog(false)}
      open={showDialog && pagetypes && pagetypes.length > 0}
      disabled={sent}
      pagetypes={pagetypes}
      saveItem={addItem}
      initialPagetype={initialPagetype}
      find={find}
      reset={reset}
      foundItems={foundItems}
      structures={structures}
      legoblokStructures={legoblokStructures}
      selectionLists={selectionLists}
      requireDefinition={requireDefinition}
      fieldDefinitionsByPagetype={fieldDefinitionsByPagetype}
    />
  </div>
);
AddItem.propTypes = {
  updateShowDialog: PropTypes.func.isRequired,
  pagetypes: PropTypes.arrayOf(
    PropTypes.shape(
      Object.freeze({
        alias: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      })
    ).isRequired
  ).isRequired,
  addItem: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  form: PropTypes.shape(
    Object.freeze({
      showDialog: PropTypes.bool.isRequired,
      sent: PropTypes.bool.isRequired,
    })
  ).isRequired,
  initialPagetype: PropTypes.string,
  disabled: PropTypes.bool,
  find: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  foundItems: PropTypes.array.isRequired,
  structures: PropTypes.arrayOf(structureShape.isRequired).isRequired,
  legoblokStructures: PropTypes.arrayOf(structureShape.isRequired),
  selectionLists: PropTypes.arrayOf(selectionListShape).isRequired,
  requireDefinition: PropTypes.func.isRequired,
  fieldDefinitionsByPagetype: PropTypes.shape({}),
};

export default AddItem;
