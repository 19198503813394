import { loadingPresent } from "async-lifecycle";
import { joinLoading } from "async-lifecycle/volatile";
import empty from "empty";
import { filter, has, map, reduce, reject, some } from "lodash/fp";
import { compose, setDisplayName } from "recompose";
import { createSelector } from "reselect";

import {
  addGegevenAction,
  initGegevenDialog,
  saveGegevensAction,
} from "../../../actions/application";
import GegevenDialogComponent from "../../../components/pagetypes/application/gegevenDialog";
import AddGegevenComponent from "../../../components/pagetypes/shared/addGegeven";
import { flown } from "../../../lodash";
import connect from "../../connect";

export const AddGegeven = compose(
  setDisplayName("AddGegeven"),
  connect(
    (
      {
        form: {
          applicationGegevenDialog: { open = false } = empty.object,
        } = empty.object,
      },
      { pageActions: { dirty = false } = empty.object }
    ) => ({ enabled: !open && !dirty }),
    (dispatch) => ({
      openDialog: () => dispatch(addGegevenAction(true)),
      closeDialog: () => dispatch(addGegevenAction(false)),
    })
  )
)(AddGegevenComponent);

const gegevenDialogSelector = createSelector(
  [
    ({ data: { entiteiten } }) => entiteiten,
    ({ data: { addLegoblok } }) => addLegoblok,
    ({ data: { pages } }, { pageActions: { pageId } = empty.object }) =>
      pages[pageId] || empty.object,
    ({ form: { applicationGegevenDialog: { open = false } = empty.object } }) =>
      open,
  ],
  (entiteiten, addLegoblok, page, open) => {
    const loading = flown(
      [entiteiten, addLegoblok, page],
      map("loading"),
      reduce(joinLoading, "success")
    );
    if (!loadingPresent(loading)) {
      return empty.object;
    }

    const bieb = flown(
      addLegoblok.structures,
      some(({ id }) => id === page.siteId)
    );
    const list = (
      bieb
        ? filter({
            bieb: page.siteId,
          })
        : reject(has("bieb"))
    )(entiteiten.entiteiten);
    return {
      entiteiten: list,
      loading: !loadingPresent(loading),
      open,
    };
  }
);

export const GegevenDialog = compose(
  setDisplayName("GegevenDialog"),
  connect(
    gegevenDialogSelector,
    (dispatch, { pageActions: { pageId } = empty.object }) => ({
      init: () => dispatch(initGegevenDialog()),
      closeDialog: () => dispatch(addGegevenAction(false)),
      saveGegevens: (gegeven) =>
        dispatch(saveGegevensAction({ ...gegeven, pageId })),
    })
  )
)(GegevenDialogComponent);
