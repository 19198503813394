import { Editor } from "@tinymce/tinymce-react";
import empty from "empty";
import { memoize } from "lodash/fp";
import PropTypes from "prop-types";
import React from "react";
import {
  compose,
  defaultProps,
  setDisplayName,
  setPropTypes,
  withHandlers,
  withProps,
} from "recompose";
import { createSelector } from "reselect";

import { getConfig } from "../../business/tinymce";
import { iproxUrl } from "../../config";
import { isEmpty, isNonEmptyString } from "../../utils";
import SafeInnerHtml, { resolveImages } from "../safeInnerHtml";
import { propTypes, withDefaultPageActions } from "./ggvtyp";

/**
 * Gegevenstype 'Html' (5)
 */
const Html = ({
  value,
  field: {
    value: originalValue,
    definition: { explanation } = empty.object,
  } = empty.object,
  className,
  pageActions: { edit, version },
  fieldDefinition,
  config,
  handleChange,
}) =>
  edit && fieldDefinition ? (
    [
      explanation && (
        <div className="explanation" key="explanation">
          {explanation}
        </div>
      ),
      <Editor
        key={version}
        initialValue={
          isNonEmptyString(originalValue)
            ? resolveImages(originalValue, `${iproxUrl}/publish/`)
            : ""
        }
        inline
        init={config}
        onChange={handleChange}
      />,
    ]
  ) : isEmpty(value) ? null : (
    <SafeInnerHtml className={className}>{value}</SafeInnerHtml>
  );

const createFieldDefinitionSelector = memoize(
  (fieldDefinition = empty.object) =>
    createSelector(
      [
        ({ pageActions: { pageId } = empty.object }) => pageId,
        ({ uploadImage = empty.func }) => uploadImage,
      ],
      (pageId, uploadImage) => ({
        ...fieldDefinition,
        images_upload_handler: (...args) => uploadImage(pageId, ...args),
      })
    )
);

const enhance = compose(
  setDisplayName("Html"),
  setPropTypes({
    ...propTypes,
    field: PropTypes.shape({
      value: PropTypes.string,
    }).isRequired,
  }),
  defaultProps({
    className: "html",
  }),
  withDefaultPageActions(),
  withProps((props) => {
    const fieldDefinition = createFieldDefinitionSelector(
      props.fieldDefinition
    )(props);
    return {
      fieldDefinition,
      config: getConfig(fieldDefinition),
    };
  }),
  withHandlers({
    handleChange:
      ({ setValue }) =>
      ({ target }) =>
        setValue({ value: `<div>${target.getContent()}</div>` }),
  })
);

export default enhance(Html);
