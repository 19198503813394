import Delete from "material-ui/svg-icons/action/delete";
import React, {
  CSSProperties,
  MouseEventHandler,
  memo,
  useCallback,
} from "react";

import { Mutations as JsMutations } from "../../business";
import { ItemLink } from "../relation.itemlink";
import { RelationItemEnriched } from "./models";

const Mutations = JsMutations as unknown as {
  created: number;
  deleted: number;
};

const wrappingStyle: CSSProperties = {
  display: "inline-flex",
  alignItems: "center",
};

const deleteStyling: CSSProperties = {
  width: 16,
  height: 16,
  opacity: 0.5,
};

const RelationSecondaryItemFromCluster = memo(
  ({
    item,
    position,
    edit,
    removeRelation,
  }: {
    item: RelationItemEnriched;
    position: number;
    edit: boolean;
    removeRelation: (
      relationId: number,
      otherItemId: number,
      usingItemId?: number
    ) => void;
  }) => {
    const handleRemove: MouseEventHandler = useCallback(
      (event) => {
        event.preventDefault();
        removeRelation(item.relationId, item.itemId);
      },
      [item, removeRelation]
    );

    return (
      <span style={wrappingStyle}>
        {position > 0 && <>,&#160;</>}
        <ItemLink item={item}>
          <ItemLabelWithMutation {...item} />
        </ItemLink>
        {edit && <Delete onClick={handleRemove} style={deleteStyling} />}
      </span>
    );
  }
);

export const ItemLabelWithMutation = ({
  label,
  mutation,
}: {
  label: string;
  mutation?: number;
}): JSX.Element =>
  mutation === undefined ? (
    <>{label}</>
  ) : (
    <span className="mutation">
      {mutation === Mutations.created ? <ins>{label}</ins> : <del>{label}</del>}
    </span>
  );

export default RelationSecondaryItemFromCluster;
