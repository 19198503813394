import empty from "empty";
import { createSelector, createStructuredSelector } from "reselect";

import { searchResultWithMiniSaarSelector } from "./list";
import { biebStructures } from "./page";
import {
  legoblokStructuresSelector,
  miniSaarSelector,
  sessionSelector,
} from ".";

const pagetypeListSelector = ({
  data: { pagetypeList: { data } = empty.object } = empty.object,
}) => data || empty.array;

const selectionListsSelector = ({
  data: { selectionLists: { data } = empty.object } = empty.object,
}) => data || empty.array;

const searchQuerySelector = createSelector(
  [
    ({
      form: {
        searchQuery: { search = empty.object } = empty.object,
      } = empty.object,
    }) => search,
    (_, { location: { query: { q } = empty.object } = empty.object }) => q,
  ],
  (search, q) => ({ trefwoord: q, ...search })
);

// eslint-disable-next-line import/prefer-default-export
export const searchSelector = createStructuredSelector({
  biebStructures,
  searchQuery: searchQuerySelector,
  selection: searchResultWithMiniSaarSelector,
  selectionLists: selectionListsSelector,
  pagetypeList: pagetypeListSelector,
  legoblokStructures: legoblokStructuresSelector,
  saar: miniSaarSelector,
  selected: ({
    form: {
      biebSelect: { selected },
    },
  }) => selected,
  session: sessionSelector,
});
