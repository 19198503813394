import SvgIcon from "material-ui/SvgIcon";
import React from "react";
import pure from "recompose/pure";

const AccountTree = (props) => (
  <SvgIcon {...props}>
    <path d="M22 11V3h-7v3H9V3H2v8h7V8h2v10h4v3h7v-8h-7v3h-2V8h2v3z" />
  </SvgIcon>
);

export default pure(AccountTree);
