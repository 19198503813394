import empty from "empty";
import SvgIcon from "material-ui/SvgIcon";
import React from "react";
import pure from "recompose/pure";

const News = (props) => {
  const { color, style: { color: styleColor = "#888" } = empty.object } = props;
  const strokeFill = {
    stroke: color || styleColor,
    strokeWidth: 2,
    strokeLinejoin: "round",
    fill: "none",
  };

  return (
    <SvgIcon {...props} width="32" height="32" viewBox="0 0 32 32">
      <g>
        <polygon
          {...strokeFill}
          points="1.066,13.33 6.4,13.33 6.4,18.66 1.066,18.66"
        />
        <polygon
          {...strokeFill}
          points="6.4,13.33 22.4,5.33 22.4,26.66 6.4,18.66"
        />
        <path
          {...strokeFill}
          d="M 4.3395894522080685 18.89982208053207 A 4.8 4.8 0 0 0 13.223588604831972 22.133333333333333"
        />
        <polygon {...strokeFill} points="26.66,16 290,16" />
        <polygon {...strokeFill} points="26.24,10.66 30.37,9.6" />
        <polygon {...strokeFill} points="26.24,21.33 30.37,22.4" />
      </g>
    </SvgIcon>
  );
};

export default pure(News);
