/* eslint  react/prop-types: off */
import classNames from "classnames";
import empty from "empty";
import {
  defaultTo,
  find,
  flatten,
  flow,
  get,
  groupBy,
  isEqual,
  map,
  pick,
  some,
  sortBy,
  uniqWith,
  values,
} from "lodash/fp";
import { Card } from "material-ui/Card";
import Divider from "material-ui/Divider";
import { List, ListItem } from "material-ui/List";
import RaisedButton from "material-ui/RaisedButton";
import OpenInNew from "material-ui/svg-icons/action/open-in-new";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { compose, pure, setDisplayName, withProps, withState } from "recompose";

import { ShowClusters, ShowSide } from "../business/models";
import { pageActionsShape } from "../business/prop-types";
import { Side, relationMultiple, shownRelations } from "../business/relations";
import { flown } from "../lodash";
import { saarHistory } from "../store";
import { isNonEmptyArray, structuredMap, takeIf } from "../utils";
import Subheader from "./material/Subheader";
import pagetypeIcons from "./pagetypes/icons";
import RelationView, { Relation } from "./relation";
import RelationClusterList from "./relations/RelationClusterList";
import RelationMetaList from "./relations/relationMetaList";
import RelationPraktijksituatieReadOnly from "./relations/relationPraktijksituatieReadOnly";
import RelationsSortDialog from "./relations/RelationsSortDialog";

const Empty = pagetypeIcons.empty;

const itemAndPageClusterId = pick(["itemId", "pageClusterId"]);

const distinctUsings = flow(
  map("using"),
  flatten,
  uniqWith((a, b) => isEqual(itemAndPageClusterId(a), itemAndPageClusterId(b))),
  sortBy("label")
);

const aggregateUsings = flow(
  groupBy(
    ({
      item: { itemId, pageClusterId = 0 } = empty.object,
      relation: { side, left, right } = empty.object,
    }) =>
      `${itemId}-${pageClusterId}-${
        (side === Side.left ? left : right).pageClusterId ?? 0
      }`
  ),
  values,
  map((g) => ({
    ...g[0],
    ids: map("id")(g),
    using: distinctUsings(g),
  }))
);

const RelationsListHeader = ({
  itemId,
  type,
  side,
  edit,
  label,
  relations = empty.array,
  sortEnabled,
  labelRender,
}) => (
  <Subheader
    secondary={type.scope > 0}
    style={{ display: "flex", padding: "12px 16px" }}
  >
    <span style={{ flexGrow: 1 }}>{label}</span>
    {edit && (
      <RelationsSortDialog
        itemId={itemId}
        type={type}
        side={side}
        relations={relations}
        enabled={sortEnabled}
        labelRender={labelRender}
      />
    )}
  </Subheader>
);

const RelationsListComponent = ({
  type = empty.object,
  side,
  itemId,
  relations,
  showDivider = false,
  showHeader = false,
  showMissing = true,
  label,
  className,
  initAddRelation,
  removeRelation,
  all,
  setAll,
  edit,
  maxLength,
  labelRender = type.labelRender,
  selectedSaar,
  legoblokStructures,
  pageActions,
  ...rest
}) => {
  const hasToggle = relations.length > maxLength;
  const curriedRemoveRelation = (id, otherItemId, usingItemId) =>
    removeRelation(type.id, itemId, id, otherItemId, usingItemId);

  if (type.metaPagetype) {
    if (
      (side === Side.right && type.rightShow === 1) ||
      (side === Side.left && type.leftShow === 1)
    ) {
      return (
        <RelationPraktijksituatieReadOnly
          all={all}
          className={className}
          label={type.displayLabel ?? label}
          maxLength={maxLength}
          relations={relations}
          setAll={setAll}
          type={type}
          selectedSaar={selectedSaar}
          legoblokStructures={legoblokStructures}
          hasToggle={hasToggle}
        />
      );
    }

    // speciale relaties met meta-data
    return (
      <RelationMetaList
        type={type}
        side={side}
        itemId={itemId}
        relations={relations}
        label={type.displayLabel ?? label}
        className={className}
        initAddRelation={initAddRelation}
        all={all}
        setAll={setAll}
        edit={(type.mayEdit ?? type.mayDelete) && edit}
        maxLength={maxLength}
        labelRender={labelRender}
        selectedSaar={selectedSaar}
        legoblokStructures={legoblokStructures}
        removeRelation={curriedRemoveRelation}
        pageActions={pageActions}
        {...rest}
      />
    );
  }

  // relaties van een type met cluster, mag je alleen vanuit de clusterzijde toevoegen
  const mayClusterEdit =
    !type.needsCluster ||
    (type.clusterId &&
      type.showSide !== ShowSide.clusterSecundair &&
      type.showClusters !== ShowClusters.rollup);

  if (
    type.needsCluster &&
    type.showClusters !== ShowClusters.rollup &&
    relations.length > 0
  ) {
    // relaties met een cluster
    return (
      <RelationClusterList
        all={all}
        className={className}
        clusterFirst={type.clusterFirst}
        edit={type.mayDelete && mayClusterEdit && edit}
        initAddRelation={initAddRelation}
        itemId={itemId}
        label={type.displayLabel ?? label}
        maxLength={maxLength}
        mayClusterEdit={mayClusterEdit}
        relations={relations}
        removeRelation={curriedRemoveRelation}
        setAll={setAll}
        showDivider={showDivider}
        showHeader={showHeader}
        side={side}
        type={type}
      />
    );
  }

  return showMissing === false && relations.length === 0 ? null : (
    <div className={classNames(className, "relations")}>
      {showDivider && <Divider />}
      <List>
        {showHeader && (
          <RelationsListHeader
            key="header"
            itemId={itemId}
            type={type}
            side={side}
            label={type.displayLabel ?? label}
            edit={type.mayDelete && mayClusterEdit && edit}
            relations={relations}
            sortEnabled={relations.length >= 2}
            labelRender={labelRender}
          />
        )}
        {relations.length === 0 && type.canExist && !type.mayAdd && (
          <ListItem key="empty">Relatie nog niet ingevuld</ListItem>
        )}
        <TransitionGroup>
          {flow(
            takeIf(maxLength - 1, !all && relations.length > maxLength),
            map((relation) => (
              <CSSTransition
                key={`rel-${relation.item.itemId}-${
                  relation.item.pageClusterId || 0
                }`}
                classNames="relations"
                timeout={{ enter: 500, exit: 200 }}
              >
                <RelationView
                  relation={
                    type.forceMiniSaar
                      ? structuredMap({
                          item: (item) => ({ miniSaar: selectedSaar, ...item }),
                        })(relation)
                      : relation
                  }
                  labelRender={labelRender}
                  edit={type.mayDelete && mayClusterEdit && edit}
                  removeRelation={curriedRemoveRelation}
                  clusterFirst={type.clusterFirst}
                  legoblokStructures={legoblokStructures}
                />
              </CSSTransition>
            ))
          )(relations)}
          {(!hasToggle || all) &&
            type.canExist &&
            type.mayAdd &&
            mayClusterEdit &&
            (relations.length === 0 ||
              (edit && relationMultiple(type.rule))) && (
              <CSSTransition
                classNames="relations"
                timeout={{ enter: 500, exit: 200 }}
              >
                <ListItem
                  key="add"
                  onClick={() => initAddRelation(type, side, itemId)}
                  rightIcon={<OpenInNew />}
                >
                  Relatie toevoegen
                </ListItem>
              </CSSTransition>
            )}
        </TransitionGroup>
        {hasToggle && (
          <ListItem key="toggle" style={{ textAlign: "center" }} disabled>
            <RaisedButton
              label={all ? "Toon minder" : "Toon meer"}
              onClick={() => setAll(!all)}
            />
          </ListItem>
        )}
      </List>
    </div>
  );
};

export const RelationsList = compose(
  setDisplayName("RelationsList"),
  pure,
  withProps(({ maxLength, type = empty.object, list = empty.array }) => ({
    relations: aggregateUsings(list),
    maxLength: maxLength || type.maxLength || (type.metaPagetype ? 10000 : 6),
  })),
  withState("all", "setAll", false)
)(RelationsListComponent);

const ItemLinkListComponent = ({
  list = empty.array,
  label,
  className,
  showDivider,
  showHeader,
  legoblokStructures = empty.array,
  setAll,
  all,
  hasToggle,
  maxLength,
  miniSaar,
  moreLink,
  ...relationProps
}) => (
  <div className={className}>
    {showDivider && <Divider />}
    <List>
      {showHeader && <Subheader>{label}</Subheader>}
      <TransitionGroup>
        {flow(
          takeIf(maxLength - 1, !all && list.length > maxLength),
          map((item) => (
            <CSSTransition
              key={`trans-${item.itemId}-${item.structureId}-${item.path}`}
              classNames="relations"
              timeout={{ enter: 500, exit: 200 }}
            >
              <Relation
                key={`${item.itemId}-${item.structureId}-${item.path}`}
                item={item}
                Wrap={ListItem}
                insideBieb={some({ id: item.structureId })(legoblokStructures)}
                {...relationProps}
              />
            </CSSTransition>
          ))
        )(list)}
      </TransitionGroup>
      {list.length === 0 && (
        <ListItem primaryText="Niets gevonden" leftIcon={<Empty />} />
      )}
      {hasToggle && (
        <ListItem key="toggle" style={{ textAlign: "center" }} disabled>
          <RaisedButton
            label={all ? "Toon minder" : "Toon meer"}
            onClick={() => {
              if (miniSaar && moreLink) {
                saarHistory.push(
                  `/${miniSaar.alias}/page/${moreLink.itemId}/${moreLink.slug}?sort=recent`
                );
              } else {
                setAll(!all);
              }
            }}
          />
        </ListItem>
      )}
    </List>
  </div>
);

export const ItemLinkList = compose(
  setDisplayName("ItemLinkList"),
  withProps(({ list, maxLength }) => ({
    hasToggle: maxLength && list ? list.length > maxLength : false,
  })),
  withState("all", "setAll", false)
)(ItemLinkListComponent);

export const RelationListWrapper = ({ sideBarList, children }) =>
  sideBarList ? (
    <Card style={{ marginBottom: "16px" }}>{children}</Card>
  ) : (
    children
  );

const raamwerkListSorter = (sortOrders) => (a, b) => {
  const sortOrderA = flown(
    sortOrders,
    find({ name: a.label }),
    get("sortOrder"),
    defaultTo(0)
  );
  const sortOrderB = flown(
    sortOrders,
    find({ name: b.label }),
    get("sortOrder"),
    defaultTo(1)
  );

  return sortOrderA - sortOrderB;
};

/**
 * Relations component implementation
 */
const Relations = ({
  itemId,
  relations,
  types,
  miniSaars,
  filter,
  shown = shownRelations(relations, types)(filter),
  sideBarList = true,
  showHeader = sideBarList,
  pageActions,
  ...other
}) => {
  const subItems = useSelector((store) => {
    const isPraktijkSituatieRelationsBar = shown?.[0]?.leftShow !== 1;
    if (isPraktijkSituatieRelationsBar) {
      return undefined;
    }

    const page = get(`data.pages.${itemId}.page`)(store);
    console.assert(page?.pagetype === "praktijksituatie", {
      page,
      errorMsg: "Pagetype must be praktijksituatie",
    });
    const alias = get("instellingen.raamwerkLijst.value.alias")(page);
    console.assert(Boolean(alias), {
      alias,
      errorMsg: "Raamwerk lijst alias must be set",
    });
    return flown(
      store,
      get("data.selectionLists.data"),
      find({ alias }),
      get("items[0].items")
    );
  });

  const shownSorted = useMemo(() => {
    if (!isNonEmptyArray(subItems)) {
      return shown;
    }

    const sortOrders = subItems.map((item, index) => ({
      sortOrder: index,
      name: item.value,
    }));
    return shown.sort(raamwerkListSorter(sortOrders));
  }, [shown, subItems]);

  if (!isNonEmptyArray(shown)) {
    return null;
  }

  return (
    <div
      className={classNames({
        "content-relations": !sideBarList,
        nonEmpty: shown.length > 0,
        relations: sideBarList,
      })}
    >
      {shownSorted.map(
        ({ side, show = Side.left, label, links, ...type }, i) => (
          <RelationListWrapper
            key={`${type.id}-${side}-${show}`}
            sideBarList={type.metaPagetype ? false : sideBarList}
          >
            <RelationsList
              {...other}
              showDivider={!sideBarList && i > 0}
              type={type}
              side={side}
              itemId={itemId}
              list={links}
              label={label}
              miniSaars={miniSaars}
              showHeader={showHeader}
              pageActions={pageActions}
            />
          </RelationListWrapper>
        )
      )}
    </div>
  );
};

Relations.propTypes = {
  itemId: PropTypes.number.isRequired,
  initAddRelation: PropTypes.func.isRequired,
  removeRelation: PropTypes.func.isRequired,
  pageActions: pageActionsShape,
};

export default compose(setDisplayName("Relations"))(Relations);
