import Snackbar from "material-ui/Snackbar";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { snackbarHideAction } from "../actions/ui";
import { emptyErrorAction } from "../actions/utils";
import { StoreRoot } from "../business/models";
import { initialSnackbarState } from "../reducers/uiReducers";

const SnackbarWidget = () => {
  const dispatch = useDispatch();
  const snackbar = useSelector(
    (state: StoreRoot) => state.ui.snackbar ?? initialSnackbarState
  );
  const hideSnackbar = useCallback(
    (reason: string) => {
      if (!snackbar.options?.persistent || reason === "explicit") {
        dispatch(snackbarHideAction());
        dispatch(emptyErrorAction());
      }
    },
    [dispatch, snackbar.options?.persistent]
  );

  return (
    <Snackbar
      open={snackbar.open}
      bodyStyle={
        Boolean(snackbar.options?.singleLine)
          ? undefined
          : {
              height: snackbar.message ? 62 : 48,
              lineHeight: 1.6,
              padding: 24,
              textAlign: "center",
            }
      }
      message={
        snackbar.options?.persistent ? (
          <div>
            <button
              style={{
                position: "absolute",
                top: "1ex",
                right: "1ex",
                fontSize: "14px",
                color: "white",
                padding: 0,
                margin: 0,
                border: "none",
                background: "transparent",
                cursor: "pointer",
              }}
              onClick={() => hideSnackbar("explicit")}
              title="Melding sluiten"
            >
              X
            </button>
            {snackbar.message}
          </div>
        ) : (
          snackbar.message ?? ""
        )
      }
      autoHideDuration={
        snackbar.options?.persistent
          ? undefined
          : snackbar.options?.autoHideDuration ?? 2000
      }
      onRequestClose={hideSnackbar}
    />
  );
};

export default SnackbarWidget;
