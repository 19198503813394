import empty from "empty";
import { filter } from "lodash/fp";
import { compose, setDisplayName } from "recompose";
import { createSelector } from "reselect";

import { pickCluster } from "../actions/cms";
import AddButton from "../components/material/addButton";
import { execOnChange } from "../recompose.contrib";
import connect from "./connect";

const uidSelector = (
  _,
  { pageActions: { pageId } = empty.object, clusterId, name }
) => `${pageId}:${clusterId ? `${name}_${clusterId}` : name}`;
const clustersSelector = ({ data: { clusters } = empty.object }) => clusters;

const definitionSelector = createSelector(
  [uidSelector, clustersSelector],
  (uid, definitions = empty.object) => definitions[uid]
);

const numberAddedSelector = createSelector(
  [
    (_, { pageActions: { pageId } = empty.object }) => pageId,
    (_, { clusterId }) => clusterId,
    (_, { name }) => name,
    ({ form: { page } }) => page,
  ],
  (pageId, clusterId, clusterName, page) => {
    const { [pageId]: { staged = empty.array } = empty.object } = page;
    return filter(
      ({ $type: type, clusterId: id, name, ready }) =>
        /ClusterAddAction/.test(type) &&
        id === clusterId &&
        name === clusterName &&
        ready === true
    )(staged).length;
  }
);

export default compose(
  setDisplayName("AddButton"),
  execOnChange(
    (props) => {
      const {
        clusterId,
        name,
        pageActions: { requireClusters = empty.func } = empty.object,
      } = props;
      requireClusters({ clusterId, name });
    },
    "clusterId",
    "name"
  ),
  connect(
    (state, props) => ({
      definition: definitionSelector(state, props),
      numberAdded: numberAddedSelector(state, props),
    }),
    (
      _,
      {
        gegeven,
        pageActions: {
          dispatch,
          pageId,
          clusterAdd = empty.func,
        } = empty.object,
      }
    ) => ({
      selectTemplate: (name, { clusterId, name: clusterAddName }) =>
        clusterAdd({ clusterId, name: clusterAddName }).then(() =>
          dispatch(pickCluster(pageId, name, gegeven))
        ),
    })
  )
)(AddButton);
