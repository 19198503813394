/* eslint  react/prop-types: off */
import empty from "empty";
import IconButton from "material-ui/IconButton";
import { lightGreen100 } from "material-ui/styles/colors";
import ActionHighlightOff from "material-ui/svg-icons/action/highlight-off";
import PropTypes from "prop-types";
import React from "react";
import Dropzone from "react-dropzone";
import {
  compose,
  defaultProps,
  mapProps,
  setDisplayName,
  setPropTypes,
  withHandlers,
} from "recompose";

import { concat, upload } from "../../utils";
import BestandView from "./bestandView";
import { propTypes, withDefaultPageActions } from "./ggvtyp";

/**
 * Gegevenstype 'Bestand' (11)
 */
const Bestand = ({
  field: { assetPath = "/", isEmpty = true } = empty.object,
  value: {
    fileName = "",
    title = "",
    path = concat(assetPath, fileName),
    extension,
  } = empty.object,
  pageActions: { edit },
  className,
  dirty,
  handleDrop,
  handleDelete,
}) =>
  edit ? (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Dropzone
        multiple={false}
        onDrop={handleDrop}
        style={{
          height: "inherit",
          color: "#999",
          display: "inline-block",
        }}
        activeStyle={{
          backgroundColor: lightGreen100,
        }}
      >
        {(isEmpty && !(title || fileName)) || (dirty && fileName === "") ? (
          <span>
            Sleep een bestand hierin of{" "}
            <span className="fake-link">selecteer</span>
          </span>
        ) : (
          title || fileName
        )}
      </Dropzone>
      <IconButton
        disabled={
          (isEmpty && !(title || fileName)) || (dirty && fileName === "")
        }
        style={{
          display: "inline-block",
        }}
        onClick={handleDelete}
        title="Verwijder bestand"
      >
        <ActionHighlightOff />
      </IconButton>
    </div>
  ) : isEmpty ? null : (
    <BestandView
      className={className}
      path={path}
      title={title}
      extension={extension}
      handleDelete={handleDelete}
    />
  );

const extensionRegex = /(\.[^.]+)$/i;
export const appendExtension = ({ fileName = "", ...rest }) => ({
  fileName,
  extension:
    fileName && extensionRegex.test(fileName)
      ? extensionRegex.exec(fileName)[0]
      : undefined,
  ...rest,
});

export default compose(
  setDisplayName("Bestand"),
  setPropTypes({
    ...propTypes,
    field: PropTypes.shape({
      value: PropTypes.shape({
        fileName: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        libraryId: PropTypes.number,
      }),
      assetPath: PropTypes.string.isRequired,
    }).isRequired,
  }),
  defaultProps({
    className: "bestand",
  }),
  withDefaultPageActions(),
  withHandlers({
    handleDrop:
      ({ setValue }) =>
      (files) => {
        for (const file of files) {
          upload(setValue)(file);
        }
      },

    handleDelete:
      ({ setValue }) =>
      () =>
        setValue(empty.object),
  }),
  mapProps(({ value = empty.object, ...props }) => ({
    value: appendExtension(value),
    ...props,
  }))
)(Bestand);
