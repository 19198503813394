import classNames from "classnames";
import { find, findLast, get, map, orderBy } from "lodash/fp";
import { ListItem } from "material-ui/List";
import ContentRemove from "material-ui/svg-icons/content/remove";
import HardwareKeyboardArrowDown from "material-ui/svg-icons/hardware/keyboard-arrow-down";
import HardwareKeyboardArrowUp from "material-ui/svg-icons/hardware/keyboard-arrow-up";
import React from "react";

import { ExternLink, StatusLink } from "../../../business/models";
import { flown } from "../../../lodash";
import { isNonEmptyArray } from "../../../utils";
import AddButton from "./addButton";
import LinkListItem from "./linkListItem";
import {
  LinkItemsProps,
  NakedTreeProps,
  StagedHyperlinkAction,
  StagedSelectionItemAction,
  TreeItemProps,
} from "./models";
import NvtMenu from "./nvtMenu";
import TreeItemState from "./treeItemState";

const renderLinkItems = ({
  item,
  edit,
  allowed,
  links,
  statusItems,
  onChange,
  openRemarkDialog,
  openHyperlinkDialog,
  stagedRemarks,
  stagedHyperlinks,
  practicalSituations,
}: LinkItemsProps) =>
  flown(
    links,
    orderBy(["link.type", "link.label"], ["asc", "asc"]),
    map(
      ({
        id,
        leftItemId,
        link,
        externLink,
        status: alias,
        remark,
      }: StatusLink) => {
        const value = flown(statusItems, find({ alias }), get("value"));
        const stagedHyperlink = externLink
          ? findLast(
              ({ externLink: { id } }: StagedHyperlinkAction) =>
                id === externLink.id
            )(stagedHyperlinks)
          : undefined;

        return (
          <LinkListItem
            key={id}
            item={item}
            leftItemId={leftItemId}
            edit={edit}
            allowed={allowed}
            link={link}
            externLink={externLink}
            status={alias}
            statusItems={statusItems}
            remark={remark}
            metaItem={{ alias, value }}
            onChange={onChange}
            openRemarkDialog={openRemarkDialog}
            stagedRemarks={stagedRemarks}
            openHyperlinkDialog={openHyperlinkDialog}
            stagedHyperlink={stagedHyperlink}
            practicalSituation={practicalSituations?.find(
              ({ filterItem: { itemId } }) => itemId === leftItemId
            )}
          />
        );
      }
    )
  );

const renderTreeItem = ({
  item,
  item: {
    id,
    value,
    items,
    links: originalLinks,
    statusLinkCount,
    emptyItemCount = 0,
    disabled = false,
    remark,
  },
  statusItems,
  level,
  open,
  toggleOpen,
  pagetypes,
  edit,
  allowed,
  openDialog,
  openHyperlinkDialog,
  openRemarkDialog,
  pageActions,
  raamwerkAlias,
  onNvtChange,
  numberAdded,
  practicalSituations,
}: TreeItemProps) => {
  const links = pageActions.dirty
    ? pageActions.staged
        .filter(
          ({ $type, ...payload }) =>
            /Raamwerk(Link|Unlink)Action/.test($type) &&
            (payload as { selectionId: number }).selectionId === item.id
        )
        .reduce((tempLinks, { $type, ...payload }): StatusLink[] => {
          const { itemId, externLink, status } = payload as {
            itemId?: number;
            externLink?: ExternLink;
            status: string;
          };
          return tempLinks.map((link) => {
            if (
              (externLink && link.externLink?.id === externLink.id) ||
              (itemId && link.link?.itemId === itemId && !link.leftItemId)
            ) {
              return {
                ...link,
                status: /Unlink/.test($type) ? "" : status,
              };
            } else {
              return link;
            }
          });
        }, originalLinks)
    : originalLinks;

  const stagedRemarks = pageActions.staged.filter(({ $type }) =>
    /RemarkAction/.test($type)
  );
  const hasStagedRemark = stagedRemarks.some(
    ({ $type, ...payload }) =>
      /RaamwerkSelectionItemRemarkAction/.test($type) &&
      (payload as { selectionId?: number }).selectionId === id
  );

  const stagedAction = pageActions.staged.filter(
    ({ $type, ...payload }) =>
      /SelectionItemAction/.test($type) &&
      (payload as { selectionId: number }).selectionId === item.id
  ) as StagedSelectionItemAction[];
  const disabledSelectionItem =
    pageActions.dirty && stagedAction.length > 0
      ? stagedAction[stagedAction.length - 1].disabled
      : disabled;

  const hasChildren = isNonEmptyArray(items) || isNonEmptyArray(links);

  const stagedHyperlinks = pageActions.staged.filter(
    ({ $type, ...payload }) => {
      const staged = payload as StagedHyperlinkAction;
      return (
        /RaamwerkLinkAction/.test($type) &&
        staged.externLink &&
        staged.externLink.id
      );
    }
  ) as StagedHyperlinkAction[];

  return (
    <ListItem
      className={classNames("raamwerk_tree_item", { disabledSelectionItem })}
      key={id}
      nestedLevel={level}
      open={open[id]}
      onClick={() => {
        if (!disabledSelectionItem && !edit && (open[id] || hasChildren)) {
          toggleOpen(id);
        }
      }}
      nestedItems={[
        ...renderLinkItems({
          onChange: ({ type, payload: { item, meta } }, link, externLink) => {
            const payload = {
              itemId: link ? link.itemId : undefined,
              externLink,
              raamwerkAlias,
              selectionId: item.selectionId,
              status: meta.alias,
            };

            pageActions.add(
              type === "ADD" ? "RaamwerkLink" : "RaamwerkUnlink",
              payload
            );
          },
          item: { ...item, disabled: disabledSelectionItem },
          edit,
          allowed,
          links,
          statusItems,
          openRemarkDialog,
          openHyperlinkDialog: openHyperlinkDialog(id),
          stagedRemarks,
          stagedHyperlinks,
          practicalSituations,
        }),
        ...renderTreeItems({
          items,
          statusItems,
          level: level + 1,
          open,
          toggleOpen,
          pagetypes,
          openDialog,
          openHyperlinkDialog,
          openRemarkDialog,
          edit,
          allowed,
          pageActions,
          raamwerkAlias,
          onNvtChange,
          numberAdded,
          practicalSituations,
        }),
      ]}
      leftIcon={
        open[id] && !disabledSelectionItem ? (
          <HardwareKeyboardArrowUp
            onClick={() => {
              if (edit) {
                toggleOpen(id);
              }
            }}
          />
        ) : hasChildren ? (
          <HardwareKeyboardArrowDown
            onClick={() => {
              if (edit) {
                toggleOpen(id);
              }
            }}
          />
        ) : (
          <ContentRemove />
        )
      }
      rightIcon={<></>}
    >
      <TreeItemState
        edit={edit}
        allowed={allowed}
        statusItems={statusItems}
        statusLinkCount={statusLinkCount}
        emptyItemCount={emptyItemCount}
        disabledSelectionItem={disabledSelectionItem}
        selectionId={id}
        remark={remark}
        openRemarkDialog={openRemarkDialog}
        hasStagedRemark={hasStagedRemark}
      />
      {edit && (
        <>
          {allowed.canNvt && (
            <NvtMenu
              selectionId={id}
              disabled={disabledSelectionItem}
              onChange={onNvtChange}
              disabledNvtItem={
                statusItems
                  .map(({ alias = "" }) => statusLinkCount[alias] || 0)
                  .reduce((a, b) => a + b, 0) > 0
              }
            />
          )}

          {allowed.canAddLink && (
            <AddButton
              pagetypes={pagetypes}
              onClick={openDialog(id)}
              onHyperlinkClick={openHyperlinkDialog(id)}
              disabled={disabledSelectionItem}
            />
          )}
        </>
      )}
      <span className="tree-item-text">{value}</span>
    </ListItem>
  );
};

const renderTreeItems = (props: NakedTreeProps) =>
  props.items.map((item) => renderTreeItem({ ...props, item }));

export default renderTreeItems;
