/* eslint  react/prop-types: off */
import classNames from "classnames";
import empty from "empty";
import IconMenu from "material-ui/IconMenu";
import { List, ListItem } from "material-ui/List";
import MenuItem from "material-ui/MenuItem";
import RaisedButton from "material-ui/RaisedButton";
import React, { Fragment } from "react";

import ClusterCard from "./clusterCard";

export const listItemStyle = {
  display: "flex",
  justifyContent: "flex-start",
  flexWrap: "wrap",
};

export const buttonStyle = { margin: 8 };

const getLabel = (name) =>
  `${name} ${name === "Bestand" ? "uploaden" : "toevoegen"}`;

const choiceAddIconMenu = ({
  name,
  plug,
  clusterId,
  enabled = false,
  definition: {
    id,
    clusterId: definitionId,
    templates = empty.array,
  } = empty.object,
  selectTemplate = empty.func,
}) => {
  const uid = `${id}:${definitionId ? `${name}_${definitionId}` : name}`;
  return (
    <IconMenu
      iconButtonElement={
        <RaisedButton
          secondary
          label={getLabel(plug?.clusterName?.(empty.object, name) ?? name)}
          disabled={!enabled}
        />
      }
      style={{ padding: "16px 0" }}
    >
      {templates.map(({ name: choiceName }, idx) => (
        <MenuItem
          key={`add_${uid}_${idx}`}
          disabled={!enabled}
          onClick={() => selectTemplate(choiceName, { clusterId, name })}
          primaryText={getLabel(choiceName)}
        />
      ))}
    </IconMenu>
  );
};

const choiceAddListItems = ({
  name,
  plug,
  clusterId,
  enabled = false,
  definition: {
    id,
    clusterId: definitionId,
    templates = empty.array,
  } = empty.object,
  selectTemplate = empty.func,
}) => {
  const uid = `${id}:${definitionId ? `${name}_${definitionId}` : name}`;
  return (
    <ListItem key={`add_${uid}`} disabled style={listItemStyle}>
      {templates.map(({ name: choiceName }, idx) => (
        <RaisedButton
          label={getLabel(
            plug?.clusterName?.(empty.object, choiceName) ?? choiceName
          )}
          key={`add_${uid}_${idx}`}
          onClick={() => selectTemplate(choiceName, { clusterId, name })}
          disabled={!enabled}
          style={buttonStyle}
          secondary
        />
      ))}
    </ListItem>
  );
};

const AddButton = (props) => {
  const {
    label,
    name,
    clusterId,
    enabled = false,
    definition: {
      id,
      clusterId: definitionId,
      choice = false,
      templates = empty.array,
    } = empty.object,
    selectTemplate = empty.func,
    style,
    innerStyle,
    useIconMenu = false,
    numberAdded,
    renderAfterAddButton,
  } = props;
  if (!id) {
    return null;
  }

  const uid = `${id}:${definitionId ? `${name}_${definitionId}` : name}`;
  const choiceAdd = useIconMenu ? choiceAddIconMenu : choiceAddListItems;
  return (
    <Fragment>
      {numberAdded > 0 && (
        <ClusterCard
          title={`${numberAdded} wacht${
            numberAdded > 1 ? "en" : ""
          } op opslaan`}
          titleStyle={{ fontWeight: "normal", fontStyle: "italic" }}
          expandable
          expanded={false}
          actAsExpander={false}
          showExpandableButton={false}
        />
      )}
      <div
        className={classNames("cluster-add-button", {
          "disabled-cluster-button": !enabled,
        })}
        style={{ position: "relative", ...style }}
        data-clusterid={clusterId}
      >
        <List style={{ padding: 0, ...innerStyle }}>
          {!choice && (
            <ListItem key={`add_${uid}`} disabled style={listItemStyle}>
              <RaisedButton
                label={getLabel(label)}
                onClick={() => selectTemplate(name, { clusterId, name })}
                disabled={!enabled}
                secondary
              />
              {renderAfterAddButton}
            </ListItem>
          )}
          {choice && templates.length > 0 && choiceAdd(props)}
        </List>
      </div>
    </Fragment>
  );
};

export default AddButton;
