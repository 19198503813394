/* eslint  react/prop-types: off */
import empty from "empty";
import { find } from "lodash/fp";
import Drawer from "material-ui/Drawer";
import MenuItem from "material-ui/MenuItem";
import {
  amber50 as biebBackgroundColor,
  amber900 as biebColor,
} from "material-ui/styles/colors";
import PropTypes from "prop-types";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { hashHistory } from "react-router";
import { Breakpoint } from "react-socks";
import {
  compose,
  mapProps,
  setDisplayName,
  setPropTypes,
  withHandlers,
  withState,
} from "recompose";

import AutoComplete from "../containers/autoComplete";
import { enableScopeSelector } from "../selectors/header";
import LinkToBiebDialog from "./bieb/LinkToBiebDialog";
import IconElementRight from "./header/iconElementRight";
import AppBar from "./material/appBar";
import AppBarMobile from "./material/AppBarMobile";
import MiniSaarMenu from "./miniSaarMenu";
import PagetypeIcon from "./pagetypeIcon";
import { ItemLink } from "./relation.itemlink";

const menuItem = (
  {
    itemId,
    label,
    type,
    slug = "",
    toggleOpen,
    status: { alias } = empty.object,
    validity: { alias: validity } = empty.object,
  },
  key
) => {
  const linkTo = { itemId, label, slug };
  return (
    <MenuItem
      key={key}
      onClick={toggleOpen}
      containerElement={<ItemLink item={linkTo} />}
      leftIcon={
        <PagetypeIcon
          type={type}
          validity={validity}
          style={{ marginTop: "7px" }}
          colorProperty={alias}
        />
      }
      primaryText={label}
      innerDivStyle={{ paddingLeft: "56px" }}
    />
  );
};

menuItem.propTypes = {
  itemId: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  slug: PropTypes.number.isRequired,
};

menuItem.defaultProps = {
  slug: "",
};

/**
 * Header component
 * @return React Element
 */
const Header = ({
  params: { id: itemId } = empty.object,
  drawerOpen,
  handleMenuDrawerClose,
  toggleOpen,
  homeLink = { label: "Inloggen", slug: "inloggen" },
  footerLinks = empty.array,
  user = empty.object,
  logout,
  password,
  selectedSaar = empty.object,
  miniSaarList,
  legoblokStructures,
  updateMiniSaar,
  deleteItem,
  linkItem,
  unlinkItem,
  isFullscreen = false,
  toggleSecondaryDrawer = empty.func,
  pageState: { edit = false, busy = false } = empty.object,
  pagetype,
  siteId,
  addToBieb,
  biebButton,
  biebLink,
  isBiebItem,
  enableScope,
  versions,
  versionsDrawer,
  showVersionsDrawer,
  setVersionDiff,
  enableWord,
  biebLinkDialogProps,
  okBiebLinkDialog,
  closeBiebLinkDialog,
}) => {
  const [showRecent, setRecent] = useState(false);
  const toggleRecent = useCallback(() => setRecent(!showRecent), [showRecent]);

  const [showSeach, setSearch] = useState(false);
  const toggleSearch = useCallback(() => setSearch(!showSeach), [showSeach]);

  const location = hashHistory.getCurrentLocation();
  useEffect(() => {
    setRecent(false);
    setSearch(false);
  }, [location.pathname, location.search]);

  const biebStructureIds = useMemo(
    () => legoblokStructures.map(({ id }) => id),
    [legoblokStructures]
  );

  return (
    <header>
      <Breakpoint customQuery="(max-width: 860px)">
        <AppBarMobile
          title="Saar"
          onLeftIconButtonClick={toggleOpen}
          authenticated={Boolean(user.id)}
          showRecent={showRecent}
          toggleRecent={toggleRecent}
          iconElementLeft={
            miniSaarList &&
            miniSaarList.length > 0 &&
            selectedSaar && (
              <MiniSaarMenu
                title="Saar"
                miniSaarList={miniSaarList}
                selectedSaar={selectedSaar}
                updateMiniSaar={updateMiniSaar}
              />
            )
          }
          iconElementRight={
            <IconElementRight
              addToBieb={addToBieb}
              biebBackgroundColor={biebBackgroundColor}
              biebButton={biebButton}
              biebLink={biebLink}
              isBiebItem={isBiebItem}
              biebColor={biebColor}
              busy={busy}
              deleteItem={deleteItem}
              edit={edit}
              isFullscreen={isFullscreen}
              itemId={parseInt(itemId, 10)}
              legoblokStructures={legoblokStructures}
              linkItem={linkItem}
              logout={logout}
              pagetype={pagetype}
              password={password}
              siteId={siteId}
              toggleSecondaryDrawer={toggleSecondaryDrawer}
              unlinkItem={unlinkItem}
              user={user}
              versions={versions}
              versionsDrawer={versionsDrawer}
              showVersionsDrawer={showVersionsDrawer}
              setVersionDiff={setVersionDiff}
              enableWord={enableWord}
              mobile
              toggleSearch={toggleSearch}
            />
          }
        >
          {showSeach && user.id && (
            <div className="inner">
              <AutoComplete
                saar={selectedSaar}
                enableScope={enableScope}
                mobile
              />
            </div>
          )}
        </AppBarMobile>
      </Breakpoint>
      <Breakpoint customQuery="(min-width: 860px)">
        <AppBar
          title="Saar"
          onLeftIconButtonClick={toggleOpen}
          authenticated={Boolean(user.id)}
          showRecent={showRecent}
          toggleRecent={toggleRecent}
          iconElementLeft={
            miniSaarList &&
            miniSaarList.length > 0 &&
            selectedSaar && (
              <MiniSaarMenu
                title="Saar"
                miniSaarList={miniSaarList}
                selectedSaar={selectedSaar}
                updateMiniSaar={updateMiniSaar}
              />
            )
          }
          iconElementRight={
            <IconElementRight
              addToBieb={addToBieb}
              biebBackgroundColor={biebBackgroundColor}
              biebButton={biebButton}
              biebLink={biebLink}
              isBiebItem={isBiebItem}
              biebColor={biebColor}
              busy={busy}
              deleteItem={deleteItem}
              edit={edit}
              isFullscreen={isFullscreen}
              itemId={parseInt(itemId, 10)}
              legoblokStructures={legoblokStructures}
              linkItem={linkItem}
              logout={logout}
              pagetype={pagetype}
              password={password}
              siteId={siteId}
              toggleSecondaryDrawer={toggleSecondaryDrawer}
              unlinkItem={unlinkItem}
              user={user}
              versions={versions}
              versionsDrawer={versionsDrawer}
              showVersionsDrawer={showVersionsDrawer}
              setVersionDiff={setVersionDiff}
              enableWord={enableWord}
            />
          }
        >
          {user.id && (
            <div className="inner">
              <AutoComplete saar={selectedSaar} enableScope={enableScope} />
            </div>
          )}
        </AppBar>
      </Breakpoint>
      <Drawer
        docked={false}
        open={drawerOpen}
        onRequestChange={handleMenuDrawerClose}
      >
        {drawerOpen && (
          <Fragment>
            {menuItem({ ...homeLink, toggleOpen })}
            {footerLinks.map((link, i) => menuItem({ ...link, toggleOpen }, i))}
          </Fragment>
        )}
      </Drawer>
      {biebLinkDialogProps.open && (
        <LinkToBiebDialog
          {...biebLinkDialogProps}
          pagetype={pagetype}
          biebStructureIds={biebStructureIds}
          onOk={okBiebLinkDialog}
          onClose={closeBiebLinkDialog}
        />
      )}
    </header>
  );
};

const siteLinkType = PropTypes.shape({
  itemId: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  slug: PropTypes.string,
});

export default compose(
  setDisplayName("Header"),
  withState("drawerOpen", "setOpen", false),
  withHandlers({
    toggleOpen:
      ({ setOpen }) =>
      () =>
        setOpen((open) => !open),
    handleMenuDrawerClose:
      ({ setOpen }) =>
      () =>
        setOpen(false),
  }),
  setPropTypes({
    homeLink: siteLinkType,
    footerLinks: PropTypes.arrayOf(siteLinkType),
  }),
  mapProps((props) => {
    const {
      page: { siteId = 0, page: { pagetype } = empty.object } = empty.object,
      types,
      ...rest
    } = props;

    const legoblokRelationType = find({ alias: `${pagetype}_legoblok` })(types);
    return {
      ...rest,
      pagetype,
      siteId,
      legoblokRelationType,
      enableScope: enableScopeSelector(props),
    };
  })
)(Header);
