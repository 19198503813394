/* eslint  react/prop-types: off */
import empty from "empty";
import { has } from "lodash/fp";
import Drawer from "material-ui/Drawer";
import IconButton from "material-ui/IconButton";
import { List, ListItem as NativeListItem } from "material-ui/List";
import Subheader from "material-ui/Subheader";
import NavigationClose from "material-ui/svg-icons/navigation/close";
import moment from "moment";
import React from "react";
import {
  branch,
  compose,
  pure,
  renderNothing,
  setDisplayName,
  withHandlers,
} from "recompose";

import { mapWithIndex } from "../lodash";
import PagetypeIcon from "./pagetypeIcon";

const ListItem = compose(
  pure,
  setDisplayName("ListItem"),
  withHandlers({
    onClick:
      ({ onClick, version }) =>
      (event) =>
        onClick(version, event),
  })
)(NativeListItem);

const VersionsDrawer = ({
  versions: { items = empty.array } = empty.object,
  open,
  handleClose,
  handleClick,
}) => (
  <Drawer
    containerStyle={{
      height: "calc(100% - 50px)",
      marginTop: 50,
      textAlign: "left",
    }}
    open={open}
    onRequestChange={handleClose}
    openSecondary
  >
    <IconButton onClick={handleClose} style={{ float: "right" }}>
      <NavigationClose />
    </IconButton>
    <List>
      <Subheader>Versiegeschiedenis</Subheader>
      {mapWithIndex(
        (
          {
            itemId,
            versionId,
            relationVersionId,
            relationItemId,
            relationItemVersionId,
            created,
            user,
          },
          index
        ) => (
          <ListItem
            key={`version-${itemId}-${versionId}-${relationVersionId}-${relationItemId}-${relationItemVersionId}`}
            leftIcon={
              <PagetypeIcon type="version-history" style={{ top: 12 }} />
            }
            innerDivStyle={{ paddingLeft: "56px" }}
            primaryText={
              <div className="primary">{moment(created).format("LLL")}</div>
            }
            secondaryText={
              <div>
                {index === 0 && <strong>Huidige versie</strong>}
                {index === 0 && " "}
                door {user.name}
              </div>
            }
            secondaryTextLines={2}
            version={{
              itemId,
              versionId,
              relationVersionId,
              relationItemId,
              relationItemVersionId,
              created,
              current: index === 0,
            }}
            onClick={handleClick}
          />
        )
      )(items)}
    </List>
  </Drawer>
);

export default compose(
  setDisplayName("VersionsDrawer"),
  pure,
  branch((props) => !has("versions.items")(props), renderNothing),
  withHandlers({
    handleClick:
      ({ setVersionDiff }) =>
      ({
        itemId,
        versionId,
        relationVersionId,
        relationItemId,
        relationItemVersionId,
        created,
        current,
      }) =>
        setVersionDiff(
          itemId,
          versionId,
          relationVersionId,
          relationItemId,
          relationItemVersionId,
          created,
          current
        ),
  })
)(VersionsDrawer);
