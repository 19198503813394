import empty from "empty";
import { compose, setDisplayName } from "recompose";

import { requireEntiteiten, requireZibs } from "../../../actions/application";
import { execOnChange } from "../../../recompose.contrib";
import { dispatchWrap } from "../../../utils";
import connect from "../../connect";
import enhanceLandscape from "../../enhanceLandscape";

export const enhanceApplication = compose(
  setDisplayName("Application"),
  connect(empty.functionThatReturns(empty.object), (dispatch) => ({
    initEntiteiten: dispatchWrap(requireEntiteiten, dispatch),
    initZibs: dispatchWrap(requireZibs, dispatch),
  })),
  execOnChange("initEntiteiten"),
  execOnChange("initZibs"),
  enhanceLandscape
);
