const stylesheet: cytoscape.Stylesheet[] = [
  {
    selector: "node",
    style: {
      label: "data(label)",
      shape: "round-rectangle",
      "text-wrap": "wrap",
      "text-halign": "center",
      "text-valign": "center",
      width: 104,
      height: 40,
      "text-max-width": "100",
      color: "white",
      "font-size": 12,
    },
  },
  {
    selector: ":selected",
    style: {
      "border-width": 2,
      "border-color": "#FF0000",
    },
  },
  {
    selector: ".visible",
    style: {
      opacity: 1,
    },
  },
  {
    selector: ".hidden",
    style: {
      opacity: 0.2,
      color: "black",
    },
  },
  {
    selector: "node.parent",
    style: {
      color: "black",
    },
  },
  {
    selector: "node.self",
    style: {
      "background-color": "#ffc107",
    },
  },
  {
    selector: "node.intern",
    style: {
      "background-color": "#8bc34a",
    },
  },
  {
    selector: "node.extern",
    style: {
      "background-color": "#03a9f4",
    },
  },
  {
    selector: "node.concept,node.nieuw",
    style: {
      "background-color": "#9c27b0",
    },
  },
  {
    selector: "node.archief,node.oud",
    style: {
      "background-color": "#9e9e9e",
    },
  },
  {
    selector: ":parent",
    style: {
      "text-halign": "center",
      "text-valign": "top",
      "z-compound-depth": "bottom",
    },
  },
  {
    selector: "node.hover",
    style: {
      "border-width": 2,
      "border-color": "#FF0000",
    },
  },
  {
    selector: "edge",
    style: {
      "line-color": "#999999",
      "target-arrow-color": "green",
      "source-arrow-color": "green",
      "target-arrow-shape": (ele) => {
        const count: number = ele.data("targetCount");
        switch (count) {
          case 0:
            return "none";
          case 1:
            return "triangle";
          default:
            return "triangle-tee";
        }
      },
      "source-arrow-shape": (ele) => {
        const count: number = ele.data("sourceCount");
        switch (count) {
          case 0:
            return "none";
          case 1:
            return "triangle";
          default:
            return "triangle-tee";
        }
      },
      "curve-style": "straight",
      "z-compound-depth": "auto",
    },
  },
  {
    selector: "edge.hover",
    style: {
      "line-color": "#FF0000",
      label: (ele: cytoscape.EdgeSingular) =>
        ele.data("linkCount") > 1
          ? `${ele.data("linkCount")} koppelingen`
          : ele.data("label"),
      "text-rotation": "autorotate",
      "font-size": 10,
      "text-margin-y": -10,
      "z-compound-depth": "top",
      "target-arrow-color": "#FF0000",
      "source-arrow-color": "#FF0000",
    },
  },
  {
    selector: "edge.nil",
    style: {
      "line-color": "#999999",
    },
  },
  {
    selector: "edge.auto",
    style: {
      "line-color": "#8bc34a",
    },
  },
  {
    selector: "edge.semi",
    style: {
      "line-color": "#ffab00",
    },
  },
  {
    selector: "edge.hand",
    style: {
      "line-color": "#ff5722",
    },
  },
];

export default stylesheet;
