/* eslint  react/prop-types: off */

import empty from "empty";
import { filter, flow, head } from "lodash/fp";
import React, { useCallback } from "react";
import { useDispatch } from "react-redux";

import { init } from "../../actions/relation";
import { Side } from "../../business/relations";
import AddClusterRelationButton from "./AddClusterRelationButton";
import defaultRenderRelationLinks from "./defaultRenderRelationLinks";
import { Label } from "./field";

const ClusterRelation = ({
  clusterId,
  pageActions,
  type,
  relation = empty.object,
  renderRelationLinkParentheses,
  renderLinks,
}) => {
  const showAdd = pageActions.edit && type?.mayAdd === true;
  const links = relation.links ?? empty.array;

  const dispatch = useDispatch();
  const handleAdd = useCallback(() => {
    dispatch(
      init(type, Side.left, pageActions.pageId, {
        clusterIds: [clusterId],
        disableStep1: true,
      })
    );
  }, [clusterId, dispatch, pageActions.pageId, type]);

  if (links.length === 0 && !showAdd) {
    return null;
  }

  const render = renderLinks ?? defaultRenderRelationLinks;

  return (
    <div className="field cluster-relation">
      <Label>{type?.left.label ?? relation.label}</Label>
      <div className="value">
        {render({
          links,
          relation,
          pageActions,
          renderRelationLinkParentheses,
        })}
        {showAdd && <AddClusterRelationButton onClick={handleAdd} />}
      </div>
    </div>
  );
};

const ClusterRelations = ({
  clusterId,
  pageActions,
  relations,
  types = empty.array,
  renderRelationLinkParentheses,
  renderLinks,
}) =>
  types.length > 0
    ? types.map((type) => (
        <ClusterRelation
          key={`${type.id}_${type.left.clusterId}`}
          clusterId={clusterId}
          pageActions={pageActions}
          type={type}
          relation={flow(
            filter(({ id }) => type.id === id),
            head
          )(relations)}
          renderRelationLinkParentheses={renderRelationLinkParentheses}
          renderLinks={renderLinks}
        />
      ))
    : relations.map((relation) => (
        <ClusterRelation
          key={`${relation.id}_${relation.side}`}
          pageActions={pageActions}
          relation={relation}
          renderRelationLinkParentheses={renderRelationLinkParentheses}
          renderLinks={renderLinks}
        />
      ));

export default ClusterRelations;
