import CircularProgress from "material-ui/CircularProgress";
import FlatButton from "material-ui/FlatButton";
import { Step, StepButton, StepContent, Stepper } from "material-ui/Stepper";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { PageActions, StructureItem } from "../../../../business/models";
import Dialog from "../../../../containers/dialog";
import { sagas } from "../../../../sagas";
import PagetypeIcon from "../../../pagetypeIcon";
import AppGegevensPicker from "../../../pickers/AppGegevensPicker";
import TreePicker from "../../../pickers/TreePicker";
import { SelectedApplicatieGegeven } from "./models";

interface Props {
  pageActions: PageActions;

  onClose(): void;
}

const AddProcessDialog = ({
  onClose,
  pageActions: { pageId },
  pageActions,
}: Props): JSX.Element => {
  const [stepIndex, setIndex] = useState<number>(0);
  const [processItemId, setProcess] = useState<number | undefined>(undefined);
  const [dataChosen, setChosen] = useState(
    new Array<SelectedApplicatieGegeven>()
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: sagas.processTree.require });
  }, [dispatch]);
  const processTree = useSelector((state: any) => state.sagas.processTree);
  const page = useSelector((state: any) => state.data.pages[pageId]);
  const roots: StructureItem[] | undefined = processTree.value?.roots;

  return (
    <Dialog
      open
      autoScrollBodyContent
      title={`Proces koppelen aan gegevens van ${page.title}`}
      actions={[
        stepIndex > 0 && (
          <FlatButton key="back" label="Vorige" onClick={() => setIndex(0)} />
        ),
        <FlatButton
          key="cancel"
          secondary
          label="Annuleren"
          onClick={onClose}
        />,
        stepIndex === 0 && (
          <FlatButton
            key="forward"
            label="Volgende"
            disabled={processItemId === undefined}
            onClick={() => setIndex(1)}
          />
        ),
        stepIndex === 1 && (
          <FlatButton
            key="ok"
            label="OK"
            primary
            disabled={processItemId === undefined || dataChosen.length === 0}
            onClick={(): void => {
              pageActions.add("LinkProcess", {
                processItemId,
                selection: dataChosen,
              });
              pageActions.save();
              onClose();
            }}
          />
        ),
      ]}
    >
      {!roots && <CircularProgress />}
      {roots && (
        <Stepper activeStep={stepIndex} orientation="vertical">
          <Step>
            <StepButton>Proces</StepButton>
            <StepContent>
              <TreePicker
                value={processItemId}
                options={roots}
                getValue={(option) => option.itemId}
                getSelectable={(option) =>
                  option.pagetype === "activiteit" ||
                  option.pagetype === "bedrijfsproces"
                }
                renderOptionIcon={(option) => (
                  <PagetypeIcon
                    type={option.pagetype}
                    colorProperty={option.status?.alias}
                    containerStyle={undefined}
                    iconStyle={undefined}
                    insideBieb={false}
                    style={undefined}
                    simpleIcon={true}
                  />
                )}
                renderOptionLabel={(option) => option.label}
                onClick={(v, on) => setProcess(on ? v : undefined)}
              />
            </StepContent>
          </Step>
          <Step>
            <StepButton>Gegevens</StepButton>
            <StepContent>
              <AppGegevensPicker
                itemId={pageActions.pageId}
                values={dataChosen}
                onChange={setChosen}
              />
            </StepContent>
          </Step>
        </Stepper>
      )}
    </Dialog>
  );
};

export default AddProcessDialog;
