/* eslint  react/prop-types: off */
import { loadingProgress } from "async-lifecycle";
import empty from "empty";
import { Card } from "material-ui/Card";
import React from "react";
import Helmet from "react-helmet";

import ItemList from "../../containers/list";
import Page from "../../containers/page";
import Facets from "../list/facets";
import BasePage from "../pagetypes/basePage";
import { SearchPage, deserializeScope, scopeLabel } from "./constants";

const Search = ({
  facets = empty.array,
  onDeselect = empty.func,
  onReset = empty.func,
  onSelect = empty.func,
  order,
  pagetypeList,
  rows = empty.array,
  selection: { documents = empty.array, loading } = empty.object,
  selectionLists = empty.array,
  selections = empty.object,
  handleFilter = empty.func,
  setFilter = empty.func,
  setOrder = empty.func,
  setPreview,
  setSelections = empty.func,
  properties,
  location: { query: { q: trefwoord = "" } = empty.object } = empty.object,
  params: { scope: scopeParam = "site" } = empty.object,
  saar: { name: saarName } = empty.object,
  saar,
  session: { user } = empty.object,
  doUseBiebItemsDialog = empty.func,
  selected,
  updateSelected,
  ...pageProps
}) => {
  const scope = deserializeScope(scopeParam);
  const title = saarName
    ? `Zoeken in ${scopeLabel(scope, saarName)}`
    : undefined;
  return (
    <div>
      <Helmet title={`${title} - Saar`} />

      <BasePage
        {...pageProps}
        id={-1}
        page={SearchPage}
        pageTitle={title}
        pageActions={{ busy: false, edit: false, dirty: false }}
        loading={loadingProgress(loading)}
        selectedTab={0}
        tabChanged={empty.func}
        user={user}
        tabs={[
          {
            label: "Resultaten",
            children: [
              <ItemList
                isSearch
                searchScope={scope}
                saar={saar}
                facets={facets}
                filter={trefwoord}
                highlightWord={trefwoord}
                key="search"
                list={documents}
                onDeselect={onDeselect}
                onSelect={onSelect}
                order={order}
                pagetypeList={pagetypeList}
                properties={properties}
                rows={rows}
                selectionLists={selectionLists}
                selections={selections}
                selected={selected}
                updateSelected={updateSelected}
                setFilter={setFilter}
                setPreview={setPreview}
                handleFilter={handleFilter}
                setOrder={setOrder}
                setSelections={setSelections}
                title="Resultaten"
                uid="search"
                debounceMs={1500}
                user={user}
                onUseBiebItems={doUseBiebItemsDialog}
                Page={Page}
              />,
            ],
          },
        ]}
        rightbar={{
          children: (
            <Card key="filters">
              {facets && facets.length > 0 && (
                <Facets
                  label="Filters"
                  facets={facets}
                  onSelect={onSelect}
                  onDeselect={onDeselect}
                  onReset={onReset}
                />
              )}
            </Card>
          ),
        }}
      />
    </div>
  );
};

export default Search;
