import classNames from "classnames";
import RaisedButton from "material-ui/RaisedButton";
import React, { ReactNode } from "react";

interface Props {
  title: string;
  onClick(event: React.MouseEvent<HTMLButtonElement>): void;
  className?: string;
  primary?: boolean;
  secondary?: boolean;
  disabled?: boolean;
  icon: ReactNode;
}

const RaisedIconButton = ({
  title,
  onClick,
  className,
  primary,
  secondary,
  disabled,
  icon,
}: Props): JSX.Element => (
  <span title={title} className={className && `${className}_span`}>
    <RaisedButton
      onClick={onClick}
      className={classNames("item-menu-button_btn", className)}
      icon={icon}
      primary={primary}
      secondary={secondary}
      disabled={disabled}
    />
  </span>
);

export default RaisedIconButton;
