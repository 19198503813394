import { RaisedButton } from "material-ui";
import React, { useCallback, useState } from "react";

import { PageActions } from "../../../../business/models";
import AddVeldenDialog from "./addVeldenDialog";

interface Props {
  clusterId: number;
  pageActions: PageActions;
}

const AddVelden = (props: Props): JSX.Element => {
  const [open, setOpen] = useState(false);
  const toggleOpen = useCallback(() => setOpen(!open), [open]);

  return (
    <>
      <RaisedButton
        label="Velden toevoegen"
        secondary
        onClick={toggleOpen}
        style={{ marginLeft: 16 }}
      />
      {open && (
        <AddVeldenDialog
          itemId={props.pageActions.pageId}
          clusterId={props.clusterId}
          pageActions={props.pageActions}
          onClose={toggleOpen}
        />
      )}
    </>
  );
};

export default AddVelden;
