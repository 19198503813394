import classNames from "classnames";
import { List, ListItem } from "material-ui/List";
import RaisedButton from "material-ui/RaisedButton";
import PropTypes from "prop-types";
import React from "react";

import { styleShape } from "../../business/prop-types";
import { buttonStyle, listItemStyle } from "./addButton";

const ClusterAddButton = ({
  className,
  disabled,
  innerStyle,
  label,
  onClick,
  style,
}) => (
  <div
    className={classNames(
      "cluster-add-button",
      {
        "disabled-cluster-button": disabled,
      },
      className
    )}
    style={{ position: "relative", ...style }}
  >
    <List style={{ padding: 0, ...innerStyle }}>
      <ListItem disabled style={listItemStyle}>
        <RaisedButton
          label={label}
          onClick={() => onClick()}
          disabled={disabled}
          secondary
          style={buttonStyle}
        />
      </ListItem>
    </List>
  </div>
);

ClusterAddButton.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
  innerStyle: styleShape,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  style: styleShape,
};

ClusterAddButton.defaultProps = {
  disabled: false,
};

export default ClusterAddButton;
