import { compact, includes, map, uniq, without } from "lodash/fp";
import { Checkbox, FlatButton, IconButton, Subheader } from "material-ui";
import ContentAdd from "material-ui/svg-icons/content/add";
import React, {
  ChangeEvent,
  ClipboardEvent,
  FormEvent,
  useCallback,
  useState,
} from "react";
import { batch } from "react-redux";

import { PageActions } from "../../../../business/models";
import Dialog from "../../../../containers/dialog";
import { flown } from "../../../../lodash";
import { splitChar } from "../../../../utils";
import HintCard from "../../../material/HintCard";

export interface Props {
  itemId: number;
  clusterId: number;
  pageActions: PageActions;
  onClose: () => void;
}

const emptyVelden: string[] = [];

const NewVeldBox = ({
  naam,
  checked,
  onClick,
}: {
  naam: string;
  checked: boolean;
  onClick: (name: string, isInputChecked: boolean) => void;
}) => {
  const handleCheck = useCallback(
    (event: unknown, isInputChecked: boolean) => {
      onClick(naam, isInputChecked);
    },
    [naam, onClick]
  );

  return (
    <Checkbox
      value={naam}
      label={naam}
      checked={checked}
      onCheck={handleCheck}
    />
  );
};

const AddVeldenDialog = ({
  itemId,
  clusterId,
  pageActions: {
    requireClusters,
    clusterAddRequiredClusterAlreadyPresent,
    fieldAdd,
    stashCluster,
  },
  onClose,
}: Props): JSX.Element => {
  const [input, updateInput] = useState<string>("");
  const [newVelden, updateVelden] = useState<string[]>(emptyVelden);

  const handleAddClusters = useCallback(() => {
    const cluster = { clusterId, name: "Veld" };
    const revert = { itemId, clusterId: 0, name: "Naam" };
    requireClusters(cluster).then(() => {
      batch(() =>
        newVelden.forEach((newVeld) => {
          clusterAddRequiredClusterAlreadyPresent(cluster);
          fieldAdd({ ...revert, revert, value: newVeld });
          stashCluster();
        })
      );
    });

    onClose();
  }, [
    requireClusters,
    clusterAddRequiredClusterAlreadyPresent,
    clusterId,
    fieldAdd,
    itemId,
    newVelden,
    onClose,
    stashCluster,
  ]);

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      const newInput = event.target.value;
      if (newInput === input) {
        return;
      }

      updateInput(newInput);
    },
    [input]
  );

  const handlePaste = useCallback(
    (event: ClipboardEvent) => {
      const pasted = event.clipboardData.getData("text");
      if (
        splitChar.test(pasted) &&
        window.confirm("Wilt u de geplakte tekst omzetten naar losse velden?")
      ) {
        event.preventDefault();
        const newVeldenFromInput = pasted.split(splitChar);
        updateVelden(
          flown(
            [...newVelden, ...newVeldenFromInput],
            map((str: string) => str.trim()),
            uniq,
            compact
          )
        );

        // reset input
        updateInput("");
      }
    },
    [newVelden]
  );

  const handleSubmit = useCallback(
    (event: FormEvent) => {
      event.preventDefault();
      const newVeldenFromInput = input.split(splitChar);
      updateVelden(
        flown(
          [...newVelden, ...newVeldenFromInput],
          map((str: string) => str.trim()),
          uniq,
          compact
        )
      );

      // reset input
      updateInput("");
    },
    [input, newVelden]
  );

  const handleClick = useCallback(
    (name: string, isInputChecked: boolean) => {
      if (!isInputChecked) {
        // remove
        updateVelden(without([name])(newVelden));
      }
    },
    [newVelden]
  );

  return (
    <Dialog
      open
      autoScrollBodyContent
      title="Applicatievelden toevoegen"
      actions={[
        <FlatButton
          key="cancel"
          label="Annuleren"
          secondary
          onClick={onClose}
        />,
        <FlatButton
          key="ok"
          label="Toevoegen"
          primary
          disabled={newVelden.length === 0}
          onClick={handleAddClusters}
        />,
      ]}
    >
      {newVelden.length > 0 && (
        <Subheader style={{ paddingLeft: 0 }}>
          Nieuw toegevoegde velden
        </Subheader>
      )}
      {flown(
        newVelden,
        map((naam: string) => (
          <NewVeldBox
            key={naam}
            naam={naam}
            checked={includes(naam)(newVelden)}
            onClick={handleClick}
          />
        ))
      )}
      <form
        method="post"
        style={{ display: "flex", alignItems: "baseline" }}
        onSubmit={handleSubmit}
      >
        <textarea
          placeholder="Naam nieuw applicatieveld"
          className="textarea-mui-look"
          onPaste={handlePaste}
          onChange={handleInputChange}
          value={input}
          rows={5}
        />
        <IconButton type="submit" disabled={input === ""}>
          <ContentAdd />
        </IconButton>
      </form>

      <HintCard
        primaryText="Nieuwe applicatievelden toevoegen"
        secondaryText="Voer een naam van een applicatieveld in. Gebruik een komma of een nieuwe regel voor het toevoegen van meerdere applicatievelden. Bijvoorbeeld 'Veld A, Veld B' resulteert in 2 nieuwe applicatievelden."
      />
    </Dialog>
  );
};

export default AddVeldenDialog;
