import empty from "empty";
import { dropRight, last } from "lodash/fp";
import FlatButton from "material-ui/FlatButton";
import { MenuItem } from "material-ui/Menu";
import SelectField from "material-ui/SelectField";
import PropTypes from "prop-types";
import React, { useCallback, useMemo, useState } from "react";
import { compose, setDisplayName, withHandlers, withState } from "recompose";

import {
  koppelingApplicatieShape,
  koppelingRowShape,
} from "../../../business/prop-types";
import { Side } from "../../../business/relations";
import Dialog from "../../../containers/dialog";
import {
  alreadySelected,
  filterByEntiteit,
  getUsedFields,
  searchInVelden,
} from "./functions";
import KoppelingSelectField from "./koppelingSelectField";
import KoppelingSelection from "./koppelingSelection";
import SearchBox from "./searchBox";

const initialGrouping = Object.freeze({ from: "list", to: "list" });

const GroupingSelect = ({ onChange, value }) => (
  <SelectField
    value={value}
    autoWidth
    floatingLabelText="Groepering"
    onChange={onChange}
  >
    <MenuItem value="grouped" primaryText="Naar entiteit" />
    <MenuItem value="list" primaryText="Platte weergave" />
  </SelectField>
);

const GegevenDialog = ({
  closeDialog,
  save,
  from,
  to,
  selectLeftItem,
  selectRightItem,
  deleteAt,
  state: { left, right, selected } = empty.object,
  koppelingVeldenRelations = empty.object,
}) => {
  // groepering
  const [grouping, setGrouping] = useState(initialGrouping);
  const handleFromGrouping = useCallback(
    (_event, _index, value) => {
      setGrouping({ ...grouping, from: value });
    },
    [grouping]
  );
  const handleToGrouping = useCallback(
    (_event, _index, value) => {
      setGrouping({ ...grouping, to: value });
    },
    [grouping]
  );

  const fromSet = useMemo(
    () => (grouping.from === "list" ? from : filterByEntiteit(from)),
    [from, grouping.from]
  );
  const toSet = useMemo(
    () => (grouping.to === "list" ? to : filterByEntiteit(to)),
    [grouping.to, to]
  );

  const [search, setSearch] = useState("");
  const filtered = useMemo(
    () =>
      search
        ? {
            from: searchInVelden(fromSet, search),
            to: searchInVelden(toSet, search),
          }
        : { from: fromSet, to: toSet },
    [search, fromSet, toSet]
  );

  const [usedFieldsLeft, usedFieldsRight] = useMemo(() => {
    const usedFieldsLeft = getUsedFields(Side.left)(koppelingVeldenRelations);
    const usedFieldsRight = getUsedFields(Side.right)(koppelingVeldenRelations);
    return [usedFieldsLeft, usedFieldsRight];
  }, [koppelingVeldenRelations]);

  const forbiddenFields = useMemo(
    () => alreadySelected(koppelingVeldenRelations, selected),
    [koppelingVeldenRelations, selected]
  );

  return (
    <Dialog
      className="koppeling-gegeven-dialog"
      title={`Gegevensoverdracht van ‘${from.page.title}’ naar ‘${to.page.title}’`}
      open
      autoScrollBodyContent
      contentStyle={{ minWidth: 960, maxWidth: "100vw" }}
      actions={[
        <FlatButton
          key="close"
          secondary
          label="Annuleren"
          onClick={closeDialog}
        />,
        <FlatButton
          key="save"
          primary
          label="Opslaan"
          disabled={selected.length === 0 || !left}
          onClick={save}
        />,
      ]}
    >
      <div className="koppeling-dialog-inner koppeling-toolbar">
        <div className="koppeling-dialog-left">
          <GroupingSelect value={grouping.from} onChange={handleFromGrouping} />
        </div>
        <div className="koppeling-dialog-middle">
          <SearchBox search={search} onSearch={setSearch} />
        </div>
        <div className="koppeling-dialog-right">
          <GroupingSelect value={grouping.to} onChange={handleToGrouping} />
        </div>
      </div>
      <div className="koppeling-dialog-inner">
        <div className="koppeling-dialog-left">
          <KoppelingSelectField
            disabled={!left}
            application={filtered.from}
            usedFields={usedFieldsLeft}
            onChange={selectLeftItem}
          />
        </div>
        <div className="koppeling-dialog-middle">
          <KoppelingSelection selected={selected} onDelete={deleteAt} />
        </div>
        <div className="koppeling-dialog-right">
          <KoppelingSelectField
            disabled={!right}
            application={filtered.to}
            usedFields={usedFieldsRight}
            forbiddenFields={forbiddenFields}
            onChange={selectRightItem}
          />
        </div>
      </div>
    </Dialog>
  );
};

GegevenDialog.propTypes = Object.freeze({
  closeDialog: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  from: koppelingApplicatieShape.isRequired,
  to: koppelingApplicatieShape.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  state: PropTypes.shape({
    left: PropTypes.bool.isRequired,
    right: PropTypes.bool.isRequired,
    selected: PropTypes.arrayOf(koppelingRowShape.isRequired).isRequired,
  }).isRequired,
  selectLeftItem: PropTypes.func.isRequired,
  selectRightItem: PropTypes.func.isRequired,
  deleteAt: PropTypes.func.isRequired,
});

const initialState = Object.freeze({
  left: true,
  right: false,
  selected: empty.array,
});

export default compose(
  setDisplayName("GegevenDialog"),
  withState(
    "state",
    "updateState",
    ({ dialog: { pageClusterId, selected } }) => ({
      ...initialState,
      pageClusterId,
      selected,
    })
  ),
  withHandlers({
    selectLeftItem:
      ({ state: { pageClusterId, selected }, updateState }) =>
      (field) =>
        updateState({
          pageClusterId,
          left: false,
          right: true,
          selected: [...selected, { left: field }],
        }),

    selectRightItem:
      ({ state: { pageClusterId, selected }, updateState }) =>
      (field) =>
        updateState({
          pageClusterId,
          left: true,
          right: false,
          selected: [
            ...dropRight(1)(selected),
            { ...last(selected), right: field },
          ],
        }),

    deleteAt:
      ({ state: { pageClusterId, left, right, selected }, updateState }) =>
      (at) => {
        if (at < selected.length) {
          updateState({
            pageClusterId,
            left,
            right,
            selected: [...selected.slice(0, at), ...selected.slice(at + 1)],
          });
        }
      },

    save:
      ({ itemId, save, state, updateState }) =>
      () => {
        updateState({ ...state, left: false, right: false });
        save(itemId, state.pageClusterId, state.selected);
      },
  })
)(GegevenDialog);
