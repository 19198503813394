import classNames from "classnames";
import { IconButton } from "material-ui";
import ExpandLess from "material-ui/svg-icons/navigation/expand-less";
import ExpandMore from "material-ui/svg-icons/navigation/expand-more";
import {
  Children,
  MouseEvent,
  PropsWithChildren,
  ReactElement,
  cloneElement,
  useCallback,
  useState,
} from "react";

import { TreeItemProps, TreeProps } from "./models";

const TreeItemContent = ({ children }: PropsWithChildren<{}>) => (
  <div className="tree-item-content">{children}</div>
);

const TreeItem = ({
  label,
  content,
  children,
  labelClickable,
  leftCheckbox,
  leftCheckboxTitle,
  rightIcon,
  onClick,
  containerStyle,
  labelStyle,
}: TreeItemProps) => {
  const [open, setOpen] = useState(false);
  const handleToggle = useCallback(
    (event: MouseEvent) => {
      if (event) {
        event.stopPropagation();
      }

      if (children) {
        setOpen((open) => !open);
      } else if (onClick) {
        onClick();
      }
    },
    [children, onClick]
  );

  const itemClickable = labelClickable && (children || onClick);

  return (
    <div className="tree-item">
      <div className="tree-item-header-outer" style={containerStyle}>
        {leftCheckbox && (
          <div title={leftCheckboxTitle} className="tree-item-checkbox">
            {leftCheckbox}
          </div>
        )}
        <div
          className={classNames("tree-item-header", {
            "tree-item-clickable": itemClickable,
          })}
          onClick={itemClickable ? handleToggle : undefined}
        >
          <div className="tree-item-label" style={labelStyle}>
            {label}
          </div>
          {rightIcon}
          {(children || content) && (
            <IconButton
              onClick={handleToggle}
              style={{ padding: 0, width: 24, height: 24 }}
              title={open ? "Sluiten" : "Openen"}
            >
              {open ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          )}
        </div>
      </div>

      {open && content && <TreeItemContent>{content}</TreeItemContent>}
      {open && children && (
        <div className="tree-item-children">
          {Children.map(children, (child) =>
            !child
              ? null
              : cloneElement(child as ReactElement, { labelClickable })
          )}
        </div>
      )}
    </div>
  );
};

const Tree = ({ children, ...props }: TreeProps) => {
  return (
    <div className="saar-tree">
      {Children.map(children, (child) =>
        !child ? null : cloneElement(child as ReactElement, props)
      )}
    </div>
  );
};

export { Tree, TreeItem, TreeItemContent };
