/* eslint  react/prop-types: off */
import empty from "empty";
import { includes, map, some, sortBy } from "lodash/fp";
import MenuItem from "material-ui/MenuItem";
import SelectField from "material-ui/SelectField";
import PropTypes from "prop-types";
import React from "react";
import {
  compose,
  defaultProps,
  setDisplayName,
  setPropTypes,
  withHandlers,
} from "recompose";

import { flown } from "../../lodash";
import { Fork } from "../../recompose.contrib";
import { arrayFlatMap, isNonEmptyArray } from "../../utils";
import { propTypes, withDefaultPageActions } from "./ggvtyp";
import Werkdagen, { test } from "./werkdagen";

const createMenuItems = (items, selectedValues, depth) =>
  items.map(({ items, id, value: itemValue }) => {
    const hasChildren = items && items.length > 0;
    const newDepth = depth + 1;
    return [
      <MenuItem
        key={id}
        insetChildren
        checked={some({ selectionId: id })(selectedValues)}
        value={id}
        disabled={hasChildren}
        primaryText={`${Array(depth).join("\xa0\xa0\xa0")}${itemValue}`}
      />,
      hasChildren && createMenuItems(items, selectedValues, newDepth),
    ];
  });

const flatItems = ({ id, value, items = empty.array }) => [
  { selectionId: id, value },
  ...arrayFlatMap(flatItems)(items),
];

/**
 * Gegevenstype 'Picklist' (4)
 */
const NativePicklist = ({
  value = empty.array,
  selectionList: { items } = empty.object,
  pageActions: { edit = false },
  field: {
    isEmpty = true,
    definition: { explanation, controlPlaceholder } = empty.object,
  } = empty.object,
  className,
  selectItem,
  handleRenderer,
}) => {
  if (edit && isNonEmptyArray(items)) {
    return (
      <SelectField
        floatingLabelText={explanation}
        floatingLabelFixed
        hintText={controlPlaceholder}
        multiple
        fullWidth
        value={value.map(({ selectionId }) => selectionId)}
        onChange={selectItem}
        selectionRenderer={handleRenderer}
      >
        {createMenuItems(items, value, 1)}
      </SelectField>
    );
  }
  return isEmpty || value.length === 0 ? null : (
    <div className={className}>
      {flown(
        value,
        sortBy(["value"]),
        map(({ selectionId, value }) => <span key={selectionId}>{value}</span>)
      )}
    </div>
  );
};

const PicklistComponent = (props) => (
  <Fork test={test} left={Werkdagen} right={NativePicklist} {...props} />
);

const Picklist = compose(
  setDisplayName("Picklist"),
  setPropTypes({
    ...propTypes,
    field: PropTypes.shape({
      value: PropTypes.arrayOf(
        PropTypes.shape({
          selectionId: PropTypes.number.isRequired,
          value: PropTypes.string.isRequired,
          alias: PropTypes.string,
        }).isRequired
      ),
    }).isRequired,
  }),
  defaultProps({
    className: "picklist",
  }),
  withDefaultPageActions(),
  withHandlers({
    selectItem:
      ({ selectionList: { items } = empty.object, setValue }) =>
      (event, index, values) => {
        const selectionItems = arrayFlatMap(flatItems)(items);
        const picked = selectionItems.filter(({ selectionId }) =>
          includes(selectionId)(values)
        );
        setValue({
          value: picked,
          string: picked.map((v) => v.value).join(";"),
        });
      },

    handleRenderer:
      ({ value }) =>
      () =>
        sortBy(["value"])(value)
          .map((s) => s.value)
          .join(", "),
  })
)(PicklistComponent);

export default Picklist;
