import { loadingPresent } from "async-lifecycle";
import { joinLoading } from "async-lifecycle/volatile";
import empty from "empty";
import {
  defaultTo,
  filter,
  find,
  flow,
  get,
  has,
  includes,
  map,
  pickBy,
  reduce,
  reject,
  some,
} from "lodash/fp";
import { branch, compose, renderComponent, setDisplayName } from "recompose";
import { createSelector } from "reselect";

import {
  add,
  addNew,
  addRelations,
  addUsing,
  clear,
  clustersForRelationsAction,
  clustersForRelationsClearAction,
  initRelations,
  personRoleFilterAction,
  updateFilter,
} from "../actions/relation";
import { setPreviewAction } from "../actions/ui";
import { Side } from "../business/relations";
import AddRelation from "../components/relations/addRelation";
import { flown } from "../lodash";
import { typesSelector } from "../selectors/page";
import { scopeSelector } from "../selectors/relations";
import { dispatchWrap } from "../utils";
import connect from "./connect";
import AddKerngegevenRelation from "./relations/addKerngegevenRelation";

const filterItems = (
  { loading, items = empty.array },
  filter = empty.array
) => ({ loading, items: items.filter((p) => includes(p.itemId)(filter)) });

const toggleStatus = ({
  form: { addRelation: { useFilter = true } = empty.object } = empty.object,
}) => useFilter;

const context = ({
  form: {
    addRelation: { context = empty.object } = empty.object,
  } = empty.object,
}) => context;

const type = createSelector(
  [typesSelector, context],
  (types, { type: { id } = empty.object }) => find({ id })(types)
);

const relationSelector = (
  { data: { relations = empty.object } = empty.object },
  { id }
) => relations[id];

const existingRelationSelector = createSelector(
  [type, relationSelector],
  (type = empty.object, relationObj = empty.object) =>
    relationObj && relationObj.relations && type && type.id
      ? relationObj.relations.filter(
          ({ relationTypeId }) => relationTypeId === type.id
        )
      : empty.array
);

const peers = ({
  form: {
    addRelation: {
      peers = empty.object,
      filter: { source, itemIds = empty.array } = empty.object,
      useFilter = true,
    } = empty.object,
  } = empty.object,
}) => ({ peers, source, useFilter, itemIds });

const appendRelations = (
  existing = empty.array,
  { items = empty.array, loading }
) => ({
  items: items.map((item) => ({
    ...item,
    relations: filter(
      ({
        left: { itemId: leftId } = empty.object,
        right: { itemId: rightId } = empty.object,
        using = empty.array,
      }) =>
        leftId === item.itemId ||
        rightId === item.itemId ||
        using.find(({ itemId: usingId }) => usingId === item.itemId) !==
          undefined
    )(existing),
  })),
  loading,
});

const using = ({
  form: {
    addRelation: {
      using = empty.object,
      filter: { source, itemIds = empty.array } = empty.object,
      useFilter = true,
    } = empty.object,
  } = empty.object,
}) => ({ using, source, useFilter, itemIds });

const filterSource = ({
  form: {
    addRelation: { filter: { source } = empty.object } = empty.object,
  } = empty.object,
}) => source;

const formLoading = ({
  form: {
    addRelation: { add: { loading = "" } = empty.object } = empty.object,
  } = empty.object,
}) => loading;

const selfSide = createSelector(
  [type, context],
  (type = empty.object, { side }) =>
    side ? { ...type[Side[side]] } : undefined
);

const peerSide = createSelector(
  [type, context],
  (type = empty.object, { side }) =>
    side
      ? {
          ...type[side === Side.using ? Side[Side.left] : Side.rev(Side[side])],
        }
      : undefined
);

const usingSide = createSelector(
  [type, context],
  (type = empty.object, { side }) =>
    side
      ? { ...type[Side[side === Side.using ? Side.right : Side.using]] }
      : undefined
);

const typesObject = createSelector(
  [selfSide, peerSide, usingSide],
  (
    {
      pagetype: selftype,
      label: peerLabel,
      labelSpec: usingLabel,
    } = empty.object,
    { pagetype: peertype } = empty.object,
    { pagetype: usingtype } = empty.object
  ) => ({
    selftype,
    peertype,
    peerLabel,
    usingtype,
    usingLabel,
  })
);

const persoonRolComplement = (pagetype) =>
  pagetype === "rol" ? "persoon" : pagetype === "persoon" ? "rol" : undefined;

const filterScope = createSelector(
  [filterSource, typesObject],
  (source, { peertype, usingtype }) =>
    peertype && peertype === persoonRolComplement(source)
      ? "peer"
      : usingtype && usingtype === persoonRolComplement(source)
      ? "using"
      : undefined
);

const peersFiltered = createSelector(
  [peers, typesObject],
  ({ peers, source, useFilter, itemIds }, { selftype, peertype }) =>
    useFilter &&
    source &&
    ((source === "rol" && peertype === "persoon") ||
      (selftype === "rol" && peertype === "persoon"))
      ? filterItems(peers, itemIds)
      : peers
);

const peersSelector = createSelector(
  [existingRelationSelector, peersFiltered],
  appendRelations
);

const usingFiltered = createSelector(
  [using, typesObject],
  ({ using, source, useFilter, itemIds }, { selftype, usingtype }) =>
    useFilter &&
    source &&
    ((source === "persoon" && usingtype === "rol") ||
      (selftype === "rol" && usingtype === "persoon"))
      ? filterItems(using, itemIds)
      : using
);

const usingSelector = createSelector(
  [existingRelationSelector, usingFiltered],
  appendRelations
);

const entiteitenSelector = createSelector(
  [
    ({ data: { entiteiten } }) => entiteiten,
    ({ data: { addLegoblok } }) => addLegoblok,
    ({ data: { pages } }, { id }) => pages[id] || empty.object,
  ],
  (entiteiten, addLegoblok, page) => {
    const loading = flown(
      [entiteiten, addLegoblok, page],
      map("loading"),
      reduce(joinLoading, "success")
    );
    if (!loadingPresent(loading)) {
      return empty.object;
    }

    const bieb = flown(
      addLegoblok.structures,
      some(({ id }) => id === page.siteId)
    );
    const list = (
      bieb
        ? filter({
            bieb: page.siteId,
          })
        : reject(has("bieb"))
    )(entiteiten.entiteiten);
    return {
      loading,
      entiteiten: list,
    };
  }
);

const verantwoordingenSelector = createSelector(
  [
    ({ data: { verantwoordingen } }) => verantwoordingen,
    ({ data: { addLegoblok } }) => addLegoblok,
    ({ data: { pages } }, { id }) => pages[id] || empty.object,
  ],
  (verantwoordingen, addLegoblok, page) => {
    const loading = flown(
      [verantwoordingen, addLegoblok, page],
      map("loading"),
      reduce(joinLoading, "success")
    );
    if (!loadingPresent(loading)) {
      return empty.object;
    }

    const bieb = flown(
      addLegoblok.structures,
      some(({ id }) => id === page.siteId)
    );
    const list = (
      bieb
        ? filter({
            bieb: page.siteId,
          })
        : reject(has("bieb"))
    )(verantwoordingen.list);
    return {
      loading,
      verantwoordingen: list,
    };
  }
);

const fieldDefinitionsSelector = flow(
  get("data.fieldDefinitionsByPagetype"),
  defaultTo(empty.object)
);

const fieldDefinitionsByPagetypeSelector = createSelector(
  [type, fieldDefinitionsSelector],
  ({ pagetype } = empty.object, fieldDefinitions) =>
    flown(
      fieldDefinitions[pagetype],
      get("page"),
      pickBy((f) => get("iproxType")(f) === "field")
    )
);

const clustersSelector = createSelector(
  [
    ({ sagas: { clustersForRelations = empty.object } = empty.object }) =>
      clustersForRelations,
  ],
  (clustersForRelations) => ({
    clusters: clustersForRelations.value ?? empty.array,
    loading: clustersForRelations.status?.loading ?? false,
  })
);

const initialFormSelector = get("form.addRelation.form") || empty.object;

const biebStructuresSelector = createSelector(
  [flow(get("data.addLegoblok.structures"), defaultTo(empty.array))],
  (biebStructures) => biebStructures.map((bieb) => ({ ...bieb, isBieb: true }))
);

const structuresSelector = createSelector(
  [
    biebStructuresSelector,
    flow(get("session.miniSaar"), defaultTo(empty.object)),
    flow(get("data.miniSaars.data"), defaultTo(empty.array)),
  ],
  (biebStructures, { siteId }, miniSaars) => {
    const { name } = flown(
      miniSaars,
      find({ siteId }),
      defaultTo(empty.object)
    );

    return name ? [{ id: siteId, name }, ...biebStructures] : empty.array;
  }
);

const biebStructureIdsSelector = createSelector(
  [biebStructuresSelector],
  (biebStructures) => flown(biebStructures, map("id"))
);

const relationScopeSelector = createSelector(
  [
    (_, { siteId }) => siteId,
    biebStructuresSelector,
    get("data.miniSaars.data"),
  ],
  (siteId, biebStructures, miniSaars) =>
    scopeSelector({
      page: { siteId },
      biebStructures,
      miniSaars,
    })
);

const connector = createSelector(
  [
    peersSelector,
    usingSelector,
    type,
    relationScopeSelector,
    context,
    formLoading,
    typesObject,
    toggleStatus,
    filterScope,
    entiteitenSelector,
    verantwoordingenSelector,
    fieldDefinitionsByPagetypeSelector,
    clustersSelector,
    initialFormSelector,
    biebStructureIdsSelector,
    structuresSelector,
  ],
  (
    peers,
    using,
    type,
    scope,
    { side },
    formLoading,
    types,
    toggleStatus,
    filterScope,
    entiteiten,
    verantwoordingen,
    metaFieldDefinitions,
    clusterData,
    initialForm,
    biebStructures,
    structures
  ) => ({
    types,
    peers,
    using,
    type,
    scope,
    side,
    formLoading,
    toggleStatus,
    filterScope,
    entiteiten,
    verantwoordingen,
    metaFieldDefinitions,
    clusters: clusterData.clusters,
    clustersLoading: clusterData.loading,
    initialForm,
    biebStructures,
    structures,
  })
);

const dispatcher = (dispatch) => ({
  refreshRelations: dispatchWrap(initRelations, dispatch),
  add: dispatchWrap(add, dispatch),
  addRelations: dispatchWrap(addRelations, dispatch),
  addNew: dispatchWrap(addNew, dispatch),
  clear: dispatchWrap(clear, dispatch),
  personRoleFilter: (source, itmIdt) =>
    dispatch(personRoleFilterAction(source, itmIdt)),
  addUsing: dispatchWrap(addUsing, dispatch),
  toggleFilter: dispatchWrap(updateFilter, dispatch),
  setPreview: dispatchWrap(setPreviewAction, dispatch),
  clustersForRelations: dispatchWrap(clustersForRelationsAction, dispatch),
  clustersForRelationsClear: dispatchWrap(
    clustersForRelationsClearAction,
    dispatch
  ),
});

export default compose(
  setDisplayName("AddRelation"),
  connect(connector, dispatcher),
  branch(
    ({ type: { alias } = empty.object }) =>
      alias === "activiteit_kerngegeven" ||
      alias === "bedrijfsproces_kerngegeven",
    renderComponent(AddKerngegevenRelation)
  )
)(AddRelation);
