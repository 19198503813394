import { Action } from "redux";

import { SiteLink, emptyArray } from "../models";

interface ResultSetValue {
  itemId: number;
  slug: string;
  title: string;
  items: SiteLink[];
}

const isResultSetValue = (value: any): value is ResultSetValue =>
  value &&
  typeof value.itemId === "number" &&
  value.items &&
  Array.isArray(value.items);

const emptyResultSet: ResultSetValue = {
  itemId: -1,
  slug: "",
  title: "",
  items: emptyArray(),
};

const resultSetSetAction = "UI_RESULTSET_SET";

export const setResultSet = (
  itemId: number,
  slug: string,
  title: string,
  items: SiteLink[]
): Action<string> & { payload: ResultSetValue } => ({
  type: resultSetSetAction,
  payload: { itemId, slug, title, items },
});

export const resultSetReducer = (
  state: ResultSetValue | undefined,
  action: Action<string> & { payload?: any }
): ResultSetValue => {
  return action.type === "MINISAAR_INIT"
    ? emptyResultSet
    : resultSetSetAction === action.type && isResultSetValue(action.payload)
    ? action.payload
    : state ?? emptyResultSet;
};

export const toSiteLink = (row: {
  itmIdt: string;
  lbl: string;
  pagetype: string;
  sitIdt: string;
  slug: string;
}): SiteLink => ({
  itemId: Number(row.itmIdt),
  structureId: Number(row.sitIdt),
  type: row.pagetype,
  label: row.lbl,
  slug: row.slug,
});

export const selectResultSet = (state: { ui: { resultSet: ResultSetValue } }) =>
  state.ui.resultSet;
