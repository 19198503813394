import { IconButton, IconMenu, MenuItem, Subheader } from "material-ui";
import Delete from "material-ui/svg-icons/action/delete";
import Add from "material-ui/svg-icons/content/add";
import MoreVert from "material-ui/svg-icons/navigation/more-vert";

export interface ToolMenuProps {
  disabled?: boolean;
  deleteView?(): void;
  saveNewView(): void;
}

const ToolMenu = ({ disabled, deleteView, saveNewView }: ToolMenuProps) => {
  return (
    <IconMenu
      iconButtonElement={
        <IconButton disabled={disabled}>
          <MoreVert />
        </IconButton>
      }
      anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
      targetOrigin={{ horizontal: "left", vertical: "top" }}
      {...{ title: "Acties met deze weergave" }}
    >
      <Subheader>Acties met deze weergave</Subheader>
      <MenuItem
        primaryText="Opslaan als nieuw"
        onClick={saveNewView}
        rightIcon={<Add />}
      />
      {deleteView && (
        <MenuItem
          primaryText="Verwijderen"
          onClick={deleteView}
          rightIcon={<Delete />}
        />
      )}
    </IconMenu>
  );
};

export default ToolMenu;
