import { loadingPresent } from "async-lifecycle";
import empty from "empty";
import { flow, get } from "lodash/fp";
import React from "react";
import { connect } from "react-redux";
import {
  branch,
  compose,
  pure,
  renderComponent,
  setDisplayName,
} from "recompose";

import { itemShape } from "../../business/prop-types";
import {
  enhanceComponent,
  getFallbackComponent,
} from "../../components/pagetypes";
import DefaultPage from "../../components/pagetypes/defaultPage";
import EmptyPage from "../../components/pagetypes/emptyPage";
import { withHoc } from "../../recompose.contrib";
import { presentationSelector } from "../../selectors/page";

const Fallback = ({
  page: { page: { pagetype } = empty.object } = empty.object,
  page,
  ...props
}) => {
  const Component = getFallbackComponent(pagetype);
  return <Component page={page} {...props} />;
};
Fallback.propTypes = {
  page: itemShape,
};

export default compose(
  setDisplayName("DefaultPage"),
  pure,
  connect((state, props) => ({
    presentation: presentationSelector(state, props),
  })),
  branch(
    ({ presentation: { loading } = empty.object }) =>
      loading && !loadingPresent(loading),
    renderComponent(EmptyPage)
  ),
  branch(
    ({ presentation: { zones = empty.array, loading } = empty.object }) =>
      zones.length === 0 && (!loading || loadingPresent(loading)),
    renderComponent(Fallback)
  ),
  /* If page shows in "preview-dialog", don't enhance it */
  branch(({ open = false }) => open, renderComponent(DefaultPage)),
  withHoc(flow(get("page.page.pagetype"), enhanceComponent))
)(DefaultPage);
