/* eslint  react/prop-types: off */
import empty from "empty";
import { get } from "lodash/fp";
import IconButton from "material-ui/IconButton";
import ActionHighlightOff from "material-ui/svg-icons/action/highlight-off";
import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import {
  compose,
  defaultProps,
  mapProps,
  setDisplayName,
  setPropTypes,
  withHandlers,
} from "recompose";
import { createSelector } from "reselect";

import VerwijzingSearchDialog from "../../containers/verwijzingSearchDialog";
import { PathLink } from "../relation.pathlink";
import { propTypes, withDefaultPageActions } from "./ggvtyp";

const verwijzingEditSelector = createSelector(
  [get("data.pages"), (_, props) => get("pageActions.pageId")(props)],
  (pages, itemId) => {
    const page = get(itemId)(pages);
    if (!page) {
      return empty.object;
    }

    const { page: { pagetype } = empty.object, siteId } = page;
    return {
      siteId,
      isLegoblok: pagetype === "legoblok",
    };
  }
);

const VerwijzingEdit = (props) => {
  const {
    isEmpty,
    dirty,
    value: { label, linkId } = empty.object,
    setValue,
    paginatypen,
    clusterName,
    sitesInclude,
    sitesExclude,
    handleDelete,
  } = props;
  const { siteId, isLegoblok } = useSelector((state) =>
    verwijzingEditSelector(state, props)
  );

  return !linkId ? (
    <VerwijzingSearchDialog
      onSave={setValue}
      paginatypen={paginatypen}
      clusterName={clusterName}
      sitesInclude={isLegoblok && siteId ? [siteId] : sitesInclude}
      sitesExclude={sitesExclude}
    />
  ) : (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <PathLink
        path={`page/${linkId}`}
        style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
        title={label}
      >
        {label || linkId}
      </PathLink>
      <IconButton
        disabled={isEmpty && !dirty}
        style={{ display: "inline-block" }}
        onClick={handleDelete}
        title="Verwijder verwijzing"
      >
        <ActionHighlightOff />
      </IconButton>
    </div>
  );
};

/**
 * Gegevenstype 'Verwijzing' (13) en 'Koppeling' (18)
 */
const Verwijzing = ({
  field: { isEmpty = true } = empty.object,
  value: { linkId, label } = empty.object,
  value,
  dirty,
  setValue,
  pageActions: { edit },
  pageActions,
  className,
  paginatypen = empty.array,
  clusterName,
  sitesInclude,
  sitesExclude,
  handleDelete,
}) =>
  edit ? (
    <VerwijzingEdit
      isEmpty={isEmpty}
      dirty={dirty}
      value={value}
      setValue={setValue}
      paginatypen={paginatypen}
      clusterName={clusterName}
      sitesInclude={sitesInclude}
      sitesExclude={sitesExclude}
      handleDelete={handleDelete}
      pageActions={pageActions}
    />
  ) : isEmpty ? null : (
    <PathLink className={className} path={`page/${linkId}`}>
      {label || linkId}
    </PathLink>
  );

export default compose(
  setDisplayName("Verwijzing"),
  setPropTypes({
    ...propTypes,
    field: PropTypes.shape({
      value: PropTypes.shape({
        linkId: PropTypes.number.isRequired,
        label: PropTypes.string,
      }),
    }).isRequired,
    paginatypen: PropTypes.arrayOf(PropTypes.string.isRequired),
    clusterName: PropTypes.string,
    sitesInclude: PropTypes.arrayOf(PropTypes.number.isRequired),
    sitesExclude: PropTypes.arrayOf(PropTypes.number.isRequired),
  }),
  defaultProps({
    className: "verwijzing",
  }),
  withDefaultPageActions(),
  mapProps(
    ({
      paginatypen,
      field: {
        definition: { pagetypes = empty.array } = empty.object,
      } = empty.object,
      field,
      ...rest
    }) => ({
      ...rest,
      field,
      paginatypen:
        paginatypen && paginatypen.length > 0 ? paginatypen : pagetypes,
    })
  ),
  withHandlers({
    handleDelete:
      ({ setValue }) =>
      () =>
        setValue(empty.object),
  })
)(Verwijzing);
