/* eslint  react/prop-types: off */
import empty from "empty";
import { compact, flow, map, sortBy } from "lodash/fp";
import React from "react";
import { DragDropContext } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import {
  compose,
  pure,
  setDisplayName,
  withHandlers,
  withState,
} from "recompose";
import { createSelector } from "reselect";

import AddButton from "../../../containers/addButton";
import { execOnChange } from "../../../recompose.contrib";
import DashboardBlok from "./dashboardBlok";

const withDragDropContext = DragDropContext(HTML5Backend);

const DashboardBlokken = ({
  blokken = empty.array,
  sorted = empty.array,
  pageActions: { edit = false },
  pageActions,
  moveBlok,
  deleteBlok,
}) => (
  <div>
    <div className="dashboard-blokken">
      {sorted.map(({ clusterId, position }) => {
        const b = blokken.find((c) => c.clusterId === clusterId);
        return b ? (
          <DashboardBlok
            key={`blok-${clusterId}`}
            blok={b}
            position={position}
            pageActions={pageActions}
            moveBlok={moveBlok}
            deleteBlok={deleteBlok}
          />
        ) : null;
      })}
    </div>
    {edit && (
      <div>
        <AddButton
          name="Blok"
          pageActions={pageActions}
          enabled={!pageActions.dirty}
        />
      </div>
    )}
  </div>
);

const initialBlokkenState = createSelector(
  [({ blokken = empty.array }) => blokken],
  (blokken) =>
    blokken
      .filter((b) => b && b.omschrijving)
      .map(({ clusterId }, idx) => ({ clusterId, position: idx }))
);

export default compose(
  setDisplayName("DashboardBlokken"),
  pure,
  withDragDropContext,
  withState("sorted", "updateBlokken", initialBlokkenState),
  withHandlers({
    deleteBlok:
      ({
        sorted,
        updateBlokken,
        pageActions: { dashboardDeleteBlok = empty.func } = empty.object,
      }) =>
      (clusterId) => {
        dashboardDeleteBlok({ clusterId });
        updateBlokken(
          sorted
            .filter((b) => b.clusterId !== clusterId)
            .map(({ clusterId }, idx) => ({ clusterId, position: idx }))
        );
      },

    moveBlok:
      ({
        sorted,
        updateBlokken,
        pageActions: {
          dashboardShift = empty.func,
          dashboardShiftRevert = empty.func,
        } = empty.object,
      }) =>
      (fromPosition, toPosition) => {
        const blokFrom = {
          clusterId: sorted.find(({ position }) => position === fromPosition)
            .clusterId,
          position: toPosition,
        };

        const removed = [
          ...sorted.slice(0, fromPosition),
          null,
          ...sorted.slice(fromPosition + 1),
        ];

        const mapWithIndex = map.convert({ cap: false });
        const moveLeft = fromPosition > toPosition;
        const reSorted = flow(
          compact,
          mapWithIndex(({ clusterId }, idx) => ({ clusterId, position: idx })),
          sortBy(["position"])
        )([
          ...removed.slice(0, moveLeft ? toPosition : toPosition + 1),
          blokFrom,
          ...removed.slice(moveLeft ? toPosition : toPosition + 1),
        ]);

        dashboardShiftRevert();
        updateBlokken(reSorted);
        dashboardShift({ blokken: reSorted });
      },
  }),
  execOnChange(
    (props) => props.updateBlokken(initialBlokkenState(props)),
    "blokken"
  )
)(DashboardBlokken);
