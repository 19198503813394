const addI18n = () => {
  global.tinymce.addI18n("nl", {
    Cut: "Knippen",
    "Heading 5": "Kop 5",
    "Header 2": "Kop 2",
    "Your browser doesn't support direct access to the clipboard. Please use the Ctrl+X/C/V keyboard shortcuts instead.":
      "Uw browser ondersteunt geen toegang tot het clipboard. Gelieve ctrl+X/C/V sneltoetsen te gebruiken.",
    "Heading 4": "Kop 4",
    Div: "Div",
    "Heading 2": "Kop 2",
    Paste: "Plakken",
    Close: "Sluiten",
    "Font Family": "Lettertype",
    Pre: "Pre",
    "Align right": "Rechts uitlijnen",
    "New document": "Nieuw document",
    Blockquote: "Quote",
    "Numbered list": "Nummering",
    "Heading 1": "Kop 1",
    Headings: "Koppen",
    "Increase indent": "Inspringen vergroten",
    Formats: "Opmaak",
    Headers: "Kopteksten",
    "Select all": "Alles selecteren",
    "Header 3": "Kop 3",
    Blocks: "Blok",
    Undo: "Ongedaan maken",
    Strikethrough: "Doorhalen",
    "Bullet list": "Opsommingsteken",
    "Header 1": "Kop 1",
    Superscript: "Superscript",
    "Clear formatting": "Opmaak verwijderen",
    "Font Sizes": "Tekengrootte",
    Subscript: "Subscript",
    "Header 6": "Kop 6",
    Redo: "Opnieuw",
    Paragraph: "Paragraaf",
    Ok: "Ok\u00e9",
    Bold: "Vet",
    Code: "Code",
    Italic: "Cursief",
    "Align center": "Centreren",
    "Header 5": "Kop 5",
    "Heading 6": "Kop 6",
    "Heading 3": "Kop 3",
    "Decrease indent": "Inspringen verkleinen",
    "Header 4": "Kop 4",
    "Paste is now in plain text mode. Contents will now be pasted as plain text until you toggle this option off.":
      "Plakken gebeurt nu als platte tekst. Tekst wordt nu ingevoegd zonder opmaak tot deze optie uitgeschakeld wordt.",
    Underline: "Onderstreept",
    Cancel: "Annuleren",
    Justify: "Uitlijnen",
    Inline: "Inlijn",
    Copy: "Kopi\u00ebren",
    "Align left": "Links uitlijnen",
    "Visual aids": "Hulpmiddelen",
    "Lower Greek": "Griekse letters",
    Square: "Vierkant",
    Default: "Standaard",
    "Lower Alpha": "Kleine letters",
    Circle: "Cirkel",
    Disc: "Bolletje",
    "Upper Alpha": "Hoofdletters",
    "Upper Roman": "Romeinse cijfers groot",
    "Lower Roman": "Romeinse cijfers klein",
    "Id should start with a letter, followed only by letters, numbers, dashes, dots, colons or underscores.":
      "ID moet beginnen met een letter, gevolgd door letters, nummers, streepjes, punten, dubbele punten of underscores.",
    Name: "Naam",
    Anchor: "Anker",
    Id: "ID",
    "You have unsaved changes are you sure you want to navigate away?":
      "U hebt niet alles opgeslagen bent u zeker dat u de pagina wenst te verlaten?",
    "Restore last draft": "Herstel het laatste concept",
    "Special character": "Speciale karakters",
    "Source code": "Broncode",
    Language: "Programmeertaal",
    "Insert/Edit code sample": "Broncode invoegen/bewerken",
    B: "Blauw",
    R: "Rood",
    G: "Groen",
    Color: "Kleur",
    "Right to left": "Rechts naar links",
    "Left to right": "Links naar rechts",
    Emoticons: "Emoticons",
    Robots: "Robots",
    "Document properties": "Document eigenschappen",
    Title: "Titel",
    Keywords: "Sleutelwoorden",
    Encoding: "Codering",
    Description: "Omschrijving",
    Author: "Auteur",
    Fullscreen: "Volledig scherm",
    "Horizontal line": "Horizontale lijn",
    "Horizontal space": "Horizontale ruimte",
    "Insert/edit image": "Afbeelding invoegen/bewerken",
    General: "Algemeen",
    Advanced: "Geavanceerd",
    Source: "Bron",
    Border: "Rand",
    "Constrain proportions": "Verhoudingen behouden",
    "Vertical space": "Verticale ruimte",
    "Image description": "Afbeelding omschrijving",
    Style: "Stijl",
    Dimensions: "Afmetingen",
    "Insert image": "Afbeelding invoegen",
    Image: "Afbeelding",
    "Zoom in": "Inzoomen",
    Contrast: "Contrast",
    Back: "Terug",
    Gamma: "Gamma",
    "Flip horizontally": "Horizontaal spiegelen",
    Resize: "Formaat aanpassen",
    Sharpen: "Scherpte",
    "Zoom out": "Uitzoomen",
    "Image options": "Afbeelding opties",
    Apply: "Toepassen",
    Brightness: "Helderheid",
    "Rotate clockwise": "Rechtsom draaien",
    "Rotate counterclockwise": "Linksom draaien",
    "Edit image": "Bewerk afbeelding",
    "Color levels": "Kleurniveau's",
    Crop: "Uitsnijden",
    Orientation: "Orientatie",
    "Flip vertically": "Verticaal spiegelen",
    Invert: "Omkeren",
    "Date/time": "Datum/tijd",
    "Insert date/time": "Voeg datum/tijd in",
    "Remove link": "Link verwijderen",
    Url: "Url",
    "Text to display": "Linktekst",
    Anchors: "Anker",
    "Insert link": "Hyperlink invoegen",
    Link: "Link",
    "New window": "Nieuw venster",
    None: "Geen",
    "The URL you entered seems to be an external link. Do you want to add the required http:// prefix?":
      'De ingegeven URL verwijst naar een extern adres. Wil je er "http://" aan toevoegen?',
    "Paste or type a link": "Plak of typ een link",
    Target: "Doel",
    "The URL you entered seems to be an email address. Do you want to add the required mailto: prefix?":
      'De ingegeven URL lijkt op een e-mailadres. Wil je er "mailto:" aan toevoegen?',
    "Insert/edit link": "Hyperlink invoegen/bewerken",
    "Insert/edit video": "Video invoegen/bewerken",
    Media: "Media",
    "Alternative source": "Alternatieve bron",
    "Paste your embed code below:": "Plak u in te sluiten code hieronder:",
    "Insert video": "Video invoegen",
    Poster: "Poster",
    "Insert/edit media": "Media invoegen/bewerken",
    Embed: "Insluiten",
    "Nonbreaking space": "Vaste spatie invoegen",
    "Page break": "Pagina einde",
    "Paste as text": "Plakken als tekst",
    Preview: "Voorbeeld",
    Print: "Print",
    Save: "Opslaan",
    "Could not find the specified string.": "Geen resultaten gevonden",
    Replace: "Vervangen",
    Next: "Volgende",
    "Whole words": "Alleen hele woorden",
    "Find and replace": "Zoek en vervang",
    "Replace with": "Vervangen door",
    Find: "Zoeken",
    "Replace all": "Alles vervangen",
    "Match case": "Identieke hoofd/kleine letters",
    Prev: "Vorige",
    Spellcheck: "Spellingscontrole",
    Finish: "Einde",
    "Ignore all": "Alles negeren",
    Ignore: "Negeren",
    "Add to Dictionary": "Toevoegen aan woordenlijst",
    "Insert row before": "Voeg rij boven toe",
    Rows: "Rijen",
    Height: "Hoogte",
    "Paste row after": "Plak rij onder",
    Alignment: "Uitlijning",
    "Border color": "Randkleur",
    "Column group": "Kolomgroep",
    Row: "Rij",
    "Insert column before": "Voeg kolom in voor",
    "Split cell": "Cel splitsen",
    "Cell padding": "Ruimte binnen cel",
    "Cell spacing": "Celruimte",
    "Row type": "Rijtype",
    "Insert table": "Tabel invoegen",
    Body: "Body",
    Caption: "Onderschrift",
    Footer: "Voettekst",
    "Delete row": "Verwijder rij",
    "Paste row before": "Plak rij boven",
    Scope: "Bereik",
    "Delete table": "Verwijder tabel",
    "H Align": "Links uitlijnen",
    Top: "Bovenaan",
    "Header cell": "Kopcel",
    Column: "Kolom",
    "Row group": "Rijgroep",
    Cell: "Cel",
    Middle: "Centreren",
    "Cell type": "Celtype",
    "Copy row": "Kopieer rij",
    "Row properties": "Rij eigenschappen",
    "Table properties": "Tabel eigenschappen",
    Bottom: "Onderaan",
    "V Align": "Boven uitlijnen",
    Header: "Koptekst",
    Right: "Rechts",
    "Insert column after": "Voeg kolom in na",
    Cols: "Kolommen",
    "Insert row after": "Voeg rij onder toe",
    Width: "Breedte",
    "Cell properties": "Cel eigenschappen",
    Left: "Links",
    "Cut row": "Knip rij",
    "Delete column": "Verwijder kolom",
    Center: "Midden",
    "Merge cells": "Cellen samenvoegen",
    "Insert template": "Sjabloon invoegen",
    Templates: "Sjablonen",
    "Background color": "Achtergrondkleur",
    "Custom...": "Eigen...",
    "Custom color": "Eigen kleur",
    "No color": "Geen kleur",
    "Text color": "Tekstkleur",
    "Table of Contents": "Inhoudsopgave",
    "Show blocks": "Blokken tonen",
    "Show invisible characters": "Onzichtbare karakters tonen",
    "Words: {0}": "Woorden: {0}",
    Insert: "Invoegen",
    File: "Bestand",
    Edit: "Bewerken",
    "Rich Text Area. Press ALT-F9 for menu. Press ALT-F10 for toolbar. Press ALT-0 for help":
      "Rich Text Area. Druk ALT-F9 voor het menu. Druk ALT-F10 voor de toolbar. Druk ALT-0 voor help.",
    Tools: "Gereedschap",
    View: "Beeld",
    Table: "Tabel",
    Format: "Opmaak",
  });
};

export default addI18n;
