/* eslint  react/prop-types: off */
import empty from "empty";
import { flow } from "lodash/fp";
import PropTypes from "prop-types";
import React from "react";
import {
  branch,
  compose,
  defaultProps,
  renderComponent,
  setDisplayName,
  setPropTypes,
} from "recompose";

import { isEmpty } from "../../utils";
import { propTypes, withDefaultPageActions } from "./ggvtyp";
import InternetView, { pattern, transformLink } from "./InternetView";
import InternetWithLabel from "./internetWithLabel";
import PlainInput from "./plainInput";

const convertValue = ({ value, ...props }) => ({
  ...props,
  string: value,
  value: isEmpty(value) ? undefined : { address: value },
});

/**
 * Gegevenstype 'Internet' (6)
 */
const Internet = ({
  value: { address } = empty.object,
  value,
  setValue,
  className,
  pageActions: { edit },
  field: { definition: { explanation, sourcePlaceholder } = empty.object },
}) =>
  edit ? (
    <PlainInput
      value={address}
      setValue={flow(convertValue, setValue)}
      transformValue={transformLink}
      type="url"
      regex={pattern}
      explanation={explanation}
      placeholder={sourcePlaceholder}
    />
  ) : (
    <InternetView className={className} value={value} />
  );

export default compose(
  setDisplayName("Internet"),
  setPropTypes({
    ...propTypes,
    field: PropTypes.shape({
      value: PropTypes.shape({
        address: PropTypes.string,
      }),
    }).isRequired,
  }),
  defaultProps({
    className: "internet",
  }),
  withDefaultPageActions(),
  branch(
    ({
      field: { definition: { hasLabel = false } = empty.object } = empty.object,
    }) => hasLabel,
    renderComponent(InternetWithLabel)
  )
)(Internet);
