import empty from "empty";
import { defaultTo, defaults, flow, get, omit } from "lodash/fp";
import { createSelector } from "reselect";

const itemIdSelector = (_, { itemId }) => itemId;
const versionIdSelector = (_, { versionId }) => versionId;
const relationVersionIdSelector = (_, { relationVersionId }) =>
  relationVersionId;
const relationItemIdSelector = (_, { relationItemId }) => relationItemId;
const relationItemVersionIdSelector = (_, { relationItemVersionId }) =>
  relationItemVersionId;
const pageDataSelector = createSelector(
  [itemIdSelector, get("pages")],
  (itemId, pages) => ({
    ...flow(get(itemId), omit(["loading", "_expiry"]))(pages),
  })
);

const emptyVersionsList = Object.freeze({ id: 0, items: empty.array });
export const versionsSelectorBuilder = (idSelector) =>
  createSelector(
    [flow(get("data.versions"), defaultTo(empty.object)), idSelector],
    (versionsObj, id) =>
      flow(
        get(id),
        defaultTo(emptyVersionsList),
        defaults(emptyVersionsList)
      )(versionsObj)
  );

const emptyVersionDiff = Object.freeze({
  relations: empty.array,
});

export const versionKey = (
  itemId,
  {
    versionId = "",
    relationVersionId = "",
    relationItemId = "",
    relationItemVersionId = "",
  } = empty.object
) =>
  [
    itemId,
    versionId,
    relationVersionId,
    relationItemId,
    relationItemVersionId,
  ].join(":");

export const versionDiffSelector = createSelector(
  [
    itemIdSelector,
    versionIdSelector,
    relationVersionIdSelector,
    relationItemIdSelector,
    relationItemVersionIdSelector,
    pageDataSelector,
    get("versionDiffs"),
    (_, { created }) => created,
  ],
  (
    itemId,
    versionId,
    relationVersionId,
    relationItemId,
    relationItemVersionId,
    pageMeta,
    versionDiffs,
    created
  ) => {
    if (!(itemId && (versionId || relationVersionId))) {
      return emptyVersionDiff;
    }

    const versionDiff = get(
      versionKey(itemId, {
        versionId,
        relationVersionId,
        relationItemId,
        relationItemVersionId,
      })
    )(versionDiffs);
    if (!versionDiff) {
      return emptyVersionDiff;
    }

    return {
      page: {
        ...pageMeta,
        ...versionDiff.page,
        lastPublished: created,
      },
      relations: versionDiff.itemRelations || empty.array,
    };
  }
);
