import { compose, lifecycle, setDisplayName } from "recompose";

import { resize } from "../actions/viewport";
import App from "../components/app";
import connect from "./connect";

/**
 * Map state to props
 */
const mapState = ({ ui }) => ({ ui });

/**
 * Map state to props
 */
const mapDispatch = (dispatch) => ({
  resize: () => dispatch(resize()),
});

/**
 * Compose Higher-order component
 */
export default compose(
  setDisplayName("App"),
  connect(mapState, mapDispatch),
  lifecycle({
    componentDidMount() {
      window.addEventListener("resize", this.props.resize);
    },
    componentWillUnmount() {
      window.removeEventListener("resize", this.props.resize);
    },
  })
)(App);
