import { flow, get, pick } from "lodash/fp";
import { compose, setDisplayName } from "recompose";
import { defaultMemoize } from "reselect";

import { logAlert } from "../../actions/utils";
import Component from "../../components/ggvtyp/meervoudigBestand";
import connect from "../connect";

const allowedFilesSelector = defaultMemoize(
  flow(get("data.settings"), pick("allowedFiles"))
);

export default compose(
  setDisplayName("MeervoudigBestand"),
  connect(allowedFilesSelector, (dispatch) => ({
    notAllowed: (extension) =>
      dispatch(
        logAlert(`Bestanden met extensie ${extension} zijn niet toegestaan.`)
      ),
  }))
)(Component);
