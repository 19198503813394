/* eslint  react/prop-types: off */
import empty from "empty";
import { identity, some, values } from "lodash/fp";
import Checkbox from "material-ui/Checkbox";
import FlatButton from "material-ui/FlatButton";
import { List, ListItem } from "material-ui/List";
import Subheader from "material-ui/Subheader";
import TextField from "material-ui/TextField";
import React from "react";
import { branch, compose, renderNothing, setDisplayName } from "recompose";

import Dialog from "../containers/dialog";
import { flown } from "../lodash";
import PagetypeIcon from "./pagetypeIcon";

const LegoblokUseDialog = ({
  close = empty.func,
  checkReference = empty.func,
  references = empty.object,
  checkedReferences = empty.object,
  updateLabel = empty.func,
  doUseSelection = empty.func,
  labels = empty.object,
}) => (
  <Dialog
    title="Legoblok overnemen"
    open
    autoScrollBodyContent
    actions={[
      <FlatButton key="close" secondary label="Annuleren" onClick={close} />,
      <FlatButton
        primary
        key="save"
        label="Opslaan"
        disabled={!flown(checkedReferences, values, some(identity))}
        onClick={doUseSelection}
      />,
    ]}
  >
    <div>
      {Object.keys(references).map((key) => (
        <List key={`tab-${key}`}>
          <Subheader>{references[key][0].tab}</Subheader>
          {references[key].map(({ itemId, label, type, tab, alreadyUsed }) => (
            <ListItem
              leftCheckbox={
                <Checkbox
                  checked={checkedReferences[itemId]}
                  onCheck={checkReference}
                  data-itemid={itemId}
                  className={
                    alreadyUsed && !checkedReferences[itemId]
                      ? "discouraged-checkbox"
                      : undefined
                  }
                />
              }
              title={
                alreadyUsed ? `Deze '${tab}' is al overgenomen.` : undefined
              }
              key={`item-${key}-${itemId}`}
              style={{ paddingTop: 0, paddingBottom: 0 }}
              primaryText={
                <TextField
                  id={`field-${key}-${itemId}`}
                  value={labels[itemId] || label}
                  disabled={!checkedReferences[itemId]}
                  fullWidth
                  onChange={updateLabel}
                  data-itemid={itemId}
                />
              }
              rightIcon={<PagetypeIcon type={type} simpleIcon />}
            />
          ))}
        </List>
      ))}
    </div>
  </Dialog>
);

export default compose(
  setDisplayName("LegoblokUseDialog"),
  branch(({ open }) => !open, renderNothing)
)(LegoblokUseDialog);
