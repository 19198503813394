import Card from "material-ui/Card";
import { List, ListItem } from "material-ui/List";
import PropTypes from "prop-types";
import React from "react";
import { withHandlers } from "recompose";

import { overzichtShape } from "../../business/prop-types";
import Excel from "../../svg/excel";
import Subheader from "../material/Subheader";
import PagetypeIcon from "../pagetypeIcon";

const ItemIcon = ({ pagetype }) => (
  <div
    style={{
      height: "24px",
      width: "24px",
      display: "block",
      position: "absolute",
      top: "0px",
      margin: "12px",
      left: "4px",
    }}
  >
    <PagetypeIcon type={pagetype} simpleIcon />
    <Excel
      style={{
        position: "absolute",
        bottom: 0,
        left: 0,
        width: 12,
        height: 12,
      }}
    />
  </div>
);

ItemIcon.propTypes = {
  pagetype: PropTypes.string.isRequired,
};

const ItemComponent = ({
  overzicht: { pagetype, title, userFullName },
  anoniem = false,
  onClick,
}) => (
  <ListItem
    innerDivStyle={{ paddingLeft: "56px" }}
    leftIcon={<ItemIcon pagetype={pagetype} />}
    onClick={onClick}
    primaryText={title}
    secondaryText={anoniem ? undefined : userFullName}
  />
);
ItemComponent.propTypes = {
  onClick: PropTypes.func.isRequired,
  overzicht: overzichtShape.isRequired,
};
const OverzichtItem = withHandlers({
  onClick:
    ({ overzicht, selectOverzicht }) =>
    (e) => {
      e.preventDefault();
      selectOverzicht(overzicht);
    },
})(ItemComponent);

const RecentOverzicht = ({ overzichten, selectOverzicht, anoniem }) => (
  <Card className="recent">
    <div className="relations">
      <List>
        <Subheader>Recente overzichten</Subheader>
        {overzichten.map((overzicht) => (
          <OverzichtItem
            overzicht={overzicht}
            selectOverzicht={selectOverzicht}
            anoniem={anoniem}
            key={overzicht.id}
          />
        ))}
      </List>
    </div>
  </Card>
);
RecentOverzicht.propTypes = {
  overzichten: PropTypes.arrayOf(overzichtShape.isRequired).isRequired,
  anoniem: PropTypes.bool,
  selectOverzicht: PropTypes.func.isRequired,
};

export default RecentOverzicht;
