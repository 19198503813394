/* eslint  react/prop-types: off */
import empty from "empty";
import { flow } from "lodash/fp";
import PropTypes from "prop-types";
import React from "react";
import { compose, defaultProps, setDisplayName, setPropTypes } from "recompose";

import { isEmpty } from "../../utils";
import { propTypes, withDefaultPageActions } from "./ggvtyp";
import PlainInput from "./plainInput";

const convertValue = ({ value, ...props }) => ({
  ...props,
  string: value,
  value: isEmpty(value) ? undefined : { address: value },
});

/**
 * Gegevenstype 'E-mail' (16)
 */
const Email = ({
  value: { address = "" } = empty.object,
  setValue,
  className,
  pageActions: { edit },
  field: { definition: { explanation, sourcePlaceholder } = empty.object },
}) =>
  edit ? (
    <PlainInput
      value={address}
      setValue={flow(convertValue, setValue)}
      type="email"
      explanation={explanation}
      placeholder={sourcePlaceholder}
    />
  ) : (
    <a className={className} href={`mailto:${address}`}>
      {address}
    </a>
  );

const enhance = compose(
  setDisplayName("Email"),
  setPropTypes({
    ...propTypes,
    field: PropTypes.shape({
      value: PropTypes.shape({
        address: PropTypes.string.isRequired,
      }),
    }).isRequired,
  }),
  defaultProps({
    className: "email",
  }),
  withDefaultPageActions()
);

export default enhance(Email);
