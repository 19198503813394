import empty from "empty";
import { connect } from "react-redux";
import { compose, pure, setDisplayName } from "recompose";

import { requireEntiteiten, requireZibs } from "../../actions/application";
import Index from "../../components/pagetypes/index.jsx";
import { execOnChange } from "../../recompose.contrib";

export const enhanceIndex = compose(
  setDisplayName("Index"),
  pure,
  connect(
    ({
      data: {
        entiteiten: { entiteiten = empty.array },
        zibs: { zibs = empty.array },
      },
    }) => ({
      entiteiten,
      zibs,
    }),
    (dispatch) => ({
      requireData: () => {
        dispatch(requireEntiteiten());
        dispatch(requireZibs());
      },
    })
  ),
  execOnChange("requireData")
);

export default enhanceIndex(Index);
