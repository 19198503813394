import { loadingPresent } from "async-lifecycle";
import empty from "empty";
import {
  filter,
  find,
  findLast,
  flatten,
  get,
  has,
  map,
  reject,
  some,
} from "lodash/fp";
import { compose, mapProps, setDisplayName } from "recompose";

import { requireEntiteiten } from "../../../actions/application";
import { RelationTypeGroups } from "../../../actions/page";
import KernVeldenComponent from "../../../components/pagetypes/application/kernvelden";
import { flown } from "../../../lodash";
import { execOnChange } from "../../../recompose.contrib";
import { dispatchWrap } from "../../../utils";
import connect from "../../connect";

const mapVelden = ({
  pageActions,
  page = empty.object,
  siteId,
  bieb,
  clusterId,
  gegevensClusterId,
  entiteiten: { loading, entiteiten } = empty.object,
  relations,
  staged,
  types,
}) => {
  if (!loadingPresent(loading)) {
    return empty.object;
  }

  const entiteitType =
    find({
      alias: "entiteit_applicatieveld",
    })(types) || empty.object;
  const { id: entiteitTypeId } = entiteitType;
  const gegevenType =
    find({
      alias: "kerngegeven_applicatieveld",
    })(types) || empty.object;
  const { right: { label } = empty.object, id: gegevenTypeId } = gegevenType;

  const {
    gegeven: { clusters: gegevenClusters = empty.array } = empty.object,
  } = page;
  const findVeld = ({
    veld: { clusters: veldClusters = empty.array } = empty.object,
  }) => find({ clusterId })(veldClusters);
  const gegevenCluster = find(
    gegevensClusterId ? { clusterId: gegevensClusterId } : findVeld
  )(gegevenClusters);

  if (!entiteitTypeId || !gegevenTypeId || !gegevenCluster) {
    return empty.object;
  }

  const entiteitTypes = [entiteitType, gegevenType];

  const kernveldRelations = flown(
    relations,
    filter(({ alias }) => find({ alias })(entiteitTypes)),
    map("links"),
    flatten,
    filter({ pageClusterId: clusterId })
  );

  const list = (bieb ? filter({ bieb: siteId }) : reject(has("bieb")))(
    entiteiten
  );

  const selected =
    staged?.map((link) => ({
      itemId: link.vanItmIdt,
      pageClusterId: link.vanPagClsIdt,
    })) ?? kernveldRelations.map(get("item"));

  return {
    label,
    entiteiten: list,
    selected,
    onChange: (links) => {
      const relations = flown(
        links,
        map((link) => ({
          itmRelTypIdt: find({ alias: link.type })(entiteitTypes).id,
          vanItmIdt: link.itemId,
          vanPagClsIdt: link.pageClusterId,
        }))
      );
      pageActions.clusterSetRelations(
        {
          clusterId,
          relations,
          relationTypeGroup: RelationTypeGroups.entiteitApplicatieVeld,
        },
        false // TODO: check whether links differs from stored
      );
    },
  };
};

const KernVelden = compose(
  setDisplayName("KernVelden"),
  connect(
    (
      {
        data: {
          pages,
          entiteiten,
          addLegoblok: { structures = empty.array } = empty.object,
          relationTypes: { types = empty.array },
        },
        form: { page },
      },
      { clusterId, pageActions: { pageId } = empty.object }
    ) => {
      const { siteId } = pages[pageId];
      const bieb = flown(
        structures,
        some(({ id }) => id === siteId)
      );

      return {
        siteId,
        bieb,
        entiteiten,
        types,
        staged: flown(
          page,
          get(`${pageId}.staged`),
          findLast(
            ({ $type: type, clusterId: stagedClusterId, relationTypeGroup }) =>
              clusterId === stagedClusterId &&
              relationTypeGroup === RelationTypeGroups.entiteitApplicatieVeld &&
              /ClusterReplaceRelations/.test(type)
          ),
          get("relations")
        ),
      };
    },
    (dispatch) => ({
      requireEntiteiten: dispatchWrap(requireEntiteiten, dispatch),
      dispatch,
    })
  ),
  execOnChange("requireEntiteiten"),
  mapProps(mapVelden)
)(KernVeldenComponent);

export default KernVelden;
