import classNames from "classnames";
import Menu from "material-ui/Menu";
import Popover from "material-ui/Popover";
import Subheader from "material-ui/Subheader";
import React, { memo, useCallback, useState } from "react";

import RaisedIconButton from "./RaisedIconButton";

type AnchorType = EventTarget & HTMLButtonElement;

interface Props {
  children?: React.ReactNode;
  title: string;
  className?: string;
  buttonIcon: React.ReactNode;
  buttonDisabled?: boolean;
  subheader: string;
}

const IconButtonMenu = memo(
  ({
    children,
    title,
    className,
    buttonIcon,
    buttonDisabled,
    subheader,
  }: Props) => {
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState({} as AnchorType);

    const handleOpen = useCallback(
      (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        setOpen(true);
      },
      []
    );

    const handleClose = useCallback(() => {
      setOpen(false);
      setAnchorEl({} as AnchorType);
    }, []);

    return (
      <span
        className={classNames(
          "item-menu-button_menu",
          className && `${className}_menu`
        )}
      >
        <RaisedIconButton
          title={title}
          onClick={handleOpen}
          className={className && `${className}_btn`}
          icon={buttonIcon}
          secondary
          disabled={buttonDisabled}
        />
        {open && (
          <Popover open anchorEl={anchorEl} onRequestClose={handleClose}>
            <Menu>
              <Subheader>{subheader}</Subheader>
              {React.Children.map(children, (child) => {
                const c = child as React.ReactElement;
                return React.cloneElement(c, {
                  ...c.props,
                  onClick: (event: React.MouseEvent<HTMLButtonElement>) => {
                    handleClose();
                    c.props.onClick(event);
                  },
                });
              })}
            </Menu>
          </Popover>
        )}
      </span>
    );
  }
);

export default IconButtonMenu;
