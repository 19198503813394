import empty from "empty";
import { isEqual } from "lodash/fp";
import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { hashHistory } from "react-router";
import {
  compose,
  defaultProps,
  lifecycle,
  pure,
  setDisplayName,
} from "recompose";

import { updateMiniSaarAction } from "../actions/data";
import { insertAfter } from "../recompose.contrib";
import { miniSaarSelector, sessionSelector } from "../selectors";
import connect from "./connect";
import Preview from "./preview";
import VersionDiff from "./versionDiff";

/**
 * Slechts een doorgeefluik...
 */
const Authenticated = ({ isAuthenticated, children }) => {
  const sisense = useSelector(
    ({
      sagas: {
        sisenseLogin: {
          value: {
            success,
            result: { redirect } = empty.object,
          } = empty.object,
        } = empty.object,
      } = empty.object,
    }) => ({ redirect, success })
  );

  const [sisenseUrlSet, setSisenseUrl] = useState(false);
  useCallback(() => {
    setSisenseUrl(true);
  }, []);

  if (
    !sisenseUrlSet &&
    isAuthenticated &&
    sisense.success &&
    sisense.redirect
  ) {
    const iframe = document.getElementById("sisense_jwt");
    iframe.src = sisense.redirect;
    setSisenseUrl(true);
  }

  return <>{children}</>;
};

/**
 * Map state to props
 */
const mapState = (state, props) => ({
  miniSaar: miniSaarSelector(state, props),
  ...sessionSelector(state, props),
});

const mapDispatch = (dispatch) => ({
  updateMiniSaar: (miniSaar) => dispatch(updateMiniSaarAction(miniSaar)),
});

const biebLink = /^\/bieb\//;

/**
 * Authenticated higher-order component
 */
export default compose(
  setDisplayName("Authenticated"),
  connect(mapState, mapDispatch),
  pure,
  defaultProps({
    user: null,
    isAuthenticated: false,
  }),
  lifecycle({
    componentDidMount() {
      const { pathname, search } = this.props.location;

      if (!this.props.isAuthenticated) {
        // niet ingelogd
        hashHistory.replace(
          `/inloggen?next=${pathname}${encodeURIComponent(search)}`
        );
      } else if (biebLink.test(pathname)) {
        hashHistory.replace(
          pathname.replace(biebLink, `/${this.props.miniSaar.path}/`)
        );
      } else if (this.props.miniSaar) {
        this.props.updateMiniSaar(this.props.miniSaar);
      }
    },
    componentDidUpdate(prevProps) {
      const { pathname, search } = this.props.location;

      if (!this.props.isAuthenticated) {
        // niet ingelogd
        hashHistory.replace(
          `/inloggen?next=${pathname}${encodeURIComponent(search)}`
        );
      } else if (biebLink.test(pathname)) {
        hashHistory.replace(
          pathname.replace(biebLink, `/${this.props.miniSaar.path}/`)
        );
      } else if (!isEqual(prevProps.miniSaar, this.props.miniSaar)) {
        this.props.updateMiniSaar(this.props.miniSaar);
      }
    },
  }),
  insertAfter(Preview),
  insertAfter(VersionDiff)
)(Authenticated);
