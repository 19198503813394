import empty from "empty";
import { get, isEqual } from "lodash/fp";
import { branch, compose, renderNothing, withState } from "recompose";

import { getPageAction } from "../actions/data";
import { clearPreviewAction } from "../actions/ui";
import PreviewDialog from "../components/pagetypes/preview";
import { flown } from "../lodash";
import { execOnChange } from "../recompose.contrib";
import { dispatchWrap } from "../utils";
import connect from "./connect";

const Preview = compose(
  connect(
    ({
      data: {
        pages,
        relations,
        relationTypes: { types = empty.array },
      },
      ui: {
        preview: { open, itemId },
      },
    }) => ({
      id: itemId,
      itemId,
      open,
      page: pages[itemId],
      relations: (relations[itemId] || empty.object).relations || empty.array,
      types,
    }),
    (dispatch) => ({
      requirePage: dispatchWrap(getPageAction, dispatch),
      onClose: dispatchWrap(clearPreviewAction, dispatch),
    }),
    (stateProps, { requirePage, ...dispatchProps }, ownProps) => ({
      ...stateProps,
      ...dispatchProps,
      ...ownProps,
      loading: get("loading")(stateProps.page),
      error: flown(stateProps.page, get("loading"), isEqual("error")),
      requirePage: () => {
        if (stateProps.itemId) {
          requirePage(stateProps.itemId, { omitRecently: true });
        }
      },
    })
  ),
  branch(({ open }) => !open, renderNothing),
  withState("selectedTab", "tabChanged", 0),
  execOnChange("requirePage", "itemId")
)(PreviewDialog);

export default Preview;
