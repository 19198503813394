/* eslint  react/prop-types: off */
import empty from "empty";
import CircularProgress from "material-ui/CircularProgress";
import FlatButton from "material-ui/FlatButton";
import { List, ListItem, makeSelectable } from "material-ui/List";
import Subheader from "material-ui/Subheader";
import ActionSearch from "material-ui/svg-icons/action/search";
import TextField from "material-ui/TextField";
import React from "react";
import { compose, setDisplayName, withHandlers, withState } from "recompose";

import Dialog from "../containers/dialog";
import PagetypeIcon from "./pagetypeIcon";

const EnhancedList = makeSelectable(List);

const SelectableList = compose(
  setDisplayName("SelectableList"),
  withState("selectedItemId", "setSelectedItem", -1),
  withHandlers({
    onChange:
      ({ setSelectedItem, onSelect = empty.func }) =>
      (event, itemId) => {
        setSelectedItem(itemId);
        onSelect(event, itemId);
      },
  })
)(({ selectedItemId, onChange, children }) => (
  <EnhancedList onChange={onChange} value={selectedItemId}>
    {children.length > 0 && <Subheader>Selecteer een item</Subheader>}
    {children}
  </EnhancedList>
));

const VerwijzingSearchDialog = ({
  open = false,
  found = empty.array,
  openDialog = empty.func,
  closeDialog = empty.func,
  update = empty.func,
  select = empty.func,
  save = empty.func,
  selectedItem = 0,
  query: { trefwoord } = empty.object,
  busy,
  clusterName,
}) => (
  <div>
    {
      <Dialog
        title={`${clusterName ?? "Pagina"} zoeken`}
        open={open}
        autoScrollBodyContent
        actions={[
          <FlatButton
            key="close"
            secondary
            label="Annuleren"
            onClick={closeDialog}
          />,
          <FlatButton
            primary
            key="save"
            label="OK"
            disabled={selectedItem === 0}
            onClick={save}
          />,
        ]}
      >
        <form
          method="post"
          style={{
            display: "flex",
            alignItems: "baseline",
            justifyContent: "space-between",
          }}
          onSubmit={update}
        >
          <TextField
            hintText="Voer een zoekterm in"
            floatingLabelText="Zoek naar een item"
            onChange={update}
            autoFocus
            fullWidth
          />
          {busy && <CircularProgress size={24} style={{ marginLeft: 16 }} />}
        </form>
        {found && (
          <SelectableList onSelect={select}>
            {found.map(
              ({
                fields: {
                  itemId,
                  label,
                  pagetype,
                  pageClusterId,
                  pageClusterLabel,
                } = empty.object,
                insideBieb,
              }) => (
                <ListItem
                  key={`${itemId}_${pageClusterId}`}
                  value={pageClusterId ? `${itemId}_${pageClusterId}` : itemId}
                  leftIcon={
                    <PagetypeIcon insideBieb={insideBieb} type={pagetype} />
                  }
                  innerDivStyle={{ paddingLeft: "56px" }}
                  primaryText={
                    pageClusterLabel ? `${label} (${pageClusterLabel})` : label
                  }
                />
              )
            )}
          </SelectableList>
        )}
        {!busy && trefwoord && trefwoord.length > 0 && found.length === 0 && (
          <p>Geen pagina gevonden.</p>
        )}
      </Dialog>
    }
    <FlatButton
      primary
      label={`Zoek een ${clusterName ?? "pagina"}`}
      onClick={openDialog}
      icon={<ActionSearch />}
    />
  </div>
);

export default VerwijzingSearchDialog;
