import classNames from "classnames";
import { identity } from "lodash/fp";
import { Card, CardText, CardTitle } from "material-ui/Card";
import FlatButton from "material-ui/FlatButton";
import IconButton from "material-ui/IconButton";
import { lightGreen300 } from "material-ui/styles/colors";
import ActionDelete from "material-ui/svg-icons/action/delete";
import PropTypes from "prop-types";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { compose, setDisplayName, withProps, withState } from "recompose";

import { styleShape } from "../../business/prop-types";
import {
  ignoreKnownIrrelevancies,
  withoutProps,
} from "../../recompose.contrib";
import { consumeClusterPresentation } from "../content/clusterPresentation";
import PlainInput from "../ggvtyp/plainInput";
import RenderIf from "../utils/RenderIf";
import CopyClusterLink from "./CopyClusterLink";

const FullCard = ({
  autoFocus,
  expanded,
  setExpanded,
  expandable,
  actAsExpander = expandable,
  showExpandableButton = expandable,
  removed,
  title,
  titlePrefix,
  titleSuffix,
  titleStyle,
  deleteDisabled = false,
  onDelete,
  onRestore,
  onTitleUpdate,
  children,
  titleRenderer,
  clusterId,
  showCopyLink,
  level,
  clusterExpansion,
  clusterSearch,
  className,
  keepRendered = true,
  ...cardProps
}) => {
  const titleDiv = useRef();
  useEffect(() => {
    if (autoFocus === "self") {
      titleDiv.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    }
  }, [autoFocus]);

  const wrap = useRef();
  const [visible, setVisible] = useState(!expandable || Boolean(autoFocus));
  const outer = wrap.current;
  useEffect(() => {
    const card = outer;
    if (!card) {
      return;
    }

    const options = {
      root: null,
      rootMargin: "10px",
      threshold: 0.1,
    };
    const observer = new IntersectionObserver(([{ isIntersecting }]) => {
      if (isIntersecting) {
        setVisible(true);
      } else if (!expanded) {
        setVisible(false);
      }
    }, options);
    observer.observe(card);
    return () => observer.disconnect();
  }, [expanded, outer]);

  return (
    <div ref={wrap}>
      <Card
        className={classNames(className, "cluster-card")}
        expanded={expanded}
        onExpandChange={setExpanded}
        {...cardProps}
        style={{ position: "relative" }}
      >
        {title && (
          <CardTitle
            className={classNames("card-title", {
              "card-title-expandable": removed || expandable,
              "card-title-expanded": expanded,
              "card-title-fixed": !removed && !expandable,
            })}
            title={
              <Fragment>
                <div
                  ref={titleDiv}
                  style={{
                    display: "inline-flex",
                    verticalAlign: removed ? undefined : "text-bottom",
                  }}
                >
                  {titlePrefix}
                  {onTitleUpdate && expanded ? (
                    <PlainInput
                      defaultValue={title}
                      setValue={({ value }) => onTitleUpdate(value)}
                      style={{
                        width: "auto",
                        borderBottomWidth: 1,
                        borderBottomStyle: "solid",
                        borderColor: lightGreen300,
                      }}
                      onClick={(e) => e.stopPropagation()}
                    />
                  ) : (
                    titleRenderer(title, clusterId)
                  )}
                  {title && showCopyLink && clusterId && (
                    <CopyClusterLink clusterId={clusterId} />
                  )}
                  {titleSuffix}
                </div>
                {onDelete && expanded && (
                  <div style={{ display: "inline-block" }}>
                    <IconButton
                      disabled={deleteDisabled}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        onDelete();
                      }}
                      title={
                        deleteDisabled
                          ? "Het is niet mogelijk dit cluster te verwijderen"
                          : undefined
                      }
                    >
                      <ActionDelete />
                    </IconButton>
                  </div>
                )}
                {removed && (
                  <div
                    style={{ display: "inline-block", margin: "-4px 0 0 8px" }}
                  >
                    <FlatButton
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        onRestore();
                      }}
                      label="Terugzetten"
                    />
                  </div>
                )}
              </Fragment>
            }
            style={titleStyle}
            actAsExpander={actAsExpander}
            showExpandableButton={showExpandableButton}
          />
        )}
        <CardText
          className={classNames("card-text", {
            "card-text-expanded": expanded,
            "card-text-collapsed": !expanded,
          })}
        >
          {!title && showCopyLink && clusterId && (
            <>
              <CopyClusterLink clusterId={clusterId} absolute />
              <div ref={titleDiv} />
            </>
          )}
          <TransitionGroup>
            <RenderIf
              condition={expanded && visible}
              Wrapper={ClusterTransition}
              keepRendered={keepRendered}
            >
              {children}
            </RenderIf>
          </TransitionGroup>
        </CardText>
      </Card>
    </div>
  );
};

FullCard.defaultProps = {
  titleRenderer: identity,
};

FullCard.propTypes = Object.freeze({
  autoFocus: PropTypes.oneOf(["self", "descendant"]),
  expanded: PropTypes.bool.isRequired,
  setExpanded: PropTypes.func.isRequired,
  level: PropTypes.number,
  expandable: PropTypes.bool.isRequired,
  actAsExpander: PropTypes.bool,
  showExpandableButton: PropTypes.bool,
  removed: PropTypes.bool.isRequired,
  title: PropTypes.node,
  titlePrefix: PropTypes.node,
  titleSuffix: PropTypes.node,
  titleStyle: styleShape,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node.isRequired).isRequired,
    PropTypes.node.isRequired,
  ]),
  deleteDisabled: PropTypes.bool,
  onDelete: PropTypes.func,
  onRestore: PropTypes.func,
  onTitleUpdate: PropTypes.func,
  titleRenderer: PropTypes.func,
  keepRendered: PropTypes.bool,
});

const ClusterTransition = ({ children, hidden }) => (
  <CSSTransition
    className={classNames({ hidden })}
    timeout={{ enter: 500, exit: 200 }}
  >
    <div>{children}</div>
  </CSSTransition>
);

const ClusterCard = compose(
  setDisplayName("ClusterCard"),
  ignoreKnownIrrelevancies,
  withProps(({ className, collapse, expandable, title, removed = false }) => ({
    className: classNames(className, { "cluster-card-removed": removed }),
    expandable:
      typeof expandable === "boolean"
        ? expandable
        : !removed && typeof collapse === "string",
    removed,
    title: typeof collapse === "string" ? collapse : title,
  })),
  withState("expanded", "setExpanded", ({ expandable, expanded }) =>
    typeof expanded === "boolean" ? expanded : !expandable
  ),
  consumeClusterPresentation,
  withProps(({ expanded, removed, level, clusterExpansion, expandable }) => {
    const forceExpanded = !removed && level <= clusterExpansion;
    return {
      expanded: (expanded && !removed) || forceExpanded,
      expandable: expandable && !forceExpanded,
    };
  }),
  withoutProps("collapse")
)(FullCard);

export default ClusterCard;
