import Checkbox from "material-ui/Checkbox";
import { ListItem } from "material-ui/List";
import PropTypes from "prop-types";
import { compose, setDisplayName, withHandlers, withProps } from "recompose";

const ItemToPick = ({
  itemId,
  disabled,
  label,
  bieb,
  container,
  selected,
  handleSelect,
}) => (
  <ListItem
    key={itemId}
    leftCheckbox={
      <Checkbox
        checked={selected[itemId] !== undefined}
        disabled={disabled}
        onCheck={handleSelect}
      />
    }
    primaryText={label}
    secondaryText={(bieb && container) || undefined}
    title={container}
  />
);

ItemToPick.propTypes = Object.freeze({
  itemId: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  bieb: PropTypes.bool,
  container: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  selected: PropTypes.shape({}),
  handleSelect: PropTypes.func.isRequired,
});

export default compose(
  setDisplayName("ItemToPick"),
  withProps(({ disabled, itemId, selected, multiple }) => ({
    disabled:
      disabled ||
      (!selected[itemId] && !multiple && Object.keys(selected).length === 1),
  })),
  withHandlers({
    handleSelect:
      ({ itemId, setSelected, onSelect }) =>
      (_, checked) => {
        setSelected(itemId, checked);
        if (checked && onSelect) {
          onSelect(itemId);
        }
      },
  })
)(ItemToPick);
