import Checkbox from "material-ui/Checkbox";
import FlatButton from "material-ui/FlatButton";
import Subheader from "material-ui/Subheader";
import { useState } from "react";

import { Relation, SiteLink } from "../../../business/models";
import Dialog from "../../../containers/dialog";

const ModuleVeldenDialoog = ({
  fieldName,
  value,
  onClose,
  onSave,
  modules,
}: {
  fieldName: string;
  value: Relation[];
  onClose: () => void;
  onSave: (selectedModules: number[]) => void;
  modules: SiteLink[];
}) => {
  const [selectedModules, setSelectedModules] = useState<number[]>(
    value.map(({ right }) => right.itemId)
  );
  const handleSave = () => {
    onSave(selectedModules);
    onClose();
  };

  return (
    <Dialog
      open
      autoScrollBodyContent
      title={`Modules koppelen aan applicatieveld '${fieldName}'`}
      actions={[
        <FlatButton
          key="cancel"
          secondary
          label="Annuleren"
          onClick={onClose}
        />,
        <FlatButton key="ok" label="Bewaren" primary onClick={handleSave} />,
      ]}
    >
      <Subheader style={{ paddingLeft: 0 }}>
        Kies de modules waar het applicatieveld in voorkomt
      </Subheader>
      {modules.map(({ itemId, label }) => (
        <Checkbox
          key={itemId}
          value={itemId}
          label={label}
          checked={selectedModules.includes(itemId)}
          onCheck={(_, isChecked) => {
            setSelectedModules(
              isChecked
                ? [...selectedModules, itemId]
                : selectedModules.filter((id) => id !== itemId)
            );
          }}
        />
      ))}
    </Dialog>
  );
};

export default ModuleVeldenDialoog;
