import empty from "empty";
import {
  defaultTo,
  entries,
  find,
  flow,
  get,
  includes,
  map,
  reduce,
  filter as where,
} from "lodash/fp";
import { createSelector, createStructuredSelector } from "reselect";

import { defaultInitialOrder } from "../components/list";
import { emptySelection } from "../components/list/facets";
import {
  defaultSearchScope,
  serializeScope,
} from "../components/search/constants";
import { emptyLookup, structuredMap } from "../utils";
import { legoblokStructuresSelector } from ".";

const searchQuery = (
  { form: { searchQuery = empty.object } = empty.object },
  { uid } = empty.object
) => searchQuery[uid] || empty.object;

const getQueryField = (property, defaultValue) =>
  createSelector(
    [searchQuery],
    (searchQuery) => searchQuery[property] || defaultValue
  );

const selections = getQueryField("selections", emptySelection);
const filter = getQueryField("filter", "");
const order = getQueryField("order", defaultInitialOrder);
const columnFilters = createSelector(
  [searchQuery],
  flow(
    entries,
    where(([key]) => key.startsWith("column-")),
    reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key.substring("column-".length)]: value,
      }),
      {}
    )
  )
);

export default createStructuredSelector({
  selections,
  filter,
  columnFilters,
  order,
});

const searchResultSelector = ({
  data: { searchResult = empty.object } = empty.object,
}) => searchResult;

export const miniSaarsSelector = ({
  data: {
    miniSaars: { data: miniSaarsArray = emptyLookup("variantId") },
  } = empty.object,
}) => miniSaarsArray;

export const currentMiniSaarSelector = ({
  session: { miniSaar: currentMiniSaar = empty.object } = empty.object,
}) => currentMiniSaar;

const enhanceSearchResult = (
  searchResult,
  miniSaars,
  currentMiniSaar,
  structures,
  searchScope
) => {
  const biebStructures = map("id")(structures);
  return structuredMap({
    documents: map((d) => {
      const variantId = parseInt(d.fields.variantId, 10);
      const siteId = parseInt(d.fields.sitIdt, 10);
      const insideBieb = includes(siteId)(biebStructures);
      const miniSaar =
        miniSaars.variantId[variantId || currentMiniSaar.variantId] ||
        empty.object;
      const miniSaarName =
        searchScope === "library" || searchScope === "site"
          ? undefined
          : insideBieb
          ? find({ id: siteId })(structures).name
          : miniSaar.name;
      return {
        lookup: d.lookup,
        fields: {
          ...d.fields,
          miniSaar,
          miniSaarName,
          saar: miniSaarName,
        },
        insideBieb,
      };
    }),
  })(searchResult);
};

const globalQueryScopeSelector = flow(
  get("form.globalQuery.scope"),
  defaultTo(defaultSearchScope),
  serializeScope
);

export const searchResultWithMiniSaarSelector = createSelector(
  [
    searchResultSelector,
    miniSaarsSelector,
    currentMiniSaarSelector,
    legoblokStructuresSelector,
    globalQueryScopeSelector,
  ],
  enhanceSearchResult
);

const autoCompleteSelector = ({
  data: { autoComplete = empty.object } = empty.object,
}) => autoComplete;

export const autoCompleteWithMiniSaarSelector = createSelector(
  [
    autoCompleteSelector,
    miniSaarsSelector,
    currentMiniSaarSelector,
    legoblokStructuresSelector,
  ],
  enhanceSearchResult
);
