import empty from "empty";
import { compose, setDisplayName } from "recompose";
import { createStructuredSelector } from "reselect";

import { cancelStage, confirmStage } from "../actions/common";
import Confirm from "../components/confirm";
import connect from "./connect";

const stageSelector = createStructuredSelector({
  stage: ({ stage: { stages = empty.array } }) => stages[0] || empty.object,
});

export default compose(
  setDisplayName("Confirm"),
  connect(stageSelector, (dispatch) => ({
    onCancel: () => dispatch(cancelStage()),
    onConfirm: (action) => dispatch(confirmStage(action)),
  }))
)(Confirm);
