const Glass = () => (
  <div
    style={{
      background: "rgba(255, 255, 255, 25%)",
      position: "fixed",
      top: 0,
      left: 0,
      height: "100dvh",
      width: "100dvw",
      zIndex: 10000,
    }}
  ></div>
);

export default Glass;
