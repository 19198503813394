import { toAsyncResponse } from "async-lifecycle-saga";
import empty from "empty";
import { filter, find, flow, join, map, toPairs } from "lodash/fp";
import { hashHistory } from "react-router";

import { iproxUrl } from "../config";
import { firstSlug } from "../selectors";
import { PayloadType, resetAuth, tokenFor } from "./auth.common";

const fetchDefaultConfig = {
  headers: {
    "Content-type": "application/json",
    Accept: "application/json",
  },
  credentials: "same-origin",
  resultMethod: "json",
};

let sessionTimestamp;

export const session = {
  get timestamp() {
    return sessionTimestamp;
  },

  clear: () => (sessionTimestamp = undefined),

  refresh: (now) => (sessionTimestamp = now),
};

export const resetSession = (reload = false) => {
  sessionTimestamp = undefined;
  sessionStorage.removeItem("variant");
  resetAuth(!reload);

  if (reload) {
    // reload current location
    global.document.location.reload();
  }
};

export const toQueryString = flow(
  toPairs,
  filter(([_, value]) => value !== undefined && value !== null),
  map(
    ([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
  ),
  join("&")
);

/**
 * Fetches the URL
 * @param  {string} url full url to the endpoint
 * @param  {object} config specific for this fetch
 * @param  {Array<key, value>} headers array with query-string parameters
 */
const fetchJSON = async (url, config = empty.object, headers = empty.array) => {
  // construct config
  const composedConfig = { ...fetchDefaultConfig, ...config };

  const accessToken =
    composedConfig.credentials === "same-origin" &&
    composedConfig.bearer !== "omit" &&
    !find({ key: "Authorization" })(config.headers)
      ? tokenFor(PayloadType.access)
      : undefined;

  // construct headers
  const composedHeaders = {
    ...fetchDefaultConfig.headers,
    ...(accessToken
      ? { Authorization: `Bearer ${accessToken}` }
      : empty.object),
    ...(config.headers || empty.object),
    ...headers.reduce(
      (o, { key, value }) => ({ ...o, [key]: value }),
      empty.object
    ),
  };

  const wasInVariant = inEstablishedVariant(url);
  const response = await fetch(url, {
    ...composedConfig,
    headers: composedHeaders,
  });

  if (!inEstablishedVariant(url) && wasInVariant) {
    // eslint-disable-next-line
    throw {
      name: "Error in fetch",
      notify: false,
      message: "Request expired",
      status: 408,
      url,
    };
  }

  if (composedConfig.resultMethod === "Response") {
    return response;
  }

  if (response.status >= 200 && response.status < 300) {
    if (response.status === 204) {
      // no content
      return Promise.resolve(empty.object);
    }

    return response[composedConfig.resultMethod]().then(
      (d) => d || empty.object
    );
  }

  if (
    !/\/authentication\/(office365|google)\?/.test(url) &&
    (response.status === 401 || response.status === 499)
  ) {
    resetSession(true);
  }

  let friendly, operationId;
  try {
    const result = await response[composedConfig.resultMethod]();
    friendly = result.friendlyMessage;
    operationId = result.operationId;
  } catch (_) {
    // ignore
  }

  let notify;
  switch (response.status) {
    case 401:
    case 403:
    case 409:
    case 499:
      notify = true;
      break;
    case 400:
    case 500:
      notify =
        Boolean(friendly) ||
        (composedConfig.method !== "GET" && !/\/search/.test(url));
      break;
    default:
      notify = Boolean(friendly);
      break;
  }

  // eslint-disable-next-line
  throw {
    name: "Error in fetch",
    friendly,
    notify,
    operationId,
    message: response.statusText,
    status: response.status,
    url: response.url,
  };
};

/**
 * Gets the URL
 * @param  {string} url full url to the endpoint
 * @param  {Array<key, value>} params array with query-string parameters
 * @param  {Array<key, value>} headers array with query-string parameters
 * @param config configuration
 */
export const getJSON = async (
  url,
  params = empty.array,
  headers = empty.array,
  config = empty.object
) => {
  const query = params
    .map(
      ({ key, value }) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    )
    .join("&");
  return fetchJSON(
    query ? `${url}?${query}` : url,
    { method: "GET", ...config },
    headers
  );
};

/**
 * Posts the URL
 * @param  {string} url full url to the endpoint
 * @param  {object} body object
 * @param  {Array<key, value>} headers array with query-string parameters
 * @param  {object} config object
 */
export const postJSON = async (
  url,
  body = empty.object,
  headers = empty.array,
  config = empty.object
) =>
  fetchJSON(
    url,
    { method: "POST", body: JSON.stringify(body), ...config },
    headers
  );

/**
 * Gets the already established variant if available
 */
const getEstablishedVariant = () => {
  const {
    pathname = "",
    query: { next = "" },
  } = hashHistory.getCurrentLocation();
  const alias =
    pathname === "/inloggen" ? firstSlug(next) : firstSlug(pathname);
  const json = sessionStorage.getItem("variant");

  if (!json) {
    return [undefined, { alias }];
  }

  const variant = JSON.parse(json);
  if (variant.alias !== alias) {
    return [undefined, { alias }];
  }

  return [variant, { alias }];
};

/**
 * Checks if url is in established variant
 */
const inEstablishedVariant = (url) => {
  const [established] = getEstablishedVariant();
  if (!established) {
    return false;
  }

  return url.startsWith(`${iproxUrl}/appidt/saar/${established.key}/`);
};

/**
 * Gets the variant
 */
const getVariant = async () => {
  const [established, alias] = getEstablishedVariant();
  if (established) {
    return established;
  }

  const variant = await getJSON(
    `${iproxUrl}/appidt/saar/variant?${toQueryString(alias)}`
  ).then(({ variantKey: key, siteKey: _, ...rest }) => ({
    ...rest,
    key,
  }));
  sessionStorage.setItem("variant", JSON.stringify(variant));
  return variant;
};

export const getSettings = async () => {
  const json = sessionStorage.getItem("settings");
  if (json) {
    return JSON.parse(json);
  }
  const settings = await getJSON(`${iproxUrl}/appidt/saar/settings`);
  sessionStorage.setItem("settings", JSON.stringify(settings));
  return settings;
};

/**
 * Fetches JSON for current variant (POST if body, GET otherwise)
 * @param path
 * @param body
 * @param headers
 * @param options
 */
export const fetchVariantJSON = async (path, body, headers, options) => {
  if (!tokenFor(PayloadType.access)) {
    // eslint-disable-next-line
    throw {
      name: "Fetch cancelled",
      message: "Cancelled",
      status: 418,
      path,
    };
  }

  const { key } = await getVariant();
  const url = `${iproxUrl}/appidt/saar/${key}/${path}`;

  return body ? postJSON(url, body, headers, options) : getJSON(url);
};

/**
 * Gets response for current variant
 */
const responseOptions = { resultMethod: "Response" };
export const variantFetch = async (
  path,
  body,
  headers,
  options = empty.object
) => {
  if (!tokenFor(PayloadType.access)) {
    // eslint-disable-next-line
    throw {
      name: "Fetch cancelled",
      message: "Cancelled",
      status: 418,
      path,
    };
  }

  const { key } = await getVariant();
  const url = `${iproxUrl}/appidt/saar/${key}/${path}`;
  const config = { ...options, ...responseOptions };

  return (
    body
      ? postJSON(url, body, headers, config)
      : getJSON(url, empty.array, headers, config)
  ).then(toAsyncResponse);
};

export const postFetch = async (
  path,
  body,
  headers,
  options = empty.object
) => {
  if (!tokenFor(PayloadType.access)) {
    // eslint-disable-next-line
    throw {
      name: "Fetch cancelled",
      message: "Cancelled",
      status: 418,
      path,
    };
  }

  const url = `${iproxUrl}/appidt/saar/${path}`;
  const config = { ...options, ...responseOptions };

  return postJSON(url, body, headers, config).then(toAsyncResponse);
};
