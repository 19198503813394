/* eslint  react/prop-types: off */
import empty from "empty";
import IconButton from "material-ui/IconButton";
import { lightGreen100 } from "material-ui/styles/colors";
import ActionHighlightOff from "material-ui/svg-icons/action/highlight-off";
import PropTypes from "prop-types";
import React from "react";
import Dropzone from "react-dropzone";
import {
  compose,
  defaultProps,
  setDisplayName,
  setPropTypes,
  withHandlers,
  withState,
} from "recompose";

import { iproxUrl } from "../../config";
import { concat, upload } from "../../utils";
import { propTypes, withDefaultPageActions } from "./ggvtyp";

const AfbeeldingDropzoneComponent = ({
  className,
  isEmpty,
  fileName,
  droppedFiles,
  path,
  dirty,
  handleDrop,
  handleDelete,
}) => (
  <div style={{ lineHeight: "1em" }}>
    <Dropzone
      multiple={false}
      onDrop={handleDrop}
      style={{
        height: "inherit",
        color: "#999",
      }}
      activeStyle={{
        backgroundColor: lightGreen100,
      }}
    >
      {(isEmpty && !dirty && fileName === "") || (dirty && fileName === "") ? (
        "Plaats een afbeelding"
      ) : droppedFiles.length > 0 ? (
        <img
          className={className}
          src={droppedFiles[0].preview}
          alt="Klik op de afbeelding om deze te wijzigen"
        />
      ) : path && !isEmpty ? (
        <img
          className={className}
          src={`${iproxUrl}${path}`}
          alt="Klik op de afbeelding om deze te wijzigen"
        />
      ) : (
        fileName
      )}
    </Dropzone>
    <IconButton
      disabled={
        (isEmpty && !dirty && fileName === "") || (dirty && fileName === "")
      }
      style={{
        display: "block",
        position: "absolute",
        top: 0,
        right: 0,
        border: 0,
        padding: 0,
        width: 24,
        height: 24,
      }}
      onClick={handleDelete}
      title="Verwijder afbeelding"
    >
      <ActionHighlightOff />
    </IconButton>
  </div>
);

const AfbeeldingDropzone = compose(
  setDisplayName("AfbeeldingDropzone"),
  withState("droppedFiles", "dropFiles", empty.array),
  withHandlers({
    handleDrop:
      ({ disabled, dropFiles, setValue }) =>
      (files) => {
        if (!disabled) {
          dropFiles(files);
          for (const file of files) {
            upload(setValue)(file);
          }
        }
      },

    handleDelete:
      ({ setValue }) =>
      () =>
        setValue(empty.object),
  })
)(AfbeeldingDropzoneComponent);

/**
 * Gegevenstype 'Afbeelding' (10)
 */
const Afbeelding = ({
  field: { assetPath = "/", isEmpty = true } = empty.object,
  value: { fileName = "", path = concat(assetPath, fileName) } = empty.object,
  dirty,
  setValue,
  pageActions: { busy, edit },
  className,
  handleImageClick,
}) =>
  edit || (busy && dirty) ? (
    <AfbeeldingDropzone
      className={className}
      disabled={busy}
      setValue={setValue}
      isEmpty={isEmpty}
      fileName={fileName}
      path={path}
      dirty={dirty}
    />
  ) : isEmpty ? null : (
    <img
      alt=""
      className={className}
      src={`${iproxUrl}${path}`}
      onClick={handleImageClick}
      style={{ cursor: "pointer" }}
    />
  );

const enhance = compose(
  setDisplayName("Afbeelding"),
  setPropTypes({
    ...propTypes,
    field: PropTypes.shape({
      value: PropTypes.shape({
        fileName: PropTypes.string.isRequired,
        libraryId: PropTypes.number,
      }),
      assetPath: PropTypes.string.isRequired,
    }).isRequired,
  }),
  defaultProps({
    className: "afbeelding",
  }),
  withDefaultPageActions(),
  withHandlers({
    handleImageClick:
      ({
        field: { assetPath = "/" } = empty.object,
        value: {
          fileName = "",
          path = concat(assetPath, fileName),
        } = empty.object,
        toggleImageLightbox,
      }) =>
      () =>
        toggleImageLightbox(true, `${iproxUrl}${path}`),
  })
);

export default enhance(Afbeelding);
