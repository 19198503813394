import empty from "empty";
import { compact, flatten, flow, isEqual, map, some } from "lodash/fp";
import MenuItem from "material-ui/MenuItem";
import SelectField from "material-ui/SelectField";
import PropTypes from "prop-types";
import React from "react";
import { compose, pure, setDisplayName, withHandlers } from "recompose";

import { listItemShape } from "./shapes";

const MultiSelect = ({ label, handleChange, values, list }) => (
  <SelectField
    fullWidth
    multiple
    hintText={label}
    value={values}
    onChange={handleChange}
  >
    {flow(
      map(({ value, label, list: children = empty.array }) => [
        <MenuItem
          key={JSON.stringify(value)}
          value={value}
          primaryText={label}
          insetChildren={children.length === 0}
          checked={children.length === 0 ? some(isEqual(value))(values) : false}
          disabled={children.length > 0}
        />,
        ...map(({ value: childValue, label: childLabel }) => (
          <MenuItem
            key={JSON.stringify(childValue)}
            value={childValue}
            primaryText={childLabel}
            insetChildren
            checked={some(isEqual(childValue))(values)}
          />
        ))(children),
      ]),
      flatten,
      compact
    )(list)}
  </SelectField>
);

MultiSelect.propTypes = {
  label: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  values: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
  list: PropTypes.arrayOf(listItemShape.isRequired).isRequired,
};

export default compose(
  setDisplayName("MultiSelect"),
  pure,
  withHandlers({
    handleChange:
      ({ onChange }) =>
      (event, index, values) =>
        onChange(values),
  })
)(MultiSelect);
