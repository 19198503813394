/* eslint  react/prop-types: off */
import render from "dom-serializer";
import empty from "empty";
import {
  compact,
  eq,
  flattenDeep,
  flow,
  get,
  has,
  includes,
  isEmpty,
  join,
  map,
  omit,
  some,
} from "lodash/fp";
import React, { memo } from "react";
import SafeInnerHtml, { parseHTML } from "react-safeinnerhtml";

import { iproxUrl } from "../config";
import MediaWidget from "../containers/mediaWidget";
import { flown } from "../lodash";
import { isNonEmptyString } from "../utils";
import { PathLink } from "./relation.pathlink";
import ZoomImage from "./zoomImage";

export const makePrefix = (prefix, value) =>
  typeof value === "string" && value.indexOf(prefix) === 0
    ? value
    : `${prefix}${value}`;

const blockElements = ["p", "table", "ul", "ol", "li", "ol", "div"];
const getText = ({ type, data, children, parent }) => {
  const text =
    type === "text" && data
      ? `${data}${
          parent &&
          parent.type === "tag" &&
          includes(parent.name)(blockElements)
            ? " "
            : ""
        }`
      : "";
  const childrenText = map(getText)(children);
  return flow(flattenDeep, compact)([text, ...childrenText]);
};

export const convertHtml2Text = (html) =>
  isNonEmptyString(html)
    ? flown(html, parseHTML, map(getText), flattenDeep, join(""))
        .replace(/(\s+){2,}/g, " ")
        .replace(/(\s+)$/, "")
    : "";

const changeChild =
  (changeFunc) =>
  ({ children, ...rest }) => {
    const changed = changeFunc({ ...rest });
    return {
      ...changed,
      children: !isEmpty(children)
        ? map(changeChild(changeFunc))(children)
        : undefined,
    };
  };

const changeImageTag =
  (prefix) =>
  ({ type, name, attribs, ...rest }) => {
    if (
      type === "tag" &&
      name === "img" &&
      has("src")(attribs) &&
      has("data-sources")(attribs)
    ) {
      const src = get("src")(attribs);
      const [{ src: imageSource = "" }] = JSON.parse(
        get("data-sources")(attribs)
      );

      return {
        type,
        name,
        attribs: {
          ...omit("data-sources")(attribs),
          src: makePrefix(
            prefix,
            src === "#"
              ? imageSource.indexOf("publish/") > -1
                ? imageSource.substring(imageSource.indexOf("publish/") + 8)
                : imageSource
              : src
          ),
        },
        ...rest,
      };
    }
    return { type, name, attribs, ...rest };
  };

export const resolveImages = (innerHTML, prefix) => {
  const dom = parseHTML(innerHTML);
  const output = map(changeChild(changeImageTag(prefix)))(dom);

  return render(output);
};

const safeInnerHtmlPlugin = ({ rootUrl }) => ({
  "attribute-data-scoped-style": ({ attribute }, { addCss }) => {
    const { nodeValue } = attribute;
    addCss(nodeValue);
    return false;
  },

  "attribute-pagetype": false,

  "element-a": ({ type, props }) => {
    const href = props.href;
    const classes = props.class
      ? props.class.nodeValue.split(" ")
      : props.className
      ? props.className.split(" ")
      : empty.array;

    if (href && href.indexOf("://") === -1 && some(eq("importLink"))(classes)) {
      // download
      const file =
        href.charAt(1) === "/" ? href.substring(href.indexOf("/", 2)) : href;
      const prefix =
        rootUrl.length > 0 && rootUrl.charAt(rootUrl.length - 1) === "/"
          ? rootUrl.substring(0, rootUrl.length - 1)
          : rootUrl;
      return {
        type,
        props: { ...props, href: makePrefix(`${prefix}/publish/`, file) },
      };
    }

    if (href && some(eq("siteLink"))(classes)) {
      // interne verwijzing
      return {
        type: PathLink,
        props: { ...props, path: `page/${href}` },
      };
    }
    if (href && some(eq("externLink"))(classes)) {
      // externe verwijzing
      return {
        type,
        props: { ...props, target: "_blank" },
      };
    }

    return { type, props };
  },

  "element-img": ({ type, props }, parentNode) => {
    const { src, "data-sources": dataSources } = props;
    const canZoom = parentNode?.name !== "a";
    const prefix =
      rootUrl.length > 0 && rootUrl.charAt(rootUrl.length - 1) === "/"
        ? rootUrl.substring(0, rootUrl.length - 1)
        : rootUrl;

    if (src && src === "#" && dataSources) {
      // catalogus afbeelding
      const [{ src: imageSource = "" }] = JSON.parse(dataSources);
      return {
        type: canZoom ? ZoomImage : type,
        props: {
          ...props,
          src: makePrefix(
            `${prefix}/publish/`,
            imageSource.indexOf("publish/") > -1
              ? imageSource.substring(imageSource.indexOf("publish/") + 8)
              : imageSource
          ),
        },
      };
    }

    if (src && src.indexOf("://") === -1) {
      // afbeelding
      const image =
        src.charAt(1) === "/" ? src.substring(src.indexOf("/", 2)) : src;
      return {
        type: canZoom ? ZoomImage : type,
        props: { ...props, src: makePrefix(`${prefix}/publish/`, image) },
      };
    }

    return { type, props };
  },

  "element-div": ({ type, props }) => {
    const classes = props.class
      ? props.class.nodeValue.split(" ")
      : props.className
      ? props.className.split(" ")
      : empty.array;
    if (props.id && some(eq("mediawidget"))(classes)) {
      const mwtIdt = props.id.replace(/^Mwt_/, "");
      return {
        type: MediaWidget,
        props: {
          ...props,
          id: mwtIdt,
        },
      };
    }

    return { type, props };
  },
});

const SafeInnerHtmlWrapped = memo(({ children, ...rest }) =>
  isNonEmptyString(children) ? (
    <SafeInnerHtml {...safeInnerHtmlPlugin({ rootUrl: iproxUrl })} {...rest}>
      {children}
    </SafeInnerHtml>
  ) : (
    <></>
  )
);

export default SafeInnerHtmlWrapped;
