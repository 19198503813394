import empty from "empty";

import { expandPath } from "../../utils";
import Field from "../content/field";
import { isField } from "../content/query";

const profielAfbeelding = ({ page, path, ...props }) => {
  const [field = empty.object, { clusterId } = empty.object] = expandPath(
    page,
    path
  );
  const {
    definition: { dataTypeCode, id: definitionId } = empty.object,
    isEmpty = false,
  } = field;
  const { pageActions = empty.object } = props;
  return isField(field) &&
    dataTypeCode === 10 &&
    (!isEmpty || pageActions.busy || pageActions.edit) ? (
    <Field
      key={field.id || definitionId}
      {...props}
      field={field}
      fullWidth
      clusterId={clusterId}
    />
  ) : null;
};

export default profielAfbeelding;
