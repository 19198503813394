/* eslint  react/prop-types: off */
import empty from "empty";
import { map } from "lodash/fp";
import FlatButton from "material-ui/FlatButton";
import { List, ListItem } from "material-ui/List";
import Subheader from "material-ui/Subheader";
import TextField from "material-ui/TextField";
import React, { useCallback, useEffect, useState } from "react";

import Dialog from "../../containers/dialog";
import { isNonEmptyArray } from "../../utils";
import PagetypeIcon from "../pagetypeIcon";
import PageRefreshIndicator from "../pagetypes/PageRefreshIndicator";
import { ItemLink } from "../relation.itemlink";
import BiebUseWizard from "./BiebUseWizard";

const BiebUseDialog = ({
  busy,
  error,
  close = empty.func,
  updateLabel = empty.func,
  withHierarchyRelations,
  updateHierarchyRelations,
  biebItem,
  biebItem: { label } = empty.object,
  valid = false,
  created,
  multi,
  doUseBiebItem,
  goHome,
  biebUseSelection,
  setBiebUseSelection,
}) => {
  useEffect(() => {
    if (Array.isArray(created) && created.length === 0) {
      close();
    }
  }, [close, created]);

  const useWizard =
    !busy &&
    !isNonEmptyArray(created) &&
    !Array.isArray(created) &&
    !Array.isArray(multi);
  const [activeBiebStep, setActiveBiebStep] = useState(0);
  const handleActiveStep = useCallback((step) => {
    setActiveBiebStep(step);

    /**
     * Onderstaande triggert een "resize" event waardoor het dialoog
     * opnieuw positie en grootte bepaalt, waardoor hopelijk altijd
     * de knoppen netjes in beeld blijven.
     */
    window.setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 256);
  }, []);

  const [disableAllActions, setDisableAllActions] = useState(false);

  return (
    <Dialog
      title="Overnemen uit Saar Bieb"
      open
      autoScrollBodyContent
      actions={[
        <FlatButton
          key="close"
          secondary
          disabled={busy}
          label="Sluiten"
          onClick={close}
        />,
        useWizard && activeBiebStep <= 2 && (
          <FlatButton
            key="previous"
            disabled={
              error ||
              busy ||
              !(valid || Array.isArray(multi) || Array.isArray(created)) ||
              activeBiebStep === 0 ||
              disableAllActions
            }
            label="Vorige stap"
            onClick={() => handleActiveStep(activeBiebStep - 1)}
          />
        ),
        (!useWizard || activeBiebStep === 2) && (
          <FlatButton
            key="primary"
            primary
            disabled={
              error ||
              busy ||
              !(valid || Array.isArray(multi) || Array.isArray(created)) ||
              disableAllActions
            }
            label={Array.isArray(created) ? "Dashboard" : "Overnemen"}
            onClick={Array.isArray(created) ? goHome : doUseBiebItem}
          />
        ),
        useWizard && activeBiebStep < 2 && (
          <FlatButton
            key="next"
            primary
            disabled={
              error ||
              busy ||
              !(valid || Array.isArray(multi) || Array.isArray(created)) ||
              disableAllActions
            }
            label="Volgende stap"
            onClick={() => handleActiveStep(activeBiebStep + 1)}
          />
        ),
      ]}
    >
      <div>
        {(() => {
          switch (true) {
            case busy:
              return <PageRefreshIndicator />;
            case isNonEmptyArray(created):
              return (
                <>
                  <Subheader>Overgenomen uit Saar Bieb</Subheader>
                  <List>
                    {map((item) => (
                      <ListItem
                        key={item.itemId}
                        primaryText={<ItemLink item={item} onClick={close} />}
                        leftIcon={<PagetypeIcon type={item.type} />}
                      />
                    ))(created)}
                  </List>
                </>
              );
            case Array.isArray(created):
              return <></>;
            case Array.isArray(multi):
              return (
                <>
                  <Subheader>Over te nemen uit Saar Bieb</Subheader>
                  <List>
                    {map((item) => (
                      <ListItem
                        key={item.itemId}
                        primaryText={item.label}
                        leftIcon={
                          <PagetypeIcon type={item.pagetype} insideBieb />
                        }
                      />
                    ))(multi)}
                  </List>
                </>
              );
            default:
              return (
                <>
                  <TextField
                    floatingLabelText="Naam pagina"
                    value={label}
                    onChange={updateLabel}
                    disabled={disableAllActions}
                  />

                  <BiebUseWizard
                    biebItem={biebItem}
                    withHierarchyRelations={withHierarchyRelations}
                    updateHierarchyRelations={updateHierarchyRelations}
                    biebStepper={{
                      activeStep: activeBiebStep,
                      setActiveStep: handleActiveStep,
                    }}
                    value={biebUseSelection}
                    onChange={setBiebUseSelection}
                    close={close}
                    setDisableAllActions={setDisableAllActions}
                  />
                </>
              );
          }
        })()}
      </div>
    </Dialog>
  );
};

export default BiebUseDialog;
