import empty from "empty";
import {
  defaultTo,
  every,
  filter,
  find,
  flatten,
  flow,
  get,
  identity,
  includes,
  keyBy,
  map,
  pick,
  some,
  uniq,
} from "lodash/fp";
import { createSelector, createStructuredSelector } from "reselect";

import { SelectionScope, hasSelectionScope } from "../business";
import { flown, innerJoin } from "../lodash";
import { structuredMap } from "../utils";

const fieldDefinitionsById = createSelector(
  [
    ({
      data: {
        fieldDefinitions: { definitions = empty.array },
      },
    }) => definitions,
  ],
  keyBy("id")
);

const fieldDefinitionSelector = createSelector(
  [
    fieldDefinitionsById,
    (_, { field: { definition: { id } = empty.object } }) => id,
  ],
  (definitions, id) => definitions[id]
);

export const fieldDefinition = createStructuredSelector({
  fieldDefinition: fieldDefinitionSelector,
});

const selectionListsById = createSelector(
  [
    ({
      data: {
        selectionLists: { data = empty.array },
      },
    }) => data,
  ],
  keyBy("id")
);

const selectionListSelector = createSelector(
  [
    selectionListsById,
    (_, { fieldDefinition: { selectionListId } = empty.object }) =>
      selectionListId,
    (
      {
        data: {
          addLegoblok: { structures = empty.array } = empty.object,
          miniSaars: { sharedStructureIds = empty.array } = empty.object,
          pages,
        },
      },
      { pageActions: { pageId } = empty.object }
    ) => {
      const siteId = pages[pageId]?.siteId;
      return (
        siteId &&
        (sharedStructureIds.includes(siteId) ||
          flown(
            structures,
            some(({ id }) => id === siteId)
          ))
      );
    },
  ],
  (selectionLists, id, shared) => {
    const list = selectionLists[id] ?? empty.array;
    const scopeFilter = hasSelectionScope(
      shared ? SelectionScope.shared : SelectionScope.saar
    );

    if (
      shared === undefined ||
      flown(
        list.items,
        every(({ scope }) => scopeFilter(scope))
      )
    ) {
      return list;
    }

    // Known limitation: this only filters on the first level of the selection list.
    const items = list.items.filter((item) => scopeFilter(item.scope));
    return { ...list, items };
  }
);

export const selectionList = createStructuredSelector({
  selectionList: selectionListSelector,
});

export const selectionListsByPagetype = createSelector(
  [
    ({
      data: {
        fieldDefinitions: { definitions = empty.array } = empty.object,
      } = empty.object,
    }) => definitions,
    ({
      data: {
        pagetypeList: { data = empty.array } = empty.object,
      } = empty.object,
    }) => data,
    ({
      data: {
        selectionLists: { data = empty.array } = empty.object,
      } = empty.object,
    }) => data,
  ],
  (definitions, pagetypes, lists) => {
    const lifecycleId =
      flown(lists, find({ alias: "lifecycle" }), get("id")) || 0;
    return flown(
      pagetypes,
      map(({ alias }) => {
        return {
          alias,
          lists: flown(
            definitions,
            filter(
              (def) =>
                (def.selectionListId || def.name === "Eind datum") &&
                flown(
                  def.pagetypes,
                  map((pagetype) => pagetype.split("_")),
                  flatten,
                  includes(alias)
                )
            ),
            map((def) => def.selectionListId || lifecycleId),
            uniq,
            innerJoin(identity, lists, get("id")),
            map(get("[1]")),
            map(pick(["id", "items"])),
            map(structuredMap({ items: map("id") }))
          ),
        };
      }),
      filter((p) => p.lists && p.lists.length > 0)
    );
  }
);

const raamwerkStatusSelector = createSelector(
  [flow(get("data.selectionLists.data"), defaultTo(empty.array))],
  find({ alias: "raamwerk_status" })
);

export const raamwerkFieldSelector = createStructuredSelector({
  selectionList: selectionListSelector,
  raamwerkStatus: raamwerkStatusSelector,
});

export const statusListSelector = createSelector(
  [flow(get("data.selectionLists.data"), defaultTo(empty.array))],
  find({ alias: "status" })
);
