import empty from "empty";
import {
  amber500 as accent1Color,
  amber900 as biebColor,
  lightBlue500 as externAppColor,
  purple500 as nieuwColor,
  grey500 as oudColor,
  lightGreen500 as primary1Color,
  orange500 as primary2Color,
} from "material-ui/styles/colors";
import { combineReducers } from "redux";

import { Viewport } from "../actions/viewport";
import { resultSetReducer } from "../business/store/resultset";

/**
 * Theme-reducer; this reducers creates config needed for MaterialUI theme
 * @return Redux reducer
 */
export const theme = empty.functionThatReturns(
  Object.freeze({
    palette: Object.freeze({
      primary1Color,
      primary2Color,
      accent1Color,
      biebColor,
      externAppColor,
      nieuwColor,
      oudColor,
    }),
    appBar: Object.freeze({
      height: 50,
    }),
  })
);

const classes = (state = empty.array, { type, payload }) => {
  switch (type) {
    case "UI_CLASSNAME_ADD":
      return [...state, payload];
    case "UI_CLASSNAME_REMOVE":
      return state.filter((c) => c !== payload);
    case "UI_CLASSNAME_RESET":
      return empty.array;
    default:
      return state;
  }
};

const secondaryDrawer = (state = false, { type, payload }) =>
  type === "UI_SECONDARYDRAWER_UPDATE" ? payload : state;

const viewport = ({ viewport = Viewport.size } = empty.object, action) =>
  action.type === "RESIZE_MEASURE" ? action.viewport : viewport;

const raamwerkFilter = (state = empty.array, { type, payload }) => {
  switch (type) {
    case "UI_RAAMWERKFILTERITEM_SELECT":
      return [...state, payload.id].sort((a, b) => a - b);
    case "UI_RAAMWERKFILTERITEM_DESELECT":
      return state.filter((id) => id !== payload.id);
    case "UI_RAAMWERKFILTERITEM_RESET":
      return empty.array;
    default:
      return state;
  }
};

const previewDefaultState = Object.freeze({ open: false });
const preview = (
  state = previewDefaultState,
  { type, payload: { itemId } = empty.object }
) => {
  switch (type) {
    case "UI_PREVIEW_SET":
      return typeof itemId === "number"
        ? {
            open: true,
            itemId,
          }
        : previewDefaultState;
    case "UI_PREVIEW_CLEAR":
      return previewDefaultState;
    default:
      return state;
  }
};

const versionDiffState = Object.freeze({ open: false });
const versionDiff = (
  state = versionDiffState,
  { type, payload: { itemId, ...rest } = empty.object }
) => {
  switch (type) {
    case "UI_VERSIONDIFF_SET":
      return typeof itemId === "number"
        ? { open: true, itemId, ...rest }
        : versionDiffState;
    case "UI_VERSIONDIFF_CLEAR":
      return versionDiffState;
    default:
      return state;
  }
};

const versionsDrawerState = Object.freeze({ show: false });
const versionsDrawer = (
  state = versionsDrawerState,
  { type, payload: { show } = empty.object }
) => (type === "UI_SHOWVERSIONSDRAWER" ? { show } : state);

const copyItemState = Object.freeze({ open: false });
const copyItemDialog = (
  state = copyItemState,
  { type, payload: { open } = empty.object }
) => (type === "TOGGLE_COPYDIALOG" ? { open } : state);

export const initialSnackbarState = Object.freeze({ open: false });
const snackbarReducer = (state = initialSnackbarState, { type, payload }) => {
  switch (type) {
    case "UI_SNACKBAR_SHOW":
      return {
        open: true,
        message: payload.message,
        options: payload.options ?? empty.object,
      };
    case "UI_SNACKBAR_HIDE":
      return {
        open: false,
      };
    default:
      return state;
  }
};

export default combineReducers({
  classes,
  copyItemDialog,
  preview,
  raamwerkFilter,
  secondaryDrawerOpen: secondaryDrawer,
  theme,
  versionDiff,
  versionsDrawer,
  viewport,
  snackbar: snackbarReducer,
  resultSet: resultSetReducer,
});
