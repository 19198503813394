import { Card, CardText } from "material-ui/Card";
import React, { memo, useCallback, useState } from "react";
import ReactMarkdown from "react-markdown";

interface RemarkTooltipIconProps {
  children: React.ReactNode;
  text: string;
  style?: object;
}

const RemarkTooltipIcon = memo<RemarkTooltipIconProps>(
  ({ children, text, style }) => {
    const [show, update] = useState(false);
    const toggleTooltip = useCallback(() => {
      update(!show);
    }, [show]);

    return (
      <span
        onMouseEnter={toggleTooltip}
        onMouseLeave={toggleTooltip}
        style={{ position: "relative", ...style }}
      >
        {show && (
          <Card
            style={{
              position: "absolute",
              right: "1em",
              top: "1em",
              width: 320,
            }}
          >
            <CardText>
              <ReactMarkdown
                source={text}
                softBreak="br"
                disallowedTypes={["Heading"]}
              />
            </CardText>
          </Card>
        )}
        {children}
      </span>
    );
  }
);

export default RemarkTooltipIcon;
