import empty from "empty";
import {
  compact,
  defaultTo,
  eq,
  every,
  filter,
  flattenDeep,
  flow,
  get,
  includes,
  keys,
  map,
  reject,
  some,
} from "lodash/fp";
import { Card } from "material-ui/Card";
import React from "react";
import { parseHTML } from "react-safeinnerhtml";
import { createSelector } from "reselect";

import { Side, isLeftShowGrouped } from "../business/relations";
import ItemList from "../containers/list";
import EmbedContainer from "../containers/pagetypes/EmbedContainer";
import { flown, innerJoin, setup } from "../lodash";
import {
  koppelingFieldsSelector,
  koppelingVeldenRelationsSelector,
  presentationRelationsSelector,
  processItemsSelector,
  processRelationTypesSelector,
  scopeSelector,
} from "../selectors/relations";
import {
  applicationsPropSelector,
  biebStructuresSelector,
  chainRelationsSelector,
  columnFiltersSelector,
  contentSelector,
  entitylandscapeSelector,
  facetsSelector,
  filterSelector,
  formSelector,
  gotoVerantwoordingenSelector,
  itemIdSelector,
  landscapeFiltersSelector,
  landscapeItemSelector,
  landscapeSelector,
  miniSaarsSelector,
  notesSelector,
  onDeselectSelector,
  onSelectSelector,
  orderSelector,
  pageActionsSelector,
  pageSelector,
  pagetypeListSelector,
  pagetypeSelector,
  pagetypesSelector,
  presentationItemsSelector,
  presentationTabsSelector,
  profielPropsSelector,
  propertiesSelector,
  raamwerkSelector,
  relationsListSelector,
  rowsSelector,
  selectionListsSelector,
  selectionSelector,
  selectionsSelector,
  setColumnFilterSelector,
  setFilterSelector,
  setOrderSelector,
  setSelectionsSelector,
  siblingsSelector,
  treeSelector,
  typesSelector,
  uiActionsSelector,
  useLegoblokDialogSelector,
  userSelector,
  verantwoordingSelector,
} from "../selectors/tabs";
import { isEmpty, isNonEmptyArray, isNonEmptyString, labelize } from "../utils";
import Cluster from "./content/cluster";
import Field from "./content/field";
import { isField, isVisible } from "./content/query";
import profielAfbeelding from "./ggvtyp/profielAfbeelding";
import ClusterCard from "./material/clusterCard";
import ItemNotesBadge from "./notes/itemNotesBadge";
import getSpecial, { isSpecial } from "./pagetypes/specials";
import SafeInnerHtml from "./safeInnerHtml";
import { showRelatedPagetypes } from "./ShowPagetypeIcons";

const hasSpecial = (name) => some(flow(get("special.name"), eq(name)));
const hasSpecialOpmerkingen = hasSpecial("Opmerkingen");
const hasSpecialRelatieToevoegen = hasSpecial("Relatie toevoegen");

export const ZoneCode = labelize([
  "none",
  "content",
  "header",
  "rightbar",
  "none",
  "firstSpecial",
  "leftbar",
]);

function renderPresentationItem(
  { name: zoneName, zoneCode }, // 0
  { content, relationType, relationTypes, special }, // 1
  itemId, // 2
  getContent, // 3
  getRelations, // 4
  page, // 5
  pageActions, // 6
  pagetypes, // 7
  selectionLists, // 8
  pagetypeList, // 9
  facets, // 10
  rows, // 11
  onSelect, // 12
  onDeselect, // 13
  setFilter, // 14
  setSelections, // 15
  setOrder, // 16
  selections, // 17
  filter, // 18
  order, // 19
  properties, // 20
  notes, // 21
  form, // 22
  user, // 23
  profielProps, // 24
  tree, // 25
  relations, // 26
  types, // 27
  currentSteps, // 28
  processTypes, // 29
  landscape, // 30
  landscapeItem, // 31
  landscapeFilters, // 32
  tabInfo, // 33
  uiActions, // 34
  biebStructures, // 35
  verantwoording, // 36
  gotoVerantwoordingen, // 37
  applications, // 38
  koppelingFields, // 39
  koppelingVeldenRelations, // 40
  chainRelations, // 41
  miniSaars, // 42
  pagetype, // 43
  siblings, // 44
  item, // 45
  raamwerk, // 46
  doUseLegoblokDialog, // 47
  columnFilters, // 48
  setColumnFilter, // 49
  entitylandscape, // 50
  relatieToevoegen // 51
) {
  switch (true) {
    case Boolean(content) &&
      zoneCode === ZoneCode.firstSpecial &&
      content.entity.ggvTyp === 10:
      return profielAfbeelding({
        ...profielProps,
        page,
        pageActions,
        path: content.path,
      });

    case Boolean(content) && content.path === "embed":
      if (pageActions.edit) {
        return getContent(content);
      }

      return map((cluster) => (
        <EmbedContainer key={cluster.id} cluster={cluster} />
      ))(page.embed.clusters);

    case Boolean(content):
      if (
        content.path === "toegang" &&
        !(user.iznet === "Read" || user.iznet === "Write")
      ) {
        return null;
      }

      return getContent({ ...content, suffix: showRelatedPagetypes(itemId) });

    case Boolean(relationType) &&
      Boolean(relationTypes) &&
      relationType === relationTypes[0]:
      return getRelations(relatieToevoegen)("", ...relationTypes);

    case Boolean(relationType) && Boolean(relationTypes):
    case Boolean(relationType) && relationType.position > 0:
      return null;

    case Boolean(relationType):
      const authorizationMask =
        page.pagetype === "praktijksituatie" && zoneCode === ZoneCode.content
          ? {
              mayAdd: false,
              mayEdit: zoneName === "Inhoud",
              mayDelete: zoneName === "Inhoud",
            }
          : undefined;
      return getRelations(relatieToevoegen, authorizationMask)(relationType);

    case Boolean(special) && isSpecial(special):
      return getSpecial({
        special,
        itemId,
        getContent,
        getRelations: getRelations(relatieToevoegen),
        page,
        pageActions,
        pagetypes,
        selectionLists,
        pagetypeList,
        facets,
        rows,
        onSelect,
        onDeselect,
        setFilter,
        setColumnFilter,
        setSelections,
        setOrder,
        selections,
        filter,
        columnFilters,
        order,
        properties,
        notes,
        form,
        user,
        profielProps,
        tree,
        relations,
        types,
        currentSteps,
        processTypes,
        entitylandscape,
        landscape,
        landscapeItem,
        landscapeFilters,
        tabInfo,
        uiActions,
        biebStructures,
        verantwoording,
        gotoVerantwoordingen,
        applications,
        koppelingFields,
        koppelingVeldenRelations,
        chainRelations,
        miniSaars,
        pagetype,
        siblings,
        item,
        raamwerk,
        relatieToevoegen,
        doUseLegoblokDialog,
      });

    case Boolean(special):
      return <div key={special.name}>Not implemented: {special.name}</div>;

    default:
      return (
        <div key="notsupported" data-properties={JSON.stringify(properties)}>
          Not supported
        </div>
      );
  }
}

const createSelectorForZone = (zone, itemsSelector) =>
  createSelector(
    [
      itemsSelector,
      itemIdSelector,
      contentSelector,
      presentationRelationsSelector,
      pageSelector,
      pageActionsSelector,
      pagetypesSelector,
      selectionListsSelector,
      pagetypeListSelector,
      facetsSelector,
      rowsSelector,
      onSelectSelector,
      onDeselectSelector,
      setFilterSelector,
      setSelectionsSelector,
      setOrderSelector,
      selectionsSelector,
      filterSelector,
      orderSelector,
      propertiesSelector,
      notesSelector,
      formSelector,
      userSelector,
      profielPropsSelector,
      treeSelector,
      relationsListSelector,
      typesSelector,
      processItemsSelector,
      processRelationTypesSelector,
      landscapeSelector,
      landscapeItemSelector,
      landscapeFiltersSelector,
      (_, tabInfo) => tabInfo,
      uiActionsSelector,
      biebStructuresSelector,
      verantwoordingSelector,
      gotoVerantwoordingenSelector,
      applicationsPropSelector,
      koppelingFieldsSelector,
      koppelingVeldenRelationsSelector,
      chainRelationsSelector,
      miniSaarsSelector,
      pagetypeSelector,
      siblingsSelector,
      flow(get("page"), defaultTo(empty.object)),
      raamwerkSelector,
      useLegoblokDialogSelector,
      columnFiltersSelector,
      setColumnFilterSelector,
      entitylandscapeSelector,
    ],
    (presentationItems, ...args) => {
      addRelationTypeGrouping(zone, presentationItems);
      const types = args[25];
      const pageScope = scopeSelector({
        page: args[43],
        biebStructures: args[33],
        miniSaars: args[40],
      });
      const relatieToevoegen =
        hasSpecialRelatieToevoegen(presentationItems) &&
        flown(
          presentationItems,
          map("relationType"),
          compact,
          innerJoin(get("id"), types, get("id")),
          map(([{ side }, type]) => ({ ...type, side })),
          reject((type) => {
            const scope = type[Side[type.side]].scope;
            return scope && scope !== pageScope;
          })
        );
      return flown(
        presentationItems,
        map((presentationItem) =>
          renderPresentationItem(
            zone,
            presentationItem,
            ...args,
            relatieToevoegen
          )
        ),
        compact,
        setup("children")
      );
    }
  );

const addRelationTypeGrouping = (zone, presentationItems) => {
  if (zone.zoneCode !== 3) {
    return;
  }

  const relationTypes = flown(
    presentationItems,
    filter(({ relationType = empty.object }) =>
      isLeftShowGrouped(relationType)
    ),
    map(({ relationType }) => relationType)
  );
  if (!isNonEmptyArray(relationTypes)) {
    return;
  }
  presentationItems
    .filter(({ relationType }) => includes(relationType)(relationTypes))
    .forEach((presentationItem) => {
      presentationItem.relationTypes = relationTypes;
    });
};

const createSelectorForFixedZone = (zone) =>
  createSelectorForZone(zone, presentationItemsSelector(zone.zoneCode));

export const extractRightbar = createSelectorForFixedZone({
  zoneCode: ZoneCode.rightbar,
});
export const extractFirstSpecial = createSelectorForFixedZone({
  zoneCode: ZoneCode.firstSpecial,
});

const getTocFromCluster = (cluster) =>
  flown(
    cluster,
    keys,
    map((k) => get(k)(cluster)),
    map(getTocFromContent),
    flattenDeep,
    compact
  );

const collectText = ({
  type,
  data = "",
  name = "",
  children = empty.array,
}) => {
  switch (type) {
    case "tag":
      return name === "br" ? " " : children.map(collectText).join("");
    case "text":
      return data;
    default:
      return "";
  }
};

const headingTagNames = ["h1", "h2", "h3", "h4", "h5", "h6"];
const findToc = (elements) => {
  if (!elements || elements.length === 0) {
    return empty.array;
  }

  return flown(
    elements,
    map((node) => {
      const { name, attribs: { id } = empty.object, children, type } = node;
      if (type === "tag" && headingTagNames.includes(name) && Boolean(id)) {
        return { id, label: collectText(node) };
      } else {
        return findToc(children);
      }
    }),
    flattenDeep
  );
};

const getTocItems = (value) => {
  if (!isNonEmptyString(value)) {
    return undefined;
  }

  const elements = parseHTML(value);
  return findToc(elements);
};

const getTocFromContent = (content) => {
  if (!content) {
    return undefined;
  }

  switch (content.iproxType) {
    case "field":
      return get("definition.dataTypeCode")(content) === 5
        ? content.value
        : undefined;
    case "clusters":
      return flown(content.clusters, map(getTocFromContent), flattenDeep);
    case "cluster":
    default:
      return content.clusterId ? getTocFromCluster(content) : undefined;
  }
};

export const extractTabs = createSelector(
  [
    presentationTabsSelector,
    itemIdSelector,
    contentSelector,
    presentationRelationsSelector,
    pageSelector,
    pageActionsSelector,
    pagetypesSelector,
    selectionListsSelector,
    pagetypeListSelector,
    selectionSelector,
    facetsSelector,
    rowsSelector,
    onSelectSelector,
    onDeselectSelector,
    setFilterSelector,
    setSelectionsSelector,
    setOrderSelector,
    selectionsSelector,
    filterSelector,
    orderSelector,
    propertiesSelector,
    notesSelector,
    formSelector,
    userSelector,
    profielPropsSelector,
    treeSelector,
    relationsListSelector,
    typesSelector,
    processItemsSelector,
    processRelationTypesSelector,
    landscapeSelector,
    landscapeItemSelector,
    landscapeFiltersSelector,
    uiActionsSelector,
    biebStructuresSelector,
    verantwoordingSelector,
    gotoVerantwoordingenSelector,
    applicationsPropSelector,
    koppelingFieldsSelector,
    koppelingVeldenRelationsSelector,
    chainRelationsSelector,
    miniSaarsSelector,
    pagetypeSelector,
    siblingsSelector,
    flow(get("page"), defaultTo(empty.object)),
    raamwerkSelector,
    useLegoblokDialogSelector,
    ({ renderPreContent }) => renderPreContent,
    columnFiltersSelector,
    setColumnFilterSelector,
    entitylandscapeSelector,
  ],
  (
    tabs,
    itemId,
    getContent,
    getRelations,
    page,
    pageActions,
    pagetypes,
    selectionLists,
    pagetypeList,
    { loading, documents = empty.array } = empty.object,
    facets,
    rows,
    onSelect,
    onDeselect,
    setFilter,
    setSelections,
    setOrder,
    selections,
    filter,
    order,
    properties,
    notes,
    form,
    user,
    profielProps,
    tree,
    relations,
    types,
    currentSteps,
    processTypes,
    landscape,
    landscapeItem,
    landscapeFilters,
    uiActions,
    biebStructures,
    verantwoording,
    gotoVerantwoordingen,
    applications,
    koppelingFields,
    koppelingVeldenRelations,
    chainRelations,
    miniSaars,
    pagetype,
    siblings,
    item,
    raamwerk,
    doUseLegoblokDialog,
    renderPreContent = empty.functionThatReturns(null),
    columnFilters,
    setColumnFilter,
    entitylandscape
  ) => {
    if (tabs.length > 0) {
      return tabs.map((tab) => {
        const hasOpmerkingen = hasSpecialOpmerkingen(tab.items);
        const pageScope = scopeSelector({
          page: item,
          biebStructures,
          miniSaars,
        });
        const relatieToevoegen =
          hasSpecialRelatieToevoegen(tab.items) &&
          flown(
            tab.items,
            map("relationType"),
            compact,
            innerJoin(get("id"), types, get("id")),
            map(([{ side }, type]) => ({ ...type, side })),
            reject((type) => {
              const scope = type[Side[type.side]].scope;
              return scope && scope !== pageScope;
            })
          );

        const toc = flown(
          tab.items,
          map("content.path"),
          map((path) => get(path)(page)),
          map(getTocFromContent),
          flattenDeep,
          map(getTocItems),
          compact,
          flattenDeep
        );

        return {
          label: hasOpmerkingen ? (
            <ItemNotesBadge counter={flow(get("length"), defaultTo(0))(notes)}>
              {tab.name}
            </ItemNotesBadge>
          ) : (
            tab.name
          ),
          alias: tab.name,
          toc,
          children: [
            renderPreContent({
              pagetype,
              tabName: tab.name,
              pageActions,
              hasOpmerkingen,
            }),
            ...flown(
              tab.items,
              defaultTo(empty.array),
              map((presentationItem) =>
                renderPresentationItem(
                  tab,
                  presentationItem,
                  itemId,
                  getContent,
                  (relatieToevoegen, authorizationMask) =>
                    (...filter) =>
                      getRelations(
                        relatieToevoegen,
                        authorizationMask
                      )(...[tab.name, ...filter]),
                  page,
                  pageActions,
                  pagetypes,
                  selectionLists,
                  pagetypeList,
                  facets,
                  rows,
                  onSelect,
                  onDeselect,
                  setFilter,
                  setSelections,
                  setOrder,
                  selections,
                  filter,
                  order,
                  properties,
                  notes,
                  form,
                  user,
                  profielProps,
                  tree,
                  relations,
                  types,
                  currentSteps,
                  processTypes,
                  landscape,
                  landscapeItem,
                  landscapeFilters,
                  // in other zones `tabInfo`
                  empty.object,
                  uiActions,
                  biebStructures,
                  verantwoording,
                  gotoVerantwoordingen,
                  applications,
                  koppelingFields,
                  koppelingVeldenRelations,
                  chainRelations,
                  miniSaars,
                  pagetype,
                  siblings,
                  item,
                  raamwerk,
                  doUseLegoblokDialog,
                  columnFilters,
                  setColumnFilter,
                  entitylandscape,
                  relatieToevoegen
                )
              )
            ),
          ],
        };
      });
    }

    const visibleClusters = Object.keys(page)
      .map((label) => ({ label, cluster: page[label] }))
      .filter(({ cluster }) => isVisible(cluster, pageActions));
    const documentTabs = isEmpty(loading)
      ? empty.array
      : [
          {
            label: "Selectie",
            alias: "Selectie",
            children:
              documents.length === 0 ? (
                loading === "success" ? (
                  <Card key="no-rerults" style={{ padding: "16px" }}>
                    <SafeInnerHtml>
                      Er zijn geen resultaten gevonden
                    </SafeInnerHtml>
                  </Card>
                ) : null
              ) : onDeselect && onSelect && order && rows ? (
                <ItemList
                  facets={facets}
                  filter={filter}
                  columnFilters={columnFilters}
                  key={page.id}
                  list={documents}
                  onDeselect={onDeselect}
                  onSelect={onSelect}
                  order={order}
                  pagetypeList={pagetypeList}
                  rows={rows}
                  selectionLists={selectionLists}
                  selections={selections}
                  setFilter={setFilter}
                  setColumnFilter={setColumnFilter}
                  setOrder={setOrder}
                  setSelections={setSelections}
                  title={page.title}
                  uid={itemId}
                  slug={item.slug}
                  properties={properties}
                  paginatypefilter={page.selectie?.selectieInstellingen?.eigenschappen?.paginatypefilter?.value?.map(
                    ({ alias }) => alias
                  )}
                />
              ) : null,
          },
        ];
    let contentTabs;
    if (documents.length === 0 && visibleClusters.length === 0) {
      contentTabs = [
        {
          label: "Inhoud",
          alias: "Inhoud",
          children: empty.array,
        },
      ];
    } else if (
      documents.length === 0 &&
      flown(visibleClusters, map("cluster"), every(isField))
    ) {
      contentTabs = [
        {
          label: "Inhoud",
          alias: "Inhoud",
          children: (
            <ClusterCard>
              {flown(
                visibleClusters,
                map(({ cluster, label }) => (
                  <Field
                    key={label}
                    field={cluster}
                    pagetypes={pagetypes}
                    pageActions={pageActions}
                  />
                ))
              )}
            </ClusterCard>
          ),
        },
      ];
    } else {
      contentTabs = visibleClusters.map(({ cluster }) => ({
        label: cluster.names[0],
        alias: cluster.names[0],
        children: (
          <div className="content">
            {isField(cluster) ? (
              <Field
                field={cluster}
                pagetypes={pagetypes}
                pageActions={pageActions}
              />
            ) : (
              <Cluster
                cluster={cluster}
                showCard
                omitTitle
                pagetypes={pagetypes}
                pageActions={pageActions}
              />
            )}
          </div>
        ),
      }));
    }

    return documentTabs.concat(contentTabs);
  }
);
