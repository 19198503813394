import { map } from "lodash/fp";
import Checkbox from "material-ui/Checkbox";
import FlatButton from "material-ui/FlatButton";
import RadioButton, { RadioButtonGroup } from "material-ui/RadioButton";
import { Step, StepButton, StepContent, Stepper } from "material-ui/Stepper";
import Subheader from "material-ui/Subheader";
import React, { Fragment, useCallback, useState } from "react";
import { useSelector } from "react-redux";

import {
  PageActions,
  Relation,
  RelationType,
  SiteLink,
} from "../../../../business/models";
import Dialog from "../../../../containers/dialog";
import { flown } from "../../../../lodash";
import { ApplicatieGegeven, VeldCluster } from "./models";

interface Props {
  pageActions: PageActions;
  onClose(): void;
}

const AddModuleDialog = ({
  onClose,
  pageActions: { pageId },
  pageActions,
}: Props): JSX.Element => {
  const [stepIndex, setIndex] = useState<number>(0);
  const [moduleItemId, setModule] = useState<number | undefined>(undefined);

  const moduleType: RelationType = useSelector((state: any) =>
    (state.data.relationTypes?.types ?? []).find(
      ({ alias }: RelationType) => alias === "applicatie_module"
    )
  );

  const modules: SiteLink[] = useSelector((state: any) =>
    (state.data.relations?.[pageId]?.relations ?? [])
      .filter(
        ({ relationTypeId }: Relation) => relationTypeId === moduleType?.id
      )
      .map(({ right }: Relation) => right)
  );

  const veldModuleType: RelationType = useSelector((state: any) =>
    (state.data.relationTypes?.types ?? []).find(
      ({ alias }: RelationType) => alias === "applicatieveld_module"
    )
  );

  const relations: Relation[] = useSelector((state: any) =>
    (state.data.relations?.[pageId]?.relations ?? []).filter(
      ({ relationTypeId }: Relation) => relationTypeId === veldModuleType?.id
    )
  );

  const [dataChosen, setChosen] = useState<number[]>([]);
  const page = useSelector((state: any) => state.data.pages[pageId]);

  const handleSelectModule = useCallback(
    (_: unknown, selected: string) => {
      const selectedId = Number(selected);
      setModule(selectedId);

      if (!selectedId) {
        setChosen([]);
      } else {
        const initial = relations
          .filter(({ right }: Relation) => right.itemId === selectedId)
          .map(({ left }: Relation) => left)
          .filter(({ pageClusterId }: SiteLink) => pageClusterId)
          .map(({ pageClusterId }: SiteLink) => pageClusterId as number);
        setChosen(initial);
      }
    },
    [relations]
  );

  const gegevens: ApplicatieGegeven[] = (
    page.page?.gegeven?.clusters ?? []
  ).filter((cluster: ApplicatieGegeven) => cluster.veld.clusters.length > 0);

  const updateVeld = useCallback(
    (add: boolean, veldId: number) => {
      setChosen(
        add ? [...dataChosen, veldId] : dataChosen.filter((id) => id !== veldId)
      );
    },
    [dataChosen]
  );

  const updateVelden = useCallback(
    (add: boolean, veldIds: number[]) => {
      setChosen(
        add
          ? Array.from(new Set([...dataChosen, ...veldIds]))
          : dataChosen.filter((id) => !veldIds.includes(id))
      );
    },
    [dataChosen]
  );

  return (
    <Dialog
      open
      autoScrollBodyContent
      title={`Module koppelen aan applicatievelden van ${page.title}`}
      actions={[
        stepIndex > 0 && (
          <FlatButton key="back" label="Vorige" onClick={() => setIndex(0)} />
        ),
        <FlatButton
          key="cancel"
          secondary
          label="Annuleren"
          onClick={onClose}
        />,
        stepIndex === 0 && (
          <FlatButton
            key="forward"
            label="Volgende"
            disabled={moduleItemId === undefined}
            onClick={() => setIndex(1)}
          />
        ),
        stepIndex === 1 && (
          <FlatButton
            key="ok"
            label="OK"
            primary
            disabled={moduleItemId === undefined}
            onClick={(): void => {
              pageActions.add("LinkModule", {
                moduleItemId,
                selection: dataChosen,
              });
              pageActions.save();
              onClose();
            }}
          />
        ),
      ]}
    >
      <Stepper activeStep={stepIndex} orientation="vertical">
        <Step>
          <StepButton>
            Module
            {moduleItemId && stepIndex > 0 && (
              <span>
                : {modules.find(({ itemId }) => itemId === moduleItemId)?.label}
              </span>
            )}
          </StepButton>
          <StepContent>
            <Subheader style={{ paddingLeft: 0 }}>Kies een module</Subheader>
            <RadioButtonGroup
              name="modules"
              onChange={handleSelectModule}
              valueSelected={moduleItemId}
            >
              {flown(
                modules,
                map(({ itemId, label }) => (
                  <RadioButton key={itemId} value={itemId} label={label} />
                ))
              )}
            </RadioButtonGroup>
          </StepContent>
        </Step>
        <Step>
          <StepButton>
            Velden
            {dataChosen.length > 0 && (
              <span>: {dataChosen.length} velden gekozen</span>
            )}
          </StepButton>
          <StepContent style={{ overflowY: "auto", maxHeight: 320 }}>
            <Subheader style={{ paddingLeft: 0 }}>
              Kies applicatievelden
            </Subheader>
            {flown(
              gegevens,
              map(
                ({
                  clusterId,
                  gegeven: {
                    naam: { value: gegevenNaam },
                  },
                  veld,
                }: ApplicatieGegeven) => {
                  const veldIds = veld.clusters.map(
                    ({ clusterId }) => clusterId
                  );

                  return (
                    <Fragment key={clusterId}>
                      <Checkbox
                        value={clusterId}
                        label={gegevenNaam}
                        checked={veldIds.some((id) => dataChosen.includes(id))}
                        onCheck={(_, isChecked) => {
                          updateVelden(isChecked, veldIds);
                        }}
                      />
                      {flown(
                        veld.clusters,
                        map(
                          ({
                            clusterId,
                            naam: { value: veldNaam },
                          }: VeldCluster) => (
                            <Checkbox
                              className="checkbox-level1"
                              key={clusterId}
                              value={clusterId}
                              label={veldNaam}
                              checked={dataChosen.includes(clusterId)}
                              onCheck={(_, isChecked) => {
                                updateVeld(isChecked, clusterId);
                              }}
                            />
                          )
                        )
                      )}
                    </Fragment>
                  );
                }
              )
            )}
          </StepContent>
        </Step>
      </Stepper>
    </Dialog>
  );
};

export default AddModuleDialog;
