/* eslint  react/prop-types: off */
import empty from "empty";
import IconButton from "material-ui/IconButton";
import ActionHighlightOff from "material-ui/svg-icons/action/highlight-off";
import ActionSearch from "material-ui/svg-icons/action/search";
import Less from "material-ui/svg-icons/navigation/expand-less";
import More from "material-ui/svg-icons/navigation/expand-more";
import PropTypes from "prop-types";
import React from "react";
import {
  compose,
  pure,
  setDisplayName,
  setPropTypes,
  withHandlers,
  withState,
} from "recompose";

import { withoutProps } from "../../recompose.contrib";
import { PathLink } from "../relation.pathlink";

const style = {
  iconSearchButton: {
    border: "none",
    height: "auto",
    MozUserSelect: "none",
    msUserSelect: "none",
    padding: "6px 0 0 0",
    userDrag: "none",
    userSelect: "none",
    WebkitTouchCallout: "none",
    WebkitUserDrag: "none",
    WebkitUserSelect: "none",
    width: "auto",
    zIndex: 2010,
  },

  iconResetButton: {
    border: "none",
    height: "auto",
    padding: "0",
    width: "auto",
    marginLeft: "16px",
    zIndex: 2010,
  },

  iconExpandButton: {
    border: "none",
    height: "auto",
    padding: "0",
    width: "auto",
    marginLeft: "16px",
    zIndex: 2010,
  },

  iconStyle: {
    color: "#fff",
  },

  toggleButton: {
    alignSelf: "center",
    height: "auto",
    width: "auto",
    marginLeft: "16px",
    zIndex: 2010,
  },

  toggleLabel: {
    color: "#fff",
  },
};

const Input = "input";

const AutoCompleteTextField = ({
  anchor,
  handleReset = empty.func,
  hintText,
  onBlur = empty.func,
  onChange = empty.func,
  onClick = empty.func,
  onFocus = empty.func,
  onSubmit = empty.func,
  setAnchor = empty.func,
  handleSubmit = empty.func,
  searchOptionsExpanded,
  toggleExpanded,
  trefwoord,
}) => (
  <div className="autoCompleteTextFieldWrapper">
    <IconButton
      containerElement={<PathLink path="search" />}
      iconStyle={style.iconStyle}
      onClick={onSubmit}
      style={style.iconSearchButton}
      title="Toon alle zoekresultaten"
    >
      <ActionSearch />
    </IconButton>
    <Input
      className="autoCompleteTextField"
      onBlur={onBlur}
      onChange={onChange}
      onClick={onClick}
      onFocus={onFocus}
      onKeyDown={handleSubmit}
      placeholder={hintText}
      ref={setAnchor}
      type="text"
      value={trefwoord}
    />
    <IconButton
      disabled={anchor && !anchor.value}
      iconStyle={style.iconStyle}
      onClick={handleReset}
      style={style.iconResetButton}
      title="Verwijder zoekterm"
    >
      <ActionHighlightOff />
    </IconButton>
    {
      <IconButton
        iconStyle={style.iconStyle}
        onClick={toggleExpanded}
        style={style.iconExpandButton}
        title="Zoekopties en resultaten"
      >
        {searchOptionsExpanded ? <Less /> : <More />}
      </IconButton>
    }
  </div>
);

export default compose(
  setDisplayName("AutoCompleteTextField"),
  setPropTypes({
    anchor: PropTypes.instanceOf(Element),
    hintText: PropTypes.string,
    isLoading: PropTypes.bool.isRequired,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onClick: PropTypes.func,
    onFocus: PropTypes.func.isRequired,
    onReset: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    setAnchor: PropTypes.func.isRequired,
    toggleExpanded: PropTypes.func.isRequired,
  }),
  pure,
  withState(
    "trefwoord",
    "updateTrefwoord",
    ({ defaultValue = "" }) => defaultValue
  ),
  withHandlers({
    handleReset:
      ({ anchor = empty.object, onReset, updateTrefwoord }) =>
      () => {
        onReset();
        updateTrefwoord("");
        anchor.focus();
      },

    onChange:
      ({ handleAutoComplete, searchInAllSites, updateTrefwoord }) =>
      ({ target: { value: trefwoord } = empty.object }) => {
        updateTrefwoord(trefwoord);
        handleAutoComplete(trefwoord, searchInAllSites);
      },

    handleSubmit:
      ({ onSubmit }) =>
      (event) => {
        onSubmit(event);
      },
  }),
  withoutProps("onReset")
)(AutoCompleteTextField);
