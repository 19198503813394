/* eslint  react/prop-types: off */
import empty from "empty";
import PropTypes from "prop-types";
import React from "react";
import { compose, setDisplayName, setPropTypes, withHandlers } from "recompose";

import { propTypes } from "./ggvtyp";
import InternetView, { pattern, transformLink } from "./InternetView";
import PlainInput from "./plainInput";

const InternetWithLabel = ({
  value: { address, label } = empty.object,
  value,
  setLabelValue,
  setAddressValue,
  className,
  pageActions: { edit },
  field: {
    definition: {
      explanation,
      valuePlaceholder,
      sourcePlaceholder,
    } = empty.object,
  },
}) =>
  edit ? (
    <div className="internet-with-label">
      <div className="internet-label">
        <PlainInput
          key="label"
          value={label}
          setValue={setLabelValue}
          placeholder={valuePlaceholder || "Label"}
        />
      </div>
      <div className="internet-address">
        <PlainInput
          key="address"
          value={address}
          setValue={setAddressValue}
          transformValue={transformLink}
          type="url"
          regex={pattern}
          explanation={explanation}
          placeholder={sourcePlaceholder || "Internet adres"}
        />
      </div>
    </div>
  ) : (
    <InternetView className={className} value={value} />
  );

const enhance = compose(
  setDisplayName("InternetWithLabel"),
  setPropTypes({
    ...propTypes,
    field: PropTypes.shape({
      value: PropTypes.shape({
        label: PropTypes.string,
        address: PropTypes.string,
      }),
    }).isRequired,
  }),
  withHandlers({
    setLabelValue:
      ({ value: { address } = empty.object, setValue }) =>
      ({ value, ...rest }) =>
        setValue({
          ...rest,
          value: { address, label: value },
          string: `[${value}]: ${address}`,
        }),
    setAddressValue:
      ({ value: { label } = empty.object, setValue }) =>
      ({ value, ...rest }) =>
        setValue({
          ...rest,
          value: { label, address: value },
          string: `[${label}]: ${value}`,
        }),
  })
);

export default enhance(InternetWithLabel);
