import PropTypes from "prop-types";
import React from "react";

const FloatingLabelText = ({ text }) => (
  <div
    style={{
      color: "rgba(0, 0, 0, 0.3)",
      fontSize: "12px",
      margin: "16px 0 4px",
    }}
  >
    {text}
  </div>
);
FloatingLabelText.propTypes = {
  text: PropTypes.string.isRequired,
};

export default FloatingLabelText;
