/* eslint  react/prop-types: off */
import empty from "empty";
import { List, ListItem } from "material-ui/List";
import RaisedButton from "material-ui/RaisedButton";
import React from "react";

import { buttonStyle, listItemStyle } from "../../material/addButton";

const AddButton = ({
  name,
  enabled = false,
  adjective = "",
  onClick = empty.func,
}) => (
  <RaisedButton
    label={`${adjective} ${name} toevoegen`}
    onClick={onClick}
    disabled={!enabled}
    style={buttonStyle}
    secondary
  />
);

const AddButtons = ({
  pageActions: { edit = false, dirty = false } = empty.object,
  clusters: { names: [name] = empty.array } = empty.object,
  clusterAdd = empty.func,
  clusterNew = empty.func,
}) =>
  edit && name ? (
    <List style={{ paddingTop: 0 }}>
      <ListItem disabled style={listItemStyle}>
        <AddButton
          name={name}
          enabled={!dirty}
          adjective="Bestaande"
          onClick={() => clusterAdd(name)}
        />
        <AddButton
          name={name}
          enabled={!dirty}
          adjective="Nieuwe"
          onClick={() => clusterNew(name)}
        />
      </ListItem>
    </List>
  ) : null;

export default AddButtons;
