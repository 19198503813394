import FlatButton from "material-ui/FlatButton";
import React, { memo, useCallback, useState } from "react";

import { SelectionListItem } from "../../../business/models";
import Dialog from "../../../containers/dialog";
import { transformLink } from "../../ggvtyp/InternetView";
import { RaamwerkStatusCheckbox } from "../../ggvtyp/raamwerk";
import {
  HyperlinkActionInput,
  HyperlinkDialogProps,
  HyperlinkForm,
} from "./models";

const validateForm = (form: HyperlinkForm): boolean => {
  return (
    form.externLink.label !== "" &&
    form.externLink.url !== "" &&
    form.status !== ""
  );
};

const HyperlinkDialog = ({
  hyperlinkForm,
  statusItems,
  closeDialog,
  hyperlinkAction,
}: HyperlinkDialogProps) => {
  const initialForm = hyperlinkForm as HyperlinkForm;

  const [form, updateForm] = useState(initialForm);
  const [valid, updateValid] = useState(validateForm(initialForm));

  const handleChange = useCallback(
    ({ target: { name, value } }: { target: HTMLInputElement }) => {
      const newForm: HyperlinkForm = {
        ...form,
        externLink: {
          ...form.externLink,
          [name]: value,
        },
      };
      updateForm(newForm);
      updateValid(validateForm(newForm));
    },
    [form]
  );

  const handleUrlBlur = useCallback(
    ({ target: { value } }: { target: HTMLInputElement }) => {
      const newForm: HyperlinkForm = {
        ...form,
        externLink: {
          ...form.externLink,
          url: transformLink(value),
        },
      };
      updateForm(newForm);
      updateValid(validateForm(newForm));
    },
    [form]
  );

  const handleRaamwerkStatusClick = useCallback(
    (item: SelectionListItem) => {
      const newForm: HyperlinkForm = { ...form, status: item.alias };
      updateForm(newForm);
      updateValid(validateForm(newForm));
    },
    [form]
  );

  const handleSubmit = useCallback(() => {
    if (valid && form.status) {
      const url = transformLink(form.externLink.url);
      const input: HyperlinkActionInput = {
        action: form.externLink.id ? "RaamwerkLink" : "RaamwerkLinkAdd",
        selectionId: form.selectionId,
        status: form.status,
        externLink: { ...form.externLink, url },
      };

      hyperlinkAction(input, closeDialog);
    }
  }, [
    closeDialog,
    form.externLink,
    form.selectionId,
    form.status,
    hyperlinkAction,
    valid,
  ]);

  return (
    <Dialog
      title="Externe verwijzing toevoegen"
      open
      autoScrollBodyContent
      actions={[
        <FlatButton
          key="close"
          secondary
          label="Annuleren"
          onClick={closeDialog}
        />,
        <FlatButton
          primary
          key="save"
          label="Opslaan"
          onClick={handleSubmit}
          disabled={!valid}
        />,
      ]}
    >
      <div className="raamwerk-hyperlink-form">
        <div className="field field-edit ggvtyp6 ggvtyp6-url">
          <div className="subheader label raamwerk-label">Hyperlink</div>
          <div className="value">
            <div className="internet-with-label">
              <div className="internet-label">
                <input
                  placeholder="Label"
                  name="label"
                  value={form.externLink.label}
                  className="raamwerk-input"
                  onChange={handleChange}
                />
              </div>
              <div className="internet-address">
                <div className="explanation">Wat is het websiteadres</div>
                <input
                  name="url"
                  type="url"
                  placeholder="Internet adres"
                  value={form.externLink.url}
                  className="raamwerk-input"
                  onChange={handleChange}
                  onBlur={handleUrlBlur}
                />
              </div>
            </div>
          </div>
        </div>
        {!form.externLink.id && (
          <div className="field field-edit">
            <div className="subheader label raamwerk-label">Status</div>
            <div className="value raamwerk_status">
              <div className="raamwerk_status_buttons">
                {statusItems.map((s) => (
                  <RaamwerkStatusCheckbox
                    key={s.id}
                    item={s}
                    meta={{
                      selectionId: s.id,
                      alias: s.alias,
                      value: s.value,
                    }}
                    onClick={handleRaamwerkStatusClick}
                    checked={form.status === s.alias}
                  />
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default memo(HyperlinkDialog);
