import { composeAction } from "async-lifecycle";
import empty from "empty";
import { compact, concat, find, map, uniq } from "lodash/fp";

import {
  add as addApi,
  addNew as addNewApi,
  addRelations as addRelationsApi,
  chainRelations,
  filterPersonRole,
  find as findApi,
  removeAll as removeAllApi,
  remove as removeApi,
} from "../api/relation";
import { Side } from "../business/relations";
import { flown } from "../lodash";
import { sagas } from "../sagas";
import { requireEntiteiten } from "./application";
import { fieldDefinitionsByPagetypeAction } from "./cms";
import { addStage, redirectAction } from "./common";
import { getPageAction } from "./data";
import { invalidateRelations } from "./relations.invalidate";

let current = empty.object;

export const useCurrent = () => current;

const initList = (itmRelTypIdt, dir, itmIdt, list, targetSitIdt, ssoItmIdt) =>
  composeAction({
    group: `ADDRELATION_${list}`,
    fire: findApi,
    args: [itmRelTypIdt, dir, itmIdt, targetSitIdt, ssoItmIdt],
  });

export const personRoleFilterAction = (source, itmIdt) =>
  composeAction({
    group: "PERSONROLE_FILTER",
    fire: (source, itmIdt) =>
      source ? filterPersonRole(source, itmIdt) : empty.object,
    args: [source, itmIdt],
    extra: { source },
  });

export const clear = () => ({
  type: "ADDRELATION_CLEAR",
  payload: empty.object,
});

export const initRelations =
  ({ id }, side, itemId, targetSitIdt, ssoItmIdt) =>
  (dispatch, getState) => {
    const {
      data: {
        relationTypes: { types: relationTypes },
      },
    } = getState();
    const relationType = find({ id })(relationTypes);
    const peersDirection = side === Side.left ? "Nar" : "Van";

    dispatch(
      initList(relationType.id, peersDirection, itemId, "PEERS", targetSitIdt)
    );
    if (relationType.using && relationType.using.pagetype) {
      const usingDirection = side === Side.using ? "Nar" : "Met";
      dispatch(
        initList(
          relationType.id,
          usingDirection,
          itemId,
          "USING",
          targetSitIdt,
          usingDirection === "Met" ? ssoItmIdt : undefined
        )
      );
    }
  };

export const init =
  ({ id }, side, itemId, initialFormState = undefined, callback = undefined) =>
  (dispatch, getState) => {
    const {
      data: {
        relationTypes: { types: relationTypes },
      },
    } = getState();
    const relationType = find({ id })(relationTypes);
    current = Object.freeze({ relationType, side, itemId, callback });
    dispatch(clear());
    dispatch(getPageAction(itemId));
    dispatch({
      type: "ADDRELATION_CONTEXT_SUCCESS",
      payload: { data: { type: relationType, side } },
    });
    dispatch({
      type: "ADDRELATION_INITIALFORM_SUCCESS",
      payload: { data: initialFormState ?? empty.object },
    });
    initRelations({ id }, side, itemId)(dispatch, getState);

    if (
      relationType.alias === "activiteit_kerngegeven" ||
      relationType.alias === "bedrijfsproces_kerngegeven"
    ) {
      dispatch(requireEntiteiten());
    }

    if (relationType.pagetype) {
      dispatch(fieldDefinitionsByPagetypeAction(relationType.pagetype));
    }

    const isUsingRol =
      relationType.using && relationType.using.pagetype === "rol";
    if (isUsingRol && relationType[Side[side]].pagetype === "persoon") {
      dispatch(personRoleFilterAction("persoon", itemId));
    } else if (
      isUsingRol &&
      (relationType.left.pagetype === "persoon" ||
        relationType.right.pagetype === "persoon")
    ) {
      dispatch(personRoleFilterAction("rol", itemId));
    } else if (
      isUsingRol &&
      relationType.using.specificItemId &&
      relationType[Side[Side.rev(side)]].pagetype === "persoon"
    ) {
      dispatch(
        personRoleFilterAction("rol", relationType.using.specificItemId)
      );
    } else {
      dispatch(personRoleFilterAction());
    }
  };

export const add = (otherItemId, usingItemId, metaData) => (dispatch) => {
  const { relationType, side, itemId, callback } = current;

  const vanItmIdt =
    side === Side.using
      ? otherItemId
      : side === Side.left
      ? itemId
      : otherItemId;
  const narItmIdt =
    side === Side.using
      ? usingItemId
      : side === Side.right
      ? itemId
      : otherItemId;
  const metItmIdt = side === Side.using ? itemId : usingItemId;

  const args = [relationType.id, vanItmIdt, narItmIdt, metItmIdt, metaData];
  dispatch(
    composeAction({
      group: "ADDRELATION_ADD",
      fire: addApi,
      args,
      callbackSuccess: () => {
        dispatch(clear());
        dispatch(invalidateRelations(vanItmIdt));
        dispatch(invalidateRelations(narItmIdt));
        if (metItmIdt) {
          dispatch(invalidateRelations(metItmIdt));
        }

        dispatch(getPageAction(itemId));
        callback?.();
        if (relationType.alias === "entiteit_entiteit") {
          const payload = { id: itemId };
          dispatch({ type: "ENTITYLANDSCAPE_INVALIDATE", payload });
        }
      },
    })
  );
};

export const addRelations = (relationModelArray) => (dispatch) => {
  const { relationType, itemId } = current;

  dispatch(
    composeAction({
      group: "ADDRELATION_ADD",
      fire: addRelationsApi,
      args: [relationType.id, relationModelArray],
      callbackSuccess: () => {
        dispatch(clear());

        for (const relationModel of relationModelArray) {
          dispatch(invalidateRelations(relationModel.vanItmIdt));
          dispatch(invalidateRelations(relationModel.narItmIdt));
          if (relationModel.metItmIdt) {
            dispatch(invalidateRelations(relationModel.metItmIdt));
          }
        }

        dispatch(getPageAction(itemId));
      },
    })
  );
};

export const addNewItem =
  (
    currentItem,
    { label, side: newItemSide, structureId, externalApplication },
    usingItemId,
    redirect = false,
    hostItemId,
    metaData,
    clusterIds
  ) =>
  (dispatch, getState) => {
    const { relationType, side, itemId } = currentItem;

    const currentIsTwo =
      side === Side.using ||
      (newItemSide === Side.using && side === Side.right);
    const oneItmIdt = currentIsTwo ? usingItemId : itemId;
    const twoItmIdt = currentIsTwo ? itemId : usingItemId;

    const args = [
      relationType.id,
      oneItmIdt,
      {
        side: newItemSide || Side.rev(side),
        label,
        structureId,
        externalApplication,
      },
      twoItmIdt,
      metaData,
      clusterIds,
    ];
    dispatch(
      composeAction({
        group: "ADDRELATION_ADD",
        fire: addNewApi,
        args,
        callbackSuccess: (payload, dispatch) => {
          dispatch(clear());
          dispatch(invalidateRelations(oneItmIdt));
          if (twoItmIdt) {
            dispatch(invalidateRelations(twoItmIdt));
          }

          dispatch(getPageAction(itemId));

          if (hostItemId) {
            dispatch(invalidateRelations(hostItemId));
          }

          if (payload.itemId) {
            if (redirect) {
              const {
                session: {
                  miniSaar: { path = "iznet" } = empty.object,
                } = empty.object,
              } = getState();
              dispatch(
                redirectAction(
                  `/${path}/page/${payload.itemId}/${payload.slug}`
                )
              );
            } else if (hostItemId) {
              dispatch(getPageAction(hostItemId));
            }
          }
        },
      })
    );
  };

export const addNew = (
  { label, side, structureId, externalApplication },
  usingItemId,
  metaData,
  clusterIds
) =>
  addNewItem(
    current,
    { label, side, structureId, externalApplication },
    usingItemId,
    false,
    undefined,
    metaData,
    clusterIds
  );

export const addUsing =
  (label, narItmIdt, structureId, externalApplication) => (dispatch) => {
    const { relationType, side, itemId } = current;

    const currentIsTwo = side === Side.right;
    const oneItmIdt = currentIsTwo ? narItmIdt : itemId;
    const twoItmIdt = currentIsTwo ? itemId : narItmIdt;

    const args = [
      relationType.id,
      oneItmIdt,
      { side: Side.using, label, structureId, externalApplication },
      twoItmIdt,
    ];
    dispatch(
      composeAction({
        group: "ADDRELATION_ADD",
        fire: (itmRelTypIdt, oneItmIdt, newItem, twoItmIdt) =>
          addNewApi(itmRelTypIdt, oneItmIdt, newItem, twoItmIdt),
        args,
        callbackSuccess: () => {
          dispatch(clear());
          dispatch(invalidateRelations(itemId));
          dispatch(getPageAction(itemId));
        },
      })
    );
  };

export const remove = (
  itmRelTypIdt,
  selfItemId,
  id,
  otherItemId,
  usingItemId
) =>
  addStage({
    message: "De verwijzing wordt definitief verwijderd.",
    action: (dispatch) => {
      const args = [itmRelTypIdt, id];
      dispatch(
        composeAction({
          group: "RELATION_DELETE",
          fire: (itmRelTypIdt, id) => removeApi(itmRelTypIdt, id),
          args,
          callbackSuccess: () => {
            dispatch(invalidateRelations(selfItemId));
            dispatch(getPageAction(selfItemId));
            dispatch(invalidateRelations(otherItemId));
            if (usingItemId) {
              dispatch(invalidateRelations(usingItemId));
            }
          },
        })
      );
    },
  });

export const removeAll = (itmRelTypIdt, relations) =>
  addStage({
    message: "De verwijzingen worden definitief verwijderd.",
    action: (dispatch) => {
      const args = [itmRelTypIdt, map("id")(relations)];
      dispatch(
        composeAction({
          group: "RELATION_DELETE",
          fire: (itmRelTypIdt, ids) => removeAllApi(itmRelTypIdt, ids),
          args,
          callbackSuccess: () => {
            const selfItemIds = flown(relations, map("selfItemId"), uniq);
            const otherItemIds = flown(relations, map("otherItemId"), uniq);
            const usingItemIds = flown(
              relations,
              map("usingItemId"),
              compact,
              uniq
            );
            const itemIds = flown(
              selfItemIds,
              concat(otherItemIds),
              concat(usingItemIds),
              uniq
            );
            for (const itemId of itemIds) {
              dispatch(invalidateRelations(itemId));
            }

            for (const selfItemId of selfItemIds) {
              dispatch(getPageAction(selfItemId));
            }
          },
        })
      );
    },
  });

export const realUpdateFilter = (useFilter) => ({
  type: "PERSONROLE_TOGGLE",
  payload: {
    useFilter,
  },
});

export const updateFilter = (useFilter) =>
  useFilter
    ? realUpdateFilter(useFilter)
    : addStage({
        message:
          "Wanneer je door gaat worden relaties die nog niet bestaan, toegevoegd.",
        action: realUpdateFilter(useFilter),
      });

export const chainRelationsAction = (itmIdt, chainModel) =>
  composeAction({
    group: "CHAINRELATIONS",
    fire: chainRelations,
    args: [itmIdt, chainModel],
    key: "id",
  });

export const clustersForRelationsAction = (id, clusterId) => (dispatch) => {
  dispatch({
    type: sagas.clustersForRelations.require,
    payload: { id, clusterId },
  });
};

export const clustersForRelationsClearAction = () => (dispatch) => {
  dispatch({ type: sagas.clustersForRelations.clear });
};
