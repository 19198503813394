const persistent = { persistent: true };
export const singleLine = { singleLine: true };
export const persistentMultiLine = { ...persistent, singleLine: false };
export const persistentSingleLine = { ...persistent, ...singleLine };

export const addUiClassAction = (classname) => ({
  type: "UI_CLASSNAME_ADD",
  payload: classname,
});

export const removeUiClassAction = (classname) => ({
  type: "UI_CLASSNAME_REMOVE",
  payload: classname,
});

export const resetUiClassesAction = () => ({
  type: "UI_CLASSNAME_RESET",
});

export const updateSecondaryDrawerAction = (open) => ({
  type: "UI_SECONDARYDRAWER_UPDATE",
  payload: open,
});

export const setPreviewAction = (itemId) => ({
  type: "UI_PREVIEW_SET",
  payload: { itemId },
});

export const clearPreviewAction = () => ({
  type: "UI_PREVIEW_CLEAR",
});

export const setVersionDiffAction = (
  itemId,
  versionId,
  relationVersionId,
  relationItemId,
  relationItemVersionId,
  created,
  current = false
) => ({
  type: "UI_VERSIONDIFF_SET",
  payload: {
    itemId,
    versionId,
    relationVersionId,
    relationItemId,
    relationItemVersionId,
    created,
    current,
  },
});

export const clearVersionDiffAction = () => ({
  type: "UI_VERSIONDIFF_CLEAR",
});

export const showVersionsDrawerAction = (show) => ({
  type: "UI_SHOWVERSIONSDRAWER",
  payload: { show },
});

export const selectRaamwerkFilterItemAction = (id) => ({
  type: "UI_RAAMWERKFILTERITEM_SELECT",
  payload: { id },
});

export const deselectRaamwerkFilterItemAction = (id) => ({
  type: "UI_RAAMWERKFILTERITEM_DESELECT",
  payload: { id },
});

export const resetRaamwerkFilterAction = () => ({
  type: "UI_RAAMWERKFILTERITEM_RESET",
});

export const snackbarShowAction = (message, options = undefined) => ({
  type: "UI_SNACKBAR_SHOW",
  payload: { message, options },
});

export const snackbarHideAction = () => ({
  type: "UI_SNACKBAR_HIDE",
});
