import FlatButton from "material-ui/FlatButton";
import { useState } from "react";

import { SiteLink, editPageActions } from "../../business/models";
import Dialog from "../../containers/dialog";
import {
  labelStyle,
  requiredStyle,
  validStyle,
} from "../../saar-add-item/components/shared";
import Verwijzing from "../ggvtyp/verwijzing";

interface Props {
  saarItemId: number;
  pagetype: string;
  biebStructureIds: number[];
  item?: SiteLink;
  onOk: (saarItemId: number, biebItemId: number) => void;
  onClose: () => void;
}

interface Link {
  linkId: number;
  label: string;
}

const LinkToBiebDialog = ({
  saarItemId,
  pagetype,
  biebStructureIds,
  item,
  onOk,
  onClose,
}: Props) => {
  const [link, setLink] = useState<Link | undefined>(
    item && {
      linkId: item.itemId,
      label: item.label,
    }
  );
  return (
    <Dialog
      title="Koppelen aan Saar Bieb"
      open
      actions={[
        <FlatButton
          secondary
          key="close"
          label="Annuleren"
          onClick={() => onClose()}
        />,
        <FlatButton
          primary
          key="ok"
          label="Koppelen"
          onClick={() => onOk(saarItemId, link!.linkId)}
          disabled={!link}
        />,
      ]}
    >
      <div style={link !== undefined ? validStyle : requiredStyle}>
        <label style={labelStyle}>Item in Saar Bieb</label>
        <br />
        <Verwijzing
          field={{ id: 1, isEmpty: link === undefined }}
          value={link}
          dirty={item?.itemId !== link?.linkId}
          setValue={({
            value: { linkId, label } = { linkId: undefined, label: undefined },
          }) => setLink(linkId && label ? { linkId, label } : undefined)}
          pageActions={editPageActions}
          paginatypen={[pagetype]}
          sitesInclude={biebStructureIds}
        />
      </div>
    </Dialog>
  );
};

export default LinkToBiebDialog;
