/* eslint  react/prop-types: off */
import empty from "empty";
import { get } from "lodash/fp";
import PropTypes from "prop-types";
import React from "react";
import { Link, withRouter } from "react-router";
import { compose, setDisplayName, setPropTypes, withHandlers } from "recompose";

import { updateOnDataChanged } from "../recompose.contrib";

const PathLinkComponent = ({
  path,
  children,
  router = empty.object,
  miniSaar = empty.object,
  className,
  style,
  handleClick,
  title,
}) => {
  const { params: { minisaar: miniSaarFromUrl = "" } = empty.object } = router;
  const prefix =
    get("[0]")(path) === "/"
      ? ""
      : `/${
          (miniSaar.alias !== "iznet" ? miniSaar.path : undefined) ||
          miniSaarFromUrl
        }/`;
  return (
    <Link
      className={className}
      style={style}
      to={`${prefix}${path}`}
      onClick={handleClick}
      title={title}
    >
      {children}
    </Link>
  );
};

export const PathLink = compose(
  setDisplayName("PathLink"),
  updateOnDataChanged("path", "children"),
  setPropTypes({
    path: PropTypes.string.isRequired,
  }),
  withRouter,
  withHandlers({
    handleClick:
      ({ onClick }) =>
      (e) => {
        if (typeof onClick === "function") {
          onClick(e);
        }

        e.stopPropagation();
      },
  })
)(PathLinkComponent);
