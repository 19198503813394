/* eslint  react/prop-types: off */
import classNames from "classnames";
import empty from "empty";
import { has, includes, isEqual, map } from "lodash/fp";
import PropTypes from "prop-types";
import React from "react";
import {
  compose,
  setDisplayName,
  setPropTypes,
  withHandlers,
  withPropsOnChange,
  withState,
} from "recompose";

import { withContentPlug } from "../../business/contentPlug";
import { readOnlyPageActions } from "../../business/models";
import { fieldShape } from "../../business/prop-types";
import ggvtyp, { RaamwerkLink } from "../../containers/ggvtyp";
import {
  ignoreKnownIrrelevancies,
  withClassNames,
  withStyle,
} from "../../recompose.contrib";
import { isEmpty } from "../../utils";
import Subheader from "../material/Subheader";
import SafeInnerHtml from "../safeInnerHtml";
import { withInspect } from "../utils/Inspect";

export const Label = compose(
  setDisplayName("Label"),
  withClassNames("label"),
  withStyle({
    lineHeight: undefined,
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    paddingBottom: 0,
    width: undefined,
    backgroundColor: "transparent",
  })
)(Subheader);

const fullWidthHtmlFields = [
  "Beschrijving",
  "Doel",
  "Goede praktijk",
  "Hulpmiddel",
  "Inhoud",
  "Inleiding",
  "Instructie",
  "Kennis",
  "Knelpunten",
  "Notitie",
  "Omschrijving",
  "Ontwerp",
  "Tekst",
];
const isFullWidth = ({ definition: { dataTypeCode, name } = empty.object }) =>
  dataTypeCode === 5 && includes(name)(fullWidthHtmlFields);

const LabelValueComponent = ({
  label,
  className,
  children,
  fullWidth,
  omitLabel,
  ...other
}) => (
  <div
    {...other}
    className={classNames(className, { "full-width": fullWidth })}
  >
    {!omitLabel && <Label>{label}</Label>}
    <div className="value">{children}</div>
  </div>
);

export const LabelValue = compose(
  setDisplayName("LabelValue"),
  withClassNames("field")
)(LabelValueComponent);

/**
 * Field component implementation
 */
const Field = (props) => {
  const {
    clusterName,
    field,
    name = field.definition?.name,
    className,
    plug,
    pagetypes = empty.array,
    alias,
    pageActions = readOnlyPageActions,
    dirty,
    valid,
    value,
    setValue,
    fullWidth,
    toggleImageLightbox,
    omitLabel = false,
    title,
  } = props;
  const {
    id,
    isEmpty,
    definition: { dataTypeCode, required = false } = empty.object,
    diff,
  } = field;

  if (dataTypeCode in ggvtyp) {
    const Component = ggvtyp[dataTypeCode];
    const computedFullWidth =
      fullWidth ?? plug.fullWidth(field) ?? isFullWidth(field);
    const label =
      !pageActions?.edit && dataTypeCode === 274 ? (
        <RaamwerkLink field={field} fromItemId={pageActions.pageId} />
      ) : (
        name
      );

    return (
      <LabelValue
        className={classNames(
          className,
          "field",
          { "field-dirty": dirty },
          { "field-edit": pageActions.edit },
          { "field-busy": pageActions.busy },
          {
            "field-invalid": !valid || (required && (dirty ? !value : isEmpty)),
          },
          `ggvtyp${dataTypeCode}`,
          `ggvtyp${dataTypeCode}-${alias || name}`,
          `ggvtyp${dataTypeCode}-${alias || name}-${pagetypes[0]}`
        )}
        key={`field_${id}`}
        label={label}
        fullWidth={computedFullWidth}
        omitLabel={omitLabel || computedFullWidth}
        title={title}
      >
        {diff && typeof diff === "string" ? (
          <SafeInnerHtml className="version-diff">{diff}</SafeInnerHtml>
        ) : (
          <Component
            clusterName={clusterName}
            field={field}
            dirty={dirty}
            pagetypes={pagetypes}
            pageActions={pageActions}
            value={value}
            setValue={setValue}
            toggleImageLightbox={toggleImageLightbox}
          />
        )}
      </LabelValue>
    );
  }

  return null;
};

const cleanValue = (value) => (isEmpty(value) ? undefined : value);

const intoValues = (object) =>
  has("files[0]")(object)
    ? map(({ fileName, title, data }) => ({ value: fileName, title, data }))(
        object.files
      )
    : undefined;

export default compose(
  setDisplayName("Field"),
  withClassNames("field"),
  ignoreKnownIrrelevancies,
  withInspect(({ pageActions }) => pageActions.$name ?? "-"),
  withContentPlug,
  setPropTypes({
    field: fieldShape.isRequired,
    itemId: PropTypes.number,
  }),
  withState("value", "setValue", ({ field: { value } }) => cleanValue(value)),
  withState("dirty", "setDirty", false),
  withState("valid", "setValid", true),
  withHandlers({
    setValue:
      ({
        clusterId,
        field: { id, value, definition: { dataTypeCode, name } = empty.object },
        setDirty,
        setValid,
        setValue,
        pageActions,
        itemId = pageActions?.pageId,
      }) =>
      ({
        value: object,
        string = isEmpty(object) ? null : object.toString(),
        data,
        valid = true,
      }) => {
        const originalValue = cleanValue(value);
        const nextValue = cleanValue(object);
        const dirty = !isEqual(nextValue, originalValue);
        setDirty(dirty);
        setValid(valid);
        setValue(nextValue === undefined ? null : nextValue);

        if (!pageActions) {
          return;
        }

        const revert = { itemId, fieldId: id, clusterId, name };
        if (dirty && valid) {
          const payload = {
            itemId,
            value: dataTypeCode === 272 ? undefined : string,
            values: dataTypeCode === 272 ? intoValues(object) : undefined,
            data,
            revert,
          };
          if (id > 0) {
            pageActions.fieldUpdate({
              fieldId: id,
              ...payload,
            });
          } else {
            pageActions.fieldAdd({
              clusterId,
              name,
              ...payload,
            });
          }
        } else {
          pageActions.fieldRevert?.(revert);
        }
      },
  }),
  withPropsOnChange(
    (
      {
        field: { value: currentValue },
        pageActions: { edited: currentEdited } = readOnlyPageActions,
      },
      {
        field: { value: pendingValue },
        pageActions: { edited: pendingEdited } = readOnlyPageActions,
      }
    ) => (currentEdited && !pendingEdited) || currentValue !== pendingValue,
    ({ edited, field: { value }, value: localValue, setValue }) => {
      if (edited || cleanValue(value) !== localValue) {
        setValue({ value, reset: true });
      }

      return empty.object;
    }
  )
)(Field);
