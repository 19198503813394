import { IconButton, Popover } from "material-ui";
import ActionHistory from "material-ui/svg-icons/action/history";
import NavigationMenu from "material-ui/svg-icons/navigation/menu";
import React, { PropsWithChildren, ReactNode } from "react";

import RecentItems from "../../containers/recentItems";
import { StoreProvider } from "../../store";

export interface AppBarMobileProps {
  title: string;
  authenticated?: boolean;
  iconElementLeft?: ReactNode;
  iconElementRight: ReactNode;
  onLeftIconButtonClick: () => void;
  showRecent: boolean;
  toggleRecent: () => void;
}

const AppBarMobile = ({
  title,
  authenticated,
  children,
  iconElementLeft,
  iconElementRight,
  onLeftIconButtonClick,
  showRecent,
  toggleRecent,
}: PropsWithChildren<AppBarMobileProps>) => {
  return (
    <div className="appBar appBar-mobile">
      <div className="wrapper">
        <div className="first">
          <IconButton
            style={{ width: 34, padding: 0 }}
            iconStyle={{ color: "#fff", width: 34 }}
            onClick={onLeftIconButtonClick}
          >
            <NavigationMenu />
          </IconButton>
          {authenticated && (
            <IconButton
              style={{ width: 34, padding: 0 }}
              iconStyle={{ color: "#fff", width: 34 }}
              onClick={toggleRecent}
              title="Toon/verberg Recent bezocht"
            >
              <ActionHistory />
            </IconButton>
          )}
        </div>
        <div className="center">
          <div className="inner">{iconElementLeft}</div>
        </div>
        <div className="last">{iconElementRight}</div>
      </div>
      {showRecent && (
        <Popover
          open
          useLayerForClickAway
          onRequestClose={toggleRecent}
          style={{ margin: "50px 0 0 16px" }}
        >
          <StoreProvider>
            <RecentItems always />
          </StoreProvider>
        </Popover>
      )}
      {children && <div className="wrapper wrapper-extra">{children}</div>}
    </div>
  );
};

export default AppBarMobile;
