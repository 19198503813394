import DropDownMenu from "material-ui/DropDownMenu";
import MenuItem from "material-ui/MenuItem";
import { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";

import { requireExpandedLandscapeAction } from "../../../actions/landscape";
import { init } from "../../../actions/relation";
import { Side } from "../../../business/relations";

const LandscapeAddButton = ({ landscape, relationType, itemId }) => {
  const items = useMemo(() => {
    const items = Object.values(landscape.rectangles).map(
      ({ item: { itemId, label } }) => ({ itemId, label })
    );
    items.sort((a, b) => a.label.localeCompare(b.label));
    return items;
  }, [landscape]);
  const dispatch = useDispatch();
  const onSelect = useCallback(
    (_, _1, value) => {
      const leftItemId = Number(value);
      if (leftItemId && relationType) {
        const callback = () => {
          dispatch({
            type: "EXPANDED_LANDSCAPE_INVALIDATE",
            payload: { id: itemId },
          });
          dispatch(
            requireExpandedLandscapeAction(itemId, [], relationType.alias)
          );
        };

        dispatch(
          init(relationType, Side.left, leftItemId, undefined, callback)
        );
      }
    },
    [dispatch, relationType, itemId]
  );
  return (
    <div>
      <DropDownMenu
        onChange={onSelect}
        value={0}
        className="raised-dropdownmenu secondary"
      >
        <MenuItem value={0} primaryText="Toevoegen relatie aan entiteit:" />
        {items.map(({ itemId, label }) => (
          <MenuItem key={itemId} primaryText={label} value={itemId} />
        ))}
      </DropDownMenu>
    </div>
  );
};

export default LandscapeAddButton;
