import { MenuItem as NativeMenuItem } from "material-ui";
import IconButton from "material-ui/IconButton";
import Menu from "material-ui/Menu";
import Popover, { PopoverAnimationVertical } from "material-ui/Popover";
import ArrowDropDown from "material-ui/svg-icons/navigation/arrow-drop-down";
import ArrowDropUp from "material-ui/svg-icons/navigation/arrow-drop-up";
import React, {
  MouseEvent,
  MouseEventHandler,
  memo,
  useCallback,
  useRef,
  useState,
} from "react";

export interface TabContentItem {
  label: string;
  id: string;
}

export interface TabContentProps {
  enabled: boolean;
  label: string;
  content?: TabContentItem[];
}

export interface TabContentMenuProps {
  open: boolean;
  content: TabContentItem[];
  onOpen: MouseEventHandler;
  onClose: () => void;
}

export interface MenuItemProps {
  item: TabContentItem;
  onClick?: () => void;
}

const headerSelected = "selected-header";
const MenuItem = ({ item, onClick }: MenuItemProps) => {
  const scrollIntoView = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();

      if (onClick) {
        onClick();
      }

      const header = document.getElementById(item.id);
      if (!header) {
        return;
      }

      const top = header.offsetTop + 150;
      window.scrollTo({ top, behavior: "smooth" });
      header.classList.add(headerSelected);
      window.setTimeout(() => {
        header.classList.remove(headerSelected);
      }, 2000);
    },
    [item.id, onClick]
  );

  return <NativeMenuItem primaryText={item.label} onClick={scrollIntoView} />;
};

const TabContentMenu = ({
  open,
  content,
  onOpen,
  onClose,
}: TabContentMenuProps) => {
  const anchor = useRef(null);
  return (
    <div ref={anchor}>
      <IconButton
        onClick={onOpen}
        style={{ width: 16, border: 0, padding: 0 }}
        id="icon-button"
      >
        {open ? <ArrowDropUp color="white" /> : <ArrowDropDown color="white" />}
      </IconButton>
      {open && (
        <Popover
          open={open}
          anchorEl={anchor.current ?? undefined}
          anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
          targetOrigin={{ horizontal: "left", vertical: "top" }}
          onRequestClose={onClose}
          animation={PopoverAnimationVertical}
        >
          <Menu>
            {content.map((item) => (
              <MenuItem key={item.id} item={item} onClick={onClose} />
            ))}
          </Menu>
        </Popover>
      )}
    </div>
  );
};

const TabContent = ({ enabled, label, content }: TabContentProps) => {
  const [open, setOpen] = useState(false);
  const handleOpen = useCallback((event: MouseEvent) => {
    event.preventDefault();
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const spanStyling = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  if (!enabled || !content || content.length === 0) {
    return <span style={spanStyling}>{label}</span>;
  }

  return (
    <span style={spanStyling} onClick={handleOpen}>
      {label}
      <TabContentMenu
        open={open}
        content={content}
        onOpen={handleOpen}
        onClose={handleClose}
      />
    </span>
  );
};

export default memo(TabContent);
