/* eslint  react/prop-types: off */
import empty from "empty";
import { filter, find } from "lodash/fp";
import Menu from "material-ui/Menu";
import MenuItem from "material-ui/MenuItem";
import Popover from "material-ui/Popover";
import SvgIcon from "material-ui/SvgIcon";
import PropTypes from "prop-types";
import React, { memo } from "react";
import {
  compose,
  pure,
  setDisplayName,
  setPropTypes,
  withHandlers,
  withProps,
  withState,
} from "recompose";

import TextField from "./material/SemiControlledTextField";

const DropDownIcon = memo((props) => (
  <SvgIcon {...props}>
    <g transform="matrix(-1,0,0,-1,24,24)">
      <path d="M12,6L24,18L0,18L12,6Z" style={{ fill: "rgb(235,235,235)" }} />
    </g>
  </SvgIcon>
));

const MiniSaarMenu = ({
  state: { anchorEl, menuOpen, search },
  selectedSaar,
  miniSaarList,
  onClose,
  onFocus,
  onSearch,
  setAnchorEl,
  update,
  disabled,
}) => (
  <div className="searchMiniSaar" ref={setAnchorEl}>
    <TextField
      id="searchMiniSaar"
      style={{ marginTop: 4, width: "auto" }}
      inputStyle={{
        color: "white",
        cursor: "default",
        textTransform: menuOpen ? "none" : "uppercase",
      }}
      value={menuOpen ? search : ""}
      onFocus={onFocus}
      onChange={onSearch}
      disabled={disabled}
      underlineShow={false}
      hintText={
        menuOpen ? (
          "Zoek in de lijst"
        ) : (
          <span className="searchMiniSaar-selected">
            <span className="searchMiniSaar-selected-text">
              {selectedSaar.name}
            </span>
            <DropDownIcon />
          </span>
        )
      }
      hintStyle={{
        color: "rgba(255,255,255,0.3)",
        textTransform: "none",
        width: "100%",
      }}
      autoComplete="off"
    />
    {menuOpen && (
      <Popover
        open={menuOpen}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        targetOrigin={{ vertical: "top", horizontal: "left" }}
        onRequestClose={onClose}
      >
        <Menu
          disableAutoFocus
          style={{
            maxHeight: "calc(100vh - 50px)",
            overflow: "auto",
          }}
          onChange={update}
        >
          {miniSaarList.map(({ key, name }) => (
            <MenuItem key={key} primaryText={name} value={key} />
          ))}
          {miniSaarList.length === 0 && (
            <MenuItem disabled primaryText="Niets gevonden" />
          )}
        </Menu>
      </Popover>
    )}
  </div>
);

export default compose(
  setDisplayName("MiniSaarMenu"),
  setPropTypes({
    title: PropTypes.string.isRequired,
    selectedSaar: PropTypes.object.isRequired,
    miniSaarList: PropTypes.array.isRequired,
    updateMiniSaar: PropTypes.func.isRequired,
  }),
  pure,
  withState("state", "setState", {
    setOpen: false,
    anchorEl: undefined,
    search: "",
  }),
  withProps(
    ({ miniSaarList: originalList = empty.array, state: { search = "" } }) => {
      const lower = search.toLowerCase();
      const disabled = originalList.length <= 1;
      const miniSaarList = search
        ? filter(({ name }) => name.toLowerCase().indexOf(lower) >= 0)(
            originalList
          )
        : originalList;
      return { disabled, miniSaarList };
    }
  ),
  withHandlers({
    update:
      ({ updateMiniSaar, miniSaarList, state, setState }) =>
      (e, key) => {
        updateMiniSaar(find({ key })(miniSaarList));
        setState({ ...state, menuOpen: false, search: "" });
      },
    onClose:
      ({ state, setState }) =>
      () => {
        setState({ ...state, menuOpen: false, search: "" });
        document.getElementById("searchMiniSaar").blur();
      },
    onFocus:
      ({ state, setState }) =>
      () =>
        setState({ ...state, menuOpen: true }),
    onSearch:
      ({ state, setState }) =>
      (e) =>
        setState({ ...state, search: e.target.value }),
    setAnchorEl:
      ({ state, setState }) =>
      (anchorEl) =>
        setState({ ...state, anchorEl }),
  })
)(MiniSaarMenu);
