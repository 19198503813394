const takeFilterKeep =
  <T>(
    count: number,
    filter: (t: T) => boolean,
    keep: (t: T) => boolean
  ): ((array: T[]) => T[]) =>
  (array: T[]): T[] => {
    const result = [];
    for (let i = 0; i < array.length; i++) {
      if (keep(array[i]) || (result.length < count && filter(array[i]))) {
        result.push(array[i]);
      }
    }
    return result;
  };

export default takeFilterKeep;
