import { RaisedButton } from "material-ui";
import React, { useCallback, useState } from "react";

import { PageActions } from "../../../../business/models";
import { buttonStyle } from "../../../material/addButton";
import AddModuleDialog from "./addModuleDialog";

interface Props {
  pageActions: PageActions;
}

const AddModule = ({ pageActions }: Props): JSX.Element => {
  const [open, setOpen] = useState(false);
  const toggleOpen = useCallback(() => setOpen(!open), [open]);

  return (
    <>
      <RaisedButton
        label="Module koppelen"
        style={buttonStyle}
        secondary
        onClick={toggleOpen}
      />
      {open && (
        <AddModuleDialog onClose={toggleOpen} pageActions={pageActions} />
      )}
    </>
  );
};

export default AddModule;
