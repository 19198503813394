import { compact, filter, flatten, isEqual, map, orderBy } from "lodash/fp";
import { Checkbox, List, TableRow, TableRowColumn } from "material-ui";
import {
  amber900 as biebColor,
  lightGreen500 as primaryColor,
} from "material-ui/styles/colors";
import NewReleases from "material-ui/svg-icons/av/new-releases";
import IndeterminateCheckBox from "material-ui/svg-icons/toggle/indeterminate-check-box";
import { useCallback, useEffect, useMemo } from "react";

import { PageActions } from "../../actions/page";
import {
  EmptyFieldData,
  FieldDefinition,
  KoppelingClusterMapping,
  Relation,
  RelationType,
  emptyArray,
} from "../../business/models";
import { reverseSide } from "../../business/relations";
import { flown } from "../../lodash";
import zipOuterBy from "../../zipOuterBy";
import { TreeItemChildren } from "../material/tree/models";
import { Tree, TreeItem } from "../material/tree/Tree";
import ClusterRelationView from "./ClusterRelationView";
import CompareStaticFields, { areValuesEqual } from "./CompareStaticFields";
import { CompareClusterTreeProps } from "./models";
import {
  CollectedCluster,
  LocatedFieldData,
  RelatedCollectedCluster,
  flattenClusterTree,
  staticFields,
} from "./utils";

export const emptyField = (definition: FieldDefinition): EmptyFieldData =>
  ({
    iproxType: "field",
    isEmpty: true,
    value: null,
    definition,
  } as EmptyFieldData);

const render = (
  tree: CollectedCluster[],
  related: RelatedCollectedCluster[],
  biebItemId: number,
  localItemId: number,
  pageActions: PageActions,
  changedBiebFieldIds: number[],
  changedBiebRelations: number[],
  showAllFields: boolean,
  types: RelationType[],
  onCheckAll: (biebFieldIds: number[], isInputChecked: boolean) => void,
  selected: number[],
  isKoppeling: boolean,
  koppelingClusterMapping: KoppelingClusterMapping | undefined
): TreeItemChildren =>
  tree.map((c) => {
    const key = `cluster-${c.clusterId}`;
    const biebFields = staticFields(c.content, [key]);
    const localCluster = related
      .filter(
        ([biebCluster, _]) =>
          c.clusterId && biebCluster.clusterId === c.clusterId
      )
      .map(([_, localCluster]) => localCluster)[0];
    const localFields = localCluster
      ? staticFields(localCluster.content, [key], true)
      : biebFields.map(({ path, content }) => ({
          path,
          content: emptyField(content.definition),
        }));

    const zip = zipOuterBy(
      biebFields,
      localFields,
      (item) => item.path,
      (path) => path.join("."),
      (item) => ({ ...item.content, clusterId: item.clusterId })
    ).filter(
      ([_path, biebField, localField]) =>
        localField &&
        biebField &&
        (!Boolean(biebField?.isEmpty) || !Boolean(localField?.isEmpty))
    );

    const firstPlainTextField = biebFields.find(
      (f) => f.content.definition.dataTypeCode === 2
    );
    const extraLabel = firstPlainTextField
      ? `: ${firstPlainTextField.content.value}`
      : "";

    const descendantBiebFieldIds = flattenClusterTree([c]).flatMap((cluster) =>
      staticFields(cluster.content, [], true).map((f) => f.content.id)
    );

    const descendantBiebRelationIds = flattenClusterTree([c])
      .flatMap((cluster) => cluster.relations)
      .map((r) => r?.id);

    const descendantBiebFieldIdsWithChanges = descendantBiebFieldIds.filter(
      (id) => changedBiebFieldIds.includes(id)
    );
    const allSelected =
      descendantBiebFieldIdsWithChanges.length > 0 &&
      descendantBiebFieldIdsWithChanges.every((id) => selected.includes(id));
    const someSelected =
      descendantBiebFieldIdsWithChanges.length > 0 &&
      descendantBiebFieldIdsWithChanges.some((id) => selected.includes(id));

    const relationsBieb = c.relations ? (
      <List>
        {flown(
          c.relations,
          map((r: Relation) => {
            const others =
              r.side === 3
                ? [r.left, r.right]
                : [r.side === 2 ? r.left : r.right];
            return others.map((other, index) => ({
              other,
              side: r.side === 3 ? (index === 0 ? 0 : 1) : reverseSide(r.side),
              relation: r,
            }));
          }),
          flatten,
          orderBy(["side", "other.label"], ["asc", "asc"]),
          map(({ other, side, relation }) => (
            <ClusterRelationView
              key={other.itemId}
              item={other}
              side={side}
              relation={relation}
              types={types}
            />
          ))
        )}
      </List>
    ) : null;

    const relationsLocal = localCluster?.relations ? (
      <List>
        {flown(
          localCluster.relations,
          map((r: Relation) => {
            const others =
              r.side === 3
                ? [r.left, r.right]
                : [r.side === 2 ? r.left : r.right];
            return others.map((other, index) => ({
              other,
              side: r.side === 3 ? (index === 0 ? 0 : 1) : reverseSide(r.side),
              relation: r,
            }));
          }),
          flatten,
          orderBy(["side", "other.label"], ["asc", "asc"]),
          map(({ other, side, relation }) => (
            <ClusterRelationView
              key={other.itemId}
              item={other}
              side={side}
              relation={relation}
              types={types}
            />
          ))
        )}
      </List>
    ) : null;

    const hasClusterChanges = changedBiebFieldIds.some((id) =>
      descendantBiebFieldIds.includes(id)
    );

    const hasRelationChanges = changedBiebRelations.some((id) =>
      descendantBiebRelationIds.includes(id)
    );

    const hasChanges = hasClusterChanges || hasRelationChanges;

    const isGegevenCluster = c.label === "Gegeven";
    const canUpdate =
      !isKoppeling ||
      (isGegevenCluster &&
        compact(koppelingClusterMapping?.[c.clusterId!] ?? emptyArray())
          .length === 2);

    if (isKoppeling && !canUpdate && c.children.length === 0 && localCluster) {
      return (
        <TreeItem
          key={c.clusterId}
          label={`Verwijderen "${c.label}${extraLabel}"`}
          leftCheckbox={
            <Checkbox style={{ top: "calc(50% - 12px)" }} checked disabled />
          }
          leftCheckboxTitle="Het cluster wordt verwijderd i.v.m. missend applicatieveld"
          rightIcon={hasChanges ? <NewReleases color={biebColor} /> : undefined}
        />
      );
    }

    if (!hasChanges && !showAllFields) {
      return null;
    }

    return (
      <TreeItem
        key={c.clusterId}
        label={`${c.label}${extraLabel}`}
        leftCheckbox={
          <Checkbox
            style={{ top: "calc(50% - 12px)" }}
            onCheck={(_, checked) =>
              onCheckAll(descendantBiebFieldIdsWithChanges, checked)
            }
            checked={allSelected}
            uncheckedIcon={someSelected ? <IndeterminateCheckBox /> : undefined}
            iconStyle={someSelected ? { fill: primaryColor } : undefined}
            disabled={!canUpdate || !hasClusterChanges}
          />
        }
        leftCheckboxTitle={
          hasClusterChanges
            ? "Alles bijwerken binnen dit cluster"
            : "Het is niet mogelijk om dit cluster bij te werken, alle velden komen overeen. De relaties kunnen wel verschillen"
        }
        rightIcon={hasChanges ? <NewReleases color={biebColor} /> : undefined}
        content={
          <CompareStaticFields
            biebItemId={biebItemId}
            localItemId={localItemId}
            zip={zip}
            pageActions={pageActions}
            showAllFields={showAllFields}
            showEmptyMessage={c.children.length === 0}
            extraRows={
              (c.relations?.length ?? 0) > 0 ? (
                <TableRow key={key}>
                  <TableRowColumn></TableRowColumn>
                  <TableRowColumn colSpan={2} style={{ verticalAlign: "top" }}>
                    {relationsBieb}
                  </TableRowColumn>
                  <TableRowColumn colSpan={2} style={{ verticalAlign: "top" }}>
                    {relationsLocal}
                  </TableRowColumn>
                </TableRow>
              ) : undefined
            }
            selected={selected}
            disabled={!canUpdate}
          />
        }
      >
        {render(
          c.children,
          related,
          biebItemId,
          localItemId,
          pageActions,
          changedBiebFieldIds,
          changedBiebRelations,
          showAllFields,
          types,
          onCheckAll,
          selected,
          isKoppeling,
          koppelingClusterMapping
        )}
      </TreeItem>
    );
  });

const areRelationsEqual = (
  one: Relation | undefined,
  two: Relation | undefined
) => {
  const otherOne = one?.side === 2 ? one?.left : one?.right;
  const otherOneLabel = `${otherOne?.label}:${otherOne?.pageClusterLabel}:${one?.relationTypeId}`;

  const otherTwo = two?.side === 2 ? two?.left : two?.right;
  const otherTwoLabel = `${otherTwo?.label}:${otherTwo?.pageClusterLabel}:${two?.relationTypeId}`;

  return isEqual(otherOneLabel, otherTwoLabel);
};

const getFieldsWithChanges = (
  biebCluster: CollectedCluster,
  sourceCluster: CollectedCluster,
  isKoppeling: boolean,
  koppelingClusterMapping: KoppelingClusterMapping | undefined,
  path: string[] = [`biebcluster-${biebCluster.clusterId}`],
  withEmptyFields = false
) => {
  const isGegevenCluster = biebCluster.label === "Gegeven";
  const canUpdate =
    !isKoppeling ||
    (isGegevenCluster &&
      compact(koppelingClusterMapping?.[biebCluster.clusterId!] ?? emptyArray())
        .length === 2);

  if (!canUpdate) {
    return [];
  }

  return staticFields(sourceCluster.content, path, withEmptyFields);
};

const CompareClusterTree = ({
  biebItemId,
  localItemId,
  biebTree,
  localTree,
  relatedItems,
  pageActions,
  showAllFields,
  types,
  selected,
  isKoppeling,
  koppelingClusterMapping,
  staged,
}: CompareClusterTreeProps) => {
  const flattenedBieb = useMemo(() => flattenClusterTree(biebTree), [biebTree]);
  const flattenedLocal = useMemo(
    () => flattenClusterTree(localTree),
    [localTree]
  );
  const flattenedBiebFieldsFromRepCls = useMemo<LocatedFieldData[]>(
    () =>
      flown(
        flattenedBieb,
        map((cluster: CollectedCluster) =>
          getFieldsWithChanges(
            cluster,
            cluster,
            isKoppeling,
            koppelingClusterMapping
          )
        ),
        flatten
      ),
    [flattenedBieb, isKoppeling, koppelingClusterMapping]
  );

  const flattenedLocalFieldsFromRepCls = useMemo<LocatedFieldData[]>(
    () =>
      flown(
        flattenedBieb,
        map((cluster: CollectedCluster) => {
          const [biebCluster, localCluster] = relatedItems.find(
            ([biebCluster, _]) =>
              cluster.clusterId && biebCluster.clusterId === cluster.clusterId
          ) ?? [undefined, undefined];
          const biebFields = staticFields(biebCluster!.content, [
            `biebcluster-${biebCluster?.clusterId}`,
          ]);

          return localCluster
            ? getFieldsWithChanges(
                cluster,
                localCluster,
                isKoppeling,
                koppelingClusterMapping,
                [`biebcluster-${biebCluster?.clusterId}`],
                true
              )
            : biebFields.map(({ path, content }) => ({
                path,
                content: emptyField(content.definition),
              }));
        }),
        flatten
      ),
    [flattenedBieb, isKoppeling, koppelingClusterMapping, relatedItems]
  );
  const zipRepCls = useMemo(
    () =>
      zipOuterBy(
        flattenedBiebFieldsFromRepCls,
        flattenedLocalFieldsFromRepCls,
        (item) => item.path,
        (path) => path.join("."),
        (item) => ({ ...item.content, clusterId: item.clusterId })
      ).filter(
        ([_path, biebField, localField]) =>
          localField &&
          biebField &&
          (!Boolean(biebField?.isEmpty) || !Boolean(localField?.isEmpty))
      ),
    [flattenedBiebFieldsFromRepCls, flattenedLocalFieldsFromRepCls]
  );

  const changedBiebFieldIds = useMemo(
    () =>
      zipRepCls
        .filter(
          ([_path, biebField, localField]) =>
            !(biebField && areValuesEqual(biebField?.value, localField?.value))
        )
        .map(([_path, biebField, _localField]) => biebField!.id),
    [zipRepCls]
  );

  const flattenedBiebRelations = useMemo<Relation[]>(
    () =>
      flown(
        flattenedBieb.map((c) => c.relations),
        flatten
      ),
    [flattenedBieb]
  );
  const flattenedLocalRelations = useMemo<Relation[]>(
    () =>
      flown(
        flattenedBieb.map((c) => {
          const [, localCluster] = relatedItems.find(
            ([biebCluster, _]) =>
              c.clusterId && biebCluster.clusterId === c.clusterId
          ) ?? [undefined, undefined];
          return localCluster?.relations ?? [];
        }),
        flatten
      ),
    [flattenedBieb, relatedItems]
  );
  const zipRelations = useMemo(
    () =>
      zipOuterBy(
        flattenedBiebRelations,
        flattenedLocalRelations,
        (relation) => [relation.relationTypeId],
        (relationTypes) => relationTypes.join("."),
        (relation) => ({ ...relation })
      ),
    [flattenedBiebRelations, flattenedLocalRelations]
  );

  const changedBiebRelations = useMemo(
    () =>
      flown(
        zipRelations,
        filter(
          ([_path, biebRelation, localRelation]) =>
            !(biebRelation && areRelationsEqual(biebRelation, localRelation))
        ),
        map(([_path, biebField, _localField]) => biebField?.id),
        compact
      ),
    [zipRelations]
  );

  const handleCheckAll = useCallback(
    (biebFieldIds: number[], isInputChecked: boolean) => {
      biebFieldIds
        .filter(
          (biebFieldId) =>
            !isInputChecked ||
            (isInputChecked && !selected.includes(biebFieldId))
        )
        .forEach((biebFieldId) => {
          if (isInputChecked) {
            pageActions.fieldCopy({
              itemId: localItemId,
              sourceItemId: biebItemId,
              sourceFieldId: biebFieldId,
            });
          } else {
            pageActions.fieldCopyRevert({
              itemId: localItemId,
              sourceItemId: biebItemId,
              sourceFieldId: biebFieldId,
            });
          }
        });
    },
    [biebItemId, localItemId, pageActions, selected]
  );

  /* Variables needed for the "Check all" checkbox */
  const descendantBiebFieldIds = useMemo(
    () =>
      flattenClusterTree(biebTree).flatMap((cluster) =>
        staticFields(cluster.content, [], true).map((f) => f.content.id)
      ),
    [biebTree]
  );
  const hasChanges = useMemo(
    () => changedBiebFieldIds.some((id) => descendantBiebFieldIds.includes(id)),
    [changedBiebFieldIds, descendantBiebFieldIds]
  );
  const descendantBiebFieldIdsWithChanges = useMemo(
    () =>
      descendantBiebFieldIds.filter((id) => changedBiebFieldIds.includes(id)),
    [changedBiebFieldIds, descendantBiebFieldIds]
  );
  const allSelected = useMemo(
    () =>
      descendantBiebFieldIdsWithChanges.every((id) => selected.includes(id)),
    [descendantBiebFieldIdsWithChanges, selected]
  );
  const someSelected = useMemo(
    () => descendantBiebFieldIdsWithChanges.some((id) => selected.includes(id)),
    [descendantBiebFieldIdsWithChanges, selected]
  );

  useEffect(() => {
    flattenedLocal.forEach((localCluster) => {
      const biebCluster = relatedItems
        .filter(
          ([_, local]) =>
            localCluster.clusterId &&
            local?.clusterId === localCluster.clusterId
        )
        .map(([bieb, _]) => bieb)[0];

      const canUpdate =
        !isKoppeling ||
        (biebCluster &&
          localCluster.label === "Gegeven" &&
          compact(
            koppelingClusterMapping?.[biebCluster.clusterId!] ?? emptyArray()
          ).length === 2);

      if (isKoppeling && !canUpdate && localCluster.children.length === 0) {
        if (
          staged.some(
            (s) =>
              /ClusterRemove/.test(s.$type) &&
              s.clusterId === localCluster.clusterId &&
              s.parentId === localCluster.parentClusterId
          )
        ) {
          return;
        }

        // Can't update "koppeling", but has all content
        const payload = {
          parentId: localCluster.parentClusterId,
          clusterId: localCluster.clusterId,
          name: localCluster.label,
        };

        pageActions.raise("FORM_PAGE_UNSTAGE", {
          $type:
            "InfoZorgSAAR.Iprox.ContentActions.ClusterRemoveAction, SAAR.Iprox",
          ...payload,
        });
        pageActions.clusterRemove(payload);
      }
    });
  }, [
    flattenedLocal,
    isKoppeling,
    koppelingClusterMapping,
    pageActions,
    relatedItems,
    staged,
  ]);

  const treeItems = flown(
    render(
      biebTree,
      relatedItems,
      biebItemId,
      localItemId,
      pageActions,
      changedBiebFieldIds,
      changedBiebRelations,
      showAllFields,
      types,
      handleCheckAll,
      selected,
      isKoppeling,
      koppelingClusterMapping
    ),
    compact
  );

  if (treeItems == null || treeItems.length === 0) {
    return <p>Er is geen inhoud gevonden om bij te werken.</p>;
  }

  return (
    <Tree labelClickable>
      <TreeItem
        label="Alles selecteren"
        containerStyle={{ borderBottom: "1px solid rgb(224, 224, 224)" }}
        labelStyle={{ color: "rgba(0, 0, 0, 0.87)", fontStyle: "italic" }}
        leftCheckbox={
          <Checkbox
            style={{ top: "calc(50% - 12px)" }}
            onCheck={(_, checked) =>
              handleCheckAll(descendantBiebFieldIdsWithChanges, checked)
            }
            checked={allSelected}
            uncheckedIcon={someSelected ? <IndeterminateCheckBox /> : undefined}
            iconStyle={someSelected ? { fill: primaryColor } : undefined}
            disabled={!hasChanges}
          />
        }
        onClick={
          hasChanges
            ? () =>
                handleCheckAll(descendantBiebFieldIdsWithChanges, !allSelected)
            : undefined
        }
      />
      {treeItems}
    </Tree>
  );
};

export default CompareClusterTree;
