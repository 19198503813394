/* eslint  react/prop-types: off */
import empty from "empty";
import { Card, CardText } from "material-ui/Card";
import FlatButton from "material-ui/FlatButton";
import TextField from "material-ui/TextField";
import React, { Fragment } from "react";
import { Link } from "react-router";

import { Loading } from "./login";

const isEmailaddress = (input) => {
  const re =
    /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
  return re.test(input);
};

const Forgot = ({
  loading = false,
  emailaddress = "",
  handleChange = empty.func,
  handleSubmit = empty.func,
}) => (
  <Fragment>
    <main className="login-form">
      <Card>
        <CardText>
          <form method="post">
            <TextField
              name="emailaddress"
              onChange={handleChange}
              hintText="E-mailadres"
              floatingLabelText="E-mailadres"
              floatingLabelFixed
              value={emailaddress}
            />
            <br />
            <FlatButton
              label="E-mail sturen"
              type="submit"
              primary
              onClick={handleSubmit}
              disabled={!isEmailaddress(emailaddress)}
            />
            <br />
            <FlatButton
              label="Annuleren"
              secondary
              containerElement={<Link to="/inloggen" />}
            />
          </form>
        </CardText>
      </Card>
    </main>
    {loading && <Loading />}
  </Fragment>
);

export default Forgot;
