import { loadingPresent } from "async-lifecycle";
import empty from "empty";
import {
  filter,
  find,
  findLast,
  flatten,
  get,
  has,
  map,
  reject,
  some,
  startsWith,
  uniq,
} from "lodash/fp";
import {
  branch,
  compose,
  mapProps,
  renderNothing,
  setDisplayName,
} from "recompose";

import { requireZibs } from "../../../actions/application";
import { RelationTypeGroups } from "../../../actions/page";
import ZibSelect from "../../../components/pagetypes/application/zibSelect";
import { flown } from "../../../lodash";
import { execOnChange } from "../../../recompose.contrib";
import { dispatchWrap } from "../../../utils";
import connect from "../../connect";

const mapVelden = ({
  pageActions,
  siteId,
  clusterId,
  zibs: { loading, zibs } = empty.object,
  bieb,
  relations,
  staged,
  types,
}) => {
  if (!loadingPresent(loading)) {
    return empty.object;
  }

  const zipRelTypes = types.filter(({ alias }) =>
    startsWith("applicatieveld_zib")(alias)
  );
  if (zipRelTypes.length === 0) {
    return empty.object;
  }

  const zibveldRelations = flown(
    relations,
    filter(({ alias }) => startsWith("applicatieveld_zib")(alias)),
    map("links"),
    flatten,
    filter({ pageClusterId: clusterId })
  );

  const label = flown(
    types,
    filter(({ alias }) => startsWith("applicatieveld_zib")(alias)),
    map("left.label"),
    uniq
  ).join("/");

  const list = (bieb ? filter({ bieb: siteId }) : reject(has("bieb")))(zibs);
  const selected =
    staged?.map((link) => ({
      itemId: link.narItmIdt,
      pageClusterId: link.narPagClsIdt,
    })) ?? zibveldRelations.map(get("item"));

  return {
    label,
    name: `zibveld_${clusterId}`,
    zibs: list,
    edit: pageActions.edit,
    type: "veld",
    selected,
    onChange: (links) => {
      const relations = flown(
        links,
        map((link) => ({
          itmRelTypIdt: find({ alias: link.type })(zipRelTypes).id,
          narItmIdt: link.itemId,
          narPagClsIdt: link.pageClusterId,
        }))
      );
      pageActions.clusterSetRelations(
        {
          clusterId,
          relations,
          relationTypeGroup: RelationTypeGroups.zibApplicatieVeld,
        },
        false // TODO: check whether links differs from stored
      );
    },
  };
};

const ZibVelden = compose(
  setDisplayName("ZibVelden"),
  connect(
    (
      {
        data: {
          pages,
          zibs,
          addLegoblok: { structures = empty.array } = empty.object,
          relationTypes: { types = empty.array },
        },
        form: { page },
      },
      { clusterId, pageActions: { pageId } = empty.object }
    ) => {
      const { siteId } = pages[pageId];
      const bieb = flown(
        structures,
        some(({ id }) => id === siteId)
      );

      const stagedAction = flown(
        page,
        get(`${pageId}.staged`),
        findLast(
          ({ $type: type, clusterId: stagedClusterId, relationTypeGroup }) =>
            clusterId === stagedClusterId &&
            relationTypeGroup === RelationTypeGroups.zibApplicatieVeld &&
            /ClusterReplaceRelations/.test(type)
        )
      );

      return {
        siteId,
        page,
        bieb,
        zibs,
        types,
        staged: stagedAction?.relations,
      };
    },
    (dispatch) => ({
      requireZibs: dispatchWrap(requireZibs, dispatch),
      dispatch,
    })
  ),
  execOnChange("requireZibs"),
  mapProps(mapVelden),
  branch(({ edit, selected }) => !edit && !selected, renderNothing)
)(ZibSelect);

export default ZibVelden;
