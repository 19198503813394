import { IconButton } from "material-ui";
import VisibilityIcon from "material-ui/svg-icons/action/visibility";
import { CSSProperties, MouseEvent, useCallback } from "react";
import { useDispatch } from "react-redux";

import { setPreviewAction } from "../../actions/ui";

const PreviewButton = ({
  itemId,
  style,
}: {
  itemId: number;
  style?: CSSProperties;
}) => {
  const dispatch = useDispatch();
  const handlePreview = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      dispatch(setPreviewAction(itemId));
    },
    [dispatch, itemId]
  );

  return (
    <IconButton
      className="preview-btn"
      onClick={handlePreview}
      style={style}
      title="Bekijk preview"
    >
      <VisibilityIcon />
    </IconButton>
  );
};

export default PreviewButton;
