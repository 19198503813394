import classNames from "classnames";
/* eslint-disable jsx-a11y/anchor-is-valid */
import empty from "empty";
import { filter, flow, get, map } from "lodash/fp";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import {
  compose,
  setDisplayName,
  setPropTypes,
  withHandlers,
  withProps,
} from "recompose";
import { createSelector } from "reselect";

import { structureShape } from "../../business/prop-types";
import { pagetypeViewName } from "../../pagetypes";
import LegoblokMoveButton from "./legoblok/legoblokMoveButton";

const PagetypeIndicator = ({
  pagetype,
  pagetypeLabel,
  show,
  mayEdit,
  edit,
  dirty,
  legoblokStructures = empty.array,
  moveLegoblokTo = empty.func,
  itemId,
  siteId: structureId,
  editLabelComponent,
  modifiedDisplay,
  section,
  handleVersionsDrawer,
  editStatusComponent,
  extraButtons,
}) =>
  pagetype && pagetypeLabel && show ? (
    <div
      className={classNames("subtitle", {
        "has-extra-buttons": Boolean(extraButtons),
      })}
    >
      {edit && mayEdit && editLabelComponent ? (
        editLabelComponent
      ) : (
        <span className="pagetype">{pagetypeViewName(pagetypeLabel)}</span>
      )}
      {flow(
        filter(({ id }) => id === structureId),
        map(({ name }) => (
          <Fragment key={name}>
            {" - "}
            <span className="bieb">
              {mayEdit && edit && legoblokStructures.length > 1 ? (
                <LegoblokMoveButton
                  structures={legoblokStructures}
                  moveLegoblokTo={moveLegoblokTo}
                  itemId={itemId}
                  structureId={structureId}
                  disabled={dirty}
                />
              ) : (
                name
              )}
            </span>
          </Fragment>
        ))
      )(legoblokStructures)}
      {editStatusComponent}
      {extraButtons}
      {modifiedDisplay &&
        (section ? (
          <span className="published">{modifiedDisplay}</span>
        ) : (
          <a className="published" onClick={handleVersionsDrawer} href="">
            {modifiedDisplay}
          </a>
        ))}
    </div>
  ) : null;

PagetypeIndicator.defaultProps = {
  modifiedDisplay: undefined,
};

PagetypeIndicator.propTypes = {
  pagetype: PropTypes.string.isRequired,
  pagetypeLabel: PropTypes.string.isRequired,
  show: PropTypes.bool,
  mayEdit: PropTypes.bool,
  edit: PropTypes.bool,
  dirty: PropTypes.bool,
  legoblokStructures: PropTypes.arrayOf(structureShape),
  moveLegoblokTo: PropTypes.func,
  itemId: PropTypes.number.isRequired,
  siteId: PropTypes.number.isRequired,
  modifiedDisplay: PropTypes.string,
  editLabelComponent: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node.isRequired).isRequired,
    PropTypes.node.isRequired,
  ]),
  handleVersionsDrawer: PropTypes.func.isRequired,
  section: PropTypes.bool,
};

const momentDisplaySelector = createSelector([get("modified")], (modified) => {
  if (!modified) {
    return undefined;
  }

  const now = moment();
  const modifiedMoment = moment(modified);
  const modifiedDisplay =
    modifiedMoment.diff(now, "days") < -7
      ? modifiedMoment.format("LL")
      : modifiedMoment.fromNow();
  return { modifiedMoment, modifiedDisplay };
});

export default compose(
  setDisplayName("PagetypeIndicator"),
  setPropTypes({
    modified: PropTypes.string,
  }),
  withProps(momentDisplaySelector),
  withHandlers({
    handleVersionsDrawer:
      ({ showVersionsDrawer }) =>
      (event) => {
        event.preventDefault();
        showVersionsDrawer(true);
      },
  })
)(PagetypeIndicator);
