import { compose, setDisplayName } from "recompose";

import { withStyle } from "../../recompose.contrib";

const Heading = compose(
  setDisplayName("Heading"),
  withStyle({ marginLeft: "16px" })
)(({ children, ...props }) => <h2 {...props}>{children}</h2>);

export default Heading;
