import CircularProgress from "material-ui/CircularProgress";
import FlatButton from "material-ui/FlatButton";
import RaisedButton from "material-ui/RaisedButton";
import PropTypes from "prop-types";
import React from "react";
import { compose, setDisplayName, setPropTypes, withHandlers } from "recompose";

const OverzichtStepButtons = ({
  step,
  handleNext,
  handlePrev,
  handleSubmit,
  handleReset,
  enabled,
  submit,
  loading,
}) => (
  <div style={{ margin: "12px 0", display: "flex", alignItems: "center" }}>
    {submit ? (
      <RaisedButton
        label="Maak overzicht"
        disableTouchRipple
        disableFocusRipple
        primary
        onClick={handleSubmit}
        style={{ marginRight: 12 }}
        disabled={!enabled}
      />
    ) : (
      <RaisedButton
        label="Volgende"
        disableTouchRipple
        disableFocusRipple
        primary
        onClick={handleNext}
        style={{ marginRight: 12 }}
        disabled={!enabled}
      />
    )}
    {step > 0 && (
      <FlatButton
        label="Vorige"
        disableTouchRipple
        disableFocusRipple
        onClick={handlePrev}
      />
    )}
    {submit && (
      <FlatButton
        label="Leegmaken"
        disableTouchRipple
        disableFocusRipple
        onClick={handleReset}
      />
    )}
    {loading && <CircularProgress size={24} />}
  </div>
);

OverzichtStepButtons.propTypes = {
  step: PropTypes.number.isRequired,
  handleNext: PropTypes.func.isRequired,
  handlePrev: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired,
  enabled: PropTypes.bool,
  submit: PropTypes.bool,
  loading: PropTypes.bool,
};

export default compose(
  setDisplayName("OverzichtStepButtons"),
  setPropTypes({
    onSubmit: PropTypes.func,
  }),
  withHandlers({
    handleNext:
      ({ stepChange, step }) =>
      () =>
        stepChange(step + 1),
    handlePrev:
      ({ stepChange, step }) =>
      () =>
        step > 0 ? stepChange(step - 1) : stepChange(0),
    handleSubmit:
      ({ onSubmit }) =>
      () =>
        onSubmit(),
    handleReset:
      ({ onReset }) =>
      () =>
        onReset(),
  })
)(OverzichtStepButtons);
