import { SeverityLevel } from "@microsoft/applicationinsights-web";
import empty from "empty";
import { deepOrange500 } from "material-ui/styles/colors";
import ActionTrendingDown from "material-ui/svg-icons/action/trending-down";
import React from "react";
import { connect } from "react-redux";
import { branch, compose, lifecycle, renderComponent } from "recompose";

import { logError } from "../actions/utils";
import HintCard from "../components/material/HintCard";
import { ignoreKnownIrrelevancies } from "../recompose.contrib";
import { getAppInsights } from "../telemetry/service";
import { dispatchWrap } from "../utils";

const errorStyle = Object.freeze({ clear: "both", marginBottom: 16 });

// Ignoring props { error, info }
const Error = () => {
  return (
    <HintCard
      primaryText="Dit deel van de pagina kan niet getoond worden"
      avatar={<ActionTrendingDown color={deepOrange500} />}
      style={errorStyle}
    />
  );
};

const consoleCatch = compose(
  connect(empty.functionThatReturns(empty.object), (dispatch) => ({
    logError: dispatchWrap(logError, dispatch),
  })),
  lifecycle({
    componentDidCatch(error, info) {
      /* track exception in AppInsights */
      const appInsights = getAppInsights();
      if (appInsights) {
        appInsights.trackException({
          error,
          exception: error,
          severityLevel: SeverityLevel.Error,
          properties: { ...info },
        });
      }

      /* track exception in IPROX.log */
      this.props.logError({
        status:
          error && typeof error === "object"
            ? error.status ||
              error.message ||
              error.description ||
              error.toString()
            : error,
      });
      this.setState({ hasError: true, error, info });
    },
  }),
  branch(({ hasError }) => hasError, renderComponent(Error))
);

const consoleConnect = (...args) =>
  compose(consoleCatch, connect(...args), ignoreKnownIrrelevancies);

export default consoleConnect;
