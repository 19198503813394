import empty from "empty";
import { get, map } from "lodash/fp";
import { branch, compose, renderNothing, setDisplayName } from "recompose";

import {
  addKoppelingGegevenAction,
  closeKoppelingGegevenAction,
  openKoppelingGegevenAction,
} from "../../../actions/koppeling";
import GegevenDialogComponent from "../../../components/pagetypes/koppeling/gegevenDialog";
import AddGegevenComponent from "../../../components/pagetypes/shared/addGegeven";
import { flown } from "../../../lodash";
import { koppelingGegevenDialogSelector } from "../../../selectors/koppeling";
import { dispatchWrap } from "../../../utils";
import connect from "../../connect";

export const AddGegeven = compose(
  setDisplayName("AddGegeven"),
  connect(
    (_, { pageClusterId }) => ({
      label: pageClusterId
        ? "Gegevensoverdracht bewerken"
        : "Gegevensoverdracht toevoegen",
    }),
    (dispatch, { pageClusterId, koppelingVeldenRelations }) => ({
      openDialog: () => {
        const selected = pageClusterId
          ? flown(
              koppelingVeldenRelations,
              get(pageClusterId),
              map(({ van, nar }) => ({
                left: {
                  itmIdt: van.itemId,
                  pagClsIdt: van.pageClusterId,
                  naam: van.pageClusterLabel,
                },
                right: {
                  itmIdt: nar.itemId,
                  pagClsIdt: nar.pageClusterId,
                  naam: nar.pageClusterLabel,
                },
              }))
            )
          : empty.array;
        return dispatch(openKoppelingGegevenAction(pageClusterId, selected));
      },
    })
  )
)(AddGegevenComponent);

export const GegevenDialog = compose(
  setDisplayName("GegevenDialog"),
  connect(
    (state, props) => ({
      dialog: koppelingGegevenDialogSelector(state, props),
    }),
    (dispatch) => ({
      closeDialog: dispatchWrap(closeKoppelingGegevenAction, dispatch),
      save: dispatchWrap(addKoppelingGegevenAction, dispatch),
    })
  ),
  branch(({ dialog: { open } = empty.object }) => !open, renderNothing)
)(GegevenDialogComponent);
