import classNames from "classnames";
import empty from "empty";
import { get, omit } from "lodash/fp";
import { Card, CardHeader, CardText } from "material-ui/Card";
import RaisedButton from "material-ui/RaisedButton";
import KeyboardArrowRight from "material-ui/svg-icons/hardware/keyboard-arrow-right";
import React from "react";
import { DragSource, DropTarget } from "react-dnd";
import { compose, pure, setDisplayName } from "recompose";

import AddButton from "../../../containers/addButton";
import Cluster from "../../content/cluster";
import Field from "../../content/field";

/**
 * Implements the drag source contract.
 */
const cardSource = {
  canDrag: ({ pageActions: { edit = false } = empty.object }) => edit,
  beginDrag: ({ blok: { clusterId } = empty.object, position }) => ({
    clusterId,
    position,
  }),
  endDrag: ({ moveBlok }, monitor) => {
    const dragIndex = monitor.getItem().position;
    const dropIndex = get("position")(monitor.getDropResult());

    if (typeof dropIndex !== "number" || dragIndex === dropIndex) {
      return;
    }

    moveBlok(dragIndex, dropIndex);
  },
};

/**
 * Implements the drag target contract.
 */
const cardTarget = {
  drop: ({ blok: { clusterId } = empty.object, position }) => ({
    clusterId,
    position,
  }),
};

/**
 * Specifies the props to inject into your component.
 */
const collectSource = (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  isDragging: monitor.isDragging(),
});

/**
 * Specifies the props to inject into your component.
 */
const collectTarget = (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
});

const centeredStyling = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const DashboardBlok = ({
  blok: {
    clusterId,
    omschrijving,
    omschrijving: {
      titel = empty.object,
      inhoud = empty.object,
    } = empty.object,
    verwijzingen,
  } = empty.object,
  position,
  pageActions: { edit } = empty.object,
  pageActions,
  connectDragSource = empty.func,
  connectDropTarget = empty.func,
  deleteBlok = empty.func,
  isDragging,
  isOver,
}) => {
  const hoverStyle = isOver
    ? {
        boxShadow:
          "rgba(255, 193, 7, 0.8) 0px 1px 6px, rgba(255, 193, 7, 0.8) 0px 1px 4px",
      }
    : isDragging
    ? { opacity: 0.25 }
    : empty.object;

  return connectDragSource(
    connectDropTarget(
      <div
        className={classNames("dashboard-blok", {
          last2: (position + 1) % 2 === 0,
          last3: (position + 1) % 3 === 0,
        })}
      >
        <Card style={hoverStyle}>
          <CardHeader>
            <Field
              field={titel}
              pageActions={pageActions}
              plug={{ fullWidth: () => true }}
            />
          </CardHeader>
          <CardText>
            <Field
              clusterId={omschrijving.clusterId}
              field={inhoud}
              pageActions={pageActions}
            />
            {verwijzingen &&
              verwijzingen.clusters &&
              verwijzingen.clusters.length > 0 &&
              verwijzingen.clusters.map((c) => (
                <Cluster
                  key={c.clusterId}
                  cluster={omit("names")(c)}
                  pageActions={pageActions}
                  plug={{
                    fullWidth: () => true,
                    renderStart: () => !edit && <KeyboardArrowRight />,
                  }}
                />
              ))}
            {edit && verwijzingen && (
              <AddButton
                name="Verwijzing"
                clusterId={clusterId}
                pageActions={pageActions}
                enabled
                useIconMenu
                style={centeredStyling}
              />
            )}
            {edit && clusterId && (
              <div style={centeredStyling}>
                <RaisedButton
                  onClick={() => deleteBlok(clusterId)}
                  label="Blok verwijderen"
                />
              </div>
            )}
          </CardText>
        </Card>
      </div>
    )
  );
};

export default compose(
  setDisplayName("DashboardBlok"),
  pure,
  DropTarget("dashboard-blok", cardTarget, collectTarget),
  DragSource("dashboard-blok", cardSource, collectSource)
)(DashboardBlok);
