import { filter, flatten, includes, map, uniq } from "lodash/fp";
import { Card, CardTitle } from "material-ui/Card";
import List from "material-ui/List";
import React, { memo, useCallback, useState } from "react";
import { useSelector } from "react-redux";

import { Pagetype, RaamwerkTreeItem } from "../../business/models";
import icons from "../../components/pagetypes/icons";
import { flown } from "../../lodash";
import { RaamwerkLink } from "../ggvtyp/raamwerk";
import AddDialog from "./raamwerk/addDialog";
import HyperlinkDialog from "./raamwerk/hyperlinkDialog";
import {
  AddDialogState,
  HyperlinkDialogState,
  HyperlinkForm,
  RaamwerkFilterItem,
  RaamwerkTreeProps,
  RemarkAction,
  RemarkDialogState,
  RemarkForm,
  raamwerkActionsAllowAll,
  raamwerkActionsAllowLocal,
} from "./raamwerk/models";
import RemarkDialog from "./raamwerk/remarkDialog";
import renderTreeItems from "./raamwerk/renderMethods";
import {
  emptyOrganisationFacetId,
  emptyPracticalSituationFacetId,
} from "./raamwerkFilters";

const RaamwerkIcon = icons.raamwerk!;

const EmptyExternLink = { url: "", label: "" };
const closedDialogState = {
  show: false,
} as AddDialogState;
const closedHyperlinkDialogState: HyperlinkDialogState = {
  show: false,
};
const closedRemarkDialogState: RemarkDialogState = {
  show: false,
};

const statusKeys = ["todo", "draft", "ok"];
const filterItems = (
  items: RaamwerkTreeItem[],
  selected: Set<number>,
  leftItemIds: number[],
  allOrganisationItems: number[]
): RaamwerkTreeItem[] => {
  const noSelection = selected.size === 0;
  const noLeftItems = leftItemIds.length === 0;
  if (noSelection && noLeftItems) {
    return items;
  }

  return items.map((item: RaamwerkTreeItem): RaamwerkTreeItem => {
    const childItems = filterItems(
      item.items,
      selected,
      leftItemIds,
      allOrganisationItems
    );
    const links = item.links.filter(
      (link) =>
        (noSelection ||
          Boolean(link.externLink) ||
          selected.has(link.link?.itemId ?? 0) ||
          (selected.has(emptyOrganisationFacetId) &&
            !allOrganisationItems.includes(link.link?.itemId ?? 0))) &&
        (noLeftItems ||
          includes(link.leftItemId)(leftItemIds) ||
          (leftItemIds.includes(emptyPracticalSituationFacetId) &&
            !link.leftItemId))
    );
    const statusLinkCount: { [status: string]: number } = {};

    for (let i = 0; i < statusKeys.length; i++) {
      const status = statusKeys[i];
      const count =
        childItems
          .map((item) => item.statusLinkCount[status] ?? 0)
          .reduce((a, b) => a + b, 0) +
        links.filter((link) => link.status === status).length;
      if (count > 0) {
        statusLinkCount[status] = count;
      }
    }

    return {
      ...item,
      items: childItems,
      links,
      statusLinkCount,
    };
  });
};

const emptyFilters: RaamwerkFilterItem[] = [];
const RaamwerkTree = memo<RaamwerkTreeProps>(
  ({
    linkRaamwerk,
    hyperlinkAction,
    remarkAction,
    requireRaamwerkTargets,
    targets,
    targetsLoading,
    open,
    toggleOpen,
    numberAdded,
    organizations = emptyFilters,
    practicalSituations = emptyFilters,
    raamwerkLijst,
    ...props
  }) => {
    const filteredItems = useSelector(
      (store: { ui: { raamwerkFilter: number[] } }) => store.ui.raamwerkFilter
    );
    const [dialogState, updateDialogState] = useState(closedDialogState);
    const openDialog = useCallback(
      (selectionItemId: number): ((pagetype: Pagetype) => void) =>
        (pagetype: Pagetype): void => {
          requireRaamwerkTargets(pagetype.id, selectionItemId, filteredItems);
          updateDialogState({
            show: true,
            pagetype: pagetype,
            selectionItemId: selectionItemId,
          });
        },
      [filteredItems, requireRaamwerkTargets]
    );
    const closeDialog = useCallback(() => {
      updateDialogState(closedDialogState);
    }, []);

    const handleNvtChange = useCallback(
      (selectionId: number, disabled: boolean) => {
        props.pageActions.add("SelectionItem", { selectionId, disabled });
        props.pageActions.save(true);
      },
      [props.pageActions]
    );

    const [hyperlinkDialogState, updateHyperlinkDialogState] = useState(
      closedHyperlinkDialogState
    );
    const openHyperlinkDialog = useCallback(
      (selectionId: number): ((hyperlinkForm?: HyperlinkForm) => void) =>
        (hyperlinkForm?: HyperlinkForm): void => {
          updateHyperlinkDialogState({
            show: true,
            hyperlinkForm: {
              selectionId,
              externLink: EmptyExternLink,
              ...hyperlinkForm,
            },
          });
        },
      []
    );
    const closeHyperlinkDialog = useCallback(() => {
      updateHyperlinkDialogState(closedHyperlinkDialogState);
    }, []);

    const [remarkDialogState, updateRemarkDialogState] = useState(
      closedRemarkDialogState
    );
    const openRemarkDialog = useCallback((remarkForm?: RemarkForm): void => {
      updateRemarkDialogState({
        show: true,
        remarkForm,
      });
    }, []);
    const closeRemarkDialog = useCallback(() => {
      updateRemarkDialogState(closedRemarkDialogState);
    }, []);

    const saveRemarkDialog = useCallback(
      (form: RemarkForm) => {
        const action: RemarkAction = form.externLinkId
          ? "RaamwerkExternLinkRemark"
          : props.local || form.itemId
          ? "RaamwerkItemRemark"
          : "RaamwerkSelectionItemRemark";

        remarkAction(
          action,
          props.local ? { ...form, itemId: props.pageActions.pageId } : form,
          closeRemarkDialog
        );
      },
      [closeRemarkDialog, props.local, props.pageActions.pageId, remarkAction]
    );

    const allOrganisationItems = flown(
      organizations,
      map("itemIds"),
      flatten,
      uniq
    );
    const selected = flown(
      organizations,
      filter(({ filterItem }: RaamwerkFilterItem): boolean =>
        includes(filterItem.itemId)(filteredItems)
      ),
      map(({ itemIds }: RaamwerkFilterItem): number[] => itemIds),
      flatten,
      (ids: number[]): Set<number> => new Set<number>(ids)
    );

    const leftItemIds = flown(
      practicalSituations,
      filter(({ filterItem }: RaamwerkFilterItem): boolean =>
        includes(filterItem.itemId)(filteredItems)
      ),
      map(({ filterItem: { itemId } }: RaamwerkFilterItem): number => itemId)
    );

    if (filteredItems.includes(emptyOrganisationFacetId)) {
      selected.add(emptyOrganisationFacetId);
    } else {
      selected.delete(emptyOrganisationFacetId);
    }

    const leftItemIdsWithEmpty = filteredItems.includes(
      emptyPracticalSituationFacetId
    )
      ? [...leftItemIds, emptyPracticalSituationFacetId]
      : leftItemIds;

    const items = filterItems(
      props.items,
      selected,
      leftItemIdsWithEmpty,
      allOrganisationItems
    );
    const allowed = props.local
      ? raamwerkActionsAllowLocal
      : raamwerkActionsAllowAll;

    return raamwerkLijst === undefined ? null : (
      <Card style={{ marginBottom: "16px" }}>
        {props.local && (
          <CardTitle
            {...{ className: "card-title" }}
            title={
              <>
                {raamwerkLijst.value}{" "}
                <RaamwerkLink
                  fromItemId={props.pageActions.pageId}
                  fieldDefinition={{
                    selectionListId: raamwerkLijst.id,
                    name: (
                      <div
                        className="icon-button-inline"
                        title={raamwerkLijst.value}
                      >
                        <RaamwerkIcon />
                      </div>
                    ),
                  }}
                />
              </>
            }
          />
        )}
        <List style={{ paddingTop: 0, paddingBottom: 0 }}>
          {renderTreeItems({
            ...props,
            allowed,
            items,
            level: 0,
            open,
            toggleOpen,
            openDialog,
            openHyperlinkDialog,
            openRemarkDialog,
            onNvtChange: handleNvtChange,
            numberAdded,
            raamwerkAlias: raamwerkLijst.alias ?? raamwerkLijst.value,
            practicalSituations: props.local ? undefined : practicalSituations,
          })}
        </List>
        {dialogState.show && (
          <AddDialog
            selectionItemId={dialogState.selectionItemId as number}
            pagetype={dialogState.pagetype as Pagetype}
            statusItems={props.statusItems}
            targets={targets}
            targetsLoading={targetsLoading}
            closeDialog={closeDialog}
            linkRaamwerk={linkRaamwerk!}
          />
        )}
        {hyperlinkDialogState.show && hyperlinkDialogState.hyperlinkForm && (
          <HyperlinkDialog
            hyperlinkForm={hyperlinkDialogState.hyperlinkForm}
            statusItems={props.statusItems}
            closeDialog={closeHyperlinkDialog}
            hyperlinkAction={hyperlinkAction!}
          />
        )}
        {remarkDialogState.show && (
          <RemarkDialog
            onCancel={closeRemarkDialog}
            onDelete={saveRemarkDialog}
            onSave={saveRemarkDialog}
            remarkForm={remarkDialogState.remarkForm}
          />
        )}
      </Card>
    );
  }
);

export default RaamwerkTree;
