import empty from "empty";
import { Card } from "material-ui/Card";
import PropTypes from "prop-types";
import React from "react";
import {
  compose,
  onlyUpdateForKeys,
  setDisplayName,
  setPropTypes,
  withState,
} from "recompose";

import { deleteItemNoteAction, updateItemNoteAction } from "../../actions/data";
import { clearForm } from "../../actions/utils";
import connect from "../../containers/connect";
import ItemNote from "./itemNote";
import ItemNoteForm from "./itemNoteForm";

const ItemNotes = compose(
  setDisplayName("ItemNotes"),
  setPropTypes({
    id: PropTypes.number.isRequired,
    form: PropTypes.shape({ note: PropTypes.shape({}).isRequired }).isRequired,
    notes: PropTypes.arrayOf(PropTypes.object).isRequired,
    user: PropTypes.object.isRequired,
  }),
  onlyUpdateForKeys(["id", "form", "notes"]),
  withState("refresh", "toggle", false),
  connect(
    () => empty.object,
    (dispatch, { id }) => ({
      save: (text, noteId) =>
        dispatch(updateItemNoteAction(id, { id: noteId, text })),
      deleteNote: (noteId) => dispatch(deleteItemNoteAction(id, noteId)),
      reset: () => dispatch(clearForm("note")),
    })
  )
)(
  ({
    id,
    form: { note } = empty.object,
    notes = empty.array,
    save,
    deleteNote,
    reset,
    user,
  }) => (
    <Card className="notes">
      <ItemNoteForm
        id={id}
        form={note}
        notes={notes ? notes.length : 0}
        save={save}
        reset={reset}
      />
      {notes && (
        <div className="notes-list">
          {notes.map((n) => (
            <ItemNote
              key={n.id}
              note={n}
              userId={parseInt(user.id, 10)}
              save={save}
              deleteNote={deleteNote}
            />
          ))}
        </div>
      )}
    </Card>
  )
);

/* eslint-disable react/prop-types */
export const renderItemNotes = ({ id, form, notes, user }) => (
  <ItemNotes id={id} key="id" form={form} notes={notes} user={user} />
);

export default ItemNotes;
