import { ComponentType, useEffect, useState } from "react";

function Delay<TFallback, TProps extends TFallback>({
  delay,
  props,
  Fallback,
  Main,
}: {
  delay: number;
  props: TProps;
  Fallback: ComponentType<TFallback>;
  Main: ComponentType<TFallback>;
}) {
  const [showMain, setShowMain] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowMain(true);
    }, delay);
    return () => {
      clearTimeout(timeout);
    };
  }, [delay]);

  // @ts-ignore - intrinsic attributes are not relevant here
  return showMain ? <Main {...props} /> : <Fallback {...props} />;
}

export default Delay;
