import React from "react";

import ClusterItemLink from "./ClusterItemLink";

const defaultRenderRelationLinks = ({
  links,
  relation,
  pageActions,
  renderRelationLinkParentheses,
}) =>
  links.map(({ item, relation: original }) => (
    <ClusterItemLink
      key={`${item.itemId}_${item.pageClusterId}_${original.id}`}
      clusterFirst={relation.clusterFirst}
      mayDelete={relation.mayDelete}
      pageActions={pageActions}
      item={item}
      relation={original}
      renderRelationLinkParentheses={renderRelationLinkParentheses}
    />
  ));

export default defaultRenderRelationLinks;
