import { compose, setDisplayName } from "recompose";

import { closePreview } from "../../actions/bieb";
import { getPageAction, pageOmitAll } from "../../actions/data";
import BiebPreviewDialog from "../../components/bieb/biebPreviewDialog";
import { execOnChange } from "../../recompose.contrib";
import connect from "../connect";

const omitAllExceptPresentation = { ...pageOmitAll, omitPresentation: false };

export default compose(
  setDisplayName("BiebPreviewDialog"),
  connect(
    ({
      form: {
        biebPreviewDialog: { open = false, itemId },
      },
      data: { pages },
    }) => ({ open, itemId, id: itemId, page: pages[itemId] }),
    (dispatch) => ({
      close: () => dispatch(closePreview()),
      requirePage: ({ open, itemId }) => {
        if (open) {
          dispatch(getPageAction(itemId, omitAllExceptPresentation));
        }
      },
    })
  ),
  execOnChange("requirePage", "open", "itemId")
)(BiebPreviewDialog);
