import { composeAction } from "async-lifecycle";
import empty from "empty";

import { getRecentlyCreated } from "../api";

// eslint-disable-next-line import/prefer-default-export
export const recentlyCreatedAction = () =>
  composeAction({
    group: "RECENTLYCREATED_ITEMS",
    fire: getRecentlyCreated,
    cachekey: "recentlyCreated",
  });

export const invalidateRecentlyCreatedAction = empty.functionThatReturns(
  Object.freeze({
    type: "RECENTLYCREATED_ITEMS_INVALIDATE",
  })
);
