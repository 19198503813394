/* eslint  react/prop-types: off */
import classNames from "classnames";
import empty from "empty";
import { Card } from "material-ui/Card";
import React from "react";

import { ItemLinkList } from "./relations";

/**
 * RecentItems component implementation
 */
const RecentItems = ({
  always = false,
  recent = empty.array,
  legoblokStructures = empty.array,
  label = "Recent bezocht",
  maxLength = 11,
  miniSaar,
  alles,
}) => {
  const nonEmpty = always || recent.length > 0;
  return (
    <Card className={classNames("recent", { nonEmpty })}>
      {recent.length}
      {nonEmpty && (
        <ItemLinkList
          className="relations"
          label={label}
          list={recent}
          legoblokStructures={legoblokStructures}
          showHeader
          maxLength={maxLength}
          miniSaar={miniSaar}
          moreLink={alles}
        />
      )}
    </Card>
  );
};

export default RecentItems;
