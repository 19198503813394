import {
  drop,
  filter,
  first,
  flow,
  get,
  groupBy,
  head,
  map,
  sortBy,
  values,
} from "lodash/fp";
import React, { Fragment, memo } from "react";

import { entiteitSortKey } from "../../../../business/gegevens";
import { Relation, SiteLink } from "../../../../business/models";
import { flown } from "../../../../lodash";
import { LabelValue } from "../../../content/field";
import Heading from "../../../content/Heading";
import ClusterCard from "../../../material/clusterCard";
import { ItemLink } from "../../../relation.itemlink";

interface VerantwoordingGegevensProps {
  relations: Relation[];
}

const ApplicatieBlok = memo(({ appGegevens }: { appGegevens: Relation[] }) => (
  <div style={{ marginLeft: 16, marginBottom: 32 }}>
    <h3 style={{ fontWeight: 500 }}>Applicatie: {appGegevens[0].left.label}</h3>
    {map((appGegeven: Relation) => {
      const entiteit = appGegeven.using[0];
      const processes = flown(
        appGegeven,
        get("using"),
        drop(1),
        sortBy("label")
      );

      return (
        <ClusterCard
          titlePrefix="Applicatiegegeven: "
          title={appGegeven.left.pageClusterLabel}
          key={`${appGegevens[0].id}-${appGegeven.id}`}
          expandable
        >
          <LabelValue label="Naam">
            <ItemLink item={appGegeven.left}>
              {appGegeven.left.pageClusterLabel}
            </ItemLink>
          </LabelValue>
          {entiteit?.pageClusterId && (
            <LabelValue label="Gebaseerd op entiteitgegeven">
              <ItemLink item={entiteit}>
                {entiteit.pageClusterLabel} ({entiteit.label})
              </ItemLink>
            </LabelValue>
          )}
          {processes && processes.length > 0 && (
            <LabelValue label="Wordt verwerkt bij">
              {map((process: SiteLink) => (
                <Fragment
                  key={`${appGegevens[0].id}-${appGegeven.id}-${process.itemId}`}
                >
                  <ItemLink item={process}>{process.label}</ItemLink>
                  <br />
                </Fragment>
              ))(processes)}
            </LabelValue>
          )}
        </ClusterCard>
      );
    })(appGegevens)}
  </div>
));

const VerantwoordingGegevens = memo(
  ({ relations }: VerantwoordingGegevensProps) => {
    return (
      <div key="verantwoording-ggvs">
        {flown(
          relations,
          filter(
            (relation: Relation): boolean =>
              relation.right.type === "verantwoording" &&
              relation.using[0]?.type === "entiteit"
          ),
          groupBy(flow(get("using"), head, get("itemId"))),
          values,
          sortBy(flow(head, get("using"), head, entiteitSortKey)),
          values,
          map((relationsGroup: Relation[]): JSX.Element => {
            const entiteit: SiteLink = flown(
              relationsGroup,
              first,
              get("using"),
              first
            );

            return (
              <div key={entiteit.itemId}>
                <Heading>Entiteit: {entiteit.label}</Heading>

                {flown(
                  relationsGroup,
                  groupBy("left.itemId"),
                  values,
                  sortBy("[0].left.label"),
                  map((appGegevens: Relation[]) => (
                    <ApplicatieBlok
                      key={appGegevens[0].id}
                      appGegevens={appGegevens}
                    />
                  ))
                )}
              </div>
            );
          })
        )}
      </div>
    );
  }
);

const renderVerantwoordingGegevens = (
  props: VerantwoordingGegevensProps
): JSX.Element => {
  return <VerantwoordingGegevens {...props} key="verantwoord-ggvs" />;
};

export default renderVerantwoordingGegevens;
