import empty from "empty";
import PropTypes from "prop-types";
import React, { useMemo } from "react";

import { entiteitShape, linkIdShape } from "../../../business/prop-types";
import { Label } from "../../content/field";
import ClusterPicker from "../../pickers/ClusterPicker";

const veldItem =
  (itemId) =>
  ({ id: pageClusterId, naam: label }) => ({
    itemId,
    pageClusterId,
    label,
    type: "kerngegeven_applicatieveld",
    items: [],
  });

const gegevenItem =
  (itemId) =>
  ({ id: pageClusterId, naam: label, velden }) => ({
    itemId: pageClusterId,
    pageClusterId,
    label,
    items: velden.map(veldItem(itemId)),
  });

const entiteitItem = ({ id: itemId, naam: label, gegevens }) => ({
  itemId,
  label,
  type: "entiteit_applicatieveld",
  items: gegevens.map(gegevenItem(itemId)),
});

const KernVelden = ({
  label,
  entiteiten = empty.array,
  selected,
  onChange,
}) => {
  const items = useMemo(() => entiteiten.map(entiteitItem), [entiteiten]);
  if (!label) {
    return null;
  }

  return (
    <div className="field field-edit cluster-relation">
      <Label>{label}</Label>
      <div className="value">
        <ClusterPicker
          items={items}
          selected={selected}
          onChange={onChange}
          clusterRequired
          multiple
          labelText="Gegeven"
        />
      </div>
    </div>
  );
};

KernVelden.propTypes = {
  label: PropTypes.string.isRequired,
  entiteiten: PropTypes.arrayOf(entiteitShape.isRequired).isRequired,
  selected: PropTypes.arrayOf(linkIdShape.isRequired).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default KernVelden;
