import empty from "empty";
import { entries, get, map, some } from "lodash/fp";
import Checkbox from "material-ui/Checkbox";
import { List } from "material-ui/List";
import MenuItem from "material-ui/MenuItem";
import SelectField from "material-ui/SelectField";
import Subheader from "material-ui/Subheader";
import TextField from "material-ui/TextField";
import PropTypes from "prop-types";
import React from "react";
import SafeInnerHtml from "react-safeinnerhtml";

import { fieldPageActions } from "../../business/models";
import Field from "../../components/content/field";
import { Relation } from "../../components/relation";
import { flown } from "../../lodash";
import { makePagetypes } from "../../pagetypes";
import { highlight } from "../../utils";

const ItemDialogFirstStep = ({
  foundItems,
  legoblokStructures,
  newItem,
  pagetypeDisabled,
  pagetypes,
  structures,
  updateLabel,
  updatePagetype,
  updateStructureId,
  closeDialog,
  updateExternCheckbox,
  hasFields = false,
  fields,
  loadingFields = false,
  fieldAdd,
  fieldRevert,
}) => {
  const myFieldPageActions = fieldPageActions(fieldAdd, fieldRevert);

  return (
    <>
      <div style={{ display: "flex" }}>
        <div>
          {structures.length > 1 && (
            <SelectField
              floatingLabelText="Kies een locatie"
              onChange={updateStructureId}
              value={newItem.structureId || structures[0].id}
            >
              {structures.map(({ id, name }) => (
                <MenuItem key={id} value={id} primaryText={name} />
              ))}
            </SelectField>
          )}
          <br />
          {!pagetypeDisabled && pagetypes.length > 0 && (
            <SelectField
              floatingLabelText="Kies een paginatype"
              onChange={updatePagetype}
              value={newItem.pagetype}
            >
              {pagetypes.map(({ id, name, alias } = empty.object) => (
                <MenuItem key={id} value={alias} primaryText={name} />
              ))}
            </SelectField>
          )}
          <br />
          <TextField
            autoFocus
            hintText="Naam"
            floatingLabelText="Voer een naam in"
            onChange={updateLabel}
            value={newItem.label}
          />
          {newItem.pagetype === "applicatie" && (
            <div style={{ marginTop: 8 }}>
              <Checkbox
                checked={get("extraAction.externalApplication")(newItem)}
                onCheck={updateExternCheckbox}
                label="Externe applicatie"
              />
            </div>
          )}
        </div>
        {!pagetypeDisabled && (
          <div style={{ height: 250, overflowY: "auto", width: "100%" }}>
            {foundItems && foundItems.length > 0 && (
              <List>
                <Subheader>Gevonden {foundItems[0].pagetype}:</Subheader>
                {flown(
                  foundItems,
                  map(({ itemId, siteId, label, pagetype: type }) => (
                    <Relation
                      key={itemId}
                      labelRender={() => (
                        <SafeInnerHtml>
                          {highlight(label, newItem.label)}
                        </SafeInnerHtml>
                      )}
                      item={{ itemId, siteId, label, type }}
                      insideBieb={some({ id: siteId })(legoblokStructures)}
                      onClick={closeDialog}
                    />
                  ))
                )}
              </List>
            )}
          </div>
        )}
      </div>
      {hasFields && !loadingFields && (
        <div>
          {flown(
            fields,
            entries,
            map(([key, field]) => (
              <Field
                key={key}
                alias={key}
                field={field}
                clusterId={0}
                pageActions={myFieldPageActions}
                pagetypes={makePagetypes(newItem.pagetype)}
              />
            ))
          )}
        </div>
      )}
    </>
  );
};

ItemDialogFirstStep.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  foundItems: PropTypes.array.isRequired,
  legoblokStructures: PropTypes.arrayOf(
    PropTypes.shape(
      Object.freeze({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      })
    )
  ).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  newItem: PropTypes.shape(
    Object.freeze({
      extraAction: PropTypes.object,
      label: PropTypes.string,
      pagetype: PropTypes.string,
      side: PropTypes.number,
      structureId: PropTypes.number,
    })
  ).isRequired,
  pagetypeDisabled: PropTypes.bool.isRequired,
  pagetypes: PropTypes.arrayOf(
    PropTypes.shape(
      Object.freeze({
        id: PropTypes.number.isRequired,
        alias: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      })
    )
  ).isRequired,
  structures: PropTypes.arrayOf(
    PropTypes.shape(
      Object.freeze({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      })
    )
  ).isRequired,
  updateLabel: PropTypes.func.isRequired,
  updatePagetype: PropTypes.func.isRequired,
  updateStructureId: PropTypes.func.isRequired,
  updateExternCheckbox: PropTypes.func.isRequired,
  closeDialog: PropTypes.func.isRequired,
  hasFields: PropTypes.bool,
  fields: PropTypes.shape({}),
  loadingFields: PropTypes.bool,
  fieldAdd: PropTypes.func,
  fieldRevert: PropTypes.func,
};

export default ItemDialogFirstStep;
