import empty from "empty";
import {
  entries,
  every,
  filter,
  get,
  map,
  pick,
  reject,
  some,
  values,
} from "lodash/fp";
import FlatButton from "material-ui/FlatButton";
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { compose, flattenProp, withHandlers, withState } from "recompose";

import { fieldPageActions, titlePageActions } from "../../business/models";
import { fieldShape } from "../../business/prop-types";
import Dialog from "../../containers/dialog";
import { flown } from "../../lodash";
import { makePagetypes } from "../../pagetypes";
import Field from "../content/field";
import { EntiteitenLoadingIndicator } from "../pagetypes/application/gegevenDialog";

const titleField = {
  definition: {
    dataTypeCode: 2,
    id: -1,
    name: "Doeleinde",
    required: true,
  },
  iproxType: "field",
  isEmpty: true,
  names: ["Doeleinde"],
  value: "",
};

const VerantwoordingContent = ({
  fields,
  fieldAdd,
  fieldRevert,
  titleUpdate,
}) => {
  const myTitlePageActions = titlePageActions(titleUpdate);
  const myFieldPageActions = fieldPageActions(fieldAdd, fieldRevert);

  return (
    <Fragment>
      <Field
        alias="lbl"
        field={titleField}
        clusterId={0}
        pageActions={myTitlePageActions}
        pagetypes={makePagetypes("verantwoording")}
      />

      {flown(
        fields,
        entries,
        map(([key, field]) => (
          <Field
            key={key}
            alias={key}
            field={field}
            clusterId={0}
            pageActions={myFieldPageActions}
            pagetypes={makePagetypes("verantwoording")}
          />
        ))
      )}
    </Fragment>
  );
};
VerantwoordingContent.propTypes = {
  fields: PropTypes.objectOf(fieldShape.isRequired).isRequired,
  fieldAdd: PropTypes.func.isRequired,
  fieldRevert: PropTypes.func.isRequired,
  titleUpdate: PropTypes.func.isRequired,
};

const AddVerantwoording = ({
  busy,
  loading,
  valid,
  onCancel,
  onSave,
  fields,
  fieldAdd,
  fieldRevert,
  titleUpdate,
}) => (
  <Dialog
    title="Nieuwe verantwoording toevoegen"
    open
    autoScrollBodyContent
    actions={
      <Fragment>
        <FlatButton secondary label="Annuleren" onClick={onCancel} />
        <FlatButton
          primary
          disabled={busy || loading || !valid}
          label="Opslaan"
          onClick={onSave}
        />
      </Fragment>
    }
  >
    {busy || loading ? (
      <EntiteitenLoadingIndicator />
    ) : (
      <VerantwoordingContent
        fields={fields}
        fieldAdd={fieldAdd}
        fieldRevert={fieldRevert}
        titleUpdate={titleUpdate}
      />
    )}
  </Dialog>
);
AddVerantwoording.propTypes = {
  busy: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  fields: PropTypes.objectOf(fieldShape.isRequired).isRequired,
  fieldAdd: PropTypes.func.isRequired,
  fieldRevert: PropTypes.func.isRequired,
  titleUpdate: PropTypes.func.isRequired,
};

const defaultState = Object.freeze({
  busy: false,
  valid: false,
  title: "",
  rootFields: empty.array,
});
const validate = (definition, title, fields) =>
  title !== "" &&
  flown(
    definition,
    values,
    filter(get("definition.required")),
    every(({ definition: { name: key } }) => flown(fields, some({ name: key })))
  );

export default compose(
  withState("state", "setState", defaultState),
  withHandlers({
    fieldAdd:
      ({ fields: definition, state, setState }) =>
      ({ name, value }) => {
        const rootFields = flown(state.rootFields, reject({ name }));
        if (value && value !== "<div></div>") {
          rootFields.push({
            name,
            value,
          });
        }

        setState({
          ...state,
          rootFields,
          valid: validate(definition, state.title, rootFields),
        });
      },
    fieldRevert:
      ({ fields: definition, state, setState }) =>
      ({ name }) => {
        const rootFields = flown(state.rootFields, reject({ name }));
        setState({
          ...state,
          rootFields,
          valid: validate(definition, state.title, rootFields),
        });
      },
    titleUpdate:
      ({ fields: definition, state, setState }) =>
      (title = "") =>
        setState({
          ...state,
          title,
          valid: validate(definition, title, state.rootFields),
        }),
    onSave:
      ({ onSave, setState, state }) =>
      () => {
        setState({ ...state, busy: true });
        onSave(pick(["title", "rootFields"])(state));
      },
  }),
  flattenProp("state")
)(AddVerantwoording);
