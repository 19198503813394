import React, { memo, useEffect } from "react";
import { hashHistory } from "react-router";

import { MiniSaar, StoreRoot } from "../business/models";
import connect from "./connect";

interface HomeProps {
  miniSaar?: MiniSaar;
}

const mapState = ({ session: { miniSaar } }: StoreRoot): HomeProps => ({
  miniSaar,
});

const Home = memo<HomeProps>(({ miniSaar }: HomeProps): JSX.Element => {
  useEffect((): void => {
    if (miniSaar && miniSaar.home) {
      hashHistory.push(miniSaar.home);
    }
  }, [miniSaar]);

  return <main className="home" />;
});

export default connect(mapState)(Home);
