import empty from "empty";
import { flatten, flow, map } from "lodash/fp";
import { createSelector } from "reselect";

const getRoot = ({ item = empty.object }) => item;
const getCollapsedState = ({ showAll = false }) => showAll;
const getCurrentSteps = ({ currentSteps = empty.array }) => currentSteps;

const flattenChildren = (item) => {
  const { children, ...rest } = item;

  const newItem = {
    ...rest,
    children: empty.array,
  };

  const childItems =
    children && children.length > 0
      ? flow(map(flattenChildren), flatten)(children)
      : empty.array;
  return [newItem, ...childItems];
};

// eslint-disable-next-line import/prefer-default-export
export const getTreeSelector = createSelector(
  [getRoot, getCollapsedState, getCurrentSteps],
  (root, showAll, currentSteps) => {
    const { children, ...rest } = root;
    return {
      ...rest,
      children: showAll
        ? children
        : flattenChildren(root).filter(
            (i) =>
              i.itemId !== root.itemId && currentSteps.indexOf(i.itemId) > -1
          ),
    };
  }
);
