import classNames from "classnames";
import { DialogProps } from "material-ui";
import Dialog from "material-ui/Dialog";
import React, { ReactElement, memo } from "react";
import { Provider, ReactReduxContext } from "react-redux";

export interface DialogWithStoreContextProps
  extends Omit<DialogProps, "actions"> {
  actions?: Array<ReactElement | null | boolean>;
}

const DialogWithStoreContext = ({
  actions,
  children,
  className,
  ...props
}: DialogWithStoreContextProps) => (
  <ReactReduxContext.Consumer>
    {(ctx) => (
      <Dialog
        actions={actions as Array<ReactElement> | undefined}
        {...props}
        className={classNames(className, "dialog-container")}
      >
        <Provider store={ctx.store}>{children}</Provider>
      </Dialog>
    )}
  </ReactReduxContext.Consumer>
);

export default memo(DialogWithStoreContext);
