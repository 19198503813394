import { map } from "lodash/fp";
import { RadioButton, RadioButtonGroup } from "material-ui/RadioButton";
import { grey600 } from "material-ui/styles/colors";
import Subheader from "material-ui/Subheader";
import PageView from "material-ui/svg-icons/action/pageview";
import PropTypes from "prop-types";
import React, { Fragment } from "react";

import { globalItemShape } from "../../business/prop-types";
import { flown } from "../../lodash";

const previewStyle = Object.freeze({ color: grey600 });

const SelectVerantwoording = ({
  onChange,
  verantwoordingen,
  verantwoordingId,
  setPreview,
}) => (
  <Fragment>
    <Subheader style={{ paddingLeft: 0 }}>Kies een verantwoording</Subheader>
    <RadioButtonGroup
      name="verantwoording"
      onChange={(_, value) => onChange(value)}
      valueSelected={verantwoordingId}
    >
      {flown(
        verantwoordingen,
        map(({ id, naam }) => (
          <RadioButton
            key={id}
            value={id}
            label={
              <Fragment>
                {naam}
                <span
                  className="step-button-preview"
                  style={{ zIndex: 3, position: "absolute" }}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setPreview(id);
                  }}
                >
                  <PageView style={previewStyle} />
                </span>
              </Fragment>
            }
          />
        ))
      )}
    </RadioButtonGroup>
  </Fragment>
);
SelectVerantwoording.propTypes = {
  onChange: PropTypes.func.isRequired,
  verantwoordingen: PropTypes.arrayOf(globalItemShape.isRequired).isRequired,
  verantwoordingId: PropTypes.number,
  setPreview: PropTypes.func.isRequired,
};

export default SelectVerantwoording;
