import { compose, setDisplayName } from "recompose";

import Selectie from "../../components/ggvtyp/selectie";
import { selectionList } from "../../selectors/definition";
import connect from "../connect";

export default compose(
  setDisplayName("Selectie"),
  connect(selectionList)
)(Selectie);
