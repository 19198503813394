import { compact, entries, flatten, flow, map } from "lodash/fp";
import moment from "moment";

import { getContentName } from "../../business/contentPlug";
import {
  AddressValue,
  ClusterData,
  ClustersData,
  EmptyFieldData,
  FieldData,
  IproxContent,
  KoppelingClusterMapping,
  LinkValue,
  NonEmptyFieldData,
  PageData,
  RaamwerkValue,
  Relation,
  SelectionValue,
  WithClusterId,
  emptyArray,
} from "../../business/models";
import { convertRaamwerkValue } from "../ggvtyp/raamwerk";

export interface LocatedFieldData {
  path: string[];
  content: FieldData;
  clusterId?: number;
}

export const isNonEmpty = (
  fieldData: FieldData
): fieldData is NonEmptyFieldData => {
  return !(fieldData as EmptyFieldData).isEmpty;
};

export const staticFields = (
  content: IproxContent,
  path: string[],
  withEmptyFields: boolean = false,
  clusterId: number | undefined = undefined
): LocatedFieldData[] => {
  switch (content.iproxType) {
    case "cluster":
      return flow(
        entries,
        map(([key, c]) =>
          staticFields(c, [...path, key], withEmptyFields, content.clusterId)
        ),
        flatten
      )(content);
    case "field":
      return ((!withEmptyFields && isNonEmpty(content)) || withEmptyFields) &&
        content.definition.name !== "Status"
        ? [{ path, content, clusterId }]
        : [];
    case "clusters":
    case "fields":
      return [];
    default:
      return [];
  }
};

export const pageFields = (
  { page }: PageData,
  withEmptyFields: boolean = false
): LocatedFieldData[] =>
  flow(
    entries,
    map(([key, content]) =>
      staticFields(content, [key], withEmptyFields, content["clusterId"])
    ),
    flatten
  )(page);

export type EnrichedIproxContent = IproxContent & { containerNames?: string[] };

const fieldIds = (
  content: EnrichedIproxContent,
  isKoppeling: boolean,
  koppelingClusterMapping: KoppelingClusterMapping
): number[] => {
  switch (content.iproxType) {
    case "field":
      return isNonEmpty(content) ? [content.id] : [];
    case "cluster":
      const isGegeven = content.containerNames?.[0] === "Gegeven";
      const canCheck =
        isGegeven &&
        koppelingClusterMapping?.[content.clusterId!] &&
        compact(koppelingClusterMapping[content.clusterId!] ?? emptyArray())
          .length === 2;

      if (isKoppeling && isGegeven && !canCheck) {
        // "Gegeven" in koppeling is niet over te nemen
        return [];
      }

      return flow(
        entries,
        map(([_, content]) =>
          fieldIds(content, isKoppeling, koppelingClusterMapping)
        ),
        flatten
      )(content);
    case "clusters":
      return flow(
        map((c: ClusterData) => {
          return fieldIds(
            {
              ...(c as {}),
              iproxType: "cluster",
              containerNames: content.names,
            } as EnrichedIproxContent,
            isKoppeling,
            koppelingClusterMapping
          );
        }),
        flatten
      )(content.clusters);
    case "fields":
    default:
      return [];
  }
};

export const getAllFieldIds = (
  { page }: PageData,
  koppelingClusterMapping: KoppelingClusterMapping
): number[] => {
  const isKoppeling = page.pagetype === "koppeling";
  return flow(
    entries,
    map(([_, content]) =>
      fieldIds(content, isKoppeling, koppelingClusterMapping)
    ),
    flatten,
    compact
  )(page);
};

const relationIds = (
  content: IproxContent,
  relations: Relation[]
): number[] => {
  switch (content.iproxType) {
    case "cluster":
      const relIds = relations
        .filter((r) => {
          const siteLink =
            r.side === 3 ? r.using[0] : r.side === 1 ? r.left : r.right;
          return (
            siteLink.pageClusterId !== undefined &&
            siteLink.pageClusterId === Number(content["clusterId"])
          );
        })
        .map((r) => r.id);

      return [
        ...relIds,
        ...flow(
          entries,
          map(([_, content]) => relationIds(content, relations)),
          flatten
        )(content),
      ];
    case "clusters":
      return flow(
        map((content) =>
          relationIds(
            { ...(content as {}), iproxType: "cluster" } as IproxContent,
            relations
          )
        ),
        flatten
      )(content.clusters);
    case "field":
    case "fields":
    default:
      return [];
  }
};

export const getAllRelationIds = (
  { page }: PageData,
  relations: Relation[]
): number[] => {
  return flow(
    entries,
    map(([_, content]) => relationIds(content, relations)),
    flatten
  )(page);
};

export type CollectedCluster = WithClusterId & {
  label: string;
  content: IproxContent;
  relations?: Relation[];
  children: CollectedCluster[];
};

export const getClusterName = (
  content: ClusterData | ClustersData,
  pagetype: string
) => {
  let name = getContentName(content);
  switch (pagetype) {
    case "applicatie":
      if (name === "Gegeven") {
        name = "Applicatiegegeven";
      } else if (name === "Veld") {
        name = "Applicatieveld";
      }

      break;
    case "entiteit":
      if (name === "Gegeven") {
        name = "Entiteitgegeven";
      } else if (name === "Veld") {
        name = "Entiteitveld";
      }

      break;
  }
  return name;
};

export const collect = (
  content: IproxContent,
  pagetype: string,
  inside: boolean,
  relations: Relation[],
  clusterName: string,
  parentClusterId: number | undefined
): CollectedCluster[] => {
  switch (content.iproxType) {
    case "clusters": {
      const clusterContent = flow(
        map((cluster: ClusterData) =>
          collect(
            { ...(cluster as {}), iproxType: "cluster" } as ClusterData,
            pagetype,
            true,
            relations,
            getClusterName(content, pagetype),
            parentClusterId
          )
        ),
        flatten
      )(content.clusters);

      if (clusterContent.length === 0) {
        return [];
      }

      return clusterContent;
    }
    case "cluster": {
      const clusterContent = flow(
        entries,
        map(([_, cluster]) =>
          collect(
            cluster,
            pagetype,
            false,
            relations,
            getClusterName(cluster, pagetype),
            content.clusterId
          )
        ),
        flatten
      )(content);

      return inside
        ? [
            {
              label: `${clusterName}`,
              clusterId: content.clusterId,
              parentClusterId,
              content,
              relations: relations.filter((r) => {
                const siteLink =
                  r.side === 3 ? r.using[0] : r.side === 1 ? r.left : r.right;
                return (
                  siteLink.pageClusterId !== undefined &&
                  siteLink.pageClusterId === Number(content["clusterId"])
                );
              }),
              children: clusterContent,
            },
          ]
        : clusterContent;
    }
    default:
      return [];
  }
};

export const flattenClusterTree = (
  tree: CollectedCluster[]
): CollectedCluster[] =>
  tree.flatMap((c) => [c, ...flattenClusterTree(c.children)]);

export type RelatedCollectedCluster = [
  CollectedCluster,
  CollectedCluster | undefined
];

export const relate = (
  biebCluster: CollectedCluster,
  localClusters: CollectedCluster[],
  relations: Relation[]
): RelatedCollectedCluster[] => {
  const relation = relations.find(
    (r) =>
      biebCluster.clusterId && r.left.pageClusterId === biebCluster.clusterId
  );

  let result: RelatedCollectedCluster[] = [];
  if (relation) {
    const localCluster = localClusters.find(
      (l) => l.clusterId === relation.right.pageClusterId
    );

    result = [...result, [biebCluster, localCluster]];
  } else {
    result = [...result, [biebCluster, undefined]];
  }

  if (biebCluster.children.length > 0) {
    result = [
      ...result,
      ...biebCluster.children
        .map((c) => relate(c, localClusters, relations))
        .flat(),
    ];
  }

  return result;
};

export const getFieldValue = (
  biebField: FieldData | undefined
): string | null => {
  if (biebField === undefined) {
    return null;
  }

  switch (biebField.definition.dataTypeCode) {
    case 1: // Boolean
      return Boolean(biebField.value) ? "1" : "0";
    case 3: // Selectie
      return (biebField.value as SelectionValue | null)?.value ?? null;
    case 4: // Picklist
      return ((biebField.value as SelectionValue[] | null) ?? [])
        .map((v) => v.value)
        .join(";");
    case 6: // Internet
    case 16: // Email
      const addressValue = biebField.value as AddressValue | undefined;
      if (!addressValue) {
        return null;
      }

      return addressValue.label
        ? `[${addressValue.label}]: ${addressValue.address}`
        : addressValue.address;
    case 7: // Datum
      return biebField.value
        ? moment(biebField.value as string)
            .startOf("day")
            .format("YYYYMMDD")
        : null;
    case 10: // Afbeelding - TODO: Task 3855: Bestand-velden ondersteunen
    case 11: // Bestand - TODO: Task 3855: Bestand-velden ondersteunen
      return null;
    case 13: // Verwijzing
    case 18: // Koppeling
      const linkValue = biebField.value as LinkValue | undefined;
      if (!linkValue) {
        return null;
      }

      return linkValue.clusterId
        ? `${linkValue.linkId}#PagCls_${linkValue.clusterId}`
        : `${linkValue.linkId}`;
    case 17: // Trefwoorden
    case 97: // IPROX gebruiker
      throw new Error(
        `${biebField.definition.name} not supported, readonly field`
      );
    case 272: // MeervoudigBestand - TODO: Task 3855: Bestand-velden ondersteunen
    case 273: // OneDrive - TODO: Task 3855: Bestand-velden ondersteunen
      return null;
    case 274: // Raamwerk
      return convertRaamwerkValue(biebField.value as RaamwerkValue) ?? null;
    case 2: // Plat gegeven
    case 20: // Wachtwoord
    case 21: // Tekstvlak
    default:
      return biebField.value as string;
  }
};
