import empty from "empty";
import { filter } from "lodash/fp";
import {
  branch,
  compose,
  renderNothing,
  setDisplayName,
  withHandlers,
} from "recompose";

import { redirectLocalAction } from "../actions/common";
import { requireOverzichtAction } from "../actions/data";
import { setOverzichtAction } from "../actions/utils";
import RecentOverzicht from "../components/overzicht/recent";
import { flown } from "../lodash";
import { execOnChange } from "../recompose.contrib";
import { dispatchWrap } from "../utils";
import connect from "./connect";

const gotoOverzicht = empty.functionThatReturns(
  redirectLocalAction("overzicht")
);

const setOverzicht = ({ columns, pagetype, title }) =>
  setOverzichtAction({
    title,
    pagetype,
    fields: flown(columns, filter("field")),
    relations: flown(columns, filter("relation")),
    currentStep: 3,
  });

export default compose(
  setDisplayName("RecentOverzicht"),
  connect(
    ({
      data: {
        overzicht: { overzichten = empty.array },
      },
      session: {
        miniSaar: {
          properties: { "recente overzichten anoniem": anoniem } = empty.object,
        },
      },
    }) => ({
      overzichten,
      anoniem,
    }),
    (dispatch) => ({
      requireOverzicht: dispatchWrap(requireOverzichtAction, dispatch),
      gotoOverzicht: dispatchWrap(gotoOverzicht, dispatch),
      setOverzicht: dispatchWrap(setOverzicht, dispatch),
    })
  ),
  withHandlers({
    selectOverzicht:
      ({ gotoOverzicht, setOverzicht }) =>
      (overzicht) => {
        setOverzicht(overzicht);
        gotoOverzicht();
      },
  }),
  execOnChange("requireOverzicht"),
  branch(({ overzichten }) => overzichten.length === 0, renderNothing)
)(RecentOverzicht);
