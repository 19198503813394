import empty from "empty";
import { List, ListItem } from "material-ui/List";
import RaisedButton from "material-ui/RaisedButton";
import PropTypes from "prop-types";
import React from "react";
import { compose, setDisplayName, setPropTypes, withHandlers } from "recompose";

import { buttonStyle, listItemStyle } from "../../material/addButton";

const AddGegeven = ({ onClick, enabled, label, children }) => (
  <List style={{ paddingTop: 0 }}>
    <ListItem disabled style={listItemStyle}>
      <RaisedButton
        label={label}
        onClick={onClick}
        disabled={!enabled}
        style={buttonStyle}
        secondary
      />
      {children}
    </ListItem>
  </List>
);

AddGegeven.propTypes = Object.freeze({
  onClick: PropTypes.func.isRequired,
  enabled: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  children: PropTypes.node,
});

AddGegeven.defaultProps = {
  enabled: true,
  label: "Gegeven toevoegen",
};

export default compose(
  setDisplayName("AddGegeven"),
  setPropTypes(
    Object.freeze({
      openDialog: PropTypes.func.isRequired,
    })
  ),
  withHandlers({
    onClick:
      ({ openDialog = empty.func }) =>
      (event) => {
        event.preventDefault();
        openDialog(true);
      },
  })
)(AddGegeven);
