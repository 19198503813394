import FloatingActionButton from "material-ui/FloatingActionButton";
import ContentAdd from "material-ui/svg-icons/content/add";
import TextField from "material-ui/TextField";
import PropTypes from "prop-types";
import React from "react";
import {
  compose,
  mapProps,
  onlyUpdateForKeys,
  setDisplayName,
  setPropTypes,
  withState,
} from "recompose";

import { execOnChange } from "../../recompose.contrib";

const ItemNoteForm = compose(
  setDisplayName("ItemNoteForm"),
  setPropTypes({
    form: PropTypes.object.isRequired,
    id: PropTypes.number.isRequired,
    notes: PropTypes.number.isRequired,
    reset: PropTypes.func.isRequired,
    save: PropTypes.func.isRequired,
  }),
  withState("note", "update", { text: "" }),
  mapProps(({ save, update, note, ...rest }) => ({
    updateNote: (event) => {
      event.preventDefault();
      if (event.target) {
        update({ text: event.target.value });
      }
    },
    resetNote: () => {
      update({ text: "" });
    },
    saveNote: (event) => {
      event.preventDefault();
      if (note.text !== "") {
        save(note.text);
      }
    },
    note,
    ...rest,
  })),
  onlyUpdateForKeys(["id", "form", "note", "notes"]),
  execOnChange(({ reset, resetNote }) => {
    reset();
    resetNote();
  }, "notes")
)(({ form, saveNote, note: { text = "" }, updateNote }) => {
  const disableTextField = (form && form.loading) === true;
  const disableButton = text === "" || disableTextField;

  return (
    <div className="notes-add">
      <div className="textarea">
        <TextField
          hintText="Typ hier jouw bericht..."
          floatingLabelText="Jouw bericht"
          multiLine
          fullWidth
          disabled={disableTextField}
          onChange={updateNote}
          value={text}
          rows={4}
        />
      </div>
      <FloatingActionButton
        mini
        className="submit-button"
        disabled={disableButton}
        onClick={saveNote}
        title="Sla opmerking op"
      >
        <ContentAdd />
      </FloatingActionButton>
    </div>
  );
});

export default ItemNoteForm;
