import empty from "empty";
import { filter, get, map } from "lodash/fp";
import Menu from "material-ui/Menu";
import MenuItem from "material-ui/MenuItem";
import Popover from "material-ui/Popover";
import RaisedButton from "material-ui/RaisedButton";
import Subheader from "material-ui/Subheader";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { compose, withStateHandlers } from "recompose";

import { init } from "../../../actions/relation";
import { mayAddRelationType } from "../../../business/authorization";
import { relationTypeWithSideShape } from "../../../business/prop-types";
import { Side } from "../../../business/relations";
import connect from "../../../containers/connect";
import { flown } from "../../../lodash";
import { userSelector } from "../../../selectors";
import { isNonEmptyArray } from "../../../utils";

const RelatieAddMenu = ({
  onAdd,
  onClose,
  onOpen,
  open,
  setAnchor,
  anchor,
  relatieToevoegen,
}) => {
  const user = useSelector(userSelector);
  return (
    <div className="relatie-add-row">
      <div className="relatie-add-box" ref={setAnchor}>
        <RaisedButton label="Relatie toevoegen" onClick={onOpen} secondary />
      </div>
      <Popover
        className="relatie-add-menu"
        open={open}
        anchorEl={anchor}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        targetOrigin={{ horizontal: "left", vertical: "top" }}
        onRequestClose={onClose}
      >
        <Menu onChange={onAdd}>
          <Subheader>Relatie toevoegen</Subheader>
          {flown(
            relatieToevoegen,
            filter((r) => mayAddRelationType(user, r)),
            map((r) => (
              <MenuItem
                key={`${r.id}-${r.side}`}
                value={r}
                primaryText={r[Side[r.side]].label}
              />
            ))
          )}
        </Menu>
      </Popover>
    </div>
  );
};

const AnchorType = PropTypes.any;
RelatieAddMenu.propTypes = {
  onAdd: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onOpen: PropTypes.func.isRequired,
  setAnchor: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  anchor: AnchorType,
  relatieToevoegen: PropTypes.arrayOf(relationTypeWithSideShape.isRequired)
    .isRequired,
};

const RelatieMenu = compose(
  connect(
    () => empty.object,
    (dispatch, { itemId }) => ({
      onAdd: (e, { id, side }) => dispatch(init({ id }, side, itemId)),
    })
  ),
  withStateHandlers(
    { open: false, anchor: undefined },
    {
      onClose:
        ({ anchor }) =>
        () => ({ anchor, open: false }),
      onOpen:
        ({ anchor }) =>
        () => ({ anchor, open: true }),
      onAdd:
        ({ anchor }, { onAdd }) =>
        (...args) => {
          onAdd(...args);
          return { anchor, open: false };
        },
      setAnchor:
        ({ open }) =>
        (anchor) => ({ open, anchor }),
    }
  )
)(RelatieAddMenu);

// No prop types: this is not a component
// eslint-disable-next-line react/prop-types
const renderRelatieToevoegen = ({ pageActions, relatieToevoegen }) => {
  if (!isNonEmptyArray(relatieToevoegen) || !get("edit")(pageActions)) {
    return null;
  }

  return (
    <RelatieMenu
      key="RelatieMenu"
      relatieToevoegen={relatieToevoegen}
      itemId={pageActions.pageId}
    />
  );
};

export default renderRelatieToevoegen;
