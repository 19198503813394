/* eslint  react/prop-types: off */
import empty from "empty";
import FlatButton from "material-ui/FlatButton";
import MenuItem from "material-ui/MenuItem";
import { RadioButton, RadioButtonGroup } from "material-ui/RadioButton";
import SelectField from "material-ui/SelectField";
import TextField from "material-ui/TextField";
import React, { Fragment } from "react";

import Dialog from "../../containers/dialog";
import FloatingLabelText from "../material/FloatingLabelText";

const BiebAddDialog = ({
  close = empty.func,
  structures = empty.array,
  updateLabel = empty.func,
  updateSite = empty.func,
  updateMoveItem = empty.func,
  newBiebItem: { siteId, label, moveItem } = empty.object,
  valid = false,
  createBiebItem = empty.func,
}) => (
  <Dialog
    title="Toevoegen aan Saar Bieb"
    open
    autoScrollBodyContent
    actions={[
      <FlatButton key="close" secondary label="Annuleren" onClick={close} />,
      <FlatButton
        primary
        key="save"
        disabled={!valid}
        label="Opslaan"
        onClick={createBiebItem}
      />,
    ]}
  >
    <div>
      <TextField
        floatingLabelText="Naam item"
        value={label}
        onChange={updateLabel}
      />
      {structures && structures.length > 1 && (
        <Fragment>
          <br />
          <SelectField
            floatingLabelText="Locatie item"
            onChange={updateSite}
            value={siteId}
          >
            {structures.map(({ id, name }) => (
              <MenuItem key={id} value={id} primaryText={name} />
            ))}
          </SelectField>
        </Fragment>
      )}
      <br />
      <FloatingLabelText text="Verplaats of kopieer" />
      <RadioButtonGroup
        className="radio-horizontal"
        name="moveItem"
        defaultSelected={moveItem ? "move" : "copy"}
        onChange={updateMoveItem}
      >
        <RadioButton value="move" label="Verplaats item" />
        <RadioButton value="copy" label="Kopieer item" />
      </RadioButtonGroup>
    </div>
  </Dialog>
);

export default BiebAddDialog;
