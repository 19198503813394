import React from "react";

import { SelectionValue } from "../../../../business/models";
import { Label } from "../../../content/field";

interface KansImpact {
  kans?: SelectionValue;
  impact?: SelectionValue;
}

const RisicoScore = ({ kans, impact }: KansImpact): JSX.Element | null => {
  if (!kans || !kans.alias || !impact || !impact.alias) {
    return null;
  }

  const kansValue = Number.parseInt(kans.alias, 10);
  const impactValue = Number.parseInt(impact.alias, 10);

  return (
    <div className="field ggvtyp3-criteria">
      <Label>Risico score</Label>
      <div className="value">{kansValue * impactValue}</div>
    </div>
  );
};

export default RisicoScore;
