import { map } from "lodash/fp";
import FlatButton from "material-ui/FlatButton";
import List, { ListItem } from "material-ui/List";
import TextField from "material-ui/TextField";
import React, { memo, useCallback, useMemo, useState } from "react";

import {
  RaamwerkAction,
  RaamwerkItem,
  RaamwerkTarget,
  SelectionListItem,
} from "../../../business/models";
import Dialog from "../../../containers/dialog";
import { flown } from "../../../lodash";
import takeFilterKeep from "../../../takeFilterKeep";
import { regexEscape } from "../../../utils";
import { RaamwerkStatusButtons } from "../../ggvtyp/raamwerk";
import PagetypeIcon from "../../pagetypeIcon";
import PageRefreshIndicator from "../PageRefreshIndicator";
import { AddDialogProps } from "./models";

const AddDialog = memo(
  ({
    selectionItemId,
    pagetype,
    statusItems,
    targets,
    targetsLoading,
    closeDialog,
    linkRaamwerk,
  }: AddDialogProps) => {
    const [sent, setSent] = useState(false);
    const [selection, updateSelection] = useState<{
      [key: number]: RaamwerkItem | undefined;
    }>({});
    const handleClick = useCallback(
      (id: number, { type, payload }: RaamwerkAction): void => {
        switch (type) {
          case "ADD":
            updateSelection({ ...selection, [id]: payload });
            break;
          case "REMOVE":
            updateSelection({ ...selection, [id]: undefined });
            break;
        }
      },
      [selection, updateSelection]
    );
    const [filter, setFilter] = useState("");
    const regex = useMemo(
      () => new RegExp(regexEscape(filter) || ".", "ig"),
      [filter]
    );

    return (
      <Dialog
        title={`Items koppelen van het type ${pagetype.name}`}
        open
        autoScrollBodyContent
        actions={[
          <FlatButton
            key="close"
            secondary
            label="Annuleren"
            onClick={closeDialog}
          />,
          <FlatButton
            primary
            disabled={sent}
            key="save"
            label="Opslaan"
            onClick={(_) => {
              setSent(true);
              linkRaamwerk(
                {
                  selectionItemId: selectionItemId,
                  links: Object.entries(selection)
                    .filter(([_, value]) => !!value)
                    .map(([itemId, value]) => ({
                      id: "",
                      status: (value as RaamwerkItem).meta.alias as string,
                      link: { itemId: Number(itemId) },
                    })),
                },
                closeDialog
              );
            }}
          />,
        ]}
      >
        {targetsLoading && targets.length === 0 && <PageRefreshIndicator />}
        {!targetsLoading && targets.length === 0 && (
          <p>
            <em>Geen items gevonden</em>
          </p>
        )}
        {targets.length > 0 && (
          <>
            <TextField
              name="filter"
              value={filter}
              floatingLabelText="Zoek in de lijst"
              onChange={(e, newValue) => setFilter(newValue)}
              style={{ width: "100%" }}
            />
            <List>
              {flown(
                targets,
                takeFilterKeep(
                  50,
                  ({ lbl }: RaamwerkTarget) => regex.test(lbl),
                  ({ itmIdt }: RaamwerkTarget) => !!selection[itmIdt]
                ),
                map(({ bieb, itmIdt, lbl }: RaamwerkTarget) => (
                  <ListItem
                    key={itmIdt}
                    primaryText={lbl}
                    leftIcon={
                      <PagetypeIcon
                        insideBieb={bieb}
                        type={pagetype.alias}
                        style={{}}
                        containerStyle={{}}
                        simpleIcon
                        iconStyle={{}}
                        colorProperty=""
                      />
                    }
                    rightIcon={
                      <RaamwerkStatusButtons
                        item={
                          {
                            id: selectionItemId,
                          } as SelectionListItem
                        }
                        onClick={(action) => handleClick(itmIdt, action)}
                        status={statusItems}
                        value={selection[itmIdt]}
                      />
                    }
                  />
                ))
              )}
            </List>
          </>
        )}
      </Dialog>
    );
  }
);

export default AddDialog;
