import FlatButton from "material-ui/FlatButton";
import React, { ChangeEvent, memo, useCallback, useState } from "react";

import Dialog from "../../../containers/dialog";
import { RemarkDialogProps, RemarkForm } from "./models";

const emptyForm: RemarkForm = {
  remark: "",
};

const RemarkDialog = memo<RemarkDialogProps>(
  ({ remarkForm, onSave, onDelete, onCancel }) => {
    const [form, updateForm] = useState<RemarkForm>(remarkForm || emptyForm);

    const handleChange = useCallback(
      ({ target: { name, value } }: ChangeEvent<HTMLTextAreaElement>) => {
        updateForm({ ...form, [name]: value });
      },
      [form]
    );

    const handleSave = useCallback(() => {
      onSave(form);
    }, [form, onSave]);

    const handleDelete = useCallback(() => {
      onDelete({ ...form, remark: undefined });
    }, [form, onDelete]);

    const handleCancel = useCallback(() => {
      onCancel();
    }, [onCancel]);

    return (
      <Dialog
        title="Opmerking"
        open
        autoScrollBodyContent
        actions={[
          <FlatButton key="close" label="Annuleren" onClick={handleCancel} />,
          remarkForm && remarkForm.remark ? (
            <FlatButton
              key="delete"
              secondary
              label="Verwijderen"
              onClick={handleDelete}
            />
          ) : null,
          <FlatButton
            key="save"
            primary
            label="Opslaan"
            onClick={handleSave}
            disabled={!form.remark}
          />,
        ]}
      >
        <div className="raamwerk-remark-form">
          <div className="field field-edit">
            <div className="subheader label raamwerk-label">Opmerking</div>
            <div className="value">
              <textarea
                key="textarea"
                name="remark"
                onChange={handleChange}
                value={form.remark}
                rows={5}
                className="remark-textarea"
                placeholder="Voer je opmerking in"
              />
            </div>
          </div>
        </div>
      </Dialog>
    );
  }
);

export default RemarkDialog;
