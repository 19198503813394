import arrayMove from "array-move";
import classNames from "classnames";
import { map } from "lodash/fp";
import FlatButton from "material-ui/FlatButton";
import ActionSwapVert from "material-ui/svg-icons/action/swap-vert";
import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import {
  SortableContainer,
  SortableContainerProps,
  SortableElement,
  SortableElementProps,
} from "react-sortable-hoc";

import { getPageAction } from "../../actions/data";
import { invalidatePage } from "../../actions/page";
import { StructureItem } from "../../business/models";
import Dialog from "../../containers/dialog";
import { sagas } from "../../sagas";
import HintCard from "../material/HintCard";
import PagetypeIcon from "../pagetypeIcon";

interface OrderItemProps {
  item: StructureItem;
}

const OrderItem = ({ item }: OrderItemProps): JSX.Element => (
  <div className={classNames("treeorder-draggable", {})}>
    <div className="treeorder-handle">
      <ActionSwapVert />
    </div>
    <div className="treeorder-icon">
      {
        // force eol
        // @ts-ignore
        item.pagetype && <PagetypeIcon type={item.pagetype} simpleIcon />
      }
    </div>
    <div className="treeorder-title">{item.label}</div>
  </div>
);

const SortableItem: React.ComponentClass<
  OrderItemProps & SortableElementProps
> = SortableElement(OrderItem);

interface OrderListProps {
  items: StructureItem[];
}

const OrderList = ({ items }: OrderListProps): JSX.Element => (
  <div style={{ userSelect: "none" }}>
    {items.map((item, i) => (
      <SortableItem key={item.itemId} item={item} index={i} />
    ))}
  </div>
);

const SortableList: React.ComponentClass<
  OrderListProps & SortableContainerProps
> = SortableContainer(OrderList);

interface TreeOrderDialogProps {
  pageItemId?: number;
  itemId: number;
  sortable: StructureItem[];
  onClose(): void;
  onOrderChanged?(): void;
  orderRootItems: boolean;
}

const TreeOrderDialog = ({
  pageItemId,
  itemId,
  sortable,
  onClose,
  onOrderChanged,
  orderRootItems,
}: TreeOrderDialogProps): JSX.Element => {
  const [items, setItems] = useState(sortable);
  const [busy, setBusy] = useState(false);
  const dispatch = useDispatch();

  const onSuccess = useCallback((): void => {
    if (pageItemId) {
      dispatch({ type: "TREE_INVALIDATE", payload: { id: pageItemId } });
      dispatch(invalidatePage(pageItemId));
      dispatch(getPageAction(pageItemId));
    }

    onClose();
  }, [dispatch, onClose, pageItemId]);

  const handleSave = useCallback((): void => {
    setBusy(true);

    if (orderRootItems) {
      dispatch({
        type: sagas.orderRootItems.require,
        payload: { ids: map("itemId")(items) },
        onSuccess,
      });
    } else {
      dispatch({
        type: sagas.orderRelations.require,
        payload: { itemId, ids: map("relationId")(items) },
        onSuccess,
      });
    }

    onClose();
    onOrderChanged?.();
  }, [
    dispatch,
    itemId,
    items,
    onClose,
    onOrderChanged,
    onSuccess,
    orderRootItems,
  ]);
  return (
    <Dialog
      title="Volgorde wijzigen"
      open
      autoScrollBodyContent
      actions={[
        <FlatButton
          key="close"
          secondary
          label="Annuleren"
          onClick={onClose}
        />,
        <FlatButton
          primary
          key="save"
          label="Opslaan"
          onClick={handleSave}
          disabled={busy}
        />,
      ]}
    >
      <SortableList
        items={items}
        distance={5}
        lockAxis="y"
        onSortEnd={({ oldIndex, newIndex }) =>
          setItems(arrayMove(items, oldIndex, newIndex))
        }
      />
      <HintCard primaryText="Sleep de items in de goede volgorde." />
    </Dialog>
  );
};

export default TreeOrderDialog;
