import { Provider } from "react-redux";
import { hashHistory } from "react-router";
import { applyMiddleware, compose, createStore } from "redux";
import createSagaMiddleware from "redux-saga";
import reduxThunk from "redux-thunk";

import reducers from "./reducers";
import initSagas from "./sagas";

/* eslint-disable no-underscore-dangle */
const extensionCompose = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
/* eslint-enable no-underscore-dangle */

const composeEnhancers =
  (process.env.NODE_ENV === "development" && extensionCompose) || compose;

let saarStore = null;

const createSaarStore = () => {
  const { reducer, sagas } = initSagas();
  const sagaMiddleware = createSagaMiddleware();
  saarStore = createStore(
    reducers({ sagas: reducer }),
    composeEnhancers(applyMiddleware(reduxThunk, sagaMiddleware))
  );
  sagaMiddleware.run(sagas);
  return saarStore;
};

export const saarHistory = hashHistory;

export const StoreProvider = ({ children }) => (
  <Provider store={saarStore}>{children}</Provider>
);

export const getMiniSaarPath = (path) => {
  const miniSaar = saarHistory.getCurrentLocation().pathname.split("/")[1];
  return `/${miniSaar}/${path}`;
};

export default createSaarStore;
