/* eslint  react/prop-types: off */
import empty from "empty";
import { get, has } from "lodash/fp";
import { Card, CardText } from "material-ui/Card";
import FlatButton from "material-ui/FlatButton";
import RaisedButton from "material-ui/RaisedButton";
import Subheader from "material-ui/Subheader";
import TextField from "material-ui/TextField";
import React, { Fragment, useEffect } from "react";
import { Link } from "react-router";

import { toStorage } from "../api/auth.common";
import { saarHistory } from "../store";
import saar from "../styles/images/saar.png";
import AzureAd from "../svg/AzureAd";
import PageRefreshIndicator from "./pagetypes/PageRefreshIndicator";

const ChangeDone = ({ handleInvalidate }) => (
  <Card>
    <CardText>
      <Subheader>Wachtwoord gewijzigd</Subheader>
      <CardText>
        <p>Uw nieuwe wachtwoord is opgeslagen.</p>
        <div style={{ textAlign: "center" }}>
          <RaisedButton primary label="OK" onClick={handleInvalidate} />
        </div>
      </CardText>
    </CardText>
  </Card>
);

const valid = (password) =>
  typeof password === "string" &&
  password.length >= 8 &&
  /[A-Z]/.test(password) &&
  /[a-z]/.test(password) &&
  /\d/.test(password) &&
  /[^a-zA-Z\d]/.test(password);
const ChangePassword = ({
  model: { done = false },
  model,
  handleChange,
  handlePassword,
  handleInvalidate,
}) =>
  done ? (
    <ChangeDone handleInvalidate={handleInvalidate} />
  ) : (
    <Card>
      <CardText>
        <Subheader>Wachtwoord wijzigen</Subheader>
        <form method="post" onSubmit={handlePassword}>
          <CardText>
            <p>
              Uw nieuwe wachtwoord moet ten minste 8 karakters lang zijn en
              minstens 1 hoofdletter, 1 kleine letter, 1 cijfer en 1 ander teken
              bevatten.
            </p>
            <TextField
              name="oldPassword"
              onChange={handleChange}
              hintText="Huidig wachtwoord"
              floatingLabelText="Huidig wachtwoord"
              floatingLabelFixed
              type="password"
            />
            <br />
            <TextField
              name="newPassword"
              onChange={handleChange}
              hintText="Nieuw wachtwoord"
              errorText={
                model.newPassword && !valid(model.newPassword)
                  ? "Wachtwoord voldoet niet aan de eisen"
                  : undefined
              }
              floatingLabelText="Nieuw wachtwoord"
              floatingLabelFixed
              type="password"
            />
            <br />
            <TextField
              name="confirmPassword"
              onChange={handleChange}
              hintText="Nogmaals nieuw wachtwoord"
              errorText={
                model.newPassword &&
                model.confirmPassword &&
                model.newPassword !== model.confirmPassword
                  ? "Wachtwoorden komen niet overeen"
                  : undefined
              }
              floatingLabelText="Bevestig nieuw wachtwoord"
              floatingLabelFixed
              type="password"
            />
          </CardText>
          <FlatButton
            label="Wachtwoord wijzigen"
            type="submit"
            disabled={
              !model.oldPassword ||
              !model.newPassword ||
              !model.confirmPassword ||
              model.oldPassword === model.newPassword ||
              model.newPassword !== model.confirmPassword ||
              !valid(model.newPassword)
            }
            primary
          />
        </form>
      </CardText>
    </Card>
  );

const Challenge = ({
  channel,
  vlgNum,
  handleChange,
  handleTfa,
  handleLogout,
}) => (
  <Card>
    <CardText>
      <p>
        Een {channel} met toegangscode (met volgnummer {vlgNum}) is naar u
        verstuurd. Zet de code uit deze {channel} in het veld Toegangscode
        hieronder.
      </p>
      <form method="post" onSubmit={handleTfa}>
        <TextField
          name="secret"
          onChange={handleChange}
          hintText="Toegangscode"
          floatingLabelText="Toegangscode"
          floatingLabelFixed
        />
        <br />
        <FlatButton label="Inloggen" type="submit" primary />
        <br />
        <FlatButton label="Uitloggen" secondary onClick={handleLogout} />
      </form>
    </CardText>
  </Card>
);

const Credentials = ({
  handleChange,
  handleLogin,
  office365: { loginUri } = empty.object,
  azureAd: { loginUri: loginAzureAdUri } = empty.object,
  handleOfficeClick,
  handleAzureAdClick,
  googleSsoClientId,
}) => {
  useEffect(() => {
    if (!googleSsoClientId || !window.google) {
      return;
    }

    window.google.accounts.id.initialize({
      client_id: googleSsoClientId,
      callback: (response) => {
        toStorage("saar_loginsource", "google");
        saarHistory.push(`/external/${response.credential}`);
      },
    });

    window.google.accounts.id.renderButton(
      document.getElementById("google-login-div"),
      { theme: "outline", size: "large" } // customization attributes
    );
  }, [googleSsoClientId]);

  return (
    <Card>
      <CardText>
        <form method="post" onSubmit={handleLogin}>
          <TextField
            name="username"
            onChange={handleChange}
            hintText="Gebruikersnaam"
            floatingLabelText="Gebruikersnaam"
            floatingLabelFixed
          />
          <br />
          <TextField
            name="password"
            onChange={handleChange}
            hintText="Wachtwoord"
            floatingLabelText="Wachtwoord"
            floatingLabelFixed
            type="password"
          />
          <br />
          <FlatButton label="Inloggen" type="submit" primary />
          <br />
          <FlatButton
            label="Wachtwoord vergeten?"
            secondary
            containerElement={<Link to="/vergeten" />}
          />
        </form>
        {(loginUri || loginAzureAdUri || googleSsoClientId) && (
          <div>
            <Subheader style={{ color: "rgba(0, 0, 0, 0.3)", paddingLeft: 0 }}>
              Of login met
            </Subheader>
            <div className="login-buttons">
              <div className="login-buttons-row">
                {loginUri && (
                  <FlatButton
                    onClick={handleOfficeClick}
                    label="Inloggen met Microsoft 365"
                    secondary
                    labelStyle={{
                      color: "rgb(60, 64, 67)",
                      textTransform: "none",
                    }}
                    icon={
                      <img
                        alt=""
                        src="data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='21' height='21' viewBox='0 0 21 21'%3e%3ctitle%3eMS-SymbolLockup%3c/title%3e%3crect x='1' y='1' width='9' height='9' fill='%23f25022'/%3e%3crect x='1' y='11' width='9' height='9' fill='%2300a4ef'/%3e%3crect x='11' y='1' width='9' height='9' fill='%237fba00'/%3e%3crect x='11' y='11' width='9' height='9' fill='%23ffb900'/%3e%3c/svg%3e"
                      />
                    }
                  />
                )}
                {loginAzureAdUri && (
                  <FlatButton
                    onClick={handleAzureAdClick}
                    label="Azure AD"
                    secondary
                    labelStyle={{ color: "#eb3c00" }}
                    icon={<AzureAd />}
                  />
                )}
              </div>
              {googleSsoClientId && (
                <div className="login-buttons-row">
                  <div id="google-login-div" />
                </div>
              )}
            </div>
          </div>
        )}
      </CardText>
    </Card>
  );
};

const Inner = ({
  tfa: { channel, sequenceNumber: vlgNum, seed, challenge } = empty.object,
  isAuthenticated,
  changePassword = false,
  checked,
  handleChange,
  handleLogin,
  handleLogout,
  handlePassword,
  handleTfa,
  myLogin,
  user,
  office365,
  azureAd,
  handleOfficeClick,
  handleAzureAdClick,
  handleInvalidate,
  googleSsoClientId,
}) => {
  switch (true) {
    case Boolean(seed && challenge):
      return (
        <Challenge
          channel={channel}
          vlgNum={vlgNum}
          handleChange={handleChange}
          handleTfa={handleTfa}
          handleLogout={handleLogout}
        />
      );
    case !checked:
      return <PageRefreshIndicator />;
    case has("username")(user) && changePassword:
      return (
        <ChangePassword
          handleChange={handleChange}
          handlePassword={handlePassword}
          model={myLogin}
          handleInvalidate={handleInvalidate}
        />
      );
    case isAuthenticated:
      return <PageRefreshIndicator />;
    default:
      return (
        <Credentials
          handleChange={handleChange}
          handleLogin={handleLogin}
          handleOfficeClick={handleOfficeClick}
          handleAzureAdClick={handleAzureAdClick}
          office365={office365}
          azureAd={azureAd}
          googleSsoClientId={googleSsoClientId}
        />
      );
  }
};

export const Loading = () => (
  <div className="login-loader">
    <PageRefreshIndicator />
  </div>
);

const Login = (props) => (
  <Fragment>
    <main className="login-form">
      <img src={saar} alt="" />
      <Inner {...props} />
    </main>
    {props.loading &&
      (Boolean(get("tfa.seed")(props) && get("tfa.challenge")(props)) ||
        (props.checked &&
          ((has("user.username")(props) && props.changePassword) ||
            !props.isAuthenticated))) && <Loading />}
  </Fragment>
);

export default Login;
