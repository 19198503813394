import empty from "empty";
import { defaultTo, find, flow, get } from "lodash/fp";
import { connect } from "react-redux";
import { compose, setDisplayName } from "recompose";
import { createSelector } from "reselect";

import { requireRaamwerkTargetsAction } from "../../actions/data";
import HintCard from "../../components/material/HintCard";
import { flown } from "../../lodash";

const raamwerkSelector = createSelector(
  [
    ({ data: { raamwerk } }) => raamwerk,
    ({ data: { raamwerkPagetypes } }) => raamwerkPagetypes,
    ({ data: { selectionLists } }) => selectionLists,
    get("form.linkRaamwerk.items"),
    get("form.raamwerkOpen"),
    (_, { id }) => id,
    (_, { pageActions }) => pageActions,
  ],
  (
    raamwerk,
    raamwerkPagetypes,
    selectionLists,
    targets,
    raamwerkOpen,
    itemId,
    pageActions
  ) => {
    const currentRaamwerk = raamwerk[`${itemId}`];
    const raamwerkLijst = get("list")(currentRaamwerk);
    return {
      pageActions,
      raamwerk: {
        ...currentRaamwerk,
        raamwerkAlias:
          currentRaamwerk.list?.alias ?? currentRaamwerk.list?.value,
        raamwerkLijst,
        statusItems: flown(
          selectionLists,
          get("data"),
          find({ alias: "raamwerk_status" }),
          get("items"),
          defaultTo(empty.array)
        ),
        targets: targets.value,
        targetsLoading:
          targets.loading !== "success" && targets.load !== "error",
        open: raamwerkOpen[itemId] || empty.object,
        pagetypes: flow(
          get(`${raamwerkLijst?.alias}.pagetypes`),
          defaultTo(empty.array)
        )(raamwerkPagetypes),
        numberAdded:
          pageActions.edit && pageActions.staged
            ? pageActions.staged
                .filter((stage) =>
                  /RaamwerkLinkAddAction|RemarkAction/.test(stage.$type)
                )
                .map(({ selectionId }) => selectionId)
            : undefined,
      },
    };
  }
);

const enhanceRaamwerk = compose(
  setDisplayName("Raamwerk"),
  connect(
    raamwerkSelector,
    (dispatch) => ({ dispatch }),
    ({ pageActions, raamwerk, ...stateProps }, { dispatch }, ownProps) => ({
      ...ownProps,
      ...stateProps,
      raamwerk: {
        ...raamwerk,
        requireRaamwerkTargets: (pagetypeId, selectionId, organizationIds) =>
          dispatch(
            requireRaamwerkTargetsAction(
              ownProps.id,
              pagetypeId,
              selectionId,
              organizationIds
            )
          ),
        linkRaamwerk: ({ selectionItemId, links }, callbackSuccess) => {
          for (const link of links) {
            pageActions.add("RaamwerkLinkAdd", {
              itemId: link.link.itemId,
              raamwerkAlias: raamwerk.raamwerkAlias,
              selectionId: selectionItemId,
              status: link.status,
            });
          }

          callbackSuccess();
          pageActions.save(true);
        },
        hyperlinkAction: (
          { action, selectionId, status, externLink },
          callbackSuccess
        ) => {
          pageActions.add(action, {
            raamwerkAlias: raamwerk.raamwerkAlias,
            selectionId,
            status,
            externLink,
          });
          callbackSuccess();
          pageActions.save(true);
        },
        toggleOpen: (itemId) =>
          dispatch({
            type: "RAAMWERKOPEN_TOGGLE",
            payload: { id: ownProps.id, itemId },
          }),

        remarkAction: (action, form, callbackSuccess) => {
          pageActions.add(action, form);
          callbackSuccess();
          pageActions.save(true);
        },
      },

      renderPreContent: ({ pagetype, tabName, pageActions, hasOpmerkingen }) =>
        !hasOpmerkingen &&
        pageActions.edit &&
        tabName !== "Inhoud" &&
        pagetype === "praktijksituatie" ? (
          <HintCard
            primaryText="Dit tabblad toont alleen relaties en scores op het raamwerk."
            secondaryText="Koppel en bewerk relaties en scores op het raamwerk bij de inhoud."
            style={{ marginBottom: 16 }}
          />
        ) : null,
    })
  )
);

export default enhanceRaamwerk;
