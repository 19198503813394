import React, { useCallback, useState } from "react";

import { StructureItem } from "../../business/models";
import TreeOrderDialog from "../relations/TreeOrderDialog";
import TreeWrap from "./treeWrap";

const Tree = (props: any): JSX.Element => {
  const [itemId, setItemId] = useState(0);
  const [sortable, setSortable] = useState<StructureItem[]>([]);
  const onSort = useCallback((itemId: number, items: StructureItem[]): void => {
    setItemId(itemId);
    setSortable(items);
  }, []);
  const onSortClose = useCallback((): void => setSortable([]), []);
  return (
    <>
      {sortable.length > 1 && (
        <TreeOrderDialog
          pageItemId={props.itemId}
          itemId={itemId}
          sortable={sortable}
          onClose={onSortClose}
          orderRootItems={itemId === 0}
        />
      )}
      <TreeWrap {...props} onSort={onSort} />
    </>
  );
};

export default Tree;
