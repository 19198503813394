import Afbeelding from "../../components/ggvtyp/afbeelding";
import BestandBase from "../../components/ggvtyp/bestand";
import Bool from "../../components/ggvtyp/bool";
import Datum from "../../components/ggvtyp/datum";
import Email from "../../components/ggvtyp/email";
import Internet from "../../components/ggvtyp/internet";
import IproxGebruiker from "../../components/ggvtyp/iproxGebruiker";
import PlatGegeven from "../../components/ggvtyp/platGegeven";
import { RaamwerkLink as RaamwerkLinkBase } from "../../components/ggvtyp/raamwerk";
import Tekstvlak from "../../components/ggvtyp/tekstvlak";
import Trefwoorden from "../../components/ggvtyp/trefwoorden";
import Verwijzing from "../../components/ggvtyp/verwijzing";
import Wachtwoord from "../../components/ggvtyp/wachtwoord";
import Werkdagen from "../../components/ggvtyp/werkdagen";
import { fieldDefinition } from "../../selectors/definition";
import connect from "../connect";
import HtmlBase from "./html";
import MeervoudigBestand from "./meervoudigBestand";
import OneDrive from "./onedrive";
import PicklistBase from "./picklist";
import RaamwerkBase from "./raamwerk";
import SelectieBase from "./selectie";

const addDefinition = connect(fieldDefinition);

const Bestand = addDefinition(BestandBase);
const Html = addDefinition(HtmlBase);
const Selectie = addDefinition(SelectieBase);
const Picklist = addDefinition(PicklistBase);
const Raamwerk = addDefinition(RaamwerkBase);
export const RaamwerkLink = addDefinition(RaamwerkLinkBase);

const ggvtyp = {
  // GgvTyp-code mapping
  1: Bool,
  2: PlatGegeven,
  3: Selectie,
  4: Picklist,
  5: Html,
  6: Internet,
  7: Datum,
  10: Afbeelding,
  11: Bestand,
  13: Verwijzing,
  16: Email,
  17: Trefwoorden,
  18: Verwijzing,
  20: Wachtwoord,
  21: Tekstvlak,
  97: IproxGebruiker,
  272: MeervoudigBestand,
  273: OneDrive,
  274: Raamwerk,

  // normal
  Afbeelding,
  Bestand,
  Bool,
  Datum,
  Email,
  Html,
  Internet,
  IproxGebruiker,
  Picklist,
  PlatGegeven,
  Selectie,
  Tekstvlak,
  Trefwoorden,
  Verwijzing,
  Wachtwoord,
  Werkdagen,
  MeervoudigBestand,
};

export default ggvtyp;
