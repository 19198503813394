import empty from "empty";

import { iproxUrl } from "../config";
import { getJSON, postJSON } from "./fetch";

const base = [
  { key: "AppIdt", value: "00004600" },
  { key: "xdl", value: "MediaWidgetManager" },
  { key: "xsl", value: "MediaWidgetManager" },
  { key: "lang", value: "nl" },
];

export const getMediaWidgetTypes = () =>
  getJSON(`${iproxUrl}/aspx/read.aspx`, [
    ...base,
    { key: "method", value: "getTypes" },
  ]);

export const getMediaWidgets = (ids = empty.array) =>
  getJSON(`${iproxUrl}/aspx/read.aspx`, [
    ...base,
    { key: "method", value: "retrieve" },
    { key: "ids", value: ids },
  ]);

export const storeMediaWidget = ({
  MwtIdt,
  Vrs,
  TypeId,
  Title,
  Url,
  SourceCode,
  Properties,
}) => {
  const body = {
    "MediaWidgetStore.$action": "run",
    "MediaWidgetStore.$id": MwtIdt,
    "MediaWidgetStore.Vrs": Vrs,
    "MediaWidgetStore.MwtTypIdt": TypeId,
    "MediaWidgetStore.Tit": Title,
    "MediaWidgetStore.Url": Url,
    "MediaWidgetStore.OrgTxt": SourceCode,
  };

  for (const { Alias, Value } of Properties) {
    body[`MediaWidgetStore.Prp.${Alias}`] = Value;
  }

  return postJSON(`${iproxUrl}/aspx/post.aspx`, body).then(
    ({ success, properties: { "MwtTab.CurIdt": mwtIdt } = empty.object }) =>
      success && mwtIdt ? getMediaWidgets([mwtIdt]) : Promise.resolve()
  );
};

export const getMediaWidgetBySourceCode = ({ type, sourceCode }) =>
  getJSON(`${iproxUrl}/aspx/read.aspx`, [
    ...base,
    { key: "method", value: "getMediaWidgetBySourceCode" },
    { key: "type", value: type },
    { key: "sourceCode", value: sourceCode },
  ]);

export const getMediaWidgetByUrl = ({ type, url }) =>
  getJSON(`${iproxUrl}/aspx/read.aspx`, [
    ...base,
    { key: "method", value: "getMediaWidgetByUrl" },
    { key: "type", value: type },
    { key: "url", value: url },
  ]);
