import empty from "empty";
import { filter, flow, keys, map } from "lodash/fp";
import { Paper } from "material-ui";
import PropTypes from "prop-types";
import React from "react";
import { compose, mapProps, setDisplayName, withHandlers } from "recompose";

import { Mutations } from "../../business";
import { patchNameLinksRechts } from "../../business/definitions";
import {
  itemShape,
  pageActionsShape,
  siteLinkShape,
} from "../../business/prop-types";
import { Side } from "../../business/relations";
import Field from "../content/field";
import { isVisibleField } from "../content/query";
import ClusterCard from "../material/clusterCard";
import PagetypeIcon from "../pagetypeIcon";

const readOnly = Object.freeze({
  edit: false,
  busy: false,
  pageId: -1,
  fieldRevert: empty.func,
});

const RelationMetaView = ({
  clusterFirst,
  label: relationLabel,
  item: { label, pageClusterLabel, itemId, type } = empty.object,
  meta: { id, page } = empty.object,
  handleRemove,
  pageActions,
  mutation,
  links,
  rechts,
}) => {
  const clusterCardLabel = `${(clusterFirst && pageClusterLabel) || label}`;
  const titleRenderer = (title) => (
    <>
      <PagetypeIcon type={type} />
      <span className="relation-meta-view-title">
        {mutation >= 0 ? (
          mutation === Mutations.created ? (
            <ins className="diff-mod">{title}</ins>
          ) : (
            <del className="diff-mod">{title}</del>
          )
        ) : (
          title
        )}
      </span>
    </>
  );
  return (
    <Paper style={{ marginBottom: 16 }}>
      <ClusterCard
        collapse={clusterCardLabel}
        onDelete={pageActions.edit ? handleRemove : undefined}
        expandable
        titleRenderer={titleRenderer}
      >
        <Field
          field={{
            definition: {
              dataTypeCode: 13,
              id: -1,
              name: relationLabel,
              required: true,
            },
            id: -2,
            iproxType: "field",
            isEmpty: false,
            names: [relationLabel],
            value: {
              label: pageClusterLabel
                ? clusterFirst
                  ? `${pageClusterLabel} (${label})`
                  : `${label} (${pageClusterLabel})`
                : label,
              linkId: itemId,
            },
          }}
          pageActions={readOnly}
        />
        {flow(
          map((key) => page[key]),
          filter((f) => isVisibleField(f, pageActions)),
          map((field) => {
            return (
              <Field
                key={field.definition.id}
                field={field}
                itemId={id}
                pageActions={pageActions}
                name={patchNameLinksRechts(
                  field.definition.name,
                  links,
                  rechts
                )}
              />
            );
          })
        )(keys(page))}
      </ClusterCard>
    </Paper>
  );
};

RelationMetaView.propTypes = {
  clusterFirst: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  item: siteLinkShape.isRequired,
  meta: itemShape.isRequired,
  pageActions: pageActionsShape.isRequired,
  handleRemove: PropTypes.func.isRequired,
  mutation: PropTypes.number,
};

RelationMetaView.defaultProps = {
  clusterFirst: false,
};

export default compose(
  setDisplayName("RelationMetaView"),

  mapProps(
    ({
      relation: {
        id,
        item = empty.object,
        using = empty.array,
        meta,
        mutation,
        relation: { originalSide, left, right },
      },
      ...other
    }) => {
      const links = (originalSide === Side.left ? left : right)?.label ?? "?";
      const rechts = (originalSide === Side.right ? left : right)?.label ?? "?";
      return { ...other, item, using, meta, id, mutation, links, rechts };
    }
  ),

  withHandlers({
    handleRemove:
      ({ removeRelation, id, item: { itemId } = empty.object }) =>
      () => {
        removeRelation(id, itemId);
      },
  })
)(RelationMetaView);
