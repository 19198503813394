import icons from "./icons";
import { Pagetype } from "./icons";

const Icon = ({
  pagetype: PagetypeIcon,
}: {
  pagetype: Pagetype;
}): JSX.Element => {
  const Icon = icons[PagetypeIcon]!;
  return <Icon />;
};

export default Icon;
