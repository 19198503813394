import empty from "empty";
import {
  compact,
  every,
  find,
  includes,
  map,
  mapValues,
  union,
  uniq,
  without,
} from "lodash/fp";
import Checkbox from "material-ui/Checkbox";
import FlatButton from "material-ui/FlatButton";
import IconButton from "material-ui/IconButton";
import { RadioButton, RadioButtonGroup } from "material-ui/RadioButton";
import RaisedButton from "material-ui/RaisedButton";
import RefreshIndicator from "material-ui/RefreshIndicator";
import { Step, StepButton, StepContent, Stepper } from "material-ui/Stepper";
import Subheader from "material-ui/Subheader";
import ContentAdd from "material-ui/svg-icons/content/add";
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import {
  branch,
  compose,
  renderNothing,
  setDisplayName,
  withHandlers,
  withProps,
  withState,
} from "recompose";

import { entiteitShape, kernGegevenShape } from "../../../business/prop-types";
import Dialog from "../../../containers/dialog";
import { flown } from "../../../lodash";
import { execOnChange, withoutProps } from "../../../recompose.contrib";
import { splitChar } from "../../../utils";
import HintCard from "../../material/HintCard";

export const EntiteitenLoadingIndicator = () => (
  <RefreshIndicator
    left={0}
    size={38}
    status="loading"
    style={{ position: "relative" }}
    top={0}
  />
);

const EntiteitenLijst = ({
  lijst,
  selectEntiteit,
  entiteit: { id: selected } = empty.object,
}) => (
  <Fragment>
    <Subheader style={{ paddingLeft: 0 }}>Kies een entiteit</Subheader>
    <RadioButtonGroup
      name="entiteit"
      onChange={(_, value) => selectEntiteit(value)}
      valueSelected={selected}
    >
      {lijst.map(({ id, naam }) => (
        <RadioButton key={id} value={id} label={naam} />
      ))}
    </RadioButtonGroup>

    <RaisedButton
      label="Geen entiteit"
      primary
      onClick={() => selectEntiteit(-1)}
      style={{ marginTop: 16 }}
    />
  </Fragment>
);
EntiteitenLijst.propTypes = Object.freeze({
  lijst: PropTypes.arrayOf(entiteitShape.isRequired).isRequired,
  selectEntiteit: PropTypes.func.isRequired,
  entiteit: entiteitShape,
});

const AddNewGegevenComponent = ({
  onChange,
  onPaste,
  onAdd,
  gegevenNaam,
  selectNewGegeven,
  newGegevens,
}) => (
  <Fragment>
    {newGegevens.length > 0 && (
      <Subheader style={{ paddingLeft: 0 }}>
        Nieuw toegevoegde gegevens
      </Subheader>
    )}
    {flown(
      newGegevens,
      map((naam) => (
        <Checkbox
          key={naam}
          value={naam}
          label={naam}
          checked={includes(naam)(newGegevens)}
          onCheck={(_, isInputChecked) =>
            selectNewGegeven(naam, isInputChecked)
          }
        />
      ))
    )}
    <form
      method="post"
      style={{ display: "flex", alignItems: "baseline" }}
      onSubmit={onAdd}
    >
      <textarea
        placeholder="Naam nieuw gegeven"
        className="textarea-mui-look"
        onPaste={onPaste}
        onChange={onChange}
        value={gegevenNaam}
        rows={5}
      />
      <IconButton type="submit" disabled={gegevenNaam === ""}>
        <ContentAdd />
      </IconButton>
    </form>

    <HintCard
      primaryText="Nieuwe gegeven toevoegen"
      secondaryText="Voer een naam van een nieuw gegeven in. Gebruik een komma of een nieuwe regel voor het toevoegen van meerdere gegevens. Bijvoorbeeld 'Gegeven A, Gegeven B' resulteert in 2 nieuwe applicatiegegevens."
    />
  </Fragment>
);
AddNewGegevenComponent.propTypes = Object.freeze({
  onChange: PropTypes.func.isRequired,
  onPaste: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  gegevenNaam: PropTypes.string,
  selectNewGegeven: PropTypes.func.isRequired,
  newGegevens: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
});

const AddNewGegeven = compose(
  setDisplayName("AddNewGegeven"),
  withState("gegevenNaam", "updateNaam", ""),
  withHandlers({
    onChange:
      ({ updateNaam }) =>
      (event) => {
        const newValue = event.target.value;
        updateNaam(newValue);
      },

    onPaste:
      ({ addNewGegeven, updateNaam }) =>
      (event) => {
        const newValue = event.clipboardData.getData("text");
        if (
          splitChar.test(newValue) &&
          window.confirm(
            "Wilt u de geplakte tekst omzetten naar losse gegevens?"
          )
        ) {
          event.preventDefault();
          addNewGegeven(newValue);

          // reset input
          updateNaam("");
        }
      },

    onAdd:
      ({ addNewGegeven, gegevenNaam, updateNaam }) =>
      (event) => {
        event.preventDefault();
        if (gegevenNaam !== "") {
          addNewGegeven(gegevenNaam);
          updateNaam("");
        }
      },
  })
)(AddNewGegevenComponent);

const GegevenLijst = ({
  lijst,
  selectKern,
  gegevenIds,
  veldIds,
  addNewGegeven,
  selectNewGegeven,
  newGegevens,
}) => (
  <Fragment>
    {lijst && lijst.length > 0 && (
      <>
        <Subheader style={{ paddingLeft: 0 }}>Kies entiteitgegevens</Subheader>
        {flown(
          lijst,
          map(({ id: gegevenId, naam, velden }) => (
            <Fragment key={gegevenId}>
              <Checkbox
                value={gegevenId}
                label={naam}
                checked={includes(gegevenId)(gegevenIds)}
                onCheck={(_, isInputChecked) =>
                  selectKern(isInputChecked, gegevenId, map("id")(velden))
                }
              />
              {flown(
                velden,
                map(({ id, naam }) => (
                  <Checkbox
                    className="checkbox-level1"
                    key={id}
                    value={id}
                    label={naam}
                    checked={includes(id)(veldIds)}
                    onCheck={(_, isInputChecked) =>
                      selectKern(
                        isInputChecked,
                        isInputChecked ? gegevenId : undefined,
                        [id]
                      )
                    }
                  />
                ))
              )}
            </Fragment>
          ))
        )}
      </>
    )}
    <AddNewGegeven
      addNewGegeven={addNewGegeven}
      selectNewGegeven={selectNewGegeven}
      newGegevens={newGegevens}
    />
  </Fragment>
);
GegevenLijst.defaultProps = {
  lijst: undefined,
};
GegevenLijst.propTypes = Object.freeze({
  lijst: PropTypes.arrayOf(kernGegevenShape),
  selectKern: PropTypes.func.isRequired,
  gegevenIds: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
  veldIds: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
  addNewGegeven: PropTypes.func.isRequired,
  selectNewGegeven: PropTypes.func.isRequired,
  newGegevens: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
});

const StepButtonEnhanced = compose(
  setDisplayName("StepButton"),
  withHandlers({
    onClick:
      ({ updateStep, stepIndex = 0 }) =>
      () =>
        updateStep(stepIndex),
  }),
  withoutProps("updateStep", "stepIndex")
)(StepButton);

const GegevenDialog = ({
  close,
  save,
  loading,
  entiteiten,
  stepIndex,
  updateStep,
  selectEntiteit,
  selectKern,
  addNewGegeven,
  selectNewGegeven,
  entiteit,
  gegevenIds,
  veldIds,
  newGegevens,
}) => (
  <Dialog
    title="Gegeven"
    open
    autoScrollBodyContent
    actions={[
      <FlatButton key="close" secondary label="Annuleren" onClick={close} />,
      <FlatButton
        key="save"
        primary
        label="Opslaan"
        onClick={save}
        disabled={newGegevens.length === 0 && gegevenIds.length === 0}
      />,
    ]}
  >
    <Stepper activeStep={stepIndex} orientation="vertical">
      <Step>
        <StepButtonEnhanced updateStep={updateStep} stepIndex={0}>
          <span>Applicatiegegeven hoort bij entiteit:</span>
          <span>&#160;</span>
          {entiteit && entiteit.naam && <strong>{entiteit.naam}</strong>}
        </StepButtonEnhanced>
        <StepContent>
          {loading ? (
            <EntiteitenLoadingIndicator />
          ) : (
            <EntiteitenLijst
              lijst={entiteiten}
              selectEntiteit={selectEntiteit}
              entiteit={entiteit}
            />
          )}
        </StepContent>
      </Step>
      <Step>
        <StepButtonEnhanced updateStep={updateStep} stepIndex={1}>
          <span>
            {entiteit?.gegevens
              ? "Is gebaseerd op entiteitgegeven:"
              : "Niet gebaseerd op entiteit:"}
          </span>
        </StepButtonEnhanced>
        <StepContent>
          <GegevenLijst
            lijst={entiteit?.gegevens}
            selectKern={selectKern}
            addNewGegeven={addNewGegeven}
            selectNewGegeven={selectNewGegeven}
            gegevenIds={gegevenIds}
            veldIds={veldIds}
            newGegevens={newGegevens}
          />
        </StepContent>
      </Step>
    </Stepper>
  </Dialog>
);
GegevenDialog.propTypes = Object.freeze({
  close: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  entiteiten: PropTypes.arrayOf(entiteitShape.isRequired).isRequired,
  stepIndex: PropTypes.number.isRequired,
  updateStep: PropTypes.func.isRequired,
  selectEntiteit: PropTypes.func.isRequired,
  selectKern: PropTypes.func.isRequired,
  addNewGegeven: PropTypes.func.isRequired,
  selectNewGegeven: PropTypes.func.isRequired,
  entiteit: entiteitShape,
  gegevenIds: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
  veldIds: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
  newGegevens: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
});

const initialState = Object.freeze({
  stepIndex: 0,
  entiteit: undefined,
  gegevenIds: empty.array,
  veldIds: empty.array,
  newGegevens: empty.array,
});

const stateHandlers = {
  updateStep:
    ({ state }) =>
    (stepIndex) => ({ ...state, stepIndex }),
  selectEntiteit:
    ({ state, entiteiten = empty.array }) =>
    (id) => {
      if (id === -1) {
        // Gekozen voor "Geen entiteit"
        return { ...initialState, stepIndex: 1, entiteit: undefined };
      }

      return state.entiteit && state.entiteit.id === id
        ? stateHandlers.updateStep(state)(1)
        : {
            ...initialState,
            stepIndex: 1,
            entiteit: find({ id })(entiteiten),
          };
    },
  selectKern:
    ({ state }) =>
    (selected, gegevenId = 0, veldIds) => {
      if (
        includes(gegevenId)(state.gegevenIds) === selected &&
        flown(
          veldIds,
          every((veldId) => includes(veldId)(state.veldIds) === selected)
        )
      ) {
        return state;
      }

      const updateSet = selected ? union : without;
      return {
        ...state,
        gegevenIds: updateSet([gegevenId])(state.gegevenIds),
        veldIds: updateSet(veldIds)(state.veldIds),
      };
    },
  selectVeld:
    ({ state }) =>
    (id, selected) =>
      includes(id)(state.veldIds) === selected
        ? state
        : {
            ...state,
            veldIds: selected
              ? [...state.veldIds, id]
              : without([id])(state.veldIds),
          },
  selectNewGegeven:
    ({ state }) =>
    (name, selected) =>
      includes(name)(state.newGegevens) === selected
        ? state
        : {
            ...state,
            newGegevens: selected
              ? [...state.newGegevens, name]
              : without([name])(state.newGegevens),
          },
  addNewGegeven:
    ({ state }) =>
    (name) => {
      const newGegevens = flown(
        [...state.newGegevens, ...name.split(splitChar)],
        map((v) => v.trim()),
        uniq,
        compact
      );
      return { ...state, newGegevens };
    },
};

export default compose(
  setDisplayName("GegevenDialog"),
  branch(({ open }) => !open, renderNothing),
  withState("state", "updateState", initialState),
  withHandlers({
    close:
      ({ closeDialog = empty.func }) =>
      (event) => {
        event.preventDefault();
        closeDialog();
      },
    save:
      ({
        closeDialog = empty.func,
        saveGegevens = empty.func,
        state: {
          entiteit: { id } = empty.object,
          gegevenIds,
          veldIds,
          newGegevens,
        } = empty.object,
      }) =>
      (event) => {
        event.preventDefault();
        saveGegevens({
          entiteitId: id,
          gegevenIds,
          veldIds,
          newGegevens,
        });
        closeDialog();
      },
    ...mapValues(
      (handler) =>
        ({ updateState, ...props }) =>
        (...args) =>
          updateState(Object.freeze(handler(props)(...args)))
    )(stateHandlers),
  }),
  withProps(({ state }) => ({ ...state })),
  execOnChange("init", "open")
)(GegevenDialog);
