import IconButton from "material-ui/IconButton";
import CommunicationChat from "material-ui/svg-icons/communication/chat";
import CommunicationChatBubbleOutline from "material-ui/svg-icons/communication/chat-bubble-outline";
import React, { memo, useCallback } from "react";

import { RemarkForm } from "./models";
import RemarkTooltipIcon from "./remarkTooltipIcon";

interface RemarkButtonProps {
  edit: boolean;
  hasStagedRemark: boolean;
  selectionId?: number;
  itemId?: number;
  externLinkId?: number;
  remark?: string;
  openDialog: (form?: RemarkForm) => void;
  style?: object;
}

const RemarkButton = memo<RemarkButtonProps>(
  ({
    edit,
    selectionId,
    itemId,
    externLinkId,
    remark,
    openDialog,
    hasStagedRemark,
    style,
  }) => {
    const combined: object = {
      display: "inline-block",
      position: "relative",
      margin: "0 16px",
      padding: 0,
      width: 24,
      height: 24,
      ...style,
    };

    const icon = remark ? (
      <CommunicationChat />
    ) : edit ? (
      <CommunicationChatBubbleOutline />
    ) : null;

    const handleClick = useCallback(() => {
      const form: RemarkForm = {
        selectionId,
        itemId,
        externLinkId,
        remark: remark || "",
      };
      openDialog(form);
    }, [externLinkId, itemId, openDialog, remark, selectionId]);

    if (!edit) {
      return remark ? (
        <RemarkTooltipIcon text={remark} style={combined}>
          {icon}
        </RemarkTooltipIcon>
      ) : (
        <span style={combined}>{icon}</span>
      );
    }

    return (
      <IconButton
        disabled={hasStagedRemark}
        style={combined}
        onClick={handleClick}
        title={remark ? "Opmerking aanpassen" : "Opmerking toevoegen"}
      >
        {icon}
      </IconButton>
    );
  }
);

export default RemarkButton;
