/* eslint  react/prop-types: off */
import empty from "empty";
import PropTypes from "prop-types";
import React from "react";
import { compose, defaultProps, setDisplayName, setPropTypes } from "recompose";

import { propTypes, withDefaultPageActions } from "./ggvtyp";

/**
 * Gegevenstype 'IproxGebruiker' (97)
 */
const IproxGebruiker = ({ value: { userName } = empty.object, className }) => (
  <span className={className}>{userName}</span>
);

const enhance = compose(
  setDisplayName("IproxGebruiker"),
  setPropTypes({
    ...propTypes,
    field: PropTypes.shape({
      value: PropTypes.shape({
        userName: PropTypes.string.isRequired,
      }),
    }).isRequired,
  }),
  defaultProps({
    className: "iproxGebruiker",
  }),
  withDefaultPageActions()
);

export default enhance(IproxGebruiker);
