import Checkbox from "material-ui/Checkbox";
import React, { useCallback } from "react";

interface SelectedCluster {
  pagClsIdt: number;
  checked: boolean;
}

interface ClusterCheckboxProps {
  id: number;
  onChange: (value: SelectedCluster) => void;
  label: string;
  checked: boolean;
  disabled: boolean;
}

const ClusterCheckbox = ({
  id,
  onChange,
  label,
  checked,
  disabled,
}: ClusterCheckboxProps) => {
  const handleCheck = useCallback(
    (_event: unknown, isInputChecked: boolean) => {
      onChange({ pagClsIdt: id, checked: isInputChecked });
    },
    [onChange, id]
  );

  return (
    <Checkbox
      key={id}
      value={id}
      label={label}
      onCheck={handleCheck}
      checked={checked}
      disabled={disabled}
    />
  );
};

interface ClusterSelectionItem {
  pagClsIdt: number;
  clsIdt: number;
  nam: string;
  lbl: string;
}

interface ClusterSelectionProps {
  clusters: ClusterSelectionItem[];
  selection: number[];
  onChange: (pagClsIdts: number[]) => void;
  disabled: boolean;
}

const emptyClusterSelection: number[] = [];
const ClusterSelection = ({
  clusters,
  selection = emptyClusterSelection,
  onChange,
  disabled,
}: ClusterSelectionProps) => {
  const handleCheck = useCallback(
    ({ pagClsIdt, checked }: { pagClsIdt: number; checked: boolean }) => {
      const newValue = checked
        ? [...selection, pagClsIdt]
        : selection.filter((i) => i !== pagClsIdt);
      onChange(newValue);
    },
    [selection, onChange]
  );

  if (clusters.length === 0) {
    return <>Er zijn geen clusters te kiezen</>;
  }

  return (
    <>
      {clusters.map(({ pagClsIdt, lbl }) => (
        <ClusterCheckbox
          id={pagClsIdt}
          key={pagClsIdt}
          label={lbl}
          onChange={handleCheck}
          checked={selection.includes(pagClsIdt)}
          disabled={disabled}
        />
      ))}
    </>
  );
};

export default ClusterSelection;
