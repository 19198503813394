/* eslint  react/prop-types: off */
import empty from "empty";
import {
  entries,
  flatten,
  flow,
  get,
  groupBy,
  head,
  map,
  some,
  sortBy,
} from "lodash/fp";
import { MenuItem, SelectField } from "material-ui";
import React, { useMemo, useState } from "react";

import {
  AddGegeven,
  GegevenDialog,
} from "../../../../containers/pagetypes/koppeling/gegevens";
import { isNonEmptyArray } from "../../../../utils";
import Heading from "../../../content/Heading";
import CopyClusterLink from "../../../material/CopyClusterLink";
import HintCard from "../../../material/HintCard";
import StickOnScroll from "../../../utils/StickOnScroll";
import icons from "../../icons";
import {
  collapse,
  entiteitNames,
  gegevenNames,
  getEntiteit,
  getEntiteitLeft,
  getEntiteitRight,
  getGegevenLeft,
  getGegevenRight,
  getSets,
  getSubName,
  koppelingIsReady,
  renderStartProps,
} from "../../koppeling/functions";
import KoppelingFieldList from "../../koppeling/koppelingFields";

const hasVelden = flow(
  get("gegevens"),
  some(flow(get("velden"), isNonEmptyArray))
);

const renderKoppelingGegevens = (props) => {
  const {
    page,
    pageActions: { edit } = empty.object,
    koppelingFields,
    applications,
  } = props;

  return edit ||
    (koppelingIsReady(koppelingFields, applications) &&
      page.inhoud.gegeven.clusters.length > 0) ? (
    <KoppelingGegevens {...props} />
  ) : null;
};

const GroupingSelect = ({ onChange, value, left, right }) => (
  <SelectField
    style={{ marginLeft: 20, width: "25em" }}
    value={value}
    onChange={(_o, _e, v) => onChange(v)}
    floatingLabelText="Groepering"
  >
    <MenuItem value="default" primaryText="Naar entiteiten" />
    <MenuItem value="ent-left" primaryText={`Naar entiteit ${left}`} />
    <MenuItem value="ent-right" primaryText={`Naar entiteit ${right}`} />
    <MenuItem value="ggv-left" primaryText={`Naar applicatiegegeven ${left}`} />
    <MenuItem
      value="ggv-right"
      primaryText={`Naar applicatiegegeven ${right}`}
    />
  </SelectField>
);

const getGrouping = (grouping) => {
  switch (grouping) {
    case "ent-left":
      return getEntiteitLeft;
    case "ent-right":
      return getEntiteitRight;
    case "ggv-left":
      return getGegevenLeft;
    case "ggv-right":
      return getGegevenRight;
    case "default":
    default:
      return getEntiteit;
  }
};

const KoppelingGegevens = ({
  getContent,
  id,
  pageActions: { edit } = empty.object,
  koppelingFields,
  koppelingVeldenRelations,
  applications,
  page,
}) => {
  const [grouping, setGrouping] = useState("default");
  const canAddGegeven =
    Boolean(koppelingFields) &&
    hasVelden(koppelingFields.from) &&
    hasVelden(koppelingFields.to);

  const [
    hasModuleVan,
    hasZibVan,
    hasVerantwoordingVan,
    hasVerwerkingVan,
    hasEntiteitVan,
    hasKoppelingVan,
  ] = useMemo(
    () => getSets(applications, koppelingVeldenRelations, "van", id),
    [applications, id, koppelingVeldenRelations]
  );

  const [
    hasModuleNar,
    hasZibNar,
    hasVerantwoordingNar,
    hasVerwerkingNar,
    hasEntiteitNar,
    hasKoppelingNar,
  ] = useMemo(
    () => getSets(applications, koppelingVeldenRelations, "nar", id),
    [applications, id, koppelingVeldenRelations]
  );

  return [
    !koppelingIsReady(koppelingFields, applications) && (
      <p>
        <em>Gegevensoverdracht niet beschikbaar</em>
      </p>
    ),
    edit &&
      (canAddGegeven ? (
        <AddGegeven key="add-gegeven" />
      ) : (
        <HintCard
          key="add-gegeven"
          primaryText="Er zijn geen velden beschikbaar om een gegevensoverdracht toe te voegen."
          secondaryText="Zorg dat er een relatie is met bron en doel applicaties en dat deze gegevens en velden bevatten."
        />
      )),
    koppelingIsReady(koppelingFields, applications) &&
      page.inhoud.gegeven.clusters.length > 0 && (
        <StickOnScroll key="Heading" main>
          <div
            style={{
              backgroundColor: "#dcedc8",
              display: "flex",
              justifyContent: "space-between",
              paddingRight: 20,
            }}
          >
            <Heading>{applications.from.page.title}</Heading>
            <Heading>{applications.to.page.title}</Heading>
          </div>
        </StickOnScroll>
      ),
    <div className="koppeling-gegevens" key="koppeling-gegevens">
      {getContent({
        path: "inhoud.gegeven",
        omitTitle: true,
        omitAddButton: true,
        show: () => koppelingIsReady(koppelingFields, applications),
        collapse,
        sort: flow(
          groupBy((cluster) =>
            getGrouping(grouping)(
              cluster,
              koppelingFields,
              koppelingVeldenRelations,
              applications
            )
          ),
          entries,
          sortBy(head),
          map(([heading, clusters]) =>
            clusters.map((cluster, i) =>
              i === 0
                ? { ...cluster, textEnd: /-right/.test(grouping), heading }
                : cluster
            )
          ),
          flatten
        ),
        headerTool: (
          <GroupingSelect
            value={grouping}
            onChange={setGrouping}
            left={applications?.from?.page?.title ?? "links"}
            right={applications?.to?.page?.title ?? "rechts"}
          />
        ),
        renderStart: (...args) => {
          const startProps = renderStartProps(...args);
          return startProps && <KoppelingFieldList {...startProps} />;
        },
        renderEnd: ({ clusterId }) =>
          edit && (
            <AddGegeven
              pageClusterId={clusterId}
              koppelingVeldenRelations={koppelingVeldenRelations}
            />
          ),
        titleRenderer: (title, clusterId) => {
          const [left, right] = title.split(" \u00a0>\u00a0 ");
          const subEntiteit = grouping.startsWith("ggv");
          const getNamesLeft = subEntiteit
            ? entiteitNames(applications.from)
            : gegevenNames(applications.from);
          const subLeft = getSubName(
            koppelingVeldenRelations[clusterId],
            "van",
            getNamesLeft,
            subEntiteit ? "entiteit" : "gegeven",
            subEntiteit ? "entiteiten" : "gegevens"
          );
          const getNamesRight = subEntiteit
            ? entiteitNames(applications.to)
            : gegevenNames(applications.to);
          const subRight = getSubName(
            koppelingVeldenRelations[clusterId],
            "nar",
            getNamesRight,
            subEntiteit ? "entiteit" : "gegeven",
            subEntiteit ? "entiteiten" : "gegevens"
          );
          return (
            <span className="spread">
              <span className="spread">
                <span>
                  {left}
                  <br />
                  <small>{subLeft}</small>
                </span>
                <span
                  style={{
                    display: "inline-block",
                    marginLeft: "1em",
                    verticalAlign: "middle",
                    opacity: 0.7,
                    textAlign: "right",
                  }}
                >
                  {hasKoppelingVan.has(clusterId) && (
                    <span title="Heeft andere koppeling">
                      <icons.koppeling />
                    </span>
                  )}
                  {hasEntiteitVan.has(clusterId) && (
                    <span title="Heeft entiteit">
                      <icons.entiteit />
                    </span>
                  )}
                  {hasVerwerkingVan.has(clusterId) && (
                    <span title="Heeft proces">
                      <icons.verwerking />
                    </span>
                  )}
                  {hasVerantwoordingVan.has(clusterId) && (
                    <span title="Heeft verantwoording">
                      <icons.verantwoording />
                    </span>
                  )}
                  {hasZibVan.has(clusterId) && (
                    <span title="Heeft zib">
                      <icons.zib />
                    </span>
                  )}
                  {hasModuleVan.has(clusterId) && (
                    <span title="Heeft module">
                      <icons.module />
                    </span>
                  )}
                </span>
              </span>
              <CopyClusterLink clusterId={clusterId} />
              <span className="spread">
                <span
                  style={{
                    display: "inline-block",
                    marginRight: "1em",
                    verticalAlign: "middle",
                    opacity: 0.7,
                  }}
                >
                  {hasModuleNar.has(clusterId) && (
                    <span title="Heeft module">
                      <icons.module />
                    </span>
                  )}
                  {hasZibNar.has(clusterId) && (
                    <span title="Heeft zib">
                      <icons.zib />
                    </span>
                  )}
                  {hasVerantwoordingNar.has(clusterId) && (
                    <span title="Heeft verantwoording">
                      <icons.verantwoording />
                    </span>
                  )}
                  {hasVerwerkingNar.has(clusterId) && (
                    <span title="Heeft proces">
                      <icons.verwerking />
                    </span>
                  )}
                  {hasEntiteitNar.has(clusterId) && (
                    <span title="Heeft entiteit">
                      <icons.entiteit />
                    </span>
                  )}
                  {hasKoppelingNar.has(clusterId) && (
                    <span title="Heeft andere koppeling">
                      <icons.koppeling />
                    </span>
                  )}
                </span>
                <span className="text-end">
                  {right}
                  <br />
                  <small>{subRight}</small>
                </span>
              </span>
            </span>
          );
        },
        omitCopyLink: true,
        koppelingFields,
        koppelingVeldenRelations,
        applications,
      })}
    </div>,
    edit && (
      <GegevenDialog
        key="gegeven-dialog"
        itemId={id}
        koppelingVeldenRelations={koppelingVeldenRelations}
        from={applications.from}
        to={applications.to}
      />
    ),
  ];
};

export default renderKoppelingGegevens;
