import Popover from "material-ui/Popover";
import RaisedButton from "material-ui/RaisedButton";
import HelpIcon from "material-ui/svg-icons/action/help";
import React, {
  MouseEvent,
  ReactInstance,
  memo,
  useCallback,
  useState,
} from "react";

interface ContactState {
  open: boolean;
  anchorEl?: ReactInstance;
}

const ContactWidget = () => {
  const [{ open, anchorEl }, setOpen] = useState<ContactState>({
    open: false,
    anchorEl: undefined,
  });
  const handleClick = useCallback((event: MouseEvent) => {
    setOpen({ open: true, anchorEl: event.currentTarget });
  }, []);
  const handleClose = useCallback(() => {
    setOpen({ open: false, anchorEl: undefined });
  }, []);

  return (
    <div className="contact-widget">
      <RaisedButton
        onClick={handleClick}
        target="_blank"
        label="Help"
        primary
        icon={<HelpIcon />}
        className="contact-widget-button"
      />

      <Popover
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: "left", vertical: "top" }}
        targetOrigin={{ horizontal: "left", vertical: "bottom" }}
        onRequestClose={handleClose}
      >
        <div className="contact-widget-popover">
          <h2>Hulp nodig?</h2>
          <p>
            Voor vragen kunt u mailen naar{" "}
            <a href="mailto:infopunt@infozorg.nl">infopunt@infozorg.nl</a>
          </p>
        </div>
      </Popover>
    </div>
  );
};

export default memo(ContactWidget);
