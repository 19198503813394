/* eslint  react/prop-types: off */
import React from "react";

import Verantwoording from "../../process/verantwoording";

const renderProcessVerantwoording = ({ verantwoording, biebStructures }) =>
  verantwoording ? (
    <Verantwoording
      key="process-verantwoording"
      {...verantwoording}
      biebStructures={biebStructures}
    />
  ) : null;

export default renderProcessVerantwoording;
