import { map } from "lodash/fp";
import { List, ListItem } from "material-ui/List";
import React, { ReactElement, ReactNode } from "react";

import { flown } from "../../lodash";

interface Value {
  toString(): string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface Option<V extends Value = Value> {
  children?: this[];
}

interface Props<T extends Option<V>, V extends Value = Value> {
  value?: V;
  options: T[];
  getValue(option: T): V;
  getSelectable(option: T): boolean;
  renderOptionIcon(option: T): ReactElement;
  renderOptionLabel(option: T): ReactNode;
  onClick(value: V, selected: boolean): void;
}

type OptionPredicate<T extends Option<V>, V extends Value = Value> = (
  option: T,
  predicate: OptionPredicate<T, V>
) => boolean;

const optionPredicate =
  <T extends Option<V>, V extends Value = Value>(
    getValue: (option: T) => V,
    value: V
  ): OptionPredicate<T, V> =>
  (option: T, predicate: OptionPredicate<T, V>): boolean =>
    getValue(option) === value ||
    (option.children !== undefined &&
      option.children.some((child) => predicate(child, predicate)));

const containsValue = <T extends Option<V>, V extends Value = Value>(
  getValue: (option: T) => V,
  value: V | undefined
): ((option: T) => boolean) => {
  if (value === undefined) {
    return () => false;
  }

  const predicate = optionPredicate(getValue, value);
  return (v) => predicate(v, predicate);
};

type RenderItems<T extends Option<V>, V extends Value = Value> = (
  options: T[],
  level: number,
  renderer: RenderItems<T, V>
) => JSX.Element[];

const renderItems =
  <T extends Option<V>, V extends Value = Value>(
    getValue: (option: T) => V,
    getSelectable: (option: T) => boolean,
    containsSelectedValue: (option: T) => boolean,
    renderOptionIcon: (option: T) => ReactElement,
    renderOptionLabel: (option: T) => ReactNode,
    onClick: (value: V, selected: boolean) => void,
    selectedValue: V | undefined
  ): RenderItems<T, V> =>
  (options, level, renderer): JSX.Element[] =>
    flown(
      options,
      map((option: T): JSX.Element => {
        const value = getValue(option);
        const selectable = getSelectable(option);
        const selected = selectable && value === selectedValue;
        return (
          <ListItem
            initiallyOpen={containsSelectedValue(option)}
            aria-selected={selected}
            style={{
              backgroundColor: selected ? "rgba(0, 0, 0, 0.1)" : undefined,
              cursor: selectable ? "pointer" : "not-allowed",
            }}
            nestedLevel={level}
            value={value}
            key={value.toString()}
            leftIcon={renderOptionIcon(option)}
            primaryText={renderOptionLabel(option)}
            onClick={() => {
              onClick(value, selectable && value !== selectedValue);
            }}
            nestedItems={
              option.children && renderer(option.children, level + 1, renderer)
            }
          />
        );
      })
    );

const TreePicker = <T extends Option<V>, V extends Value = Value>({
  value,
  options,
  getValue,
  getSelectable,
  renderOptionIcon,
  renderOptionLabel,
  onClick,
}: Props<T, V>): JSX.Element => {
  const containsSelectedValue = containsValue(getValue, value);
  const renderer: RenderItems<T> = renderItems(
    getValue,
    getSelectable,
    containsSelectedValue,
    renderOptionIcon,
    renderOptionLabel,
    onClick,
    value
  );
  return (
    <List style={{ width: "100%" }}>{renderer(options, 0, renderer)}</List>
  );
};

export default TreePicker;
