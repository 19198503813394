import { Card } from "material-ui/Card";

import {
  InternetValue,
  PlainValue,
  SelectionValue,
} from "../../business/models";
import ClusterCard from "../material/clusterCard";

interface EmbedCluster {
  clusterId: number;
  systeem: { value: SelectionValue };
  embedURL: { value: InternetValue };
  hoogte: { value: PlainValue };
  filter: { value: PlainValue };
  tabelnaamVoorFilter: { value: PlainValue };
  kolomnaamVoorFilter: { value: PlainValue };
}

export const NoEmbedAccount = () => (
  <ClusterCard className="cluster-card">
    <p>
      U heeft nog geen account voor deze functionaliteit. Dit kunt u aanvragen
      via de "Help" knop.
    </p>
  </ClusterCard>
);

export interface EmbedProps {
  cluster: EmbedCluster;
  miniSaar: { name: string };
}

const withFilter = (
  address: string,
  table: string,
  column: string,
  miniSaar: string
) => {
  const filters = [
    {
      jaql: {
        dim: `[${table}.${column}]`,
        datatype: "text",
        collapsed: false,
        title: column,
        filter: {
          explicit: true,
          userMultiSelect: false,
          multiSelection: false,
          members: [miniSaar.replace(/'/g, "")],
        },
      },
    },
  ];

  const separator = address.includes("?") ? "&" : "?";
  return `${address}${separator}filter=${encodeURIComponent(
    JSON.stringify(filters)
  )}`;
};

export const Embed = ({
  cluster: {
    clusterId,
    systeem: { value: systeem },
    embedURL: { value: embedURL },
    hoogte: { value: height },
    tabelnaamVoorFilter: { value: table },
    kolomnaamVoorFilter: { value: column },
  },
  miniSaar: { name: miniSaar },
}: EmbedProps) => {
  return (
    <Card className="cluster-card" key={clusterId}>
      <iframe
        title={systeem.value}
        width="100%"
        height={height ?? 480}
        frameBorder="0"
        src={
          table && column
            ? withFilter(embedURL.address, table, column, miniSaar)
            : embedURL.address
        }
      />
    </Card>
  );
};
