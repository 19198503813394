/* eslint  react/prop-types: off */
import empty from "empty";
import React from "react";

import { ItemLink } from "./relation.itemlink";

export const extraFromUsing = (using, linkProps, biebStructure) => {
  const extra = using.map((u, i) => (
    <span key={u.pageClusterId || u.itemId} className="extra-from-using">
      {i > 0 && ", "}
      <ItemLink key={i} {...linkProps} item={u} />
    </span>
  ));
  if (biebStructure) {
    if (extra.length > 0) {
      extra.push(", ");
    }
    extra.push(
      <span key={using.length} className="extra-from-using">
        {biebStructure.name}
      </span>
    );
  }
  return extra;
};

export const defaultLabelRender = (
  { label, pageClusterLabel, miniSaar } = empty.object,
  { clusterFirst = false } = empty.object
) => {
  const fullLabel = pageClusterLabel
    ? clusterFirst
      ? `${pageClusterLabel} (${label})`
      : `${label} (${pageClusterLabel})`
    : label;
  return typeof miniSaar === "object" ? (
    <span>
      <span
        style={{
          float: "right",
          marginLeft: "1em",
          maxWidth: "30%",
          overflow: "hidden",
        }}
      >
        {miniSaar.name}
      </span>
      {fullLabel}
    </span>
  ) : (
    fullLabel
  );
};

export const remove = (removeRelation, id, itemId, usingItemId) => (e) => {
  e.preventDefault();
  removeRelation(id, itemId, usingItemId);
};

export const followPrimary = (onClick) => (e) => {
  if (
    !e.defaultPrevented &&
    !e.propagationStopped &&
    !("href" in e.target) &&
    !("href" in e.target.dataset)
  ) {
    e.stopPropagation();
    if (typeof onClick === "function") {
      onClick(e);
    }

    if (e.defaultPrevented) {
      return;
    }

    const a = e.currentTarget.querySelector("a[href]");
    if (a) {
      a.click();
    }
  }
};
