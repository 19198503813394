import empty from "empty";
import { entries, filter, fromPairs, identity, isEqual, map } from "lodash/fp";
import { withRouter } from "react-router";
import {
  compose,
  defaultProps,
  mapProps,
  pure,
  setDisplayName,
  withHandlers,
  withProps,
  wrapDisplayName,
} from "recompose";
import { createSelector } from "reselect";

import { updateQueryAction } from "../actions/search";
import { toQueryString } from "../api/fetch";
import { onDeselect, onReset, onSelect } from "../components/list/facets";
import {
  currentFacets,
  orderedPropsAndRows,
  parseFacet,
  stringifyFacet,
} from "../components/list/selectors";
import { flown } from "../lodash";
import { execOnChange } from "../recompose.contrib";
import { selectionListsByPagetype } from "../selectors/definition";
import connectQuery from "../selectors/list";
import { saarHistory } from "../store";
import connect from "./connect";

const addBron = createSelector(identity, (properties = empty.array) => [
  ...properties,
  "saar",
]);

const defaults = Object.freeze({
  isSearch: false,
  properties: Object.freeze(["titel", "datum", "pagetype"]),
});

const isFacetProp = (name) => name.includes(":");

const enhanceWithFacets = () => {
  const hoc = compose(
    setDisplayName("enhanceWithFacets"),
    withRouter,
    pure,
    mapProps(({ listColumns, ...rest }) => ({
      properties: listColumns,
      ...rest,
    })),
    defaultProps(defaults),
    setDisplayName("WithBronAndQuery"),
    mapProps(({ properties, searchQuery = empty.object, ...rest }) => ({
      properties: addBron(properties),
      searchQuery,
      ...rest,
    })),
    setDisplayName("SetUid"),
    withProps(({ id: uid }) => ({ uid })),
    setDisplayName("ConnectFilters"),
    connect(
      connectQuery,
      (
        dispatch,
        {
          setFilter,
          setColumnFilter,
          uid,
          location: { query, pathname } = empty.object,
        }
      ) => ({
        setFilter:
          typeof setFilter === "function"
            ? setFilter
            : (value) => dispatch(updateQueryAction(uid, "filter", value)),

        setColumnFilter:
          typeof setColumnFilter === "function"
            ? setColumnFilter
            : (property, value) => {
                dispatch(updateQueryAction(uid, `column-${property}`, value));
              },

        updateSelections: (value) => {
          dispatch(updateQueryAction(uid, "selections", value));
        },

        setSelections: (value) => {
          // Set filter parameters in the URL
          const fromQuery = flown(
            query,
            entries,
            filter((a) => !isFacetProp(a[0])),
            fromPairs
          );
          const fromValue = flown(
            value,
            entries,
            map(([k, v]) => [
              stringifyFacet(k),
              v.map(stringifyFacet).join(","),
            ]),
            fromPairs
          );

          // redirect
          const nextQuery = { ...fromQuery, ...fromValue };
          if (!isEqual(nextQuery, query)) {
            saarHistory.replace(`${pathname}?${toQueryString(nextQuery)}`);
            if (value.size === 0) {
              dispatch(updateQueryAction(uid, "selections", value));
            }
          }
        },

        setOrder: (value) => dispatch(updateQueryAction(uid, "order", value)),
      })
    ),
    execOnChange(
      ({ location: { query: { sort } } = empty.object, setOrder }) => {
        if (sort === "recent") {
          setOrder({ property: 1, descending: true });
        }
      },
      ({ location: prev }, { location: next }) =>
        prev.query.sort !== next.query.sort || prev.path !== next.path
    ),
    execOnChange(
      ({
        updateSelections,
        setSelections,
        location: { query } = empty.object,
        selections,
      }) => {
        const keys = Object.keys(query).filter(isFacetProp);
        if (keys.length === 0 && selections.size > 0) {
          // Sync querystring with previously made selections
          setSelections(selections);
          return;
        }

        const nextSelections = new Map();
        for (const k of keys) {
          nextSelections.set(
            parseFacet(k),
            query[k].split(",").map(parseFacet)
          );
        }

        // set the new filter selection
        if (!isEqual(selections, nextSelections)) {
          updateSelections(nextSelections);
        }
      },
      (prev, next) => !isEqual(prev.location.query, next.location.query)
    ),
    setDisplayName("ConnectListsByPagetype"),
    connect((state, props) => ({
      listsByPagetype: selectionListsByPagetype(state, props),
    })),
    setDisplayName("WithDocumentsAsList"),
    withProps(({ selection: { documents = empty.array } = empty.object }) => ({
      list: documents,
    })),
    setDisplayName("WithRowsAndFacets"),
    withProps((props) => ({
      facets: currentFacets(props),
      ...orderedPropsAndRows(props),
    })),
    setDisplayName("WithSelectHandlers"),
    withHandlers({
      onSelect: ({ selections, setSelections }) =>
        onSelect(selections, setSelections),
      onDeselect: ({ selections, setSelections }) =>
        onDeselect(selections, setSelections),
      onReset: ({ setSelections }) => onReset(setSelections),
    })
  );

  if (process.env.NODE_ENV !== "production") {
    return (BaseComponent) =>
      setDisplayName(wrapDisplayName(BaseComponent, "enhanceWithFacets"))(
        hoc(BaseComponent)
      );
  }

  return hoc;
};

export default enhanceWithFacets;
