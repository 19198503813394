import empty from "empty";
import { head } from "lodash/fp";
import { Step, StepButton, StepContent } from "material-ui/Stepper";
import PropTypes from "prop-types";
import React, { useCallback } from "react";

import { clustersForRelationType } from "../../business/prop-types";
import PageRefreshIndicator from "../pagetypes/PageRefreshIndicator";
import ClusterSelection from "./clusters/ClusterSelection";
import ItemsPicker from "./itemsPicker";

const ClustersSteps = ({
  types: { peertype, peerLabel } = empty.object,
  peer,
  using,
  form,
  setStepIndex,
  toggleStatus,
  toggleFilter,
  clusters,
  updateClusterIds,
  clustersLoading,
}) => {
  const stepOneSelection =
    form.clusterIds && form.clusterIds.length > 0
      ? clusters
          .filter(({ pagClsIdt }) => form.clusterIds.includes(pagClsIdt))
          .map(({ lbl }) => lbl)
          .join("; ")
      : undefined;
  const stepOneLabel = head(clusters)?.nam ?? "Clusters";

  const handleChange = useCallback(
    (pagClsIdts) => {
      updateClusterIds(pagClsIdts);
    },
    [updateClusterIds]
  );

  return [
    <Step key={0}>
      <StepButton onClick={() => setStepIndex(0)}>
        <span>{stepOneLabel} kiezen</span>
        {stepOneSelection && <span>: {stepOneSelection}</span>}
      </StepButton>
      <StepContent>
        {clustersLoading ? (
          <PageRefreshIndicator />
        ) : (
          <ClusterSelection
            clusters={clusters}
            selection={form.clusterIds}
            onChange={handleChange}
            disabled={Boolean(form.disableStep1)}
          />
        )}
      </StepContent>
    </Step>,
    <Step key={1}>
      <StepButton onClick={() => setStepIndex(1)}>
        <span>{peerLabel}</span>
      </StepButton>
      <StepContent>
        <ItemsPicker
          {...peer}
          toggleStatus={toggleStatus}
          toggleFilter={using.specificItemId ? toggleFilter : undefined}
          pagetype={peertype}
        />
      </StepContent>
    </Step>,
  ];
};

ClustersSteps.propTypes = Object.freeze({
  // eslint-disable-next-line react/forbid-prop-types
  types: PropTypes.any,
  // eslint-disable-next-line react/forbid-prop-types
  peer: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  using: PropTypes.shape({ specificItemId: PropTypes.number }),
  // eslint-disable-next-line react/forbid-prop-types
  form: PropTypes.any,
  // eslint-disable-next-line react/forbid-prop-types
  handleNextStep: PropTypes.any,
  // eslint-disable-next-line react/forbid-prop-types
  setStepIndex: PropTypes.any,
  toggleStatus: PropTypes.bool.isRequired,
  toggleFilter: PropTypes.func.isRequired,
  clusterRequired: PropTypes.bool,
  clusters: PropTypes.arrayOf(clustersForRelationType),
});

export default ClustersSteps;
