import empty from "empty";
import {
  branch,
  compose,
  pure,
  renderNothing,
  setDisplayName,
  withHandlers,
  withProps,
  withState,
} from "recompose";

import { addToBiebAction, addToBiebDialogAction } from "../../actions/bieb";
import BiebAddDialog from "../../components/bieb/biebAddDialog";
import connect from "../connect";

export default compose(
  setDisplayName("BiebAddDialog"),
  pure,
  connect(
    ({
      form: { biebAddDialog: { open = false, busy = false } = empty.object },
      data: { addLegoblok: { structures = empty.array } = empty.object },
    }) => ({ structures, open, busy }),
    (dispatch) => ({
      close: () => {
        dispatch(addToBiebDialogAction(false));
      },

      create: (newBiebItem) => {
        dispatch(addToBiebDialogAction(false));
        dispatch(addToBiebAction(newBiebItem));
      },
    })
  ),
  branch(({ open }) => !open, renderNothing),
  withState(
    "newBiebItem",
    "updateBiebItem",
    ({ page: { id, title } = empty.object }) => ({
      label: title,
      sourceItemId: id,
      moveItem: false, // standaard 'Kopieer pagina' [copy]
    })
  ),
  withProps(
    ({
      newBiebItem: { siteId, root, sourceItemId, label } = empty.object,
    }) => ({
      valid: siteId && root && sourceItemId && label,
    })
  ),
  withHandlers({
    updateLabel:
      ({ newBiebItem, updateBiebItem }) =>
      ({ target: { value } = empty.object }) =>
        updateBiebItem({ ...newBiebItem, label: value }),

    updateSite:
      ({ structures, newBiebItem, updateBiebItem }) =>
      (event, index, value) =>
        updateBiebItem({
          ...newBiebItem,
          siteId: value,
          root: structures[index].root,
        }),

    updateMoveItem:
      ({ newBiebItem, updateBiebItem }) =>
      (_, value) =>
        updateBiebItem({ ...newBiebItem, moveItem: value === "move" }),

    createBiebItem:
      ({ create, newBiebItem }) =>
      (event) => {
        event.preventDefault();
        create(newBiebItem);
      },
  })
)(BiebAddDialog);
