import { IconButton } from "material-ui";

import Excel from "../../svg/excel";
import { PathLink } from "../relation.pathlink";

export interface ExcelButtonProps {
  biebColor: string;
  biebBackgroundColor: string;
}

const ExcelButton = ({ biebBackgroundColor, biebColor }: ExcelButtonProps) => (
  <div style={{ lineHeight: "48px" }}>
    <IconButton
      iconStyle={{ color: biebColor }}
      style={{
        backgroundColor: biebBackgroundColor,
        borderWidth: 5,
        borderStyle: "solid",
        borderColor: biebBackgroundColor,
        borderRadius: 5,
        padding: 0,
        width: 34,
        display: "inline-block",
        height: 34,
        overflow: "hidden",
        verticalAlign: "middle",
        marginRight: 8,
      }}
      containerElement={<PathLink path="overzicht" />}
    >
      <Excel color={biebColor} />
    </IconButton>
  </div>
);

export default ExcelButton;
