/* eslint  react/prop-types: off */
import empty from "empty";
import { flatten, flow, map, omit, uniqBy } from "lodash/fp";
import React from "react";
import { compose, mapProps, pure, setDisplayName } from "recompose";

import { Side, shownRelations } from "../../../../business/relations";
import { RelationListWrapper, RelationsList } from "../../../relations";

const applicationTypes = [
  { alias: "kerngegeven_applicatiegegeven", side: Side.left },
  { alias: "kerngegeven_applicatieveld", side: Side.left },
  { alias: "entiteit_applicatiegegeven", side: Side.left },
  { alias: "entiteit_applicatieveld", side: Side.left },
];

const ApplicationRelationsComponent = ({ itemId, relations, miniSaars }) => (
  <RelationListWrapper sideBarList>
    <RelationsList
      itemId={itemId}
      type="entiteit_applicatie"
      side={1}
      label="Komt voor in applicatie"
      list={relations}
      miniSaars={miniSaars}
      showHeader
    />
  </RelationListWrapper>
);

const ApplicationRelations = compose(
  setDisplayName("ApplicationRelations"),
  pure,
  mapProps(({ relations = empty.array, types = empty.array, ...rest }) => ({
    ...rest,
    relations: flow(
      map(({ links = empty.array }) => links),
      flatten,
      map(
        omit(["pageClusterId", "item.pageClusterId", "item.pageClusterLabel"])
      ),
      uniqBy("item.itemId")
    )(shownRelations(relations, types)(applicationTypes)),
  }))
)(ApplicationRelationsComponent);

const renderApplicationRelations = ({
  itemId: id,
  relations,
  miniSaars,
  types,
}) => (
  <ApplicationRelations
    key="app-rels"
    itemId={id}
    relations={relations}
    types={types}
    miniSaars={miniSaars}
  />
);

export default renderApplicationRelations;
