import { composeAction } from "async-lifecycle";
import empty from "empty";
import { has } from "lodash/fp";

import {
  changePassword,
  checkLogin,
  getAzureAdUrl,
  getOffice365Info,
  getOfficeUrl,
  login,
  loginWithAzureAd,
  loginWithGoogle,
  loginWithOffice,
  requestPassword,
} from "../api";
import {
  addAuthenticationResult,
  isExternalUser,
  isOfficeUser,
} from "../api/auth";
import { fromStorage } from "../api/auth.common";
import { resetSession } from "../api/fetch";
import { sagas } from "../sagas";
import { redirectAction } from "./common";
import {
  requireMiniSaarsAction,
  requireNavigationAction,
  requireRelationTypesAction,
} from "./data";
import { startBiebJobWatcher, startPageJobWatcher } from "./utils";

const clearMiniSaarsAction = () => ({
  type: "MINISAARS_CLEAR",
  payload: empty.object,
});

const clearNavigationAction = () => ({
  type: "NAVIGATION_CLEAR",
  payload: empty.object,
});

const clearRelationTypesAction = () => ({
  type: "RELATIONTYPES_CLEAR",
  payload: empty.object,
});

const requireOffice365InfoAction = () =>
  composeAction({
    group: "OFFICEINFO",
    fire: getOffice365Info,
  });

const clearOffice365InfoAction = () => ({
  type: "OFFICEINFO_CLEAR",
  payload: empty.object,
});

const loginNavigationCallbacks = {
  callbackAfter: (payload, dispatch) => {
    if (
      !payload ||
      (payload && !payload.user) ||
      (payload && payload.user && !payload.user.id)
    ) {
      // inloggen is niet goed gegaan...
      dispatch(clearMiniSaarsAction());
      dispatch(clearNavigationAction());
      dispatch(clearOffice365InfoAction());
    } else {
      dispatch(requireMiniSaarsAction());
      dispatch(requireNavigationAction());
      if (fromStorage("saar_loginsource") === "office365") {
        dispatch(requireOffice365InfoAction());
      }

      /* Get Sisense JWT-url */
      dispatch({ type: sagas.sisenseLogin.require });
      startBiebJobWatcher(dispatch, true);
      startPageJobWatcher(dispatch, true);
    }
  },
  callbackError: (dispatch) => {
    dispatch(clearMiniSaarsAction());
    dispatch(clearNavigationAction());
  },
};

const loginRelationTypesCallbacks = {
  callbackAfter: (payload, dispatch) => {
    if (
      !payload ||
      (payload && !payload.user) ||
      (payload && payload.user && !payload.user.id)
    ) {
      // inloggen is niet goed gegaan...
      dispatch(clearRelationTypesAction());
    } else {
      dispatch(requireRelationTypesAction());
    }
  },
  callbackError: (dispatch) => dispatch(clearRelationTypesAction()),
};

export const loginInvalidateAction = () => ({
  type: "LOGIN_INVALIDATE",
  payload: empty.object,
});

export const loginAction = (user) =>
  composeAction(
    {
      group: "LOGIN",
      fire: (user) => login(user),
      args: [user],
      container: "session",
      callbackAfter: (payload) => {
        if (
          has("user.id")(payload) ||
          has("user.changePassword")(payload) ||
          has("possession")(payload) ||
          has("possessionInvalid")(payload)
        ) {
          return;
        }

        // inloggen is niet goed gegaan...
        // eslint-disable-next-line
        throw {
          status: 401,
          message: "Gebruikersnaam of wachtwoord niet correct.",
        };
      },
    },
    loginNavigationCallbacks,
    loginRelationTypesCallbacks
  );

export const checkLoginAction = () =>
  composeAction(
    {
      group: "CHECKLOGIN",
      fire: () => checkLogin(),
      container: "session",
    },
    loginNavigationCallbacks,
    loginRelationTypesCallbacks
  );

export const logoutAction =
  (externalLogoutUrl, sisenseLogout) => (dispatch) => {
    const externalUser = isOfficeUser() || isExternalUser();
    dispatch(clearMiniSaarsAction());
    dispatch(clearNavigationAction());
    resetSession();
    dispatch({
      type: "LOGOUT",
    });

    if (sisenseLogout) {
      const iframe = document.getElementById("sisense_jwt");
      iframe.src = sisenseLogout;
    }

    global.location.href = externalUser ? externalLogoutUrl || "/" : "/";
  };

export const passwordAction = (model) =>
  composeAction({
    group: "CHANGEPASSWORD",
    fire: changePassword,
    args: [model],
  });

export const requestPasswordAction = (emailaddress) =>
  composeAction({
    group: "REQUEST_PASSWORD",
    fire: (emailaddress) => requestPassword(emailaddress),
    args: [emailaddress],
    callbackSuccess: (_, dispatch) => {
      dispatch(redirectAction("/inloggen"));
    },
  });

export const getOfficeUrlAction = () =>
  composeAction({
    group: "OFFICEURL",
    fire: getOfficeUrl,
  });

export const loginWithOfficeAction = (code) =>
  composeAction({
    group: "OFFICELOGIN",
    fire: loginWithOffice,
    args: [code],
    callbackSuccess: (payload, dispatch) => {
      addAuthenticationResult(payload, true);
      dispatch(redirectAction("/inloggen"));
    },
    callbackError: (dispatch) => {
      resetSession(false);
      dispatch(redirectAction("/inloggen"));
    },
  });

export const getAzureAdUrlAction = () =>
  composeAction({
    group: "AZUREADURL",
    fire: getAzureAdUrl,
  });

export const loginWithAzureAdAction = (code) =>
  composeAction({
    group: "AZUREADLOGIN",
    fire: loginWithAzureAd,
    args: [code],
    callbackSuccess: (payload, dispatch) => {
      addAuthenticationResult(payload, true);
      dispatch(redirectAction("/inloggen"));
    },
    callbackError: (dispatch) => {
      resetSession(false);
      dispatch(redirectAction("/inloggen"));
    },
  });

export const loginWithGoogleAction = (code) => {
  return composeAction({
    group: "GOOGLELOGIN",
    fire: loginWithGoogle,
    args: [code],
    callbackSuccess: (payload, dispatch) => {
      addAuthenticationResult(payload, false, true);
      dispatch(checkLoginAction()).then(() => {
        dispatch(redirectAction("/inloggen"));
      });
    },
    callbackError: (dispatch) => {
      resetSession(false);
      dispatch(redirectAction("/inloggen"));
    },
  });
};
