import { find, get, map } from "lodash/fp";
import MenuItem from "material-ui/MenuItem";
import SelectField from "material-ui/SelectField";
import PropTypes from "prop-types";
import React from "react";
import { compose, setDisplayName, withHandlers } from "recompose";

import { pagetypeListItemShape } from "./shapes";

const pagetypes = [
  "activiteit",
  "applicatie",
  "applicatieset",
  "basisset",
  "bedrijfsproces",
  "bedrijfsregel",
  "birapportage",
  "casus",
  "document",
  "entiteit",
  "groep",
  "klant",
  "koppeling",
  "legoblok",
  "leverancier",
  "locatie",
  "module",
  "nieuws",
  "offerte",
  "organisatie",
  "organisatieonderdeel",
  "persoon",
  "procesgroep",
  "procesonderdeel",
  "project",
  "risico",
  "rol",
  "training",
  "verantwoording",
];

const PagetypeSelect = ({ handleChange, value, pagetypeList, disabled }) => (
  <SelectField
    fullWidth
    floatingLabelText="Paginatype"
    value={value}
    onChange={handleChange}
    disabled={disabled}
  >
    <MenuItem value="" primaryText="" />
    {map((pagetypeAlias) => {
      const found = find({ alias: pagetypeAlias })(pagetypeList);
      return (
        found && (
          <MenuItem
            key={pagetypeAlias}
            value={pagetypeAlias}
            primaryText={get("name")(found)}
          />
        )
      );
    })(pagetypes)}
  </SelectField>
);

PagetypeSelect.propTypes = {
  handleChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  pagetypeList: PropTypes.arrayOf(pagetypeListItemShape).isRequired,
};

export default compose(
  setDisplayName("PagetypeSelect"),
  withHandlers({
    handleChange:
      ({ onChange }) =>
      (event, index, value) =>
        onChange(value),
  })
)(PagetypeSelect);
