import { mediaWidgetAction } from "../actions/data";
import MediaWidget from "../components/mediaWidget";
import { mediaWidgetSelector } from "../selectors";
import { dispatchWrap } from "../utils";
import connect from "./connect";

export default connect(
  (state, props) => ({
    mediaWidget: mediaWidgetSelector(state, props),
  }),
  (dispatch) => ({
    init: dispatchWrap(mediaWidgetAction, dispatch),
  })
)(MediaWidget);
