import classNames from "classnames";
import { flow, groupBy, map, sortBy } from "lodash/fp";
import Divider from "material-ui/Divider";
import { List, ListItem } from "material-ui/List";
import RaisedButton from "material-ui/RaisedButton";
import OpenInNew from "material-ui/svg-icons/action/open-in-new";
import React, { useMemo } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import {
  Relation,
  RelationCluster,
  RelationListItem,
  RelationSide,
  RelationType,
  ShowSide,
} from "../../business/models";
import { relationMultiple } from "../../business/relations";
import { flown } from "../../lodash";
import { takeIf } from "../../utils";
import Subheader from "../material/Subheader";
import { ItemLink } from "../relation.itemlink";
import { RelationItemEnriched } from "./models";
import RelationClusterListItem from "./RelationClusterListItem";
import RelationSecondaryItemFromCluster, {
  ItemLabelWithMutation,
} from "./RelationSecondaryItemFromCluster";

const mapToCluster = (relation: Relation) => ({
  ...relation,
  ...(relation.left.pageClusterId ? relation.left : relation.right),
});

const enrichRelationItem = (
  listItem: RelationListItem
): RelationItemEnriched => {
  const { id: relationId, mutation, item } = listItem;
  return { relationId, mutation, ...item };
};

export interface RelationClusterListProps {
  all: boolean;
  className: string;
  edit: boolean;
  initAddRelation: (
    type: RelationType,
    side: RelationSide,
    itemId: number
  ) => void;
  itemId: number;
  label: string;
  maxLength: number;
  mayClusterEdit: boolean;
  relations: RelationListItem[];
  removeRelation: (
    relationId: number,
    otherItemId: number,
    usingItemId?: number
  ) => void;
  setAll: (all: boolean) => void;
  showDivider: boolean;
  showHeader: boolean;
  side: RelationSide;
  type: RelationType;
}

const RelationClusterList = ({
  all,
  className,
  edit,
  initAddRelation,
  itemId,
  label,
  maxLength,
  mayClusterEdit,
  relations,
  removeRelation,
  setAll,
  showDivider = false,
  showHeader = false,
  side,
  type,
}: RelationClusterListProps) => {
  const list = useMemo(
    () =>
      flown(
        relations,
        groupBy(({ item: { itemId }, pageClusterId }) =>
          type.showSide === ShowSide.clusterSecundair || !pageClusterId
            ? itemId
            : pageClusterId
        ),
        map((grouped: RelationListItem[]) => {
          const first = grouped[0];
          const fromClusterSide =
            first.pageClusterId !== undefined &&
            type.showSide !== ShowSide.clusterSecundair;
          if (fromClusterSide) {
            // cluster zijde; standaardweergave
            const cluster = mapToCluster(first.relation);
            return {
              fromClusterSide,
              label: cluster.pageClusterLabel,
              clusters: [cluster],
              items: flow(map(enrichRelationItem), sortBy(["label"]))(grouped),
            };
          }

          return {
            // item zijde of cluster => secundaire weergave
            fromClusterSide,
            label: first.item.label,
            clusters: flow(
              map((i: RelationListItem) => mapToCluster(i.relation)),
              sortBy(["pageClusterLabel"])
            )(grouped),
            items: [enrichRelationItem(first)],
          };
        }),
        sortBy(["label"])
      ),
    [relations, type.showSide]
  );

  const hasToggle = list.length > maxLength;
  return (
    <div className={classNames(className, "relations")}>
      {showDivider && <Divider />}
      <List>
        {showHeader && (
          <Subheader key="header" secondary={type.scope > 0}>
            {type.displayLabel ?? label}
          </Subheader>
        )}
        {relations.length === 0 && type.canExist && !type.mayAdd && (
          <ListItem key="empty">Relatie nog niet ingevuld</ListItem>
        )}
        <TransitionGroup>
          {flow(
            takeIf(maxLength - 1, !all && list.length > maxLength),
            map(
              ({
                fromClusterSide,
                label,
                clusters,
                items,
              }: {
                fromClusterSide: boolean;
                label: string;
                clusters: RelationCluster[];
                items: RelationItemEnriched[];
              }) => (
                <CSSTransition
                  key={`rel-${items[0].itemId}-${clusters[0].pageClusterId}-${type.showSide}`}
                  classNames="relations"
                  timeout={{ enter: 500, exit: 200 }}
                >
                  {fromClusterSide ? (
                    <RelationClusterListItem
                      primary={label}
                      numberOfSecondaryItems={items.length}
                      secondary={items.map((item, i) => (
                        <RelationSecondaryItemFromCluster
                          key={item.itemId}
                          item={item}
                          position={i}
                          edit={edit}
                          removeRelation={removeRelation}
                        />
                      ))}
                      iconType="cluster"
                    />
                  ) : (
                    <RelationClusterListItem
                      primary={label}
                      numberOfSecondaryItems={clusters.length}
                      secondary={clusters.map((cluster, i) => (
                        <span
                          key={`${items[0].itemId}-${cluster.pageClusterId}`}
                        >
                          {i > 0 && ", "}
                          <ItemLink
                            item={{
                              ...items[0],
                              pageClusterId: cluster.pageClusterId,
                              pageClusterLabel: undefined,
                              label: cluster.pageClusterLabel,
                            }}
                          >
                            <ItemLabelWithMutation
                              label={cluster.pageClusterLabel}
                              mutation={cluster.mutation}
                            />
                          </ItemLink>
                        </span>
                      ))}
                      iconType={items[0].type}
                    />
                  )}
                </CSSTransition>
              )
            )
          )(list)}
          {(!hasToggle || all) &&
            type.canExist &&
            type.mayAdd &&
            mayClusterEdit &&
            (list.length === 0 || (edit && relationMultiple(type.rule))) && (
              <CSSTransition
                classNames="relations"
                timeout={{ enter: 500, exit: 200 }}
              >
                <ListItem
                  key="add"
                  onClick={() => initAddRelation(type, side, itemId)}
                  rightIcon={<OpenInNew />}
                >
                  Relatie toevoegen
                </ListItem>
              </CSSTransition>
            )}
        </TransitionGroup>
        {hasToggle && (
          <ListItem key="toggle" style={{ textAlign: "center" }} disabled>
            <RaisedButton
              label={all ? "Toon minder" : "Toon meer"}
              onClick={() => setAll(!all)}
            />
          </ListItem>
        )}
      </List>
    </div>
  );
};

export default RelationClusterList;
