import { get } from "lodash/fp";
import FlatButton from "material-ui/FlatButton";
import IconButton from "material-ui/IconButton";
import ExpandLess from "material-ui/svg-icons/navigation/expand-less";
import ExpandMore from "material-ui/svg-icons/navigation/expand-more";
import NavigationUnfoldLess from "material-ui/svg-icons/navigation/unfold-less";
import NavigationUnfoldMore from "material-ui/svg-icons/navigation/unfold-more";
import TextField from "material-ui/TextField";
import { ReactNode, useCallback, useState } from "react";

import { isNonEmptyArray } from "../../utils";
import { ClusterPresentation } from "./clusterPresentation";
import Heading from "./Heading";
import { containsText } from "./query";

interface ExpandToolProps {
  expandableDepth: number;
  expandedLevel: number;
  setExpandedLevel(level: number): void;
}

const ExpandTool = ({
  expandableDepth,
  expandedLevel,
  setExpandedLevel,
}: ExpandToolProps) => (
  <>
    <IconButton
      disabled={!Boolean(expandedLevel)}
      title="Beperk tot vorige niveau"
      onClick={() => setExpandedLevel(expandedLevel - 1)}
    >
      <NavigationUnfoldLess />
    </IconButton>
    <IconButton
      title="Toon volgende niveau"
      disabled={expandedLevel === expandableDepth}
      onClick={() =>
        setExpandedLevel(expandedLevel === undefined ? 1 : expandedLevel + 1)
      }
    >
      <NavigationUnfoldMore />
    </IconButton>
  </>
);

interface ClusterCardsListProps {
  name: string;
  props: Record<string, unknown>;
  parent: { heading?: string };
  clusters: unknown[];
  moreClusters?: Record<string, unknown>[];
  linkedClusterId?: number;
  renderCluster: (...args: unknown[]) => ReactNode;
  plug: {
    clusterName: (...args: unknown[]) => boolean;
    search: (...args: unknown[]) => boolean;
    headerTool: ReactNode;
  };
  level: number;
  expandableDepth: number;
  edit: boolean;
}

const ClusterCardsList = ({
  name,
  props,
  parent,
  clusters,
  moreClusters,
  linkedClusterId,
  renderCluster,
  plug,
  level,
  expandableDepth,
  edit,
}: ClusterCardsListProps) => {
  const [expandedLevel, setExpandedLevel] = useState<number>(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [more, setMore] = useState(
    Boolean(
      moreClusters &&
        linkedClusterId &&
        moreClusters.some((c) => c.clusterId === linkedClusterId)
    )
  );
  const toggleMore = useCallback(() => setMore(!more), [more]);

  if (!isNonEmptyArray(clusters) && !isNonEmptyArray(moreClusters)) {
    return <></>;
  }

  const cluster = clusters[0] || moreClusters![0];
  const search =
    level === 0 && plug.search(cluster, plug.clusterName(cluster, name), props);
  const heading =
    get(`headingLevel${level}`)(plug) ||
    get("entity.hea")(plug) ||
    parent.heading;
  return (
    <>
      <div className="clusters-header">
        {Boolean(heading) && <Heading>{heading}</Heading>}
        {plug.headerTool}
        <div className="clusters-tools">
          {search && (
            <TextField
              disabled={edit}
              name="search"
              defaultValue={searchTerm}
              onChange={(_, newValue) => setSearchTerm(newValue)}
              floatingLabelText="Filter clusters"
            />
          )}
          {expandableDepth > 0 && (
            <ExpandTool
              expandableDepth={expandableDepth}
              expandedLevel={expandedLevel}
              setExpandedLevel={setExpandedLevel}
            />
          )}
        </div>
      </div>

      {expandableDepth > 0 || search ? (
        <ClusterPresentation.Provider
          value={{ expansion: expandedLevel, search: searchTerm }}
        >
          {clusters
            .filter((cluster) => !search || containsText(searchTerm)(cluster))
            .map(renderCluster)}
          {isNonEmptyArray(moreClusters) && (
            <FlatButton
              style={{ marginBottom: 12 }}
              onClick={toggleMore}
              label={`${
                more ? "Verberg" : "Toon"
              } velden niet gerelateerd aan deze entiteit`}
              icon={more ? <ExpandLess /> : <ExpandMore />}
            />
          )}
          {isNonEmptyArray(moreClusters) &&
            more &&
            moreClusters!
              .filter((cluster) => !search || containsText(searchTerm)(cluster))
              .map((c, i) =>
                renderCluster({ child: c, name, id: c.id }, i + clusters.length)
              )}
        </ClusterPresentation.Provider>
      ) : (
        <>
          {clusters.map(renderCluster)}
          {isNonEmptyArray(moreClusters) && (
            <FlatButton
              style={{ marginBottom: 12 }}
              onClick={toggleMore}
              label={`${
                more ? "Verberg" : "Toon"
              } velden niet gerelateerd aan deze entiteit`}
              icon={more ? <ExpandLess /> : <ExpandMore />}
            />
          )}
          {isNonEmptyArray(moreClusters) &&
            more &&
            moreClusters!.map((c, i) =>
              renderCluster({ child: c, name, id: c.id }, i + clusters.length)
            )}
        </>
      )}
    </>
  );
};

export default ClusterCardsList;
