import { loadingPresent } from "async-lifecycle";
import empty from "empty";
import {
  concat,
  eq,
  filter,
  flatten,
  flow,
  get,
  map,
  reject,
  some,
} from "lodash/fp";
import FlatButton from "material-ui/FlatButton";
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import {
  compose,
  mapProps,
  pure,
  setDisplayName,
  withHandlers,
  withProps,
  withState,
} from "recompose";

import {
  entiteitShape,
  globalItemShape,
  selectedGegevenShape,
} from "../../business/prop-types";
import { Side, emptyType } from "../../business/relations";
import Dialog from "../../containers/dialog";
import { flown } from "../../lodash";
import { EntiteitenLoadingIndicator } from "../pagetypes/application/gegevenDialog";
import AddKerngegevenStepper from "./addKerngegevenStepper";

const AddKerngegevenRelation = ({
  valid,
  clear,
  save,
  loading,
  entiteiten,
  onVerantwoordingChange,
  onGegevenToggle,
  currentGegevens,
  selectedGegevens,
  verantwoordingen,
  verantwoordingId,
  stepIndex,
  setStepIndex,
  onAdd,
  setPreview,
}) => (
  <Dialog
    title="Verantwoording en gegevens toevoegen"
    open
    autoScrollBodyContent
    actions={
      <Fragment>
        {stepIndex > 0 && (
          <FlatButton
            disabled={loading}
            label="Vorige"
            onClick={() => setStepIndex(stepIndex - 1)}
          />
        )}
        <FlatButton secondary label="Annuleren" onClick={clear} />
        {stepIndex === 0 && (
          <FlatButton
            label="Volgende"
            disabled={loading || !valid}
            onClick={() => setStepIndex(stepIndex + 1)}
          />
        )}
        {stepIndex === 1 && (
          <FlatButton
            primary
            disabled={loading || !valid}
            label="Opslaan"
            onClick={save}
          />
        )}
      </Fragment>
    }
  >
    {loading ? (
      <EntiteitenLoadingIndicator />
    ) : (
      <AddKerngegevenStepper
        entiteiten={entiteiten}
        onVerantwoordingChange={onVerantwoordingChange}
        onGegevenToggle={onGegevenToggle}
        currentGegevens={currentGegevens}
        selectedGegevens={selectedGegevens}
        verantwoordingen={verantwoordingen}
        verantwoordingId={verantwoordingId}
        stepIndex={stepIndex}
        setStepIndex={setStepIndex}
        onAdd={onAdd}
        setPreview={setPreview}
      />
    )}
  </Dialog>
);
AddKerngegevenRelation.propTypes = {
  valid: PropTypes.bool.isRequired,
  clear: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  entiteiten: PropTypes.arrayOf(entiteitShape.isRequired).isRequired,
  onVerantwoordingChange: PropTypes.func.isRequired,
  onGegevenToggle: PropTypes.func.isRequired,
  currentGegevens: PropTypes.arrayOf(selectedGegevenShape.isRequired)
    .isRequired,
  selectedGegevens: PropTypes.arrayOf(selectedGegevenShape.isRequired)
    .isRequired,
  verantwoordingen: PropTypes.arrayOf(globalItemShape.isRequired).isRequired,
  verantwoordingId: PropTypes.number,
  stepIndex: PropTypes.number.isRequired,
  setStepIndex: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  setPreview: PropTypes.func.isRequired,
};

const initialState = Object.freeze({
  verantwoordingId: undefined,
  saving: false,
  selectedGegevens: empty.array,
  stepIndex: 0,
});

export default compose(
  setDisplayName("AddKerngegevenRelation"),
  pure,
  withState("state", "updateState", ({ verantwoordingId }) => ({
    ...initialState,
    verantwoordingId,
  })),
  withProps(
    ({
      state: { verantwoordingId, saving, selectedGegevens, stepIndex },
      entiteiten = empty.object,
      verantwoordingen = empty.object,
      side,
      type = emptyType,
      peers: { items = empty.array } = empty.object,
    }) => {
      const allRelations = flow(map("relations"), flatten)(items);
      return {
        entiteiten: entiteiten.entiteiten || empty.array,
        verantwoordingen: verantwoordingen.verantwoordingen || empty.array,
        title: `${
          (side === Side.left ? type.left : type.right).label
        } toevoegen`,
        valid:
          stepIndex === 0
            ? typeof verantwoordingId === "number"
            : selectedGegevens.length > 0,
        currentGegevens: flown(
          allRelations,
          filter(flow(get("using[0].itemId"), eq(verantwoordingId))),
          map(
            ({ right: { itemId: entiteitId, pageClusterId: gegevenId } }) => ({
              entiteitId,
              gegevenId,
            })
          )
        ),
        loading:
          saving ||
          !loadingPresent(entiteiten.loading) ||
          !loadingPresent(verantwoordingen.loading),
      };
    }
  ),
  withHandlers({
    onVerantwoordingChange:
      ({ state, updateState }) =>
      (verantwoordingId) =>
        updateState({ ...state, verantwoordingId }),

    onGegevenToggle:
      ({ state, updateState }) =>
      (gegeven, selected) =>
        updateState(
          some(gegeven)(state.selectedGegevens) === selected
            ? state
            : {
                ...state,
                selectedGegevens: (selected ? concat : reject)(gegeven)(
                  state.selectedGegevens
                ),
              }
        ),

    save:
      ({
        id,
        state: { verantwoordingId, selectedGegevens } = empty.object,
        state,
        updateState,
        addRelations,
      }) =>
      () => {
        updateState({ ...state, saving: true });
        addRelations(
          map(({ entiteitId, gegevenId }) => ({
            vanItmIdt: id,
            narItmIdt: entiteitId,
            narPagClsIdt: gegevenId,
            metItmIdt: verantwoordingId,
          }))(selectedGegevens)
        );
      },

    setStepIndex:
      ({ state, updateState }) =>
      (stepIndex) => {
        updateState({
          ...state,
          selectedGegevens:
            stepIndex === 0 ? empty.array : state.selectedGegevens,
          stepIndex,
        });
      },
  }),
  mapProps(({ state, ...rest }) => ({ ...rest, ...state }))
)(AddKerngegevenRelation);
