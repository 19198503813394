import {
  biebRelationsSelector,
  legoblokRelationsSelector,
} from "../../../selectors/relations";

const renderBiebRelations = (props) => [
  legoblokRelationsSelector(props),
  biebRelationsSelector(props),
];

export default renderBiebRelations;
