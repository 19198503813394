/* eslint  react/prop-types: off */
import { intersection, isEmpty, map } from "lodash/fp";
import FlatButton from "material-ui/FlatButton";
import Menu from "material-ui/Menu";
import MenuItem from "material-ui/MenuItem";
import Popover from "material-ui/Popover";
import UserIcon from "material-ui/svg-icons/action/account-circle";
import PropTypes from "prop-types";
import React, { useCallback } from "react";
import {
  compose,
  setDisplayName,
  setPropTypes,
  withHandlers,
  withState,
} from "recompose";

import { flown } from "../lodash";

const authorizerProfiles = [8, 9, 12];

const UserMenu = ({
  userMenuOpen,
  toggleOpen,
  user,
  password,
  logout,
  anchorEl,
  setAnchorEl,
  mobile,
}) => {
  const openRedactie = useCallback(() => {
    const { host } = document.location;
    window.open(`https://redactie-${host}/?AppIdt=00003100`, "redactie");
  }, []);
  return (
    <div
      style={{ display: "inline-flex", position: "relative" }}
      ref={setAnchorEl}
    >
      <FlatButton
        label={!mobile ? user.name : undefined}
        style={{
          color: "#fff",
          height: "48px",
          minWidth: mobile ? 34 : undefined,
        }}
        onClick={toggleOpen}
        icon={mobile ? <UserIcon /> : undefined}
      />
      <Popover
        open={userMenuOpen}
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: mobile ? "right" : "left",
          vertical: "bottom",
        }}
        targetOrigin={{
          horizontal: mobile ? "right" : "left",
          vertical: "top",
        }}
        onRequestClose={toggleOpen}
      >
        <Menu open={userMenuOpen}>
          <MenuItem primaryText={"Wachtwoord wijzigen"} onClick={password} />
          {!flown(
            user.tasks,
            map("prfIdt"),
            intersection(authorizerProfiles),
            isEmpty
          ) && <MenuItem primaryText={"Autorisaties"} onClick={openRedactie} />}
          <MenuItem primaryText={"Uitloggen"} onClick={logout} />
        </Menu>
      </Popover>
    </div>
  );
};

export default compose(
  setDisplayName("UserMenu"),
  withState("userMenuOpen", "setOpen", false),
  withState("anchorEl", "setAnchorEl"),
  withHandlers({
    toggleOpen:
      ({ setOpen, userMenuOpen }) =>
      () =>
        setOpen(!userMenuOpen),
  }),
  setPropTypes({
    user: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      email: PropTypes.string,
      username: PropTypes.string,
    }).isRequired,
    logout: PropTypes.func.isRequired,
    password: PropTypes.func.isRequired,
  })
)(UserMenu);
