import { findIndex } from "lodash/fp";
import Prev from "material-ui/svg-icons/image/navigate-before";
import Next from "material-ui/svg-icons/image/navigate-next";
import PropTypes from "prop-types";
import React from "react";
import { compose, onlyUpdateForKeys, setDisplayName } from "recompose";

import { PathLink } from "../../../relation.pathlink";

const ProcessNavigationComponent = ({ self, siblings }) => {
  const currentIdx = findIndex({ itemId: self })(siblings);
  const previousIdx = currentIdx > 0 ? currentIdx - 1 : -1;
  const nextIdx = currentIdx < siblings.length - 1 ? currentIdx + 1 : -1;

  const previous = previousIdx >= 0 ? siblings[previousIdx] : undefined;
  const next = nextIdx >= 0 ? siblings[nextIdx] : undefined;

  if (!previous && !next) {
    return null;
  }

  return (
    <div className="process-navigation">
      {previous && (
        <div className="previous">
          <PathLink path={`page/${previous.itemId}`}>
            <Prev
              style={{
                width: "32px",
                height: "32px",
                verticalAlign: "middle",
              }}
              title="Vorige"
            />
            <span className="link-text">{previous.label}</span>
          </PathLink>
        </div>
      )}
      {next && (
        <div className="next">
          <PathLink path={`page/${next.itemId}`}>
            <span className="link-text">{next.label}</span>
            <Next
              style={{
                width: "32px",
                height: "32px",
                verticalAlign: "middle",
              }}
              title="Volgende"
            />
          </PathLink>
        </div>
      )}
    </div>
  );
};

const SiblingShape = PropTypes.shape({
  itemId: PropTypes.number.isRequired,
});

ProcessNavigationComponent.propTypes = {
  self: PropTypes.number.isRequired,
  siblings: PropTypes.arrayOf(SiblingShape.isRequired).isRequired,
};

const ProcessNavigation = compose(
  setDisplayName("ProcessNavigation"),
  onlyUpdateForKeys(["self", "siblings"])
)(ProcessNavigationComponent);

/* eslint-disable react/prop-types */
const renderProcessNavigation = ({ siblings, id }) =>
  id && siblings ? (
    <ProcessNavigation key="process-navigation" self={id} siblings={siblings} />
  ) : null;

export default renderProcessNavigation;
