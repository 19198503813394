import empty from "empty";
import React from "react";
import { IndexRedirect, Route } from "react-router";

import { clearPageActions } from "./actions/cms";
import { getPageAction, requireSettingsAction } from "./actions/data";
import {
  clearPreviewAction,
  clearVersionDiffAction,
  resetUiClassesAction,
} from "./actions/ui";
import App from "./containers/app";
import Authenticated from "./containers/authenticated";
import ExternalLogin from "./containers/externalLogin";
import Forgot from "./containers/forgot";
import Home from "./containers/home";
import Login from "./containers/login";
import LoginMicrosoft365 from "./containers/loginMicrosoft365";
import Overzicht from "./containers/overzicht";
import Page from "./containers/page";
import RaamwerkRedirect from "./containers/raamwerkredirect";
import Search from "./containers/search";
import Verantwoordingen from "./containers/verantwoordingen";
import { sagas } from "./sagas";

/**
 * Method to get application related settings
 */
const requireApp = (dispatch) => () => {
  dispatch(requireSettingsAction());
};

/**
 * Method to get async page for the `Page` component
 */
const requirePage =
  (dispatch) =>
  ({
    params: { id } = empty.object,
    location: { query: { tab, clusterId } = empty.object } = empty.object,
  }) => {
    dispatch(clearPageActions());
    dispatch(getPageAction(id));

    if (id && clusterId && tab === undefined) {
      dispatch({
        type: sagas.tabForCluster.require,
        payload: { itemId: id, clusterId },
      });
    }
  };

/**
 * Reset UI state when leaving a page
 */
const resetUiState = (dispatch) => () => {
  dispatch(resetUiClassesAction());
  dispatch(clearPreviewAction());
  dispatch(clearVersionDiffAction());
};

const routes = (dispatch) => (
  <Route path="/" component={App} onEnter={requireApp(dispatch)}>
    <IndexRedirect to="/inloggen" />
    <Route path="/inloggen" component={Login} />
    <Route path="/inloggen/ms365" component={LoginMicrosoft365} />
    <Route path="/external/:code" component={ExternalLogin} />
    <Route path="/external/error/:error" component={ExternalLogin} />
    <Route path="/vergeten" component={Forgot} />
    <Route path="/:minisaar" component={Authenticated}>
      <IndexRedirect to="home" />
      <Route path="home" component={Home} />
      <Route path="overzicht" component={Overzicht} />
      <Route path="search(/:scope)" component={Search} />
      <Route path="verantwoordingen" component={Verantwoordingen} />
      <Route path="raamwerk/:slug(/:fromItemId)" component={RaamwerkRedirect} />
      <Route
        path="page/:id(/:slug)"
        component={Page}
        onEnter={requirePage(dispatch)}
        onLeave={resetUiState(dispatch)}
      />
    </Route>
  </Route>
);

export default routes;
