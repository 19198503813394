import classNames from "classnames";
import RaisedButton from "material-ui/RaisedButton";
import ActionDelete from "material-ui/svg-icons/action/delete";
import ContentAdd from "material-ui/svg-icons/content/add";
import Table, {
  TableBody,
  TableFooter,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
} from "material-ui/Table";
import React, { ReactNode, useCallback, useMemo, useReducer } from "react";

import { PageActions } from "../../../../business/models";
import { isNonEmptyArray } from "../../../../utils";
import ClusterCard from "../../../material/clusterCard";
import InlineIconButton from "../../../material/InlineIconButton";
import SafeInnerHtmlWrapped from "../../../safeInnerHtml";

interface AuthProfile {
  id: number;
  name: string;
  nameDiff?: string;
  lines: AuthLine[];
}

interface AuthLine {
  id: number;
  domain: string;
  domainDiff?: string;
  actions: string;
  actionsDiff?: string;
  scope: string;
  scopeDiff?: string;
}

interface SpanProps {
  value: string;
  diff?: string;
  disabled: boolean;
  children?: ReactNode;
  onChange(value: string): void;
}
const SpanInput = ({
  value,
  diff,
  disabled,
  children,
  onChange,
}: SpanProps): JSX.Element => (
  <div
    className={classNames("span-input", {
      "span-input-children": children && !disabled,
    })}
  >
    {diff ? (
      <SafeInnerHtmlWrapped>{diff}</SafeInnerHtmlWrapped>
    ) : disabled ? (
      value
    ) : (
      <textarea
        onChange={(e) => onChange(e.target.value)}
        readOnly={disabled}
        wrap="soft"
        value={value}
        maxLength={255}
      />
    )}
    {!disabled && children}
  </div>
);

interface AuthState {
  id: number;
  profiles: AuthProfile[];
}

const addProfile = ({ id, profiles }: AuthState): AuthState => ({
  id: id - 2,
  profiles: [
    ...profiles,
    {
      id,
      name: "Nieuw profiel",
      lines: [{ id: id - 1, domain: "", actions: "", scope: "" }],
    },
  ],
});
const addLine = (
  { id, profiles }: AuthState,
  { p }: { p: number }
): AuthState => ({
  id: id - 1,
  profiles: profiles.map((profile) =>
    profile.id === p
      ? {
          ...profile,
          lines: [...profile.lines, { id, domain: "", actions: "", scope: "" }],
        }
      : profile
  ),
});
const updateName = (
  { id, profiles }: AuthState,
  { p, name }: { p: number; name: string }
): AuthState => ({
  id,
  profiles: profiles.map((profile) =>
    profile.id === p ? { ...profile, name } : profile
  ),
});
const updateLine = (
  { id, profiles }: AuthState,
  {
    p,
    l,
    prop,
    value,
  }: { p: number; l: number; prop: keyof AuthLine; value: string }
): AuthState => ({
  id,
  profiles: profiles.map((profile) =>
    profile.id === p
      ? {
          ...profile,
          lines: profile.lines.map((line) =>
            line.id === l ? { ...line, [prop]: value } : line
          ),
        }
      : profile
  ),
});
const deleteProfile = (
  { id, profiles }: AuthState,
  { p }: { p: number }
): AuthState => ({
  id,
  profiles: profiles.filter((profile) => profile.id !== p),
});
const deleteLine = (
  state: AuthState,
  { p, l }: { p: number; l: number }
): AuthState => {
  const { id, profiles } = state;
  switch (profiles.find(({ id }) => id === p)?.lines.length) {
    case undefined: // Should not happen
      return state;
    case 0: // Should not happen
    case 1:
      return deleteProfile(state, { p });
    default:
      return {
        id,
        profiles: profiles.map((profile) =>
          profile.id === p
            ? {
                ...profile,
                lines: profile.lines.filter((line) => line.id !== l),
              }
            : profile
        ),
      };
  }
};

const reducer = (
  state: AuthState,
  action: { [key: string]: any }
): AuthState => {
  switch (action.type) {
    case "addProfile":
      return addProfile(state);
    case "addLine":
      return addLine(state, action as any);
    case "updateName":
      return updateName(state, action as any);
    case "updateLine":
      return updateLine(state, action as any);
    case "deleteProfile":
      return deleteProfile(state, action as any);
    case "deleteLine":
      return deleteLine(state, action as any);
    default:
      return state;
  }
};

interface ProfielCluster {
  clusterId: number;
  naam: { value: string; diff?: string };
  regels: { clusters: ProfielRegel[] };
}

interface ProfielRegel {
  clusterId: number;
  wat: { value: string; diff?: string };
  bevoegdheden?: { value: string; diff?: string };
  bereik?: { value: string; diff?: string };
}

const extractProfiles = (
  autorisatieprofielen: { clusters: ProfielCluster[] } | undefined
): AuthProfile[] => {
  if (!autorisatieprofielen) {
    return [];
  }

  return autorisatieprofielen.clusters
    .filter((profiel) => isNonEmptyArray(profiel.regels.clusters))
    .map((profiel) => ({
      id: profiel.clusterId,
      name: profiel.naam.value,
      nameDiff: profiel.naam.diff,
      lines: profiel.regels.clusters.map((regel) => ({
        id: regel.clusterId,
        domain: regel.wat.value,
        domainDiff: regel.wat.diff,
        actions: regel.bevoegdheden?.value ?? "",
        actionsDiff: regel.bevoegdheden?.diff ?? "",
        scope: regel.bereik?.value ?? "",
        scopeDiff: regel.bereik?.diff ?? "",
      })),
    }));
};

interface Props {
  pageActions: PageActions;
}
const AutorisatieProfielen = ({
  pageActions: { edit, add },
  page: { autorisatieprofielen },
}: Props & any): JSX.Element => {
  const wrappedReducer = useCallback(
    (state: AuthState, action: { [key: string]: any }): AuthState => {
      const next = reducer(state, action);
      add("AuthProfile", { profiles: next.profiles }, true);
      return next;
    },
    [add]
  );
  const authProfiles = useMemo(
    () => extractProfiles(autorisatieprofielen),
    [autorisatieprofielen]
  );
  const [{ profiles }, dispatch] = useReducer(wrappedReducer, {
    id: 0,
    profiles: authProfiles,
  });
  return (
    <Table>
      <TableHeader adjustForCheckbox={false} displaySelectAll={false}>
        <TableRow>
          <TableHeaderColumn rowSpan={2}>Autorisatieprofiel</TableHeaderColumn>
          <TableHeaderColumn colSpan={3}>Autorisatierol</TableHeaderColumn>
        </TableRow>
        <TableRow>
          <TableHeaderColumn>Wat</TableHeaderColumn>
          <TableHeaderColumn>Bevoegdheden</TableHeaderColumn>
          <TableHeaderColumn>Bereik</TableHeaderColumn>
        </TableRow>
      </TableHeader>
      <TableBody displayRowCheckbox={false}>
        {profiles.map(
          ({
            id: p,
            name,
            nameDiff,
            lines,
            lines: [
              {
                id: l,
                domain,
                domainDiff,
                actions,
                actionsDiff,
                scope,
                scopeDiff,
              },
            ],
          }) => [
            <TableRow key={p}>
              <TableHeaderColumn rowSpan={lines.length + (edit ? 1 : 0)}>
                <SpanInput
                  disabled={!edit}
                  value={name}
                  diff={nameDiff}
                  onChange={(name) => dispatch({ type: "updateName", p, name })}
                >
                  {" "}
                  <InlineIconButton
                    onClick={() => dispatch({ type: "deleteProfile", p })}
                  >
                    <ActionDelete />
                  </InlineIconButton>
                </SpanInput>
              </TableHeaderColumn>
              <TableRowColumn>
                <SpanInput
                  disabled={!edit}
                  value={domain}
                  diff={domainDiff}
                  onChange={(value) =>
                    dispatch({
                      type: "updateLine",
                      p,
                      l,
                      prop: "domain",
                      value,
                    })
                  }
                />
              </TableRowColumn>
              <TableRowColumn>
                <SpanInput
                  disabled={!edit}
                  value={actions}
                  diff={actionsDiff}
                  onChange={(value) =>
                    dispatch({
                      type: "updateLine",
                      p,
                      l,
                      prop: "actions",
                      value,
                    })
                  }
                />
              </TableRowColumn>
              <TableRowColumn>
                <SpanInput
                  disabled={!edit}
                  value={scope}
                  diff={scopeDiff}
                  onChange={(value) =>
                    dispatch({
                      type: "updateLine",
                      p,
                      l,
                      prop: "scope",
                      value,
                    })
                  }
                >
                  <InlineIconButton
                    onClick={() => dispatch({ type: "deleteLine", p, l })}
                  >
                    <ActionDelete />
                  </InlineIconButton>
                </SpanInput>
              </TableRowColumn>
            </TableRow>,
            lines
              .filter((_, i) => i > 0)
              .map(({ id: l, domain, actions, scope }) => (
                <TableRow key={l}>
                  <TableRowColumn>
                    <SpanInput
                      disabled={!edit}
                      value={domain}
                      diff={domainDiff}
                      onChange={(value) =>
                        dispatch({
                          type: "updateLine",
                          p,
                          l,
                          prop: "domain",
                          value,
                        })
                      }
                    />
                  </TableRowColumn>
                  <TableRowColumn>
                    <SpanInput
                      disabled={!edit}
                      value={actions}
                      diff={actionsDiff}
                      onChange={(value) =>
                        dispatch({
                          type: "updateLine",
                          p,
                          l,
                          prop: "actions",
                          value,
                        })
                      }
                    />
                  </TableRowColumn>
                  <TableRowColumn>
                    <SpanInput
                      disabled={!edit}
                      value={scope}
                      diff={scopeDiff}
                      onChange={(value) =>
                        dispatch({
                          type: "updateLine",
                          p,
                          l,
                          prop: "scope",
                          value,
                        })
                      }
                    >
                      <InlineIconButton
                        onClick={() => dispatch({ type: "deleteLine", p, l })}
                      >
                        <ActionDelete />
                      </InlineIconButton>
                    </SpanInput>
                  </TableRowColumn>
                </TableRow>
              )),
            edit && (
              <TableRow key={`${p}.actions`}>
                <TableRowColumn colSpan={3} style={{ textAlign: "right" }}>
                  <InlineIconButton
                    onClick={() => dispatch({ type: "addLine", p })}
                  >
                    <ContentAdd />
                  </InlineIconButton>
                </TableRowColumn>
              </TableRow>
            ),
          ]
        )}
      </TableBody>
      {edit && (
        <TableFooter adjustForCheckbox={false}>
          <TableRow>
            <TableRowColumn colSpan={4}>
              <RaisedButton
                label="Autorisatieprofiel toevoegen"
                secondary
                onClick={() => dispatch({ type: "addProfile" })}
              />
            </TableRowColumn>
          </TableRow>
        </TableFooter>
      )}
    </Table>
  );
};

const renderAutorisatieProfielen = (props: Props): JSX.Element | null => {
  if (
    !props.pageActions.edit &&
    !isNonEmptyArray((props as any).page?.autorisatieprofielen?.clusters)
  ) {
    return null;
  }
  return (
    <ClusterCard key="renderAutorisatieProfielen" title="Autorisatieprofielen">
      <AutorisatieProfielen {...props} key={props.pageActions.version} />
    </ClusterCard>
  );
};

export default renderAutorisatieProfielen;
