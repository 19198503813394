import empty from "empty";
import {
  compact,
  filter,
  find,
  flow,
  fromPairs,
  get,
  has,
  identity,
  keys,
  map,
  omit,
  orderBy,
  some,
  take,
  uniq,
  without,
} from "lodash/fp";
import Checkbox from "material-ui/Checkbox";
import FlatButton from "material-ui/FlatButton";
import { List, ListItem } from "material-ui/List";
import MenuItem from "material-ui/MenuItem";
import SelectField from "material-ui/SelectField";
import muiThemeable from "material-ui/styles/muiThemeable";
import Subheader from "material-ui/Subheader";
import AlertWarning from "material-ui/svg-icons/alert/warning";
import Add from "material-ui/svg-icons/content/add";
import Remove from "material-ui/svg-icons/content/remove";
import TextField from "material-ui/TextField";
import Toggle from "material-ui/Toggle";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import {
  compose,
  setDisplayName,
  withHandlers,
  withProps,
  withState,
} from "recompose";

import Confirm from "../../containers/confirm";
import { flown } from "../../lodash";
import { isEmpty, isNonEmptyArray } from "../../utils";
import ItemToPick from "./itemToPick";

const pickedItems = (newItems = [], selected) => {
  const peers = compact([
    ...newItems,
    ...flow(
      keys,
      filter((key) => !Number.isNaN(key)),
      map((key) => parseInt(key, 10))
    )(selected),
  ]);

  return isNonEmptyArray(peers) ? { peers } : null;
};

const ItemsPicker = ({
  items,
  selected,
  handleSelected,
  search,
  suggestion = "",
  handleSearch,
  externalApplication,
  handleExternalApplication,
  canCreate,
  multiple = true,
  onSelect,
  toggleStatus,
  toggleFilter,
  pagetype,
  structures = empty.array,
  refresh = empty.func,
  siteId,
  maySelectLocation,
  newItems,
  addNewItem,
  removeNewItem,
  ssoItmIdt,
}) => {
  const [maxRows, setRows] = useState(50);
  const [location, setLocation] = useState(find({ id: siteId })(structures));
  const expandRows = useCallback(() => setRows(2 * maxRows), [maxRows]);

  const selectLocation = useCallback(
    (event, key, selectedId) => {
      setLocation(find({ id: selectedId })(structures));
      refresh(selectedId, ssoItmIdt);
    },
    [refresh, structures, ssoItmIdt]
  );

  useEffect(() => {
    if (ssoItmIdt) {
      refresh(location?.id, ssoItmIdt);
    }
  }, [refresh, ssoItmIdt, location]);

  const filteredItems = items;

  return (
    <div className="form-row">
      <Confirm />
      {maySelectLocation && structures.length > 1 && (
        <SelectField
          floatingLabelText="Kies een locatie"
          onChange={selectLocation}
          value={location?.id || structures[0].id}
        >
          {structures.map(({ id, name }) => (
            <MenuItem key={id} value={id} primaryText={name} />
          ))}
        </SelectField>
      )}
      <TextField
        autoFocus
        name="search"
        value={search}
        fullWidth
        hintText="Zoekterm"
        floatingLabelText="Zoekterm"
        onChange={handleSearch}
      />
      <div
        style={{
          overflow: "auto",
          maxHeight: "200px",
          border: "1px solid rgb(189, 189, 189)",
        }}
      >
        <List>
          {filteredItems.length === 0 && (
            <ListItem>
              <em>Geen items gevonden</em>
            </ListItem>
          )}
          {flown(
            filteredItems,
            take(maxRows),
            map(({ itemId, disabled, label, bieb, container }) => (
              <ItemToPick
                key={itemId}
                itemId={itemId}
                disabled={disabled}
                label={label}
                bieb={bieb}
                container={container}
                selected={selected}
                multiple={multiple}
                onSelect={onSelect}
                setSelected={handleSelected}
              />
            ))
          )}
          {filteredItems.length > maxRows && (
            <ListItem leftCheckbox={<></>}>
              <span
                style={{
                  textTransform: "uppercase",
                  fontSize: "14px",
                  marginRight: "1em",
                  display: "inline-block",
                  verticalAlign: "middle",
                }}
              >
                {maxRows} van {filteredItems.length} getoond
              </span>
              <FlatButton primary label="Toon meer" onClick={expandRows} />
            </ListItem>
          )}
          {toggleFilter && (
            <ListItem
              leftIcon={<AlertWarning />}
              primaryText="Filter lijst op basis van rollen bij personen"
              rightToggle={
                <Toggle
                  toggled={toggleStatus}
                  onToggle={(_, isInputChecked) => toggleFilter(isInputChecked)}
                />
              }
            />
          )}
        </List>
      </div>
      {canCreate && (!location || location.id === siteId) && (
        <div className="item-to-add">
          <Subheader>Toevoegen nieuw item</Subheader>
          <List>
            <ListItem
              leftCheckbox={
                <Checkbox
                  checked={false}
                  onCheck={() => addNewItem(search || suggestion)}
                />
              }
              primaryText={search || suggestion}
              rightIcon={<Add />}
            />
          </List>
        </div>
      )}
      {newItems && newItems.length > 0 && (
        <div className="itemlist-to-add">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Subheader>Nieuw toegevoegde gegevens</Subheader>
            {pagetype === "applicatie" && (
              <Checkbox
                label="Externe applicatie(s)"
                className="relatie-extern"
                checked={externalApplication}
                onCheck={handleExternalApplication}
              />
            )}
          </div>

          <List>
            {newItems.map((i) => (
              <ListItem
                key={i}
                leftCheckbox={
                  <Checkbox checked onCheck={() => removeNewItem(i)} />
                }
                primaryText={i}
                rightIcon={<Remove />}
              />
            ))}
          </List>
        </div>
      )}
    </div>
  );
};

ItemsPicker.propTypes = Object.freeze({
  items: PropTypes.arrayOf(
    PropTypes.shape(
      Object.freeze({
        container: PropTypes.string,
        bieb: PropTypes.bool,
        disabled: PropTypes.bool,
        itemId: PropTypes.number.isRequired,
        label: PropTypes.string.isRequired,
        relations: PropTypes.arrayOf(PropTypes.shape({})),
      })
    )
  ),
  // eslint-disable-next-line react/forbid-prop-types
  selected: PropTypes.shape({}),
  handleSelected: PropTypes.func.isRequired,
  create: PropTypes.bool,
  externalApplication: PropTypes.bool,
  handleExternalApplication: PropTypes.func.isRequired,
  search: PropTypes.string.isRequired,
  handleSearch: PropTypes.func.isRequired,
  canCreate: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  muiTheme: PropTypes.shape({}).isRequired,
  multiple: PropTypes.bool,
  onSelect: PropTypes.func,
  toggleStatus: PropTypes.bool,
  toggleFilter: PropTypes.func,
  pagetype: PropTypes.string.isRequired,
});

export default compose(
  setDisplayName("ItemsPicker"),

  withState("search", "setSearch", ""),

  withState("newItems", "setNewItems", []),

  withProps(({ value }) => ({
    selected: fromPairs(
      ((value || empty.object).peers || empty.array).map((itemId) => [
        itemId,
        true,
      ])
    ),
  })),

  withHandlers({
    addNewItem:
      ({
        newItems,
        setNewItems,
        setSearch,
        setValue,
        selected,
        externalApplication,
        onSelect,
      }) =>
      (label) => {
        const list = flow(compact, uniq)([...newItems, label]);
        setNewItems(list);
        setSearch("");
        setValue(pickedItems(list, selected), externalApplication);
        if (onSelect) {
          onSelect();
        }
      },

    removeNewItem:
      ({ newItems, setNewItems, setValue, selected, externalApplication }) =>
      (label) => {
        const list = without([label])(newItems);
        setNewItems(list);
        setValue(pickedItems(list, selected), externalApplication);
      },
  }),

  withState(
    "create",
    "setCreate",
    ({ value, selected, setSearch, suggestion }) => {
      const numReg = new RegExp("^[0-9]+$");
      if (selected && has("peers[0]")(value)) {
        const label = get("peers[0]")(value);
        if (label !== "" && !numReg.test(label)) {
          setSearch(label);
          return selected[label];
        }
      }

      return false;
    }
  ),

  withProps(
    ({ items, search, suggestion, selected, createEnabled = true }) => ({
      items: flow(
        isEmpty(search)
          ? identity
          : filter(
              ({ itemId, label }) =>
                selected[itemId] ||
                label.toLowerCase().indexOf(search.toLowerCase()) >= 0
            ),
        orderBy([({ label }) => label], ["asc"])
      )(items),

      canCreate:
        createEnabled &&
        ((!isEmpty(search) &&
          !some(({ label }) => label.toLowerCase() === search.toLowerCase())(
            items
          )) ||
          (isEmpty(search) &&
            !isEmpty(suggestion) &&
            !some(
              ({ label }) => label.toLowerCase() === suggestion.toLowerCase()
            )(items))),
    })
  ),

  withHandlers({
    handleExternalApplication:
      ({ newItems, selected, setValue }) =>
      (_, nextExternalApplication) => {
        setValue(pickedItems(newItems, selected), nextExternalApplication);
      },

    handleSearch:
      ({
        create,
        setCreate,
        setSearch,
        selected,
        setValue,
        externalApplication,
        newItems,
      }) =>
      (_, nextSearch) => {
        const nextCreate = nextSearch === "" && create ? false : create;
        setSearch(nextSearch);
        setCreate(nextCreate);
        setValue(pickedItems(newItems, selected), externalApplication);
      },

    handleSelected:
      ({ newItems, selected, setValue, externalApplication }) =>
      (itemId, checked) => {
        const nextSelected = checked
          ? { ...selected, [itemId]: true }
          : omit(itemId)(selected);
        setValue(pickedItems(newItems, nextSelected), externalApplication);
      },
  }),

  muiThemeable()
)(ItemsPicker);
