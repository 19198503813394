import empty from "empty";
import { debounce } from "lodash/fp";

export class Viewport {
  /**
   */
  static get size() {
    const windowSize = window
      ? {
          height: window.innerHeight,
          width: window.innerWidth,
        }
      : empty.object;

    const documentSize =
      document && document.documentElement
        ? {
            height: document.documentElement.clientHeight,
            width: document.documentElement.clientWidth,
          }
        : empty.object;

    return { documentSize, windowSize };
  }
}

export const resize = (() => {
  let latestDispatch;
  const measure = () =>
    latestDispatch({
      type: "RESIZE_MEASURE",
      viewport: Viewport.size,
    });

  const lazyMeasure = debounce(100)(measure);
  return () => (dispatch) => {
    latestDispatch = dispatch;
    lazyMeasure();
  };
})();
