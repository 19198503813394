import { defaultTo, keys } from "lodash/fp";
import { List, ListItem } from "material-ui";
import { amber300, amber50 } from "material-ui/styles/colors";
import WarningIcon from "material-ui/svg-icons/alert/warning";
import React from "react";

import { SiteLink, emptyObject } from "../../business/models";
import { flown } from "../../lodash";
import PagetypeIcon from "../pagetypeIcon";
import { ItemLink } from "../relation.itemlink";
import { WarnAboutMissingAppsProps } from "./models";

const WarnAboutMissingApps = ({
  biebPage,
  koppelingCanBeUsed,
  koppelingWithApplications,
  biebPagetypeMapping,
  close,
}: WarnAboutMissingAppsProps) => {
  if (biebPage.page.pagetype !== "koppeling" || koppelingCanBeUsed) {
    return null;
  }

  return (
    <div style={{ display: "flex", backgroundColor: amber50 }}>
      <div
        style={{
          padding: 16,
          borderLeftWidth: 3,
          borderLeftColor: amber300,
          borderLeftStyle: "solid",
        }}
      >
        <WarningIcon color={amber300} />
      </div>
      <div style={{ flex: "1 1 0" }}>
        <p>Betrokken applicaties zijn nog niet overgenomen:</p>
        {koppelingWithApplications.length > 0 && (
          <List>
            {koppelingWithApplications
              .filter(
                (application) =>
                  flown(
                    biebPagetypeMapping?.[application.itemId],
                    defaultTo(emptyObject),
                    keys
                  ).length === 0
              )
              .map((application: SiteLink) => (
                <ListItem
                  key={application.itemId}
                  primaryText={<ItemLink item={application} onClick={close} />}
                  leftIcon={<PagetypeIcon type={application.type} />}
                />
              ))}
          </List>
        )}
      </div>
    </div>
  );
};

export default WarnAboutMissingApps;
