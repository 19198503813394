import empty from "empty";
import { compact, filter, find, flatten, flow, head, map } from "lodash/fp";
import FlatButton from "material-ui/FlatButton";
import RaisedButton from "material-ui/RaisedButton";
import PropTypes from "prop-types";
import React, { useState } from "react";

import { pageActionsShape } from "../business/prop-types";
import Dialog from "../containers/dialog";
import ApplicatieVeldExtrasContainer from "../containers/pagetypes/application/applicatieVeldExtras";
import { flown } from "../lodash";
import Field from "./content/field";
import { isCluster, isField, isVisibleField } from "./content/query";

const Template = ({
  pagetype,
  name,
  clusterName,
  template,
  link,
  pageActions,
  extra,
}) => {
  const filterFields = (container) => (alias) => {
    const obj = container[alias];
    return link
      ? isField(obj) && obj.definition.dataTypeCode === 18
      : isVisibleField(obj, pageActions);
  };

  return (
    <div>
      {flow(
        Object.keys,
        filter(filterFields(template)),
        map((alias) => (
          <Field
            key={alias}
            alias={alias}
            field={template[alias]}
            clusterId={0}
            clusterName={clusterName}
            pageActions={pageActions}
            pagetypes={empty.array}
            fullWidth={false}
          />
        ))
      )(template)}
      {flow(
        Object.keys,
        filter((alias) => isCluster(template[alias])),
        map((alias) => {
          const cluster = template[alias];
          return flow(
            Object.keys,
            filter(filterFields(cluster)),
            map((alias) => (
              <Field
                key={alias}
                alias={alias}
                field={cluster[alias]}
                clusterId={0}
                clusterName={clusterName}
                pageActions={pageActions}
                pagetypes={empty.array}
                fullWidth={false}
              />
            ))
          )(cluster);
        })
      )(template)}
      {pagetype === "applicatie" && name === "Veld" && (
        <ApplicatieVeldExtrasContainer pageActions={pageActions} />
      )}
      {extra}
    </div>
  );
};
Template.propTypes = Object.freeze({
  // eslint-disable-next-line react/forbid-prop-types
  template: PropTypes.object.isRequired,
  link: PropTypes.bool.isRequired,
  clusterName: PropTypes.string,
  pageActions: pageActionsShape.isRequired,
});

const Body = (props) => (
  <div style={{ paddingTop: "3ex" }}>
    <Template {...props} />
  </div>
);
Body.propTypes = Object.freeze({
  ...Template.propTypes,
  pagetype: PropTypes.string.isRequired,
});

const getKoppeling = (template) => {
  return (
    flown(
      template,
      filter(isField),
      find(({ definition }) => definition.dataTypeCode === 18)
    ) ?? flown(template, filter(isCluster), map(getKoppeling), compact, head)
  );
};

let getRequired = () => [];
getRequired = (template) => {
  const shallow = flown(
    template,
    filter(isField),
    filter(({ definition }) => definition.required)
  );
  const deep = flown(template, filter(isCluster), map(getRequired), flatten);
  return shallow.concat(deep);
};

const ClusterDialog = ({ valid, name, template, pageActions, pagetype }) => {
  const koppeling = getKoppeling(template);
  const [state, setState] = useState(Boolean(koppeling) ? "choice" : "new");
  return (
    <Dialog
      title={`${name} toevoegen`}
      open
      autoScrollBodyContent
      actions={[
        <FlatButton
          key="close"
          secondary
          label="Annuleren"
          onClick={() => pageActions.cancelCluster()}
        />,
        <FlatButton
          primary
          disabled={!valid}
          key="save"
          label="Bewaren"
          onClick={() => pageActions.stashCluster()}
        />,
      ]}
    >
      {state === "choice" && (
        <>
          <p>Hoe wilt u {name} toevoegen?</p>
          <div>
            <RaisedButton
              secondary
              label={`${name} nieuw maken`}
              onClick={() => setState("new")}
            />
            <>&nbsp;</>
            <RaisedButton
              secondary
              label={`${koppeling.definition.name} kiezen`}
              onClick={() => {
                setState("link");
                getRequired(template).forEach((field) => {
                  const id = {
                    itemId: pageActions.pageId,
                    clusterId: 0,
                    name: field.definition.name,
                  };
                  pageActions.fieldAdd({ ...id, value: "-", revert: id });
                });
              }}
            />
          </div>
        </>
      )}
      {state !== "choice" && (
        <Body
          name={name}
          clusterName={state === "link" ? name : undefined}
          template={template}
          link={state === "link"}
          pageActions={pageActions}
          pagetype={pagetype}
        />
      )}
    </Dialog>
  );
};
ClusterDialog.propTypes = Object.freeze({
  name: PropTypes.string.isRequired,
  valid: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  template: PropTypes.object.isRequired,
  pagetype: PropTypes.string.isRequired,
  pageActions: pageActionsShape.isRequired,
});

export default ClusterDialog;
