import empty from "empty";
import {
  compact,
  difference,
  every,
  flatten,
  flow,
  identity,
  isEqual,
  map,
  memoize,
  range,
  some,
  take,
} from "lodash/fp";
import moment from "moment";

import { flown, mapWithIndex } from "./lodash";

moment.locale("nl");

export const titleCase = (value) =>
  value.charAt(0).toUpperCase() + value.slice(1);

export const shallowEqualIgnoreFunctions = (left, right) =>
  left === right || (typeof left === "function" && typeof right === "function");

export const deepEqualIgnoreFunctions = (left, right) => {
  switch (true) {
    case left === right:
      return true;
    case typeof left !== typeof right ||
      Array.isArray(left) !== Array.isArray(right) ||
      (left === null) !== (right === null):
      return false;
    case left === undefined || left === null:
      return true;
    case typeof left === "function":
      return true;
    case left.length !== right.length:
      return false;
    case Array.isArray(left): {
      const indexes = range(0, left.length);
      return (
        every((i) => shallowEqualIgnoreFunctions(left[i], right[i]))(indexes) ||
        every((i) => deepEqualIgnoreFunctions(left[i], right[i]))(indexes)
      );
    }
    case typeof left !== "object":
      return false;
    case "memoizedProps" in left:
      return false;
    default: {
      const indexes = Object.keys(left);
      const extras = difference(Object.keys(right))(indexes);

      return (
        extras.length === 0 &&
        (every((i) => shallowEqualIgnoreFunctions(left[i], right[i]))(
          indexes
        ) ||
          every((i) => deepEqualIgnoreFunctions(left[i], right[i]))(indexes))
      );
    }
  }
};

const createMultiUndoubler = (capacity, compare) => {
  let cache = [];
  let index = 0;
  return (value) => {
    const found = cache.find((item) => compare(item, value));
    if (found) {
      return found;
    }

    return (cache[index++ % capacity] = value);
  };
};

export const weakMemoize = (fn, capacity = 100) => {
  const backup = memoize.Cache;
  memoize.Cache = WeakMap;
  const memoized = memoize((args) => fn(...args));
  memoize.Cache = backup;
  const undoubler = createMultiUndoubler(capacity, isEqual);
  return (...raw) => flown(raw, Array.from, undoubler, memoized);
};

/**
 * Moment JS has an issue with dates in October 1995 and before
 * Daylight saving time changed in that year for Europe.
 */
const hackyMoment = (date, format = undefined) =>
  moment(date, format).add(3, "hours").startOf("day");

/**
 * Format only the date part
 */
export const formatDate = (date) =>
  date ? hackyMoment(date).format("D MMMM YYYY") : "";

/**
 * Export only the date part
 */
export const exportDate = (date) =>
  date ? hackyMoment(date).format("YYYY-MM-DD") : "";

/**
 * Format only the date part
 */
export const shortDate = (date) =>
  date ? hackyMoment(date).format("DD-MM-YYYY") : "";

/**
 * Parse only the date part
 */
export const parseDate = (value, format = undefined) =>
  hackyMoment(value, format).toDate();

export const formatDateTime = (datetime) => {
  const m = moment(datetime);
  return `${m.format("D MMMM YYYY")} om ${m.format("H:mm")}`;
};

export const isEmptyArray = (a) => Array.isArray(a) && a.length === 0;

export const isNonEmptyArray = (a) => Array.isArray(a) && a.length > 0;

export const isNonEmptyString = (s) => typeof s === "string" && s.length > 0;

export const arrayFilterMap = (mapper) => (a) =>
  isNonEmptyArray(a) ? flow(map(mapper), compact)(a) : empty.array;

export const arrayFlatMap = (mapper) => (a) =>
  isNonEmptyArray(a) ? flow(map(mapper), flatten)(a) : empty.array;

export const arrayFilter = (filter) => (a) =>
  isNonEmptyArray(a) ? a.filter(filter) : empty.array;

export const isEmpty = (value) =>
  value === undefined || value === null || value === "" || isEmptyArray(value);

export const isChildren = (children) =>
  children !== false &&
  !isEmpty(children) &&
  (!Array.isArray(children) || some(isChildren)(children));

export const nullIfEmpty = (children) => (isEmpty(children) ? null : children);

export const labelize = (array) => {
  const result = [...array];
  for (const [index, value] of array.entries()) {
    result[value] = index;
  }
  return result;
};

export const drop = (obj, key) => {
  if (key in obj) {
    const clone = { ...obj };
    delete clone[key];
    return clone;
  }
  return obj;
};

export const mapValues = (mapper) => (obj) => {
  if (obj === undefined || obj === null) {
    return obj;
  }

  const result = {};
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      result[key] = mapper(obj[key], key);
    }
  }

  return result;
};

export const dispatchWrap = weakMemoize((action, dispatch) =>
  flow(action, dispatch)
);

export const takeIf = (count, condition) =>
  condition ? take(count) : identity;

export const countToMap = (array) => {
  const map = new Map();
  if (isNonEmptyArray(array)) {
    for (const value of array) {
      map.set(value, (map.get(value) || 0) + 1);
    }
  }
  return map;
};

export const regexEscape = (value) =>
  typeof value === "string"
    ? value.replace(/[.?*+^$[\]\\(){}|-]/g, "\\$&")
    : "";

export const highlight = (text, word) => {
  const regExp = new RegExp(regexEscape(word), "ig");
  return text.replace(regExp, "<mark>$&</mark>");
};

export const trimFirst = (input, char) =>
  input.indexOf(char) === 0 ? input.substring(1) : input;

export const expandPath = (root, path) => {
  const result = [root];
  if (typeof path !== "string" || path === "" || path === "*") {
    return result;
  }
  let obj = root;
  for (const part of path.split(".")) {
    obj = obj === null || obj === undefined ? undefined : obj[part];
    result.unshift(obj);
  }
  return result;
};

const once = Object.freeze({ once: true });
export const base64 = (blob) =>
  new Promise((resolve, reject) => {
    const fr = new FileReader();
    fr.addEventListener(
      "load",
      ({ target: { result: dataUrl } }) =>
        resolve(dataUrl.substring(dataUrl.indexOf(",") + 1)),
      once
    );
    fr.addEventListener("abort", (...args) => reject(...args), once);
    fr.addEventListener("error", (...args) => reject(...args), once);
    fr.readAsDataURL(blob);
  });

export const concat = (...args) => args.join("");

export const uploadMulti = (appendValue) => (files) => {
  Promise.all(map(base64)(files)).then((dataArray) => {
    const value = mapWithIndex((data, index) => {
      const { name, preview } = files[index];
      return {
        fileName: name,
        title: name.replace(/\.[^.]+/, ""),
        preview,
        data,
      };
    })(dataArray);
    appendValue(value);
  });
};

export const upload = (setValue) => (file) =>
  base64(file).then((data) =>
    setValue({
      value: {
        fileName: file.name,
        title: file.name.replace(/\.[^.]+/, ""),
        preview: file.preview,
      },
      string: file.name,
      data,
    })
  );

export const structuredMap = (mapping) => {
  if (mapping === null || typeof mapping !== "object") {
    throw new Error("mapping must be an object");
  }

  for (const key in mapping) {
    if (typeof mapping[key] !== "function") {
      throw new Error(`mapping[${key}] must be an object`);
    }
  }

  return (obj) => {
    if (obj === null || typeof obj !== "object") {
      return obj;
    }

    return Object.keys(mapping).reduce((acc, key) => {
      const original = acc[key];
      const mapped = mapping[key](original);
      return original === mapped ? acc : { ...acc, [key]: mapped };
    }, obj);
  };
};

export const getExtension = ({ name: fileName }) =>
  fileName.substring(fileName.lastIndexOf(".") + 1);

// Adds a property key to an array containing a map by this key
/* eslint-disable no-param-reassign */
export const withLookup = (key) => (array) => {
  array[key] = {};
  for (const item of array) {
    array[key][item[key]] = item;
  }
  return array;
};
/* eslint-enable no-param-reassign */

const emptyLookups = {};
export const emptyLookup = (key) => {
  if (emptyLookups[key]) {
    return emptyLookups[key];
  }
  const r = [];
  r[key] = empty.object;
  return (emptyLookups[key] = Object.freeze(r));
};

export const flatMapHead =
  (f) =>
  (a = empty.array) => {
    for (const item of a) {
      const result = f(item);
      if (result !== undefined) {
        return result;
      }
    }

    return undefined;
  };

export const uuidv4 = () =>
  "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });

export const splitChar = /[,\r\n]/;

export const createUndoubler = (initial = empty.object, compare = isEqual) => {
  let prev = initial;
  return (value) => (compare(prev, value) ? prev : (prev = value));
};
