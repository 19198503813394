/* eslint  react/prop-types: off */
import empty from "empty";
import { get, has } from "lodash/fp";
import Checkbox from "material-ui/Checkbox";
import Divider from "material-ui/Divider";
import IconButton from "material-ui/IconButton";
import { List, ListItem } from "material-ui/List";
import Subheader from "material-ui/Subheader";
import ActionDelete from "material-ui/svg-icons/action/delete";
import HardwareKeyboardArrowDown from "material-ui/svg-icons/hardware/keyboard-arrow-down";
import HardwareKeyboardArrowUp from "material-ui/svg-icons/hardware/keyboard-arrow-up";
import PropTypes from "prop-types";
import React, { Fragment, useCallback, useState } from "react";
import { compose, setDisplayName, withHandlers } from "recompose";

import SaarSubheader from "../material/Subheader";
import pagetypeIcons from "../pagetypes/icons";
import { PathLink } from "../relation.pathlink";

export const emptySelection = Object.freeze(new Map());

export const onSelect = (selections, setSelections) => (listId, itemId) => {
  const list = selections.get(listId) || empty.array;
  const nextSelections = new Map(selections);
  nextSelections.set(listId, [...list, itemId]);
  setSelections(nextSelections);
};

export const onDeselect = (selections, setSelections) => (listId, itemId) => {
  const list = selections.get(listId) || empty.array;
  const index = list.indexOf(itemId);
  if (index < 0) {
    return;
  }

  const nextSelections = new Map(selections);
  if (list.length <= 1) {
    nextSelections.delete(listId);
  } else {
    nextSelections.set(
      listId,
      list.slice(0, index).concat(list.slice(index + 1))
    );
  }
  setSelections(nextSelections);
};

export const onReset = (setSelections) => () => setSelections(new Map());

const FacetListItemComponent = ({
  id,
  listId,
  value,
  count,
  selected,
  handleCheck,
}) => {
  const isItem =
    (get("itemId")(id) || get("itmIdt")(id)) > 0 || has("relIdt")(id);
  const iconAlias =
    get("type")(id) ||
    get("listName")(listId) ||
    get("rel")(listId)?.replace(/.*_/, "") ||
    "empty";
  const Icon = pagetypeIcons[iconAlias];

  return (
    <ListItem
      className="facet-list-item"
      leftCheckbox={
        <Checkbox
          checked={selected}
          onCheck={handleCheck}
          style={{ top: "calc(50% - 12px)" }}
        />
      }
      primaryText={
        <span>
          <span className="facet-label">{value}</span>{" "}
          <span className="facet-count">({count})</span>
        </span>
      }
      rightIcon={
        isItem ? (
          <PathLink
            style={{ margin: "6px 8px 0px 8px", padding: 0 }}
            path={`page/${get("itemId")(id) || get("itmIdt")(id)}`}
          >
            <Icon style={{ opacity: 0.65, width: 18 }} />
          </PathLink>
        ) : undefined
      }
      style={{ paddingTop: "8px", paddingBottom: "8px" }}
      innerDivStyle={{ paddingLeft: "56px" }}
    />
  );
};

const FacetListItem = compose(
  setDisplayName("FacetListItem"),
  withHandlers({
    handleCheck:
      ({ id, listId, onSelect, onDeselect }) =>
      (_, isInputChecked) =>
        isInputChecked ? onSelect(listId, id) : onDeselect(listId, id),
  })
)(FacetListItemComponent);

const FacetList = ({
  id: listId,
  value,
  items,
  onSelect,
  onDeselect,
  initiallyOpen = false,
}) => {
  const [open, setOpen] = useState(initiallyOpen);
  const toggleOpen = useCallback(() => setOpen(!open), [open]);
  return (
    <>
      <Subheader
        style={{
          paddingBottom: open ? undefined : 8,
          paddingTop: 8,
          lineHeight: "inherit",
          cursor: "pointer",
        }}
        onClick={toggleOpen}
      >
        <span
          style={{ marginTop: -4, float: "right", transform: "scale(0.75)" }}
        >
          {open ? <HardwareKeyboardArrowUp /> : <HardwareKeyboardArrowDown />}
        </span>
        {value}
      </Subheader>
      {open &&
        items.map(({ id, value, count, selected }) => (
          <FacetListItem
            key={JSON.stringify(id)}
            id={id}
            listId={listId}
            value={value}
            count={count}
            selected={selected}
            onSelect={onSelect}
            onDeselect={onDeselect}
          />
        ))}
    </>
  );
};

const Facets = ({ facets, onSelect, onDeselect, onReset }) => (
  <div>
    <SaarSubheader style={{ display: "flex" }}>
      <div style={{ flexGrow: "1", alignSelf: "center" }}>Filters</div>
      <IconButton
        style={{
          alignSelf: "center",
          padding: "0",
          margin: "0 12px",
          width: "24px",
          height: "24px",
        }}
        onClick={() => onReset()}
      >
        <ActionDelete />
      </IconButton>
    </SaarSubheader>
    <List className="facets-list" style={{ padding: "0 0 8px 0" }}>
      {facets.map((facet, i) => (
        <Fragment key={JSON.stringify(facet.id)}>
          {i > 0 && <Divider />}
          <FacetList
            {...facet}
            onSelect={onSelect}
            onDeselect={onDeselect}
            initiallyOpen={facets.length === 1}
          />
        </Fragment>
      ))}
    </List>
  </div>
);

Facets.propTypes = Object.freeze({
  facets: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.object.isRequired,
      value: PropTypes.string.isRequired,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.object.isRequired,
          value: PropTypes.string.isRequired,
          count: PropTypes.number.isRequired,
          selected: PropTypes.bool.isRequired,
        })
      ).isRequired,
    })
  ).isRequired,
  onSelect: PropTypes.func.isRequired,
  onDeselect: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
});

export default Facets;
