import cytoscape from "cytoscape";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import CytoscapeComponent from "react-cytoscapejs";

import { LandscapeView } from "../../../business/models";
import stylesheet from "./stylesheet";
import Toolbar, { ToolbarProps } from "./toolbar";

const classesToArray = (classes: string | string[] | undefined) => {
  if (typeof classes === "string") {
    return classes.split(" ");
  }

  return classes ?? [];
};

interface Props extends ToolbarProps {
  nodes: cytoscape.NodeDefinition[];

  edges: cytoscape.EdgeDefinition[];

  filters: {
    selectedItemIds: { [itmIdt: number]: boolean };
  };

  onNodeClick(nodeId: string): void;

  onEdgeClick(sourceNodeId: string, targetNodeId: string): void;

  onSave(
    nameOrDefault: string | true, // true for default view
    nodes: cytoscape.NodeDefinition[],
    view?: LandscapeView
  ): void;

  onChange(): void;

  maySaveDefault: boolean;

  onDelete(view: LandscapeView): void;
}

const Network = ({
  edges,
  nodes: nodesProps,
  filters: { selectedItemIds },
  onEdgeClick,
  onNodeClick,
  onSave,
  onChange,
  maySaveDefault,
  onDelete,
  ...toolbarProps
}: Props) => {
  const { view } = toolbarProps;
  let cyInstance = useRef(undefined as unknown as cytoscape.Core);

  const [nodes, setNodes] = useState<cytoscape.NodeDefinition[]>(
    nodesProps.map((node) => structuredClone(node))
  );
  useEffect(
    () => {
      setNodes(nodesProps.map((node) => structuredClone(node)));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [view?.id ?? 0, selectedItemIds]
  );

  const elements = useMemo<cytoscape.ElementDefinition[]>(
    () => [...nodes, ...edges],
    [edges, nodes]
  );

  const handleGroupClick = useCallback(() => {
    const selectedNodes = cyInstance.current.$("node:selected");

    if (selectedNodes.length === 0) {
      return;
    }

    const isParent = selectedNodes.filter((node) => node.hasClass("parent"));
    const isChild = selectedNodes.filter((node) => node.hasClass("child"));
    const isOther = selectedNodes.filter(
      (node) => !node.hasClass("child") && !node.hasClass("parent")
    );

    switch (isParent.length) {
      case 0:
        break;
      case 1: {
        const parentId = isParent[0].id();

        if (isOther.length > 0) {
          addParent(isChild, parentId);
          addParent(isOther, parentId);
        } else if (isChild.length === 0) {
          setNodes((nodes) =>
            nodes.map((node) =>
              node.data.parent === parentId
                ? {
                    ...node,
                    data: { ...node.data, parent: undefined },
                    classes: classesToArray(node.classes).filter(
                      (c) => c !== "child"
                    ),
                  }
                : node
            )
          );
          window.setTimeout(() => {
            setNodes((nodes) =>
              nodes.filter((node) => node.data.id !== parentId)
            );
          }, 100);
        }

        cyInstance.current.$(":selected").unselect();
        onChange();
        return;
      }
      default:
        alert("Meerdere groepen geselecteerd");
        cyInstance.current.$(":selected").unselect();
        return;
    }

    if (isOther.length === 0) {
      removeParent(isChild);
      onChange();
      return;
    }

    const label = prompt("Wat is de naam van de groep?");
    const parentId = crypto.randomUUID();
    const parentNode: cytoscape.NodeDefinition = {
      data: { id: parentId, label },
      classes: "parent",
    };

    addParent(selectedNodes, parentId, parentNode);
    cyInstance.current.$(":selected").unselect();
    onChange();
    return;

    function addParent(
      selectedNodes: cytoscape.NodeCollection,
      parentId: string,
      parent?: cytoscape.NodeDefinition
    ) {
      const selectedNodeIds = selectedNodes.map((selected) => selected.id());
      setNodes((nodes) => {
        const next = nodes.map((node) =>
          selectedNodeIds.includes(node.data.id!)
            ? {
                ...node,
                data: { ...node.data, parent: parentId },
                classes: node.classes?.includes?.("child")
                  ? node.classes
                  : [...classesToArray(node.classes), "child"],
              }
            : node
        );

        return parent ? [parent, ...next] : next;
      });
    }

    function removeParent(selectedNodes: cytoscape.NodeCollection) {
      const selectedNodeIds = selectedNodes.map((selected) => selected.id());
      setNodes((nodes) =>
        nodes.map((node) =>
          selectedNodeIds.includes(node.data.id!)
            ? {
                ...node,
                data: { ...node.data, parent: undefined },
                classes: classesToArray(node.classes).filter(
                  (c) => c !== "child"
                ),
              }
            : node
        )
      );
    }
  }, [onChange]);

  const fitViewport = useCallback(() => {
    cyInstance.current.fit();
  }, [cyInstance]);

  const saveNewView = useCallback(() => {
    const json = cyInstance.current.json() as {
      elements: {
        nodes: cytoscape.NodeDefinition[];
      };
    };

    const name = prompt("Wat is de naam van de weergave?");
    if (!name) {
      return;
    }

    onSave(name, [...json.elements.nodes]);
  }, [onSave]);

  const saveCurrentView = useCallback(() => {
    const json = cyInstance.current.json() as {
      elements: {
        nodes: cytoscape.NodeDefinition[];
      };
    };
    if (view && !view.default) {
      const name = prompt("Wat is de naam van de weergave?", view.name);
      if (name) {
        onSave(name, [...json.elements.nodes], view);
      }
    } else {
      onSave(view?.name ?? true, [...json.elements.nodes], view);
    }
  }, [onSave, view]);

  const deleteView = useCallback(() => {
    if (!view) {
      return;
    }

    onDelete(view);
  }, [view, onDelete]);

  const [layout, setLayout] = useState<cytoscape.LayoutOptions>({
    name: "preset",
  });

  const [layoutVersion, setLayoutVersion] = useState(0);

  const onLayoutChange = useCallback(
    (newLayout: cytoscape.LayoutOptions) => {
      setLayout(newLayout);
      if (newLayout.name === "preset") {
        setNodes(nodesProps.map((node) => structuredClone(node)));
        setLayoutVersion((v) => v + 1);
      }
    },
    [nodesProps]
  );

  return (
    <>
      <div
        className="cluster-card"
        style={{
          backgroundColor: "white",
          boxShadow:
            "rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px",
          padding: 4,
        }}
      >
        <Toolbar
          createCompoundNode={handleGroupClick}
          saveNewView={saveNewView}
          saveCurrentView={
            maySaveDefault || (view && !view.default)
              ? saveCurrentView
              : undefined
          }
          deleteView={
            maySaveDefault || (view && !view.default) ? deleteView : undefined
          }
          fitViewport={fitViewport}
          layout={layout}
          onChangeLayout={onLayoutChange}
          {...toolbarProps}
        />
      </div>
      <div
        style={{ width: "100%", backgroundColor: "red", aspectRatio: "16/9" }}
      >
        <CytoscapeComponent
          key={layoutVersion}
          elements={elements}
          layout={layout}
          stylesheet={stylesheet}
          style={{
            backgroundColor: "#F5F5F5",
            height: "100%",
            width: "100%",
          }}
          wheelSensitivity={0.1}
          cy={(cy) => {
            if (cyInstance.current === cy) {
              return;
            }

            cyInstance.current = cy;
            cy.on("mouseover", "edge", (event) => {
              const edge = event.target;
              edge.addClass("hover");
            });

            cy.on("mouseout", "edge", (event) => {
              const edge = event.target;
              edge.removeClass("hover");
            });

            cy.on("mouseover", "node", (event) => {
              const node = event.target;
              node.addClass("hover");
            });

            cy.on("mouseout", "node", (event) => {
              const node = event.target;
              node.removeClass("hover");
            });

            cy.on("click", "node", (e) => {
              if (!e.originalEvent.ctrlKey && !e.originalEvent.shiftKey) {
                onNodeClick(e.target.data("id"));
              }
            });
            cy.on("click", "edge", (e) => {
              if (!e.originalEvent.ctrlKey && !e.originalEvent.shiftKey) {
                onEdgeClick(e.target.data("source"), e.target.data("target"));
              }
            });

            cy.on("position", "node:child", (event) => {
              const node = event.target;
              const parent = node.parent();
              parent.boundingBox({ includeChildren: true });
            });

            cy.on("position", "node", onChange);
          }}
        />
      </div>
    </>
  );
};

export default Network;
