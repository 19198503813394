/* eslint  react/prop-types: off */
import empty from "empty";
import PropTypes from "prop-types";
import React from "react";
import { compose, defaultProps, setDisplayName, setPropTypes } from "recompose";

import { propTypes, withDefaultPageActions } from "./ggvtyp";

/**
 * Gegevenstype 'Trefwoorden' (1)
 */
const Trefwoorden = ({
  field: { isEmpty = true } = empty.object,
  value,
  className,
}) =>
  isEmpty ? null : (
    <div className={className}>
      {value.map(({ id, name }) => (
        <span key={id}>{name}</span>
      ))}
    </div>
  );

const enhance = compose(
  setDisplayName("Trefwoorden"),
  setPropTypes({
    ...propTypes,
    field: PropTypes.shape({
      value: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired,
          classId: PropTypes.number,
        }).isRequired
      ),
    }).isRequired,
  }),
  defaultProps({
    className: "trefwoorden",
  }),
  withDefaultPageActions()
);

export default enhance(Trefwoorden);
