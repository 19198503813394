import empty from "empty";
import { concat, flatten, flow, groupBy, map } from "lodash/fp";

export const innerJoin = (leftKeySelector, rights, rightKeySelector) => {
  const rightObj = groupBy(rightKeySelector)(rights);
  return flow(
    map((left) => {
      const rights = rightObj[leftKeySelector(left)];
      return rights ? map((right) => [left, right])(rights) : empty.array;
    }),
    flatten
  );
};

export const leftJoin = (leftKeySelector, rights, rightKeySelector) => {
  const rightObj = groupBy(rightKeySelector)(rights);
  return flow(
    map((left) => {
      const rights = rightObj[leftKeySelector(left)];
      return rights
        ? map((right) => [left, right])(rights)
        : [[left, undefined]];
    }),
    flatten
  );
};

export const flown = (arg, ...funcs) => flow(...funcs)(arg);

export const push = (tail) => (head) => concat(head, tail);

export const setup = (key) => (value) => ({ [key]: value });

export const mapWithIndex = map.convert({ cap: false });

export const flatItems = ({ items = empty.array }) =>
  flown(items, map(flatItems), flatten, concat(items));

export const toSet = (a) => new Set(a);
