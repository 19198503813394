/* eslint  react/prop-types: off */
import classNames from "classnames";
import empty from "empty";
import { every, find, get, keys, uniq } from "lodash/fp";
import React, { Fragment } from "react";
import {
  compose,
  mapProps,
  setDisplayName,
  shouldUpdate,
  withProps,
  withPropsOnChange,
  wrapDisplayName,
} from "recompose";
import { defaultMemoize } from "reselect";

import { flown } from "./lodash";
import { deepEqualIgnoreFunctions, drop } from "./utils";

export const mergeClassNames =
  (...extraClassNames) =>
  ({ className }) => ({
    className: uniq(classNames(...extraClassNames, className).split(" ")).join(
      " "
    ),
  });

export const withClassNames = compose(withProps, mergeClassNames);

export const mergeStyles =
  (extraStyle) =>
  ({ style = {} }) => ({
    style: { ...extraStyle, ...style },
  });

export const withStyle = compose(withProps, mergeStyles);

const wrapDisplayDev =
  process.env.NODE_ENV === "production"
    ? empty.functionThatReturns(empty.functionThatReturnsArgument)
    : (name) => (hoc) => (BaseComponent) =>
        setDisplayName(wrapDisplayName(BaseComponent, name))(
          hoc(BaseComponent)
        );

const without = (keys) => (obj) =>
  obj === null || obj === undefined
    ? obj
    : keys.reduce((obj, key) => (key in obj ? drop(obj, key) : obj), obj);

export const withoutProps = (...args) =>
  wrapDisplayDev("withoutProps")(
    mapProps((props) =>
      without(typeof args[0] === "function" ? args[0](props) : args)(props)
    )
  );

export const hasDataChanged =
  (...keys) =>
  (prev, next) =>
    !every((key) => deepEqualIgnoreFunctions(get(key)(prev), get(key)(next)))(
      keys
    );

export const ignoreKnownIrrelevancies = shouldUpdate((prev, next) => {
  const key = flown(
    prev,
    keys,
    find((key) => {
      switch (key) {
        case "plug":
          return false;
        case "pageActions":
          return prev.pageActions?.edit !== next.pageActions?.edit;
        default:
          return !Object.is(prev[key], next[key]);
      }
    })
  );

  return Boolean(key);
});

export const updateOnDataChanged = (...keys) => {
  const changed = shouldUpdate(hasDataChanged(...keys));
  return wrapDisplayDev("updateOnDataChanged")(changed);
};

export const Fork = ({ test, left, right, ...props }) => {
  const Sub = test(props) ? left : right;
  return <Sub {...props} />;
};

export const execOnChange = (funcProp, ...dependencies) => {
  const hoc = compose(
    withPropsOnChange(
      typeof dependencies[0] === "function" ? dependencies[0] : dependencies,
      (props) => {
        if (typeof funcProp === "function") {
          funcProp(props);
        } else if (typeof props[funcProp] === "function") {
          props[funcProp](props);
        }

        return empty.object;
      }
    ),
    withoutProps(typeof funcProp === "function" ? "" : funcProp)
  );
  return wrapDisplayDev("execOnChange")(hoc);
};

export const insertBefore = (Insert) => {
  const hoc = (Base) => (props) =>
    (
      <Fragment>
        <Insert {...props} />
        <Base {...props} />
      </Fragment>
    );
  return wrapDisplayDev("insertBefore")(hoc);
};

export const insertAfter = (Insert) => {
  const hoc = (Base) => (props) =>
    (
      <Fragment>
        <Base {...props} />
        <Insert {...props} />
      </Fragment>
    );
  return wrapDisplayDev("insertAfter")(hoc);
};

export const withHoc = (getHocFromProps) => (BaseComponent) => {
  const componentSelector = defaultMemoize((hoc) => hoc(BaseComponent));
  return (props) => {
    const hoc = getHocFromProps(props);
    const Enhanced = componentSelector(hoc);
    return <Enhanced {...props} />;
  };
};
