/* eslint  react/prop-types: off */
import empty from "empty";
import { map, reject, some } from "lodash/fp";
import Checkbox from "material-ui/Checkbox";
import React from "react";
import { compose, setDisplayName, withHandlers } from "recompose";

import { withClassNames } from "../../recompose.contrib";

export const test = ({ field: { names = empty.array } }) =>
  names[0] === "Werkdagen";

const dagen = Object.freeze({
  ma: "Maandag",
  di: "Dinsdag",
  wo: "Woensdag",
  do: "Donderdag",
  vr: "Vrijdag",
});

const delen = Object.freeze({
  o: "Ochtend",
  m: "Middag",
});

const updateValue = (oldValue, value, selected) => {
  const array = selected
    ? [...oldValue, { value }]
    : reject({ value })(oldValue);
  return { value: array, string: map("value")(array).join(";") };
};

const working = (dag, deel) =>
  some(({ value }) => value === `${dagen[dag]}${delen[deel].toLowerCase()}`);

const WerkdagCheckboxComponent = ({
  dag,
  deel,
  handleCheck,
  value,
  busy,
  edit,
}) => (
  <td>
    <Checkbox
      disabled={busy || !edit}
      checked={working(dag, deel)(value)}
      onCheck={handleCheck}
    />
  </td>
);

const WerkdagCheckbox = compose(
  setDisplayName("WerkdagCheckbox"),
  withHandlers({
    handleCheck:
      ({ setValue, value = empty.array, dag, deel }) =>
      (_, checked) =>
        setValue(
          updateValue(
            value,
            `${dagen[dag]}${delen[deel].toLowerCase()}`,
            checked
          )
        ),
  })
)(WerkdagCheckboxComponent);

/**
 * Gegevenstype 'Werkdagen' (4)
 */
const Werkdagen = ({
  field: { isEmpty = true } = empty.object,
  value = empty.array,
  className,
  setValue,
  pageActions: { busy, edit },
}) =>
  isEmpty && !busy && !edit ? null : (
    <table className={className}>
      <thead>
        <tr>
          <th />
          {Object.keys(dagen).map((key) => (
            <th scope="col" key={key}>
              <abbr title={dagen[key]}>{dagen[key].substring(0, 2)}</abbr>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {Object.keys(delen).map((deel) => (
          <tr key={deel}>
            <th scope="row">{delen[deel]}</th>
            {Object.keys(dagen).map((dag) => (
              <WerkdagCheckbox
                key={`${dag}-${deel}`}
                dag={dag}
                deel={deel}
                value={value}
                setValue={setValue}
                busy={busy}
                edit={edit}
              />
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );

export default compose(
  setDisplayName("Werkdagen"),
  withClassNames("werkdagen")
)(Werkdagen);
