import classNames from "classnames";
import empty from "empty";
import { entries, filter, flow, head, map } from "lodash/fp";
import { Card } from "material-ui/Card";
import { List, ListItem } from "material-ui/List";
import RaisedButton from "material-ui/RaisedButton";
import React from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import { structuredMap, takeIf } from "../../utils";
import { Meta } from "../ggvtyp/raamwerk";
import Subheader from "../material/Subheader";
import RelationView from "../relation";

const RelationPraktijksituatieReadOnly = ({
  all,
  className,
  label,
  maxLength,
  relations,
  setAll,
  type,
  selectedSaar,
  legoblokStructures,
  hasToggle,
}) =>
  relations.length === 0 ? null : (
    <Card style={{ marginBottom: "16px" }}>
      <div className={classNames(className, "relations")}>
        <List>
          <Subheader key="header" secondary={type.scope > 0}>
            {type.displayLabel ?? label}
          </Subheader>
          <TransitionGroup>
            {flow(
              takeIf(maxLength - 1, !all && relations.length > maxLength),
              map((relation) => {
                const raamwerkField = flow(
                  entries,
                  filter(
                    ([
                      _,
                      {
                        definition: { dataTypeCode } = empty.object,
                      } = empty.object,
                    ]) => dataTypeCode === 274
                  ),
                  map(([_, obj]) => obj),
                  head
                )(relation.meta.page);

                return (
                  <CSSTransition
                    key={`rel-${relation.item.itemId}-${
                      relation.item.pageClusterId || 0
                    }`}
                    classNames="relations"
                    timeout={{ enter: 500, exit: 200 }}
                  >
                    <RelationView
                      relation={
                        type.forceMiniSaar
                          ? structuredMap({
                              item: (item) => ({
                                miniSaar: selectedSaar,
                                ...item,
                              }),
                            })(relation)
                          : relation
                      }
                      labelRender={type.labelRender}
                      clusterFirst={type.clusterFirst}
                      legoblokStructures={legoblokStructures}
                      secondaryText={
                        <span className="inside-relation-item">
                          {raamwerkField.value.items.map(({ item, meta }) => (
                            <span
                              className="raamwerk-item"
                              key={item.selectionId}
                            >
                              <Meta item={meta} small />{" "}
                              {item.alias?.includes(">")
                                ? type.leftShow === 1
                                  ? item.alias.substring(
                                      0,
                                      item.alias.indexOf(">")
                                    )
                                  : item.alias
                                : item.value}
                            </span>
                          ))}
                        </span>
                      }
                      secondaryTextLines={2}
                    />
                  </CSSTransition>
                );
              })
            )(relations)}
          </TransitionGroup>
          {hasToggle && (
            <ListItem key="toggle" style={{ textAlign: "center" }} disabled>
              <RaisedButton
                label={all ? "Toon minder" : "Toon meer"}
                onClick={() => setAll(!all)}
              />
            </ListItem>
          )}
        </List>
      </div>
    </Card>
  );

export default RelationPraktijksituatieReadOnly;
