import empty from "empty";
import { filter, intersection, map, some } from "lodash/fp";
import ImageNavigateNext from "material-ui/svg-icons/image/navigate-next";
import React from "react";

import { Side } from "../../business/relations";
import { iproxUrl } from "../../config";
import Basecamp from "../../icons/basecamp";
import Highrise from "../../icons/highrise";
import { flown } from "../../lodash";
import { pagetypeViewName } from "../../pagetypes";
import {
  exportDate,
  highlight,
  isEmpty,
  isNonEmptyArray,
  parseDate,
  shortDate,
} from "../../utils";
import { highriseFolder } from "../ggvtyp/platGegeven";
import { ItemLink } from "../relation.itemlink";
import SafeInnerHtml, { convertHtml2Text } from "../safeInnerHtml";

export const extractValues = (
  { lookup = empty.object, fields },
  { pagetypeList, setPreview, structureIds } = empty.object
) => {
  const {
    [`bieb_${fields.pagetype}`]: overgenomenRelatiesLeft = empty.array,
    [`${fields.pagetype}_bieb`]: overgenomenRelatiesRight = empty.array,
  } = lookup;
  const overgenomen = flown(
    overgenomenRelatiesLeft,
    filter({ side: Side.left }),
    map("structureId"),
    intersection(structureIds),
    isNonEmptyArray
  );
  const overgeleverd = flown(
    overgenomenRelatiesRight,
    filter({ side: Side.right }),
    map("structureId"),
    intersection(structureIds),
    isNonEmptyArray
  );
  const overtenemen =
    fields.pagetype === "legoblok" ||
    some(
      ({ allowed, alias }) => allowed % 2 === 1 && alias === fields.pagetype
    )(pagetypeList);
  const item = {
    itemId: fields.itemId,
    variantId: fields.variantId,
    miniSaar: fields.miniSaar,
    slug: fields.slug,
    label: fields.label,
    discouraged: overgeleverd || overgenomen || !overtenemen,
    overgeleverd,
    overgenomen,
    overtenemen,
    setPreview,
  };

  return (property) => {
    switch (property) {
      case "titel":
        return fields.docTyp.indexOf("attachment") > -1
          ? [
              {
                pad: fields.pad,
                label: `${fields.assLbl} - ${fields.filSiz}`,
                from: item,
              },
            ]
          : [item];
      case "icoonlink":
        return [{ ...item, icon: ImageNavigateNext }];
      case "datum":
        return [parseDate(fields.lstModDtm)];
      case "pagetype":
        return [pagetypeViewName(fields.pagetype)];
      case "projectid":
        return lookup.id
          ? [<Basecamp key={lookup.id} id={lookup.id} label={lookup.id} />]
          : undefined;
      case "highrise-ID":
        return lookup[property] && lookup.pagetype && lookup.pagetype.length > 0
          ? [
              <Highrise
                key={lookup[property]}
                id={lookup[property]}
                label={lookup[property]}
                folder={highriseFolder(lookup.pagetype)}
              />,
            ]
          : lookup[property];
      default:
        if (/datum\b/.test(property) || property.startsWith("datum ")) {
          return (
            lookup[property] ??
            (isEmpty(fields[property]) ? empty.array : [fields[property]])
          ).map((value) => parseDate(value));
        }

        return (
          lookup[property] ||
          (isEmpty(fields[property]) ? empty.array : [fields[property]])
        ).map((value) => {
          switch (value) {
            case "True":
              return "Ja";
            case "False":
              return "Nee";
            default:
              return value;
          }
        });
    }
  };
};

export const orderValue = (value, descending) =>
  isEmpty(value)
    ? descending
      ? " "
      : null
    : value.itemId
    ? orderValue(value.label, descending)
    : typeof value === "string"
    ? value.trim()
    : value instanceof Date
    ? value.valueOf()
    : orderValue(value.toString(), descending);

export const displayValue = (value, keyword) =>
  isEmpty(value) ? (
    false
  ) : value.pad ? (
    <a href={`${iproxUrl}${value.pad}`} title={`${value.label}`}>
      {value.label}
    </a>
  ) : value.itemId ? (
    <ItemLink item={value} title={value.label} highlightWord={keyword}>
      {value.icon && <value.icon />}
    </ItemLink>
  ) : typeof value === "object" && value.type ? (
    value
  ) : typeof value === "string" && value.charAt(0) === "<" ? (
    <SafeInnerHtml>{highlight(value, keyword)}</SafeInnerHtml>
  ) : value instanceof Date ? (
    shortDate(value)
  ) : keyword !== "" ? (
    <SafeInnerHtml>{highlight(value, keyword)}</SafeInnerHtml>
  ) : (
    value
  );

export const exportValue = (value) =>
  isEmpty(value)
    ? null
    : value.itemId
    ? exportValue(value.label)
    : typeof value === "string" && value.charAt(0) === "<"
    ? exportValue(convertHtml2Text(value))
    : value instanceof Date
    ? exportDate(value)
    : typeof value.props?.label?.[0] === "string"
    ? value.props?.label?.[0]
    : value.toString().trim();

export const stringValue = (value) =>
  isEmpty(value)
    ? ""
    : value.itemId
    ? stringValue(value.label)
    : typeof value === "string"
    ? value.trim().toLowerCase()
    : value instanceof Date
    ? shortDate(value)
    : stringValue(value.toString());
