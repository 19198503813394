import classNames from "classnames";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { requireZibExpansion } from "../../../actions/application";
import { RelationTypeGroups } from "../../../actions/page";
import {
  ContentAction,
  PageActions,
  RelTab,
  RelationGroup,
  RelationType,
} from "../../../business/models";
import { Label } from "../../content/field";
import ClusterPicker, {
  ClusterPickItem,
  ClusterPickReference,
  ContainerClusterPickItem,
} from "../../pickers/ClusterPicker";

interface Props {
  label: string;
  pageClusterId: number;
  pageClusterName: "onderdeel" | "inhoud";
  pageActions: PageActions;
  relations: RelationGroup[];
  types: RelationType[];
}

interface ZibOnderdeel {
  itemId: number;
  id: number;
  level: number;
  naam: string;
  type: string;
  via?: { itemId: number; pageClusterId: number; level: number };
  onderdelen?: ZibOnderdeel[];
}

const toClusterPickItem = ({
  itemId,
  id,
  level,
  naam,
  type,
  via,
  onderdelen,
}: ZibOnderdeel): ClusterPickItem | ContainerClusterPickItem => ({
  itemId,
  pageClusterId: id,
  level,
  label: naam,
  type: type.toLowerCase(),
  via,
  items: onderdelen?.[0] ? onderdelen.map(toClusterPickItem) : undefined,
});

const ZibExpandedSelect = ({
  label,
  pageClusterId,
  pageClusterName,
  pageActions,
  relations,
  types,
}: Props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(requireZibExpansion());
  }, [dispatch]);

  const staged: Partial<RelTab>[] | undefined = useSelector(
    (state: any) =>
      state.form.page[pageActions.pageId]?.staged?.find(
        ({ $type: type, clusterId, relationTypeGroup }: ContentAction) =>
          clusterId === pageClusterId &&
          relationTypeGroup ===
            RelationTypeGroups.informatiestandaardInhoudZibGegeven &&
          /ClusterReplaceRelations/.test(type)
      )?.relations
  );

  const bieb = useSelector((state: any) => {
    const structures = state.data.addLegoblok.structures ?? [];
    const siteId = state.data.pages[pageActions.pageId]?.siteId;
    return structures.find(({ id }: { id: number }) => id === siteId)?.id;
  });

  const selected = useMemo(() => {
    if (staged) {
      return staged.map(
        ({ narItmIdt, narPagClsIdt, metItmIdt, metPagClsIdt }) => ({
          itemId: narItmIdt!,
          pageClusterId: narPagClsIdt,
          type: "gegeven",
          via: metItmIdt
            ? {
                itemId: metItmIdt!,
                pageClusterId: metPagClsIdt!,
              }
            : undefined,
        })
      );
    }

    return relations
      .filter(({ alias }) =>
        /^informatiestandaard_(onderdeel|inhoud)_zib/.test(alias)
      )
      .flatMap(({ links }) =>
        links
          .filter((link) => link.relation.left.pageClusterId === pageClusterId)
          .map(({ relation }) => relation)
      )
      .map(
        ({
          right: { itemId: rightItemId, pageClusterId: rightPageClusterId },
          using,
        }) => ({
          itemId: rightItemId,
          pageClusterId: rightPageClusterId!,
          type: "gegeven",
          via: using?.[0]
            ? {
                itemId: using[0].itemId,
                pageClusterId: using[0].pageClusterId!,
              }
            : undefined,
        })
      );
  }, [pageClusterId, relations, staged]);

  const zibs = useSelector((state: any) =>
    state.data.zibExpansion.zibs?.filter((zib: { bieb?: number }) =>
      bieb ? zib.bieb === bieb : !zib.bieb
    )
  );

  const items = useMemo(() => {
    return (
      zibs?.map((zib: ZibOnderdeel) => {
        return {
          itemId: zib.id,
          label: zib.naam,
          level: 0,
          type: "zib",
          items: zib.onderdelen?.[0]
            ? zib.onderdelen.map(toClusterPickItem)
            : undefined,
        };
      }) ?? []
    );
  }, [zibs]);

  const onChange = useCallback(
    (selected: ClusterPickReference[]) => {
      const relations = selected.map(
        ({ itemId, pageClusterId, level, via }) => {
          const typeAlias = via
            ? `informatiestandaard_${pageClusterName}_zib_sub${via.level}_zib_ggv${level}`
            : pageClusterId
            ? `informatiestandaard_${pageClusterName}_zib_ggv${level}`
            : `informatiestandaard_${pageClusterName}_zib`;
          const type = types.find(({ alias }) => alias === typeAlias);
          if (!type) {
            console.error("cr7", typeAlias, types);
            throw new Error(`Relation type ${typeAlias} not found`);
          }

          return {
            itmRelTypIdt: type.id,
            narItmIdt: itemId,
            narPagClsIdt: pageClusterId,
            metItmIdt: via?.itemId,
            metPagClsIdt: via?.pageClusterId,
          };
        }
      );

      pageActions.clusterSetRelations(
        {
          clusterId: pageClusterId,
          relations,
          relationTypeGroup:
            RelationTypeGroups.informatiestandaardInhoudZibGegeven,
        },
        false
      );
    },
    [pageActions, pageClusterId, pageClusterName, types]
  );

  if (selected.length === 0 && !pageActions.edit) {
    return null;
  }

  return (
    <div
      className={classNames("field cluster-relation", {
        "field-edit": pageActions.edit,
      })}
    >
      <Label>{label}</Label>
      {zibs && (
        <div className="value">
          <ClusterPicker
            items={items}
            selected={selected}
            onChange={onChange}
            multiple
            labelText="Zib"
            disabled={!pageActions.edit}
          />
        </div>
      )}
    </div>
  );
};

export default ZibExpandedSelect;
